import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { EditStateService } from "../../../application/master/herbicide/editState";
import { NewStateService } from "../../../application/master/herbicide/newState";
import { ReadApiService } from "../../../application/master/herbicide/readApi";
import { isActiveEntity, isEntity, isNewEntity } from "../../../domain/entity";
import { Herbicide } from "../../../domain/herbicide";
import { ApplicationState } from "../../../store/modules";
import { NewOrEditStateType } from "../../../store/modules/master/herbicide/newOrEditState/reducer";
import { MasterFormDefault } from "../../components/molecules/MasterForm";
import { MasterList } from "../../components/molecules/MasterList";
import { PageHeader } from "../../components/molecules/PageHeader";
import { MainLayout } from "../Layout";
import { MasterLayout } from "../Layout/Master";
import { HerbicideEditPage } from "./EditPage";
import { HerbicideNewPage } from "./NewPage";

interface StateProps {
  herbicides: Herbicide[];
  newOrEditState: NewOrEditStateType;
}

interface DispatchProps {
  readApiService: ReadApiService;
  newStateService: NewStateService;
  editStateService: EditStateService;
}

type HerbicidesPageProps = StateProps & DispatchProps;

export class Wrapped extends React.Component<HerbicidesPageProps> {
  public componentDidMount(): void {
    this.props.readApiService.init().then(() => {
      const { herbicide } = this.props.newOrEditState;
      if (herbicide !== null && isEntity(herbicide)) {
        this.props.editStateService.selectHerbicide(herbicide.id);
      }
    });
  }

  public render() {
    const herbicide = this.props.newOrEditState.herbicide;
    let form;
    if (herbicide !== null) {
      form = isEntity(herbicide) ? <HerbicideEditPage /> : <HerbicideNewPage />;
    } else {
      form = (
        <MasterFormDefault
          onClickAdd={herbicide !== null && isNewEntity(herbicide) ? null : this.handleClickAddHerbicide}
          onClickDelete={herbicide !== null && isEntity(herbicide) ? this.handleClickDeleteHerbicide : null}
        />
      );
    }

    return (
      <MainLayout>
        <MasterLayout
          header={<PageHeader title={"除草剤・薬品"} />}
          list={
            <MasterList
              items={this.props.herbicides}
              selectedItemId={herbicide !== null && isEntity(herbicide) ? herbicide.id : null}
              onClickItem={this.handleClickListItem}
            />
          }
          form={form}
        />
      </MainLayout>
    );
  }

  private handleClickListItem = (id: number) => {
    this.props.editStateService.selectHerbicide(id);
  };

  private handleClickAddHerbicide = () => {
    this.props.newStateService.createHerbicide();
  };

  private handleClickDeleteHerbicide = () => {
    this.props.editStateService.deleteHerbicide();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { newOrEditState } = state.master.herbicide;
  return {
    herbicides: state.api.herbicide.herbicides.filter(isActiveEntity),
    newOrEditState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    readApiService: new ReadApiService(dispatch),
    editStateService: new EditStateService(dispatch),
    newStateService: new NewStateService(dispatch),
  };
};

export const HerbicidesPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);
