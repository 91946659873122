import { default as moment } from "moment";
import * as React from "react";
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import { Header, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { NewOrEditEnvironmentReport } from "../../../../domain/environmentReport";
import WaterTemperatureSVG from "../../../../static/img/water_temperature.svg";
import { COLOR } from "../../../styles/Color";
import { DIMEN } from "../../../styles/Dimen";
import { ButtonGroup, SelectButton } from "../../atoms/Button";
import { AutoSizer } from "../AutoSizer";

type PeriodType = "week" | "month" | "three_months" | "half_year";

interface ReportListTemperatureContentProps {
  environmentReports: NewOrEditEnvironmentReport[];
  selectedDate: Date;
  activePeriodType: PeriodType;
  onClickPeriod: (periodType: PeriodType) => void;
}

interface LineData {
  key: string;
  value: number | null;
}

export const ReportListTemperatureContent = ({
  environmentReports,
  selectedDate,
  activePeriodType,
  onClickPeriod,
}: ReportListTemperatureContentProps) => {
  const data = environmentReports.map(mapEnvironmentReportToData);
  const date = moment(selectedDate);
  const environmentReport = environmentReports.find((e) => {
    const reportDate = moment(e.date);
    return date.isSame(reportDate, "date");
  });
  return (
    <Outer>
      <GraphOuter>
        <AutoSizer>
          {({ width, height }) => (
            <LineChart width={width} height={height} data={data} margin={{ top: 10, left: 0, bottom: 10, right: 10 }}>
              <CartesianGrid strokeDasharray="1 1" />
              <XAxis
                dataKey="key"
                tickFormatter={(value) => {
                  const split = value.split("/");
                  return `${split[1]}/${split[2]}`;
                }}
              />
              <YAxis domain={["dataMin - 2", "dataMax + 2"]} width={40} />
              <Tooltip formatter={(value: unknown) => [`${value}℃`, "水温"]} />
              <Legend formatter={() => <span>水温</span>} />
              <Line
                type="monotone"
                dataKey="value"
                stroke="#6bb3dd"
                connectNulls={true}
                isAnimationActive={false}
              />
            </LineChart>
          )}
        </AutoSizer>
      </GraphOuter>
      <ContentOuter>
        <ButtonOuter>
          <ButtonGroup>
            <SelectButton active={activePeriodType === "week"} onClick={() => onClickPeriod("week")}>
              1週
            </SelectButton>
            <SelectButton active={activePeriodType === "month"} onClick={() => onClickPeriod("month")}>
              1月
            </SelectButton>
            <SelectButton active={activePeriodType === "three_months"} onClick={() => onClickPeriod("three_months")}>
              3月
            </SelectButton>
            <SelectButton active={activePeriodType === "half_year"} onClick={() => onClickPeriod("half_year")}>
              6月
            </SelectButton>
          </ButtonGroup>
        </ButtonOuter>
        <DateHeader>{date.format("YYYY年MM月DD日")}</DateHeader>
        <DetailOuter>
          <DetailIcon>
            <Icon size={"huge"}>
              <img src={WaterTemperatureSVG} alt={"水温"} style={{ width: "auto", height: "100%" }} />
            </Icon>
          </DetailIcon>
          <DetailTitle>
            <ItemTitle>水温</ItemTitle>
            <ItemCaption>water temperature</ItemCaption>
          </DetailTitle>
          <DetailValue>
            {!environmentReport || environmentReport.value === "" ? "-" : environmentReport.value}
            <DetailUnit>℃</DetailUnit>
          </DetailValue>
        </DetailOuter>
      </ContentOuter>
    </Outer>
  );
};

export const mapEnvironmentReportToData = (report: NewOrEditEnvironmentReport): LineData => {
  const reportDate = moment(report.date).format("YYYY/MM/DD");
  const value = report.value === "" ? null : Number(report.value);
  return { key: reportDate, value };
};

const Outer = styled.div`
  width: 100%;
  height: 100%;
`;

const GraphOuter = styled.div`
  height: 50%;
`;

const ButtonOuter = styled.div`
  text-align: right;
  margin-bottom: ${DIMEN.X2};
`;

const DateHeader = styled(Header).attrs({ as: "h3" })``;

const ContentOuter = styled.div`
  margin-left: ${DIMEN.X3};
  padding-right: ${DIMEN.X1};
`;

const DetailOuter = styled.div`
  display: flex;
  padding: ${DIMEN.X1};
`;
const DetailIcon = styled.div`
  flex 1 1;
`;
const DetailTitle = styled.div`
  padding-left: ${DIMEN.X1};
  flex: 2 2;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const ItemTitle = styled.div`
  font-size: 18px;
`;
const ItemCaption = styled.div`
  font-size: 12px;
  color: ${COLOR.Gray["darken-1"]};
`;
const DetailValue = styled.div`
  flex: 2 2;
  font-size: 24px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const DetailUnit = styled.span`
  font-weight: 400;
`;
