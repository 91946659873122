import { Dispatch } from "redux";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { HerbicideApiService } from "../../api/herbicide";

interface IReadApiService extends IApplicationService {
  init: () => void;
}

export class ReadApiService implements IReadApiService {
  private herbicideApiService: HerbicideApiService;
  public constructor(private dispatch: Dispatch<any>) {
    this.herbicideApiService = new HerbicideApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public async init() {
    await this.herbicideApiService.init();
  }
}
