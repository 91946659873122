import { sortPosition } from "../../../../domain/entity";
import { PondType } from "../../../../domain/pondType";
import { FetchLifeCycle } from "../../../lifeCycle/fetchLifeCycle";

export interface PondTypeStateType extends FetchLifeCycle {
  pondTypes: PondType[];
}

export const getInitialState = (): PondTypeStateType => {
  return {
    pondTypes: [],
    isFetched: false,
    isFetching: false,
    isError: null,
  };
};

export const FetchHandlers = {
  start(state: PondTypeStateType) {
    return { ...state, isFetched: false, isFetching: true, isError: null };
  },
  complete(state: PondTypeStateType, pondTypes: PondType[]) {
    const sortedEntity = pondTypes.concat().sort(sortPosition);
    return { ...state, pondTypes: sortedEntity, isFetched: true, isFetching: false };
  },
  fail(state: PondTypeStateType, message: string = "") {
    return { ...state, isFetched: false, isFetching: false, isError: { message } };
  },
  shouldFetch(state: PondTypeStateType): PondTypeStateType {
    return { ...state, isFetched: false };
  },
};
