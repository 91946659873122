import * as React from "react";
import { MobileRouter } from "./mobile";
import { PCRouter } from "./pc";

export const Router = () => {
  return (
    <>
      <PCRouter />
      <MobileRouter />
    </>
  );
};
