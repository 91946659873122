import { EditPond, NewOrEditPond, NewPond, Pond } from "../../../../../domain/pond";
import { EditLifeCycle } from "../../../../lifeCycle/editLifeCycle";

export interface NewOrEditStateType extends EditLifeCycle {
  pond: NewOrEditPond | null;
}

export const getInitialState = (): NewOrEditStateType => {
  return { pond: null, isUnSave: false, isSaved: false, isSaving: false, isError: null };
};

export const InitialHandler = {
  createPond(state: NewOrEditStateType, pond: NewPond): NewOrEditStateType {
    return { ...state, pond, isUnSave: false, isSaved: false, isError: null };
  },
  selectPond(state: NewOrEditStateType, pond: EditPond): NewOrEditStateType {
    return { ...state, pond, isUnSave: false, isSaved: false, isError: null };
  },
};

export const ChangeHandler = {
  changePond(state: NewOrEditStateType, key: keyof NewOrEditPond, value: any): NewOrEditStateType {
    if (state.pond === null) {
      return state;
    }
    return { ...state, pond: { ...state.pond, [key]: value }, isUnSave: true };
  },
};

export const SaveHandler = {
  cancelPond(state: NewOrEditStateType, pond: NewOrEditPond): NewOrEditStateType {
    return { ...state, pond, isUnSave: false, isSaved: false };
  },
  saveStart(state: NewOrEditStateType): NewOrEditStateType {
    return { ...state, isSaving: true, isError: null };
  },
  saveSuccess(state: NewOrEditStateType, pond: Pond | null): NewOrEditStateType {
    return { ...state, pond, isUnSave: false, isSaved: true, isSaving: false };
  },
  saveFail(state: NewOrEditStateType, message: string = ""): NewOrEditStateType {
    return { ...state, isUnSave: true, isSaved: false, isSaving: false, isError: { message } };
  },
};
