import * as React from "react";
import styled from "styled-components";
import { Herbicide } from "../../../../domain/herbicide";
import { PondType } from "../../../../domain/pondType";
import { UseMethodType } from "../../../../domain/useMethodType";
import { DIMEN } from "../../../styles/Dimen";
import { SaveButton } from "../../atoms/Button/Mobile";
import { Form } from "../../atoms/Form/Form";
import { FormSelect } from "../../atoms/Form/Select";
import { ContentSection, PageSection } from "../../atoms/Section";
import { Spinner } from "../../atoms/Spinner";
import { ReportAmountUnitInput } from "../ReportAmountInput";
import { WorkReportFormNoteBody } from "../WorkReportFormNote";

interface WorkReportFormHerbicideProps {
  herbicides: Herbicide[];
  useMethodTypes: UseMethodType[];
  pondTypes: PondType[];
  herbicideId: null | number;
  amount: string;
  useMethodTypeId: null | number;
  note: string;
  imageIds: number[];
  pondTypeId: number;
  onChangeHerbicide: (id: null | number) => void;
  onChangeAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeUseMethodType: (id: number | null) => void;
  onClickAmountUp: () => void;
  onClickAmountDown: () => void;
  onChangeNote: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickImage: (imageIds: number[], imageId: number) => void;
  onClickImageRemove: (id: number) => void;
  onChangePondType: ((id: number | null) => void) | null;
}
export const WorkReportFormHerbicide = (
  props: WorkReportFormHerbicideProps & { onClickSave: () => void; isSaving: boolean; disabledSave: boolean }
) => {
  return (
    <Form>
      {props.isSaving && <Spinner />}
      <PageSection>除草剤（池）・薬品を記録</PageSection>
      <WorkReportFormHerbicideBody {...props} />
      <SaveButton onClick={props.onClickSave} disabled={props.disabledSave} />
    </Form>
  );
};

export const WorkReportFormHerbicideBody = (props: WorkReportFormHerbicideProps) => {
  return (
    <>
      <ContentSection>種類</ContentSection>
      <Content>
        <FormSelect value={props.herbicideId} options={props.herbicides} onChange={props.onChangeHerbicide} />
      </Content>
      <ContentSection>量</ContentSection>
      <Content>
        <ReportAmountUnitInput
          useMethodTypes={props.useMethodTypes}
          amount={props.amount}
          useMethodTypeId={props.useMethodTypeId}
          onChangeAmount={props.onChangeAmount}
          onChangeUseMethodType={props.onChangeUseMethodType}
          onClickAmountUp={props.onClickAmountUp}
          onClickAmountDown={props.onClickAmountDown}
        />
      </Content>
      <WorkReportFormNoteBody
        note={props.note}
        imageIds={props.imageIds}
        onChangeNote={props.onChangeNote}
        onChangeFile={props.onChangeFile}
        onClickImage={props.onClickImage}
        onClickImageRemove={props.onClickImageRemove}
      />
      {!!props.onChangePondType && (
        <>
          <ContentSection>池の区分</ContentSection>
          <Content>
            <FormSelect value={props.pondTypeId} options={props.pondTypes} onChange={props.onChangePondType} />
          </Content>
        </>
      )}
    </>
  );
};

const Content = styled.div`
  padding: ${DIMEN.X1};
`;
