import { Entity, generateUid, isNewEntity, NewEntity } from "../entity";
import { getValidation, isEmptyString, isTextLength, Validation, Validator } from "../validation";

interface CarpPairingAttribute extends Record<string, unknown> {
  name: string;
  position: number;
  imageIds: number[];
  isDeleted: boolean;
}

export interface NewCarpPairing extends CarpPairingAttribute, NewEntity {}
export interface EditCarpPairing extends CarpPairingAttribute, Entity {}

export type NewOrEditCarpPairing = NewCarpPairing | EditCarpPairing;
export type CarpPairing = EditCarpPairing;

// service
const NEW_ENTITY_PREFIX = "cp-";

export function createNewCarpPairing(): NewCarpPairing {
  return {
    uid: generateUid(NEW_ENTITY_PREFIX),
    name: "",
    imageIds: [],
    position: 1,
    isDeleted: false,
  };
}

export function isNewCarpPairing(carpPairing: NewOrEditCarpPairing): carpPairing is NewCarpPairing {
  return isNewEntity(carpPairing);
}

/*** Validator ***/
export class CarpPairingValidator extends Validator<NewOrEditCarpPairing> {
  public validate(entity: NewOrEditCarpPairing) {
    const nameValidation = validateName(entity);
    if (nameValidation) {
      this.addMessages(nameValidation);
    }
  }
}

function validateName({ name }: NewOrEditCarpPairing): Validation | null {
  if (isEmptyString(name)) {
    return getValidation("組み合わせ名は必須です。入力してください。");
  }
  if (!isTextLength(name, 0, 255)) {
    return getValidation("組み合わせ名は255文字以下で入力してください。");
  }
  return null;
}
