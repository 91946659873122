import { default as moment } from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NavigationService } from "../../../../application/mobile/navigation";
import { NewOrEditStateService } from "../../../../application/mobile/pondReport/newOrEditState";
import { PondDisinfectionReportStateService } from "../../../../application/mobile/pondReport/pondDisinfectionReportState";
import { Pond } from "../../../../domain/pond";
import { NewOrEditPondDisinfectionReport } from "../../../../domain/pondDisinfectionReport";
import { NewOrEditPondReport } from "../../../../domain/pondReport";
import { ApplicationState } from "../../../../store/modules";
import { NavigationStateType } from "../../../../store/modules/mobile/navigation/reducer";
import { ListStateType } from "../../../../store/modules/mobile/pondReport/listState/reducer";
import { NewOrEditStateType } from "../../../../store/modules/mobile/pondReport/newOrEditState/reducer";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { PondReportFormDisinfection } from "../../../components/molecules/PondReportFormDisinfection";
import { MobileLayout } from "../../Layout/Mobile";

interface StateProps {
  newOrEditState: NewOrEditStateType;
  listState: ListStateType;
  navigationState: NavigationStateType;
}

interface OwnProps {
  pond: Pond;
  pondReport: NewOrEditPondReport;
  disinfectionReport: NewOrEditPondDisinfectionReport;
  onClickSave: () => void;
}

interface DispatchProps {
  newOrEditStateService: NewOrEditStateService;
  navigationService: NavigationService;
  pondDisinfectionReportStateService: PondDisinfectionReportStateService;
}

type PondReportPondDisinfectionPageProps = StateProps & DispatchProps & RouteComponentProps & OwnProps;

export class Wrapped extends React.Component<PondReportPondDisinfectionPageProps> {
  public componentWillMount(): void {
    if (this.props.navigationState.selectedPondId === null) {
      this.props.history.push("/");
      return;
    }
  }

  public render() {
    const { pond, disinfectionReport } = this.props;
    return (
      <MobileLayout>
        <BaseNavigationHeader title={pond.name} onClickReturn={this.handleClickReturn} />
        <BaseNavigationContent>
          <PondReportFormDisinfection
            amount={disinfectionReport.amount}
            date={disinfectionReport.date}
            note={disinfectionReport.note}
            onChangeAmount={this.handleChangeAmount}
            onClickAmountUp={this.handleClickAmountUp}
            onClickAmountDown={this.handleClickAmountDown}
            onChangeDate={this.handleChangeDate}
            onClickToday={this.handleClickToday}
            onClickYesterday={this.handleClickYesterday}
            onClickYesterdayMinusOne={this.handleClickYesterdayMinusOne}
            onChangeNote={this.handleChangeNote}
            isSaving={this.props.newOrEditState.isSaving}
            onClickSave={this.props.onClickSave}
          />
        </BaseNavigationContent>
      </MobileLayout>
    );
  }

  private handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const num = Number(e.target.value);
    if (!Number.isNaN(num)) {
      this.props.pondDisinfectionReportStateService.changeAmount(e.target.value);
    }
  };

  private handleClickAmountUp = () => {
    this.props.pondDisinfectionReportStateService.changeAmountUp();
  };

  private handleClickAmountDown = () => {
    this.props.pondDisinfectionReportStateService.changeAmountDown();
  };

  private handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = moment(e.target.value, "YYYY-MM-DD");
    if (date.isValid()) {
      this.props.pondDisinfectionReportStateService.changeDate(date.toDate());
    }
  };

  private handleClickToday = () => {
    this.props.pondDisinfectionReportStateService.changeDateToToday();
  };
  private handleClickYesterday = () => {
    this.props.pondDisinfectionReportStateService.changeDateToYesterday();
  };
  private handleClickYesterdayMinusOne = () => {
    this.props.pondDisinfectionReportStateService.changeDateToYesterdayMinusOne();
  };

  private handleChangeNote = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.pondDisinfectionReportStateService.changeNote(e.target.value);
  };

  private handleClickReturn = () => {
    this.props.history.goBack();
  };
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StateProps & OwnProps => {
  return {
    navigationState: state.mobile.navigation,
    listState: state.mobile.pondReportState.listState,
    newOrEditState: state.mobile.pondReportState.newOrEditStateType,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    newOrEditStateService: new NewOrEditStateService(dispatch),
    navigationService: new NavigationService(dispatch),
    pondDisinfectionReportStateService: new PondDisinfectionReportStateService(dispatch),
  };
};

export const PondReportPondDisinfectionNewOrEditPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
