import { sortPosition } from "../../../../domain/entity";
import { ParentCarp } from "../../../../domain/parentCarp";
import { FetchLifeCycle } from "../../../lifeCycle/fetchLifeCycle";

export interface ParentCarpStateType extends FetchLifeCycle {
  parentCarps: ParentCarp[];
}

export const getInitialState = (): ParentCarpStateType => {
  return { parentCarps: [], isFetched: false, isFetching: false, isError: null };
};

export const FetchHandlers = {
  start(state: ParentCarpStateType) {
    return { ...state, isFetched: false, isFetching: true, isError: null };
  },
  complete(state: ParentCarpStateType, parentCarps: ParentCarp[]) {
    const sortedEntity = parentCarps.concat().sort(sortPosition);
    return { ...state, parentCarps: sortedEntity, isFetched: true, isFetching: false };
  },
  fail(state: ParentCarpStateType, message: string = "") {
    return { ...state, isFetched: false, isFetching: false, isError: { message } };
  },
  shouldFetch(state: ParentCarpStateType): ParentCarpStateType {
    return { ...state, isFetched: false };
  },
};

export const SaveHandlers = {
  createParentCarp(state: ParentCarpStateType, parentCarp: ParentCarp): ParentCarpStateType {
    const parentCarps = state.parentCarps.concat(parentCarp);
    const sortedEntity = parentCarps.concat().sort(sortPosition);
    return { ...state, parentCarps: sortedEntity };
  },
  updateParentCarp(state: ParentCarpStateType, parentCarp: ParentCarp): ParentCarpStateType {
    const parentCarps = state.parentCarps.map((c) => (c.id === parentCarp.id ? parentCarp : c));
    const sortedEntity = parentCarps.concat().sort(sortPosition);
    return { ...state, parentCarps: sortedEntity };
  },
  deleteParentCarp(state: ParentCarpStateType, parentCarp: ParentCarp): ParentCarpStateType {
    const parentCarps = state.parentCarps.map((c) => (c.id === parentCarp.id ? parentCarp : c));
    const sortedEntity = parentCarps.concat().sort(sortPosition);
    return { ...state, parentCarps: sortedEntity };
  },
};
