import { Entity } from "../entity";

interface UseMethodTypeAttribute extends Record<string, unknown> {
  name: string;
  unit: string;
  groupName: string;
  reportGroup: number;
  pondClassId: number;
  position: number;
  isDeleted: boolean;
}

export interface NewUseMethodType extends UseMethodTypeAttribute {}
export interface EditUseMethodType extends UseMethodTypeAttribute, Entity {}

export type NewOrEditUseMethodType = NewUseMethodType | EditUseMethodType;
export type UseMethodType = EditUseMethodType;

enum GroupName {
  feeder = "feeder",
  bucket = "bucket",
  bag = "bag",
}

export function isBucketUseMethodType(useMethodType: UseMethodType): boolean {
  return useMethodType.groupName === GroupName.bucket;
}

export function isFeederUseMethodType(useMethodType: UseMethodType): boolean {
  return useMethodType.groupName === GroupName.feeder;
}
