import * as React from "react";
import { FormField } from "semantic-ui-react";
import { Pond } from "../../../../domain/pond";
import { PondArea } from "../../../../domain/pondArea";
import { getCurrentPondReport, getFryCurrentAmount, PondReport } from "../../../../domain/pondReport";
import { ModalStateType as MobileModalStateType } from "../../../../store/modules/mobile/pondReport/moveModalState/reducer";
import { ModalStateType } from "../../../../store/modules/report/pondReport/moveModalState/reducer";
import { Form } from "../../atoms/Form/Form";
import { FormNumberInput, FormTextInput } from "../../atoms/Form/Input";
import { FormSelect } from "../../atoms/Form/Select";
import { Modal, ModalContent } from "../../atoms/Modal";
import { Spinner } from "../../atoms/Spinner";
import { Text } from "../../atoms/Text";
import { MasterFormButton } from "../MasterFormButton";

interface MoveFormProps {
  isOpen: boolean;
  modalState: ModalStateType;
  selectedDate: Date;
  pondReports: PondReport[];
  pondAreas: PondArea[];
  ponds: Pond[];
  onChangePondArea: (pondAreaId: null | number) => void;
  onChangePond: (pondId: null | number) => void;
  onChangeAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeNote: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSave: () => void;
  onClose: () => void;
  isSaving: boolean;
}

export const CarpMovingFormModal = (props: MoveFormProps) => {
  const { pondAreaId, pondId, amount, note } = props.modalState;
  const pondReport = getCurrentPondReport(props.pondReports, pondId, props.selectedDate) as PondReport;
  const hasPondReports = pondReport !== null;

  const ponds = props.ponds.filter((p) => p.pondAreaId === pondAreaId);
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size={"small"}>
      <ModalContent>
        {props.isSaving && <Spinner />}
        <Form>
          <FormSelect
            value={pondAreaId === null ? NOT_SELECTED_ID : pondAreaId}
            label={"移動先エリア"}
            options={getPondAreaOptions(props.pondAreas)}
            onChange={onChangePondAreaOption(props.onChangePondArea)}
          />
          <FormSelect value={pondId} label={"移動先の野池"} options={ponds} onChange={props.onChangePond} />
          <FormNumberInput label={"移動する匹数"} value={amount} onChange={props.onChangeAmount} />
          {!hasPondReports && "状態管理がありません。管理者に問い合わせてください。"}
          {hasPondReports && <SumField amount={amount} pondReport={pondReport} />}
          <FormTextInput label={"メモ"} value={note} onChange={props.onChangeNote} />
          <MasterFormButton disabledSave={!hasPondReports} onClickSave={props.onSave} onClickCancel={props.onClose} />
        </Form>
      </ModalContent>
    </Modal>
  );
};

const NOT_SELECTED_ID = -1;

function getPondAreaOptions(pondAreas: PondArea[]): Array<{ id: number; name: string }> {
  return pondAreas
    .map((area) => ({ id: area.id, name: area.name }))
    .concat({ id: NOT_SELECTED_ID, name: "未割り当て" });
}

const onChangePondAreaOption = (onChange: (id: number | null) => void) => (pondAreaId: number | null) => {
  const selectedId = pondAreaId === NOT_SELECTED_ID ? null : pondAreaId;
  onChange(selectedId);
};

interface SumFieldProps {
  amount: string;
  pondReport: PondReport;
}
const SumField = ({ amount, pondReport }: SumFieldProps) => {
  const numAmount = Number(amount);
  const currentAmount = getFryCurrentAmount(pondReport);
  const sum = numAmount + currentAmount;
  return (
    <FormField>
      <label>移動先の合計匹数</label>
      <Text>{`${Number.isNaN(sum) ? "" : sum}匹`}</Text>
    </FormField>
  );
};

interface MobileCarpMovingFormModal {
  isOpen: boolean;
  modalState: MobileModalStateType;
  pondAreas: PondArea[];
  ponds: Pond[];
  onChangePondArea: (pondAreaId: null | number) => void;
  onChangePond: (pondId: null | number) => void;
  onSave: () => void;
  onClose: () => void;
}

export const MobileCarpMovingFormModal = (props: MobileCarpMovingFormModal) => {
  const { pondAreaId, pondId } = props.modalState;
  const ponds = props.ponds.filter((p) => p.pondAreaId === pondAreaId);
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size={"small"}>
      <ModalContent>
        <Form>
          <FormSelect
            value={pondAreaId === null ? NOT_SELECTED_ID : pondAreaId}
            label={"移動先エリア"}
            options={getPondAreaOptions(props.pondAreas)}
            onChange={onChangePondAreaOption(props.onChangePondArea)}
          />
          <FormSelect value={pondId} label={"移動先の野池"} options={ponds} onChange={props.onChangePond} />
          <MasterFormButton onClickSave={props.onSave} onClickCancel={props.onClose} />
        </Form>
      </ModalContent>
    </Modal>
  );
};
