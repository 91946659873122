import { default as moment } from "moment";
import * as React from "react";
import { Header } from "semantic-ui-react";
import styled from "styled-components";
import { COLOR } from "../../../styles/Color";
import { DIMEN } from "../../../styles/Dimen";
import { ButtonGroup, SelectButton } from "../../atoms/Button";
import { PageSection } from "../../atoms/Section";

interface DateSelectionSectionProps {
  selectedDate: Date;
}
export const DateSelectionSection = ({ selectedDate }: DateSelectionSectionProps) => {
  const formattedDate = moment(selectedDate).format("YYYY年MM月DD日");
  return (
    <PageSection className={"large white"}>
      <Outer>
        <DateOuter>{formattedDate}</DateOuter>
        <DateSuffix>の記録</DateSuffix>
      </Outer>
    </PageSection>
  );
};

export const NoContentDetailSection = () => {
  return (
    <PageSection className={"large white"}>
      <DateOuter>&nbsp;</DateOuter>
    </PageSection>
  );
};

interface ReportListDetailSectionHeaderProps {
  selectedDate: Date;
  selectedTab: "detail" | "graph";
  onClickDetail: () => void;
  onClickGraph: () => void;
}
export const ReportListDetailSectionHeader = (props: ReportListDetailSectionHeaderProps) => {
  const formattedDate = moment(props.selectedDate).format("YYYY年MM月DD日");
  return (
    <HeaderOuter>
      <DateHeader>{formattedDate}</DateHeader>
      <ButtonGroup>
        <SelectButton active={"detail" === props.selectedTab} onClick={props.onClickDetail}>
          記録
        </SelectButton>
        <SelectButton active={"graph" === props.selectedTab} onClick={props.onClickGraph}>
          グラフ
        </SelectButton>
      </ButtonGroup>
    </HeaderOuter>
  );
};

const Outer = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderOuter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${DIMEN.X1_5} ${DIMEN.X1};
  border-bottom: 1px solid ${COLOR.Gray["lighter-1"]};
`;
const DateHeader = styled(Header).attrs({ as: "h2" })`
  margin-bottom: 0;
`;

const DateOuter = styled(Header).attrs({ as: "h3" })`
  margin-right: ${DIMEN.X1};
  margin-bottom: 0;
`;

const DateSuffix = styled.div``;
