import { Dispatch } from "redux";
import { createNewFeed, FeedValidator } from "../../../domain/feed";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { FeedRepository } from "../../../infrastracture/feed/repository";
import { ApplicationState } from "../../../store/modules";
import { apiFeedActions } from "../../../store/modules/api/feed/ducks";
import { masterFeedNewOrEditStateActions } from "../../../store/modules/master/feed/newOrEditState/ducks";
import { notificationAlertStateActions } from "../../../store/modules/notification/alert/ducks";
import { notificationQueueStateActions } from "../../../store/modules/notification/queue/ducks";

interface INewStateService extends IApplicationService {
  createFeed: () => void;
  changeName: (name: string) => void;
  changePondClass: (pondClassId: number) => void;
  cancelFeed: () => void;
  saveFeed: () => void;
}

export class NewStateService implements INewStateService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public createFeed() {
    this.dispatch(masterFeedNewOrEditStateActions.createFeed({ feed: createNewFeed() }));
  }

  @catchApplicationError()
  public changeName(name: string) {
    this.dispatch(masterFeedNewOrEditStateActions.changeFeed({ key: "name", value: name }));
  }

  @catchApplicationError()
  public changePondClass(pondClassId: null | number) {
    this.dispatch(masterFeedNewOrEditStateActions.changeFeed({ key: "pondClassId", value: pondClassId }));
  }

  @catchApplicationError()
  public cancelFeed() {
    this.dispatch(masterFeedNewOrEditStateActions.cancelFeed({ feed: createNewFeed() }));
  }

  @catchApplicationError((dispatch) => dispatch(masterFeedNewOrEditStateActions.saveFail()))
  public async saveFeed() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(masterFeedNewOrEditStateActions.saveStart());
      const { newOrEditState } = getState().master.feed;
      const newFeed = newOrEditState.feed;
      if (newFeed === null) {
        throw new ApplicationError("餌が見つかりませんでした。");
      }
      const validator = new FeedValidator();
      validator.validate(newFeed);
      if (!validator.isValid()) {
        this.dispatch(notificationAlertStateActions.showErrorMessage({ errorMessage: validator.getMessages() }));
        this.dispatch(masterFeedNewOrEditStateActions.saveFail());
        return;
      }
      const savedFeed = await new FeedRepository().postFeed(newFeed);
      this.dispatch(masterFeedNewOrEditStateActions.saveSuccess({ feed: savedFeed }));
      this.dispatch(apiFeedActions.createFeed({ feed: savedFeed }));
      this.dispatch(notificationQueueStateActions.addSaveMessage({ itemName: "餌" }));
    });
  }
}
