import { Entity } from "../entity";

interface FeedReasonTypeAttribute extends Record<string, unknown> {
  name: string;
  pondClassId: number;
  position: number;
  isDeleted: boolean;
}

export interface NewFeedReasonType extends FeedReasonTypeAttribute {}
export interface EditFeedReasonType extends FeedReasonTypeAttribute, Entity {}

export type NewOrEditFeedReason = NewFeedReasonType | EditFeedReasonType;
export type FeedReasonType = EditFeedReasonType;

/*** service ***/

export function shouldAlertReason(feedReason: FeedReasonType) {
  return feedReason.name !== "通常";
}
