import { Dispatch } from "redux";
import { createNewHerbicide, HerbicideValidator } from "../../../domain/herbicide";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { HerbicideRepository } from "../../../infrastracture/herbicide/repository";
import { ApplicationState } from "../../../store/modules";
import { apiHerbicideActions } from "../../../store/modules/api/herbicide/ducks";
import { masterHerbicideNewOrEditStateActions } from "../../../store/modules/master/herbicide/newOrEditState/ducks";
import { notificationAlertStateActions } from "../../../store/modules/notification/alert/ducks";
import { notificationQueueStateActions } from "../../../store/modules/notification/queue/ducks";

interface INewStateService extends IApplicationService {
  createHerbicide: () => void;
  changeName: (name: string) => void;
  cancelHerbicide: () => void;
  saveHerbicide: () => void;
}

export class NewStateService implements INewStateService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public createHerbicide() {
    this.dispatch(masterHerbicideNewOrEditStateActions.createHerbicide({ herbicide: createNewHerbicide() }));
  }

  @catchApplicationError()
  public changeName(name: string) {
    this.dispatch(masterHerbicideNewOrEditStateActions.changeHerbicide({ key: "name", value: name }));
  }

  @catchApplicationError()
  public cancelHerbicide() {
    this.dispatch(masterHerbicideNewOrEditStateActions.cancelHerbicide({ herbicide: createNewHerbicide() }));
  }

  @catchApplicationError((dispatch) => dispatch(masterHerbicideNewOrEditStateActions.saveFail()))
  public async saveHerbicide() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(masterHerbicideNewOrEditStateActions.saveStart());
      const { newOrEditState } = getState().master.herbicide;
      const newHerbicide = newOrEditState.herbicide;
      if (newHerbicide === null) {
        throw new ApplicationError("除草剤が見つかりませんでした。");
      }
      const validator = new HerbicideValidator();
      validator.validate(newHerbicide);
      if (!validator.isValid()) {
        this.dispatch(notificationAlertStateActions.showErrorMessage({ errorMessage: validator.getMessages() }));
        this.dispatch(masterHerbicideNewOrEditStateActions.saveFail());
        return;
      }
      const savedHerbicide = await new HerbicideRepository().postHerbicide(newHerbicide);
      this.dispatch(masterHerbicideNewOrEditStateActions.saveSuccess({ herbicide: savedHerbicide }));
      this.dispatch(apiHerbicideActions.createHerbicide({ herbicide: savedHerbicide }));
      this.dispatch(notificationQueueStateActions.addSaveMessage({ itemName: "除草剤・薬品" }));
    });
  }
}
