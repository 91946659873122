import * as React from "react";
import styled from "styled-components";
import { DIMEN } from "../../../styles/Dimen";
import { Grid, GridColumn } from "../../atoms/Grid";
import { ListNotSelectedMessage } from "../../atoms/Message";
import { MasterActionButton } from "../MasterFormButton";

interface MasterFormProps {
  onClickAdd: null | (() => void);
  onClickDelete: null | (() => void);
  children?: any;
}

export const MasterForm = ({ onClickAdd, onClickDelete, children }: MasterFormProps) => {
  return (
    <Grid centered columns={1}>
      <GridColumn mobile={16} tablet={14} computer={14}>
        <Wrapper>
          <Action>
            <MasterActionButton onClickAdd={onClickAdd} onClickDelete={onClickDelete} />
          </Action>
          <Body>{children}</Body>
        </Wrapper>
      </GridColumn>
    </Grid>
  );
};

const Wrapper = styled.div`
  padding: ${DIMEN.X2};
`;
const Action = styled.div``;

const Body = styled.div``;

export const MasterFormDefault = ({ onClickAdd, onClickDelete }: MasterFormProps) => {
  return (
    <>
      <ListNotSelectedMessage>左のリストから項目を選択して下さい</ListNotSelectedMessage>
      <WrapperDefault>
        <Grid centered columns={1}>
          <GridColumn mobile={16} tablet={14} computer={14}>
            <Wrapper>
              <Action>
                <MasterActionButton onClickAdd={onClickAdd} onClickDelete={onClickDelete} />
              </Action>
            </Wrapper>
          </GridColumn>
        </Grid>
      </WrapperDefault>
    </>
  );
};

const WrapperDefault = styled.div``;
