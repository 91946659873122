import * as React from "react";
import {
  Modal as _Modal,
  ModalActions as _ModalActions,
  ModalContent as _ModalContent,
  ModalHeader as _ModalHeader,
} from "semantic-ui-react";
import styled from "styled-components";

export const ModalHeader = _ModalHeader;
export const ModalActions = _ModalActions;

interface ModalProps {
  size?: "mini" | "tiny" | "small" | "large" | "fullscreen";
  basic?: boolean;
  isOpen: boolean;
  onClose: () => void;
  children?: any;
  closeOnDimmerClick?: boolean;
}

export const Modal = ({ isOpen, onClose, children, size, closeOnDimmerClick, basic }: ModalProps) => {
  return (
    <_Modal open={isOpen} onClose={onClose} size={size} closeOnDimmerClick={closeOnDimmerClick} basic={basic}>
      {children}
    </_Modal>
  );
};

export const ModalContent = styled(_ModalContent)`
  &.no-padding {
    padding: 0 !important;
  }
`;
