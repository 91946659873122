import * as React from "react";
import styled from "styled-components";
import { COLOR } from "../../../styles/Color";
import { DIMEN } from "../../../styles/Dimen";
import { Modal, ModalContent } from "../../atoms/Modal";
import { TextArea } from "../../atoms/Text";

interface NotificationModalProp {
  isOpen: boolean;
  onClose: () => void;
  onClickOk: () => void;
  okText: string;
  message: string;
}

export const NotificationModal = ({ isOpen, onClose, okText, message, onClickOk }: NotificationModalProp) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="small" closeOnDimmerClick={false}>
      <ModalContent>
        <Content>
          <TextArea text={message} />
        </Content>
      </ModalContent>
      <Actions>
        <OkButton onClick={onClickOk}>{okText}</OkButton>
      </Actions>
    </Modal>
  );
};

export const Content = styled.div`
  text-align: center;
  font-weight: 700 !important;
  padding: ${DIMEN.X1_5};
`;

export const Actions = styled.div`
  display: flex;
  border-top: 1px solid ${COLOR.Gray["lighter-2"]};
  text-align: center;
  font-weight: 700;
`;

export const OkButton = styled.div`
  flex 1 1;
  padding: ${DIMEN.X1_5};
`;
