import { Dispatch } from "redux";
import { FeedReasonType } from "../../../domain/feedReasonType";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { FeedReasonTypeRepository } from "../../../infrastracture/feedReasonType/repository";
import { ApplicationState } from "../../../store/modules";
import { apiFeedReasonTypeActions } from "../../../store/modules/api/feedReasonType/ducks";

interface IFeedReasonTypeApiService extends IApplicationService {
  init: () => void;
  fetchUnFetched: () => void;
}

export class FeedReasonTypeApiService implements IFeedReasonTypeApiService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError((dispatch) => dispatch(apiFeedReasonTypeActions.fetchFail()))
  public async init() {
    this.dispatch(apiFeedReasonTypeActions.fetchStart());
    const feedReasonTypes: FeedReasonType[] = await new FeedReasonTypeRepository().getFeedReasonTypes();
    this.dispatch(apiFeedReasonTypeActions.fetchComplete({ feedReasonTypes }));
  }

  @catchApplicationError((dispatch) => dispatch(apiFeedReasonTypeActions.fetchFail()))
  public async fetchUnFetched() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      if (getState().api.feedReasonType.isFetched) {
        return;
      }
      this.dispatch(apiFeedReasonTypeActions.fetchStart());
      const feedReasonTypes: FeedReasonType[] = await new FeedReasonTypeRepository().getFeedReasonTypes();
      this.dispatch(apiFeedReasonTypeActions.fetchComplete({ feedReasonTypes }));
    });
  }
}
