import { PondOwnerType } from "../../domain/pondOwnerType";
import * as Api from "../../libs/apiClient";

export function translateApiToPondOwnerType(apiPondOwnerType: Api.PondOwnerType): PondOwnerType {
  return {
    id: apiPondOwnerType.id,
    name: apiPondOwnerType.name,
    position: apiPondOwnerType.position,
    isDeleted: apiPondOwnerType.isDeleted,
  };
}

export function translatePondOwnerTypeToApi(pondOwnerType: PondOwnerType): Api.PondOwnerType {
  return {
    id: pondOwnerType.id,
    name: pondOwnerType.name,
    position: pondOwnerType.position,
    isDeleted: pondOwnerType.isDeleted,
  };
}
