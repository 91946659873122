import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NewOrEditStateService as EnvironmentReportService } from "../../../../application/mobile/environmentReport/newOrEditState";
import { NavigationService } from "../../../../application/mobile/navigation";
import { ListStateService as PondReportListService } from "../../../../application/mobile/pondReport/listState";
import { NewOrEditStateService as PondReportService } from "../../../../application/mobile/pondReport/newOrEditState";
import { ReportListStateService } from "../../../../application/mobile/reportList";
import { FeedReportStateService } from "../../../../application/mobile/workReport/feedReportState";
import { FertilizerReportStateService } from "../../../../application/mobile/workReport/fertilizerReportState";
import { HerbicideReportStateService } from "../../../../application/mobile/workReport/herbicideReportState";
import { NewOrEditStateService as WorkReportService } from "../../../../application/mobile/workReport/newOrEditState";
import { getUniqueKey } from "../../../../domain/entity";
import {
  EnvironmentReport,
  getEnvironmentReportsBasedOnDate,
  NewOrEditEnvironmentReport,
} from "../../../../domain/environmentReport";
import { Feed } from "../../../../domain/feed";
import { FeedReasonType } from "../../../../domain/feedReasonType";
import { Fertilizer } from "../../../../domain/fertilizer";
import { Herbicide } from "../../../../domain/herbicide";
import { ParentCarp } from "../../../../domain/parentCarp";
import { Pond } from "../../../../domain/pond";
import { PondReport } from "../../../../domain/pondReport";
import { ReportGroup } from "../../../../domain/reportGroup";
import { ScaleType } from "../../../../domain/scaleType";
import { SearchQueryMobile } from "../../../../domain/searchQuery";
import { UseMethodType } from "../../../../domain/useMethodType";
import { WorkReport } from "../../../../domain/workReport";
import { ApplicationState } from "../../../../store/modules";
import { imageGalleryStateActions } from "../../../../store/modules/imageGallery/ducks";
import { NavigationStateType } from "../../../../store/modules/mobile/navigation/reducer";
import { GraphPeriodType, ReportListStateType } from "../../../../store/modules/mobile/reportListState/reducer";
import { Spinner } from "../../../components/atoms/Spinner";
import { ActionsModal } from "../../../components/molecules/ActionsModal";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { DateSelectionSection } from "../../../components/molecules/ReportListDetailSection";
import { ReportListTemperatureContent } from "../../../components/molecules/ReportListTemperatureContent";
import { ReportListDetail } from "../../../components/organisms/ReportListDetail";
import { MobileLayout } from "../../Layout/Mobile";

interface StateProps {
  workReports: WorkReport[];
  pondReports: PondReport[];
  environmentReports: EnvironmentReport[];

  ponds: Pond[];
  parentCarps: ParentCarp[];
  feeds: Feed[];
  feedReasonTypes: FeedReasonType[];
  fertilizers: Fertilizer[];
  herbicides: Herbicide[];
  useMethodTypes: UseMethodType[];
  scaleTypes: ScaleType[];

  reportListState: ReportListStateType;
  navigationState: NavigationStateType;
  isLoaded: boolean;
}

interface StateType {
  isMovingPage: boolean;
}

interface DispatchProps {
  reportListStateService: ReportListStateService;
  navigationService: NavigationService;
  workReportService: WorkReportService;
  feedReportService: FeedReportStateService;
  fertilizerReportService: FertilizerReportStateService;
  herbicideReportService: HerbicideReportStateService;
  pondReportService: PondReportService;
  pondReportListService: PondReportListService;
  environmentReportService: EnvironmentReportService;
  showImageGallery: (imageIds: number[], imageId: number) => void;
}

type ReportListDetailPageProps = StateProps & DispatchProps & RouteComponentProps;

export class Wrapped extends React.Component<ReportListDetailPageProps, StateType> {
  constructor(props: ReportListDetailPageProps) {
    super(props);

    this.state = {
      isMovingPage: false,
    };
  }

  public componentWillMount(): void {
    if (this.props.navigationState.selectedPondId === null) {
      this.props.history.push("/");
      return;
    }
    if (!this.props.isLoaded) {
      this.props.history.push("/reports");
      return;
    }

    if (this.props.reportListState.detailTab === "graph") {
      this.props.reportListStateService.fetchEnvironmentReports();
    }
  }

  public render() {
    const { reportListState } = this.props;
    const { graphPeriodType } = reportListState;

    const pond = this.props.ponds.find((p) => p.id === this.props.navigationState.selectedPondId);
    if (!pond) {
      return (
        <MobileLayout>
          <BaseNavigationHeader title={""} onClickReturn={this.handleClickReturn} />
          <BaseNavigationContent>
            <Spinner />
          </BaseNavigationContent>
        </MobileLayout>
      );
    }

    const { selectedReportTypeIds } = reportListState.reportFilterState;

    return (
      <MobileLayout>
        <BaseNavigationHeader
          title={pond.name}
          onClickReturn={this.handleClickReturn}
          onClickAction={this.handleOpenActionModal}
        />
        <BaseNavigationContent>
          {reportListState.detailTab === "detail" && (
            <>
              <DateSelectionSection selectedDate={this.props.reportListState.selectedDate} />
              <ReportListDetail
                selectedDate={this.props.reportListState.selectedDate}
                selectedPondId={this.props.navigationState.selectedPondId}
                workReports={this.props.workReports}
                pondReports={this.props.pondReports}
                environmentReports={this.props.environmentReports}
                ponds={this.props.ponds}
                parentCarps={this.props.parentCarps}
                feeds={this.props.feeds}
                feedReasonTypes={this.props.feedReasonTypes}
                fertilizers={this.props.fertilizers}
                herbicides={this.props.herbicides}
                useMethodTypes={this.props.useMethodTypes}
                scaleTypes={this.props.scaleTypes}
                isDisplayFeedReport={selectedReportTypeIds.includes(ReportGroup.Feed)}
                isDisplayFertilizerReport={selectedReportTypeIds.includes(ReportGroup.Fertilizer)}
                isDisplayHerbicideReport={selectedReportTypeIds.includes(ReportGroup.Herbicide)}
                isDisplayNoteReport={selectedReportTypeIds.includes(ReportGroup.Note)}
                isDisplayEnvironmentReport={selectedReportTypeIds.includes(ReportGroup.EnvironmentReport)}
                isDisplayPondReport={selectedReportTypeIds.includes(ReportGroup.PondReport)}
                onClickWorkReport={this.handleClickWorkReport}
                onClickWorkReportNote={this.handleClickWorkReportNote}
                onClickWorkReportImage={this.handleClickWorkReportImage}
                onClickPondReport={this.handleClickPondReport}
                onClickEnvironmentReport={this.handleClickEnvironmentReport}
              />
            </>
          )}
          {reportListState.detailTab === "graph" && (
            <ReportListTemperatureContent
              environmentReports={this.filterEnvironmentReportsForGraph()}
              selectedDate={this.props.reportListState.selectedDate}
              activePeriodType={graphPeriodType}
              onClickPeriod={this.handleClickPeriodType}
            />
          )}
        </BaseNavigationContent>
        <ActionsModal
          isOpen={this.props.reportListState.isOpenDetailActionModal}
          onClose={this.handleCloseActionModal}
          actions={[
            { text: "記録", onClick: this.handleClickShowDetail },
            { text: "グラフ", onClick: this.handleClickShowGraph },
          ]}
        />
      </MobileLayout>
    );
  }

  private filterEnvironmentReportsForGraph = (): NewOrEditEnvironmentReport[] => {
    const { selectedPondId } = this.props.navigationState;
    if (selectedPondId === null) {
      return [];
    }
    const reports = this.props.environmentReports.filter((e) => e.pondId === selectedPondId);
    const { selectedDate, graphPeriodType } = this.props.reportListState;
    return getEnvironmentReportsBasedOnDate(reports, selectedPondId, selectedDate, graphPeriodType);
  };

  private handleOpenActionModal = () => {
    this.props.reportListStateService.openDetailActionModal();
  };

  private handleCloseActionModal = () => {
    this.props.reportListStateService.closeDetailActionModal();
  };

  private handleClickShowDetail = () => {
    this.props.reportListStateService.changeDetailTab("detail");
    this.props.reportListStateService.closeDetailActionModal();
  };

  private handleClickShowGraph = async () => {
    this.props.reportListStateService.changeDetailTab("graph");
    this.props.reportListStateService.closeDetailActionModal();
    await this.props.reportListStateService.fetchEnvironmentReports();
  };

  private handleClickPeriodType = (periodType: GraphPeriodType) => {
    this.props.reportListStateService.changeGraphPeriodType(periodType);
  };

  private handleClickWorkReport = async (
    workReportId: number,
    reportKey: "feed" | "fertilizer" | "herbicide",
    itemId: number
  ) => {
    if (this.state.isMovingPage) {
      return;
    }
    this.setState({ isMovingPage: true });
    const { selectedDate } = this.props.reportListState;
    this.props.navigationService.changeDate(selectedDate);
    await this.props.workReportService.initWorkReport();
    const query = SearchQueryMobile.createMobileQuery("reports");
    if (reportKey === "feed") {
      this.props.feedReportService.selectFeedReport(itemId);
      this.props.history.push(SearchQueryMobile.createURLFromMobileQuery("/reports/feeds", query));
      return;
    }
    if (reportKey === "fertilizer") {
      this.props.fertilizerReportService.selectFertilizerReport(itemId);
      this.props.history.push(SearchQueryMobile.createURLFromMobileQuery("/reports/fertilizers", query));
      return;
    }
    if (reportKey === "herbicide") {
      this.props.herbicideReportService.selectHerbicideReport(itemId);
      this.props.history.push(SearchQueryMobile.createURLFromMobileQuery("/reports/herbicides", query));
      return;
    }
  };

  private handleClickWorkReportNote = async () => {
    if (this.state.isMovingPage) {
      return;
    }
    this.setState({ isMovingPage: true });
    const { selectedDate } = this.props.reportListState;
    this.props.navigationService.changeDate(selectedDate);
    await this.props.workReportService.initWorkReport();
    const query = SearchQueryMobile.createMobileQuery("reports");
    this.props.history.push(SearchQueryMobile.createURLFromMobileQuery("/reports/notes", query));
  };

  private handleClickWorkReportImage = (imageIds: number[], imageId: number) => {
    this.props.showImageGallery(imageIds, imageId);
  };

  private handleClickPondReport = async (
    pondReportId: number,
    reportKey: "disinfection" | "selection" | "moving" | "adultCarp",
    itemId: number
  ) => {
    if (this.state.isMovingPage) {
      return;
    }
    this.setState({ isMovingPage: true });
    this.props.navigationService.changeDate(this.props.reportListState.selectedDate);
    await this.props.pondReportService.selectPondReport(pondReportId);
    const query = SearchQueryMobile.createMobileQuery("reports");
    if (reportKey === "disinfection") {
      this.props.pondReportListService.selectPondDisinfectionReport(itemId);
      this.props.history.push(
        SearchQueryMobile.createURLFromMobileQuery("/reports/pond_reports/pond_disinfection_reports", query)
      );
      return;
    }
    if (reportKey === "selection") {
      this.props.pondReportListService.selectCarpSelectionReport(itemId);
      this.props.history.push(
        SearchQueryMobile.createURLFromMobileQuery("/reports/pond_reports/carp_selection_reports", query)
      );
      return;
    }

    if (reportKey === "adultCarp") {
      this.props.pondReportListService.selectAdultCarpReport(itemId);
      this.props.history.push(
        SearchQueryMobile.createURLFromMobileQuery("/reports/pond_reports/adult_carp_reports", query)
      );
      return;
    }

    if (reportKey === "moving") {
      const pondReport = this.props.pondReports.find((pr) => pr.id === pondReportId);
      const movingReport = pondReport && pondReport.carpMovingReports.find((cmr) => getUniqueKey(cmr) === itemId);
      if (!pondReport || !movingReport) {
        alert("移動時の記録が見つかりませんでした。");
        return;
      }
      const selectionReport = pondReport.carpSelectionReports.find(
        (csr) => csr.selectionNumber === movingReport.selectionNumber
      );
      if (selectionReport) {
        this.props.pondReportListService.selectCarpSelectionReport(getUniqueKey(selectionReport));
        this.props.history.push(
          SearchQueryMobile.createURLFromMobileQuery("/reports/pond_reports/carp_selection_reports", query)
        );
        return;
      }
    }
  };

  private handleClickEnvironmentReport = async () => {
    if (this.state.isMovingPage) {
      return;
    }
    this.setState({ isMovingPage: true });
    this.props.navigationService.changeDate(this.props.reportListState.selectedDate);
    await this.props.environmentReportService.initEnvironmentReport();
    const query = SearchQueryMobile.createMobileQuery("reports");
    this.props.history.push(SearchQueryMobile.createURLFromMobileQuery("/reports/environment_reports", query));
  };

  private handleClickReturn = () => {
    this.props.history.goBack();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const {
    workReport,
    pondReport,
    environmentReport,
    pond,
    parentCarp,
    feed,
    feedReasonType,
    fertilizer,
    herbicide,
    useMethodType,
    scaleType,
  } = state.api;
  return {
    workReports: workReport.workReports,
    pondReports: pondReport.pondReports,
    environmentReports: environmentReport.environmentReports,
    ponds: pond.ponds,
    parentCarps: parentCarp.parentCarps,
    feeds: feed.feeds,
    feedReasonTypes: feedReasonType.feedReasonTypes,
    fertilizers: fertilizer.fertilizers,
    herbicides: herbicide.herbicides,
    useMethodTypes: useMethodType.useMethodTypes,
    scaleTypes: scaleType.scaleTypes,

    reportListState: state.mobile.reportListState,
    navigationState: state.mobile.navigation,
    isLoaded: state.api.pond.isFetched,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    reportListStateService: new ReportListStateService(dispatch),
    navigationService: new NavigationService(dispatch),
    workReportService: new WorkReportService(dispatch),
    feedReportService: new FeedReportStateService(dispatch),
    fertilizerReportService: new FertilizerReportStateService(dispatch),
    herbicideReportService: new HerbicideReportStateService(dispatch),
    pondReportService: new PondReportService(dispatch),
    pondReportListService: new PondReportListService(dispatch),
    environmentReportService: new EnvironmentReportService(dispatch),
    showImageGallery: (imageIds, imageId) =>
      dispatch(imageGalleryStateActions.showImages({ imageIds, initialImageId: imageId })),
  };
};

export const ReportListDetailPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
