import { actionCreatorFactory } from "typescript-fsa";
import { UniqueKey } from "../../../../../domain/entity";

// Actions
const actionCreator = actionCreatorFactory("MobilePondReportListState");

const initialStart = actionCreator("initialStart");
const initialComplete = actionCreator("initialComplete");
const initialFailed = actionCreator("initialFailed");

const selectPondDisinfectionReport = actionCreator<SelectPondDisinfectionReportPayload>("selectPondDisinfectionReport");
interface SelectPondDisinfectionReportPayload {
  selectedUniqueKey: UniqueKey;
}
const selectCarpSelectionReport = actionCreator<SelectCarpSelectionReportPayload>("selectCarpSelectionReport");
interface SelectCarpSelectionReportPayload {
  selectedUniqueKey: UniqueKey;
}

const selectAdultCarpReport = actionCreator<SelectAdultCarpReportPayload>("selectAdultCarpReport");
interface SelectAdultCarpReportPayload {
  selectedUniqueKey: UniqueKey;
}

export const pondReportListStateActions = {
  initialStart,
  initialComplete,
  initialFailed,
  selectPondDisinfectionReport,
  selectCarpSelectionReport,
  selectAdultCarpReport,
};

// Reducers
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { getInitialState, InitialHandler, ListStateType, SelectHandler } from "./reducer";

export const pondReportListStateReducer = reducerWithInitialState<ListStateType>(getInitialState())
  .case(initialStart, (state) => InitialHandler.initialStart(state))
  .case(initialComplete, (state) => InitialHandler.initialComplete(state))
  .case(initialFailed, (state) => InitialHandler.initialFailed(state))
  .case(selectPondDisinfectionReport, (state, { selectedUniqueKey }) =>
    SelectHandler.selectPondDisinfectionReport(state, selectedUniqueKey)
  )
  .case(selectCarpSelectionReport, (state, { selectedUniqueKey }) =>
    SelectHandler.selectCarpSelectionReport(state, selectedUniqueKey)
  )
  .case(selectAdultCarpReport, (state, { selectedUniqueKey }) =>
    SelectHandler.selectAdultCarpReport(state, selectedUniqueKey)
  );
