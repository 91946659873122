import { NewOrEditAdultCarpReport } from "../../../../../domain/adultCarpReport";
import { NewCarpMovingReport, NewOrEditCarpMovingReport } from "../../../../../domain/carpMovingReport";
import { NewOrEditCarpSelectionReport } from "../../../../../domain/carpSelectionReport";
import { getUniqueKey, isNewEntity, UniqueKey } from "../../../../../domain/entity";
import { NewOrEditPondDisinfectionReport } from "../../../../../domain/pondDisinfectionReport";
import { NewOrEditPondReport, PondReport } from "../../../../../domain/pondReport";
import { EditLifeCycle } from "../../../../lifeCycle/editLifeCycle";

export interface NewOrEditStateType extends EditLifeCycle {
  pondReport: NewOrEditPondReport | null;
}

export const getInitialState = (): NewOrEditStateType => {
  return {
    pondReport: null,
    isSaving: false,
    isUnSave: false,
    isSaved: false,
    isError: null,
  };
};

export const InitialHandler = {
  initialStart(state: NewOrEditStateType): NewOrEditStateType {
    return { ...state, pondReport: null, isSaving: false, isUnSave: false, isSaved: false, isError: null };
  },
  initialComplete(state: NewOrEditStateType, pondReport: NewOrEditPondReport): NewOrEditStateType {
    return { ...state, pondReport, isUnSave: true };
  },
  initialFailed(state: NewOrEditStateType): NewOrEditStateType {
    return {
      ...state,
      isSaving: false,
      isUnSave: false,
      isSaved: false,
      isError: { message: "Initialization is failed." },
    };
  },
  resetState(state: NewOrEditStateType): NewOrEditStateType {
    return {
      ...state,
      ...getInitialState(),
    };
  },
};

export const ChangeHandler = {
  changePondReport(state: NewOrEditStateType, key: keyof NewOrEditPondReport, value: any): NewOrEditStateType {
    if (state.pondReport === null) {
      return state;
    }
    return { ...state, pondReport: { ...state.pondReport, [key]: value }, isUnSave: true };
  },
  changeCarpMovingReport(
    state: NewOrEditStateType,
    movingUniqueKey: UniqueKey,
    key: keyof NewOrEditCarpMovingReport,
    value: any
  ): NewOrEditStateType {
    if (state.pondReport === null) {
      return state;
    }
    const mapCarpMovingReports = (cmr: NewOrEditCarpMovingReport) => {
      const uk = getUniqueKey(cmr);
      if (uk !== movingUniqueKey) {
        return cmr;
      }
      return {
        ...cmr,
        [key]: value,
      };
    };
    const carpMovingReports = isNewEntity(state.pondReport)
      ? state.pondReport.carpMovingReports.map(mapCarpMovingReports)
      : state.pondReport.carpMovingReports.map(mapCarpMovingReports);

    const pondReport = { ...state.pondReport, carpMovingReports } as NewOrEditPondReport;
    return { ...state, pondReport };
  },
  changeCarpSelectionReport(
    state: NewOrEditStateType,
    selectionUniqueKey: UniqueKey,
    key: keyof NewOrEditCarpSelectionReport,
    value: any
  ): NewOrEditStateType {
    if (state.pondReport === null) {
      return state;
    }
    const mapCarpSelectionReports = (csr: NewOrEditCarpSelectionReport) => {
      const uk = getUniqueKey(csr);
      if (uk !== selectionUniqueKey) {
        return csr;
      }
      return {
        ...csr,
        [key]: value,
      };
    };

    const carpSelectionReports = isNewEntity(state.pondReport)
      ? state.pondReport.carpSelectionReports.map(mapCarpSelectionReports)
      : state.pondReport.carpSelectionReports.map(mapCarpSelectionReports);
    const pondReport = { ...state.pondReport, carpSelectionReports } as NewOrEditPondReport;
    return { ...state, pondReport };
  },
  changePondDisinfectionReport(
    state: NewOrEditStateType,
    disinfectionUniqueKey: UniqueKey,
    key: keyof NewOrEditPondDisinfectionReport,
    value: any
  ): NewOrEditStateType {
    if (state.pondReport === null) {
      return state;
    }
    const mapPondDisinfectionReports = (pdr: NewOrEditPondDisinfectionReport) => {
      const uk = getUniqueKey(pdr);
      if (uk !== disinfectionUniqueKey) {
        return pdr;
      }
      return {
        ...pdr,
        [key]: value,
      };
    };
    const pondDisinfectionReports = isNewEntity(state.pondReport)
      ? state.pondReport.pondDisinfectionReports.map(mapPondDisinfectionReports)
      : state.pondReport.pondDisinfectionReports.map(mapPondDisinfectionReports);
    const pondReport = { ...state.pondReport, pondDisinfectionReports } as NewOrEditPondReport;
    return { ...state, pondReport };
  },
  changeAdultCarpReport(
    state: NewOrEditStateType,
    adultUniqueKey: UniqueKey,
    key: keyof NewOrEditAdultCarpReport,
    value: any
  ): NewOrEditStateType {
    if (state.pondReport === null) {
      return state;
    }
    const mapAdultCarpReports = (csr: NewOrEditAdultCarpReport) => {
      const uk = getUniqueKey(csr);
      if (uk !== adultUniqueKey) {
        return csr;
      }
      return {
        ...csr,
        [key]: value,
      };
    };

    const adultCarpReports = isNewEntity(state.pondReport)
      ? state.pondReport.adultCarpReports.map(mapAdultCarpReports)
      : state.pondReport.adultCarpReports.map(mapAdultCarpReports);
    const pondReport = { ...state.pondReport, adultCarpReports } as NewOrEditPondReport;
    return { ...state, pondReport };
  },
  addCarpMovingReport(state: NewOrEditStateType, carpMovingReport: NewCarpMovingReport): NewOrEditStateType {
    const { pondReport } = state;
    if (pondReport === null) {
      return state;
    }
    let carpMovingReports: NewOrEditCarpMovingReport[] = [];
    if (pondReport.carpMovingReports.some(isNewEntity)) {
      const mapCarpMovingReport = (cmr: NewOrEditCarpMovingReport) => (isNewEntity(cmr) ? carpMovingReport : cmr);
      carpMovingReports = isNewEntity(pondReport)
        ? pondReport.carpMovingReports.map(mapCarpMovingReport)
        : pondReport.carpMovingReports.map(mapCarpMovingReport);
    } else {
      carpMovingReports = pondReport.carpMovingReports.concat(carpMovingReport);
    }
    return { ...state, pondReport: { ...pondReport, carpMovingReports } as NewOrEditPondReport };
  },
};

export const SaveHandler = {
  saveStart(state: NewOrEditStateType): NewOrEditStateType {
    return { ...state, isSaving: true, isSaved: false, isUnSave: true, isError: null };
  },
  saveSuccess(state: NewOrEditStateType, pondReport: PondReport): NewOrEditStateType {
    return {
      ...state,
      pondReport,
      isSaving: false,
      isSaved: true,
      isUnSave: false,
      isError: null,
    };
  },
  deleteSuccess(state: NewOrEditStateType): NewOrEditStateType {
    return { ...state, pondReport: null, isSaving: false, isSaved: true, isUnSave: false, isError: null };
  },
  saveFail(state: NewOrEditStateType): NewOrEditStateType {
    return { ...state, isSaving: false, isUnSave: true, isSaved: false, isError: null };
  },
};
