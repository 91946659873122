import { Dispatch } from "redux";
import { Pond } from "../../../domain/pond";
import { PondArea } from "../../../domain/pondArea";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { PondRepository } from "../../../infrastracture/pond/repository";
import { ApplicationState } from "../../../store/modules";
import { apiPondActions } from "../../../store/modules/api/pond/ducks";

interface IPondApiService extends IApplicationService {
  init: () => void;
  fetchUnFetched: () => void;
  updateByPondArea: (pondArea: PondArea) => void;
}

export class PondApiService implements IPondApiService {
  public constructor(private readonly dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError((dispatch: Dispatch) => dispatch(apiPondActions.fetchFail()))
  public async init() {
    this.dispatch(apiPondActions.fetchStart());
    const ponds: Pond[] = await new PondRepository().getPonds();
    this.dispatch(apiPondActions.fetchComplete({ ponds }));
  }

  @catchApplicationError((dispatch: Dispatch) => dispatch(apiPondActions.fetchFail()))
  public async fetchUnFetched() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      if (getState().api.pond.isFetched) {
        return;
      }
      this.dispatch(apiPondActions.fetchStart());
      const ponds = await new PondRepository().getPonds();
      this.dispatch(apiPondActions.fetchComplete({ ponds }));
    });
  }

  @catchApplicationError()
  public updateByPondArea(pondArea: PondArea) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const replacedPonds = state.api.pond.ponds.map((p) => {
        if (pondArea.pondIds.includes(p.id)) {
          return { ...p, pondAreaId: pondArea.id };
        }
        if (p.pondAreaId === pondArea.id) {
          return { ...p, pondAreaId: null };
        }
        return p;
      });
      this.dispatch(apiPondActions.replacePonds({ ponds: replacedPonds }));
    });
  }
}
