import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { NewStateService } from "../../../application/master/fertilizer/newState";
import { ApplicationState } from "../../../store/modules";
import { NewOrEditStateType } from "../../../store/modules/master/fertilizer/newOrEditState/reducer";
import { FertilizerForm } from "../../components/organisms/FertilizerForm";

interface StateProps {
  newOrEditState: NewOrEditStateType;
}

interface DispatchProps {
  newStateService: NewStateService;
}

type FertilizerNewPageProps = StateProps & DispatchProps;

export class Wrapped extends React.Component<FertilizerNewPageProps> {
  public render() {
    const { fertilizer, isSaving } = this.props.newOrEditState;
    return (
      <FertilizerForm
        fertilizer={fertilizer}
        isSaving={isSaving}
        onChangeName={this.handleChangeName}
        onClickSave={this.handleClickSaveFertilizer}
        onClickCancel={this.handleClickCancelFertilizer}
        onClickAdd={this.handleClickAddFertilizer}
        onClickDelete={null}
      />
    );
  }

  private handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.newStateService.changeName(e.target.value);
  };

  private handleClickSaveFertilizer = () => {
    this.props.newStateService.saveFertilizer();
  };

  private handleClickCancelFertilizer = () => {
    this.props.newStateService.cancelFertilizer();
  };

  private handleClickAddFertilizer = () => {
    this.props.newStateService.createFertilizer();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    newOrEditState: state.master.fertilizer.newOrEditState,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    newStateService: new NewStateService(dispatch),
  };
};

export const FertilizerNewPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);
