import { EditWorker, NewWorker, Worker } from "../../domain/worker";
import * as api from "../../libs/client";
import { translateApiToWorker, translateWorkerToApi } from "./translate";

interface IWorkerRepository {
  getWorkers: () => Promise<Worker[]>;
  getWorkerMe: () => Promise<Worker>;
  postWorker: (worker: NewWorker) => Promise<Worker>;
  putWorker: (worker: EditWorker) => Promise<Worker>;
  deleteWorker: (worker: Worker) => Promise<Worker>;
}

export class WorkerRepository implements IWorkerRepository {
  public async getWorkers(): Promise<Worker[]> {
    const workersApi = new api.WorkersApi(api.serverConfig);
    const response = await workersApi.getWorkers();
    return response.data.map((d) => translateApiToWorker(d));
  }

  public async getWorkerMe(): Promise<Worker> {
    const workersApi = new api.WorkersApi(api.serverConfig);
    const response = await workersApi.getWorkerMe();
    return translateApiToWorker(response.data);
  }

  public async postWorker(worker: NewWorker): Promise<Worker> {
    const workersApi = new api.WorkersApi(api.serverConfig);
    const response = await workersApi.postWorker(translateWorkerToApi(worker));
    return translateApiToWorker(response.data);
  }

  public async putWorker(worker: EditWorker): Promise<Worker> {
    const workersApi = new api.WorkersApi(api.serverConfig);
    const response = await workersApi.putWorker(worker.id, translateWorkerToApi(worker));
    return translateApiToWorker(response.data);
  }

  public async deleteWorker(worker: EditWorker): Promise<Worker> {
    const workersApi = new api.WorkersApi(api.serverConfig);
    const response = await workersApi.deleteWorker(worker.id, translateWorkerToApi(worker));
    return translateApiToWorker(response.data);
  }
}
