import { default as moment } from "moment";
import { Dispatch } from "redux";
import { Image } from "../../../domain/image";
import { createNewWorkReport, isEditWorkReport, WorkReportValidator } from "../../../domain/workReport";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { WorkReportRepository } from "../../../infrastracture/workReport/repository";
import { ApplicationState } from "../../../store/modules";
import { apiWorkReportActions } from "../../../store/modules/api/workReport/ducks";
import { notificationAlertStateActions } from "../../../store/modules/notification/alert/ducks";
import { workReportNewOrEditStateActions } from "../../../store/modules/report/workReport/newOrEditState/ducks";
import { ImageApiService } from "../../api/image";
import { WorkReportApiService } from "../../api/workReport";

interface INewOrEditStateService extends IApplicationService {
  initWorkReport: () => void;
  changeNote: (note: string) => void;
  changePondType: (pondTypeId: number) => void;
  addImage: (file: File) => void;
  removeImage: (imageId: number) => void;
  deleteFeedReport: () => void;
  deleteFertilizerReport: () => void;
  deleteHerbicideReport: () => void;
  cancelWorkReport: () => void;
  saveWorkReport: () => void;
}

export class NewOrEditStateService implements INewOrEditStateService {
  private workReportApiService: WorkReportApiService;
  private imageApiService: ImageApiService;

  public constructor(private dispatch: Dispatch<any>) {
    this.workReportApiService = new WorkReportApiService(dispatch);
    this.imageApiService = new ImageApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public async initWorkReport() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedPondId, selectedDate } = state.report.navigation;
      const pond = state.api.pond.ponds.find((p) => p.id === selectedPondId);
      if (!pond) {
        return;
      }
      const selectedMomentDate = moment(selectedDate);
      await this.workReportApiService.fetchReportByDate(selectedDate, pond.id);
      const fetchedState = getState();
      const workReport = fetchedState.api.workReport.workReports.find((wr) => {
        const dateOfReport = moment(wr.date);
        return pond.id === wr.pondId && dateOfReport.isSame(selectedMomentDate, "date");
      });
      const user = state.auth.user;
      if (user === null) {
        throw new ApplicationError("不正な操作です。");
      }
      if (pond.pondTypeId === null) {
        throw new ApplicationError("池の区分が設定されていません。");
      }
      this.dispatch(
        workReportNewOrEditStateActions.initialWorkReport({
          workReport: workReport || createNewWorkReport(pond.id, user.id, pond.pondTypeId, selectedDate),
        })
      );
    });
  }

  @catchApplicationError()
  public changeNote(note: string) {
    this.dispatch(workReportNewOrEditStateActions.changeWorkReport({ key: "note", value: note }));
  }

  @catchApplicationError()
  public changePondType(pondTypeId: number) {
    this.dispatch(workReportNewOrEditStateActions.changeWorkReport({ key: "pondTypeId", value: pondTypeId }));
  }

  @catchApplicationError()
  public async addImage(file: File) {
    await this.imageApiService.uploadImage(
      file,
      (image: Image) => {
        this.dispatch(workReportNewOrEditStateActions.addImage({ imageId: image.id }));
      },
      () => {
        // TODO: loading中を作るのであれば...
      }
    );
  }

  @catchApplicationError()
  public removeImage(imageId: number) {
    this.dispatch(workReportNewOrEditStateActions.removeImage({ imageId }));
  }

  @catchApplicationError()
  public async deleteFeedReport() {
    this.dispatch(workReportNewOrEditStateActions.removeFeedReport());
    await this.saveWorkReport();
  }

  @catchApplicationError()
  public async deleteFertilizerReport() {
    this.dispatch(workReportNewOrEditStateActions.removeFertilizerReport());
    await this.saveWorkReport();
  }

  @catchApplicationError()
  public async deleteHerbicideReport() {
    this.dispatch(workReportNewOrEditStateActions.removeHerbicideReport());
    await this.saveWorkReport();
  }

  @catchApplicationError()
  public cancelWorkReport() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedPondId, selectedDate } = state.report.navigation;
      const pond = state.api.pond.ponds.find((p) => p.id === selectedPondId);
      if (!pond) {
        return;
      }
      const selectedMomentDate = moment(selectedDate);
      const workReport = state.api.workReport.workReports.find((wr) => {
        const dateOfReport = moment(wr.date);
        return pond.id === wr.pondId && dateOfReport.isSame(selectedMomentDate, "date");
      });
      const user = state.auth.user;
      if (user === null) {
        throw new ApplicationError("不正な操作です。");
      }
      if (pond.pondTypeId === null) {
        throw new ApplicationError("池の区分が設定されていません。");
      }
      this.dispatch(
        workReportNewOrEditStateActions.initialWorkReport({
          workReport: workReport || createNewWorkReport(pond.id, user.id, pond.pondTypeId, selectedDate),
        })
      );
    });
  }

  @catchApplicationError((dispatch) => dispatch(workReportNewOrEditStateActions.saveFail()))
  public async saveWorkReport() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(workReportNewOrEditStateActions.saveStart());
      const state = getState();
      const { newOrEditStateType } = state.report.workReport;
      const workReport = newOrEditStateType.workReport;
      if (workReport === null) {
        throw new ApplicationError("記録が見つかりませんでした。");
      }
      const validator = new WorkReportValidator(state.api.useMethodType.useMethodTypes);
      validator.validate(workReport);
      if (!validator.isValid()) {
        this.dispatch(notificationAlertStateActions.showErrorMessage({ errorMessage: validator.getMessages() }));
        this.dispatch(workReportNewOrEditStateActions.saveFail());
        return;
      }

      if (isEditWorkReport(workReport)) {
        const updatedReport = await new WorkReportRepository().putWorkReport(workReport);
        this.dispatch(workReportNewOrEditStateActions.saveSuccess({ workReport: updatedReport }));
        this.dispatch(apiWorkReportActions.updateWorkReport({ workReport: updatedReport }));
        return;
      }
      const createdReport = await new WorkReportRepository().postWorkReport(workReport);
      this.dispatch(workReportNewOrEditStateActions.saveSuccess({ workReport: createdReport }));
      this.dispatch(apiWorkReportActions.createWorkReport({ workReport: createdReport }));
    });
  }
}
