import * as React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";
import { COLOR } from "../../../styles/Color";
import { DIMEN } from "../../../styles/Dimen";
import { FormTransparentDatePicker } from "../../atoms/Form/DatePicker";
import { PageSection } from "../../atoms/Section";
import { MobileListItem } from "../../molecules/MobileList";

interface PondReportMenuProps {
  selectedDate: Date;
  onClickNextPond: () => void;
  onClickPrevPond: () => void;
  onChangeDate: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickFeed: () => void;
  onClickWaterTemperature: () => void;
  onClickFertilizer: () => void;
  onClickHerbicide: () => void;
  onClickNote: () => void;
  onClickPondReport: () => void;
  onClickEnvironment: () => void;
  onClickReportList: () => void;
}

export const WorkReportMenu = (props: PondReportMenuProps) => {
  return (
    <>
      <PageSection className={"large"}>
        <DateOuter>
          <FormTransparentDatePicker date={props.selectedDate} onChange={props.onChangeDate} />
        </DateOuter>
        <DateSuffix>の記録</DateSuffix>
      </PageSection>
      <List>
        <ButtonWrapper>
          <ListItem isNext={false} onClick={props.onClickPrevPond}>
            <PrevIcon />
            前の池
          </ListItem>
          <ListItem isNext={true} onClick={props.onClickNextPond}>
            次の池
            <NextIcon />
          </ListItem>
        </ButtonWrapper>
        <MobileListItem className={"large"} onClick={props.onClickFeed}>
          給餌を記録
        </MobileListItem>
        <MobileListItem className={"large"} onClick={props.onClickWaterTemperature}>
          水温を記録
        </MobileListItem>
        <MobileListItem className={"large"} onClick={props.onClickFertilizer}>
          肥料を記録
        </MobileListItem>
        <MobileListItem className={"large"} onClick={props.onClickHerbicide}>
          除草（池）・薬品を記録
        </MobileListItem>
        <MobileListItem className={"large"} onClick={props.onClickNote}>
          作業メモを記録
        </MobileListItem>
        <MobileListItem className={"large"} onClick={props.onClickPondReport}>
          選別・放鯉・池揚げを記録
        </MobileListItem>
        <MobileListItem className={"large"} onClick={props.onClickEnvironment}>
          環境記録を見る
        </MobileListItem>
        <MobileListItem className={"large"} onClick={props.onClickReportList}>
          この野池の記録を見る
        </MobileListItem>
      </List>
    </>
  );
};

export const NextIcon = styled(Icon).attrs({
  name: "arrow right",
  size: "large",
})`
  color: ${COLOR.Base.black} !important;
  margin-left: ${DIMEN.X0_5} !important;
`;

export const PrevIcon = styled(Icon).attrs({
  name: "arrow left",
  size: "large",
})`
  color: ${COLOR.Base.black} !important;
  margin-right: ${DIMEN.X0_5} !important;
`;

const ButtonWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${COLOR.Gray.base};
`;
const ListItem = styled.div`
  width: 100%;
  padding: ${DIMEN.X2} ${DIMEN.X3} ${DIMEN.X2} ${DIMEN.X2};
  cursor: pointer;
  position: relative;
  :hover {
    background-color: ${COLOR.Gray["lighter-3"]};
  }
  text-align: ${({ isNext }: { isNext: boolean }) => isNext ? "right" : "left" };
  border-left: ${({ isNext }: { isNext: boolean }) => isNext && `1px solid ${COLOR.Gray.base}`};;
`;

const DateOuter = styled.div`
  margin-right: ${DIMEN.X1};
  display: inline-block;
`;

const DateSuffix = styled.div`
  font-size: 12px;
  display: inline-block;
`;

const List = styled.div``;
