import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NavigationService } from "../../../../application/mobile/navigation";
import { HerbicideReportStateService } from "../../../../application/mobile/workReport/herbicideReportState";
import { NewOrEditStateService } from "../../../../application/mobile/workReport/newOrEditState";
import { getUniqueKey, UniqueKey } from "../../../../domain/entity";
import { Herbicide } from "../../../../domain/herbicide";
import { HerbicideReport } from "../../../../domain/herbicideReport";
import { Pond } from "../../../../domain/pond";
import { NewOrEditWorkReport } from "../../../../domain/workReport";
import { ApplicationState } from "../../../../store/modules";
import { NavigationStateType } from "../../../../store/modules/mobile/navigation/reducer";
import { AddButton } from "../../../components/atoms/Button/Mobile";
import { PageSection } from "../../../components/atoms/Section";
import { Spinner } from "../../../components/atoms/Spinner";
import { MobileList } from "../../../components/molecules/MobileList";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { MobileLayout } from "../../Layout/Mobile";

interface StateProps {
  ponds: Pond[];
  herbicides: Herbicide[];
  navigationState: NavigationStateType;
}

interface OwnProps {
  workReport: NewOrEditWorkReport | null;
}

interface DispatchProps {
  newOrEditStateService: NewOrEditStateService;
  navigationService: NavigationService;
  herbicideReportStateService: HerbicideReportStateService;
}

type WorkReportHerbicidePageProps = StateProps & OwnProps & DispatchProps & RouteComponentProps;

export class Wrapped extends React.Component<WorkReportHerbicidePageProps> {
  public componentWillMount(): void {
    if (this.props.navigationState.selectedPondId === null) {
      this.props.history.push("/");
      return;
    }
  }

  public render() {
    const pond = this.props.ponds.find((p) => p.id === this.props.navigationState.selectedPondId);
    const { workReport } = this.props;

    if (!pond || !workReport) {
      return (
        <MobileLayout>
          <BaseNavigationHeader title={""} onClickReturn={this.handleClickReturn} />
          <BaseNavigationContent>
            <Spinner />
          </BaseNavigationContent>
        </MobileLayout>
      );
    }
    const items = (workReport.herbicideReports as HerbicideReport[]).map((fr) => {
      const herbicide = this.props.herbicides.find((f) => f.id === fr.herbicideId);
      return { id: getUniqueKey(fr), name: herbicide ? herbicide.name : "" };
    });
    return (
      <MobileLayout>
        <BaseNavigationHeader title={pond.name} onClickReturn={this.handleClickReturn} />
        <BaseNavigationContent>
          <PageSection>除草剤（池）・薬品</PageSection>
          <MobileList itemClassName={"large"} items={items} onClickItem={this.handleClickHerbicide} />
          <AddButton onClick={this.handleClickAddHerbicide} />
        </BaseNavigationContent>
      </MobileLayout>
    );
  }
  private handleClickHerbicide = (key: UniqueKey) => {
    this.props.herbicideReportStateService.selectHerbicideReport(key);
  };

  private handleClickAddHerbicide = () => {
    this.props.herbicideReportStateService.addHerbicideReport();
  };

  private handleClickReturn = () => {
    this.props.history.goBack();
  };
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StateProps & OwnProps => {
  const { pond, herbicide } = state.api;
  return {
    ponds: pond.ponds,
    herbicides: herbicide.herbicides,
    navigationState: state.mobile.navigation,
    workReport: ownProps.workReport,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    newOrEditStateService: new NewOrEditStateService(dispatch),
    navigationService: new NavigationService(dispatch),
    herbicideReportStateService: new HerbicideReportStateService(dispatch),
  };
};

export const WorkReportHerbicideListPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
