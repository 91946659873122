import * as _ from "lodash";
import { default as moment } from "moment";

export function getBeforeDate(date: Date, beforeDateNumber: number): Date {
  return moment(date)
    .subtract(beforeDateNumber, "days")
    .toDate();
}

export function getAfterDate(date: Date, afterDateNumber: number): Date {
  return moment(date)
    .add(afterDateNumber, "days")
    .toDate();
}

export function getStartOfMonth(date: Date): Date {
  return moment(date)
    .startOf("month")
    .toDate();
}

export function getEndOfMonth(date: Date): Date {
  return moment(date)
    .endOf("month")
    .toDate();
}

export function getPeriodDates(startDate: Date, endDate: Date): Date[] {
  const start = moment(startDate);
  const end = moment(endDate);
  const diff = end.diff(start, "days");
  if (diff < 0) {
    return [];
  }
  return _.range(0, diff + 1).map((num) =>
    start
      .clone()
      .add(num, "days")
      .toDate()
  );
}

export function getMonthDates(date: Date): Date[] {
  const startDate = getStartOfMonth(date);
  const endDate = getEndOfMonth(date);
  return getPeriodDates(startDate, endDate);
}

export function isSameDate(dateA: Date, dateB: Date): boolean {
  return moment(dateA).isSame(moment(dateB), "dates");
}

/**
 * 対象年度の取得
 * @param date
 */
export function getFiscalYear(date: Date): number {
  const m = moment(date);
  return m.month() < 3 ? m.year() - 1 : m.year();
}

export function getFiscalStartDate(fiscalYear: number): Date {
  return moment()
    .year(fiscalYear)
    .month(4 - 1)
    .startOf("month")
    .toDate();
}

export function getFiscalEndDate(fiscalYear: number): Date {
  return moment()
    .year(fiscalYear + 1)
    .month(3 - 1)
    .endOf("month")
    .toDate();
}

export function isSameFiscalYear(dateA: Date, dateB: Date): boolean {
  return getFiscalYear(dateA) === getFiscalYear(dateB);
}
