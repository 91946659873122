import { Dispatch } from "redux";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { PondApiService } from "../../api/pond";
import { PondAreaApiService } from "../../api/pondArea";
import { PondOwnerTypeApiService } from "../../api/pondOwnerType";
import { PondTypeApiService } from "../../api/pondType";
import { WorkerApiService } from "../../api/worker";

interface IReadApiService extends IApplicationService {
  init: () => void;
}

export class ReadApiService implements IReadApiService {
  private pondApiService: PondApiService;
  private pondAreaApiService: PondAreaApiService;
  private pondOwnerTypeApiService: PondOwnerTypeApiService;
  private pondTypeApiService: PondTypeApiService;
  private workerApiService: WorkerApiService;

  public constructor(private dispatch: Dispatch<any>) {
    this.pondApiService = new PondApiService(dispatch);
    this.pondAreaApiService = new PondAreaApiService(dispatch);
    this.pondTypeApiService = new PondTypeApiService(dispatch);
    this.pondOwnerTypeApiService = new PondOwnerTypeApiService(dispatch);
    this.workerApiService = new WorkerApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public async init() {
    await Promise.all([
      this.pondApiService.init(),
      this.pondAreaApiService.init(),
      this.pondOwnerTypeApiService.fetchUnFetched(),
      this.pondTypeApiService.fetchUnFetched(),
      this.workerApiService.init(),
    ]);
  }
}
