import { Dispatch } from "redux";
import { Herbicide } from "../../../domain/herbicide";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { HerbicideRepository } from "../../../infrastracture/herbicide/repository";
import { ApplicationState } from "../../../store/modules";
import { apiHerbicideActions } from "../../../store/modules/api/herbicide/ducks";

interface IHerbicideApiService extends IApplicationService {
  init: () => void;
  fetchUnFetched: () => void;
}

export class HerbicideApiService implements IHerbicideApiService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError((dispatch) => dispatch(apiHerbicideActions.fetchFail()))
  public async init() {
    this.dispatch(apiHerbicideActions.fetchStart());
    const herbicides: Herbicide[] = await new HerbicideRepository().getHerbicides();
    this.dispatch(apiHerbicideActions.fetchComplete({ herbicides }));
  }

  @catchApplicationError((dispatch) => dispatch(apiHerbicideActions.fetchFail()))
  public async fetchUnFetched() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      if (getState().api.herbicide.isFetched) {
        return;
      }
      this.dispatch(apiHerbicideActions.fetchStart());
      const herbicides: Herbicide[] = await new HerbicideRepository().getHerbicides();
      this.dispatch(apiHerbicideActions.fetchComplete({ herbicides }));
    });
  }
}
