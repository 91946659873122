export enum PondClass {
  All = 0,
  Fry,
  Adult,
}

export function isFryPondClass(entity: { pondClassId: null | number }): boolean {
  return entity.pondClassId === PondClass.All || entity.pondClassId === PondClass.Fry;
}

export function isAdultPondClass(entity: { pondClassId: null | number }): boolean {
  return entity.pondClassId === PondClass.All || entity.pondClassId === PondClass.Adult;
}
