import { combineReducers } from "redux";
import { MoveModalStateType } from "./moveModalState/reducer";
import { NewOrEditStateType } from "./newOrEditState/reducer";
import { PairReportModalStateType } from "./pairReportModalState/reducer";

export interface ReportPondReportState {
  moveModalState: MoveModalStateType;
  newOrEditState: NewOrEditStateType;
  pairReportModalState: PairReportModalStateType;
}

import { MoveModalStateReducer } from "./moveModalState/ducks";
import { NewOrEditStateReducer } from "./newOrEditState/ducks";
import { PairReportModalStateReducer } from "./pairReportModalState/ducks";

export const pondReportReducer = combineReducers({
  moveModalState: MoveModalStateReducer,
  newOrEditState: NewOrEditStateReducer,
  pairReportModalState: PairReportModalStateReducer,
});
