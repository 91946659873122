import { Dispatch } from "redux";
import { createNewPondArea, PondAreaValidator } from "../../../domain/pondArea";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { PondAreaRepository } from "../../../infrastracture/pondArea/repository";
import { ApplicationState } from "../../../store/modules";
import { apiPondAreaActions } from "../../../store/modules/api/pondArea/ducks";
import { masterPondAreaNewOrEditStateActions } from "../../../store/modules/master/pondArea/newOrEditState/ducks";
import { notificationAlertStateActions } from "../../../store/modules/notification/alert/ducks";
import { notificationQueueStateActions } from "../../../store/modules/notification/queue/ducks";
import { PondApiService } from "../../api/pond";

interface INewStateService extends IApplicationService {
  createPondArea: () => void;
  changeName: (name: string) => void;
  changePonds: (pondIds: number[]) => void;
  cancelPondArea: () => void;
  savePondArea: () => void;
}

export class NewStateService implements INewStateService {
  private readonly pondApiService: PondApiService;

  public constructor(private dispatch: Dispatch<any>) {
    this.pondApiService = new PondApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public createPondArea() {
    this.dispatch(masterPondAreaNewOrEditStateActions.createPondArea({ pondArea: createNewPondArea() }));
  }

  @catchApplicationError()
  public changeName(name: string) {
    this.dispatch(masterPondAreaNewOrEditStateActions.changePondArea({ key: "name", value: name }));
  }

  @catchApplicationError()
  public changePonds(pondIds: number[]) {
    this.dispatch(masterPondAreaNewOrEditStateActions.changePondArea({ key: "pondIds", value: pondIds }));
  }

  @catchApplicationError()
  public cancelPondArea() {
    this.dispatch(masterPondAreaNewOrEditStateActions.cancelPondArea({ pondArea: createNewPondArea() }));
  }

  @catchApplicationError((dispatch) => dispatch(masterPondAreaNewOrEditStateActions.saveFail()))
  public async savePondArea() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(masterPondAreaNewOrEditStateActions.saveStart());
      const { newOrEditState } = getState().master.pondArea;
      const newPondArea = newOrEditState.pondArea;
      if (newPondArea === null) {
        throw new ApplicationError("エリアが見つかりませんでした。");
      }
      const validator = new PondAreaValidator();
      validator.validate(newPondArea);
      if (!validator.isValid()) {
        this.dispatch(notificationAlertStateActions.showErrorMessage({ errorMessage: validator.getMessages() }));
        this.dispatch(masterPondAreaNewOrEditStateActions.saveFail());
        return;
      }
      const savedPondArea = await new PondAreaRepository().postPondArea(newPondArea);
      this.dispatch(masterPondAreaNewOrEditStateActions.saveSuccess({ pondArea: savedPondArea }));
      this.dispatch(apiPondAreaActions.createPondArea({ pondArea: savedPondArea }));
      this.pondApiService.updateByPondArea(savedPondArea);
      this.dispatch(notificationQueueStateActions.addSaveMessage({ itemName: "エリア" }));
    });
  }
}
