import { Header, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { COLOR } from "../../../styles/Color";
import { DIMEN } from "../../../styles/Dimen";

export const Outer = styled.div`
  position: relative;
  background-color: ${COLOR.Primary.base};
  padding: ${DIMEN.X1} 0;
`;

export const MenuButton = styled(Icon).attrs({
  name: "bars",
  size: "large",
})`
  color: ${COLOR.Base.white} !important;
`;

export const ReturnButton = styled(Icon).attrs({
  name: "arrow left",
  size: "large",
})`
  color: ${COLOR.Base.white} !important;
`;

export const ActionButton = styled(Icon).attrs({
  name: "ellipsis vertical",
  size: "large",
})`
  color: ${COLOR.Base.white} !important;
`;

export const FilterButton = styled(Icon).attrs({
  name: "filter",
  size: "large",
})`
  color: ${COLOR.Base.white} !important;
`;

export const LeftButtonOuter = styled.div`
  position: absolute;
  top: ${DIMEN.X1};
  left: ${DIMEN.X1};
  height: calc(100% - ${DIMEN.X1} * 2);
  display: flex;
  align-items: center;
`;

export const RightButtonOuter = styled.div`
  position: absolute;
  top: ${DIMEN.X1};
  right: ${DIMEN.X1};
  height: calc(100% - ${DIMEN.X1} * 2);
  display: flex;
  align-items: center;
`;

export const Title = styled(Header).attrs({ as: "h2" })`
  color: ${COLOR.Base.white};
  margin: 0 !important;
  text-align: center;
  min-height: 1em;
`;

export const MOBILE_NAVIGATION_HEADER_HEIGHT = "46px";
