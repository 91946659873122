import { Dispatch } from "redux";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { FertilizerApiService } from "../../api/fertilizer";

interface IReadApiService extends IApplicationService {
  init: () => void;
}

export class ReadApiService implements IReadApiService {
  private fertilizerApiService: FertilizerApiService;
  public constructor(private dispatch: Dispatch<any>) {
    this.fertilizerApiService = new FertilizerApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public async init() {
    await this.fertilizerApiService.init();
  }
}
