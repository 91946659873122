import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { EditStateService } from "../../../application/master/herbicide/editState";
import { NewStateService } from "../../../application/master/herbicide/newState";
import { ApplicationState } from "../../../store/modules";
import { NewOrEditStateType } from "../../../store/modules/master/herbicide/newOrEditState/reducer";
import { ConfirmModal } from "../../components/molecules/ConfirmModal";
import { HerbicideForm } from "../../components/organisms/HerbicideForm";

interface StateProps {
  newOrEditState: NewOrEditStateType;
}

interface DispatchProps {
  editStateService: EditStateService;
  newStateService: NewStateService;
}

type HerbicideEditPageProps = StateProps & DispatchProps;

interface HerbicideEditPageState {
  isOpenDeleteConfirm: boolean;
}

export class Wrapped extends React.Component<HerbicideEditPageProps, HerbicideEditPageState> {
  constructor(props: HerbicideEditPageProps) {
    super(props);

    this.state = {
      isOpenDeleteConfirm: false,
    };
  }

  public render() {
    const { herbicide, isSaving } = this.props.newOrEditState;
    return (
      <>
        <HerbicideForm
          herbicide={herbicide}
          isSaving={isSaving}
          onChangeName={this.handleChangeName}
          onClickSave={this.handleClickSaveHerbicide}
          onClickCancel={this.handleClickCancelHerbicide}
          onClickAdd={this.handleClickAddHerbicide}
          onClickDelete={this.openDeleteConfirmModal}
        />{" "}
        <ConfirmModal
          isOpen={this.state.isOpenDeleteConfirm}
          onClose={this.closeDeleteConfirmModal}
          onClickCancel={this.closeDeleteConfirmModal}
          onClickOk={this.handleClickDeleteHerbicide}
          cancelText={"キャンセル"}
          okText={"削除"}
          confirmMessage={"除草剤・薬品を削除してもよろしいでしょうか？"}
        />
      </>
    );
  }

  private handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.editStateService.changeName(e.target.value);
  };

  private handleClickSaveHerbicide = () => {
    this.props.editStateService.saveHerbicide();
  };

  private handleClickCancelHerbicide = () => {
    this.props.editStateService.cancelHerbicide();
  };

  private handleClickAddHerbicide = () => {
    this.props.newStateService.createHerbicide();
  };

  private handleClickDeleteHerbicide = () => {
    this.props.editStateService.deleteHerbicide();
    this.closeDeleteConfirmModal();
  };

  private closeDeleteConfirmModal = () => {
    this.setState({ isOpenDeleteConfirm: false });
  };
  private openDeleteConfirmModal = () => {
    this.setState({ isOpenDeleteConfirm: true });
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { newOrEditState } = state.master.herbicide;
  return {
    newOrEditState,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    editStateService: new EditStateService(dispatch),
    newStateService: new NewStateService(dispatch),
  };
};

export const HerbicideEditPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);
