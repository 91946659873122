import { actionCreatorFactory } from "typescript-fsa";
// Actions
const actionCreator = actionCreatorFactory("NotificationAlertState");

export const showErrorMessage = actionCreator<ShowErrorMessagePayload>("showErrorMessage");
interface ShowErrorMessagePayload {
  errorMessage: string;
}
export const hideErrorMessage = actionCreator("hideErrorMessage");

export const notificationAlertStateActions = {
  showErrorMessage,
  hideErrorMessage,
};

// Reducers
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { getInitialState, InitHandlers, NotificationAlertStateType } from "./reducer";

export const notificationAlertStateReducer = reducerWithInitialState<NotificationAlertStateType>(getInitialState())
  .case(showErrorMessage, (state, { errorMessage }) => InitHandlers.showErrorMessage(state, errorMessage))
  .case(hideErrorMessage, (state) => InitHandlers.hideErrorMessage(state));
