import { Entity } from "../entity";
import { getValidation, isEmptyString, isTextLength, Validation, Validator } from "../validation";

interface HerbicideAttribute extends Record<string, unknown> {
  name: string;
  position: number;
  isDeleted: boolean;
}

export interface NewHerbicide extends HerbicideAttribute {}
export interface EditHerbicide extends HerbicideAttribute, Entity {}

export type NewOrEditHerbicide = NewHerbicide | EditHerbicide;
export type Herbicide = EditHerbicide;

// service

export function createNewHerbicide(): NewHerbicide {
  return {
    name: "",
    position: 1,
    isDeleted: false,
  };
}

/*** Validator ***/
export class HerbicideValidator extends Validator<NewOrEditHerbicide> {
  public validate(entity: NewOrEditHerbicide) {
    const nameValidation = validateName(entity);
    if (nameValidation) {
      this.addMessages(nameValidation);
    }
  }
}

function validateName({ name }: NewOrEditHerbicide): Validation | null {
  if (isEmptyString(name)) {
    return getValidation("除草剤・薬品名は必須です。入力してください。");
  }
  if (!isTextLength(name, 0, 255)) {
    return getValidation("除草剤・薬品名は255文字以下で入力してください。");
  }
  return null;
}
