import { default as moment } from "moment";
import * as React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";
import {
  getCarpSelectionMessageState,
  isReleaseCarpSelectionReport,
  removeEmptyCarpSelectionReport,
} from "../../../../domain/carpSelectionReport";
import { CarpVarietyType } from "../../../../domain/carpVarietyType";
import { getUniqueKey, UniqueKey } from "../../../../domain/entity";
import { ParentCarp } from "../../../../domain/parentCarp";
import { isEmptyPondDisinfectionReport } from "../../../../domain/pondDisinfectionReport";
import { getEmptyAmountMessage, PondReport } from "../../../../domain/pondReport";
import { ScaleType } from "../../../../domain/scaleType";
import { FrySelectionReportSummaryVM, ToFrySelectionReportSummaryVM } from "../../../../domain/selectionReportVM";
import CleanSVG from "../../../../static/img/clean.svg";
import { COLOR } from "../../../styles/Color";
import { DIMEN } from "../../../styles/Dimen";
import { GroupListItem } from "../../atoms/GroupListItem";

interface MobileFryPondReportListProps {
  currentPondReport: PondReport;
  prevPondReport: PondReport | null;
  parentCarp: ParentCarp | null;
  carpVarietyTypes: CarpVarietyType[];
  carpSizeTypes: ScaleType[];
  carpQualityTypes: ScaleType[];
  malformationTypes: ScaleType[];
  carpRatioTypes: ScaleType[];
  onClickPondDisinfection: (uniqueKey: UniqueKey) => void;
  onClickCarpSelection: (uniqueKey: UniqueKey) => void;
}

export const FryPondReportList = (props: MobileFryPondReportListProps) => {
  const { parentCarp } = props;
  const carpVarietyType = parentCarp ? props.carpVarietyTypes.find((v) => v.id === parentCarp.carpVarietyTypeId) : null;
  return (
    <List>
      <DisinfectionReportList
        currentPondReport={props.currentPondReport}
        prevPondReport={props.prevPondReport}
        onClickPondDisinfection={props.onClickPondDisinfection}
      />
      <SelectionReportList
        currentPondReport={props.currentPondReport}
        prevPondReport={props.prevPondReport}
        carpVarietyType={carpVarietyType || null}
        carpSizeTypes={props.carpSizeTypes}
        carpQualityTypes={props.carpQualityTypes}
        malformationTypes={props.malformationTypes}
        carpRatioTypes={props.carpRatioTypes}
        onClickCarpSelection={props.onClickCarpSelection}
      />
    </List>
  );
};

interface DisinfectionReportListProps {
  currentPondReport: PondReport;
  prevPondReport: PondReport | null;
  onClickPondDisinfection: (uniqueKey: UniqueKey) => void;
}
const DisinfectionReportList = (props: DisinfectionReportListProps) => {
  const currentReport = props.currentPondReport.pondDisinfectionReports[0];
  const prevReport = props.prevPondReport ? props.prevPondReport.pondDisinfectionReports[0] : undefined;
  const selectionReports = removeEmptyCarpSelectionReport(props.currentPondReport.carpSelectionReports);
  return (
    <GroupListItem className={"mobile"} onClick={() => props.onClickPondDisinfection(getUniqueKey(currentReport))}>
      <ListTitle>
        <SelectionName>塩素</SelectionName>
        {!isEmptyPondDisinfectionReport(currentReport) && selectionReports.length === 0 && (
          <IconCell>
            <CleanCompleteIcon />
          </IconCell>
        )}
      </ListTitle>
      <ListCaption>
        <DetailHeader>
          <DateCell />
          <AmountCell>本数</AmountCell>
          <SizeCell />
          <QualityCell />
          <MalformationCell />
          <RatioCell />
        </DetailHeader>
        <CurrentDetail>
          <DateCell>
            {currentReport && currentReport.date ? moment(currentReport.date).format("YYYY/MM/DD") : "-"}
          </DateCell>
          <AmountCell>{currentReport && currentReport.amount ? `${currentReport.amount}本` : "- 本"}</AmountCell>
          <SizeCell />
          <QualityCell />
          <MalformationCell />
          <RatioCell />
        </CurrentDetail>
        <PrevDetail>
          <DateCell>{prevReport && prevReport.date ? moment(prevReport.date).format("YYYY/MM/DD") : "-"}</DateCell>
          <AmountCell>{prevReport && prevReport.amount ? `${prevReport.amount}本` : "- 本"}</AmountCell>
          <SizeCell />
          <QualityCell />
          <MalformationCell />
          <RatioCell />
        </PrevDetail>
      </ListCaption>
    </GroupListItem>
  );
};

interface SelectionReportListProps {
  currentPondReport: PondReport;
  prevPondReport: PondReport | null;
  carpVarietyType: CarpVarietyType | null;
  carpSizeTypes: ScaleType[];
  carpQualityTypes: ScaleType[];
  malformationTypes: ScaleType[];
  carpRatioTypes: ScaleType[];
  onClickCarpSelection: (uniqueKey: UniqueKey) => void;
}
const SelectionReportList = (props: SelectionReportListProps) => {
  const currentReports: FrySelectionReportSummaryVM[] = ToFrySelectionReportSummaryVM(
    props.currentPondReport.carpSelectionReports,
    props.currentPondReport.carpMovingReports
  );
  const prevReports: FrySelectionReportSummaryVM[] = props.prevPondReport
    ? ToFrySelectionReportSummaryVM(props.prevPondReport.carpSelectionReports, props.prevPondReport.carpMovingReports)
    : [];
  const sizeDictionary = props.carpSizeTypes.reduce((obj, size) => ({ ...obj, [size.id]: size.name }), {});
  const qualityDictionary = props.carpQualityTypes.reduce(
    (obj, quality) => ({ ...obj, [quality.id]: quality.name }),
    {}
  );
  const malformationDictionary = props.malformationTypes.reduce(
    (obj, malformation) => ({ ...obj, [malformation.id]: malformation.name }),
    {}
  );
  const ratioDictionary = props.carpRatioTypes.reduce((obj, ratio) => ({ ...obj, [ratio.id]: ratio.name }), {});

  return (
    <>
      {currentReports.map((r) => {
        const currentUniqueKey = getUniqueKey(r);
        const messageState =
          props.carpVarietyType &&
          getCarpSelectionMessageState(
            props.currentPondReport.carpSelectionReports,
            r.selectionNumber,
            new Date(),
            props.carpVarietyType,
            props.currentPondReport.isCompleted
          );
        const prevReport = prevReports.find((pr) => pr.selectionNumber === r.selectionNumber) || {
          date: null,
          amount: "",
          carpSizeTypeId: null,
          carpQualityTypeId: null,
          malformationTypeId: null,
          carpRatioTypeId: null,
          carpScore: "",
        };
        const isReleaseCarp = isReleaseCarpSelectionReport(r);
        return (
          <GroupListItem
            className={"mobile"}
            key={currentUniqueKey}
            onClick={() => props.onClickCarpSelection(currentUniqueKey)}>
            <ListTitle>
              <SelectionName>{r.name}</SelectionName>
              {messageState === "warning" && (
                <IconCell>
                  <SelectionWarningIcon />
                </IconCell>
              )}
              {messageState === "alert" && (
                <IconCell>
                  <SelectionAlertIcon />
                </IconCell>
              )}
            </ListTitle>
            <ListCaption>
              <DetailHeader>
                <DateCell />
                <AmountCell>匹数</AmountCell>
                <SizeCell>{!isReleaseCarp && "サイズ"}</SizeCell>
                <QualityCell>{!isReleaseCarp && "評価"}</QualityCell>
                <ScoreCell>{!isReleaseCarp && "点数"}</ScoreCell>
                <MalformationCell>{!isReleaseCarp && "奇形"}</MalformationCell>
                <RatioCell>{!isReleaseCarp && "数"}</RatioCell>
              </DetailHeader>
              <CurrentDetail>
                <DateCell>{r.date ? moment(r.date).format("YYYY/MM/DD") : "-"}</DateCell>
                <AmountCell>{getAmountString(r.amount)}</AmountCell>
                <SizeCell>
                  {!isReleaseCarp && (r.carpSizeTypeId !== null ? sizeDictionary[r.carpSizeTypeId] : "-")}
                </SizeCell>
                <QualityCell>
                  {!isReleaseCarp && (r.carpQualityTypeId !== null ? qualityDictionary[r.carpQualityTypeId] : "-")}
                </QualityCell>
                <ScoreCell>{!isReleaseCarp && (r.carpScore !== "" ? r.carpScore : "-")}</ScoreCell>
                <MalformationCell>
                  {!isReleaseCarp &&
                    (r.malformationTypeId !== null ? malformationDictionary[r.malformationTypeId] : "-")}
                </MalformationCell>
                <RatioCell>
                  {!isReleaseCarp && (r.carpRatioTypeId ? ratioDictionary[r.carpRatioTypeId] : "-")}
                </RatioCell>
              </CurrentDetail>
              <PrevDetail>
                <DateCell>{prevReport.date ? moment(prevReport.date).format("YYYY/MM/DD") : "-"}</DateCell>
                <AmountCell>{getAmountString(prevReport.amount)}</AmountCell>
                <SizeCell>
                  {!isReleaseCarp &&
                    (prevReport.carpSizeTypeId !== null ? sizeDictionary[prevReport.carpSizeTypeId] : "-")}
                </SizeCell>
                <QualityCell>
                  {!isReleaseCarp &&
                    (prevReport.carpQualityTypeId !== null ? qualityDictionary[prevReport.carpQualityTypeId] : "-")}
                </QualityCell>
                <ScoreCell>{!isReleaseCarp && (prevReport.carpScore !== "" ? prevReport.carpScore : "-")}</ScoreCell>
                <MalformationCell>
                  {!isReleaseCarp &&
                    (prevReport.malformationTypeId !== null
                      ? malformationDictionary[prevReport.malformationTypeId]
                      : "-")}
                </MalformationCell>
                <RatioCell>
                  {!isReleaseCarp &&
                    (prevReport.carpRatioTypeId !== null ? ratioDictionary[prevReport.carpRatioTypeId] : "-")}
                </RatioCell>
              </PrevDetail>
            </ListCaption>
          </GroupListItem>
        );
      })}
    </>
  );
};

function getAmountString(amount: string) {
  if (amount === "0") {
    return getEmptyAmountMessage();
  }
  if (amount === "") {
    return "- 匹";
  }
  return `${amount}匹`;
}

const List = styled.div``;

const ListTitle = styled.div`
  display: flex;
  padding: ${DIMEN.X0_5} 0;
  min-height: 28px;
`;

const SelectionName = styled.div`
  font-weight: bold;
  font-size: 18px;
  flex-basis: 5em;
`;

const ListCaption = styled.div`
  padding: ${DIMEN.X0_5} 1.5em ${DIMEN.X0_5} 0;
  min-height: 50px;
  position: relative;
`;

const DetailHeader = styled.div`
  display: flex;
  color: ${COLOR.Gray["darken-1"]};
  font-size: 12px;
  margin-bottom: ${DIMEN.X0_5};
  > div {
    padding: ${DIMEN.X0_5} 0;
    border-bottom: 1px solid ${COLOR.Gray["darken-1"]};
    &:first-child {
      border-bottom: 0;
    }
  }
`;
const CurrentDetail = styled.div`
  display: flex;
`;
const PrevDetail = styled.div`
  display: flex;
  font-size: 12px;
  text-align: center;
  color: ${COLOR.Gray["darken-1"]};
`;
const IconCell = styled.div`
  display: flex;
`;
const BaseCell = styled.div`
  flex: 1 1 0;
  text-align: center;
`;
const DateCell = styled(BaseCell)`
  flex: 1 1 4rem;
`;
const AmountCell = styled(BaseCell)`
  flex: 1 1 3rem;
`;
const SizeCell = styled(BaseCell)`
  min-width: 36px;
`;
const QualityCell = styled(BaseCell)`
  min-width: 24px;
`;
const ScoreCell = styled(BaseCell)`
  min-width: 24px;
`;
const MalformationCell = styled(BaseCell)`
  min-width: 24px;
`;
const RatioCell = styled(BaseCell)`
  min-width: 28px;
`;

export const CleanCompleteIcon = () => {
  return (
    <Icon size={"large"}>
      <img src={CleanSVG} alt={"消毒済"} style={{ width: "auto", height: "100%" }} />
    </Icon>
  );
};
export const SelectionWarningIcon = styled(Icon).attrs({ name: "warning circle", color: "yellow", size: "large" })``;
export const SelectionAlertIcon = styled(Icon).attrs({ name: "warning circle", color: "red", size: "large" })``;
