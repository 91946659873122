import { Dispatch } from "redux";
import { getFiscalYear } from "../../../domain/calendar";
import {
  createNewAdultPondReport,
  createNewFryPondReport,
  fillEmptyAdultPondReports,
  fillEmptyFryPondReports,
  filterByFiscalYear,
  isAdultPondReports,
  isFryPondReports,
  NewOrEditPondReport,
} from "../../../domain/pondReport";
import { isFryPondType } from "../../../domain/pondType";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { ApplicationState } from "../../../store/modules";
import { reportPondReportNewOrEditStateActions } from "../../../store/modules/report/pondReport/newOrEditState/ducks";

interface INewOrEditStateService extends IApplicationService {
  createPondReports: () => void;
  changePondType: (pondTypeId: number) => void;
  selectPondReports: () => void;
  changePrevYear: () => void;
  changeCurrentYear: () => void;
  changeNextYear: () => void;
  changeYear: (date: Date) => void;
}

export class NewOrEditStateService implements INewOrEditStateService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public createPondReports() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedPondId } = state.report.navigation;
      const pond = state.api.pond.ponds.find((p) => p.id === selectedPondId);
      if (!pond) {
        throw new ApplicationError("野池が見つかりませんでした。");
      }
      const pondType = state.api.pondType.pondTypes.find((t) => pond.pondTypeId === t.id);
      if (!pondType) {
        throw new ApplicationError("野池の区分が見つかりませんでした。");
      }
      const user = state.auth.user;
      if (user === null) {
        throw new ApplicationError("不正な操作です。");
      }
      const fiscalYear = getFiscalYear(state.report.pondReport.newOrEditState.selectedDate);
      const pondReport = isFryPondType(pondType)
        ? createNewFryPondReport(pond.id, user.id, fiscalYear, pondType.id)
        : createNewAdultPondReport(pond.id, user.id, fiscalYear, pondType.id);
      this.dispatch(
        reportPondReportNewOrEditStateActions.createPondReports({
          pondReports: [pondReport],
        })
      );
    });
  }

  @catchApplicationError()
  public changePondType(pondTypeId: number) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedPondId } = state.report.navigation;
      const pond = state.api.pond.ponds.find((p) => p.id === selectedPondId);
      if (!pond) {
        throw new ApplicationError("野池が見つかりませんでした。");
      }
      const pondType = state.api.pondType.pondTypes.find((t) => pondTypeId === t.id);
      if (!pondType) {
        throw new ApplicationError("野池の区分が見つかりませんでした。");
      }
      const user = state.auth.user;
      if (user === null) {
        throw new ApplicationError("不正な操作です。");
      }

      const { pondReports, selectedDate } = state.report.pondReport.newOrEditState;
      const editingPondReport = pondReports !== null ? pondReports[0] : null;

      const fiscalYear = !!editingPondReport ? editingPondReport.fiscalYear : getFiscalYear(selectedDate);
      const pondReport = isFryPondType(pondType)
        ? createNewFryPondReport(pond.id, user.id, fiscalYear, pondType.id)
        : createNewAdultPondReport(pond.id, user.id, fiscalYear, pondType.id);
      this.dispatch(
        reportPondReportNewOrEditStateActions.createPondReports({
          pondReports: [pondReport],
        })
      );
    });
  }

  @catchApplicationError()
  public selectPondReports() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedDate } = state.report.pondReport.newOrEditState;
      const { selectedPondId } = state.report.navigation;
      const pondReportsByPondId = state.api.pondReport.pondReports.filter((p) => p.pondId === selectedPondId);
      const pondReports = filterByFiscalYear(pondReportsByPondId, getFiscalYear(selectedDate));
      if (pondReports.length === 0) {
        this.dispatch(reportPondReportNewOrEditStateActions.selectPondReports({ pondReports: [] }));
        return;
      }
      let filledPondReports: NewOrEditPondReport[] = [];
      if (isFryPondReports(pondReports, state.api.pondType.pondTypes)) {
        filledPondReports = fillEmptyFryPondReports(pondReports);
      }
      if (isAdultPondReports(pondReports, state.api.pondType.pondTypes)) {
        filledPondReports = fillEmptyAdultPondReports(pondReports);
      }
      this.dispatch(reportPondReportNewOrEditStateActions.selectPondReports({ pondReports: filledPondReports }));
    });
  }

  @catchApplicationError()
  public changePrevYear() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedDate } = state.report.pondReport.newOrEditState;
      this.dispatch(
        reportPondReportNewOrEditStateActions.changeSelectedDate({
          selectedDate: new Date(selectedDate.getFullYear() - 1, selectedDate.getMonth(), selectedDate.getDate()),
        })
      );
    });
  }

  @catchApplicationError()
  public changeCurrentYear() {
    const date = new Date();
    this.dispatch(
      reportPondReportNewOrEditStateActions.changeSelectedDate({
        selectedDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
      })
    );
  }

  @catchApplicationError()
  public changeNextYear() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedDate } = state.report.pondReport.newOrEditState;
      this.dispatch(
        reportPondReportNewOrEditStateActions.changeSelectedDate({
          selectedDate: new Date(selectedDate.getFullYear() + 1, selectedDate.getMonth(), selectedDate.getDate()),
        })
      );
    });
  }

  @catchApplicationError()
  public changeYear(date: Date) {
    this.dispatch(
      reportPondReportNewOrEditStateActions.changeSelectedDate({
        selectedDate: date,
      })
    );
  }
}
