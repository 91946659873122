import { actionCreatorFactory } from "typescript-fsa";
import { EnvironmentReport } from "../../../../domain/environmentReport";
// Actions
const actionCreator = actionCreatorFactory("ApiEnvironmentReport");

const fetchStart = actionCreator("fetchStart");
const fetchComplete = actionCreator<FetchCompletePayload>("fetchComplete");
interface FetchCompletePayload {
  environmentReports: EnvironmentReport[];
}
const fetchFail = actionCreator("fetchFail");
const shouldFetch = actionCreator("shouldFetch");

// save
const createEnvironmentReport = actionCreator<CreateEnvironmentReportPayload>("createEnvironmentReport");
interface CreateEnvironmentReportPayload {
  environmentReport: EnvironmentReport;
}
const updateEnvironmentReport = actionCreator<UpdateEnvironmentReportPayload>("updateEnvironmentReport");
interface UpdateEnvironmentReportPayload {
  environmentReport: EnvironmentReport;
}
const deleteEnvironmentReport = actionCreator<DeleteEnvironmentReportPayload>("deleteEnvironmentReport");
interface DeleteEnvironmentReportPayload {
  environmentReport: EnvironmentReport;
}

export const apiEnvironmentReportActions = {
  fetchStart,
  fetchComplete,
  fetchFail,
  shouldFetch,
  createEnvironmentReport,
  updateEnvironmentReport,
  deleteEnvironmentReport,
};

// Reducers
import { EnvironmentReportStateType, FetchHandlers, getInitialState, SaveHandlers } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const EnvironmentReportReducer = reducerWithInitialState<EnvironmentReportStateType>(getInitialState())
  .case(fetchStart, (state) => FetchHandlers.start(state))
  .case(fetchComplete, (state, { environmentReports }) => FetchHandlers.completeMerge(state, environmentReports))
  .case(fetchFail, (state) => FetchHandlers.fail(state))
  .case(shouldFetch, (state) => FetchHandlers.shouldFetch(state))
  .case(createEnvironmentReport, (state, { environmentReport }) =>
    SaveHandlers.createEnvironmentReport(state, environmentReport)
  )
  .case(updateEnvironmentReport, (state, { environmentReport }) =>
    SaveHandlers.updateEnvironmentReport(state, environmentReport)
  )
  .case(deleteEnvironmentReport, (state, { environmentReport }) =>
    SaveHandlers.deleteEnvironmentReport(state, environmentReport)
  );
