import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NewOrEditStateService as EnvironmentReportService } from "../../../application/report/environmentReport/newOrEditState";
import { NavigationStateService } from "../../../application/report/navigation";
import { NewOrEditStateService as PondReportService } from "../../../application/report/pondReport/newOrEditState";
import { FeedReportStateService } from "../../../application/report/workReport/feedReportState";
import { FertilizerReportStateService } from "../../../application/report/workReport/fertilizerReportState";
import { HerbicideReportStateService } from "../../../application/report/workReport/herbicideReportState";
import { NewOrEditStateService as WorkReportService } from "../../../application/report/workReport/newOrEditState";
import { ReportListService } from "../../../application/view/reportList";
import {
  EnvironmentReport,
  getEnvironmentReportsBasedOnDate,
  NewOrEditEnvironmentReport,
} from "../../../domain/environmentReport";
import { Feed } from "../../../domain/feed";
import { FeedReasonType } from "../../../domain/feedReasonType";
import { Fertilizer } from "../../../domain/fertilizer";
import { Herbicide } from "../../../domain/herbicide";
import { ParentCarp } from "../../../domain/parentCarp";
import { Pond } from "../../../domain/pond";
import { PondReport } from "../../../domain/pondReport";
import { ReportGroup } from "../../../domain/reportGroup";
import { ScaleType } from "../../../domain/scaleType";
import { SearchQuery } from "../../../domain/searchQuery";
import { UseMethodType } from "../../../domain/useMethodType";
import { WorkReport } from "../../../domain/workReport";
import { ApplicationState } from "../../../store/modules";
import { imageGalleryStateActions } from "../../../store/modules/imageGallery/ducks";
import { GraphPeriodType, ReportListStateType } from "../../../store/modules/view/reportList/reducer";
import { OverFlowSection } from "../../components/atoms/Section";
import {
  NoContentDetailSection,
  ReportListDetailSectionHeader,
} from "../../components/molecules/ReportListDetailSection";
import { ReportListTemperatureContent } from "../../components/molecules/ReportListTemperatureContent";
import { ReportListDetail } from "../../components/organisms/ReportListDetail";
import { COLOR } from "../../styles/Color";

interface StateProps {
  workReports: WorkReport[];
  pondReports: PondReport[];
  environmentReports: EnvironmentReport[];

  ponds: Pond[];
  parentCarps: ParentCarp[];
  feeds: Feed[];
  feedReasonTypes: FeedReasonType[];
  fertilizers: Fertilizer[];
  herbicides: Herbicide[];
  useMethodTypes: UseMethodType[];
  scaleTypes: ScaleType[];

  reportList: ReportListStateType;
}

interface StateType {
  isMovingPage: boolean;
}

interface DispatchProps {
  reportListService: ReportListService;
  navigationService: NavigationStateService;
  workReportService: WorkReportService;
  feedReportService: FeedReportStateService;
  fertilizerReportService: FertilizerReportStateService;
  herbicideReportService: HerbicideReportStateService;
  pondReportService: PondReportService;
  environmentReportService: EnvironmentReportService;
  showImageGallery: (imageIds: number[], imageId: number) => void;
}

type ReportListDetailPageProps = StateProps & DispatchProps & RouteComponentProps;

// NoContextHeader + DetailHeader
const NO_CONTENT_HEADER_HEIGHT = "47px";
const DETAIL_HEADER_HEIGHT = "61px";
const HEADER_HEIGHT = `calc(${NO_CONTENT_HEADER_HEIGHT} + ${DETAIL_HEADER_HEIGHT})`;

export class Wrapped extends React.Component<ReportListDetailPageProps, StateType> {
  constructor(props: ReportListDetailPageProps) {
    super(props);

    this.state = {
      isMovingPage: false,
    };
  }

  public render() {
    const { reportList } = this.props;
    const { selectedDate, selectedPondId, graphPeriodType } = reportList;
    const { selectedReportTypeIds } = reportList.reportFilterState;

    return (
      <>
        <NoContentDetailSection />
        <OverFlowSection
          height={`calc(100% - ${NO_CONTENT_HEADER_HEIGHT})`}
          style={{ borderLeft: `1px solid ${COLOR.Gray["lighter-1"]}` }}>
          <ReportListDetailSectionHeader
            selectedDate={selectedDate}
            selectedTab={reportList.detailTab}
            onClickDetail={this.handleClickDetail}
            onClickGraph={this.handleClickGraph}
          />
          <OverFlowSection height={`calc(100% - ${HEADER_HEIGHT})`}>
            {reportList.detailTab === "detail" && (
              <ReportListDetail
                selectedDate={selectedDate}
                selectedPondId={selectedPondId}
                workReports={this.props.workReports}
                pondReports={this.props.pondReports}
                environmentReports={this.props.environmentReports}
                ponds={this.props.ponds}
                parentCarps={this.props.parentCarps}
                feeds={this.props.feeds}
                feedReasonTypes={this.props.feedReasonTypes}
                fertilizers={this.props.fertilizers}
                herbicides={this.props.herbicides}
                useMethodTypes={this.props.useMethodTypes}
                scaleTypes={this.props.scaleTypes}
                isDisplayFeedReport={selectedReportTypeIds.includes(ReportGroup.Feed)}
                isDisplayFertilizerReport={selectedReportTypeIds.includes(ReportGroup.Fertilizer)}
                isDisplayHerbicideReport={selectedReportTypeIds.includes(ReportGroup.Herbicide)}
                isDisplayNoteReport={selectedReportTypeIds.includes(ReportGroup.Note)}
                isDisplayEnvironmentReport={selectedReportTypeIds.includes(ReportGroup.EnvironmentReport)}
                isDisplayPondReport={selectedReportTypeIds.includes(ReportGroup.PondReport)}
                onClickWorkReport={this.handleClickWorkReport}
                onClickWorkReportNote={this.handleClickWorkReportNote}
                onClickWorkReportImage={this.handleClickWorkReportImage}
                onClickPondReport={this.handleClickPondReport}
                onClickEnvironmentReport={this.handleClickEnvironmentReport}
              />
            )}
            {reportList.detailTab === "graph" && (
              <ReportListTemperatureContent
                environmentReports={this.filterEnvironmentReportsForGraph()}
                selectedDate={this.props.reportList.selectedDate}
                activePeriodType={graphPeriodType}
                onClickPeriod={this.handleClickPeriodType}
              />
            )}
          </OverFlowSection>
        </OverFlowSection>
      </>
    );
  }

  private filterEnvironmentReportsForGraph = (): NewOrEditEnvironmentReport[] => {
    const { selectedPondId, selectedDate } = this.props.reportList;
    if (selectedPondId === null) {
      return [];
    }
    const reports = this.props.environmentReports.filter((e) => e.pondId === selectedPondId);
    const { graphPeriodType } = this.props.reportList;
    return getEnvironmentReportsBasedOnDate(reports, selectedPondId, selectedDate, graphPeriodType);
  };

  private handleClickDetail = () => {
    this.props.reportListService.changeDetailTab("detail");
  };

  private handleClickGraph = async () => {
    this.props.reportListService.changeDetailTab("graph");
    await this.props.reportListService.fetchEnvironmentReports();
  };

  private handleClickPeriodType = async (periodType: GraphPeriodType) => {
    this.props.reportListService.changeGraphPeriodType(periodType);
  };

  private handleClickWorkReport = async (
    workReportId: number,
    reportKey: "feed" | "fertilizer" | "herbicide",
    itemId: number
  ) => {
    if (this.state.isMovingPage) {
      return;
    }
    this.setState({ isMovingPage: true });
    const { selectedDate, selectedPondAreaId, selectedPondId } = this.props.reportList;
    if (selectedPondId === null || selectedPondAreaId === null) {
      return;
    }
    this.props.navigationService.selectDate(selectedDate);
    this.props.navigationService.selectPond(selectedPondId);
    this.props.navigationService.selectPondArea(selectedPondAreaId);
    await this.props.workReportService.initWorkReport();
    if (reportKey === "feed") {
      this.props.feedReportService.selectFeedReport(itemId);
      const query = SearchQuery.createQuery("reports_list");
      this.props.history.push(SearchQuery.createURLFromQuery("/work_reports/feed", query));
      return;
    }
    if (reportKey === "fertilizer") {
      this.props.fertilizerReportService.selectFertilizerReport(itemId);
      const query = SearchQuery.createQuery("reports_list");
      this.props.history.push(SearchQuery.createURLFromQuery("/work_reports/fertilizer", query));
      return;
    }
    if (reportKey === "herbicide") {
      this.props.herbicideReportService.selectHerbicideReport(itemId);
      const query = SearchQuery.createQuery("reports_list");
      this.props.history.push(SearchQuery.createURLFromQuery("/work_reports/herbicide", query));
      return;
    }
  };

  private handleClickWorkReportNote = async () => {
    if (this.state.isMovingPage) {
      return;
    }
    this.setState({ isMovingPage: true });
    const { selectedDate, selectedPondId, selectedPondAreaId } = this.props.reportList;
    if (selectedPondId === null || selectedPondAreaId === null) {
      return;
    }
    this.props.navigationService.selectDate(selectedDate);
    this.props.navigationService.selectPond(selectedPondId);
    this.props.navigationService.selectPondArea(selectedPondAreaId);
    await this.props.workReportService.initWorkReport();
    const query = SearchQuery.createQuery("reports_list");
    this.props.history.push(SearchQuery.createURLFromQuery("/work_reports/note", query));
  };

  private handleClickWorkReportImage = (imageIds: number[], imageId: number) => {
    this.props.showImageGallery(imageIds, imageId);
  };

  private handleClickPondReport = async () => {
    if (this.state.isMovingPage) {
      return;
    }
    this.setState({ isMovingPage: true });
    const { selectedDate, selectedPondId, selectedPondAreaId } = this.props.reportList;
    if (selectedPondId === null || selectedPondAreaId === null) {
      return;
    }
    this.props.navigationService.selectPond(selectedPondId);
    this.props.navigationService.selectPondArea(selectedPondAreaId);
    this.props.pondReportService.changeYear(selectedDate);
    this.props.pondReportService.selectPondReports();
    const query = SearchQuery.createQuery("reports_list");
    this.props.history.push(SearchQuery.createURLFromQuery("/pond_reports", query));
  };

  private handleClickEnvironmentReport = async () => {
    if (this.state.isMovingPage) {
      return;
    }
    this.setState({ isMovingPage: true });
    const { selectedDate, selectedPondAreaId, selectedPondId } = this.props.reportList;
    if (selectedPondId === null || selectedPondAreaId === null) {
      return;
    }
    this.props.navigationService.selectDate(selectedDate);
    this.props.navigationService.selectPond(selectedPondId);
    this.props.navigationService.selectPondArea(selectedPondAreaId);
    await this.props.environmentReportService.initEnvironmentReport();
    const query = SearchQuery.createQuery("reports_list");
    this.props.history.push(SearchQuery.createURLFromQuery("/environment_reports", query));
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const {
    workReport,
    pondReport,
    environmentReport,
    pond,
    parentCarp,
    feed,
    feedReasonType,
    fertilizer,
    herbicide,
    useMethodType,
    scaleType,
  } = state.api;
  return {
    workReports: workReport.workReports,
    pondReports: pondReport.pondReports,
    environmentReports: environmentReport.environmentReports,
    ponds: pond.ponds,
    parentCarps: parentCarp.parentCarps,
    feeds: feed.feeds,
    feedReasonTypes: feedReasonType.feedReasonTypes,
    fertilizers: fertilizer.fertilizers,
    herbicides: herbicide.herbicides,
    useMethodTypes: useMethodType.useMethodTypes,
    scaleTypes: scaleType.scaleTypes,

    reportList: state.view.reportList,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    reportListService: new ReportListService(dispatch),
    navigationService: new NavigationStateService(dispatch),
    workReportService: new WorkReportService(dispatch),
    feedReportService: new FeedReportStateService(dispatch),
    fertilizerReportService: new FertilizerReportStateService(dispatch),
    herbicideReportService: new HerbicideReportStateService(dispatch),
    pondReportService: new PondReportService(dispatch),
    environmentReportService: new EnvironmentReportService(dispatch),
    showImageGallery: (imageIds, imageId) =>
      dispatch(imageGalleryStateActions.showImages({ imageIds, initialImageId: imageId })),
  };
};

export const ReportListDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
