import { Dispatch } from "redux";
import { getFiscalYear } from "../../../domain/calendar";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { CarpVarietyTypeApiService } from "../../api/carpVarietyType";
import { ParentCarpApiService } from "../../api/parentCarps";
import { PondApiService } from "../../api/pond";
import { PondAreaApiService } from "../../api/pondArea";
import { PondOwnerTypeApiService } from "../../api/pondOwnerType";
import { PondReportApiService } from "../../api/pondReport";
import { PondTypeApiService } from "../../api/pondType";
import { WorkerApiService } from "../../api/worker";

interface IReadApiService extends IApplicationService {
  init: () => void;
  fetchPondAreas: () => void;
  fetchWorkers: () => void;
}

export class ReadApiService implements IReadApiService {
  private pondApiService: PondApiService;
  private pondAreaApiService: PondAreaApiService;
  private pondTypeApiService: PondTypeApiService;
  private pondOwnerTypeApiService: PondOwnerTypeApiService;
  private parentCarpApiService: ParentCarpApiService;
  private pondReportApiService: PondReportApiService;
  private workerApiService: WorkerApiService;
  private carpVarietyTypeApiService: CarpVarietyTypeApiService;

  public constructor(private dispatch: Dispatch<any>) {
    this.pondApiService = new PondApiService(dispatch);
    this.pondAreaApiService = new PondAreaApiService(dispatch);
    this.pondTypeApiService = new PondTypeApiService(dispatch);
    this.pondOwnerTypeApiService = new PondOwnerTypeApiService(dispatch);
    this.parentCarpApiService = new ParentCarpApiService(dispatch);
    this.pondReportApiService = new PondReportApiService(dispatch);
    this.workerApiService = new WorkerApiService(dispatch);
    this.carpVarietyTypeApiService = new CarpVarietyTypeApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public async init() {
    const now = new Date();
    const fiscalYear = getFiscalYear(now);
    await Promise.all([
      this.pondApiService.fetchUnFetched(),
      this.pondAreaApiService.fetchUnFetched(),
      this.pondTypeApiService.fetchUnFetched(),
      this.pondOwnerTypeApiService.fetchUnFetched(),
      this.parentCarpApiService.init(),
      this.pondReportApiService.init(fiscalYear),
      this.carpVarietyTypeApiService.fetchUnFetched(),
    ]);
  }

  @catchApplicationError()
  public async fetchUnFetchedApi() {
    const fiscalYear = getFiscalYear(new Date());
    await Promise.all([
      this.pondApiService.fetchUnFetched(),
      this.pondAreaApiService.fetchUnFetched(),
      this.pondTypeApiService.fetchUnFetched(),
      this.pondOwnerTypeApiService.fetchUnFetched(),
      this.parentCarpApiService.fetchUnFetched(),
      this.pondReportApiService.fetchUnFetched(fiscalYear),
      this.carpVarietyTypeApiService.fetchUnFetched(),
    ]);
  }

  @catchApplicationError()
  public async fetchPondAreas() {
    await this.pondAreaApiService.fetchUnFetched();
  }

  @catchApplicationError()
  public async fetchWorkers() {
    await this.workerApiService.fetchUnFetched();
  }
}
