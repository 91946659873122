import { default as moment } from "moment";
import * as React from "react";
import { getFiscalYear, getMonthDates } from "../../../../domain/calendar";
import { EnvironmentReport, filterEnvironmentReportByCriteria } from "../../../../domain/environmentReport";
import { Feed } from "../../../../domain/feed";
import { FeedReasonType } from "../../../../domain/feedReasonType";
import { Fertilizer } from "../../../../domain/fertilizer";
import { Herbicide } from "../../../../domain/herbicide";
import { ParentCarp } from "../../../../domain/parentCarp";
import { Pond } from "../../../../domain/pond";
import { filterPondReportsByCriteria, PondReport } from "../../../../domain/pondReport";
import { ScaleType } from "../../../../domain/scaleType";
import { UseMethodType } from "../../../../domain/useMethodType";
import { filterEmptyWorkReport, filterWorkReportsByCriteria, WorkReport } from "../../../../domain/workReport";
import { EmptyMessage } from "../../atoms/Message";
import { ReportListItem } from "../../molecules/ReportListItem";

interface ReportListProps {
  selectedDate: Date;
  selectedPondId: number | null;
  ponds: Pond[];
  workReports: WorkReport[];
  pondReports: PondReport[];
  environmentReports: EnvironmentReport[];
  parentCarps: ParentCarp[];
  feeds: Feed[];
  feedReasonTypes: FeedReasonType[];
  fertilizers: Fertilizer[];
  herbicides: Herbicide[];
  scaleTypes: ScaleType[];
  useMethodTypes: UseMethodType[];
  isAscDate: boolean;
  isDisplayFeedReport: boolean;
  isDisplayFertilizerReport: boolean;
  isDisplayHerbicideReport: boolean;
  isDisplayNoteReport: boolean;
  isDisplayEnvironmentReport: boolean;
  isDisplayPondReport: boolean;
  onClickDate: (date: Date) => void;
}
export const ReportList = (props: ReportListProps) => {
  const dates = props.isAscDate ? getMonthDates(props.selectedDate) : getMonthDates(props.selectedDate).reverse();
  const list = dates
    .map((d) => {
      const workReports = filterWorkReportsByCriteria(props.workReports, {
        date: d,
        pondId: props.selectedPondId,
      }).filter((w) => filterEmptyWorkReport(w, props)) as WorkReport[];
      const fiscalYear = getFiscalYear(props.selectedDate);
      const pondReports = props.isDisplayPondReport
        ? (filterPondReportsByCriteria(props.pondReports, {
          fiscalYear,
          pondId: props.selectedPondId,
        }) as PondReport[])
        : [];
      const environmentReports = props.isDisplayEnvironmentReport
        ? (filterEnvironmentReportByCriteria(props.environmentReports, {
          date: d,
          pondId: props.selectedPondId,
        }) as EnvironmentReport[])
        : [];
      if (isEmptyList(workReports, pondReports, environmentReports, d)) {
        return null;
      }
      return (
        <ReportListItem
          key={d.valueOf()}
          date={d}
          workReports={workReports}
          pondReports={pondReports}
          environmentReports={environmentReports}
          ponds={props.ponds}
          pairPondReports={props.pondReports}
          parentCarps={props.parentCarps}
          feeds={props.feeds}
          feedReasonTypes={props.feedReasonTypes}
          fertilizers={props.fertilizers}
          herbicides={props.herbicides}
          useMethodTypes={props.useMethodTypes}
          scaleTypes={props.scaleTypes}
          isDisplayFeedReport={props.isDisplayFeedReport}
          isDisplayFertilizerReport={props.isDisplayFertilizerReport}
          isDisplayHerbicideReport={props.isDisplayHerbicideReport}
          isDisplayNoteReport={props.isDisplayNoteReport}
          onClickListItem={() => props.onClickDate(d)}
        />
      );
    })
    .filter((l) => l !== null);

  const isEmpty = list.length === 0;
  return (
    <>
      {isEmpty && <EmptyMessage>記録がありませんでした。</EmptyMessage>}
      {!isEmpty && list}
    </>
  );
};

const isEmptyList = (
  workReports: WorkReport[],
  pondReports: PondReport[],
  environmentReports: EnvironmentReport[],
  date: Date
) => {
  if (workReports.length !== 0) {
    return false;
  }
  if (environmentReports.length !== 0) {
    return false;
  }
  return !pondReports.some((pr) => {
    return (
      existReportByDate(pr.carpSelectionReports, date) ||
      existReportByDate(pr.pondDisinfectionReports, date) ||
      existReportByDate(pr.carpMovingReports, date) ||
      existReportByDate(pr.adultCarpReports, date)
    );
  });
};
const existReportByDate = (reports: Array<{ date: Date | null }>, date: Date): boolean => {
  return reports.some((r) => {
    const reportDate = moment(r.date || "invalid_date");
    return reportDate.isSame(date, "date");
  });
};
