import { Entity } from "../entity";

interface PondTypeAttribute extends Record<string, unknown> {
  name: string;
  position: number;
  isDeleted: boolean;
}

export interface NewPondType extends PondTypeAttribute {}
export interface EditPondType extends PondTypeAttribute, Entity {}

export type NewOrEditPondType = NewPondType | EditPondType;
export type PondType = EditPondType;

/*** Service ***/
export function isFryPondType(pondType: PondType): boolean {
  return pondType.name === "稚魚池";
}

export function isAdultPondType(pondType: PondType): boolean {
  return !isFryPondType(pondType);
}
