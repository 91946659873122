import { isEntity } from "../../domain/entity";
import { Herbicide, NewOrEditHerbicide } from "../../domain/herbicide";
import * as Api from "../../libs/apiClient";

export function translateApiToHerbicide(apiHerbicide: Api.Herbicide): Herbicide {
  return {
    id: apiHerbicide.id as number,
    name: apiHerbicide.name,
    position: apiHerbicide.position,
    isDeleted: apiHerbicide.isDeleted,
  };
}

export function translateHerbicideToApi(herbicide: NewOrEditHerbicide): Api.Herbicide {
  return {
    id: isEntity(herbicide) ? herbicide.id : undefined,
    name: herbicide.name,
    position: herbicide.position,
    isDeleted: herbicide.isDeleted,
  };
}
