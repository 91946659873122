import { sortPosition } from "../../../../domain/entity";
import { Herbicide } from "../../../../domain/herbicide";
import { FetchLifeCycle } from "../../../lifeCycle/fetchLifeCycle";

export interface HerbicideStateType extends FetchLifeCycle {
  herbicides: Herbicide[];
}

export const getInitialState = (): HerbicideStateType => {
  return { herbicides: [], isFetched: false, isFetching: false, isError: null };
};

export const FetchHandlers = {
  start(state: HerbicideStateType) {
    return { ...state, isFetched: false, isFetching: true, isError: null };
  },
  complete(state: HerbicideStateType, herbicides: Herbicide[]) {
    const sortedEntity = herbicides.concat().sort(sortPosition);
    return { ...state, herbicides: sortedEntity, isFetched: true, isFetching: false };
  },
  fail(state: HerbicideStateType, message: string = "") {
    return { ...state, isFetched: false, isFetching: false, isError: { message } };
  },
  shouldFetch(state: HerbicideStateType): HerbicideStateType {
    return { ...state, isFetched: false };
  },
};

export const SaveHandlers = {
  createHerbicide(state: HerbicideStateType, herbicide: Herbicide): HerbicideStateType {
    const herbicides = state.herbicides.concat(herbicide);
    const sortedEntity = herbicides.concat().sort(sortPosition);
    return { ...state, herbicides: sortedEntity };
  },
  updateHerbicide(state: HerbicideStateType, herbicide: Herbicide): HerbicideStateType {
    const herbicides = state.herbicides.map((h) => (h.id === herbicide.id ? herbicide : h));
    const sortedEntity = herbicides.concat().sort(sortPosition);
    return { ...state, herbicides: sortedEntity };
  },
  deleteHerbicide(state: HerbicideStateType, herbicide: Herbicide): HerbicideStateType {
    const herbicides = state.herbicides.map((h) => (h.id === herbicide.id ? herbicide : h));
    const sortedEntity = herbicides.concat().sort(sortPosition);
    return { ...state, herbicides: sortedEntity };
  },
};
