import { combineReducers } from "redux";
import { notificationAlertStateReducer } from "./alert/ducks";
import { NotificationAlertStateType } from "./alert/reducer";
import { notificationQueueStateReducer } from "./queue/ducks";
import { NotificationQueueStateType } from "./queue/reducer";

export interface NotificationStateType {
  alertState: NotificationAlertStateType;
  queueState: NotificationQueueStateType;
}

export const notificationStateReducer = combineReducers({
  alertState: notificationAlertStateReducer,
  queueState: notificationQueueStateReducer,
});
