import { sortPosition } from "../../../../domain/entity";
import { UseMethodType } from "../../../../domain/useMethodType";
import { FetchLifeCycle } from "../../../lifeCycle/fetchLifeCycle";

export interface UseMethodTypeStateType extends FetchLifeCycle {
  useMethodTypes: UseMethodType[];
}

export const getInitialState = (): UseMethodTypeStateType => {
  return { useMethodTypes: [], isFetched: false, isFetching: false, isError: null };
};

export const FetchHandlers = {
  start(state: UseMethodTypeStateType): UseMethodTypeStateType {
    return { ...state, isFetched: false, isFetching: true, isError: null };
  },
  complete(state: UseMethodTypeStateType, useMethodTypes: UseMethodType[]): UseMethodTypeStateType {
    const sortedEntity = useMethodTypes.concat().sort(sortPosition);
    return { ...state, useMethodTypes: sortedEntity, isFetched: true, isFetching: false };
  },
  fail(state: UseMethodTypeStateType, message: string = ""): UseMethodTypeStateType {
    return { ...state, isFetched: false, isFetching: false, isError: { message } };
  },
  shouldFetch(state: UseMethodTypeStateType): UseMethodTypeStateType {
    return { ...state, isFetched: false };
  },
};
