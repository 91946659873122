import { PondOwnerType } from "../../domain/pondOwnerType";
import * as api from "../../libs/client";
import { translateApiToPondOwnerType } from "./translate";

interface IPondOwnerTypeRepository {
  getPondOwnerTypes: () => Promise<PondOwnerType[]>;
}

export class PondOwnerTypeRepository implements IPondOwnerTypeRepository {
  public async getPondOwnerTypes(): Promise<PondOwnerType[]> {
    const pondOwnerTypesApi = new api.PondOwnerTypesApi(api.serverConfig);
    const response = await pondOwnerTypesApi.getPondOwnerTypes();
    return response.data.map((d) => translateApiToPondOwnerType(d));
  }
}
