import * as React from "react";
import styled from "styled-components";
import { DIMEN } from "../../../styles/Dimen";
import { Button, IconButton } from "../../atoms/Button";
import { Image } from "../../atoms/Image";
import { Modal, ModalActions, ModalContent, ModalHeader } from "../../atoms/Modal";

interface GalleyImage {
  id: number;
  url: string;
}

interface ImageGalleryProp {
  isOpen: boolean;
  onClose: () => void;
  images: GalleyImage[];
  initialImageId: number | null;
}

interface ImageGalleryState {
  selectedImageId: number | null;
}

export class ImageGallery extends React.Component<ImageGalleryProp, ImageGalleryState> {
  constructor(props: ImageGalleryProp) {
    super(props);
    this.state = {
      selectedImageId: null,
    };
  }

  public componentWillReceiveProps(nextProps: Readonly<ImageGalleryProp>, nextContext: any): void {
    if (this.props.isOpen !== nextProps.isOpen && nextProps.isOpen) {
      let selectedImageId = nextProps.initialImageId;
      if (nextProps.initialImageId === null && nextProps.images.length !== 0) {
        selectedImageId = nextProps.images[0].id;
      }
      this.setState({ selectedImageId });
    }
  }

  public render() {
    const { isOpen, onClose, images } = this.props;
    const selectedImage = images.find((i) => i.id === this.state.selectedImageId);
    return (
      <Modal isOpen={isOpen} onClose={onClose} basic={true}>
        <ModalHeader>
          <HeaderOuter onClick={onClose}>
            <Button icon={"times"} />
          </HeaderOuter>
        </ModalHeader>
        <ModalContent>
          {selectedImage && (
            <ImageOuter>
              <a href={selectedImage.url} target={"_blank"} rel={"noopener noreferrer"}>
                <Image src={selectedImage.url} />
              </a>
            </ImageOuter>
          )}
        </ModalContent>
        <ModalActions>
          <Actions>
            <IconButton
              disabled={images.findIndex((i) => i.id === this.state.selectedImageId) === 0}
              onClick={this.handleClickLeft}
              icon={"angle left"}
            />
            <IconButton
              disabled={images.findIndex((i) => i.id === this.state.selectedImageId) === images.length - 1}
              onClick={this.handleClickRight}
              icon={"angle right"}
            />
          </Actions>
        </ModalActions>
      </Modal>
    );
  }

  private handleClickLeft = () => {
    const selectedIndex = this.props.images.findIndex((i) => i.id === this.state.selectedImageId);
    const nextIndex = selectedIndex - 1;
    if (nextIndex >= 0) {
      this.setState({ selectedImageId: this.props.images[nextIndex].id });
    }
  };

  private handleClickRight = () => {
    const selectedIndex = this.props.images.findIndex((i) => i.id === this.state.selectedImageId);
    const nextIndex = selectedIndex + 1;
    if (nextIndex <= this.props.images.length - 1) {
      this.setState({ selectedImageId: this.props.images[nextIndex].id });
    }
  };
}
export const HeaderOuter = styled.div`
  text-align: right;
`;
export const ImageOuter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Actions = styled.div`
  display: flex;
  margin: ${DIMEN.X1_5};
  border-radius: 5px;
  padding: ${DIMEN.X1_5};
  justify-content: space-around;
`;
