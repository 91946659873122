import { Dispatch } from "redux";
import { PondType } from "../../../domain/pondType";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { PondTypeRepository } from "../../../infrastracture/pondType/repository";
import { ApplicationState } from "../../../store/modules";
import { apiPondTypeActions } from "../../../store/modules/api/pondType/ducks";

interface IPondTypeApiService extends IApplicationService {
  init: () => void;
  fetchUnFetched: () => void;
}

export class PondTypeApiService implements IPondTypeApiService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError((dispatch) => dispatch(apiPondTypeActions.fetchFail()))
  public async init() {
    this.dispatch(apiPondTypeActions.fetchStart());
    const pondTypes: PondType[] = await new PondTypeRepository().getPondTypes();
    this.dispatch(apiPondTypeActions.fetchComplete({ pondTypes }));
  }

  @catchApplicationError((dispatch) => dispatch(apiPondTypeActions.fetchFail()))
  public async fetchUnFetched() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      if (getState().api.pondType.isFetched) {
        return;
      }
      this.dispatch(apiPondTypeActions.fetchStart());
      const pondTypes: PondType[] = await new PondTypeRepository().getPondTypes();
      this.dispatch(apiPondTypeActions.fetchComplete({ pondTypes }));
    });
  }
}
