import { sortPosition } from "../../../../domain/entity";
import { Feed } from "../../../../domain/feed";
import { FetchLifeCycle } from "../../../lifeCycle/fetchLifeCycle";

export interface FeedStateType extends FetchLifeCycle {
  feeds: Feed[];
}

export const getInitialState = (): FeedStateType => {
  return { feeds: [], isFetched: false, isFetching: false, isError: null };
};

export const FetchHandlers = {
  start(state: FeedStateType) {
    return { ...state, isFetched: false, isFetching: true, isError: null };
  },
  complete(state: FeedStateType, feeds: Feed[]) {
    const sortedEntity = feeds.concat().sort(sortPosition);
    return { ...state, feeds: sortedEntity, isFetched: true, isFetching: false };
  },
  fail(state: FeedStateType, message: string = "") {
    return { ...state, isFetched: false, isFetching: false, isError: { message } };
  },
  shouldFetch(state: FeedStateType): FeedStateType {
    return { ...state, isFetched: false };
  },
};

export const SaveHandlers = {
  createFeed(state: FeedStateType, feed: Feed): FeedStateType {
    const feeds = state.feeds.concat(feed);
    const sortedEntity = feeds.concat().sort(sortPosition);
    return { ...state, feeds: sortedEntity };
  },
  updateFeed(state: FeedStateType, feed: Feed): FeedStateType {
    const feeds = state.feeds.map((f) => (f.id === feed.id ? feed : f));
    const sortedEntity = feeds.concat().sort(sortPosition);
    return { ...state, feeds: sortedEntity };
  },
  deleteFeed(state: FeedStateType, feed: Feed): FeedStateType {
    const feeds = state.feeds.map((f) => (f.id === feed.id ? feed : f));
    const sortedEntity = feeds.concat().sort(sortPosition);
    return { ...state, feeds: sortedEntity };
  },
};
