import { Dispatch } from "redux";
import { getUniqueKey, UniqueKey } from "../../../domain/entity";
import { copyFertilizerReport, createNewFertilizerReport, NewFertilizerReport } from "../../../domain/fertilizerReport";
import { filterWorkReportByPond, getLatestFertilizerReport } from "../../../domain/workReport";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { ApplicationState } from "../../../store/modules";
import { workReportNewOrEditStateActions } from "../../../store/modules/report/workReport/newOrEditState/ducks";
import { FertilizerApiService } from "../../api/fertilizer";
import { UseMethodTypeApiService } from "../../api/useMethodType";
import { WorkReportApiService } from "../../api/workReport";

interface IFertilizerReportStateService extends IApplicationService {
  fetchApi: () => void;
  fetchLatestFertilizerReport: () => void;
  initFertilizerReport: () => void;
  addFertilizerReport: () => void;
  selectFertilizerReport: (uniqueKey: UniqueKey) => void;
  changeFertilizer: (id: null | number) => void;
  changeAmount: (amount: string) => void;
  changeUseMethodType: (id: null | number) => void;
}

export class FertilizerReportStateService implements IFertilizerReportStateService {
  private useMethodTypeApiService: UseMethodTypeApiService;
  private fertilizerApiService: FertilizerApiService;
  private workReportApiService: WorkReportApiService;

  public constructor(private dispatch: Dispatch<any>) {
    this.useMethodTypeApiService = new UseMethodTypeApiService(dispatch);
    this.fertilizerApiService = new FertilizerApiService(dispatch);
    this.workReportApiService = new WorkReportApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public async fetchApi() {
    await Promise.all([this.fertilizerApiService.init(), this.useMethodTypeApiService.fetchUnFetched()]);
  }

  @catchApplicationError()
  public async fetchLatestFertilizerReport() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedDate, selectedPondId } = state.report.navigation;
      if (selectedPondId === null) {
        throw new ApplicationError("不正な操作です。");
      }
      await this.workReportApiService.fetchLatestFertilizerReport(selectedDate, selectedPondId);
    });
  }

  @catchApplicationError()
  public initFertilizerReport() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const { workReport } = getState().report.workReport.newOrEditStateType;
      if (!workReport) {
        return;
      }
      const fertilizerReportCounts = workReport.fertilizerReports.length;
      if (fertilizerReportCounts === 0) {
        this.addFertilizerReport();
        return;
      }
      const fertilizerReport = workReport.fertilizerReports[0];
      if (!!fertilizerReport) {
        this.selectFertilizerReport(getUniqueKey(fertilizerReport));
        return;
      }
    });
  }

  @catchApplicationError()
  public addFertilizerReport() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedDate, selectedPondId } = state.report.navigation;
      const filteredReportsByPond = state.api.workReport.workReports.filter((r) =>
        filterWorkReportByPond(r, selectedPondId)
      );
      const latestFertilizerReport = getLatestFertilizerReport(filteredReportsByPond, selectedDate);
      const newFertilizerReport = latestFertilizerReport
        ? (copyFertilizerReport(latestFertilizerReport, createNewFertilizerReport()) as NewFertilizerReport)
        : createNewFertilizerReport();
      this.dispatch(workReportNewOrEditStateActions.addFertilizerReport({ fertilizerReport: newFertilizerReport }));
      this.dispatch(
        workReportNewOrEditStateActions.selectAnyReport({
          selectedReportKey: "fertilizer",
          selectedUniqueKey: newFertilizerReport.uid,
        })
      );
    });
  }

  @catchApplicationError()
  public selectFertilizerReport(uniqueKey: UniqueKey) {
    this.dispatch(
      workReportNewOrEditStateActions.selectAnyReport({
        selectedReportKey: "fertilizer",
        selectedUniqueKey: uniqueKey,
      })
    );
  }

  @catchApplicationError()
  public changeFertilizer(id: null | number) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedReportKey, selectedUniqueKey } = state.report.workReport.newOrEditStateType;
      if (selectedReportKey !== "fertilizer" || selectedUniqueKey === null) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        workReportNewOrEditStateActions.changeFertilizerReport({
          fertilizerUniqueKey: selectedUniqueKey,
          key: "fertilizerId",
          value: id,
        })
      );
    });
  }

  @catchApplicationError()
  public changeAmount(amount: string) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedReportKey, selectedUniqueKey } = state.report.workReport.newOrEditStateType;
      if (selectedReportKey !== "fertilizer" || selectedUniqueKey === null) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        workReportNewOrEditStateActions.changeFertilizerReport({
          fertilizerUniqueKey: selectedUniqueKey,
          key: "amount",
          value: amount,
        })
      );
    });
  }

  @catchApplicationError()
  public changeUseMethodType(id: null | number) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedReportKey, selectedUniqueKey } = state.report.workReport.newOrEditStateType;
      if (selectedReportKey !== "fertilizer" || selectedUniqueKey === null) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        workReportNewOrEditStateActions.changeFertilizerReport({
          fertilizerUniqueKey: selectedUniqueKey,
          key: "useMethodTypeId",
          value: id,
        })
      );
    });
  }
}
