import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { EditStateService } from "../../../application/master/pond/editState";
import { PondAreaStateService } from "../../../application/master/pond/pondAreaState";
import { ReadApiService } from "../../../application/master/pond/readApi";
import { isActiveEntity, isEntity } from "../../../domain/entity";
import { Pond } from "../../../domain/pond";
import { PondArea } from "../../../domain/pondArea";
import { ApplicationState } from "../../../store/modules";
import { NewOrEditStateType } from "../../../store/modules/master/pond/newOrEditState/reducer";
import { PondAreaStateType } from "../../../store/modules/master/pond/pondAreaState/reducer";
import { MasterGroupList } from "../../components/molecules/MasterGroupList";
import { MasterItem, MasterList } from "../../components/molecules/MasterList";
import { COLOR } from "../../styles/Color";

interface StateProps {
  pondAreas: PondArea[];
  ponds: Pond[];
  newOrEditState: NewOrEditStateType;
  pondAreaState: PondAreaStateType;
}

interface DispatchProps {
  readApiService: ReadApiService;
  editStateService: EditStateService;
  pondAreaStateService: PondAreaStateService;
}

type PondsListProps = StateProps & DispatchProps;

const NOT_SELECTED_ID = -1;

export class Wrapped extends React.Component<PondsListProps> {
  public render() {
    const { pond } = this.props.newOrEditState;
    const { pondArea } = this.props.pondAreaState;
    const selectedPondAreaId = pondArea !== null && isEntity(pondArea) ? pondArea.id : null;
    const filteredPonds = this.props.ponds.filter((p) => p.pondAreaId === selectedPondAreaId);
    return (
      <Outer>
        <Inner>
          <MasterGroupList
            items={this.getGroupItems()}
            selectedItemId={selectedPondAreaId === null ? NOT_SELECTED_ID : selectedPondAreaId}
            onClickItem={this.handleClickPondArea}
          />
        </Inner>
        <Inner>
          <MasterList
            items={filteredPonds}
            selectedItemId={pond !== null && isEntity(pond) ? pond.id : null}
            onClickItem={this.handleClickListItem}
          />
        </Inner>
      </Outer>
    );
  }

  private getGroupItems = (): MasterItem[] => {
    return this.props.pondAreas
      .map((area) => ({ id: area.id, name: area.name }))
      .concat({ id: NOT_SELECTED_ID, name: "未割り当て" });
  };

  private handleClickPondArea = (id: number) => {
    const selectedId = id === NOT_SELECTED_ID ? null : id;
    this.props.pondAreaStateService.selectPondArea(selectedId);
  };

  private handleClickListItem = (id: number) => {
    this.props.editStateService.selectPond(id);
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { newOrEditState, pondAreaState } = state.master.pond;
  return {
    pondAreas: state.api.pondArea.pondAreas.filter(isActiveEntity),
    ponds: state.api.pond.ponds.filter(isActiveEntity),
    newOrEditState,
    pondAreaState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    readApiService: new ReadApiService(dispatch),
    editStateService: new EditStateService(dispatch),
    pondAreaStateService: new PondAreaStateService(dispatch),
  };
};

export const PondsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);

const Outer = styled.div`
  display: flex;
  height: 100%;
`;
const Inner = styled.div`
  flex: 1 1 0;
  border-right: 1px solid ${COLOR.Gray.base};
  height: 100%;
  overflow-y: auto;
  &:last-child {
    border-right: none;
  }
`;
