import { useState, useEffect, useRef, useCallback, MutableRefObject } from "react";

const clearScrollTopFromLocalStore = (storeKey: string) => {
  localStorage.removeItem(storeKey);
};

const scrollTopToLocalStore = (storeKey: string, ref: MutableRefObject<number>) => {
  localStorage.setItem(storeKey, String(ref.current));
};
const scrollTopFromLocalStore = (storeKey: string): number | undefined => {
  const item = localStorage.getItem(storeKey);
  return item === null ? undefined : Number(item);
};

interface UseScrollTopOfVirtualList {
  scrollTop: number | undefined;
  onScroll: (props: { scrollTop: number }) => void;
}

export enum VirtualListScrollTopStoreKey {
  PondSelectionEachArea = "PondSelectionEachArea",
  PondSelection = "PondSelection",
}

export const useScrollTopOfVirtualList = (storeKey: string): UseScrollTopOfVirtualList => {
  const scrollTopRef = useRef(0);
  const [forceScrollTop, setForceScrollTop] = useState<number | undefined>(undefined);

  useEffect(() => {
    const scrollTop = scrollTopFromLocalStore(storeKey);

    if (scrollTop !== null) {
      clearScrollTopFromLocalStore(storeKey);
      setForceScrollTop(scrollTop);
    }

    return () => scrollTopToLocalStore(storeKey, scrollTopRef);
  }, []);

  useEffect(() => {
    if (forceScrollTop !== undefined) {
      setForceScrollTop(undefined);
    }
  }, [forceScrollTop]);

  const onScroll = useCallback(
    ({ scrollTop }) => {
      scrollTopRef.current = scrollTop;
    },
    [scrollTopRef]);

  return { scrollTop: forceScrollTop, onScroll };
};
