import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { PairReportModalStateService } from "../../../application/report/pondReport/pairReportModalState";
import { Pond } from "../../../domain/pond";
import { PondReport } from "../../../domain/pondReport";
import {
  isCarpQualityScaleType,
  isCarpRatioScaleType,
  isCarpSizeScaleType,
  isMalformationScaleType,
  ScaleType,
} from "../../../domain/scaleType";
import { ApplicationState } from "../../../store/modules";
import { PairReportModalStateType } from "../../../store/modules/report/pondReport/pairReportModalState/reducer";
import { PairReportTableModal } from "../../components/molecules/PairReportTableModal";

interface StateProps {
  pairReportModalState: PairReportModalStateType;
  pondReports: PondReport[];
  ponds: Pond[];
  carpSizeTypes: ScaleType[];
  carpQualityTypes: ScaleType[];
  malformationTypes: ScaleType[];
  carpRatioTypes: ScaleType[];
}

interface DispatchProps {
  pairReportModalStateService: PairReportModalStateService;
}

type PairReportModalProps = StateProps & DispatchProps;

export class Wrapped extends React.Component<PairReportModalProps> {
  public render() {
    return (
      <PairReportTableModal
        isOpen={this.props.pairReportModalState.isOpen}
        onClose={this.handleClickClose}
        pondReport={this.props.pairReportModalState.pondReport}
        ponds={this.props.ponds}
        pondReports={this.props.pondReports}
        carpSizeTypes={this.props.carpSizeTypes}
        carpQualityTypes={this.props.carpQualityTypes}
        malformationTypes={this.props.malformationTypes}
        carpRatioTypes={this.props.carpRatioTypes}
      />
    );
  }

  private handleClickClose = () => {
    this.props.pairReportModalStateService.closeModal();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { pondReport, pond, scaleType } = state.api;
  const { scaleTypes } = scaleType;
  const { pairReportModalState } = state.report.pondReport;
  return {
    pairReportModalState,
    pondReports: pondReport.pondReports,
    ponds: pond.ponds,
    carpSizeTypes: scaleTypes.filter(isCarpSizeScaleType),
    carpQualityTypes: scaleTypes.filter(isCarpQualityScaleType),
    malformationTypes: scaleTypes.filter(isMalformationScaleType),
    carpRatioTypes: scaleTypes.filter(isCarpRatioScaleType),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    pairReportModalStateService: new PairReportModalStateService(dispatch),
  };
};

export const PairReportModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);
