import { actionCreatorFactory } from "typescript-fsa";
import { NewCarpPairing, NewOrEditCarpPairing } from "../../../../../domain/carpPairing";
import { EditParentCarp, NewOrEditParentCarp, NewParentCarp, ParentCarp } from "../../../../../domain/parentCarp";
// Actions
const actionCreator = actionCreatorFactory("MasterParentCarpNewOrEditState");

const createParentCarp = actionCreator<CreateParentCarpPayload>("createParentCarp");
interface CreateParentCarpPayload {
  parentCarp: NewParentCarp;
}
const selectParentCarp = actionCreator<SelectParentCarpPayload>("selectParentCarp");
interface SelectParentCarpPayload {
  parentCarp: EditParentCarp;
}

const changeParentCarp = actionCreator<ChangeParentCarpPayload>("changeParentCarp");
interface ChangeParentCarpPayload {
  key: keyof NewOrEditParentCarp;
  value: any;
}

const changeCarpPairing = actionCreator<ChangeCarpPairingPayload>("changeCarpPairing");
interface ChangeCarpPairingPayload {
  uniqueKey: UniqueKey;
  key: keyof NewOrEditCarpPairing;
  value: any;
}
const addImageToCarpPairing = actionCreator<AddImageToCarpPairingPayload>("addImageToCarpPairing");
interface AddImageToCarpPairingPayload {
  uniqueKey: UniqueKey;
  imageId: number;
}
const removeImageToCarpPairing = actionCreator<RemoveImageToCarpPairingPayload>("removeImageToCarpPairing");
interface RemoveImageToCarpPairingPayload {
  uniqueKey: UniqueKey;
  imageId: number;
}

const addCarpPairing = actionCreator<AddCarpPairingPayload>("addCarpPairing");
interface AddCarpPairingPayload {
  carpPairing: NewCarpPairing;
}

const removeCarpPairing = actionCreator<RemoveCarpPairingPayload>("removeCarpPairing");
interface RemoveCarpPairingPayload {
  uniqueKey: UniqueKey;
}

export const cancelParentCarp = actionCreator<CancelParentCarpPayload>("cancelParentCarp");
interface CancelParentCarpPayload {
  parentCarp: NewOrEditParentCarp;
}

export const saveStart = actionCreator("saveStart");
export const saveSuccess = actionCreator<SaveSuccessPayload>("saveSuccess");
interface SaveSuccessPayload {
  parentCarp: ParentCarp | null;
}
export const saveFail = actionCreator("saveFail");

export const masterParentCarpNewOrEditStateActions = {
  createParentCarp,
  selectParentCarp,
  changeParentCarp,
  changeCarpPairing,
  addImageToCarpPairing,
  removeImageToCarpPairing,
  addCarpPairing,
  removeCarpPairing,
  cancelParentCarp,
  saveStart,
  saveSuccess,
  saveFail,
};

// Reducers
import { ChangeHandler, getInitialState, InitialHandler, NewOrEditStateType, SaveHandler } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";
import { UniqueKey } from "../../../../../domain/entity";

export const NewOrEditStateReducer = reducerWithInitialState<NewOrEditStateType>(getInitialState())
  .case(createParentCarp, (state, { parentCarp }) => {
    return InitialHandler.createParentCarp(state, parentCarp);
  })
  .case(selectParentCarp, (state, { parentCarp }) => {
    return InitialHandler.selectParentCarp(state, parentCarp);
  })
  .case(changeParentCarp, (state, { key, value }) => {
    return ChangeHandler.changeParentCarp(state, key, value);
  })
  .case(changeCarpPairing, (state, { uniqueKey, key, value }) => {
    return ChangeHandler.changeCarpPairing(state, uniqueKey, key, value);
  })
  .case(addImageToCarpPairing, (state, { uniqueKey, imageId }) => {
    return ChangeHandler.addImageToCarpPairing(state, uniqueKey, imageId);
  })
  .case(removeImageToCarpPairing, (state, { uniqueKey, imageId }) => {
    return ChangeHandler.removeImageToCarpPairing(state, uniqueKey, imageId);
  })
  .case(addCarpPairing, (state, { carpPairing }) => {
    return ChangeHandler.addCarpPairing(state, carpPairing);
  })
  .case(removeCarpPairing, (state, { uniqueKey }) => {
    return ChangeHandler.removeCarpPairing(state, uniqueKey);
  })
  .case(cancelParentCarp, (state, { parentCarp }) => {
    return SaveHandler.cancelParentCarp(state, parentCarp);
  })
  .case(saveStart, (state) => {
    return SaveHandler.saveStart(state);
  })
  .case(saveSuccess, (state, { parentCarp }) => {
    return SaveHandler.saveSuccess(state, parentCarp);
  })
  .case(saveFail, (state) => {
    return SaveHandler.saveFail(state);
  });
