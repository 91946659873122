import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { EditStateService } from "../../../application/master/pond/editState";
import { NewStateService } from "../../../application/master/pond/newState";
import { ReadApiService } from "../../../application/master/pond/readApi";
import { isActiveEntity, isEntity, isNewEntity } from "../../../domain/entity";
import { Pond } from "../../../domain/pond";
import { ApplicationState } from "../../../store/modules";
import { NewOrEditStateType } from "../../../store/modules/master/pond/newOrEditState/reducer";
import { MasterFormDefault } from "../../components/molecules/MasterForm";
import { PageHeader } from "../../components/molecules/PageHeader";
import { MainLayout } from "../Layout";
import { MasterLayout } from "../Layout/Master";
import { PondEditPage } from "./EditPage";
import { PondsList } from "./List";
import { PondNewPage } from "./NewPage";

interface StateProps {
  ponds: Pond[];
  newOrEditState: NewOrEditStateType;
}

interface DispatchProps {
  readApiService: ReadApiService;
  newStateService: NewStateService;
  editStateService: EditStateService;
}

type PondsPageProps = StateProps & DispatchProps;

export class Wrapped extends React.Component<PondsPageProps> {
  public componentDidMount(): void {
    this.props.readApiService.init().then(() => {
      const { pond } = this.props.newOrEditState;
      if (pond !== null && isEntity(pond)) {
        this.props.editStateService.selectPond(pond.id);
      }
    });
  }

  public render() {
    const pond = this.props.newOrEditState.pond;
    let form;
    if (pond !== null) {
      form = isEntity(pond) ? <PondEditPage /> : <PondNewPage />;
    } else {
      form = (
        <MasterFormDefault
          onClickAdd={pond !== null && isNewEntity(pond) ? null : this.handleClickAddPond}
          onClickDelete={pond !== null && isEntity(pond) ? this.handleClickDeletePond : null}
        />
      );
    }

    return (
      <MainLayout>
        <MasterLayout header={<PageHeader title={"野池"} />} list={<PondsList />} form={form} listColumn={2} />
      </MainLayout>
    );
  }

  private handleClickAddPond = () => {
    this.props.newStateService.createPond();
  };

  private handleClickDeletePond = () => {
    this.props.editStateService.deletePond();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { newOrEditState } = state.master.pond;
  return {
    ponds: state.api.pond.ponds.filter(isActiveEntity),
    newOrEditState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    readApiService: new ReadApiService(dispatch),
    newStateService: new NewStateService(dispatch),
    editStateService: new EditStateService(dispatch),
  };
};

export const PondsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);
