import { Entity, generateUid, isEntity, isNewEntity, NewEntity } from "../entity";
import { getValidation, isEmptyString, isLimitedNumber, Validation, Validator } from "../validation";

interface HerbicideReportAttribute extends Record<string, unknown> {
  herbicideId: null | number;
  useMethodTypeId: null | number;
  amount: string;
}

export interface NewHerbicideReport extends HerbicideReportAttribute, NewEntity {}
export interface EditHerbicideReport extends HerbicideReportAttribute, Entity {}

export type NewOrEditHerbicideReport = NewHerbicideReport | EditHerbicideReport;
export type HerbicideReport = EditHerbicideReport;

// service
const NEW_ENTITY_PREFIX = "her-";

export function createNewHerbicideReport(useMethodTypeId: number): NewHerbicideReport {
  return {
    uid: generateUid(NEW_ENTITY_PREFIX),
    herbicideId: null,
    useMethodTypeId,
    amount: "",
  };
}

export function isNewHerbicideReport(report: NewOrEditHerbicideReport): report is NewHerbicideReport {
  return isNewEntity(report);
}

export function isEditHerbicideReport(report: NewOrEditHerbicideReport): report is EditHerbicideReport {
  return isEntity(report);
}

export function copyHerbicideReport(
  source: NewOrEditHerbicideReport,
  target: NewOrEditHerbicideReport
): NewOrEditHerbicideReport {
  return {
    ...target,
    herbicideId: source.herbicideId,
    useMethodTypeId: source.useMethodTypeId,
    amount: source.amount,
  };
}
/*** Validator ***/
export class HerbicideReportValidator extends Validator<NewOrEditHerbicideReport> {
  public validate(entity: NewOrEditHerbicideReport) {
    const amountValidation = validateAmount(entity);
    if (amountValidation) {
      this.addMessages(amountValidation);
    }
    const useMethodTypeValidation = validateUseMethodType(entity);
    if (useMethodTypeValidation) {
      this.addMessages(useMethodTypeValidation);
    }
    const herbicideValidation = validateHerbicide(entity);
    if (herbicideValidation) {
      this.addMessages(herbicideValidation);
    }
  }
}

function validateAmount({ amount }: NewOrEditHerbicideReport): Validation | null {
  if (isEmptyString(amount)) {
    return getValidation("量は必須です。入力してください。");
  }
  if (!isLimitedNumber(amount, 9, 4)) {
    return getValidation("量は整数部9桁、小数部4桁で入力してください。");
  }
  return null;
}

function validateUseMethodType({ useMethodTypeId }: NewOrEditHerbicideReport): Validation | null {
  if (useMethodTypeId === null) {
    return getValidation("単位は必須です。");
  }
  return null;
}

function validateHerbicide({ herbicideId }: NewOrEditHerbicideReport): Validation | null {
  if (herbicideId === null) {
    return getValidation("除草剤・薬品は必須です。");
  }
  return null;
}
