import { Dispatch } from "redux";
import { Feed } from "../../../domain/feed";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { FeedRepository } from "../../../infrastracture/feed/repository";
import { ApplicationState } from "../../../store/modules";
import { apiFeedActions } from "../../../store/modules/api/feed/ducks";

interface IFeedApiService extends IApplicationService {
  init: () => void;
  fetchUnFetched: () => void;
}

export class FeedApiService implements IFeedApiService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError((dispatch) => dispatch(apiFeedActions.fetchFail()))
  public async init() {
    this.dispatch(apiFeedActions.fetchStart());
    const feeds: Feed[] = await new FeedRepository().getFeeds();
    this.dispatch(apiFeedActions.fetchComplete({ feeds }));
  }

  @catchApplicationError((dispatch) => dispatch(apiFeedActions.fetchFail()))
  public async fetchUnFetched() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      if (getState().api.feed.isFetched) {
        return;
      }
      this.dispatch(apiFeedActions.fetchStart());
      const feeds: Feed[] = await new FeedRepository().getFeeds();
      this.dispatch(apiFeedActions.fetchComplete({ feeds }));
    });
  }
}
