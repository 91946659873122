import { Input as _Input } from "semantic-ui-react";
import styled from "styled-components";
import { DIMEN } from "../../../styles/Dimen";

export const ThinInput = styled(_Input)`
  width: 100%;
  > input {
    padding: ${DIMEN.X0_5} ${DIMEN.X0_5} !important;
  }
`;
