import { getAdultSelectionName, NewOrEditAdultCarpReport } from "../adultCarpReport";
import { getSuffixOfMovingReport, isNewCarpMovingReport, NewOrEditCarpMovingReport } from "../carpMovingReport";
import { getSelectionName, NewOrEditCarpSelectionReport } from "../carpSelectionReport";
import { isNewEntity, sortUniqueKeyAsc } from "../entity";
import { Pond } from "../pond";
import { PondReport } from "../pondReport";

export type FrySelectionReportVM = (NewOrEditCarpSelectionReport | NewOrEditCarpMovingReport) & {
  name: string;
  subNumber: number;
  carpSizeTypeId: null | number;
  carpQualityTypeId: null | number;
  malformationTypeId: null | number;
  carpRatioTypeId: null | number;
  carpScore: string;
};

export function sortFrySelectionReportVM(a: FrySelectionReportVM, b: FrySelectionReportVM) {
  if (a.selectionNumber !== b.selectionNumber) {
    return a.selectionNumber - b.selectionNumber;
  }
  if (a.subNumber !== b.subNumber) {
    return a.subNumber - b.subNumber;
  }
  return sortUniqueKeyAsc(a, b);
}

export function isCarpMovingReport(
  report: NewOrEditCarpSelectionReport | NewOrEditCarpMovingReport
): report is NewOrEditCarpMovingReport {
  return "movedId" in report && "moverId" in report;
}

export function mapSelectionReportToFrySelectionReportVM(report: NewOrEditCarpSelectionReport): FrySelectionReportVM {
  return {
    ...report,
    name: getSelectionName(report.selectionNumber),
    subNumber: 0,
  };
}

export function mapMovingReportToFrySelectionReportVM(
  report: NewOrEditCarpMovingReport,
  ponds: Pond[],
  pondReports: PondReport[]
): FrySelectionReportVM {
  const nameSuffix = getSuffixOfMovingReport(report);
  const targetId = report.movedId || report.moverId;
  const pondReport = pondReports.find(
    (pr) =>
      !!pr.carpMovingReports.find((cmr) => {
        if (isNewCarpMovingReport(cmr)) {
          return false;
        }
        return cmr.id === targetId;
      })
  );
  const pond = !!pondReport ? ponds.find((p) => p.id === pondReport.pondId) : null;
  return {
    ...report,
    name: `${!!pond ? pond.name : ""}${nameSuffix}`,
    carpSizeTypeId: null,
    carpQualityTypeId: null,
    malformationTypeId: null,
    carpRatioTypeId: null,
    carpScore: "",
  };
}

export function ToFrySelectionReportVM(
  reports: Array<NewOrEditCarpSelectionReport | NewOrEditCarpMovingReport>,
  ponds: Pond[],
  pondReports: PondReport[]
): FrySelectionReportVM[] {
  return reports
    .map((r) => ({ ...r, name: "", subNumber: isCarpMovingReport(r) ? r.subNumber : 0 } as FrySelectionReportVM))
    .sort(sortFrySelectionReportVM)
    .map((r) => {
      if (isCarpMovingReport(r)) {
        return mapMovingReportToFrySelectionReportVM(r, ponds, pondReports);
      }
      return mapSelectionReportToFrySelectionReportVM(r);
    });
}

export function getFryAmount(selectionReportVMs: FrySelectionReportVM[]): number {
  return selectionReportVMs.reduce((sum: number, vm) => {
    if (isCarpMovingReport(vm)) {
      if (vm.moverId !== null) {
        return sum - Number(vm.amount);
      }
      if (vm.movedId !== null) {
        return sum + Number(vm.amount);
      }
      if (isNewEntity(vm) && vm.pairPondReportId !== null) {
        return sum - Number(vm.amount);
      }
    }
    return sum + Number(vm.amount);
  }, 0);
}

export type FrySelectionReportSummaryVM = NewOrEditCarpSelectionReport & {
  name: string;
  subNumber: number;
};

export function ToFrySelectionReportSummaryVM(
  selectionReports: NewOrEditCarpSelectionReport[],
  movingReports: NewOrEditCarpMovingReport[]
): FrySelectionReportSummaryVM[] {
  return selectionReports
    .map((r) => ({ ...r, name: "", subNumber: 0 }))
    .sort(sortFrySelectionReportVM)
    .map((r) => {
      r.name = getSelectionName(r.selectionNumber);

      const movingReportsOfSelection = movingReports.filter((mr) => mr.selectionNumber === r.selectionNumber);
      if (movingReportsOfSelection.length !== 0) {
        const sumAmount = movingReportsOfSelection.reduce((sum, mr) => {
          if (mr.moverId !== null) {
            return sum - Number(mr.amount);
          }
          if (mr.movedId !== null) {
            return sum + Number(mr.amount);
          }
          return sum;
        }, Number(r.amount || 0));
        r.amount = sumAmount + "";
      }
      return r;
    });
}

export type AdultSelectionReportVM = NewOrEditAdultCarpReport & {
  name: string;
};

export function mapAdultCarpReportToAdultSelectionReportVM(report: NewOrEditAdultCarpReport): AdultSelectionReportVM {
  const name = getAdultSelectionName(report.selectionNumber);
  return { ...report, name };
}

export function ToAdultSelectionReportVM(reports: NewOrEditAdultCarpReport[]): AdultSelectionReportVM[] {
  return reports.map(mapAdultCarpReportToAdultSelectionReportVM);
}
