import { default as moment } from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NavigationService } from "../../../../application/mobile/navigation";
import { ListStateService } from "../../../../application/mobile/pondReport/listState";
import { NewOrEditStateService } from "../../../../application/mobile/pondReport/newOrEditState";
import { Pond } from "../../../../domain/pond";
import { EditPondReport, getPondReportByDate, PondReport } from "../../../../domain/pondReport";
import { isFryPondType, PondType } from "../../../../domain/pondType";
import { ApplicationState } from "../../../../store/modules";
import { NavigationStateType } from "../../../../store/modules/mobile/navigation/reducer";
import { ListStateType } from "../../../../store/modules/mobile/pondReport/listState/reducer";
import { NewOrEditStateType } from "../../../../store/modules/mobile/pondReport/newOrEditState/reducer";
import { EmptyMessage } from "../../../components/atoms/Message";
import { Spinner } from "../../../components/atoms/Spinner";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { MobileLayout } from "../../Layout/Mobile";
import { AdultPondReportSelectionPage } from "./AdultPondReport";
import { FryPondReportSelectionPage } from "./FryPondReport";

interface StateProps {
  ponds: Pond[];
  pondTypes: PondType[];
  pondReports: PondReport[];
  navigationState: NavigationStateType;
  listState: ListStateType;
  newOrEditState: NewOrEditStateType;
  isLoaded: boolean;
}

interface DispatchProps {
  listStateService: ListStateService;
  newOrEditStateService: NewOrEditStateService;
  navigationService: NavigationService;
}

type PondSelectionPageProps = StateProps & DispatchProps & RouteComponentProps;

export class Wrapped extends React.Component<PondSelectionPageProps> {
  public componentWillMount(): void {
    if (this.props.navigationState.selectedPondId === null) {
      this.props.history.push("/");
      return;
    }
    if (!this.props.isLoaded) {
      this.props.history.push("/reports/menu");
      return;
    }
    this.props.newOrEditStateService.resetPondReport();
    this.props.listStateService.init().then(() => {
      this.props.newOrEditStateService.initPondReport();
    });
  }

  public render() {
    const pond = this.props.ponds.find((p) => p.id === this.props.navigationState.selectedPondId);
    if (!pond) {
      return this.renderEmpty();
    }
    const currentPondReport = this.props.newOrEditState.pondReport;
    const pondType = !!currentPondReport && this.props.pondTypes.find((t) => t.id === currentPondReport.pondTypeId);
    if (currentPondReport === null || !pondType) {
      return this.renderEmpty(pond.name);
    }
    const prev = moment(this.props.navigationState.selectedDate).add(-1, "year");
    const prevPondReport = getPondReportByDate(this.props.pondReports, pond.id, prev.toDate()) as EditPondReport;

    if (isFryPondType(pondType)) {
      return (
        <FryPondReportSelectionPage
          pond={pond}
          currentPondReport={currentPondReport as EditPondReport}
          prevPondReport={prevPondReport}
        />
      );
    }
    return (
      <AdultPondReportSelectionPage
        pond={pond}
        currentPondReport={currentPondReport as EditPondReport}
        prevPondReport={prevPondReport}
      />
    );
  }

  public renderEmpty(title: string = "") {
    const { newOrEditState, listState } = this.props;
    const isLoading = listState.isFetching;
    const isEmpty = !isLoading && newOrEditState.pondReport === null && newOrEditState.isError !== null;
    return (
      <MobileLayout>
        <BaseNavigationHeader title={title} onClickReturn={this.handleClickReturn} />
        <BaseNavigationContent>
          {isLoading && <Spinner />}
          {isEmpty && (
            <EmptyMessage>
              選別・放鯉・池揚げ記録がありません。
              <br />
              予め選別・放鯉・池揚げ記録を作成してください。
            </EmptyMessage>
          )}
        </BaseNavigationContent>
      </MobileLayout>
    );
  }

  private handleClickReturn = () => {
    this.props.history.goBack();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { pond, pondType, pondReport } = state.api;
  return {
    ponds: pond.ponds,
    pondTypes: pondType.pondTypes,
    pondReports: pondReport.pondReports,
    navigationState: state.mobile.navigation,
    listState: state.mobile.pondReportState.listState,
    newOrEditState: state.mobile.pondReportState.newOrEditStateType,
    isLoaded: pond.isFetched,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    listStateService: new ListStateService(dispatch),
    newOrEditStateService: new NewOrEditStateService(dispatch),
    navigationService: new NavigationService(dispatch),
  };
};

export const PondReportSelectionPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
