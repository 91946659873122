import { CarpVarietyType } from "../../domain/carpVarietyType";
import * as api from "../../libs/client";
import { translateApiToCarpVarietyType } from "./translate";

interface ICarpVarietyTypeRepository {
  getCarpVarietyTypes: () => Promise<CarpVarietyType[]>;
}

export class CarpVarietyTypeRepository implements ICarpVarietyTypeRepository {
  public async getCarpVarietyTypes(): Promise<CarpVarietyType[]> {
    const carpVarietyTypesApi = new api.CarpVarietyTypesApi(api.serverConfig);
    const response = await carpVarietyTypesApi.getCarpVarietyTypes();
    return response.data.map((d) => translateApiToCarpVarietyType(d));
  }
}
