import { actionCreatorFactory } from "typescript-fsa";
// Actions
const actionCreator = actionCreatorFactory("MasterParentCarpListState");

const selectYear = actionCreator<SelectYearPayload>("selectYear");
interface SelectYearPayload {
  selectedYear: number;
}

export const masterParentCarpListStateActions = {
  selectYear,
};

// Reducers
import { ChangeHandler, getInitialState, ListStateType } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const ListStateReducer = reducerWithInitialState<ListStateType>(getInitialState()).case(
  selectYear,
  (state, { selectedYear }) => {
    return ChangeHandler.selectYear(state, selectedYear);
  }
);
