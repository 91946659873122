import { getValidation, isEmptyString, Validation, Validator } from "../validation";

interface LoginForm {
  accountName: string;
  password: string;
}

/*** Validator ***/
export class LoginValidator extends Validator<LoginForm> {
  public validate(entity: LoginForm) {
    const accountNameValidation = validateAccountName(entity);
    if (accountNameValidation) {
      this.addMessages(accountNameValidation);
    }
    const passwordValidation = validatePassword(entity);
    if (passwordValidation) {
      this.addMessages(passwordValidation);
    }
  }
}

function validateAccountName({ accountName }: LoginForm): Validation | null {
  if (isEmptyString(accountName)) {
    return getValidation("アカウント名を入力してください。");
  }
  return null;
}

function validatePassword({ password }: LoginForm): Validation | null {
  if (isEmptyString(password)) {
    return getValidation("パスワードを入力してください。");
  }
  return null;
}
