import { Dispatch } from "redux";
import { isEntity } from "../../../domain/entity";
import { PondAreaValidator } from "../../../domain/pondArea";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { PondAreaRepository } from "../../../infrastracture/pondArea/repository";
import { ApplicationState } from "../../../store/modules";
import { apiPondAreaActions } from "../../../store/modules/api/pondArea/ducks";
import { masterPondAreaNewOrEditStateActions } from "../../../store/modules/master/pondArea/newOrEditState/ducks";
import { notificationAlertStateActions } from "../../../store/modules/notification/alert/ducks";
import { notificationQueueStateActions } from "../../../store/modules/notification/queue/ducks";
import { PondApiService } from "../../api/pond";

interface IEditStateService extends IApplicationService {
  selectPondArea: (pondAreaId: number) => void;
  changeName: (name: string) => void;
  changePonds: (pondIds: number[]) => void;
  cancelPondArea: () => void;
  savePondArea: () => void;
}

export class EditStateService implements IEditStateService {
  private readonly pondApiService: PondApiService;

  public constructor(private dispatch: Dispatch<any>) {
    this.pondApiService = new PondApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public selectPondArea(pondAreaId: number) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const { api } = getState();
      const pondArea = api.pondArea.pondAreas.find((area) => area.id === pondAreaId);
      if (!pondArea) {
        throw new ApplicationError("エリアが見つかりませんでした。");
      }
      this.dispatch(masterPondAreaNewOrEditStateActions.selectPondArea({ pondArea }));
    });
  }

  @catchApplicationError()
  public changeName(name: string) {
    this.dispatch(masterPondAreaNewOrEditStateActions.changePondArea({ key: "name", value: name }));
  }

  @catchApplicationError()
  public changePonds(pondIds: number[]) {
    this.dispatch(masterPondAreaNewOrEditStateActions.changePondArea({ key: "pondIds", value: pondIds }));
  }

  @catchApplicationError()
  public cancelPondArea() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const { api, master } = getState();
      const editPondArea = master.pondArea.newOrEditState.pondArea;
      if (editPondArea === null || !isEntity(editPondArea)) {
        return;
      }
      const pondArea = api.pondArea.pondAreas.find((area) => area.id === editPondArea.id);
      if (!pondArea) {
        throw new ApplicationError("エリアが見つかりませんでした。");
      }
      this.dispatch(masterPondAreaNewOrEditStateActions.cancelPondArea({ pondArea }));
    });
  }

  @catchApplicationError((dispatch) => dispatch(masterPondAreaNewOrEditStateActions.saveFail()))
  public async savePondArea() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(masterPondAreaNewOrEditStateActions.saveStart());
      const { newOrEditState } = getState().master.pondArea;
      const editPondArea = newOrEditState.pondArea;
      if (editPondArea === null || !isEntity(editPondArea)) {
        throw new ApplicationError("エリアが見つかりませんでした。");
      }
      const validator = new PondAreaValidator();
      validator.validate(editPondArea);
      if (!validator.isValid()) {
        this.dispatch(notificationAlertStateActions.showErrorMessage({ errorMessage: validator.getMessages() }));
        this.dispatch(masterPondAreaNewOrEditStateActions.saveFail());
        return;
      }
      const savedPondArea = await new PondAreaRepository().putPondArea(editPondArea);
      this.dispatch(masterPondAreaNewOrEditStateActions.saveSuccess({ pondArea: savedPondArea }));
      this.dispatch(apiPondAreaActions.updatePondArea({ pondArea: savedPondArea }));
      this.pondApiService.updateByPondArea(savedPondArea);
      this.dispatch(notificationQueueStateActions.addSaveMessage({ itemName: "エリア" }));
    });
  }

  @catchApplicationError((dispatch) => dispatch(masterPondAreaNewOrEditStateActions.saveFail()))
  public async deletePondArea() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(masterPondAreaNewOrEditStateActions.saveStart());
      const { newOrEditState } = getState().master.pondArea;
      const editPondArea = newOrEditState.pondArea;
      if (editPondArea === null || !isEntity(editPondArea)) {
        throw new ApplicationError("エリアが見つかりませんでした。");
      }
      const savedPondArea = await new PondAreaRepository().deletePondArea(editPondArea);
      this.dispatch(masterPondAreaNewOrEditStateActions.saveSuccess({ pondArea: null }));
      this.dispatch(apiPondAreaActions.deletePondArea({ pondArea: savedPondArea }));
      this.pondApiService.updateByPondArea(savedPondArea);
      this.dispatch(notificationQueueStateActions.addDeleteMessage({ itemName: "エリア" }));
      await this.pondApiService.init();
    });
  }
}
