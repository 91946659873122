import * as _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NavigationStateService } from "../../../application/report/navigation";
import { HerbicideReportStateService } from "../../../application/report/workReport/herbicideReportState";
import { NewOrEditStateService } from "../../../application/report/workReport/newOrEditState";
import { isSameFiscalYear } from "../../../domain/calendar";
import { getUniqueKey, isActiveEntity, isEntity, isNewEntity } from "../../../domain/entity";
import { Herbicide } from "../../../domain/herbicide";
import { Pond } from "../../../domain/pond";
import { PondArea } from "../../../domain/pondArea";
import { PondType } from "../../../domain/pondType";
import { isHerbicideReportGroup } from "../../../domain/reportGroup";
import { SearchQuery } from "../../../domain/searchQuery";
import { UseMethodType } from "../../../domain/useMethodType";
import { WorkReport } from "../../../domain/workReport";
import { ApplicationState } from "../../../store/modules";
import { ImageStateType } from "../../../store/modules/api/image/reducer";
import { imageGalleryStateActions } from "../../../store/modules/imageGallery/ducks";
import { notificationQueueStateActions } from "../../../store/modules/notification/queue/ducks";
import { NavigationStateType } from "../../../store/modules/report/navigation/reducer";
import { NewOrEditStateType } from "../../../store/modules/report/workReport/newOrEditState/reducer";
import { ConfirmModal } from "../../components/molecules/ConfirmModal";
import { PageHeader } from "../../components/molecules/PageHeader";
import { WorkReportFormHerbicide } from "../../components/organisms/WorkReportFormHerbicide";
import { MainLayout } from "../Layout";
import { ReportLayout } from "../Layout/Report";
import { DefaultPage, SpinnerPage } from "./DefaultPage";
import { PondsReportsList } from "./PondsReportsList";

interface StateProps {
  ponds: Pond[];
  pondAreas: PondArea[];
  pondTypes: PondType[];
  herbicides: Herbicide[];
  useMethodTypes: UseMethodType[];
  workReports: WorkReport[];
  newOrEditState: NewOrEditStateType;
  navigationState: NavigationStateType;
  imageState: ImageStateType;
}

interface DispatchProps {
  herbicideReportStateService: HerbicideReportStateService;
  newOrEditStateService: NewOrEditStateService;
  navigationStateService: NavigationStateService;
  showImageGallery: (imageIds: number[], imageId: number) => void;

  notifySaveMessage: () => void;
  notifyDeleteMessage: () => void;
}

type WorkReportHerbicidePageProps = StateProps & DispatchProps & RouteComponentProps;

interface WorkReportHerbicidePageState {
  isOpenDeleteConfirm: boolean;
}

export class Wrapped extends React.Component<WorkReportHerbicidePageProps, WorkReportHerbicidePageState> {
  constructor(props: WorkReportHerbicidePageProps) {
    super(props);

    this.state = {
      isOpenDeleteConfirm: false,
    };
  }

  public componentWillMount(): void {
    this.initStateOnMounted();
  }

  public render() {
    return (
      <MainLayout>
        <ReportLayout
          header={<PageHeader title={"除草剤（池）・薬品を記録"} />}
          list={this.renderList()}
          form={this.renderForm()}
          listColumn={3}
        />
      </MainLayout>
    );
  }

  private async initStateOnMounted() {
    await Promise.all([
      this.props.navigationStateService.fetchApi(),
      this.props.herbicideReportStateService.fetchApi(),
    ]);
    if (
      this.props.navigationState.selectedPondId !== null &&
      this.props.newOrEditState.selectedReportKey !== "herbicide"
    ) {
      await Promise.all([
        this.props.newOrEditStateService.initWorkReport(),
        this.props.herbicideReportStateService.fetchLatestHerbicideReport(),
      ]);
      this.props.herbicideReportStateService.initHerbicideReport();
    }
  }

  private renderList() {
    const { workReport, selectedUniqueKey } = this.props.newOrEditState;
    const entity = workReport ? this.props.workReports.find((wr) => wr.id === getUniqueKey(workReport)) : null;

    const herbicideReports = entity ? entity.herbicideReports : [];
    const items = herbicideReports.map((fr) => {
      const herbicide = this.props.herbicides.find((f) => f.id === fr.herbicideId);

      return {
        id: getUniqueKey(fr) as number,
        name: herbicide ? herbicide.name : "",
      };
    });
    const selectedItemId = typeof selectedUniqueKey === "number" ? selectedUniqueKey : null;

    return (
      <PondsReportsList
        items={items}
        selectedItemId={selectedItemId}
        onClickItem={this.handleClickItem}
        onClickPond={this.handleClickPond}
      />
    );
  }

  private renderForm() {
    const pond = this.props.ponds.find((p) => p.id === this.props.navigationState.selectedPondId);
    const { newOrEditState, navigationState } = this.props;
    const { selectedPondId, selectedDate } = navigationState;
    if (selectedPondId === null) {
      this.renderEmpty();
    }
    const { workReport, selectedReportKey, selectedUniqueKey, isSaving } = newOrEditState;

    const herbicideReport =
      selectedReportKey === "herbicide" &&
      !!workReport &&
      workReport.herbicideReports.find((fr) => getUniqueKey(fr) === selectedUniqueKey);

    if (!pond) {
      return this.renderEmpty();
    }

    if (!herbicideReport || workReport === null) {
      return this.renderSpinner();
    }

    const herbicides = this.props.herbicides.filter(
      (herbicide) => isActiveEntity(herbicide) || herbicideReport.herbicideId === herbicide.id
    );

    return (
      <>
        <WorkReportFormHerbicide
          title={this.getFormTitle()}
          date={selectedDate}
          herbicides={herbicides}
          useMethodTypes={this.props.useMethodTypes}
          pondTypes={this.props.pondTypes}
          herbicideId={herbicideReport.herbicideId}
          amount={herbicideReport.amount}
          useMethodTypeId={herbicideReport.useMethodTypeId}
          note={workReport.note}
          imageIds={workReport.imageIds}
          pondTypeId={workReport.pondTypeId}
          onChangeDate={this.handleChangeDate}
          onChangeHerbicide={this.handleChangeHerbicide}
          onChangeAmount={this.handleChangeAmount}
          onChangeUseMethodType={this.handleChangeUseMethodType}
          onClickAmountUp={_.noop}
          onClickAmountDown={_.noop}
          onChangeNote={this.handleChangeNote}
          onChangeFile={this.handleChangeFile}
          onClickImage={this.handleClickImage}
          onClickImageRemove={this.handleClickImageRemove}
          onChangePondType={isSameFiscalYear(workReport.date, new Date()) ? null : this.handleChangePondType}
          onClickSave={this.handleClickSave}
          onClickCancel={this.handleClickCancel}
          onClickAdd={isNewEntity(herbicideReport) ? null : this.handleClickAdd}
          onClickDelete={isNewEntity(herbicideReport) ? null : this.openDeleteConfirmModal}
          isSaving={isSaving}
          disabledSave={this.props.imageState.isSaving}
        />

        <ConfirmModal
          isOpen={this.state.isOpenDeleteConfirm}
          onClose={this.closeDeleteConfirmModal}
          onClickCancel={this.closeDeleteConfirmModal}
          onClickOk={this.handleClickDelete}
          cancelText={"キャンセル"}
          okText={"削除"}
          confirmMessage={"除草剤（池）・薬品記録を削除してもよろしいでしょうか？"}
        />
      </>
    );
  }

  private getFormTitle(): string {
    const { ponds, pondAreas, navigationState } = this.props;
    const { selectedPondId, selectedPondAreaId } = navigationState;
    const pond = ponds.find((p) => p.id === selectedPondId);
    const pondArea = pondAreas.find((a) => a.id === selectedPondAreaId);

    return `${pondArea ? pondArea.name : ""} ${pond ? pond.name : ""}`;
  }

  private renderEmpty() {
    return <DefaultPage />;
  }

  private renderSpinner() {
    return <SpinnerPage />;
  }

  private handleClickPond = async (id: number) => {
    this.props.navigationStateService.selectPond(id);
    await Promise.all([
      this.props.newOrEditStateService.initWorkReport(),
      this.props.herbicideReportStateService.fetchLatestHerbicideReport(),
    ]);
    this.props.herbicideReportStateService.initHerbicideReport();
  };

  private handleClickItem = (id: number) => {
    this.props.herbicideReportStateService.selectHerbicideReport(id);
  };

  private handleChangeDate = async (date: Date) => {
    this.props.navigationStateService.selectDate(date);
    await Promise.all([
      this.props.newOrEditStateService.initWorkReport(),
      this.props.herbicideReportStateService.fetchLatestHerbicideReport(),
    ]);
    this.props.herbicideReportStateService.initHerbicideReport();
  };

  private handleChangeHerbicide = (id: null | number) => {
    this.props.herbicideReportStateService.changeHerbicide(id);
  };

  private handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const num = Number(e.target.value);
    if (!Number.isNaN(num)) {
      this.props.herbicideReportStateService.changeAmount(e.target.value);
    }
  };

  private handleChangeUseMethodType = (id: null | number) => {
    this.props.herbicideReportStateService.changeUseMethodType(id);
  };

  private handleChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.props.newOrEditStateService.changeNote(e.target.value);
  };

  private handleClickImage = (imageIds: number[], imageId: number) => {
    this.props.showImageGallery(imageIds, imageId);
  };

  private handleClickImageRemove = (id: number) => {
    this.props.newOrEditStateService.removeImage(id);
  };

  private handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length !== 0) {
      this.props.newOrEditStateService.addImage(e.target.files[0]);
    }
  };

  private handleChangePondType = (id: number | null) => {
    if (id === null) {
      return;
    }
    this.props.newOrEditStateService.changePondType(id);
  };

  private handleClickSave = async () => {
    await this.props.newOrEditStateService.saveWorkReport();
    if (this.props.newOrEditState.isSaved) {
      this.props.notifySaveMessage();
      const returnUrl = SearchQuery.getReturnURLFromState(this.props.location.search);
      if (!returnUrl) {
        return;
      }
      const url = SearchQuery.createURLFromState("", this.props.location.search);
      if (url !== "") {
        this.props.history.push(url);
        return;
      }
    }
  };

  private handleClickCancel = () => {
    const { workReport, selectedUniqueKey } = this.props.newOrEditState;
    const herbicideReport =
      workReport && workReport.herbicideReports.find((fr) => getUniqueKey(fr) === selectedUniqueKey);
    this.props.newOrEditStateService.cancelWorkReport();
    if (herbicideReport && isEntity(herbicideReport)) {
      this.props.herbicideReportStateService.selectHerbicideReport(getUniqueKey(herbicideReport));
    } else {
      this.props.herbicideReportStateService.addHerbicideReport();
    }
  };

  private handleClickAdd = () => {
    this.props.herbicideReportStateService.addHerbicideReport();
  };

  private handleClickDelete = async () => {
    await this.props.newOrEditStateService.deleteHerbicideReport();
    this.closeDeleteConfirmModal();
    this.props.notifyDeleteMessage();
    const returnUrl = SearchQuery.getReturnURLFromState(this.props.location.search);
    const url = SearchQuery.createURLFromState("", this.props.location.search);
    if (!returnUrl || url === "") {
      this.props.herbicideReportStateService.initHerbicideReport();
      return;
    } else {
      this.props.history.push(url);
    }
  };

  private closeDeleteConfirmModal = () => {
    this.setState({ isOpenDeleteConfirm: false });
  };
  private openDeleteConfirmModal = () => {
    this.setState({ isOpenDeleteConfirm: true });
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { workReport, navigation } = state.report;
  return {
    ponds: state.api.pond.ponds,
    pondAreas: state.api.pondArea.pondAreas,
    pondTypes: state.api.pondType.pondTypes,
    herbicides: state.api.herbicide.herbicides,
    useMethodTypes: state.api.useMethodType.useMethodTypes.filter(isHerbicideReportGroup),
    workReports: state.api.workReport.workReports,
    newOrEditState: workReport.newOrEditStateType,
    navigationState: navigation,
    imageState: state.api.image,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    herbicideReportStateService: new HerbicideReportStateService(dispatch),
    newOrEditStateService: new NewOrEditStateService(dispatch),
    navigationStateService: new NavigationStateService(dispatch),
    showImageGallery: (imageIds, imageId) =>
      dispatch(imageGalleryStateActions.showImages({ imageIds, initialImageId: imageId })),
    notifySaveMessage: () =>
      dispatch(notificationQueueStateActions.addSaveMessage({ itemName: "除草剤（池）・薬品記録" })),
    notifyDeleteMessage: () =>
      dispatch(notificationQueueStateActions.addDeleteMessage({ itemName: "除草剤（池）・薬品記録" })),
  };
};

export const WorkReportHerbicidePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
