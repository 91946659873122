import { combineReducers } from "redux";

import { CarpVarietyTypeStateType } from "./carpVarietyType/reducer";
import { EnvironmentReportStateType } from "./environmentReport/reducer";
import { FeedStateType } from "./feed/reducer";
import { FeedReasonTypeStateType } from "./feedReasonType/reducer";
import { FertilizerStateType } from "./fertilizer/reducer";
import { HerbicideStateType } from "./herbicide/reducer";
import { ImageStateType } from "./image/reducer";
import { ParentCarpStateType } from "./parentCarp/reducer";
import { PondStateType } from "./pond/reducer";
import { PondAreaStateType } from "./pondArea/reducer";
import { PondOwnerTypeStateType } from "./pondOwnerType/reducer";
import { PondReportStateType } from "./pondReport/reducer";
import { PondTypeStateType } from "./pondType/reducer";
import { ScaleTypeStateType } from "./scaleType/reducer";
import { UseMethodTypeStateType } from "./useMethodType/reducer";
import { WorkerStateType } from "./worker/reducer";
import { WorkReportStateType } from "./workReport/reducer";

export interface ApiState {
  carpVarietyType: CarpVarietyTypeStateType;
  environmentReport: EnvironmentReportStateType;
  feed: FeedStateType;
  feedReasonType: FeedReasonTypeStateType;
  fertilizer: FertilizerStateType;
  herbicide: HerbicideStateType;
  image: ImageStateType;
  parentCarp: ParentCarpStateType;
  pond: PondStateType;
  pondArea: PondAreaStateType;
  pondOwnerType: PondOwnerTypeStateType;
  pondReport: PondReportStateType;
  pondType: PondTypeStateType;
  scaleType: ScaleTypeStateType;
  useMethodType: UseMethodTypeStateType;
  worker: WorkerStateType;
  workReport: WorkReportStateType;
}

import { CarpVarietyTypeReducer } from "./carpVarietyType/ducks";
import { EnvironmentReportReducer } from "./environmentReport/ducks";
import { FeedReducer } from "./feed/ducks";
import { FeedReasonTypeReducer } from "./feedReasonType/ducks";
import { FertilizerReducer } from "./fertilizer/ducks";
import { HerbicideReducer } from "./herbicide/ducks";
import { ImageReducer } from "./image/ducks";
import { ParentCarpReducer } from "./parentCarp/ducks";
import { PondReducer } from "./pond/ducks";
import { PondAreaReducer } from "./pondArea/ducks";
import { PondOwnerTypeReducer } from "./pondOwnerType/ducks";
import { PondReportReducer } from "./pondReport/ducks";
import { PondTypeReducer } from "./pondType/ducks";
import { ScaleTypeReducer } from "./scaleType/ducks";
import { UseMethodTypeReducer } from "./useMethodType/ducks";
import { WorkerReducer } from "./worker/ducks";
import { WorkReportReducer } from "./workReport/ducks";

export const apiReducer = combineReducers({
  carpVarietyType: CarpVarietyTypeReducer,
  environmentReport: EnvironmentReportReducer,
  feed: FeedReducer,
  feedReasonType: FeedReasonTypeReducer,
  fertilizer: FertilizerReducer,
  herbicide: HerbicideReducer,
  image: ImageReducer,
  parentCarp: ParentCarpReducer,
  pond: PondReducer,
  pondArea: PondAreaReducer,
  pondOwnerType: PondOwnerTypeReducer,
  pondReport: PondReportReducer,
  pondType: PondTypeReducer,
  scaleType: ScaleTypeReducer,
  useMethodType: UseMethodTypeReducer,
  worker: WorkerReducer,
  workReport: WorkReportReducer,
});
