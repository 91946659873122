import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { EditStateService } from "../../../application/master/pondArea/editState";
import { NewStateService } from "../../../application/master/pondArea/newState";
import { ReadApiService } from "../../../application/master/pondArea/readApi";
import { isActiveEntity, isEntity, isNewEntity } from "../../../domain/entity";
import { PondArea } from "../../../domain/pondArea";
import { ApplicationState } from "../../../store/modules";
import { NewOrEditStateType } from "../../../store/modules/master/pondArea/newOrEditState/reducer";
import { MasterFormDefault } from "../../components/molecules/MasterForm";
import { MasterList } from "../../components/molecules/MasterList";
import { PageHeader } from "../../components/molecules/PageHeader";
import { MainLayout } from "../Layout";
import { MasterLayout } from "../Layout/Master";
import { PondAreaEditPage } from "./EditPage";
import { PondAreaNewPage } from "./NewPage";

interface StateProps {
  pondAreas: PondArea[];
  newOrEditState: NewOrEditStateType;
}

interface DispatchProps {
  readApiService: ReadApiService;
  newStateService: NewStateService;
  editStateService: EditStateService;
}

type PondAreasPageProps = StateProps & DispatchProps;

export class Wrapped extends React.Component<PondAreasPageProps> {
  public componentDidMount(): void {
    this.props.readApiService.init().then(() => {
      const { pondArea } = this.props.newOrEditState;
      if (pondArea !== null && isEntity(pondArea)) {
        this.props.editStateService.selectPondArea(pondArea.id);
      }
    });
  }

  public render() {
    const pondArea = this.props.newOrEditState.pondArea;
    let form;
    if (pondArea != null) {
      form = isEntity(pondArea) ? <PondAreaEditPage /> : <PondAreaNewPage />;
    } else {
      form = (
        <MasterFormDefault
          onClickAdd={pondArea !== null && isNewEntity(pondArea) ? null : this.handleClickAddPondArea}
          onClickDelete={pondArea !== null && isEntity(pondArea) ? this.handleClickDeletePondArea : null}
        />
      );
    }

    return (
      <MainLayout>
        <MasterLayout
          header={<PageHeader title={"エリア"} />}
          list={
            <MasterList
              items={this.props.pondAreas}
              selectedItemId={pondArea !== null && isEntity(pondArea) ? pondArea.id : null}
              onClickItem={this.handleClickListItem}
            />
          }
          form={form}
        />
      </MainLayout>
    );
  }

  private handleClickListItem = (id: number) => {
    this.props.editStateService.selectPondArea(id);
  };

  private handleClickAddPondArea = () => {
    this.props.newStateService.createPondArea();
  };

  private handleClickDeletePondArea = () => {
    this.props.editStateService.deletePondArea();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { newOrEditState } = state.master.pondArea;
  return {
    pondAreas: state.api.pondArea.pondAreas.filter(isActiveEntity),
    newOrEditState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    readApiService: new ReadApiService(dispatch),
    editStateService: new EditStateService(dispatch),
    newStateService: new NewStateService(dispatch),
  };
};

export const PondAreasPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);
