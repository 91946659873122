import * as React from "react";
import { connect } from "react-redux";
import { Dimmer as _Dimmer, DimmerDimmable, Segment, SidebarPushable } from "semantic-ui-react";
import styled from "styled-components";
import { ApplicationState } from "../../../store/modules";
import { NavigationSidebarContainer } from "../../containers/NavigationSidebar";

interface StateProps {
  visibleMenu: boolean;
}

type MobileLayoutProps = StateProps & {
  children?: any;
};
export const Wrapped = (props: MobileLayoutProps) => {
  return (
    <Layout>
      <SidebarPushable as={Segment}>
        <NavigationSidebarContainer />
        <DimmerDimmable dimmed style={{ height: "100%" }}>
          {props.visibleMenu && <Dimmer simple />}
          <Content>{props.children}</Content>
        </DimmerDimmable>
      </SidebarPushable>
    </Layout>
  );
};

const Layout = styled.div`
  height: 100%;
`;
const Content = styled.div`
  height: 100%;
`;

const Dimmer = styled(_Dimmer)`
  background-color: rgba(0, 0, 0, 0.4) !important;
`;

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    visibleMenu: state.mobile.navigation.visibleMenu,
  };
};

export const MobileLayout = connect(mapStateToProps)(Wrapped);
