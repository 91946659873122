import { Dispatch } from "redux";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { CarpVarietyTypeApiService } from "../../api/carpVarietyType";
import { ParentCarpApiService } from "../../api/parentCarps";

interface IReadApiService extends IApplicationService {
  init: () => void;
}

export class ReadApiService implements IReadApiService {
  private parentCarpApiService: ParentCarpApiService;
  private carpVarietyTypeApiService: CarpVarietyTypeApiService;

  public constructor(private dispatch: Dispatch<any>) {
    this.parentCarpApiService = new ParentCarpApiService(dispatch);
    this.carpVarietyTypeApiService = new CarpVarietyTypeApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public async init() {
    await Promise.all([this.parentCarpApiService.init(), this.carpVarietyTypeApiService.fetchUnFetched()]);
  }
}
