import { actionCreatorFactory } from "typescript-fsa";

// Actions
const actionCreator = actionCreatorFactory("MobilePondReportMoveModalState");

const openModal = actionCreator("openModal");
const closeModal = actionCreator("closeModal");
const changeModalState = actionCreator<ChangeModalStatePayload>("changeModalState");
interface ChangeModalStatePayload {
  key: keyof ModalStateType;
  value: any;
}

export const mobilePondReportMoveModalStateActions = {
  openModal,
  closeModal,
  changeModalState,
};

// Reducers
import { ChangeHandler, getInitialState, InitialHandler, ModalStateType, MoveModalStateType } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const moveModalStateReducer = reducerWithInitialState<MoveModalStateType>(getInitialState())
  .case(openModal, () => {
    return InitialHandler.openModal();
  })
  .case(closeModal, () => {
    return InitialHandler.closeModal();
  })
  .case(changeModalState, (state, { key, value }) => {
    return ChangeHandler.changeModalState(state, key, value);
  });
