import { default as moment } from "moment";
import { Dispatch } from "redux";
import { getAfterDate, getBeforeDate, getEndOfMonth, getFiscalYear, getStartOfMonth } from "../../../domain/calendar";
import { getCountFromBase } from "../../../domain/environmentReport";
import { filterByFiscalYear, PondReport } from "../../../domain/pondReport";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationState } from "../../../store/modules";
import { viewReportListStateActions } from "../../../store/modules/view/reportList/ducks";
import { GraphPeriodType } from "../../../store/modules/view/reportList/reducer";
import { CarpVarietyTypeApiService } from "../../api/carpVarietyType";
import { EnvironmentReportApiService } from "../../api/environmentReport";
import { FeedApiService } from "../../api/feed";
import { FeedReasonTypeApiService } from "../../api/feedReasonType";
import { FertilizerApiService } from "../../api/fertilizer";
import { HerbicideApiService } from "../../api/herbicide";
import { ParentCarpApiService } from "../../api/parentCarps";
import { PondApiService } from "../../api/pond";
import { PondAreaApiService } from "../../api/pondArea";
import { PondOwnerTypeApiService } from "../../api/pondOwnerType";
import { PondReportApiService } from "../../api/pondReport";
import { PondTypeApiService } from "../../api/pondType";
import { ScaleTypeApiService } from "../../api/scaleType";
import { UseMethodTypeApiService } from "../../api/useMethodType";
import { WorkReportApiService } from "../../api/workReport";

interface IReportListService extends IApplicationService {
  fetchMaster: () => void;
  fetchReport: (date: Date) => void;
  fetchEnvironmentReports: () => void;
  openFilterModal: () => void;
  closeFilterModal: () => void;
  changePrevYear: () => void;
  changePrevMonth: () => void;
  changeNextMonth: () => void;
  changeNextYear: () => void;
  changeSelectedDate: (date: Date) => void;
  changePond: (pondId: number) => void;
  changePondArea: (pondAreaId: number) => void;
  changeDetailTab: (detailTab: "detail" | "graph") => void;
  changeGraphPeriodType: (periodType: GraphPeriodType) => void;
  changeSortDate: (isAscDate: boolean) => void;
  changeReportType: (reportTypeId: number) => void;
}

export class ReportListService implements IReportListService {
  private workReportApiService: WorkReportApiService;
  private pondReportApiService: PondReportApiService;
  private environmentApiService: EnvironmentReportApiService;
  private pondAreaApiService: PondAreaApiService;
  private pondApiService: PondApiService;
  private pondTypeApiService: PondTypeApiService;
  private pondOwnerTypeApiService: PondOwnerTypeApiService;
  private parentCarpApiService: ParentCarpApiService;
  private carpVarietyTypeApiService: CarpVarietyTypeApiService;
  private feedApiService: FeedApiService;
  private feedReasonTypeApiService: FeedReasonTypeApiService;
  private fertilizerApiService: FertilizerApiService;
  private herbicideApiService: HerbicideApiService;
  private useMethodTypeApiService: UseMethodTypeApiService;
  private scaleTypeApiService: ScaleTypeApiService;

  public constructor(private dispatch: Dispatch<any>) {
    this.workReportApiService = new WorkReportApiService(dispatch);
    this.pondReportApiService = new PondReportApiService(dispatch);
    this.environmentApiService = new EnvironmentReportApiService(dispatch);

    this.pondAreaApiService = new PondAreaApiService(dispatch);
    this.pondApiService = new PondApiService(dispatch);
    this.pondTypeApiService = new PondTypeApiService(dispatch);
    this.pondOwnerTypeApiService = new PondOwnerTypeApiService(dispatch);
    this.parentCarpApiService = new ParentCarpApiService(dispatch);
    this.carpVarietyTypeApiService = new CarpVarietyTypeApiService(dispatch);
    this.feedApiService = new FeedApiService(dispatch);
    this.feedReasonTypeApiService = new FeedReasonTypeApiService(dispatch);
    this.fertilizerApiService = new FertilizerApiService(dispatch);
    this.herbicideApiService = new HerbicideApiService(dispatch);
    this.useMethodTypeApiService = new UseMethodTypeApiService(dispatch);
    this.scaleTypeApiService = new ScaleTypeApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public async fetchMaster() {
    await Promise.all([
      this.pondAreaApiService.init(),
      this.pondApiService.init(),
      this.pondTypeApiService.fetchUnFetched(),
      this.pondOwnerTypeApiService.fetchUnFetched(),
      this.parentCarpApiService.init(),
      this.carpVarietyTypeApiService.fetchUnFetched(),
      this.feedApiService.init(),
      this.feedReasonTypeApiService.fetchUnFetched(),
      this.fertilizerApiService.init(),
      this.herbicideApiService.init(),
      this.useMethodTypeApiService.fetchUnFetched(),
      this.scaleTypeApiService.fetchUnFetched(),
    ]);
  }

  @catchApplicationError()
  public async fetchReport(date: Date) {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const selectedPondId = state.view.reportList.selectedPondId;
      if (selectedPondId !== null) {
        const startDateOfMonth = getStartOfMonth(date);
        const endDateOfMonth = getEndOfMonth(date);
        await Promise.all([
          this.workReportApiService.fetchReportByPeriod(startDateOfMonth, endDateOfMonth, selectedPondId),
          this.fetchPondReport(date, selectedPondId),
          this.environmentApiService.fetchReportByPeriod(startDateOfMonth, endDateOfMonth, selectedPondId),
        ]);
      }
    });
  }

  @catchApplicationError()
  public openFilterModal() {
    this.dispatch(viewReportListStateActions.openFilterModal());
  }

  @catchApplicationError()
  public closeFilterModal() {
    this.dispatch(viewReportListStateActions.closeFilterModal());
  }

  @catchApplicationError()
  public async changePrevYear() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const displayDate = moment(state.view.reportList.displayDate)
        .add(-1, "year")
        .toDate();
      this.dispatch(viewReportListStateActions.changeDisplayDate({ displayDate }));
      await this.fetchReport(displayDate);
    });
  }

  @catchApplicationError()
  public async changePrevMonth() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const displayDate = moment(state.view.reportList.displayDate)
        .add(-1, "month")
        .toDate();
      this.dispatch(viewReportListStateActions.changeDisplayDate({ displayDate }));
      await this.fetchReport(displayDate);
    });
  }

  @catchApplicationError()
  public async changeNextMonth() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const displayDate = moment(state.view.reportList.displayDate)
        .add(1, "month")
        .toDate();
      this.dispatch(viewReportListStateActions.changeDisplayDate({ displayDate }));
      await this.fetchReport(displayDate);
    });
  }

  @catchApplicationError()
  public async changeNextYear() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const displayDate = moment(state.view.reportList.displayDate)
        .add(1, "year")
        .toDate();
      this.dispatch(viewReportListStateActions.changeDisplayDate({ displayDate }));
      await this.fetchReport(displayDate);
    });
  }

  @catchApplicationError()
  public changeSelectedDate(date: Date) {
    this.dispatch(viewReportListStateActions.changeSelectedDate({ selectedDate: date }));
  }

  @catchApplicationError()
  public changePond(pondId: number) {
    this.dispatch(viewReportListStateActions.changePond({ selectedPondId: pondId }));
  }
  @catchApplicationError()
  public changePondArea(pondAreaId: number) {
    this.dispatch(viewReportListStateActions.changePondArea({ selectedPondAreaId: pondAreaId }));
  }

  @catchApplicationError()
  public changeDetailTab(detailTab: "detail" | "graph") {
    this.dispatch(viewReportListStateActions.changeDetailTab({ detailTab }));
  }

  @catchApplicationError()
  public changeGraphPeriodType(periodType: GraphPeriodType) {
    this.dispatch(viewReportListStateActions.changeGraphPeriodType({ periodType }));
  }

  @catchApplicationError()
  public changeSortDate(isAscDate: boolean) {
    this.dispatch(viewReportListStateActions.changeSortDate({ isAscDate }));
  }

  @catchApplicationError()
  public changeReportType(reportTypeId: number) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const { selectedReportTypeIds } = getState().view.reportList.reportFilterState;

      if (selectedReportTypeIds.includes(reportTypeId)) {
        this.dispatch(viewReportListStateActions.removeSelectedReportType({ selectedReportTypeId: reportTypeId }));
      } else {
        this.dispatch(viewReportListStateActions.addSelectedReportType({ selectedReportTypeId: reportTypeId }));
      }
    });
  }

  @catchApplicationError()
  public async fetchEnvironmentReports() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedPondId, selectedDate } = state.view.reportList;
      if (selectedPondId === null) {
        return;
      }
      const startDate = this.getFetchingStartDateOfEnvironmentReport(selectedDate, "half_year");
      const endDate = this.getFetchingEndDateOfEnvironmentReport(selectedDate, "half_year");
      await this.environmentApiService.fetchReportByPeriod(startDate, endDate, selectedPondId);
    });
  }

  private getFetchingStartDateOfEnvironmentReport(date: Date, graphPeriodType: GraphPeriodType): Date {
    const beforeThreeMonthDate = getBeforeDate(date, getCountFromBase(graphPeriodType));
    return getStartOfMonth(beforeThreeMonthDate);
  }

  private getFetchingEndDateOfEnvironmentReport(date: Date, graphPeriodType: GraphPeriodType): Date {
    const beforeThreeMonthDate = getAfterDate(date, getCountFromBase(graphPeriodType));
    return getEndOfMonth(beforeThreeMonthDate);
  }

  private async fetchPondReport(date: Date, selectedPondId: number) {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      const fiscalYear = getFiscalYear(date);

      await this.pondReportApiService.init(fiscalYear, selectedPondId);
      const fetchedState = getState();
      const pondReportsByPondId = fetchedState.api.pondReport.pondReports.filter((pr) => pr.pondId === selectedPondId);
      const pondReports = filterByFiscalYear(pondReportsByPondId, fiscalYear) as PondReport[];
      const pondReportIds = pondReports.map((pr) => pr.id);
      await Promise.all(pondReportIds.map((id) => this.pondReportApiService.fetchRelatedPondReports(id)));
    });
  }
}
