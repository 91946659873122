import { default as classNames } from "classnames";
import * as React from "react";
import styled from "styled-components";
import { ListItem } from "../../atoms/ListItem";

export interface MasterItem {
  id: number;
  name: string;
}

interface MasterListProps {
  items: MasterItem[];
  selectedItemId: number | null;
  onClickItem: (id: number) => void;
}

export const MasterList = ({ items, selectedItemId, onClickItem }: MasterListProps) => {
  return (
    <List>
      {items.map((i) => (
        <ListItem
          key={i.id}
          className={classNames({ active: i.id === selectedItemId })}
          onClick={() => onClickItem(i.id)}>
          {i.name}
        </ListItem>
      ))}
    </List>
  );
};

const List = styled.div``;
