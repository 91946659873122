import { combineReducers } from "redux";
import { EnvironmentReportStateType } from "./environmentReport";
import { NavigationStateType } from "./navigation/reducer";
import { PondReportStateType } from "./pondReport";
import { ReportListStateType } from "./reportListState/reducer";
import { WorkReportStateType } from "./workReport";

export interface MobileState {
  environmentReportState: EnvironmentReportStateType;
  navigation: NavigationStateType;
  pondReportState: PondReportStateType;
  reportListState: ReportListStateType;
  workReportState: WorkReportStateType;
}
import { environmentReportStateReducer } from "./environmentReport";
import { navigationStateReducer } from "./navigation/ducks";
import { pondReportStateReducer } from "./pondReport";
import { reportListStateReducer } from "./reportListState/ducks";
import { workReportStateReducer } from "./workReport";

export const mobileReducer = combineReducers({
  environmentReportState: environmentReportStateReducer,
  navigation: navigationStateReducer,
  pondReportState: pondReportStateReducer,
  reportListState: reportListStateReducer,
  workReportState: workReportStateReducer,
});
