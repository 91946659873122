import { Dispatch } from "redux";
import { getBeforeDate } from "../../../domain/calendar";
import { getCountFromEdgeBase } from "../../../domain/environmentReport";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { ApplicationState } from "../../../store/modules";
import { mobileNavigationActions } from "../../../store/modules/mobile/navigation/ducks";
import { GraphPeriodType, PageType } from "../../../store/modules/mobile/navigation/reducer";
import { EnvironmentReportApiService } from "../../api/environmentReport";

interface INavigationService extends IApplicationService {
  fetchEnvironmentReports: () => void;
  changeDate: (date: Date) => void;
  changePeriodType: (periodType: GraphPeriodType) => void;
  selectPondArea: (id: number) => void;
  selectPond: (id: number) => void;
  selectWorker: (id: number) => void;
  clearState: () => void;
  clearStateAtLoggedOut: () => void;
  openMenu: () => void;
  closeMenu: () => void;

  changeSortDate: (isAscDate: boolean) => void;
  changeOnlyEmptyPond: () => void;
  changePondStatus: (pondStatusId: number) => void;
  changeCarpVariety: (carpVarietyTypeId: number) => void;

  changeCurrentPage: (currentPage: PageType) => void;
}

export class NavigationService implements INavigationService {
  private environmentReportApiService: EnvironmentReportApiService;

  public constructor(private dispatch: Dispatch<any>) {
    this.environmentReportApiService = new EnvironmentReportApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  public async fetchEnvironmentReports() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedDate, selectedPondId } = state.mobile.navigation;

      if (selectedPondId === null) {
        throw new ApplicationError("野池が選択されていません。");
      }

      const startDate = getBeforeDate(selectedDate, getCountFromEdgeBase("half_year", false));
      await this.environmentReportApiService.fetchReportByPeriod(startDate, selectedDate, selectedPondId);
    });
  }

  @catchApplicationError()
  public changeDate(date: Date) {
    this.dispatch(mobileNavigationActions.changeSelectedDate({ selectedDate: date }));
  }

  @catchApplicationError()
  public changePeriodType(periodType: GraphPeriodType) {
    this.dispatch(mobileNavigationActions.changeGraphPeriodType({ periodType }));
  }

  @catchApplicationError()
  public selectPondArea(id: number) {
    this.dispatch(mobileNavigationActions.selectPondArea({ selectedPondAreaId: id }));
  }

  @catchApplicationError()
  public selectPond(id: number) {
    this.dispatch(mobileNavigationActions.selectPond({ selectedPondId: id }));
  }

  @catchApplicationError()
  public selectWorker(id: number) {
    this.dispatch(mobileNavigationActions.selectWorker({ selectedWorkerId: id }));
  }

  @catchApplicationError()
  public clearState() {
    this.dispatch(mobileNavigationActions.clearSelectState());
  }

  @catchApplicationError()
  public clearStateAtLoggedOut() {
    this.dispatch(mobileNavigationActions.resetState());
  }

  @catchApplicationError()
  public openMenu() {
    this.dispatch(mobileNavigationActions.openMenu());
  }

  @catchApplicationError()
  public closeMenu() {
    this.dispatch(mobileNavigationActions.closeMenu());
  }

  @catchApplicationError()
  public changeCurrentPage(currentPage: PageType) {
    this.dispatch(mobileNavigationActions.changeCurrentPage({ currentPage }));
  }

  @catchApplicationError()
  public changeSortDate(isAscDate: boolean) {
    this.dispatch(mobileNavigationActions.changeSortDate({ isAscDate }));
  }
  @catchApplicationError()
  public changeOnlyEmptyPond() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const { pondFilterState } = getState().mobile.navigation;
      this.dispatch(mobileNavigationActions.changeOnlyEmptyPond({ isOnlyEmptyPond: !pondFilterState.isOnlyEmptyPond }));
    });
  }
  @catchApplicationError()
  public changePondStatus(pondStatusId: number) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const { selectedPondStatusIds } = getState().mobile.navigation.pondFilterState;
      if (selectedPondStatusIds.includes(pondStatusId)) {
        this.dispatch(mobileNavigationActions.removeSelectedPondStatus({ pondStatusId }));
      } else {
        this.dispatch(mobileNavigationActions.addSelectedPondStatus({ pondStatusId }));
      }
    });
  }
  @catchApplicationError()
  public changeCarpVariety(carpVarietyId: number) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const { notSelectedCarpVarietyTypeIds } = getState().mobile.navigation.pondFilterState;
      if (notSelectedCarpVarietyTypeIds.includes(carpVarietyId)) {
        this.dispatch(mobileNavigationActions.removeNotSelectedCarpVarietyType({ carpVarietyId }));
      } else {
        this.dispatch(mobileNavigationActions.addNotSelectedCarpVarietyType({ carpVarietyId }));
      }
    });
  }
}
