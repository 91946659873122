import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { ReportListStateService } from "../../../../application/mobile/reportList";
import { canGoBack } from "../../../../domain/window";
import { ApplicationState } from "../../../../store/modules";
import { ReportListStateType } from "../../../../store/modules/mobile/reportListState/reducer";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { ReportFilterForm } from "../../../components/molecules/ReportFilterForm";
import { MobileLayout } from "../../Layout/Mobile";

interface StateProps {
  reportListState: ReportListStateType;
}

interface DispatchProps {
  reportListStateService: ReportListStateService;
}

type ReportListFilterPageProps = StateProps & DispatchProps & RouteComponentProps;

export class Wrapped extends React.Component<ReportListFilterPageProps> {
  public render() {
    const { reportSortingState, reportFilterState } = this.props.reportListState;
    return (
      <MobileLayout>
        <BaseNavigationHeader title={"一覧"} onClickReturn={this.handleClickReturn} />
        <BaseNavigationContent>
          <ReportFilterForm
            sortingState={reportSortingState}
            filterState={reportFilterState}
            onChangeSortDate={this.handleChangeSortDate}
            onChangeReportType={this.handleChangeReportType}
          />
        </BaseNavigationContent>
      </MobileLayout>
    );
  }

  private handleChangeSortDate = (isAscDate: boolean) => {
    this.props.reportListStateService.changeSortDate(isAscDate);
  };
  private handleChangeReportType = (reportTypeId: number) => {
    this.props.reportListStateService.changeReportType(reportTypeId);
  };

  private handleClickReturn = () => {
    if (canGoBack()) {
      this.props.history.goBack();
    }
    this.props.history.push("/reports");
    return;
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    reportListState: state.mobile.reportListState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    reportListStateService: new ReportListStateService(dispatch),
  };
};

export const ReportListFilterPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
