import { isEntity } from "../../domain/entity";
import { Feed, NewOrEditFeed } from "../../domain/feed";
import * as Api from "../../libs/apiClient";

export function translateApiToFeed(apiFeed: Api.Feed): Feed {
  return {
    id: apiFeed.id as number,
    name: apiFeed.name,
    pondClassId: apiFeed.pondClassId,
    position: apiFeed.position,
    isDeleted: apiFeed.isDeleted,
  };
}

export function translateFeedToApi(feed: NewOrEditFeed): Api.Feed {
  return {
    id: isEntity(feed) ? feed.id : undefined,
    name: feed.name,
    pondClassId: feed.pondClassId as number,
    position: feed.position,
    isDeleted: feed.isDeleted,
  };
}
