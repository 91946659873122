import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NavigationService } from "../../../../application/mobile/navigation";
import { ReadApiService } from "../../../../application/mobile/pondSelection/readApi";
import { NewOrEditStateService } from "../../../../application/mobile/workReport/newOrEditState";
import { isActiveEntity, UniqueKey } from "../../../../domain/entity";
import { Worker } from "../../../../domain/worker";
import { ApplicationState } from "../../../../store/modules";
import { EmptyMessage } from "../../../components/atoms/Message";
import { MobileList } from "../../../components/molecules/MobileList";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { MobileLayout } from "../../Layout/Mobile";

interface StateProps {
  workers: Worker[];
}

interface DispatchProps {
  readApiService: ReadApiService;
  newOrEditStateService: NewOrEditStateService;
  navigationService: NavigationService;
}

type WorkerSelectionPageProps = StateProps & DispatchProps & RouteComponentProps;

export class Wrapped extends React.Component<WorkerSelectionPageProps> {
  public componentWillMount(): void {
    this.props.navigationService.clearState();
    this.props.readApiService.fetchWorkers();
  }

  public render() {
    const isEmpty = this.props.workers.length === 0;
    return (
      <MobileLayout>
        <BaseNavigationHeader title={"担当者"} onClickMenu={this.handleClickMenu} />
        <BaseNavigationContent>
          {isEmpty && <EmptyMessage>担当者が見つかりませんでした。</EmptyMessage>}
          {!isEmpty && (
            <MobileList itemClassName={"large"} items={this.props.workers} onClickItem={this.handleClickWorker} />
          )}
        </BaseNavigationContent>
      </MobileLayout>
    );
  }

  private handleClickWorker = (id: UniqueKey) => {
    if (typeof id === "string") {
      return;
    }
    this.props.navigationService.selectWorker(id);
    this.props.history.push(`/ponds/workers/${id}`);
  };

  private handleClickMenu = () => {
    this.props.navigationService.openMenu();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { worker } = state.api;
  return {
    workers: worker.workers.filter(isActiveEntity),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    readApiService: new ReadApiService(dispatch),
    newOrEditStateService: new NewOrEditStateService(dispatch),
    navigationService: new NavigationService(dispatch),
  };
};

export const WorkerSelectionPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
