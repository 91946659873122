import { combineReducers } from "redux";
import { NewOrEditStateType } from "./newOrEditState/reducer";

export interface ReportWorkReportState {
  newOrEditStateType: NewOrEditStateType;
}

import { workReportNewOrEditStateReducer } from "./newOrEditState/ducks";

export const workReportReducer = combineReducers({
  newOrEditStateType: workReportNewOrEditStateReducer,
});
