import { Entity } from "../entity";
import { getValidation, isEmptyString, isTextLength, Validation, Validator } from "../validation";

interface FeedAttribute extends Record<string, unknown> {
  name: string;
  pondClassId: null | number;
  position: number;
  isDeleted: boolean;
}

export interface NewFeed extends FeedAttribute {}
export interface EditFeed extends FeedAttribute, Entity {}

export type NewOrEditFeed = NewFeed | EditFeed;
export type Feed = EditFeed;

// service

export function createNewFeed(): NewFeed {
  return {
    name: "",
    pondClassId: null,
    position: 1,
    isDeleted: false,
  };
}

/*** Validator ***/
export class FeedValidator extends Validator<NewOrEditFeed> {
  public validate(entity: NewOrEditFeed) {
    const nameValidation = validateName(entity);
    if (nameValidation) {
      this.addMessages(nameValidation);
    }
    const pondClassValidation = validatePondClass(entity);
    if (pondClassValidation) {
      this.addMessages(pondClassValidation);
    }
  }
}

function validateName({ name }: NewOrEditFeed): Validation | null {
  if (isEmptyString(name)) {
    return getValidation("餌名は必須です。入力してください。");
  }
  if (!isTextLength(name, 0, 255)) {
    return getValidation("餌名は255文字以下で入力してください。");
  }
  return null;
}
function validatePondClass({ pondClassId }: NewOrEditFeed): Validation | null {
  if (pondClassId === null) {
    return getValidation("区分は必須です。");
  }
  return null;
}
