import { Dispatch } from "redux";
import { getFiscalEndDate, getFiscalStartDate, getFiscalYear } from "../../../domain/calendar";
import { UniqueKey } from "../../../domain/entity";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { ApplicationState } from "../../../store/modules";
import { pondReportListStateActions } from "../../../store/modules/mobile/pondReport/listState/ducks";
import { CarpVarietyTypeApiService } from "../../api/carpVarietyType";
import { ParentCarpApiService } from "../../api/parentCarps";
import { PondReportApiService } from "../../api/pondReport";
import { ScaleTypeApiService } from "../../api/scaleType";

interface IListStateService extends IApplicationService {
  init: () => void;
  selectPondDisinfectionReport: (uniqueKey: UniqueKey) => void;
  selectCarpSelectionReport: (uniqueKey: UniqueKey) => void;
  selectAdultCarpReport: (uniqueKey: UniqueKey) => void;
}

export class ListStateService implements IListStateService {
  private pondReportApiService: PondReportApiService;
  private parentCarpApiService: ParentCarpApiService;
  private scaleTypeApiService: ScaleTypeApiService;
  private carpVarietyApiService: CarpVarietyTypeApiService;

  public constructor(private dispatch: Dispatch<any>) {
    this.pondReportApiService = new PondReportApiService(dispatch);
    this.parentCarpApiService = new ParentCarpApiService(dispatch);
    this.scaleTypeApiService = new ScaleTypeApiService(dispatch);
    this.carpVarietyApiService = new CarpVarietyTypeApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError((dispatch) => dispatch(pondReportListStateActions.initialFailed()))
  public async init() {
    this.dispatch(pondReportListStateActions.initialStart());
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedPondId, selectedDate } = state.mobile.navigation;
      if (selectedPondId === null) {
        throw new ApplicationError("野池が選択されていません。");
      }
      const currentFiscalYear = getFiscalYear(selectedDate);
      // 前年度の記録を取得する
      const startDate = getFiscalStartDate(currentFiscalYear - 1);
      const endDate = getFiscalEndDate(currentFiscalYear);

      await Promise.all([
        this.pondReportApiService.fetchPondReports(startDate, endDate, selectedPondId),
        this.parentCarpApiService.init(),
        this.scaleTypeApiService.fetchUnFetched(),
        this.carpVarietyApiService.fetchUnFetched(),
      ]);
      this.dispatch(pondReportListStateActions.initialComplete());
    });
  }

  @catchApplicationError()
  public selectPondDisinfectionReport(uniqueKey: UniqueKey) {
    this.dispatch(pondReportListStateActions.selectPondDisinfectionReport({ selectedUniqueKey: uniqueKey }));
  }

  @catchApplicationError()
  public selectCarpSelectionReport(uniqueKey: UniqueKey) {
    this.dispatch(pondReportListStateActions.selectCarpSelectionReport({ selectedUniqueKey: uniqueKey }));
  }

  @catchApplicationError()
  public selectAdultCarpReport(uniqueKey: UniqueKey) {
    this.dispatch(pondReportListStateActions.selectAdultCarpReport({ selectedUniqueKey: uniqueKey }));
  }
}
