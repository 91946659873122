import { actionCreatorFactory } from "typescript-fsa";
import { EditPond, NewOrEditPond, NewPond, Pond } from "../../../../../domain/pond";
// Actions
const actionCreator = actionCreatorFactory("MasterPondNewOrEditState");

const createPond = actionCreator<CreatePondPayload>("createPond");
interface CreatePondPayload {
  pond: NewPond;
}

const selectPond = actionCreator<SelectPondPayload>("selectPond");
interface SelectPondPayload {
  pond: EditPond;
}

const changePond = actionCreator<ChangePondPayload>("changePond");
interface ChangePondPayload {
  key: keyof NewOrEditPond;
  value: any;
}

export const cancelPond = actionCreator<CancelPondPayload>("cancelPond");
interface CancelPondPayload {
  pond: NewOrEditPond;
}

export const saveStart = actionCreator("saveStart");
export const saveSuccess = actionCreator<SaveSuccessPayload>("saveSuccess");
interface SaveSuccessPayload {
  pond: Pond | null;
}

export const saveFail = actionCreator("saveFail");

export const masterPondNewOrEditStateActions = {
  createPond,
  selectPond,
  changePond,
  cancelPond,
  saveStart,
  saveSuccess,
  saveFail,
};

// Reducers
import { ChangeHandler, getInitialState, InitialHandler, NewOrEditStateType, SaveHandler } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const NewOrEditStateReducer = reducerWithInitialState<NewOrEditStateType>(getInitialState())
  .case(createPond, (state, { pond }) => {
    return InitialHandler.createPond(state, pond);
  })
  .case(selectPond, (state, { pond }) => {
    return InitialHandler.selectPond(state, pond);
  })
  .case(changePond, (state, { key, value }) => {
    return ChangeHandler.changePond(state, key, value);
  })
  .case(cancelPond, (state, { pond }) => {
    return SaveHandler.cancelPond(state, pond);
  })
  .case(saveStart, (state) => {
    return SaveHandler.saveStart(state);
  })
  .case(saveSuccess, (state, { pond }) => {
    return SaveHandler.saveSuccess(state, pond);
  })
  .case(saveFail, (state) => {
    return SaveHandler.saveFail(state);
  });
