import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NavigationService } from "../../../../application/mobile/navigation";
import { ReadApiService } from "../../../../application/mobile/pondSelection/readApi";
import { NewOrEditStateService } from "../../../../application/mobile/workReport/newOrEditState";
import { CarpVarietyType } from "../../../../domain/carpVarietyType";
import { isActiveEntity } from "../../../../domain/entity";
import { ParentCarp } from "../../../../domain/parentCarp";
import { isUsedPond, Pond } from "../../../../domain/pond";
import { filterByOnlyEmptyPond, filterByParentCarp, filterByPondStatus } from "../../../../domain/pond/filter";
import { sortByPondReportDate } from "../../../../domain/pond/sort";
import { PondOwnerType } from "../../../../domain/pondOwnerType";
import { getCurrentPondReport, PondReport } from "../../../../domain/pondReport";
import { isFryPondType, PondType } from "../../../../domain/pondType";
import { Worker } from "../../../../domain/worker";
import { ApplicationState } from "../../../../store/modules";
import { AuthStateType } from "../../../../store/modules/auth/reducer";
import { NavigationStateType } from "../../../../store/modules/mobile/navigation/reducer";
import { EmptyMessage } from "../../../components/atoms/Message";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { PondSelection } from "../../../components/organisms/PondSelection";
import { MobileLayout } from "../../Layout/Mobile";

interface StateProps {
  ponds: Pond[];
  pondTypes: PondType[];
  pondOwnerTypes: PondOwnerType[];
  parentCarps: ParentCarp[];
  pondReports: PondReport[];
  carpVarietyTypes: CarpVarietyType[];
  workers: Worker[];

  authState: AuthStateType;
  navigationState: NavigationStateType;
}

interface DispatchProps {
  readApiService: ReadApiService;
  newOrEditStateService: NewOrEditStateService;
  navigationService: NavigationService;
}

type FryPondSelectionPageProps = StateProps & DispatchProps & RouteComponentProps;

export function filterPondsByFryPond(navigationState: NavigationStateType, api: { pondReports: PondReport[]; pondTypes: PondType[]; parentCarps: ParentCarp[]; ponds: Pond[] }): Pond[] {
  const { ponds, pondTypes, pondReports, parentCarps } = api;
  const { pondFilterState, pondSortingState } = navigationState;

  const filteredPonds = ponds.filter((pond) => {
    if (pond.pondAreaId === null) {
      return false;
    }
    const pondReport = getCurrentPondReport(pondReports, pond.id);
    const pondType =
      (!!pondReport && pondTypes.find((t) => t.id === pondReport.pondTypeId)) ||
      pondTypes.find((t) => t.id === pond.pondTypeId);
    if (!pondType || !isFryPondType(pondType)) {
      return false;
    }

    const filterResultByParentCarp = filterByParentCarp(
      pondReport,
      parentCarps,
      pondFilterState.notSelectedCarpVarietyTypeIds
    );
    const filterResultByOnlyEmptyPond = filterByOnlyEmptyPond(pondReport, pondFilterState.isOnlyEmptyPond);
    const filterResultByPondStatus = filterByPondStatus(pondReport, pondFilterState.selectedPondStatusIds);
    return filterResultByParentCarp && filterResultByOnlyEmptyPond && filterResultByPondStatus;
  });
  return sortByPondReportDate(filteredPonds, pondReports, pondSortingState.isAscDate);
}

export class Wrapped extends React.Component<FryPondSelectionPageProps> {
  public componentWillMount(): void {
    this.props.readApiService.init();

    this.initState(this.props);
  }

  public render() {
    const sortedPonds = filterPondsByFryPond(this.props.navigationState, this.props);
    const isEmpty = sortedPonds.length === 0;
    return (
      <MobileLayout>
        <BaseNavigationHeader
          title={"稚魚池一覧"}
          onClickMenu={this.handleClickMenu}
          onClickFilter={this.handleClickFilter}
        />
        <BaseNavigationContent>
          {isEmpty && <EmptyMessage>野池が見つかりませんでした。</EmptyMessage>}
          {!isEmpty && (
            <PondSelection
              ponds={sortedPonds}
              pondReports={this.props.pondReports}
              pondTypes={this.props.pondTypes}
              pondOwnerTypes={this.props.pondOwnerTypes}
              parentCarps={this.props.parentCarps}
              carpVarietyTypes={this.props.carpVarietyTypes}
              onClickPond={this.handleClickPond}
              shouldMoveScrollTopOnMounted={this.shouldMoveScrollTopOnMounted()}
            />
          )}
        </BaseNavigationContent>
      </MobileLayout>
    );
  }

  private initState(props: Readonly<FryPondSelectionPageProps>) {
    props.navigationService.clearState();
  }

  private shouldMoveScrollTopOnMounted(): boolean {
    const { prevSelectedWorkerId, prevSelectedPondAreaId, prevPage, selectedPondAreaId, selectedWorkerId, currentPage } = this.props.navigationState;
    return prevPage === currentPage && prevSelectedWorkerId === selectedWorkerId && prevSelectedPondAreaId === selectedPondAreaId;
  }

  private handleClickPond = async (id: number) => {
    this.props.navigationService.selectPond(id);
    const date = new Date();
    this.props.navigationService.changeDate(date);
    this.props.history.push("/reports/menu");
  };

  private handleClickFilter = () => {
    this.props.history.push("/ponds/fry/filter");
  };

  private handleClickMenu = () => {
    this.props.navigationService.openMenu();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { pond, pondType, pondOwnerType, pondReport, parentCarp, carpVarietyType, worker } = state.api;
  return {
    ponds: pond.ponds.filter((p) => isActiveEntity(p) && isUsedPond(p) && p.pondTypeId !== null),
    pondTypes: pondType.pondTypes,
    pondOwnerTypes: pondOwnerType.pondOwnerTypes,
    pondReports: pondReport.pondReports,
    parentCarps: parentCarp.parentCarps.filter(isActiveEntity),
    carpVarietyTypes: carpVarietyType.carpVarietyTypes,
    workers: worker.workers,

    authState: state.auth,
    navigationState: state.mobile.navigation,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    readApiService: new ReadApiService(dispatch),
    newOrEditStateService: new NewOrEditStateService(dispatch),
    navigationService: new NavigationService(dispatch),
  };
};

export const FryPondSelectionPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
