import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { NewStateService } from "../../../application/master/parentCarps/newState";
import { CarpVarietyType } from "../../../domain/carpVarietyType";
import { isActiveEntity, UniqueKey } from "../../../domain/entity";
import { ApplicationState } from "../../../store/modules";
import { ImageStateType } from "../../../store/modules/api/image/reducer";
import { imageGalleryStateActions } from "../../../store/modules/imageGallery/ducks";
import { NewOrEditStateType } from "../../../store/modules/master/parentCarp/newOrEditState/reducer";
import { ParentCarpForm } from "../../components/organisms/ParentCarpForm";

interface StateProps {
  carpVarietyTypes: CarpVarietyType[];
  newOrEditState: NewOrEditStateType;
  image: ImageStateType;
}

interface DispatchProps {
  newStateService: NewStateService;
  showImageGallery: (imageIds: number[], imageId: number) => void;
}

type ParentCarpNewPageProps = StateProps & DispatchProps;

export class Wrapped extends React.Component<ParentCarpNewPageProps> {
  public render() {
    const { parentCarp, isSaving } = this.props.newOrEditState;
    return (
      <ParentCarpForm
        parentCarp={parentCarp}
        carpPairings={parentCarp ? parentCarp.carpPairings.filter(isActiveEntity) : []}
        carpVarietyTypes={this.props.carpVarietyTypes}
        isSaving={isSaving}
        disabledSave={this.props.image.isSaving}
        onChangeName={this.handleChangeName}
        onChangeCarpVarietyType={this.handleChangeCarpVarietyType}
        onChangeCarpPairingName={this.handleChangeCarpPairingName}
        onAddCarpPairing={this.handleAddCarpPairing}
        onRemoveCarpPairing={this.handleRemoveCarpPairing}
        onChangeYear={this.handleChangeYear}
        onClickSave={this.handleClickSaveParentCarp}
        onClickCancel={this.handleClickCancelParentCarp}
        onClickAdd={this.handleClickAddParentCarp}
        onClickDelete={null}
        onChangeFile={this.handleChangeFile}
        onClickImage={this.handleClickImage}
        onClickImageRemove={this.handleClickImageRemove}
      />
    );
  }

  private handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.newStateService.changeName(e.target.value);
  };

  private handleChangeCarpVarietyType = (varietyId: null | number) => {
    this.props.newStateService.changeCarpVarietyType(varietyId);
  };

  private handleChangeYear = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      this.props.newStateService.changeYear(null);
      return;
    }

    const year = +e.target.value;
    if (!Number.isNaN(year)) {
      this.props.newStateService.changeYear(year);
    }
  };

  private handleChangeCarpPairingName = (uniqueKey: UniqueKey, e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.newStateService.changeCarpPairingName(uniqueKey, e.target.value);
  };

  private handleAddCarpPairing = () => {
    this.props.newStateService.addCarpPairing();
  };

  private handleRemoveCarpPairing = (uniqueKey: UniqueKey) => {
    this.props.newStateService.removeCarpPairing(uniqueKey);
  };

  private handleClickSaveParentCarp = () => {
    this.props.newStateService.saveParentCarp();
  };

  private handleClickCancelParentCarp = () => {
    this.props.newStateService.cancelParentCarp();
  };

  private handleClickAddParentCarp = () => {
    this.props.newStateService.createParentCarp();
  };

  private handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>, uniqueKey: UniqueKey) => {
    if (e.target.files && e.target.files.length !== 0) {
      this.props.newStateService.addCarpPairingImage(uniqueKey, e.target.files[0]);
    }
  };

  private handleClickImage = (imageIds: number[], imageId: number) => {
    this.props.showImageGallery(imageIds, imageId);
  };

  private handleClickImageRemove = (uniqueKey: UniqueKey, imageId: number) => {
    this.props.newStateService.removeCarpPairingImage(uniqueKey, imageId);
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { newOrEditState } = state.master.parentCarp;
  return {
    carpVarietyTypes: state.api.carpVarietyType.carpVarietyTypes,
    newOrEditState,
    image: state.api.image,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    newStateService: new NewStateService(dispatch),
    showImageGallery: (imageIds, imageId) =>
      dispatch(imageGalleryStateActions.showImages({ imageIds, initialImageId: imageId })),
  };
};

export const ParentCarpNewPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);
