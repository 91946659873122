/*
 * モバイルの野池一覧画面でのフィルターを行うための関数群。
 *
 */

import { FilterByPondStatus } from "../../presentation/components/molecules/PondFilterForm";
import { SelectionNumberOfSelectionReport } from "../carpSelectionReport";
import { ParentCarp } from "../parentCarp";
import { getCurrentPondStatus, getEmptyAmountMessage, NewOrEditPondReport, PondStatus } from "../pondReport";

export function filterByParentCarp(
  pondReport: NewOrEditPondReport | null,
  parentCarps: ParentCarp[],
  notSelectedCarpVarietyIds: number[]
): boolean {
  const isAllDisplay = notSelectedCarpVarietyIds.length === 0;
  if (isAllDisplay) {
    return true;
  }
  if (pondReport === null) {
    return false;
  }
  const parentCarp = parentCarps.find((carp) => pondReport.parentCarpId === carp.id);
  if (!parentCarp) {
    return false;
  }
  return !notSelectedCarpVarietyIds.includes(parentCarp.carpVarietyTypeId);
}

export function filterByOnlyEmptyPond(pondReport: NewOrEditPondReport | null, isOnlyEmptyPond: boolean): boolean {
  if (!isOnlyEmptyPond) {
    return true;
  }
  if (pondReport === null) {
    return true;
  }
  const currentPondStatus = getCurrentPondStatus(pondReport);
  if (currentPondStatus === null || currentPondStatus.statusKey === "disinfection") {
    return true;
  }
  return currentPondStatus.amount === getEmptyAmountMessage();
}

export function filterByPondStatus(pondReport: NewOrEditPondReport | null, pondStatusIds: number[]): boolean {
  const isAllDisplay = pondStatusIds.length === 0;
  if (isAllDisplay) {
    return true;
  }
  if (pondReport === null) {
    return false;
  }
  const pondStatus = getCurrentPondStatus(pondReport);
  if (pondStatus === null) {
    return false;
  }
  if (pondStatusIds.includes(FilterByPondStatus.DoneDisinfection)) {
    if (isDonePondDisinfection(pondStatus, pondReport.isCompleted)) {
      return true;
    }
  }
  if (pondStatusIds.includes(FilterByPondStatus.NotDoneOneSelection)) {
    if (isNotDoneSelection(SelectionNumberOfSelectionReport.OneSelection, pondStatus, pondReport.isCompleted)) {
      return true;
    }
  }
  if (pondStatusIds.includes(FilterByPondStatus.NotDoneTwoSelection)) {
    if (isNotDoneSelection(SelectionNumberOfSelectionReport.TwoSelection, pondStatus, pondReport.isCompleted)) {
      return true;
    }
  }
  if (pondStatusIds.includes(FilterByPondStatus.NotDoneThreeSelection)) {
    if (isNotDoneSelection(SelectionNumberOfSelectionReport.ThreeSelection, pondStatus, pondReport.isCompleted)) {
      return true;
    }
  }
  return false;
}

export function isDonePondDisinfection(pondStatus: PondStatus, isCompletedSelection: boolean): boolean {
  return !isCompletedSelection && pondStatus.statusKey === "disinfection";
}

export function isNotDoneSelection(
  selectionNumber: number,
  pondStatus: PondStatus,
  isCompletedSelection: boolean
): boolean {
  return (
    !isCompletedSelection && pondStatus.statusKey === "selection" && selectionNumber - 1 === pondStatus.selectionNumber
  );
}
