import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { EditStateService } from "../../../application/master/worker/editState";
import { NewStateService } from "../../../application/master/worker/newState";
import { ReadApiService } from "../../../application/master/worker/readApi";
import { isActiveEntity, isEntity, isNewEntity } from "../../../domain/entity";
import { Worker } from "../../../domain/worker";
import { ApplicationState } from "../../../store/modules";
import { NewOrEditStateType } from "../../../store/modules/master/worker/newOrEditState/reducer";
import { MasterFormDefault } from "../../components/molecules/MasterForm";
import { MasterList } from "../../components/molecules/MasterList";
import { PageHeader } from "../../components/molecules/PageHeader";
import { MainLayout } from "../Layout";
import { MasterLayout } from "../Layout/Master";
import { WorkerEditPage } from "./EditPage";
import { WorkerNewPage } from "./NewPage";

interface StateProps {
  workers: Worker[];
  newOrEditState: NewOrEditStateType;
}

interface DispatchProps {
  readApiService: ReadApiService;
  editStateService: EditStateService;
  newStateService: NewStateService;
}

type WorkersPageProps = StateProps & DispatchProps;

export class Wrapped extends React.Component<WorkersPageProps> {
  public componentDidMount(): void {
    this.props.readApiService.init().then(() => {
      const { worker } = this.props.newOrEditState;
      if (worker !== null && isEntity(worker)) {
        this.props.editStateService.selectWorker(worker.id);
      }
    });
  }

  public render() {
    const worker = this.props.newOrEditState.worker;
    let form;
    if (worker !== null) {
      form = isEntity(worker) ? <WorkerEditPage /> : <WorkerNewPage />;
    } else {
      form = (
        <MasterFormDefault
          onClickAdd={worker !== null && isNewEntity(worker) ? null : this.handleClickAddWorker}
          onClickDelete={worker !== null && isEntity(worker) ? this.handleClickDeleteWorker : null}
        />
      );
    }

    return (
      <MainLayout>
        <MasterLayout
          header={<PageHeader title={"担当者"} />}
          list={
            <MasterList
              items={this.props.workers}
              selectedItemId={worker !== null && isEntity(worker) ? worker.id : null}
              onClickItem={this.handleClickListItem}
            />
          }
          form={form}
        />
      </MainLayout>
    );
  }

  private handleClickListItem = (id: number) => {
    this.props.editStateService.selectWorker(id);
  };

  private handleClickAddWorker = () => {
    this.props.newStateService.createWorker();
  };

  private handleClickDeleteWorker = () => {
    this.props.editStateService.deleteWorker();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { newOrEditState } = state.master.worker;
  return {
    workers: state.api.worker.workers.filter(isActiveEntity),
    newOrEditState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    readApiService: new ReadApiService(dispatch),
    editStateService: new EditStateService(dispatch),
    newStateService: new NewStateService(dispatch),
  };
};

export const WorkersPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);
