import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { authStateActions } from "../store/modules/auth/ducks";
import { notificationAlertStateActions } from "../store/modules/notification/alert/ducks";
import { APPLICATION_ERROR_NAME } from "./errors/applicationError";

function isAxiosError(error: Error): error is AxiosError {
  return "config" in error && "response" in error;
}

export enum ErrorMessage {
  UnExpected = "予期しないエラーが発生しました。",
  UnAuthorized = "再度ログインしてください。",
  NotFound = "見つかりませんでした。",
  BadRequest = "不正なリクエストです。",
  ServerError = "サーバーでエラーが発生しました。",
}

export function apiErrorHandler(
  error: AxiosError,
  failedCallback: (errorMessage: string, statusCode?: number) => void
) {
  if (!error.response) {
    failedCallback(ErrorMessage.UnExpected);
    return;
  }

  const { status } = error.response;

  if (status === 401) {
    const errorMessage = error.response.data.message || error.response.data.Message;
    failedCallback(errorMessage || ErrorMessage.UnAuthorized, status);
    return;
  }
  if (status === 404) {
    failedCallback(ErrorMessage.NotFound, status);
    return;
  }
  if (status === 400) {
    const errorMessage = error.response.data.message || error.response.data.Message;
    failedCallback(errorMessage || ErrorMessage.BadRequest, status);
    return;
  }

  if (status > 400 && status < 500) {
    failedCallback(ErrorMessage.BadRequest, status);
    return;
  }

  if (status >= 500) {
    failedCallback(ErrorMessage.ServerError, status);
    return;
  }
  failedCallback(ErrorMessage.UnExpected, status);
}

export function errorHandler(error: Error, failedCallback: (errorMessage: string, statusCode?: number) => void) {
  if (process.env.NODE_ENV !== "production") {
    console.error(error.stack);
  }

  if (isAxiosError(error)) {
    apiErrorHandler(error, failedCallback);
    return;
  }
  failedCallback(ErrorMessage.UnExpected);
  return;
}

/**
 * Application層用のエラーハンドリング
 * @param dispatch
 * @param error
 */
export function handleApplicationError<T extends Error>(dispatch: Dispatch, error: T) {
  errorHandler(error, (errorMessage: string, statusCode?: number) => {
    if (statusCode === 401) {
      dispatch(authStateActions.loginFail({ errorMessage }));
      dispatch(notificationAlertStateActions.showErrorMessage({ errorMessage }));
      return;
    }
    if (error.name === APPLICATION_ERROR_NAME) {
      dispatch(notificationAlertStateActions.showErrorMessage({ errorMessage: error.message }));
      return;
    }
    dispatch(notificationAlertStateActions.showErrorMessage({ errorMessage }));
  });
}

export interface IApplicationService {
  getDispatch: () => Dispatch;
}

export function catchApplicationError(callback?: (dispatch: Dispatch) => any) {
  return (target: IApplicationService, propertyKey: string, descriptor: PropertyDescriptor) => {
    const delegate = descriptor.value;
    descriptor.value = async function(...args: any[]) {
      try {
        return await delegate.apply(this, args);
      } catch (e) {
        handleApplicationError(target.getDispatch.apply(this), e);
        if (callback) {
          callback(target.getDispatch.apply(this));
        }
      }
    };
    return descriptor;
  };
}
