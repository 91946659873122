import { UseMethodType } from "../../domain/useMethodType";
import * as Api from "../../libs/apiClient";

export function translateApiToUseMethodType(apiUseMethodType: Api.UseMethodType): UseMethodType {
  return {
    id: apiUseMethodType.id,
    name: apiUseMethodType.name,
    unit: apiUseMethodType.unit,
    groupName: apiUseMethodType.groupName,
    pondClassId: apiUseMethodType.pondClassId,
    reportGroup: apiUseMethodType.reportGroup,
    position: apiUseMethodType.position,
    isDeleted: apiUseMethodType.isDeleted,
  };
}

export function translateCarpVarietyToApi(useMethodType: UseMethodType): Api.UseMethodType {
  return {
    id: useMethodType.id,
    name: useMethodType.name,
    unit: useMethodType.unit,
    groupName: useMethodType.groupName,
    pondClassId: useMethodType.pondClassId,
    reportGroup: useMethodType.reportGroup,
    position: useMethodType.position,
    isDeleted: useMethodType.isDeleted,
  };
}
