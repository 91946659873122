import * as _ from "lodash";
import * as React from "react";
import { NewOrEditCarpMovingReport } from "../../../../domain/carpMovingReport";
import { isReleaseCarpSelectionReport, NewOrEditCarpSelectionReport } from "../../../../domain/carpSelectionReport";
import { getUniqueKey, UniqueKey } from "../../../../domain/entity";
import { Pond } from "../../../../domain/pond";
import { getEmptyAmountMessage, PondReport } from "../../../../domain/pondReport";
import { ScaleType } from "../../../../domain/scaleType";
import {
  FrySelectionReportVM,
  getFryAmount,
  isCarpMovingReport,
  ToFrySelectionReportVM,
} from "../../../../domain/selectionReportVM";
import { FormDatePicker } from "../../atoms/Form/DatePicker";
import { FormNumberInput, FormTextInput } from "../../atoms/Form/Input";
import { FormSelect } from "../../atoms/Form/Select";
import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "../../atoms/Table";
import {
  AmountCell,
  CellTitle,
  DateCell,
  MalformationCell,
  NoteCell,
  RatioCell,
  RowHeaderTableCell,
  SizeCell,
} from "./elements";

interface FrySelectionReportTableProps {
  ponds: Pond[];
  pondReports: PondReport[];
  carpSizeTypes: ScaleType[];
  carpQualityTypes: ScaleType[];
  malformationTypes: ScaleType[];
  carpRatioTypes: ScaleType[];
  carpSelectionReports: NewOrEditCarpSelectionReport[];
  carpMovingReports: NewOrEditCarpMovingReport[];
  onChangeSelectionReport: (selectionUid: UniqueKey, key: keyof NewOrEditCarpSelectionReport, value: any) => void;
  onChangeMovingReport: (movingUid: UniqueKey, key: keyof NewOrEditCarpMovingReport, value: any) => void;
  onClickMovingReport: (movingUid: UniqueKey) => void;
}

export class FrySelectionReportTable extends React.Component<FrySelectionReportTableProps> {
  public render() {
    const { ponds, pondReports } = this.props;

    const reports: FrySelectionReportVM[] = ToFrySelectionReportVM(
      [...this.props.carpSelectionReports, ...this.props.carpMovingReports],
      ponds,
      pondReports
    );
    const reportsEachSelectionNumber = _.groupBy(reports, (r) => r.selectionNumber);

    return (
      <Table definition={true}>
        <TableHeader>
          <TableRow>
            <TableHeaderCell />
            <TableHeaderCell>日付</TableHeaderCell>
            <TableHeaderCell>匹数</TableHeaderCell>
            <TableHeaderCell>サイズ</TableHeaderCell>
            <TableHeaderCell>評価</TableHeaderCell>
            <TableHeaderCell>点数</TableHeaderCell>
            <TableHeaderCell>奇形</TableHeaderCell>
            <TableHeaderCell>数</TableHeaderCell>
            <TableHeaderCell>メモ</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.keys(reportsEachSelectionNumber)
            .sort()
            .map((selectionNumber) =>
              this.renderSameSelectionReport(reportsEachSelectionNumber[selectionNumber], selectionNumber)
            )}
        </TableBody>
      </Table>
    );
  }

  private renderSameSelectionReport = (sameSelectionNumberReports: FrySelectionReportVM[], selectionNumber: string) => {
    const { carpSizeTypes, carpQualityTypes, malformationTypes, carpRatioTypes } = this.props;
    const sumAmount = getFryAmount(sameSelectionNumberReports);
    const rows = sameSelectionNumberReports.map((report) => {
      if (isCarpMovingReport(report)) {
        const movingId = getUniqueKey(report);
        const pairMovingId = report.movedId !== null ? report.movedId : report.moverId;
        return (
          <MovingTableRow
            key={movingId}
            cellTitle={report.name}
            date={report.date}
            amount={report.amount}
            note={report.note}
            onClickLink={this.handleClickMovingLink(pairMovingId)}
            onChangeDate={this.handleChangeDateOfMovingReport(movingId)}
            onChangeAmount={this.handleChangeAmountOfMovingReport(movingId)}
            onChangeNote={this.handleChangeNoteOfMovingReport(movingId)}
          />
        );
      }
      const selectionId = getUniqueKey(report);

      if (isReleaseCarpSelectionReport(report)) {
        return (
          <ReleaseCarpTableRow
            key={selectionId}
            cellTitle={report.name}
            date={report.date}
            amount={report.amount}
            note={report.note}
            onChangeDate={this.handleChangeDateOfSelectionReport(selectionId)}
            onChangeAmount={this.handleChangeAmountOfSelectionReport(selectionId)}
            onChangeNote={this.handleChangeNoteOfSelectionReport(selectionId)}
          />
        );
      }

      return (
        <SelectionTableRow
          key={selectionId}
          carpQualityTypes={carpQualityTypes}
          carpSizeTypes={carpSizeTypes}
          malformationTypes={malformationTypes}
          carpRatioTypes={carpRatioTypes}
          cellTitle={report.name}
          date={report.date}
          amount={report.amount}
          sizeId={report.carpSizeTypeId}
          qualityId={report.carpQualityTypeId}
          malformationId={report.malformationTypeId}
          ratioId={report.carpRatioTypeId}
          score={report.carpScore}
          note={report.note}
          onChangeDate={this.handleChangeDateOfSelectionReport(selectionId)}
          onChangeAmount={this.handleChangeAmountOfSelectionReport(selectionId)}
          onChangeSize={this.handleChangeSizeOfSelectionReport(selectionId)}
          onChangeQuality={this.handleChangeQualityOfSelectionReport(selectionId)}
          onChangeMalformation={this.handleChangeMalformationOfSelectionReport(selectionId)}
          onChangeRatio={this.handleChangeRatioOfSelectionReport(selectionId)}
          onChangeScore={this.handleChangeCarpScoreOfSelectionReport(selectionId)}
          onChangeNote={this.handleChangeNoteOfSelectionReport(selectionId)}
        />
      );
    });
    return (
      <React.Fragment key={selectionNumber}>
        {rows}
        {rows.length > 1 && <SummaryTableRow amount={sumAmount} />}
      </React.Fragment>
    );
  };

  /*** MovingReport ***/
  private handleChangeDateOfMovingReport = (movingId: UniqueKey) => (date: Date) => {
    this.props.onChangeMovingReport(movingId, "date", date);
  };
  private handleChangeAmountOfMovingReport = (movingId: UniqueKey) => (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChangeMovingReport(movingId, "amount", e.target.value);
  };

  private handleChangeNoteOfMovingReport = (movingId: UniqueKey) => (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChangeMovingReport(movingId, "note", e.target.value);
  };

  private handleClickMovingLink = (movingId: number | null) => () => {
    if (movingId !== null) {
      this.props.onClickMovingReport(movingId);
    }
  };

  /*** SelectionReport ***/
  private handleChangeDateOfSelectionReport = (selectionId: UniqueKey) => (date: Date) => {
    this.props.onChangeSelectionReport(selectionId, "date", date);
  };
  private handleChangeAmountOfSelectionReport = (selectionId: UniqueKey) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.props.onChangeSelectionReport(selectionId, "amount", e.target.value);
  };
  private handleChangeSizeOfSelectionReport = (selectionId: UniqueKey) => (sizeId: null | number) => {
    this.props.onChangeSelectionReport(selectionId, "carpSizeTypeId", sizeId);
  };
  private handleChangeQualityOfSelectionReport = (selectionId: UniqueKey) => (qualityId: null | number) => {
    this.props.onChangeSelectionReport(selectionId, "carpQualityTypeId", qualityId);
  };
  private handleChangeMalformationOfSelectionReport = (selectionId: UniqueKey) => (malformationId: null | number) => {
    this.props.onChangeSelectionReport(selectionId, "malformationTypeId", malformationId);
  };
  private handleChangeRatioOfSelectionReport = (selectionId: UniqueKey) => (ratioId: null | number) => {
    this.props.onChangeSelectionReport(selectionId, "carpRatioTypeId", ratioId);
  };

  private handleChangeCarpScoreOfSelectionReport = (selectionId: UniqueKey) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.props.onChangeSelectionReport(selectionId, "carpScore", e.target.value);
  };

  private handleChangeNoteOfSelectionReport = (selectionId: UniqueKey) => (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChangeSelectionReport(selectionId, "note", e.target.value);
  };
}

interface ReleaseCarpTableRowProps {
  cellTitle: string;
  date: Date | null;
  amount: string;
  note: string;
  onChangeDate: (date: Date) => void;
  onChangeAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeNote: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const ReleaseCarpTableRow = (props: ReleaseCarpTableRowProps) => {
  const { cellTitle, date, amount, note } = props;
  return (
    <TableRow>
      <RowHeaderTableCell>{cellTitle}</RowHeaderTableCell>
      <TableCell>
        <DateCell>
          <FormDatePicker label={""} date={date} onChange={props.onChangeDate} />
        </DateCell>
      </TableCell>
      <TableCell>
        <AmountCell>
          <FormNumberInput label={""} value={amount} onChange={props.onChangeAmount} />
        </AmountCell>
      </TableCell>
      <TableCell>
        <SizeCell />
      </TableCell>
      <TableCell>
        <SizeCell />
      </TableCell>
      <TableCell>
        <AmountCell />
      </TableCell>
      <TableCell>
        <MalformationCell />
      </TableCell>
      <TableCell>
        <RatioCell />
      </TableCell>
      <TableCell>
        <NoteCell>
          <FormTextInput label={""} value={note} onChange={props.onChangeNote} />
        </NoteCell>
      </TableCell>
    </TableRow>
  );
};

interface SelectionRowProps {
  carpSizeTypes: ScaleType[];
  carpQualityTypes: ScaleType[];
  malformationTypes: ScaleType[];
  carpRatioTypes: ScaleType[];
  cellTitle: string;
  date: Date | null;
  amount: string;
  sizeId: null | number;
  qualityId: null | number;
  malformationId: null | number;
  ratioId: null | number;
  score: string;
  note: string;
  onChangeDate: (date: Date) => void;
  onChangeAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeSize: (sizeId: null | number) => void;
  onChangeQuality: (qualityId: null | number) => void;
  onChangeMalformation: (malformationId: null | number) => void;
  onChangeRatio: (ratioId: null | number) => void;
  onChangeScore: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeNote: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SelectionTableRow = (props: SelectionRowProps) => {
  const { cellTitle, date, amount, note } = props;
  return (
    <TableRow>
      <RowHeaderTableCell>{cellTitle}</RowHeaderTableCell>
      <TableCell>
        <DateCell>
          <FormDatePicker label={""} date={date} onChange={props.onChangeDate} />
        </DateCell>
      </TableCell>
      <TableCell>
        <AmountCell>
          <FormNumberInput label={""} value={amount} onChange={props.onChangeAmount} />
        </AmountCell>
      </TableCell>
      <TableCell>
        <SizeCell>
          <FormSelect
            value={props.sizeId}
            label={""}
            options={props.carpSizeTypes}
            onChange={props.onChangeSize}
            minWidth={"0"}
          />
        </SizeCell>
      </TableCell>
      <TableCell>
        <SizeCell>
          <FormSelect
            value={props.qualityId}
            label={""}
            options={props.carpQualityTypes}
            onChange={props.onChangeQuality}
            minWidth={"0"}
          />
        </SizeCell>
      </TableCell>
      <TableCell>
        <AmountCell>
          <FormNumberInput label={""} value={props.score} onChange={props.onChangeScore} />
        </AmountCell>
      </TableCell>
      <TableCell>
        <MalformationCell>
          <FormSelect
            value={props.malformationId}
            label={""}
            options={props.malformationTypes}
            onChange={props.onChangeMalformation}
            minWidth={"0"}
          />
        </MalformationCell>
      </TableCell>
      <TableCell>
        <RatioCell>
          <FormSelect
            value={props.ratioId}
            label={""}
            options={props.carpRatioTypes}
            onChange={props.onChangeRatio}
            minWidth={"0"}
          />
        </RatioCell>
      </TableCell>
      <TableCell>
        <NoteCell>
          <FormTextInput label={""} value={note} onChange={props.onChangeNote} />
        </NoteCell>
      </TableCell>
    </TableRow>
  );
};

interface MovingRowProps {
  cellTitle: string;
  date: Date | null;
  amount: string;
  note: string;
  onClickLink: () => void;
  onChangeDate: (date: Date) => void;
  onChangeAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeNote: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const MovingTableRow = (props: MovingRowProps) => {
  const { cellTitle, date, amount, note } = props;
  return (
    <TableRow>
      <RowHeaderTableCell>
        <CellTitle onClick={props.onClickLink}>{cellTitle}</CellTitle>
      </RowHeaderTableCell>
      <TableCell>
        <DateCell>
          <FormDatePicker label={""} date={date} onChange={props.onChangeDate} />
        </DateCell>
      </TableCell>
      <TableCell>
        <AmountCell>
          <FormNumberInput label={""} value={amount} onChange={props.onChangeAmount} />
        </AmountCell>
      </TableCell>
      <TableCell>
        <SizeCell />
      </TableCell>
      <TableCell>
        <SizeCell />
      </TableCell>
      <TableCell>
        <AmountCell />
      </TableCell>
      <TableCell>
        <MalformationCell />
      </TableCell>
      <TableCell>
        <RatioCell />
      </TableCell>
      <TableCell>
        <NoteCell>
          <FormTextInput label={""} value={note} onChange={props.onChangeNote} />
        </NoteCell>
      </TableCell>
    </TableRow>
  );
};

interface SummaryRowProps {
  amount: number;
}
const SummaryTableRow = (props: SummaryRowProps) => {
  return (
    <TableRow>
      <RowHeaderTableCell>合計匹数</RowHeaderTableCell>
      <TableCell>
        <DateCell />
      </TableCell>
      <TableCell>
        <AmountCell className={"text-center"}>
          {props.amount === 0 ? getEmptyAmountMessage() : `${props.amount} 匹`}
        </AmountCell>
      </TableCell>
      <TableCell>
        <SizeCell />
      </TableCell>
      <TableCell>
        <SizeCell />
      </TableCell>
      <TableCell>
        <AmountCell />
      </TableCell>
      <TableCell>
        <MalformationCell />
      </TableCell>
      <TableCell>
        <RatioCell />
      </TableCell>
      <TableCell>
        <NoteCell />
      </TableCell>
    </TableRow>
  );
};
