import * as React from "react";
import { NewOrEditFertilizer } from "../../../../domain/fertilizer";
import { Form } from "../../atoms/Form/Form";
import { FormTextInput } from "../../atoms/Form/Input";
import { Spinner } from "../../atoms/Spinner";
import { MasterForm } from "../../molecules/MasterForm";
import { MasterFormButton } from "../../molecules/MasterFormButton";

interface FertilizerFormProps {
  fertilizer: NewOrEditFertilizer | null;
  isSaving: boolean;
  onChangeName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickSave: () => void;
  onClickCancel: () => void;
  onClickAdd: (() => void) | null;
  onClickDelete: (() => void) | null;
}

export const FertilizerForm = ({
  fertilizer,
  isSaving,
  onChangeName,
  onClickSave,
  onClickCancel,
  onClickAdd,
  onClickDelete,
}: FertilizerFormProps) => {
  if (fertilizer === null) {
    return <Spinner />;
  }

  return (
    <MasterForm onClickAdd={onClickAdd} onClickDelete={onClickDelete}>
      {isSaving && <Spinner />}
      <Form>
        <FormTextInput label={"肥料名"} value={fertilizer.name} onChange={onChangeName} />
        <MasterFormButton onClickSave={onClickSave} onClickCancel={onClickCancel} />
      </Form>
    </MasterForm>
  );
};
