import { Dispatch } from "redux";
import { getFiscalStartDate, getFiscalYear } from "../../../domain/calendar";
import {
  createNewCarpMovingReport,
  NewCarpMovingReport,
  NewOrEditCarpMovingReport,
} from "../../../domain/carpMovingReport";
import { NewOrEditCarpSelectionReport } from "../../../domain/carpSelectionReport";
import { getUniqueKey, UniqueKey } from "../../../domain/entity";
import { NewOrEditPondDisinfectionReport } from "../../../domain/pondDisinfectionReport";
import {
  createNewFryPondReport,
  EditPondReport,
  fillEmptyFryPondReports,
  getCurrentPondReport,
  getFryMaxSelectionNumber,
  getFryMaxSubNumberOfSelectionNumber,
  isAdultPondReports,
  isNewPondReport,
  NewOrEditPondReport,
  NewPondReport,
  overwriteFryPondReportBeforeSaving,
  PondReport,
  PondReportValidator,
  removeEmptyFryPondReports,
} from "../../../domain/pondReport";
import { ScaleType } from "../../../domain/scaleType";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { PondReportRepository } from "../../../infrastracture/pondReport/repository";
import { ApplicationState } from "../../../store/modules";
import { apiPondReportActions } from "../../../store/modules/api/pondReport/ducks";
import { notificationAlertStateActions } from "../../../store/modules/notification/alert/ducks";
import { notificationQueueStateActions } from "../../../store/modules/notification/queue/ducks";
import { reportPondReportNewOrEditStateActions } from "../../../store/modules/report/pondReport/newOrEditState/ducks";
import { PondReportApiService } from "../../api/pondReport";

interface IFryPondNewOrEditService extends IApplicationService {
  addPondReport: () => void;
  changeParentCarp: (reportUniqueKey: UniqueKey, parentCarpId: number) => void;
  changeCarpPairing: (reportUniqueKey: UniqueKey, carpPairingId: number) => void;
  changeFiscalYear: (reportUniqueKey: UniqueKey, fiscalYear: string) => void;
  changeCarpMovingReport: (
    reportUniqueKey: UniqueKey,
    movingUniqueKey: UniqueKey,
    key: keyof NewOrEditCarpMovingReport,
    value: any
  ) => void;
  changeCarpSelectionReport: (
    reportUniqueKey: UniqueKey,
    selectionUniqueKey: UniqueKey,
    key: keyof NewOrEditCarpSelectionReport,
    value: any
  ) => void;
  changePondDisinfectionReport: (
    reportUniqueKey: UniqueKey,
    disinfectionUniqueKey: UniqueKey,
    key: keyof NewOrEditPondDisinfectionReport,
    value: any
  ) => void;
  addCarpMovingReport: () => void;
  savePondReports: () => void;
}

export class FryPondNewOrEditStateService implements IFryPondNewOrEditService {
  private readonly pondReportApiService: PondReportApiService;
  public constructor(private dispatch: Dispatch<any>) {
    this.pondReportApiService = new PondReportApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public async addPondReport() {
    await this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedPondId } = state.report.navigation;
      const pond = state.api.pond.ponds.find((p) => p.id === selectedPondId);
      if (!pond) {
        throw new ApplicationError("野池が見つかりませんでした。");
      }
      const pondType = state.api.pondType.pondTypes.find((t) => t.id === pond.pondTypeId);
      if (!pondType) {
        throw new ApplicationError("野池の区分が見つかりませんでした。");
      }
      const user = state.auth.user;
      if (user === null) {
        throw new ApplicationError("不正な操作です。");
      }
      const { selectedDate, pondReports } = state.report.pondReport.newOrEditState;
      const maxSubNumber = Math.max(...(pondReports || []).map((pr) => pr.subNumber), 0);
      const pondReport: NewPondReport = {
        ...createNewFryPondReport(pond.id, user.id, getFiscalYear(selectedDate), pondType.id, maxSubNumber + 1),
      };

      this.dispatch(
        reportPondReportNewOrEditStateActions.addPondReport({
          pondReport,
        })
      );
    });
  }

  @catchApplicationError()
  public changeParentCarp(reportUniqueKey: UniqueKey, parentCarpId: number) {
    this.dispatch(
      reportPondReportNewOrEditStateActions.changePondReport({
        reportUniqueKey,
        key: "parentCarpId",
        value: parentCarpId,
      })
    );
  }

  @catchApplicationError()
  public changeCarpPairing(reportUniqueKey: UniqueKey, pairingId: number) {
    this.dispatch(
      reportPondReportNewOrEditStateActions.changePondReport({
        reportUniqueKey,
        key: "carpPairingId",
        value: pairingId,
      })
    );
  }

  @catchApplicationError()
  public changeFiscalYear(reportUniqueKey: UniqueKey, fiscalYear: string) {
    this.dispatch(
      reportPondReportNewOrEditStateActions.changePondReport({ reportUniqueKey, key: "fiscalYear", value: fiscalYear })
    );
  }

  @catchApplicationError()
  public changeCarpMovingReport(
    reportUniqueKey: UniqueKey,
    movingUniqueKey: UniqueKey,
    key: keyof NewOrEditCarpMovingReport,
    value: any
  ) {
    this.dispatch(
      reportPondReportNewOrEditStateActions.changeCarpMovingReport({ reportUniqueKey, movingUniqueKey, key, value })
    );
  }

  @catchApplicationError()
  public changeCarpSelectionReport(
    reportUniqueKey: UniqueKey,
    selectionUniqueKey: UniqueKey,
    key: keyof NewOrEditCarpSelectionReport,
    value: any
  ) {
    this.dispatch(
      reportPondReportNewOrEditStateActions.changeCarpSelectionReport({
        reportUniqueKey,
        selectionUniqueKey,
        key,
        value,
      })
    );
  }

  @catchApplicationError()
  public changePondDisinfectionReport(
    reportUniqueKey: UniqueKey,
    disinfectionUniqueKey: UniqueKey,
    key: keyof NewOrEditPondDisinfectionReport,
    value: any
  ) {
    this.dispatch(
      reportPondReportNewOrEditStateActions.changePondDisinfectionReport({
        reportUniqueKey,
        disinfectionUniqueKey,
        key,
        value,
      })
    );
  }

  @catchApplicationError((dispatch) => dispatch(reportPondReportNewOrEditStateActions.saveFail()))
  public async addCarpMovingReport() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(reportPondReportNewOrEditStateActions.saveStart());

      const state = getState();
      const { newOrEditState } = state.report.pondReport;
      const { pondReports, selectedDate } = newOrEditState;
      if (pondReports === null || pondReports.length === 0) {
        throw new ApplicationError("不正な動作です。");
      }
      const pondReport = getCurrentPondReport(pondReports, state.report.navigation.selectedPondId, selectedDate);
      if (!pondReport) {
        throw new ApplicationError("移動元の野池の選別記録が見つかりませんでした。");
      }
      const selectionNumber = getFryMaxSelectionNumber(pondReport);
      const selectionReport = pondReport.carpSelectionReports.find((csr) => csr.selectionNumber === selectionNumber);
      if (!selectionReport) {
        throw new ApplicationError("移動元の選別記録が見つかりませんでした。");
      }
      if (selectionReport.date === null) {
        throw new ApplicationError(
          "該当の選別に日付が入っていないので、移動の記録の日付を入れることができませんでした。"
        );
      }
      const subNumber = getFryMaxSubNumberOfSelectionNumber(pondReport, selectionNumber) + 1;
      const { modalState } = state.report.pondReport.moveModalState;

      const pairPondReport = getCurrentPondReport(
        state.api.pondReport.pondReports,
        modalState.pondId,
        selectedDate
      ) as PondReport;
      if (pairPondReport === null) {
        throw new ApplicationError("移動先の野池の選別記録が見つかりませんでした。");
      }
      if (isAdultPondReports([pairPondReport], state.api.pondType.pondTypes)) {
        throw new ApplicationError("移動先の野池が稚魚池ではありません。");
      }

      const newMovingReport: NewCarpMovingReport = {
        ...createNewCarpMovingReport(selectionReport.date, selectionNumber, subNumber),
        pairPondReportId: pairPondReport.id,
        amount: modalState.amount,
        note: modalState.note,
      };
      const requestPondReport = {
        ...pondReport,
        carpMovingReports: pondReport.carpMovingReports.concat(newMovingReport),
      } as NewOrEditPondReport;

      const errorMessage = this.validatePondReports([requestPondReport], {
        pondReports: state.api.pondReport.pondReports,
        scaleTypes: state.api.scaleType.scaleTypes,
      });
      if (errorMessage !== null) {
        this.dispatch(notificationAlertStateActions.showErrorMessage({ errorMessage }));
        this.dispatch(reportPondReportNewOrEditStateActions.saveFail());
        return;
      }

      const savedPondReport = await this.savePondReport(requestPondReport, state.api.scaleType.scaleTypes);
      const savedPondReports = state.api.pondReport.pondReports
        .filter((pr) => {
          return pondReports.some((r) => getUniqueKey(r) === getUniqueKey(pr));
        })
        .map((pr) => {
          return getUniqueKey(pr) !== getUniqueKey(savedPondReport) ? pr : savedPondReport;
        });
      const filledReports = fillEmptyFryPondReports(savedPondReports) as PondReport[];
      this.dispatch(reportPondReportNewOrEditStateActions.saveSuccess({ pondReports: filledReports }));
      this.dispatch(apiPondReportActions.savePondReports({ pondReports: savedPondReports }));
      this.dispatch(notificationQueueStateActions.addMessage({ message: "鯉の移動を記録しました。" }));
      await this.pondReportApiService.fetchRelatedPondReports(savedPondReport.id);
    });
  }

  @catchApplicationError((dispatch) => dispatch(reportPondReportNewOrEditStateActions.saveFail()))
  public async savePondReports() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(reportPondReportNewOrEditStateActions.saveStart());
      const state = getState();
      const { newOrEditState } = state.report.pondReport;
      if (newOrEditState.pondReports === null || newOrEditState.pondReports.length === 0) {
        throw new ApplicationError("池の状態記録が見つかりませんでした。");
      }
      const errorMessage = this.validatePondReports(newOrEditState.pondReports, {
        pondReports: state.api.pondReport.pondReports,
        scaleTypes: state.api.scaleType.scaleTypes,
      });
      if (errorMessage !== null) {
        this.dispatch(notificationAlertStateActions.showErrorMessage({ errorMessage }));
        this.dispatch(reportPondReportNewOrEditStateActions.saveFail());
        return;
      }
      const savedPondReports = await Promise.all(
        newOrEditState.pondReports.map((pr) => this.savePondReport(pr, state.api.scaleType.scaleTypes))
      );
      const filledPondReports = fillEmptyFryPondReports(savedPondReports) as PondReport[];
      this.dispatch(reportPondReportNewOrEditStateActions.saveSuccess({ pondReports: filledPondReports }));
      this.dispatch(apiPondReportActions.savePondReports({ pondReports: savedPondReports }));

      const firstReport = filledPondReports[0];
      if (firstReport) {
        const fiscalYear = Number(firstReport.fiscalYear);
        this.dispatch(
          reportPondReportNewOrEditStateActions.changeSelectedDate({
            selectedDate: getFiscalStartDate(fiscalYear),
          })
        );
      }
    });
  }

  private validatePondReports(
    pondReports: NewOrEditPondReport[],
    apiValue: { pondReports: PondReport[]; scaleTypes: ScaleType[] }
  ): string | null {
    const errorMessages: string[] = [];
    removeEmptyFryPondReports(pondReports)
      .map((pr) => overwriteFryPondReportBeforeSaving(pr, apiValue.scaleTypes))
      .forEach((pr, index) => {
        const validator = new PondReportValidator(apiValue.pondReports);
        validator.validate(pr);
        if (!validator.isValid()) {
          errorMessages.push(`${index + 1}番目の記録 : ${validator.getMessages()}`);
        }
      });
    if (errorMessages.length === 0) {
      return null;
    }
    return errorMessages.join("\n");
  }

  private async savePondReport(pondReport: NewOrEditPondReport, scaleTypes: ScaleType[]): Promise<PondReport> {
    if (isNewPondReport(pondReport)) {
      const removedNewPondReport = removeEmptyFryPondReports([pondReport])[0] as NewPondReport;
      const savingNewPondReport = overwriteFryPondReportBeforeSaving(removedNewPondReport, scaleTypes) as NewPondReport;
      return await new PondReportRepository().postPondReport(savingNewPondReport);
    }
    const removedEditPondReport: EditPondReport = removeEmptyFryPondReports([pondReport])[0] as EditPondReport;
    const savingEditPondReport = overwriteFryPondReportBeforeSaving(
      removedEditPondReport,
      scaleTypes
    ) as EditPondReport;
    return await new PondReportRepository().putPondReport(savingEditPondReport);
  }
}
