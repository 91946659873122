import { Dispatch } from "redux";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { WorkerApiService } from "../../api/worker";

interface IReadApiService extends IApplicationService {
  init: () => void;
}

export class ReadApiService implements IReadApiService {
  private workerApiService: WorkerApiService;

  public constructor(private dispatch: Dispatch<any>) {
    this.workerApiService = new WorkerApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public async init() {
    await this.workerApiService.init();
  }
}
