import { actionCreatorFactory } from "typescript-fsa";
import { NewOrEditAdultCarpReport } from "../../../../../domain/adultCarpReport";
import { NewOrEditCarpMovingReport } from "../../../../../domain/carpMovingReport";
import { NewOrEditCarpSelectionReport } from "../../../../../domain/carpSelectionReport";
import { NewOrEditPondDisinfectionReport } from "../../../../../domain/pondDisinfectionReport";
import { NewOrEditPondReport, NewPondReport, PondReport } from "../../../../../domain/pondReport";

// Actions
const actionCreator = actionCreatorFactory("ReportPondReportNewOrEditState");

const createPondReports = actionCreator<CreatePondReportsPayload>("createPondReports");
interface CreatePondReportsPayload {
  pondReports: NewPondReport[];
}

const selectPondReports = actionCreator<SelectPondReportsPayload>("selectPondReports");
interface SelectPondReportsPayload {
  pondReports: NewOrEditPondReport[];
}

const addPondReport = actionCreator<AddPondReportPayload>("addPondReport");
interface AddPondReportPayload {
  pondReport: NewPondReport;
}

const changeSelectedDate = actionCreator<ChangeSelectedDatePayload>("changeSelectedDate");
interface ChangeSelectedDatePayload {
  selectedDate: Date;
}

const changePondReport = actionCreator<ChangePondReportPayload>("changePondReport");
interface ChangePondReportPayload {
  reportUniqueKey: UniqueKey;
  key: keyof NewOrEditPondReport;
  value: any;
}
const changeCarpMovingReport = actionCreator<ChangeCarpMovingReportPayload>("changeCarpMovingReport");
interface ChangeCarpMovingReportPayload {
  reportUniqueKey: UniqueKey;
  movingUniqueKey: UniqueKey;
  key: keyof NewOrEditCarpMovingReport;
  value: any;
}
const changeCarpSelectionReport = actionCreator<ChangeCarpSelectionReportPayload>("changeCarpSelectionReport");
interface ChangeCarpSelectionReportPayload {
  reportUniqueKey: UniqueKey;
  selectionUniqueKey: UniqueKey;
  key: keyof NewOrEditCarpSelectionReport;
  value: any;
}
const changePondDisinfectionReport = actionCreator<ChangePondDisinfectionReportPayload>("changePondDisinfectionReport");
interface ChangePondDisinfectionReportPayload {
  reportUniqueKey: UniqueKey;
  disinfectionUniqueKey: UniqueKey;
  key: keyof NewOrEditPondDisinfectionReport;
  value: any;
}
const changeAdultCarpReport = actionCreator<ChangeAdultCarpReportPayload>("changeAdultCarpReport");
interface ChangeAdultCarpReportPayload {
  reportUniqueKey: UniqueKey;
  adultUniqueKey: UniqueKey;
  key: keyof NewOrEditAdultCarpReport;
  value: any;
}

export const cancelPondReports = actionCreator<CancelPondReportsPayload>("cancelPondReports");
interface CancelPondReportsPayload {
  pondReports: NewOrEditPondReport[];
}

export const saveStart = actionCreator("saveStart");
export const saveSuccess = actionCreator<SaveSuccessPayload>("saveSuccess");
interface SaveSuccessPayload {
  pondReports: PondReport[];
}
export const saveFail = actionCreator("saveFail");

export const reportPondReportNewOrEditStateActions = {
  createPondReports,
  selectPondReports,
  addPondReport,
  changeSelectedDate,
  changePondReport,
  changeCarpMovingReport,
  changeCarpSelectionReport,
  changePondDisinfectionReport,
  changeAdultCarpReport,
  cancelPondReports,
  saveStart,
  saveSuccess,
  saveFail,
};

// Reducers
import { ChangeHandler, getInitialState, InitialHandler, NewOrEditStateType, SaveHandler } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";
import { UniqueKey } from "../../../../../domain/entity";

export const NewOrEditStateReducer = reducerWithInitialState<NewOrEditStateType>(getInitialState())
  .case(createPondReports, (state, { pondReports }) => {
    return InitialHandler.createPondReports(state, pondReports);
  })
  .case(selectPondReports, (state, { pondReports }) => {
    return InitialHandler.selectPondReports(state, pondReports);
  })
  .case(addPondReport, (state, { pondReport }) => {
    return ChangeHandler.addPondReport(state, pondReport);
  })
  .case(changeSelectedDate, (state, { selectedDate }) => {
    return ChangeHandler.changeSelectedDate(state, selectedDate);
  })
  .case(changePondReport, (state, { reportUniqueKey, key, value }) => {
    return ChangeHandler.changePondReport(state, reportUniqueKey, key, value);
  })
  .case(changeCarpMovingReport, (state, { reportUniqueKey, movingUniqueKey, key, value }) => {
    return ChangeHandler.changeCarpMovingReport(state, reportUniqueKey, movingUniqueKey, key, value);
  })
  .case(changeCarpSelectionReport, (state, { reportUniqueKey, selectionUniqueKey, key, value }) => {
    return ChangeHandler.changeCarpSelectionReport(state, reportUniqueKey, selectionUniqueKey, key, value);
  })
  .case(changePondDisinfectionReport, (state, { reportUniqueKey, disinfectionUniqueKey, key, value }) => {
    return ChangeHandler.changePondDisinfectionReport(state, reportUniqueKey, disinfectionUniqueKey, key, value);
  })
  .case(changeAdultCarpReport, (state, { reportUniqueKey, adultUniqueKey, key, value }) => {
    return ChangeHandler.changeAdultCarpReport(state, reportUniqueKey, adultUniqueKey, key, value);
  })
  .case(cancelPondReports, (state, { pondReports }) => {
    return SaveHandler.cancelPondReports(state, pondReports);
  })
  .case(saveStart, (state) => {
    return SaveHandler.saveStart(state);
  })
  .case(saveSuccess, (state, { pondReports }) => {
    return SaveHandler.saveSuccess(state, pondReports);
  })
  .case(saveFail, (state) => {
    return SaveHandler.saveFail(state);
  });
