import { actionCreatorFactory } from "typescript-fsa";
import { PondArea } from "../../../../domain/pondArea";
// Actions
const actionCreator = actionCreatorFactory("ApiPondArea");

const fetchStart = actionCreator("fetchStart");
const fetchComplete = actionCreator<FetchCompletePayload>("fetchComplete");
interface FetchCompletePayload {
  pondAreas: PondArea[];
}
const fetchFail = actionCreator("fetchFail");
const shouldFetch = actionCreator("shouldFetch");

// save
const createPondArea = actionCreator<CreatePondAreaPayload>("createPondArea");
interface CreatePondAreaPayload {
  pondArea: PondArea;
}
const updatePondArea = actionCreator<UpdatePondAreaPayload>("updatePondArea");
interface UpdatePondAreaPayload {
  pondArea: PondArea;
}
const deletePondArea = actionCreator<DeletePondAreaPayload>("deletePondArea");
interface DeletePondAreaPayload {
  pondArea: PondArea;
}
const replacePondAreas = actionCreator<ReplacePondAreasPayload>("replacePondAreas");
interface ReplacePondAreasPayload {
  pondAreas: PondArea[];
}

export const apiPondAreaActions = {
  fetchStart,
  fetchComplete,
  fetchFail,
  shouldFetch,
  createPondArea,
  updatePondArea,
  deletePondArea,
  replacePondAreas,
};

// Reducers
import { FetchHandlers, getInitialState, PondAreaStateType, SaveHandlers } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const PondAreaReducer = reducerWithInitialState<PondAreaStateType>(getInitialState())
  .case(fetchStart, (state) => FetchHandlers.start(state))
  .case(fetchComplete, (state, { pondAreas }) => FetchHandlers.complete(state, pondAreas))
  .case(fetchFail, (state) => FetchHandlers.fail(state))
  .case(shouldFetch, (state) => FetchHandlers.shouldFetch(state))
  .case(createPondArea, (state, { pondArea }) => SaveHandlers.createPondArea(state, pondArea))
  .case(updatePondArea, (state, { pondArea }) => SaveHandlers.updatePondArea(state, pondArea))
  .case(deletePondArea, (state, { pondArea }) => SaveHandlers.deletePondArea(state, pondArea))
  .case(replacePondAreas, (state, { pondAreas }) => SaveHandlers.replacePondAreas(state, pondAreas));
