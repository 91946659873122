export const DIMEN = {
  X0: "0",
  X0_5: "4px",
  X1: "8px",
  X1_5: "12px",
  X2: "16px",
  X2_5: "20px",
  X3: "24px",
  X4: "32px",
  X5: "40px",
  X6: "48px",
  X7: "56px",
  X8: "64px",
  X9: "72px",
  X10: "80px",
  X11: "88px",
  X12: "96px",
  X13: "104px",
  X14: "112px",
  X15: "120px",
  X16: "128px",
  X17: "136px",
  X18: "144px",
  X19: "152px",
  X20: "160px",
  X21: "168px",
  X22: "176px",
  X23: "184px",
  X24: "192px",
  X25: "200px",
  X26: "208px",
  X27: "216px",
  X28: "224px",
  X29: "232px",
  X30: "240px",
};
