import { actionCreatorFactory } from "typescript-fsa";
import { UniqueKey } from "../../../../domain/entity";

// Actions
const actionCreator = actionCreatorFactory("NotificationQueueState");

export const addMessage = actionCreator<AddMessagePayload>("addMessage");
interface AddMessagePayload {
  message: string;
}
export const removeMessage = actionCreator<RemoveMessagePayload>("removeMessage");
interface RemoveMessagePayload {
  id: UniqueKey;
}
export const addSaveMessage = ({ itemName }: { itemName: string }) => {
  return addMessage({ message: `${itemName}を保存しました。` });
};
export const addDeleteMessage = ({ itemName }: { itemName: string }) => {
  return addMessage({ message: `${itemName}を削除しました。` });
};

export const notificationQueueStateActions = {
  addMessage,
  addSaveMessage,
  addDeleteMessage,
  removeMessage,
};

// Reducers
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { getInitialState, InitHandlers, NotificationQueueStateType } from "./reducer";

export const notificationQueueStateReducer = reducerWithInitialState<NotificationQueueStateType>(getInitialState())
  .case(addMessage, (state, { message }) => InitHandlers.addMessage(state, message))
  .case(removeMessage, (state, { id }) => InitHandlers.removeMessage(state, id));
