import { Dispatch } from "redux";
import { isEntity } from "../../../domain/entity";
import { FeedValidator } from "../../../domain/feed";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { FeedRepository } from "../../../infrastracture/feed/repository";
import { ApplicationState } from "../../../store/modules";
import { apiFeedActions } from "../../../store/modules/api/feed/ducks";
import { masterFeedNewOrEditStateActions } from "../../../store/modules/master/feed/newOrEditState/ducks";
import { notificationAlertStateActions } from "../../../store/modules/notification/alert/ducks";
import { notificationQueueStateActions } from "../../../store/modules/notification/queue/ducks";

interface IEditStateService extends IApplicationService {
  selectFeed: (feedId: number) => void;
  changeName: (name: string) => void;
  changePondClass: (pondClassId: number) => void;
  cancelFeed: () => void;
  saveFeed: () => void;
  deleteFeed: () => void;
}

export class EditStateService implements IEditStateService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public selectFeed(feedId: number) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const { feeds } = getState().api.feed;
      const feed = feeds.find((f) => f.id === feedId);
      if (!feed) {
        throw new ApplicationError("餌が見つかりませんでした。");
      }
      this.dispatch(masterFeedNewOrEditStateActions.selectFeed({ feed }));
    });
  }

  @catchApplicationError()
  public changeName(name: string) {
    this.dispatch(masterFeedNewOrEditStateActions.changeFeed({ key: "name", value: name }));
  }

  @catchApplicationError()
  public changePondClass(pondClassId: null | number) {
    this.dispatch(masterFeedNewOrEditStateActions.changeFeed({ key: "pondClassId", value: pondClassId }));
  }

  @catchApplicationError()
  public cancelFeed() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const { api, master } = getState();
      const editFeed = master.feed.newOrEditState.feed;
      if (editFeed === null || !isEntity(editFeed)) {
        return;
      }
      const feed = api.feed.feeds.find((f) => f.id === editFeed.id);
      if (!feed) {
        throw new ApplicationError("餌が見つかりませんでした。");
      }
      this.dispatch(masterFeedNewOrEditStateActions.cancelFeed({ feed }));
    });
  }

  @catchApplicationError((dispatch) => dispatch(masterFeedNewOrEditStateActions.saveFail()))
  public async saveFeed() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(masterFeedNewOrEditStateActions.saveStart());
      const { newOrEditState } = getState().master.feed;
      const editFeed = newOrEditState.feed;
      if (editFeed === null || !isEntity(editFeed)) {
        throw new ApplicationError("餌が見つかりませんでした。");
      }
      const validator = new FeedValidator();
      validator.validate(editFeed);
      if (!validator.isValid()) {
        this.dispatch(notificationAlertStateActions.showErrorMessage({ errorMessage: validator.getMessages() }));
        this.dispatch(masterFeedNewOrEditStateActions.saveFail());
        return;
      }
      const savedFeed = await new FeedRepository().putFeed(editFeed);
      this.dispatch(masterFeedNewOrEditStateActions.saveSuccess({ feed: savedFeed }));
      this.dispatch(apiFeedActions.updateFeed({ feed: savedFeed }));
      this.dispatch(notificationQueueStateActions.addSaveMessage({ itemName: "餌" }));
    });
  }

  @catchApplicationError((dispatch) => dispatch(masterFeedNewOrEditStateActions.saveFail()))
  public async deleteFeed() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(masterFeedNewOrEditStateActions.saveStart());
      const { newOrEditState } = getState().master.feed;
      const editFeed = newOrEditState.feed;
      if (editFeed === null || !isEntity(editFeed)) {
        throw new ApplicationError("餌が見つかりませんでした。");
      }
      const savedFeed = await new FeedRepository().deleteFeed(editFeed);
      this.dispatch(masterFeedNewOrEditStateActions.saveSuccess({ feed: null }));
      this.dispatch(apiFeedActions.deleteFeed({ feed: savedFeed }));
      this.dispatch(notificationQueueStateActions.addDeleteMessage({ itemName: "餌" }));
    });
  }
}
