import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { NavigationStateService } from "../../../application/report/navigation";
import { isActiveEntity } from "../../../domain/entity";
import { isUsedPond, Pond } from "../../../domain/pond";
import { PondArea } from "../../../domain/pondArea";
import { ApplicationState } from "../../../store/modules";
import { NavigationStateType } from "../../../store/modules/report/navigation/reducer";
import { MasterGroupList } from "../../components/molecules/MasterGroupList";
import { MasterItem, MasterList } from "../../components/molecules/MasterList";
import { COLOR } from "../../styles/Color";

interface StateProps {
  pondAreas: PondArea[];
  ponds: Pond[];
  navigationState: NavigationStateType;
}

interface OwnProps {
  onClickPond: (pondId: number) => void;
}

interface DispatchProps {
  navigationStateService: NavigationStateService;
}

type PondsListProps = StateProps & OwnProps & DispatchProps;

export class Wrapped extends React.Component<PondsListProps> {
  public render() {
    const { selectedPondAreaId, selectedPondId } = this.props.navigationState;
    const filteredPonds = this.props.ponds.filter((p) => p.pondAreaId !== null && p.pondAreaId === selectedPondAreaId);
    return (
      <Outer>
        <Inner>
          <MasterGroupList
            items={this.getGroupItems()}
            selectedItemId={selectedPondAreaId}
            onClickItem={this.handleClickPondArea}
          />
        </Inner>
        <Inner>
          <MasterList items={filteredPonds} selectedItemId={selectedPondId} onClickItem={this.props.onClickPond} />
        </Inner>
      </Outer>
    );
  }

  private getGroupItems = (): MasterItem[] => {
    return this.props.pondAreas.map((area) => ({ id: area.id, name: area.name }));
  };

  private handleClickPondArea = (id: number) => {
    this.props.navigationStateService.selectPondArea(id);
  };
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StateProps & OwnProps => {
  const { api } = state;
  return {
    pondAreas: api.pondArea.pondAreas.filter(isActiveEntity),
    ponds: api.pond.ponds.filter((p) => isActiveEntity(p) && isUsedPond(p) && p.pondTypeId !== null),
    navigationState: state.report.navigation,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    navigationStateService: new NavigationStateService(dispatch),
  };
};

export const PondsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);

const Outer = styled.div`
  display: flex;
  height: 100%;
`;
const Inner = styled.div`
  flex: 1 1 0;
  border-right: 1px solid ${COLOR.Gray.base};
  height: 100%;
  overflow-y: auto;
  &:last-child {
    border-right: none;
  }
`;
