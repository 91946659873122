import { Entity } from "../entity";
import { getValidation, isEmptyString, isTextLength, Validation, Validator } from "../validation";

interface FertilizerAttribute extends Record<string, unknown> {
  name: string;
  position: number;
  isDeleted: boolean;
}

export interface NewFertilizer extends FertilizerAttribute {}
export interface EditFertilizer extends FertilizerAttribute, Entity {}

export type NewOrEditFertilizer = NewFertilizer | EditFertilizer;
export type Fertilizer = EditFertilizer;

// service

export function createNewFertilizer(): NewFertilizer {
  return {
    name: "",
    position: 1,
    isDeleted: false,
  };
}

/*** Validator ***/
export class FertilizerValidator extends Validator<NewOrEditFertilizer> {
  public validate(entity: NewOrEditFertilizer) {
    const nameValidation = validateName(entity);
    if (nameValidation) {
      this.addMessages(nameValidation);
    }
  }
}

function validateName({ name }: NewOrEditFertilizer): Validation | null {
  if (isEmptyString(name)) {
    return getValidation("肥料名は必須です。入力してください。");
  }
  if (!isTextLength(name, 0, 255)) {
    return getValidation("肥料名は255文字以下で入力してください。");
  }
  return null;
}
