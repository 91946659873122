import { Dispatch } from "redux";
import { isEntity } from "../../../domain/entity";
import { FertilizerValidator } from "../../../domain/fertilizer";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { FertilizerRepository } from "../../../infrastracture/fertilizer/repository";
import { ApplicationState } from "../../../store/modules";
import { apiFertilizerActions } from "../../../store/modules/api/fertilizer/ducks";
import { masterFertilizerNewOrEditStateActions } from "../../../store/modules/master/fertilizer/newOrEditState/ducks";
import { notificationAlertStateActions } from "../../../store/modules/notification/alert/ducks";
import { notificationQueueStateActions } from "../../../store/modules/notification/queue/ducks";

interface IEditStateService extends IApplicationService {
  selectFertilizer: (fertilizerId: number) => void;
  changeName: (name: string) => void;
  cancelFertilizer: () => void;
  saveFertilizer: () => void;
  deleteFertilizer: () => void;
}

export class EditStateService implements IEditStateService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public selectFertilizer(fertilizerId: number) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const { api } = getState();
      const fertilizer = api.fertilizer.fertilizers.find((f) => f.id === fertilizerId);
      if (!fertilizer) {
        throw new ApplicationError("肥料が見つかりませんでした。");
      }
      this.dispatch(masterFertilizerNewOrEditStateActions.selectFertilizer({ fertilizer }));
    });
  }

  @catchApplicationError()
  public changeName(name: string) {
    this.dispatch(masterFertilizerNewOrEditStateActions.changeFertilizer({ key: "name", value: name }));
  }

  @catchApplicationError()
  public cancelFertilizer() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const { api, master } = getState();
      const editFertilizer = master.fertilizer.newOrEditState.fertilizer;
      if (editFertilizer === null || !isEntity(editFertilizer)) {
        return;
      }
      const fertilizer = api.fertilizer.fertilizers.find((w) => w.id === editFertilizer.id);
      if (!fertilizer) {
        throw new ApplicationError("肥料が見つかりませんでした。");
      }
      this.dispatch(masterFertilizerNewOrEditStateActions.cancelFertilizer({ fertilizer }));
    });
  }

  @catchApplicationError((dispatch) => dispatch(masterFertilizerNewOrEditStateActions.saveFail()))
  public async saveFertilizer() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(masterFertilizerNewOrEditStateActions.saveStart());
      const { newOrEditState } = getState().master.fertilizer;
      const editFertilizer = newOrEditState.fertilizer;
      if (editFertilizer === null || !isEntity(editFertilizer)) {
        throw new ApplicationError("肥料が見つかりませんでした。");
      }
      const validator = new FertilizerValidator();
      validator.validate(editFertilizer);
      if (!validator.isValid()) {
        this.dispatch(notificationAlertStateActions.showErrorMessage({ errorMessage: validator.getMessages() }));
        this.dispatch(masterFertilizerNewOrEditStateActions.saveFail());
        return;
      }
      const savedFertilizer = await new FertilizerRepository().putFertilizer(editFertilizer);
      this.dispatch(masterFertilizerNewOrEditStateActions.saveSuccess({ fertilizer: savedFertilizer }));
      this.dispatch(apiFertilizerActions.updateFertilizer({ fertilizer: savedFertilizer }));
      this.dispatch(notificationQueueStateActions.addSaveMessage({ itemName: "肥料" }));
    });
  }

  @catchApplicationError((dispatch) => dispatch(masterFertilizerNewOrEditStateActions.saveFail()))
  public async deleteFertilizer() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(masterFertilizerNewOrEditStateActions.saveStart());
      const { newOrEditState } = getState().master.fertilizer;
      const editFertilizer = newOrEditState.fertilizer;
      if (editFertilizer === null || !isEntity(editFertilizer)) {
        throw new ApplicationError("肥料が見つかりませんでした。");
      }
      const savedFertilizer = await new FertilizerRepository().deleteFertilizer(editFertilizer);
      this.dispatch(masterFertilizerNewOrEditStateActions.saveSuccess({ fertilizer: null }));
      this.dispatch(apiFertilizerActions.deleteFertilizer({ fertilizer: savedFertilizer }));
      this.dispatch(notificationQueueStateActions.addDeleteMessage({ itemName: "肥料" }));
    });
  }
}
