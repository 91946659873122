import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { AdultPondNewOrEditStateService } from "../../../application/report/pondReport/adultPondNewOrEditState";
import { NewOrEditStateService } from "../../../application/report/pondReport/newOrEditState";
import { ReadApiService } from "../../../application/report/pondReport/readApi";
import { NewOrEditAdultCarpReport } from "../../../domain/adultCarpReport";
import { UniqueKey } from "../../../domain/entity";
import { Pond } from "../../../domain/pond";
import { PondArea } from "../../../domain/pondArea";
import { PondReport } from "../../../domain/pondReport";
import { PondType } from "../../../domain/pondType";
import { SearchQuery } from "../../../domain/searchQuery";
import { ApplicationState } from "../../../store/modules";
import { notificationQueueStateActions } from "../../../store/modules/notification/queue/ducks";
import { NavigationStateType } from "../../../store/modules/report/navigation/reducer";
import { NewOrEditStateType } from "../../../store/modules/report/pondReport/newOrEditState/reducer";
import { AdultPondReportForm, NoPondReportForm } from "../../components/organisms/AdultPondReportForm";

interface StateProps {
  ponds: Pond[];
  pondAreas: PondArea[];
  pondTypes: PondType[];
  pondReports: PondReport[];
  newOrEditState: NewOrEditStateType;
  navigationState: NavigationStateType;
}

interface DispatchProps {
  adultPondNewOrEditStateService: AdultPondNewOrEditStateService;
  readApiService: ReadApiService;
  newOrEditStateService: NewOrEditStateService;
  notifySaveMessage: () => void;
}

type PondReportEditPageProps = StateProps & DispatchProps & RouteComponentProps;

export class Wrapped extends React.Component<PondReportEditPageProps> {
  public render() {
    const { pondReports, selectedDate, isSaving } = this.props.newOrEditState;
    const { selectedPondId } = this.props.navigationState;
    const selectedPond = this.props.ponds.find((p) => p.id === selectedPondId);
    const selectedPondArea = selectedPond && this.props.pondAreas.find((p) => p.id === selectedPond.pondAreaId);

    if (pondReports === null) {
      return (
        <NoPondReportForm
          selectedPondArea={selectedPondArea as PondArea}
          selectedPond={selectedPond as Pond}
          onClickNew={this.handleClickNewPondReport}
        />
      );
    }

    return (
      <AdultPondReportForm
        pondTypes={this.props.pondTypes}
        pondReports={pondReports}
        selectedPond={selectedPond as Pond}
        selectedPondArea={selectedPondArea || null}
        selectedDate={selectedDate}
        onChangeFiscalYear={this.handleChangeFiscalYear}
        onChangePondType={this.handleChangePondType}
        onChangeAdultCarpReport={this.handleChangeAdultCarpReport}
        onClickNew={this.handleClickNewPondReport}
        onClickPrevYear={this.handleClickPrevYear}
        onClickCurrentYear={this.handleClickCurrentYear}
        onClickNextYear={this.handleClickNextYear}
        onClickSave={this.handleClickSavePondReport}
        onClickCancel={this.handleClickCancelPondReport}
        isSaving={isSaving}
      />
    );
  }

  private handleChangeFiscalYear = (reportUniqueKey: UniqueKey) => (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.adultPondNewOrEditStateService.changeFiscalYear(reportUniqueKey, e.target.value);
  };

  private handleChangePondType = (__: UniqueKey) => (pondTypeId: number | null) => {
    if (pondTypeId === null) {
      return;
    }
    this.props.newOrEditStateService.changePondType(pondTypeId);
  };

  private handleChangeAdultCarpReport = (reportUniqueKey: UniqueKey) => (
    adultUniqueKey: UniqueKey,
    key: keyof NewOrEditAdultCarpReport,
    value: any
  ) => {
    this.props.adultPondNewOrEditStateService.changeAdultCarpReport(reportUniqueKey, adultUniqueKey, key, value);
  };

  private handleClickPrevYear = async () => {
    this.props.newOrEditStateService.changePrevYear();
    await this.props.readApiService.getPondReports();
    this.props.newOrEditStateService.selectPondReports();
  };
  private handleClickCurrentYear = async () => {
    this.props.newOrEditStateService.changeCurrentYear();
    await this.props.readApiService.getPondReports();
    this.props.newOrEditStateService.selectPondReports();
  };
  private handleClickNextYear = async () => {
    this.props.newOrEditStateService.changeNextYear();
    await this.props.readApiService.getPondReports();
    this.props.newOrEditStateService.selectPondReports();
  };

  private handleClickNewPondReport = () => {
    this.props.newOrEditStateService.createPondReports();
  };

  private handleClickSavePondReport = async () => {
    await this.props.adultPondNewOrEditStateService.savePondReports();
    if (this.props.newOrEditState.isSaved) {
      this.props.notifySaveMessage();
      const returnURL = SearchQuery.getReturnURLFromState(this.props.location.search);
      if (!returnURL) {
        this.props.newOrEditStateService.selectPondReports();
        return;
      }
      const url = SearchQuery.createURLFromState("", this.props.location.search);
      if (url !== "") {
        this.props.history.push(url);
      }
    }
  };

  private handleClickCancelPondReport = () => {
    this.props.newOrEditStateService.selectPondReports();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { newOrEditState } = state.report.pondReport;
  return {
    ponds: state.api.pond.ponds,
    pondAreas: state.api.pondArea.pondAreas,
    pondTypes: state.api.pondType.pondTypes,
    pondReports: state.api.pondReport.pondReports,
    newOrEditState,
    navigationState: state.report.navigation,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    adultPondNewOrEditStateService: new AdultPondNewOrEditStateService(dispatch),
    readApiService: new ReadApiService(dispatch),
    newOrEditStateService: new NewOrEditStateService(dispatch),
    notifySaveMessage: () => dispatch(notificationQueueStateActions.addSaveMessage({ itemName: "放鯉・池揚げ記録" })),
  };
};

export const AdultPondPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
