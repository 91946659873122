import * as React from "react";
import styled from "styled-components";
import { ReportGroup } from "../../../../domain/reportGroup";
import { DIMEN } from "../../../styles/Dimen";
import { SelectButton } from "../../atoms/Button";
import { Form } from "../../atoms/Form/Form";
import { ContentSection, PageSection } from "../../atoms/Section";

interface SortingState {
  isAscDate: boolean;
}

interface FilterState {
  selectedReportTypeIds: number[];
}

interface ReportFilterFormProps {
  sortingState: SortingState;
  filterState: FilterState;

  onChangeSortDate: (isAscDate: boolean) => void;
  onChangeReportType: (reportTypeId: number) => void;
}
export const ReportFilterForm = (props: ReportFilterFormProps) => {
  const { sortingState, filterState, onChangeSortDate, onChangeReportType } = props;
  return (
    <Form>
      <PageSection>ソート</PageSection>
      <ContentSection>日付</ContentSection>
      <Content>
        <Button active={sortingState.isAscDate} onClick={() => onChangeSortDate(true)}>
          古い順
        </Button>
        <Button active={!sortingState.isAscDate} onClick={() => onChangeSortDate(false)}>
          新しい順
        </Button>
      </Content>
      <PageSection>絞り込み</PageSection>
      <ContentSection>記録</ContentSection>
      <Content>
        <Button
          active={filterState.selectedReportTypeIds.includes(ReportGroup.Feed)}
          onClick={() => onChangeReportType(ReportGroup.Feed)}>
          給餌
        </Button>
        <Button
          active={filterState.selectedReportTypeIds.includes(ReportGroup.EnvironmentReport)}
          onClick={() => onChangeReportType(ReportGroup.EnvironmentReport)}>
          水温
        </Button>
        <Button
          active={filterState.selectedReportTypeIds.includes(ReportGroup.Fertilizer)}
          onClick={() => onChangeReportType(ReportGroup.Fertilizer)}>
          肥料
        </Button>
        <Button
          active={filterState.selectedReportTypeIds.includes(ReportGroup.Herbicide)}
          onClick={() => onChangeReportType(ReportGroup.Herbicide)}>
          除草剤・薬品
        </Button>
        <Button
          active={filterState.selectedReportTypeIds.includes(ReportGroup.Note)}
          onClick={() => onChangeReportType(ReportGroup.Note)}>
          メモ
        </Button>
        <Button
          active={filterState.selectedReportTypeIds.includes(ReportGroup.PondReport)}
          onClick={() => onChangeReportType(ReportGroup.PondReport)}>
          選別
        </Button>
      </Content>
    </Form>
  );
};
const Content = styled.div`
  padding: ${DIMEN.X1};
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const Button = styled(SelectButton)`
  min-width: 10em;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
`;
