import * as React from "react";
import { MobileView } from "react-device-detect";
import styled from "styled-components";
import { isBucketUseMethodType, UseMethodType } from "../../../../domain/useMethodType";
import { DIMEN } from "../../../styles/Dimen";
import { UpDownButton } from "../../atoms/Button";
import { FormNumberInput } from "../../atoms/Form/Input";
import { FormSelect } from "../../atoms/Form/Select";

interface ReportAmountUnitInputProps {
  useMethodTypes: UseMethodType[];
  amount: string;
  useMethodTypeId: null | number;
  onChangeAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeUseMethodType: (id: null | number) => void;
  onClickAmountUp: () => void;
  onClickAmountDown: () => void;
}

export const ReportAmountUnitInput = (props: ReportAmountUnitInputProps) => {
  const isSingleUseMethod = props.useMethodTypes.length === 1;
  if (isSingleUseMethod) {
    const useMethod = props.useMethodTypes[0];
    const isBucket = isBucketUseMethodType(useMethod);
    return (
      <AmountOuter>
        <Prefix>{isBucket ? useMethod.name : ""}</Prefix>
        <Amount>
          <FormNumberInput value={props.amount} onChange={props.onChangeAmount} />
        </Amount>
        <Suffix>{useMethod.unit}</Suffix>
        <AmountSelect>
          <MobileView>
            <UpDownButton onClickUp={props.onClickAmountUp} onClickDown={props.onClickAmountDown} />
          </MobileView>
        </AmountSelect>
      </AmountOuter>
    );
  }

  return (
    <AmountOuter>
      <Amount>
        <FormNumberInput value={props.amount} onChange={props.onChangeAmount} />
      </Amount>
      <UseMethod>
        <FormSelect
          value={props.useMethodTypeId}
          options={props.useMethodTypes}
          onChange={props.onChangeUseMethodType}
          minWidth={"1.5em"}
        />
      </UseMethod>
      <AmountSelect>
        <MobileView>
          <UpDownButton onClickUp={props.onClickAmountUp} onClickDown={props.onClickAmountDown} />
        </MobileView>
      </AmountSelect>
    </AmountOuter>
  );
};

interface ReportAmountInputProps {
  amount: string;
  unit: string;
  onChangeAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickAmountUp: () => void;
  onClickAmountDown: () => void;
}
export const ReportAmountInput = (props: ReportAmountInputProps) => {
  return (
    <AmountOuter>
      <Prefix />
      <Amount>
        <FormNumberInput value={props.amount} onChange={props.onChangeAmount} />
      </Amount>
      <Suffix>{props.unit}</Suffix>
      <AmountSelect>
        <MobileView>
          <UpDownButton onClickUp={props.onClickAmountUp} onClickDown={props.onClickAmountDown} />
        </MobileView>
      </AmountSelect>
    </AmountOuter>
  );
};

interface ReportAmountInputOnly {
  amount: string;
  unit: string;
  onChangeAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ReportAmountInputOnly = (props: ReportAmountInputOnly) => {
  return (
    <AmountOuter>
      <Prefix />
      <Amount>
        <FormNumberInput value={props.amount} onChange={props.onChangeAmount} />
      </Amount>
      <Suffix>{props.unit}</Suffix>
    </AmountOuter>
  );
};

const AmountOuter = styled.div`
  display: flex;
  align-items: center;
`;

const Prefix = styled.div`
  width: 4em;
  margin-right: ${DIMEN.X2};
  text-align: center;
`;

const Suffix = styled.div`
  width: 4em;
  margin-right: ${DIMEN.X2};
  text-align: center;
`;

const Amount = styled.div`
  width: 7em;
  margin-right: ${DIMEN.X2};
`;
const UseMethod = styled.div`
  width: 10em;
  margin-right: ${DIMEN.X2};
`;
const AmountSelect = styled.div`
  flex: 1 1;
`;
