import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { NewStateService } from "../../../application/master/feed/newState";
import { ApplicationState } from "../../../store/modules";
import { NewOrEditStateType } from "../../../store/modules/master/feed/newOrEditState/reducer";
import { FeedForm } from "../../components/organisms/FeedForm";

interface StateProps {
  newOrEditState: NewOrEditStateType;
}

interface DispatchProps {
  newStateService: NewStateService;
}

type FeedNewPageProps = StateProps & DispatchProps;

export class Wrapped extends React.Component<FeedNewPageProps> {
  public render() {
    const { feed, isSaving } = this.props.newOrEditState;
    return (
      <FeedForm
        feed={feed}
        isSaving={isSaving}
        onChangeName={this.handleChangeName}
        onChangePondClass={this.handleChangePondClass}
        onClickSave={this.handleClickSaveFeed}
        onClickCancel={this.handleClickCancelFeed}
        onClickAdd={this.handleClickAddFeed}
        onClickDelete={null}
      />
    );
  }

  private handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.newStateService.changeName(e.target.value);
  };

  private handleChangePondClass = (pondClassId: null | number) => {
    this.props.newStateService.changePondClass(pondClassId);
  };

  private handleClickSaveFeed = () => {
    this.props.newStateService.saveFeed();
  };

  private handleClickCancelFeed = () => {
    this.props.newStateService.cancelFeed();
  };

  private handleClickAddFeed = () => {
    this.props.newStateService.createFeed();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    newOrEditState: state.master.feed.newOrEditState,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    newStateService: new NewStateService(dispatch),
  };
};

export const FeedNewPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);
