import { combineReducers } from "redux";
import { NewOrEditStateType } from "./newOrEditState/reducer";
import { PondAreaStateType } from "./pondAreaState/reducer";

export interface MasterPondState {
  newOrEditState: NewOrEditStateType;
  pondAreaState: PondAreaStateType;
}

import { NewOrEditStateReducer } from "./newOrEditState/ducks";
import { PondAreaStateReducer } from "./pondAreaState/ducks";

export const pondReducer = combineReducers({
  newOrEditState: NewOrEditStateReducer,
  pondAreaState: PondAreaStateReducer,
});
