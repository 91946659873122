import { FeedReasonType } from "../../domain/feedReasonType";
import * as api from "../../libs/client";
import { translateApiToFeedReasonType } from "./translate";

interface IFeedReasonTypeRepository {
  getFeedReasonTypes: () => Promise<FeedReasonType[]>;
}

export class FeedReasonTypeRepository implements IFeedReasonTypeRepository {
  public async getFeedReasonTypes(): Promise<FeedReasonType[]> {
    const feedReasonTypesApi = new api.FeedReasonTypesApi(api.serverConfig);
    const response = await feedReasonTypesApi.getFeedReasonTypes();
    return response.data.map((d) => translateApiToFeedReasonType(d));
  }
}
