import { default as moment } from "moment";
import { isEntity } from "../../domain/entity";
import { FeedReport, NewOrEditFeedReport } from "../../domain/feedReport";
import { FertilizerReport, NewOrEditFertilizerReport } from "../../domain/fertilizerReport";
import { HerbicideReport, NewOrEditHerbicideReport } from "../../domain/herbicideReport";
import { isEditWorkReport, NewOrEditWorkReport, WorkReport } from "../../domain/workReport";
import * as Api from "../../libs/apiClient";

/*** WorkReport ***/
export function translateApiToWorkReport(apiWorkReport: Api.WorkReport): WorkReport {
  return {
    id: apiWorkReport.id as number,

    pondId: apiWorkReport.pondId,
    userId: apiWorkReport.userId,
    pondTypeId: apiWorkReport.pondTypeId,
    date: moment(apiWorkReport.date, "YYYY-MM-DD").toDate(),
    note: apiWorkReport.note,
    imageIds: apiWorkReport.imageIds || [],

    feedReports: apiWorkReport.feedReports.map(translateApiToFeedReport),
    fertilizerReports: apiWorkReport.fertilizerReports.map(translateApiToFertilizerReport),
    herbicideReports: apiWorkReport.herbicideReports.map(translateApiToHerbicideReport),
  };
}

export function translateWorkReportToApi(workReport: NewOrEditWorkReport): Api.WorkReport {
  return {
    id: isEntity(workReport) ? workReport.id : undefined,

    pondId: workReport.pondId,
    userId: workReport.userId,
    pondTypeId: workReport.pondTypeId,
    date: moment(workReport.date).format("YYYY-MM-DD"),
    note: workReport.note,
    imageIds: workReport.imageIds,

    feedReports: isEditWorkReport(workReport)
      ? workReport.feedReports.map(translateFeedReportToApi)
      : workReport.feedReports.map(translateFeedReportToApi),
    fertilizerReports: isEditWorkReport(workReport)
      ? workReport.fertilizerReports.map(translateFertilizerReportToApi)
      : workReport.fertilizerReports.map(translateFertilizerReportToApi),
    herbicideReports: isEditWorkReport(workReport)
      ? workReport.herbicideReports.map(translateHerbicideReportToApi)
      : workReport.herbicideReports.map(translateHerbicideReportToApi),
  };
}

/*** FeedReport ***/
export function translateApiToFeedReport(apiFeedReport: Api.FeedReport): FeedReport {
  return {
    id: apiFeedReport.id as number,
    feedId: apiFeedReport.feedId,
    feedReasonTypeId: apiFeedReport.feedReasonTypeId,
    useMethodTypeId: apiFeedReport.useMethodTypeId,
    count: "" + apiFeedReport.count,
    amount: "" + apiFeedReport.amount,
  };
}

export function translateFeedReportToApi(feedReport: NewOrEditFeedReport): Api.FeedReport {
  return {
    id: isEntity(feedReport) ? feedReport.id : undefined,
    feedId: feedReport.feedId as number,
    feedReasonTypeId: feedReport.feedReasonTypeId as number,
    useMethodTypeId: feedReport.useMethodTypeId as number,
    count: Number(feedReport.count),
    amount: Number(feedReport.amount),
  };
}

/*** FertilizerReport ***/
export function translateApiToFertilizerReport(apiFertilizerReport: Api.FertilizerReport): FertilizerReport {
  return {
    id: apiFertilizerReport.id as number,
    fertilizerId: apiFertilizerReport.fertilizerId,
    useMethodTypeId: apiFertilizerReport.useMethodTypeId,
    amount: "" + apiFertilizerReport.amount,
  };
}

export function translateFertilizerReportToApi(fertilizerReport: NewOrEditFertilizerReport): Api.FertilizerReport {
  return {
    id: isEntity(fertilizerReport) ? fertilizerReport.id : undefined,
    fertilizerId: fertilizerReport.fertilizerId as number,
    useMethodTypeId: fertilizerReport.useMethodTypeId as number,
    amount: Number(fertilizerReport.amount),
  };
}

/*** HerbicideReport ***/
export function translateApiToHerbicideReport(apiHerbicideReport: Api.HerbicideReport): HerbicideReport {
  return {
    id: apiHerbicideReport.id as number,
    herbicideId: apiHerbicideReport.herbicideId,
    useMethodTypeId: apiHerbicideReport.useMethodTypeId,
    amount: "" + apiHerbicideReport.amount,
  };
}

export function translateHerbicideReportToApi(herbicideReport: NewOrEditHerbicideReport): Api.HerbicideReport {
  return {
    id: isEntity(herbicideReport) ? herbicideReport.id : undefined,
    herbicideId: herbicideReport.herbicideId as number,
    useMethodTypeId: herbicideReport.useMethodTypeId as number,
    amount: Number(herbicideReport.amount),
  };
}
