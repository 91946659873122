import { Dispatch } from "redux";
import { LoginValidator } from "../../domain/login";
import { catchApplicationError, handleApplicationError, IApplicationService } from "../../handler/errorHandlers";
import { ApplicationError } from "../../handler/errors/applicationError";
import { AuthRepository } from "../../infrastracture/auth/repository";
import { WorkerRepository } from "../../infrastracture/worker/repository";
import { ApplicationState } from "../../store/modules";
import { apiCarpVarietyTypeActions } from "../../store/modules/api/carpVarietyType/ducks";
import { apiEnvironmentReportActions } from "../../store/modules/api/environmentReport/ducks";
import { apiFeedActions } from "../../store/modules/api/feed/ducks";
import { apiFeedReasonTypeActions } from "../../store/modules/api/feedReasonType/ducks";
import { apiFertilizerActions } from "../../store/modules/api/fertilizer/ducks";
import { apiHerbicideActions } from "../../store/modules/api/herbicide/ducks";
import { apiParentCarpActions } from "../../store/modules/api/parentCarp/ducks";
import { apiPondActions } from "../../store/modules/api/pond/ducks";
import { apiPondAreaActions } from "../../store/modules/api/pondArea/ducks";
import { apiPondOwnerTypeActions } from "../../store/modules/api/pondOwnerType/ducks";
import { apiPondReportActions } from "../../store/modules/api/pondReport/ducks";
import { apiPondTypeActions } from "../../store/modules/api/pondType/ducks";
import { apiScaleTypeActions } from "../../store/modules/api/scaleType/ducks";
import { apiUseMethodTypeActions } from "../../store/modules/api/useMethodType/ducks";
import { apiWorkerActions } from "../../store/modules/api/worker/ducks";
import { apiWorkReportActions } from "../../store/modules/api/workReport/ducks";
import { authStateActions } from "../../store/modules/auth/ducks";

interface IAuthService extends IApplicationService {
  changeAccountName: (accountName: string) => void;
  changePassword: (password: string) => void;
  login: () => void;
  logout: () => void;
  refresh: (failedCallback: () => void) => void;
}

export class AuthService implements IAuthService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public changeAccountName(accountName: string) {
    this.dispatch(authStateActions.changeAccountName({ accountName }));
  }

  @catchApplicationError()
  public changePassword(password: string) {
    this.dispatch(authStateActions.changePassword({ password }));
  }

  @catchApplicationError((dispatch) =>
    dispatch(authStateActions.loginFail({ errorMessage: "ログインに失敗しました。" }))
  )
  public async login() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      this.dispatch(authStateActions.loginStart());
      const { accountName, password } = state.auth;

      const validator = new LoginValidator();
      validator.validate({ accountName, password });
      if (!validator.isValid()) {
        throw new ApplicationError(validator.getMessages());
      }

      try {
        await new AuthRepository().login(accountName, password);
        const loggedInWorker = await new WorkerRepository().getWorkerMe();
        this.dispatch(authStateActions.loginSuccess({ user: loggedInWorker }));
        this.makeApiUnFetched();
      } catch (e) {
        handleApplicationError(this.dispatch, e);
      }
    });
  }

  @catchApplicationError()
  public async logout() {
    await new AuthRepository().logout();
    this.dispatch(authStateActions.logout());
  }

  @catchApplicationError()
  public async refresh(failedCallback: () => void) {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { isLoggedIn } = state.auth;
      if (isLoggedIn) {
        return;
      }
      try {
        const loggedInWorker = await new WorkerRepository().getWorkerMe();
        this.dispatch(authStateActions.loginSuccess({ user: loggedInWorker }));
      } catch {
        failedCallback();
      }
    });
  }

  private makeApiUnFetched() {
    this.dispatch(apiCarpVarietyTypeActions.shouldFetch());
    this.dispatch(apiEnvironmentReportActions.shouldFetch());
    this.dispatch(apiFeedActions.shouldFetch());
    this.dispatch(apiFeedReasonTypeActions.shouldFetch());
    this.dispatch(apiFertilizerActions.shouldFetch());
    this.dispatch(apiHerbicideActions.shouldFetch());
    this.dispatch(apiParentCarpActions.shouldFetch());
    this.dispatch(apiPondActions.shouldFetch());
    this.dispatch(apiPondAreaActions.shouldFetch());
    this.dispatch(apiPondOwnerTypeActions.shouldFetch());
    this.dispatch(apiPondReportActions.shouldFetch());
    this.dispatch(apiPondTypeActions.shouldFetch());
    this.dispatch(apiScaleTypeActions.shouldFetch());
    this.dispatch(apiUseMethodTypeActions.shouldFetch());
    this.dispatch(apiWorkerActions.shouldFetch());
    this.dispatch(apiWorkReportActions.shouldFetch());
  }
}
