import { sortPosition } from "../../../../domain/entity";
import { PondArea } from "../../../../domain/pondArea";
import { FetchLifeCycle } from "../../../lifeCycle/fetchLifeCycle";

export interface PondAreaStateType extends FetchLifeCycle {
  pondAreas: PondArea[];
}

export const getInitialState = (): PondAreaStateType => {
  return { pondAreas: [], isFetched: false, isFetching: false, isError: null };
};

export const FetchHandlers = {
  start(state: PondAreaStateType) {
    return { ...state, isFetched: false, isFetching: true, isError: null };
  },
  complete(state: PondAreaStateType, pondAreas: PondArea[]) {
    const sortedEntity = pondAreas.concat().sort(sortPosition);
    return { ...state, pondAreas: sortedEntity, isFetched: true, isFetching: false };
  },
  fail(state: PondAreaStateType, message: string = "") {
    return { ...state, isFetched: false, isFetching: false, isError: { message } };
  },
  shouldFetch(state: PondAreaStateType): PondAreaStateType {
    return { ...state, isFetched: false };
  },
};

export const SaveHandlers = {
  createPondArea(state: PondAreaStateType, pondArea: PondArea): PondAreaStateType {
    const pondAreas = state.pondAreas.concat(pondArea);
    const sortedEntity = pondAreas.concat().sort(sortPosition);
    return { ...state, pondAreas: sortedEntity };
  },
  updatePondArea(state: PondAreaStateType, pondArea: PondArea): PondAreaStateType {
    const pondAreas = state.pondAreas.map((w) => (w.id === pondArea.id ? pondArea : w));
    const sortedEntity = pondAreas.concat().sort(sortPosition);
    return { ...state, pondAreas: sortedEntity };
  },
  deletePondArea(state: PondAreaStateType, pondArea: PondArea): PondAreaStateType {
    const pondAreas = state.pondAreas.map((w) => (w.id === pondArea.id ? pondArea : w));
    const sortedEntity = pondAreas.concat().sort(sortPosition);
    return { ...state, pondAreas: sortedEntity };
  },
  replacePondAreas(state: PondAreaStateType, pondAreas: PondArea[]): PondAreaStateType {
    return { ...state, pondAreas };
  },
};
