import { EnvironmentReport, NewOrEditEnvironmentReport } from "../../../../../domain/environmentReport";
import { EditLifeCycle } from "../../../../lifeCycle/editLifeCycle";

export interface NewOrEditStateType extends EditLifeCycle {
  environmentReport: NewOrEditEnvironmentReport | null;
}

export const getInitialState = (): NewOrEditStateType => {
  return {
    environmentReport: null,
    isSaving: false,
    isUnSave: false,
    isSaved: false,
    isError: null,
  };
};

export const InitialHandler = {
  initialEnvironmentReport(
    state: NewOrEditStateType,
    environmentReport: NewOrEditEnvironmentReport
  ): NewOrEditStateType {
    return { ...state, environmentReport };
  },
  resetState(state: NewOrEditStateType): NewOrEditStateType {
    return { ...state, ...getInitialState() };
  },
};

export const ChangeHandler = {
  changeEnvironmentReport(
    state: NewOrEditStateType,
    key: keyof NewOrEditEnvironmentReport,
    value: any
  ): NewOrEditStateType {
    if (state.environmentReport === null) {
      return state;
    }
    return { ...state, environmentReport: { ...state.environmentReport, [key]: value }, isUnSave: true };
  },
};

export const SaveHandler = {
  saveStart(state: NewOrEditStateType): NewOrEditStateType {
    return { ...state, isSaving: true, isSaved: false, isUnSave: true, isError: null };
  },
  saveSuccess(state: NewOrEditStateType, environmentReport: EnvironmentReport): NewOrEditStateType {
    return {
      ...state,
      environmentReport,
      isSaving: false,
      isSaved: true,
      isUnSave: false,
      isError: null,
    };
  },
  deleteSuccess(state: NewOrEditStateType): NewOrEditStateType {
    return { ...state, environmentReport: null, isSaving: false, isSaved: true, isUnSave: false, isError: null };
  },
  saveFail(state: NewOrEditStateType): NewOrEditStateType {
    return { ...state, isSaving: false, isUnSave: true, isSaved: false, isError: null };
  },
};
