import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    margin: 0;
    padding: 0;
    height: 100%;
    color: #313B3E;
    font-family: Noto Sans JP, Verdana, Roboto, "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  }
  
  // react-datepickerへのstyle調整
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    width: 100%;
  }
  // iOSでfont-size: 16pxよりも下の場合に勝手にズームが走るので対応
  input[type=text],
  input[type=number],
  input[type=password],
  input[type=tel],
  input[type=datetime],
  input[type=date],
  input[type=month],
  input[type=week],
  input[type=time],
  textarea, select {
    font-size: 16px !important;
    padding: 9.5px 14px !important;
    line-height: 17px !important;
  }
`;
