import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { EditStateService } from "../../../application/master/pond/editState";
import { NewStateService } from "../../../application/master/pond/newState";
import { isActiveEntity } from "../../../domain/entity";
import { PondArea } from "../../../domain/pondArea";
import { PondOwnerType } from "../../../domain/pondOwnerType";
import { PondType } from "../../../domain/pondType";
import { Worker } from "../../../domain/worker";
import { ApplicationState } from "../../../store/modules";
import { NewOrEditStateType } from "../../../store/modules/master/pond/newOrEditState/reducer";
import { ConfirmModal } from "../../components/molecules/ConfirmModal";
import { PondForm } from "../../components/organisms/PondForm";

interface StateProps {
  pondAreas: PondArea[];
  pondTypes: PondType[];
  pondOwnerTypes: PondOwnerType[];
  workers: Worker[];
  newOrEditState: NewOrEditStateType;
}

interface DispatchProps {
  newStateService: NewStateService;
  editStateService: EditStateService;
}

type PondEditPageProps = StateProps & DispatchProps;

interface PondEditPageState {
  isOpenDeleteConfirm: boolean;
}

export class Wrapped extends React.Component<PondEditPageProps, PondEditPageState> {
  constructor(props: PondEditPageProps) {
    super(props);

    this.state = {
      isOpenDeleteConfirm: false,
    };
  }

  public render() {
    const { pond, isSaving } = this.props.newOrEditState;
    return (
      <>
        <PondForm
          pond={pond}
          pondAreas={this.props.pondAreas}
          pondTypes={this.props.pondTypes}
          pondOwnerTypes={this.props.pondOwnerTypes}
          workers={this.props.workers}
          isSaving={isSaving}
          onChangePondArea={this.handleChangePondArea}
          onChangeName={this.handleChangeName}
          onChangePosition={this.handleChangePosition}
          onChangePondType={this.handleChangePondType}
          onChangePondOwnerTypes={this.handleChangePondOwnerTypes}
          onChangeWorkers={this.handleChangeWorkers}
          onChangeIsUnused={this.handleChangeIsUnused}
          onClickSave={this.handleClickSavePond}
          onClickCancel={this.handleClickCancelPond}
          onClickAdd={this.handleClickAddPond}
          onClickDelete={this.openDeleteConfirmModal}
        />

        <ConfirmModal
          isOpen={this.state.isOpenDeleteConfirm}
          onClose={this.closeDeleteConfirmModal}
          onClickCancel={this.closeDeleteConfirmModal}
          onClickOk={this.handleClickDeletePond}
          cancelText={"キャンセル"}
          okText={"削除"}
          confirmMessage={"野池を削除してもよろしいでしょうか？"}
        />
      </>
    );
  }

  private handleChangePondArea = (pondAreaId: null | number) => {
    this.props.editStateService.changePondArea(pondAreaId);
  };

  private handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.editStateService.changeName(e.target.value);
  };

  private handleChangePosition = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      this.props.editStateService.changePosition(null);
      return;
    }
    const value = +e.target.value;
    if (!Number.isNaN(value)) {
      this.props.editStateService.changePosition(value);
    }
  };

  private handleChangePondType = (typeId: null | number) => {
    this.props.editStateService.changePondType(typeId);
  };

  private handleChangePondOwnerTypes = (ownerTypeIds: number[]) => {
    this.props.editStateService.changePondOwnerTypes(ownerTypeIds);
  };

  private handleChangeWorkers = (workerIds: number[]) => {
    this.props.editStateService.changeWorkers(workerIds);
  };

  private handleChangeIsUnused = (checked: boolean) => {
    this.props.editStateService.changeIsUnused(checked);
  };

  private handleClickSavePond = () => {
    this.props.editStateService.savePond();
  };

  private handleClickCancelPond = () => {
    this.props.editStateService.cancelPond();
  };

  private handleClickAddPond = () => {
    this.props.newStateService.createPond();
  };

  private handleClickDeletePond = () => {
    this.props.editStateService.deletePond();
    this.closeDeleteConfirmModal();
  };

  private closeDeleteConfirmModal = () => {
    this.setState({ isOpenDeleteConfirm: false });
  };
  private openDeleteConfirmModal = () => {
    this.setState({ isOpenDeleteConfirm: true });
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { newOrEditState } = state.master.pond;
  return {
    pondAreas: state.api.pondArea.pondAreas.filter(isActiveEntity),
    pondTypes: state.api.pondType.pondTypes,
    pondOwnerTypes: state.api.pondOwnerType.pondOwnerTypes,
    workers: state.api.worker.workers.filter(isActiveEntity),
    newOrEditState,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    newStateService: new NewStateService(dispatch),
    editStateService: new EditStateService(dispatch),
  };
};

export const PondEditPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);
