import { sortPosition } from "../../../../domain/entity";
import { Fertilizer } from "../../../../domain/fertilizer";
import { FetchLifeCycle } from "../../../lifeCycle/fetchLifeCycle";

export interface FertilizerStateType extends FetchLifeCycle {
  fertilizers: Fertilizer[];
}

export const getInitialState = (): FertilizerStateType => {
  return { fertilizers: [], isFetched: false, isFetching: false, isError: null };
};

export const FetchHandlers = {
  start(state: FertilizerStateType) {
    return { ...state, isFetched: false, isFetching: true, isError: null };
  },
  complete(state: FertilizerStateType, fertilizers: Fertilizer[]) {
    const sortedEntity = fertilizers.concat().sort(sortPosition);
    return { ...state, fertilizers: sortedEntity, isFetched: true, isFetching: false };
  },
  fail(state: FertilizerStateType, message: string = "") {
    return { ...state, isFetched: false, isFetching: false, isError: { message } };
  },
  shouldFetch(state: FertilizerStateType): FertilizerStateType {
    return { ...state, isFetched: false };
  },
};

export const SaveHandlers = {
  createFertilizer(state: FertilizerStateType, fertilizer: Fertilizer): FertilizerStateType {
    const fertilizers = state.fertilizers.concat(fertilizer);
    const sortedEntity = fertilizers.concat().sort(sortPosition);
    return { ...state, fertilizers: sortedEntity };
  },
  updateFertilizer(state: FertilizerStateType, fertilizer: Fertilizer): FertilizerStateType {
    const fertilizers = state.fertilizers.map((f) => (f.id === fertilizer.id ? fertilizer : f));
    const sortedEntity = fertilizers.concat().sort(sortPosition);
    return { ...state, fertilizers: sortedEntity };
  },
  deleteFertilizer(state: FertilizerStateType, fertilizer: Fertilizer): FertilizerStateType {
    const fertilizers = state.fertilizers.map((f) => (f.id === fertilizer.id ? fertilizer : f));
    const sortedEntity = fertilizers.concat().sort(sortPosition);
    return { ...state, fertilizers: sortedEntity };
  },
};
