import { Entity } from "../entity";

interface ImageAttribute extends Record<string, unknown> {
  fileName: string;
  originalFileName: string;
  contentType: string;
  activated: boolean;
  redirectURL?: string;
}

export interface NewImage extends ImageAttribute {}
export interface EditImage extends ImageAttribute, Entity {}

export type NewOrEditImage = NewImage | EditImage;
export type Image = EditImage;

export function getImageSrcURL(imageId: number): string {
  return `/api/images/${imageId}`;
}

enum AllowFileExtension {
  PNG = "png",
  JPEG = "jpeg",
  JPG = "jpg",
}

export function getFileExtension(fileName: string): string {
  const match = fileName.match(/\.[a-zA-Z]+$/);
  if (!match) {
    return "";
  }
  return match[0].replace(".", "");
}

export function isAllowFileExtension(fileName: string): boolean {
  const extension = getFileExtension(fileName);
  const reg = new RegExp(`(${AllowFileExtension.PNG}|${AllowFileExtension.JPEG}|${AllowFileExtension.JPG})`, "i");
  return reg.test(extension);
}

const CONTENT_TYPE_PREFIX = "image/";

export function getContentType(fileName: string): string {
  const extension = getFileExtension(fileName);
  if (new RegExp(`(${AllowFileExtension.PNG})`, "i").test(extension)) {
    return CONTENT_TYPE_PREFIX + "png";
  }
  if (new RegExp(`(${AllowFileExtension.JPEG}|${AllowFileExtension.JPG})`, "i")) {
    return CONTENT_TYPE_PREFIX + "jpeg";
  }
  return "binary/octet-stream";
}
