import * as _ from "lodash";
import { default as moment } from "moment";
import * as React from "react";
import { Modal, ModalContent, ModalHeader } from "semantic-ui-react";
import { getUniqueKey } from "../../../../domain/entity";
import { Pond } from "../../../../domain/pond";
import { getEmptyAmountMessage, PondReport } from "../../../../domain/pondReport";
import { ScaleType } from "../../../../domain/scaleType";
import {
  FrySelectionReportVM,
  getFryAmount,
  isCarpMovingReport,
  ToFrySelectionReportVM,
} from "../../../../domain/selectionReportVM";
import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "../../atoms/Table";

interface PairReportTableModalProps {
  isOpen: boolean;
  onClose: () => void;
  pondReport: PondReport | null;
  ponds: Pond[];
  pondReports: PondReport[];
  carpSizeTypes: ScaleType[];
  carpQualityTypes: ScaleType[];
  malformationTypes: ScaleType[];
  carpRatioTypes: ScaleType[];
}

export const PairReportTableModal = (props: PairReportTableModalProps) => {
  const { pondReport } = props;
  const pond = pondReport && props.ponds.find((p) => p.id === pondReport.pondId);
  return (
    <Modal open={props.isOpen} onClose={props.onClose}>
      <ModalHeader>{pond && pond.name}の記録</ModalHeader>
      <ModalContent>
        {pondReport && (
          <>
            <PondDisinfectionTable pondReport={pondReport} />
            <PondSelectionTable
              pondReport={pondReport}
              ponds={props.ponds}
              pondReports={props.pondReports}
              carpSizeTypes={props.carpSizeTypes}
              carpQualityTypes={props.carpQualityTypes}
              malformationTypes={props.malformationTypes}
              carpRatioTypes={props.carpRatioTypes}
            />
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

interface PondDisinfectionTableProps {
  pondReport: PondReport;
}
const PondDisinfectionTable = ({ pondReport }: PondDisinfectionTableProps) => {
  const { pondDisinfectionReports } = pondReport;

  if (pondDisinfectionReports.length === 0) {
    return null;
  }
  const disinfectionReport = pondDisinfectionReports[0];

  return (
    <Table definition={true}>
      <TableHeader>
        <TableRow>
          <TableHeaderCell />
          <TableHeaderCell>日付</TableHeaderCell>
          <TableHeaderCell>本数</TableHeaderCell>
          <TableHeaderCell>メモ</TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableCell style={{ width: "160px" }}>塩素</TableCell>
          <TableCell>{moment(disinfectionReport.date || "invalid Date").format("YYYY/MM/DD")}</TableCell>
          <TableCell>{disinfectionReport.amount}本</TableCell>
          <TableCell>{disinfectionReport.note}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

interface PondSelectionTableProps {
  pondReport: PondReport;
  ponds: Pond[];
  pondReports: PondReport[];
  carpSizeTypes: ScaleType[];
  carpQualityTypes: ScaleType[];
  malformationTypes: ScaleType[];
  carpRatioTypes: ScaleType[];
}

const PondSelectionTable = ({
  pondReport,
  ponds,
  pondReports,
  carpSizeTypes,
  carpQualityTypes,
  malformationTypes,
  carpRatioTypes,
}: PondSelectionTableProps) => {
  const selectionReports = ToFrySelectionReportVM(
    [...pondReport.carpSelectionReports, ...pondReport.carpMovingReports],
    ponds,
    pondReports
  );
  if (selectionReports.length === 0) {
    return null;
  }

  const sizeDictionary = carpSizeTypes.reduce((dict, s) => ({ ...dict, [s.id]: s }), {});
  const qualityDictionary = carpQualityTypes.reduce((dict, q) => ({ ...dict, [q.id]: q }), {});
  const malformationDictionary = malformationTypes.reduce((dict, m) => ({ ...dict, [m.id]: m }), {});
  const ratioDictionary = carpRatioTypes.reduce((dict, r) => ({ ...dict, [r.id]: r }), {});

  const reportsEachSelectionNumber = _.groupBy(selectionReports, (r) => r.selectionNumber);

  return (
    <Table definition={true}>
      <TableHeader>
        <TableHeaderCell />
        <TableHeaderCell>日付</TableHeaderCell>
        <TableHeaderCell>匹数</TableHeaderCell>
        <TableHeaderCell>サイズ</TableHeaderCell>
        <TableHeaderCell>評価</TableHeaderCell>
        <TableHeaderCell>点数</TableHeaderCell>
        <TableHeaderCell>奇形</TableHeaderCell>
        <TableHeaderCell>数</TableHeaderCell>
        <TableHeaderCell>メモ</TableHeaderCell>
      </TableHeader>
      <TableBody>
        {Object.keys(reportsEachSelectionNumber)
          .sort()
          .map((selectionNumber) => (
            <SelectionVMTableRow
              key={selectionNumber}
              selectionReports={reportsEachSelectionNumber[selectionNumber]}
              sizeDictionary={sizeDictionary}
              qualityDictionary={qualityDictionary}
              malformationDictionary={malformationDictionary}
              ratioDictionary={ratioDictionary}
            />
          ))}
      </TableBody>
    </Table>
  );
};

interface SelectionVMTableRowProps {
  selectionReports: FrySelectionReportVM[];
  sizeDictionary: { [key: string]: ScaleType };
  qualityDictionary: { [key: string]: ScaleType };
  malformationDictionary: { [key: string]: ScaleType };
  ratioDictionary: { [key: string]: ScaleType };
}

const SelectionVMTableRow = ({
  selectionReports,
  sizeDictionary,
  qualityDictionary,
  malformationDictionary,
  ratioDictionary,
}: SelectionVMTableRowProps) => {
  const amount = getFryAmount(selectionReports);
  const rows = selectionReports.map((r) => {
    if (isCarpMovingReport(r)) {
      return <MovingTableRow key={getUniqueKey(r)} movingReport={r} />;
    }
    return (
      <SelectionTableRow
        key={getUniqueKey(r)}
        selectionReport={r}
        sizeDictionary={sizeDictionary}
        qualityDictionary={qualityDictionary}
        malformationDictionary={malformationDictionary}
        ratioDictionary={ratioDictionary}
      />
    );
  });
  return (
    <>
      {rows}
      {rows.length > 1 && <SummaryTableRow amount={amount} />}
    </>
  );
};

interface SelectionTableRowProps {
  selectionReport: FrySelectionReportVM;
  sizeDictionary: { [key: string]: ScaleType };
  qualityDictionary: { [key: string]: ScaleType };
  malformationDictionary: { [key: string]: ScaleType };
  ratioDictionary: { [key: string]: ScaleType };
}
const SelectionTableRow = ({
  selectionReport,
  sizeDictionary,
  qualityDictionary,
  malformationDictionary,
  ratioDictionary,
}: SelectionTableRowProps) => {
  const size = sizeDictionary[selectionReport.carpSizeTypeId || ""];
  const quality = qualityDictionary[selectionReport.carpQualityTypeId || ""];
  const malformation = malformationDictionary[selectionReport.malformationTypeId || ""];
  const ratio = ratioDictionary[selectionReport.carpRatioTypeId || ""];
  return (
    <TableRow>
      <TableCell style={{ width: "160px" }}>{selectionReport.name}</TableCell>
      <TableCell>{moment(selectionReport.date || "invalid Date").format("YYYY/MM/DD")}</TableCell>
      <TableCell>{selectionReport.amount}匹</TableCell>
      <TableCell>{size ? size.name : ""}</TableCell>
      <TableCell>{quality ? quality.name : ""}</TableCell>
      <TableCell>{selectionReport.carpScore}</TableCell>
      <TableCell>{malformation ? malformation.name : ""}</TableCell>
      <TableCell>{ratio ? ratio.name : ""}</TableCell>
      <TableCell>{selectionReport.note}</TableCell>
    </TableRow>
  );
};

interface MovingTableRowProps {
  movingReport: FrySelectionReportVM;
}
const MovingTableRow = ({ movingReport }: MovingTableRowProps) => {
  return (
    <TableRow>
      <TableCell style={{ width: "160px" }}>{movingReport.name}</TableCell>
      <TableCell>{moment(movingReport.date || "invalid Date").format("YYYY/MM/DD")}</TableCell>
      <TableCell>{movingReport.amount}匹</TableCell>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell>{movingReport.note}</TableCell>
    </TableRow>
  );
};

interface SummaryTableRowProps {
  amount: number;
}
const SummaryTableRow = ({ amount }: SummaryTableRowProps) => {
  return (
    <TableRow>
      <TableCell style={{ width: "160px" }}>合計匹数</TableCell>
      <TableCell />
      <TableCell>{amount === 0 ? getEmptyAmountMessage() : `${amount} 匹`}</TableCell>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
    </TableRow>
  );
};
