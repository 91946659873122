import { Entity } from "../entity";

interface ScaleTypeAttribute extends Record<string, unknown> {
  name: string;
  groupName: string;
  position: number;
  isDeleted: boolean;
}

export interface NewScaleType extends ScaleTypeAttribute {}
export interface EditScaleType extends ScaleTypeAttribute, Entity {}

export type NewOrEditScale = NewScaleType | EditScaleType;
export type ScaleType = EditScaleType;

export enum ScaleGroupName {
  CarpSize = "carpSize",
  CarpQuality = "carpQuality",
  Malformation = "malformation",
  CarpRatio = "carpRatio",
  Empty = "empty",
}

export function isCarpSizeScaleType(scaleType: ScaleType): boolean {
  return scaleType.groupName === ScaleGroupName.CarpSize;
}
export function isCarpQualityScaleType(scaleType: ScaleType): boolean {
  return scaleType.groupName === ScaleGroupName.CarpQuality;
}
export function isMalformationScaleType(scaleType: ScaleType): boolean {
  return scaleType.groupName === ScaleGroupName.Malformation;
}
export function isCarpRatioScaleType(scaleType: ScaleType): boolean {
  return scaleType.groupName === ScaleGroupName.CarpRatio;
}
export function isEmptyScaleType(scaleType: ScaleType): boolean {
  return scaleType.groupName === ScaleGroupName.Empty;
}
