import { default as moment } from "moment";
import { isEntity } from "../../domain/entity";
import { EnvironmentReport, NewOrEditEnvironmentReport } from "../../domain/environmentReport";
import * as Api from "../../libs/apiClient";

/*** EnvironmentReport ***/
export function translateApiToEnvironmentReport(apiEnvironmentReport: Api.EnvironmentReport): EnvironmentReport {
  return {
    id: apiEnvironmentReport.id as number,

    pondId: apiEnvironmentReport.pondId,
    date: moment(apiEnvironmentReport.date, "YYYY-MM-DD").toDate(),
    value: "" + apiEnvironmentReport.value,
  };
}

export function translateEnvironmentReportToApi(environmentReport: NewOrEditEnvironmentReport): Api.EnvironmentReport {
  return {
    id: isEntity(environmentReport) ? environmentReport.id : undefined,

    pondId: environmentReport.pondId,
    date: moment(environmentReport.date).format("YYYY-MM-DD"),
    value: Number(environmentReport.value),
  };
}
