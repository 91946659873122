import { NewOrEditPondArea } from "../../../../../domain/pondArea";
import { EditLifeCycle } from "../../../../lifeCycle/editLifeCycle";

export interface PondAreaStateType extends EditLifeCycle {
  pondArea: NewOrEditPondArea | null;
}

export const getInitialState = (): PondAreaStateType => {
  return { pondArea: null, isUnSave: false, isSaved: false, isSaving: false, isError: null };
};

export const InitialHandler = {
  selectPondArea(state: PondAreaStateType, pondArea: NewOrEditPondArea | null) {
    return { ...state, pondArea, isUnSave: false, isSaved: false, isError: null };
  },
};

export const SaveHandler = {
  saveStart(state: PondAreaStateType): PondAreaStateType {
    return { ...state, isSaving: true, isError: null };
  },
  saveSuccess(state: PondAreaStateType, pondArea: NewOrEditPondArea): PondAreaStateType {
    return { ...state, pondArea, isUnSave: false, isSaved: true, isSaving: false };
  },
  saveFail(state: PondAreaStateType, message: string = ""): PondAreaStateType {
    return { ...state, isUnSave: true, isSaved: false, isSaving: false, isError: { message } };
  },
};
