import { ScaleType } from "../../domain/scaleType";
import * as api from "../../libs/client";
import { translateApiToScaleType } from "./translate";

interface IScaleTypeRepository {
  getScaleTypes: () => Promise<ScaleType[]>;
}

export class ScaleTypeRepository implements IScaleTypeRepository {
  public async getScaleTypes(): Promise<ScaleType[]> {
    const scaleTypesApi = new api.ScaleTypesApi(api.serverConfig);
    const response = await scaleTypesApi.getScaleTypes();
    return response.data.map((d) => translateApiToScaleType(d));
  }
}
