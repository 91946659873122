export enum ReportGroup {
  All = 0,
  Feed,
  Fertilizer,
  Herbicide,
  // 以降、フロントエンドでの使用のため追加された項目
  Note,
  EnvironmentReport,
  PondReport,
}

export function isFeedReportGroup(entity: { reportGroup: null | number }): boolean {
  return entity.reportGroup === ReportGroup.Feed;
}

export function isFertilizerReportGroup(entity: { reportGroup: null | number }): boolean {
  return entity.reportGroup === ReportGroup.Fertilizer;
}

export function isHerbicideReportGroup(entity: { reportGroup: null | number }): boolean {
  return entity.reportGroup === ReportGroup.Herbicide;
}
