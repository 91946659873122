import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { NavigationStateService } from "../../../application/report/navigation";
import { NewOrEditStateService } from "../../../application/report/pondReport/newOrEditState";
import { ReadApiService } from "../../../application/report/pondReport/readApi";
import { isAdultPond, isFryPond, Pond } from "../../../domain/pond";
import { isAdultPondReports, isFryPondReports } from "../../../domain/pondReport";
import { PondType } from "../../../domain/pondType";
import { ApplicationState } from "../../../store/modules";
import { NavigationStateType } from "../../../store/modules/report/navigation/reducer";
import { NewOrEditStateType } from "../../../store/modules/report/pondReport/newOrEditState/reducer";
import { PageHeader } from "../../components/molecules/PageHeader";
import { MainLayout } from "../Layout";
import { ReportLayout } from "../Layout/Report";
import { AdultPondPage } from "./AdultPondPage";
import { DefaultPage } from "./DefaultPage";
import { FryPondPage } from "./FryPondPage";
import { PondsList } from "./List";
import { MoveModal } from "./MoveModal";
import { PairReportModal } from "./PairReportModal";

interface StateProps {
  ponds: Pond[];
  pondTypes: PondType[];
  newOrEditState: NewOrEditStateType;
  navigationState: NavigationStateType;
}

interface DispatchProps {
  readApiService: ReadApiService;
  newOrEditStateService: NewOrEditStateService;
  navigationStateService: NavigationStateService;
}

type PondReportsPageProps = StateProps & DispatchProps;

export class Wrapped extends React.Component<PondReportsPageProps> {
  public componentDidMount(): void {
    this.initStateOnMounted();
  }

  public render() {
    return (
      <MainLayout>
        <ReportLayout
          header={<PageHeader title={"選別・放鯉・池揚げ"} />}
          list={<PondsList />}
          form={this.renderForm()}
          listColumn={2}
          isOpenList={this.props.navigationState.isOpenList}
          onClickOpen={this.handleClickOpen}
          onClickClose={this.handleClickClose}
        />
        <MoveModal />
        <PairReportModal />
      </MainLayout>
    );
  }

  public renderForm() {
    const { pondReports } = this.props.newOrEditState;
    if (pondReports === null) {
      return <DefaultPage />;
    }
    if (pondReports.length > 0) {
      if (isFryPondReports(pondReports, this.props.pondTypes)) {
        return <FryPondPage />;
      }
      if (isAdultPondReports(pondReports, this.props.pondTypes)) {
        return <AdultPondPage />;
      }
    }
    // 記録がない場合は、現在の野池の設定で画面を表示する?
    const { ponds, pondTypes } = this.props;
    const { selectedPondId } = this.props.navigationState;
    const pond = ponds.find((p) => p.id === selectedPondId);
    if (!!pond && isFryPond(pond, pondTypes)) {
      return <FryPondPage />;
    }
    if (!!pond && isAdultPond(pond, pondTypes)) {
      return <AdultPondPage />;
    }
    return null;
  }

  private async initStateOnMounted() {
    await this.props.readApiService.init();
    if (this.props.navigationState.selectedPondId !== null) {
      await this.props.readApiService.getPondReports();
      this.props.newOrEditStateService.selectPondReports();
    }
  }

  private handleClickOpen = () => {
    this.props.navigationStateService.openList();
  };

  private handleClickClose = () => {
    this.props.navigationStateService.closeList();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { pond, pondType } = state.api;
  const { newOrEditState } = state.report.pondReport;
  return {
    ponds: pond.ponds,
    pondTypes: pondType.pondTypes,
    newOrEditState,
    navigationState: state.report.navigation,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    readApiService: new ReadApiService(dispatch),
    newOrEditStateService: new NewOrEditStateService(dispatch),
    navigationStateService: new NavigationStateService(dispatch),
  };
};

export const PondReportsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);
