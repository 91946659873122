import { actionCreatorFactory } from "typescript-fsa";
// Actions
const actionCreator = actionCreatorFactory("MobileReportListState");

const initialState = actionCreator("initial");

const openDetailActionModal = actionCreator("openDetailActionModal");
const closeDetailActionModal = actionCreator("closeDetailActionModal");

const changeDisplayDate = actionCreator<ChangeDisplayDatePayload>("changeDisplayDate");
interface ChangeDisplayDatePayload {
  displayDate: Date;
}
const changeSelectedDate = actionCreator<ChangeSelectedDatePayload>("changeSelectedDate");
interface ChangeSelectedDatePayload {
  selectedDate: Date;
}
const changeDetailTab = actionCreator<ChangeDetailTabStatePayload>("changeDetailTab");
interface ChangeDetailTabStatePayload {
  detailTab: "detail" | "graph";
}
const changeGraphPeriodType = actionCreator<ChangeGraphPeriodTypePayload>("changeGraphPeriodType");
interface ChangeGraphPeriodTypePayload {
  periodType: GraphPeriodType;
}

const changeSortDate = actionCreator<ChangeSortDatePayload>("changeSortDate");
interface ChangeSortDatePayload {
  isAscDate: boolean;
}

const addSelectedReportType = actionCreator<AddSelectedReportTypePayload>("addSelectedReportType");
interface AddSelectedReportTypePayload {
  selectedReportTypeId: number;
}

const removeSelectedReportType = actionCreator<RemoveSelectedReportTypePayload>("removeSelectedReportType");
interface RemoveSelectedReportTypePayload {
  selectedReportTypeId: number;
}

export const mobileReportListStateActions = {
  initialState,
  openDetailActionModal,
  closeDetailActionModal,
  changeDisplayDate,
  changeSelectedDate,
  changeDetailTab,
  changeGraphPeriodType,
  changeSortDate,
  addSelectedReportType,
  removeSelectedReportType,
};

// Reducers
import { reducerWithInitialState } from "typescript-fsa-reducers";
import {
  FilterHandlers,
  getInitialState,
  GraphPeriodType,
  InitialHandler,
  ModalHandler,
  ReportListStateType,
  SelectHandlers,
  SortHandlers,
} from "./reducer";

export const reportListStateReducer = reducerWithInitialState<ReportListStateType>(getInitialState())
  .case(initialState, (state) => InitialHandler.initialState(state))
  .case(changeDisplayDate, (state, { displayDate }) => SelectHandlers.changeDisplayDate(state, displayDate))
  .case(changeSelectedDate, (state, { selectedDate }) => SelectHandlers.changeSelectedDate(state, selectedDate))
  .case(changeDetailTab, (state, { detailTab }) => SelectHandlers.changeDetailTab(state, detailTab))
  .case(changeGraphPeriodType, (state, { periodType }) => SelectHandlers.changeGraphPeriodType(state, periodType))
  .case(changeSortDate, (state, { isAscDate }) => SortHandlers.changeSortDate(state, isAscDate))
  .case(addSelectedReportType, (state, { selectedReportTypeId }) =>
    FilterHandlers.addSelectedReportType(state, selectedReportTypeId)
  )
  .case(removeSelectedReportType, (state, { selectedReportTypeId }) =>
    FilterHandlers.removeSelectedReportType(state, selectedReportTypeId)
  )
  .case(openDetailActionModal, (state) => ModalHandler.openDetailActionModal(state))
  .case(closeDetailActionModal, (state) => ModalHandler.closeDetailActionModal(state));
