import { ScaleType } from "../../domain/scaleType";
import * as Api from "../../libs/apiClient";

export function translateApiToScaleType(apiScaleType: Api.ScaleType): ScaleType {
  return {
    id: apiScaleType.id,
    name: apiScaleType.name,
    groupName: apiScaleType.groupName,
    position: apiScaleType.position,
    isDeleted: apiScaleType.isDeleted,
  };
}

export function translateCarpSizeToApi(scaleType: ScaleType): Api.ScaleType {
  return {
    id: scaleType.id,
    name: scaleType.name,
    groupName: scaleType.groupName,
    position: scaleType.position,
    isDeleted: scaleType.isDeleted,
  };
}
