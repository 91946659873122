import { default as moment } from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NavigationService } from "../../../../application/mobile/navigation";
import { CarpSelectionReportStateService } from "../../../../application/mobile/pondReport/carpSelectionReportState";
import { MoveModalStateService } from "../../../../application/mobile/pondReport/moveModalState";
import { NewOrEditStateService } from "../../../../application/mobile/pondReport/newOrEditState";
import { NewOrEditCarpSelectionReport } from "../../../../domain/carpSelectionReport";
import { isEntity } from "../../../../domain/entity";
import { Pond } from "../../../../domain/pond";
import { PondArea } from "../../../../domain/pondArea";
import { NewOrEditPondReport, PondReport } from "../../../../domain/pondReport";
import {
  isCarpQualityScaleType,
  isCarpRatioScaleType,
  isCarpSizeScaleType,
  isMalformationScaleType,
  ScaleType,
} from "../../../../domain/scaleType";
import { ApplicationState } from "../../../../store/modules";
import { NavigationStateType } from "../../../../store/modules/mobile/navigation/reducer";
import { ListStateType } from "../../../../store/modules/mobile/pondReport/listState/reducer";
import { NewOrEditStateType } from "../../../../store/modules/mobile/pondReport/newOrEditState/reducer";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { PondReportFormSelection } from "../../../components/molecules/PondReportFormSelection";
import { MobileLayout } from "../../Layout/Mobile";

interface StateProps {
  pondAreas: PondArea[];
  ponds: Pond[];
  pondReports: PondReport[];
  carpSizeTypes: ScaleType[];
  carpQualityTypes: ScaleType[];
  malformationTypes: ScaleType[];
  carpRatioTypes: ScaleType[];
  newOrEditState: NewOrEditStateType;
  listState: ListStateType;
  navigationState: NavigationStateType;
}

interface OwnProps {
  pond: Pond;
  pondReport: NewOrEditPondReport;
  selectionReport: NewOrEditCarpSelectionReport;
  onClickSave: () => void;
}

interface DispatchProps {
  newOrEditStateService: NewOrEditStateService;
  navigationService: NavigationService;
  carpSelectionReportStateService: CarpSelectionReportStateService;
  moveModalStateService: MoveModalStateService;
}

type PondReportCarpSelectionPageProps = StateProps & DispatchProps & RouteComponentProps & OwnProps;

export class Wrapped extends React.Component<PondReportCarpSelectionPageProps> {
  public componentWillMount(): void {
    if (this.props.navigationState.selectedPondId === null) {
      this.props.history.push("/");
      return;
    }
  }

  public render() {
    const { pond, pondReport, selectionReport } = this.props;
    const movingReports = pondReport.carpMovingReports.filter(
      (cmr) => cmr.selectionNumber === selectionReport.selectionNumber
    );
    const disabledMoving =
      pondReport.carpSelectionReports.some(
        (csr) => csr.selectionNumber > selectionReport.selectionNumber && isEntity(csr)
      ) || movingReports.some(isEntity);

    return (
      <MobileLayout>
        <BaseNavigationHeader title={pond.name} onClickReturn={this.handleClickReturn} />
        <BaseNavigationContent>
          <PondReportFormSelection
            ponds={this.props.ponds}
            pondAreas={this.props.pondAreas}
            pondReports={this.props.pondReports}
            carpSizeTypes={this.props.carpSizeTypes}
            carpQualityTypes={this.props.carpQualityTypes}
            malformationTypes={this.props.malformationTypes}
            carpRatioTypes={this.props.carpRatioTypes}
            selectionNumber={selectionReport.selectionNumber}
            amount={selectionReport.amount}
            date={selectionReport.date}
            carpSizeTypeId={selectionReport.carpSizeTypeId}
            carpQualityTypeId={selectionReport.carpQualityTypeId}
            malformationTypeId={selectionReport.malformationTypeId}
            carpRatioTypeId={selectionReport.carpRatioTypeId}
            carpScore={selectionReport.carpScore}
            note={selectionReport.note}
            isCompleteSelection={pondReport.isCompleted}
            disabledMoving={disabledMoving}
            movingReports={movingReports}
            onChangeAmount={this.handleChangeAmount}
            onChangeDate={this.handleChangeDate}
            onClickToday={this.handleClickToday}
            onClickYesterday={this.handleClickYesterday}
            onClickYesterdayMinusOne={this.handleClickYesterdayMinusOne}
            onChangeCarpSize={this.handleChangeCarpSize}
            onChangeCarpQuality={this.handleChangeCarpQuality}
            onChangeMalformation={this.handleChangeMalformation}
            onChangeCarpRatio={this.handleChangeCarpRatio}
            onChangeCarpScore={this.handleChangeCarpScore}
            onChangeNote={this.handleChangeNote}
            onChangeIsCompleteSelection={this.handleChangeIsCompleted}
            onClickCarpMoving={this.handleClickCarpMoving}
            isSaving={this.props.newOrEditState.isSaving}
            onClickSave={this.props.onClickSave}
          />
        </BaseNavigationContent>
      </MobileLayout>
    );
  }

  private handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const num = Number(e.target.value);
    if (!Number.isNaN(num)) {
      this.props.carpSelectionReportStateService.changeAmount(e.target.value);
    }
  };

  private handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = moment(e.target.value, "YYYY-MM-DD");
    if (date.isValid()) {
      this.props.carpSelectionReportStateService.changeDate(date.toDate());
    }
  };

  private handleClickToday = () => {
    this.props.carpSelectionReportStateService.changeDateToToday();
  };
  private handleClickYesterday = () => {
    this.props.carpSelectionReportStateService.changeDateToYesterday();
  };
  private handleClickYesterdayMinusOne = () => {
    this.props.carpSelectionReportStateService.changeDateToYesterdayMinusOne();
  };

  private handleChangeCarpSize = (sizeId: null | number) => {
    this.props.carpSelectionReportStateService.changeCarpSize(sizeId);
  };
  private handleChangeCarpQuality = (qualityId: null | number) => {
    this.props.carpSelectionReportStateService.changeCarpQuality(qualityId);
  };
  private handleChangeMalformation = (malformationId: null | number) => {
    this.props.carpSelectionReportStateService.changeMalformation(malformationId);
  };
  private handleChangeCarpRatio = (ratioId: null | number) => {
    this.props.carpSelectionReportStateService.changeCarpRatio(ratioId);
  };

  private handleChangeCarpScore = (e: React.ChangeEvent<HTMLInputElement>) => {
    const num = Number(e.target.value);
    if (!Number.isNaN(num)) {
      this.props.carpSelectionReportStateService.changeCarpScore(e.target.value);
    }
  };

  private handleChangeNote = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.carpSelectionReportStateService.changeNote(e.target.value);
  };

  private handleChangeIsCompleted = (checked: boolean) => {
    this.props.carpSelectionReportStateService.changeIsCompleted(checked);
  };

  private handleClickCarpMoving = () => {
    this.props.moveModalStateService.openModal();
  };

  private handleClickReturn = () => {
    this.props.history.goBack();
  };
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StateProps & OwnProps => {
  const { pond, pondArea, pondReport, scaleType } = state.api;
  const { scaleTypes } = scaleType;
  return {
    pondAreas: pondArea.pondAreas,
    ponds: pond.ponds,
    pondReports: pondReport.pondReports,
    carpSizeTypes: scaleTypes.filter(isCarpSizeScaleType),
    carpQualityTypes: scaleTypes.filter(isCarpQualityScaleType),
    malformationTypes: scaleTypes.filter(isMalformationScaleType),
    carpRatioTypes: scaleTypes.filter(isCarpRatioScaleType),
    navigationState: state.mobile.navigation,
    listState: state.mobile.pondReportState.listState,
    newOrEditState: state.mobile.pondReportState.newOrEditStateType,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    newOrEditStateService: new NewOrEditStateService(dispatch),
    navigationService: new NavigationService(dispatch),
    carpSelectionReportStateService: new CarpSelectionReportStateService(dispatch),
    moveModalStateService: new MoveModalStateService(dispatch),
  };
};

export const PondReportCarpSelectionNewOrEditPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
