import * as React from "react";
import styled from "styled-components";

export const Text = styled.p`
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
  // 3点リーダー
  &.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const TextArea = ({ text }: { text: string }) => {
  const messageArray = text.split(/(\n|\r|\r\n)/);
  return (
    <React.Fragment>
      {messageArray.map((m, index) => (
        <React.Fragment key={index}>
          {m}
          {index !== messageArray.length - 1 && <br />}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};
