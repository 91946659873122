import { actionCreatorFactory } from "typescript-fsa";
import { EditFeed, Feed, NewFeed, NewOrEditFeed } from "../../../../../domain/feed";
// Actions
const actionCreator = actionCreatorFactory("MasterFeedNewOrEditState");

const createFeed = actionCreator<CreateFeedPayload>("createFeed");
interface CreateFeedPayload {
  feed: NewFeed;
}

const selectFeed = actionCreator<SelectFeedPayload>("selectFeed");
interface SelectFeedPayload {
  feed: EditFeed;
}

const changeFeed = actionCreator<ChangeFeedPayload>("changeFeed");
interface ChangeFeedPayload {
  key: keyof NewOrEditFeed;
  value: any;
}

export const cancelFeed = actionCreator<CancelFeedPayload>("cancelFeed");
interface CancelFeedPayload {
  feed: NewOrEditFeed;
}

export const saveStart = actionCreator("saveStart");
export const saveSuccess = actionCreator<SaveSuccessPayload>("saveSuccess");
interface SaveSuccessPayload {
  feed: Feed | null;
}
export const saveFail = actionCreator("saveFail");

export const masterFeedNewOrEditStateActions = {
  createFeed,
  selectFeed,
  changeFeed,
  cancelFeed,
  saveStart,
  saveSuccess,
  saveFail,
};

// Reducers
import { ChangeHandler, getInitialState, InitialHandler, NewOrEditStateType, SaveHandler } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const NewOrEditStateReducer = reducerWithInitialState<NewOrEditStateType>(getInitialState())
  .case(createFeed, (state, { feed }) => {
    return InitialHandler.createFeed(state, feed);
  })
  .case(selectFeed, (state, { feed }) => {
    return InitialHandler.selectFeed(state, feed);
  })
  .case(changeFeed, (state, { key, value }) => {
    return ChangeHandler.changeFeed(state, key, value);
  })
  .case(cancelFeed, (state, { feed }) => {
    return SaveHandler.cancelFeed(state, feed);
  })
  .case(saveStart, (state) => {
    return SaveHandler.saveStart(state);
  })
  .case(saveSuccess, (state, { feed }) => {
    return SaveHandler.saveSuccess(state, feed);
  })
  .case(saveFail, (state) => {
    return SaveHandler.saveFail(state);
  });
