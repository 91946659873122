import { isEntity } from "../../domain/entity";
import { NewOrEditWorker, Worker } from "../../domain/worker";
import * as Api from "../../libs/apiClient";

export function translateApiToWorker(apiWorker: Api.Worker): Worker {
  return {
    id: apiWorker.id as number,
    name: apiWorker.name,
    accountName: apiWorker.accountName,
    password: apiWorker.password,
    isDeleted: apiWorker.isDeleted,
    position: apiWorker.position,
  };
}

export function translateWorkerToApi(worker: NewOrEditWorker): Api.Worker {
  return {
    id: isEntity(worker) ? worker.id : undefined,
    name: worker.name,
    accountName: worker.accountName,
    password: worker.password,
    isDeleted: worker.isDeleted,
    position: worker.position,
  };
}
