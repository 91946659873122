import { Menu as _Menu, MenuItem as _MenuItem } from "semantic-ui-react";
import styled from "styled-components";
import { COLOR } from "../../../styles/Color";

export const Menu = styled(_Menu).attrs({ vertical: true, borderless: true })`
  background-color: ${COLOR.Primary.base} !important;
  font-size: 16px !important;
  height: 100%;
  width: 100% !important;
`;

export const BottomItem = styled.div`
  position: absolute !important;
  bottom: 0 !important;
`;

export const MenuItem = styled(_MenuItem)`
  color: ${COLOR.Base.white} !important;
`;

export const ClickHandler = styled.div`
  width: 100%;
`;
