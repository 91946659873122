import { Dispatch } from "redux";
import { Pond } from "../../../domain/pond";
import { PondArea } from "../../../domain/pondArea";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { PondAreaRepository } from "../../../infrastracture/pondArea/repository";
import { ApplicationState } from "../../../store/modules";
import { apiPondAreaActions } from "../../../store/modules/api/pondArea/ducks";

interface IPondAreaApiService extends IApplicationService {
  init: () => void;
  fetchUnFetched: () => void;
  updateByPond: (pond: Pond) => void;
}

export class PondAreaApiService implements IPondAreaApiService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError((dispatch) => dispatch(apiPondAreaActions.fetchFail()))
  public async init() {
    this.dispatch(apiPondAreaActions.fetchStart());
    const pondAreas: PondArea[] = await new PondAreaRepository().getPondAreas();
    this.dispatch(apiPondAreaActions.fetchComplete({ pondAreas }));
  }

  @catchApplicationError((dispatch) => dispatch(apiPondAreaActions.fetchFail()))
  public async fetchUnFetched() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      if (getState().api.pondArea.isFetched) {
        return;
      }
      this.dispatch(apiPondAreaActions.fetchStart());
      const pondAreas: PondArea[] = await new PondAreaRepository().getPondAreas();
      this.dispatch(apiPondAreaActions.fetchComplete({ pondAreas }));
    });
  }

  @catchApplicationError()
  public updateByPond(pond: Pond) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const replacedPondAreas = state.api.pondArea.pondAreas.map((area) => {
        if (area.pondIds.includes(pond.id)) {
          if (pond.pondAreaId === area.id) {
            return area;
          }
          return { ...area, pondIds: area.pondIds.filter((id) => id !== pond.id) };
        }
        if (pond.pondAreaId === area.id) {
          return { ...area, pondIds: area.pondIds.concat(pond.id).sort() };
        }
        return area;
      });
      this.dispatch(apiPondAreaActions.replacePondAreas({ pondAreas: replacedPondAreas }));
    });
  }
}
