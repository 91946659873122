import { NewCarpPairing, NewOrEditCarpPairing } from "../../../../../domain/carpPairing";
import { getUniqueKey, isEntity, isNewEntity, UniqueKey } from "../../../../../domain/entity";
import { EditParentCarp, NewOrEditParentCarp, NewParentCarp, ParentCarp } from "../../../../../domain/parentCarp";
import { EditLifeCycle } from "../../../../lifeCycle/editLifeCycle";

export interface NewOrEditStateType extends EditLifeCycle {
  parentCarp: NewOrEditParentCarp | null;
}

export const getInitialState = (): NewOrEditStateType => {
  return { parentCarp: null, isUnSave: false, isSaved: false, isSaving: false, isError: null };
};

export const InitialHandler = {
  createParentCarp(state: NewOrEditStateType, parentCarp: NewParentCarp): NewOrEditStateType {
    return { ...state, parentCarp, isUnSave: false, isSaved: false, isError: null };
  },
  selectParentCarp(state: NewOrEditStateType, parentCarp: EditParentCarp): NewOrEditStateType {
    return { ...state, parentCarp, isUnSave: false, isSaved: false, isError: null };
  },
};

export const ChangeHandler = {
  changeParentCarp(state: NewOrEditStateType, key: keyof NewOrEditParentCarp, value: any): NewOrEditStateType {
    if (state.parentCarp === null) {
      return state;
    }
    return { ...state, parentCarp: { ...state.parentCarp, [key]: value }, isUnSave: true };
  },
  changeCarpPairing(
    state: NewOrEditStateType,
    uniqueKey: UniqueKey,
    key: keyof NewOrEditCarpPairing,
    value: any
  ): NewOrEditStateType {
    if (state.parentCarp === null) {
      return state;
    }
    const mapCarpPairing = (pair: NewOrEditCarpPairing) => {
      const uniqueKeyOfPair = getUniqueKey(pair);
      if (uniqueKeyOfPair === uniqueKey) {
        return { ...pair, [key]: value };
      }
      return pair;
    };
    const carpPairings = isEntity(state.parentCarp)
      ? state.parentCarp.carpPairings.map(mapCarpPairing)
      : state.parentCarp.carpPairings.map(mapCarpPairing);
    return { ...state, parentCarp: { ...state.parentCarp, carpPairings } as NewOrEditParentCarp, isUnSave: true };
  },
  addImageToCarpPairing(state: NewOrEditStateType, uniqueKey: UniqueKey, imageId: number): NewOrEditStateType {
    if (state.parentCarp === null) {
      return state;
    }
    const mapCarpPairing = (pair: NewOrEditCarpPairing) => {
      const uniqueKeyOfPair = getUniqueKey(pair);
      if (uniqueKeyOfPair === uniqueKey) {
        return { ...pair, imageIds: pair.imageIds.concat(imageId) };
      }
      return pair;
    };
    const carpPairings = isEntity(state.parentCarp)
      ? state.parentCarp.carpPairings.map(mapCarpPairing)
      : state.parentCarp.carpPairings.map(mapCarpPairing);
    return { ...state, parentCarp: { ...state.parentCarp, carpPairings } as NewOrEditParentCarp, isUnSave: true };
  },
  removeImageToCarpPairing(state: NewOrEditStateType, uniqueKey: UniqueKey, imageId: number): NewOrEditStateType {
    if (state.parentCarp === null) {
      return state;
    }
    const mapCarpPairing = (pair: NewOrEditCarpPairing) => {
      const uniqueKeyOfPair = getUniqueKey(pair);
      if (uniqueKeyOfPair === uniqueKey) {
        return { ...pair, imageIds: pair.imageIds.filter((id) => id !== imageId) };
      }
      return pair;
    };
    const carpPairings = isEntity(state.parentCarp)
      ? state.parentCarp.carpPairings.map(mapCarpPairing)
      : state.parentCarp.carpPairings.map(mapCarpPairing);
    return { ...state, parentCarp: { ...state.parentCarp, carpPairings } as NewOrEditParentCarp, isUnSave: true };
  },
  addCarpPairing(state: NewOrEditStateType, carpPairing: NewCarpPairing): NewOrEditStateType {
    if (state.parentCarp === null) {
      return state;
    }
    return {
      ...state,
      parentCarp: {
        ...state.parentCarp,
        carpPairings: state.parentCarp.carpPairings.concat(carpPairing),
      } as NewOrEditParentCarp,
    };
  },
  removeCarpPairing(state: NewOrEditStateType, uniqueKey: UniqueKey): NewOrEditStateType {
    if (state.parentCarp === null) {
      return state;
    }
    const carpPairings = state.parentCarp.carpPairings
      .filter((pair) => {
        if (getUniqueKey(pair) === uniqueKey) {
          return !isNewEntity(pair);
        }
        return true;
      })
      .map((pair) => {
        if (getUniqueKey(pair) === uniqueKey) {
          return { ...pair, isDeleted: true };
        }
        return pair;
      });

    return {
      ...state,
      parentCarp: {
        ...state.parentCarp,
        carpPairings,
      } as NewOrEditParentCarp,
    };
  },
};

export const SaveHandler = {
  cancelParentCarp(state: NewOrEditStateType, parentCarp: NewOrEditParentCarp): NewOrEditStateType {
    return { ...state, parentCarp, isUnSave: false, isSaved: false };
  },
  saveStart(state: NewOrEditStateType): NewOrEditStateType {
    return { ...state, isSaving: true, isError: null };
  },
  saveSuccess(state: NewOrEditStateType, parentCarp: ParentCarp | null): NewOrEditStateType {
    return { ...state, parentCarp, isUnSave: false, isSaved: true, isSaving: false };
  },
  saveFail(state: NewOrEditStateType, message: string = ""): NewOrEditStateType {
    return { ...state, isUnSave: true, isSaved: false, isSaving: false, isError: { message } };
  },
};
