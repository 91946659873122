export interface NotificationAlertStateType {
  showErrorMessage: boolean;
  errorMessage: string;
}

export const getInitialState = (): NotificationAlertStateType => {
  return {
    showErrorMessage: false,
    errorMessage: "",
  };
};

export const InitHandlers = {
  showErrorMessage(state: NotificationAlertStateType, errorMessage: string): NotificationAlertStateType {
    return { ...state, showErrorMessage: true, errorMessage };
  },
  hideErrorMessage(state: NotificationAlertStateType): NotificationAlertStateType {
    return { ...state, showErrorMessage: false, errorMessage: "" };
  },
};
