import { ApplicationError } from "../../handler/errors/applicationError";
import { getSelectionName, isTakeoutCarpSelectionReport } from "../carpSelectionReport";
import { Entity, generateUid, getUniqueKey, isNewEntity, NewEntity } from "../entity";
import { getFryAmount, getFryCurrentAmount, NewOrEditPondReport, PondReport } from "../pondReport";
import { getValidation, isEmptyString, isLimitedNumber, Validation, Validator } from "../validation";

interface CarpMovingReportAttribute extends Record<string, unknown> {
  movedId: null | number;
  moverId: null | number;
  selectionNumber: number;
  subNumber: number;
  date: Date;
  amount: string;
  note: string;
}

export interface NewCarpMovingReport extends CarpMovingReportAttribute, NewEntity {
  pairPondReportId: null | number;
}
export interface EditCarpMovingReport extends CarpMovingReportAttribute, Entity {}

export type NewOrEditCarpMovingReport = NewCarpMovingReport | EditCarpMovingReport;
export type CarpMovingReport = EditCarpMovingReport;

// service
const NEW_ENTITY_PREFIX = "cmr-";
export function createNewCarpMovingReport(date: Date, selectionNumber: number, subNumber: number): NewCarpMovingReport {
  return {
    uid: generateUid(NEW_ENTITY_PREFIX),
    movedId: null,
    moverId: null,
    selectionNumber,
    subNumber,
    date,
    amount: "",
    note: "",
    pairPondReportId: null,
  };
}

export function isNewCarpMovingReport(
  carpMovingReport: NewOrEditCarpMovingReport
): carpMovingReport is NewCarpMovingReport {
  return isNewEntity(carpMovingReport);
}

export function getPairPondReport(
  carpMovingReport: NewOrEditCarpMovingReport,
  pondReports: NewOrEditPondReport[]
): NewOrEditPondReport | null {
  if (isNewCarpMovingReport(carpMovingReport)) {
    return pondReports.find((pr) => getUniqueKey(pr) === carpMovingReport.pairPondReportId) || null;
  }
  return (
    pondReports.find((pr) =>
      pr.carpMovingReports.some(
        (cmr) => getUniqueKey(cmr) === carpMovingReport.movedId || getUniqueKey(cmr) === carpMovingReport.moverId
      )
    ) || null
  );
}

export function getSuffixOfMovingReport(entity: NewOrEditCarpMovingReport): string {
  return entity.movedId !== null ? "から" : "への";
}

/*** Validator ***/
export class CarpMovingReportValidator extends Validator<NewOrEditCarpMovingReport> {
  private selectionNumber: number | null = null;
  private subNumber: number | null = null;

  constructor(private ownPondReport: NewOrEditPondReport, private pondReports: PondReport[]) {
    super();
  }

  public getMessages(): string {
    return `${this.getSelectionNumberString()} : ${super.getMessages()}`;
  }

  public validate(entity: NewOrEditCarpMovingReport) {
    this.selectionNumber = entity.selectionNumber;
    this.subNumber = entity.subNumber;

    const dateValidation = validateDate(entity);
    if (dateValidation) {
      this.addMessages(dateValidation);
    }
    const amountValidation = validateAmount(entity);
    if (amountValidation) {
      this.addMessages(amountValidation);
    }

    if (isNewEntity(entity)) {
      const pairReport = this.pondReports.find((pr) => pr.id === entity.pairPondReportId);
      if (!pairReport) {
        this.addMessages(getValidation("移動先の野池に選別記録がありませんでした。"));
      } else {
        this.validateNewMovedReport(entity.amount, this.ownPondReport);
        this.validateNewMoverReport(entity.amount, pairReport);
      }
    } else {
      if (entity.movedId !== null) {
        const pairReport = this.pondReports.find(
          (pr) =>
            pr.carpMovingReports.length !== 0 &&
            pr.carpMovingReports.some((cmr) => getUniqueKey(cmr) === entity.movedId)
        );
        if (!pairReport) {
          this.addMessages(getValidation("移動元の野池に選別記録がありませんでした。"));
        } else {
          const param = { amount: entity.amount, movedId: entity.movedId, moverId: entity.id };
          this.validateMoverReport(param, this.ownPondReport);
          this.validateMovedReport(param, pairReport);
        }
      } else if (entity.moverId !== null) {
        const pairReport = this.pondReports.find(
          (pr) =>
            pr.carpMovingReports.length !== 0 &&
            pr.carpMovingReports.some((cmr) => getUniqueKey(cmr) === entity.moverId)
        );
        if (!pairReport) {
          this.addMessages(getValidation("移動先の野池に選別記録がありませんでした。"));
        } else {
          const param = { amount: entity.amount, moverId: entity.moverId, movedId: entity.id };
          this.validateMovedReport(param, this.ownPondReport);
          this.validateMoverReport(param, pairReport);
        }
      }
    }
  }

  private getSelectionNumberString(): string {
    if (this.selectionNumber === null || this.subNumber === null) {
      throw new ApplicationError("不正な操作です。");
    }
    const selectionName = getSelectionName(this.selectionNumber);
    return `${selectionName}の${this.subNumber}番目の移動`;
  }

  private validateNewMoverReport(amount: string, moverReport: NewOrEditPondReport) {
    if (moverReport.carpSelectionReports.length === 0) {
      this.addMessages(getValidation("移動先の野池は選別が行われていません。"));
      return;
    }
    if (moverReport.carpSelectionReports.some(isTakeoutCarpSelectionReport)) {
      this.addMessages(getValidation("移動先の野池は池揚げが行われています。"));
      return;
    }
    const currentAmount = getFryCurrentAmount(moverReport);
    const sumAmount = currentAmount + (Number(amount) || 0);
    if (sumAmount < 0) {
      this.addMessages(getValidation("移動先の野池にいない鯉を移動元に移動しようとしています。"));
    }
  }

  private validateNewMovedReport(amount: string, movedReport: NewOrEditPondReport) {
    if (
      movedReport.carpSelectionReports.length !== 0 &&
      movedReport.carpSelectionReports.some(isTakeoutCarpSelectionReport)
    ) {
      this.addMessages(getValidation("移動元の野池は池揚げが行われています。"));
      return;
    }
    const currentAmount = getFryCurrentAmount(movedReport);
    if (currentAmount < 0) {
      this.addMessages(getValidation("移動元の野池にいる鯉よりも多くの匹数が設定されています。"));
    }
  }

  private validateMoverReport(param: { moverId: number; amount: string }, moverReport: NewOrEditPondReport) {
    const movingReport = moverReport.carpMovingReports.find((cmr) => param.moverId === getUniqueKey(cmr));
    if (!movingReport) {
      this.addMessages(getValidation("移動先の記録が見つかりませんでした。"));
      return;
    }
    const mapMovingReport = (cmr: NewOrEditCarpMovingReport) => {
      if (param.moverId === getUniqueKey(cmr)) {
        return { ...cmr, amount: param.amount };
      }
      return cmr;
    };

    const expectedMoverReport = {
      ...moverReport,
      carpMovingReports: isNewEntity(moverReport)
        ? moverReport.carpMovingReports.map(mapMovingReport)
        : moverReport.carpMovingReports.map(mapMovingReport),
    } as NewOrEditPondReport;
    const amount = getFryAmount(expectedMoverReport, movingReport.selectionNumber, movingReport.subNumber);
    if (amount < 0) {
      this.addMessages(getValidation("移動先の野池にいない鯉を移動元に移動しようとしています。"));
    }
  }

  private validateMovedReport(param: { movedId: number; amount: string }, movedReport: NewOrEditPondReport) {
    const movingReport = movedReport.carpMovingReports.find((cmr) => param.movedId === getUniqueKey(cmr));
    if (!movingReport) {
      this.addMessages(getValidation("移動元の記録が見つかりませんでした。"));
      return;
    }

    const mapMovingReport = (cmr: NewOrEditCarpMovingReport) => {
      if (param.movedId === getUniqueKey(cmr)) {
        return { ...cmr, amount: param.amount };
      }
      return cmr;
    };
    const expectedMovedReport = {
      ...movedReport,
      carpMovingReports: isNewEntity(movedReport)
        ? movedReport.carpMovingReports.map(mapMovingReport)
        : movedReport.carpMovingReports.map(mapMovingReport),
    } as NewOrEditPondReport;

    const amount = getFryAmount(expectedMovedReport, movingReport.selectionNumber, movingReport.subNumber);
    if (amount < 0) {
      this.addMessages(getValidation("移動元の野池にいる鯉よりも多くの匹数が設定されています。"));
    }
  }
}

function validateDate({ date }: NewOrEditCarpMovingReport): Validation | null {
  if (date == null) {
    return getValidation("日付は必須です。入力してください。");
  }
  return null;
}

function validateAmount({ amount }: NewOrEditCarpMovingReport): Validation | null {
  if (isEmptyString(amount)) {
    return getValidation("匹数は必須です。入力してください。");
  }
  if (!isLimitedNumber(amount, 9, 4)) {
    return getValidation("匹数は整数部9桁、小数部4桁で入力してください。");
  }
  return null;
}
