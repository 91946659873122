import * as React from "react";
import styled from "styled-components";
import { COLOR } from "../../../styles/Color";
import { DIMEN } from "../../../styles/Dimen";
import { Modal, ModalContent } from "../../atoms/Modal";

interface Action {
  text: string;
  onClick: () => void;
}

interface MobileActionsModalProp {
  isOpen: boolean;
  onClose: () => void;
  actions: Action[];
}

export const ActionsModal = ({ isOpen, onClose, actions }: MobileActionsModalProp) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="small" closeOnDimmerClick={false} basic={true}>
      <ModalContent>
        <Outer>
          <Content>
            {actions.map((a, index) => (
              <List key={index} onClick={a.onClick}>
                {a.text}
              </List>
            ))}
          </Content>
          <Actions>
            <CancelButton onClick={onClose}>キャンセル</CancelButton>
          </Actions>
        </Outer>
      </ModalContent>
    </Modal>
  );
};

export const Outer = styled.div`
  color: ${COLOR.Primary.base} !important;
  font-size: 18px;
  position: fixed;
  width: calc(100% - ${DIMEN.X1} * 2);
  left: 0;
  bottom: 0;
`;

export const Content = styled.div`
  text-align: center;
  font-weight: 700 !important;
  margin: ${DIMEN.X1_5};
  background-color: ${COLOR.Base.white};
  border-radius: 5px;
`;

export const List = styled.div`
  padding: ${DIMEN.X1_5};
  border-bottom: 1px solid ${COLOR.Gray["lighter-2"]};
  &:last-child {
    border-bottom: 0;
  }
`;

export const Actions = styled.div`
  display: flex;
  border-top: 1px solid ${COLOR.Gray["lighter-2"]};
  text-align: center;
  font-weight: 700;
  margin: ${DIMEN.X1_5};
  background-color: ${COLOR.Base.white};
  border-radius: 5px;
  padding: ${DIMEN.X1_5};
`;

export const CancelButton = styled.div`
  flex: 1 1;
  border-right: 1px solid ${COLOR.Gray["lighter-2"]};
`;
