import * as React from "react";
import { Header } from "semantic-ui-react";
import styled from "styled-components";
import { NewOrEditCarpPairing } from "../../../../domain/carpPairing";
import { CarpVarietyType } from "../../../../domain/carpVarietyType";
import { getUniqueKey, UniqueKey } from "../../../../domain/entity";
import { getImageSrcURL } from "../../../../domain/image";
import { NewOrEditParentCarp } from "../../../../domain/parentCarp";
import { DIMEN } from "../../../styles/Dimen";
import { Button } from "../../atoms/Button";
import { Form } from "../../atoms/Form/Form";
import { FormNumberInput, FormTextInput } from "../../atoms/Form/Input";
import { FormFileInput } from "../../atoms/Form/InputFile";
import { FormSelect } from "../../atoms/Form/Select";
import { EditImage } from "../../atoms/Image";
import { Spinner } from "../../atoms/Spinner";
import { MasterForm } from "../../molecules/MasterForm";
import { MasterActionButton, MasterFormButton } from "../../molecules/MasterFormButton";

interface ParentCarpFormProps {
  parentCarp: NewOrEditParentCarp | null;
  carpPairings: NewOrEditCarpPairing[];
  carpVarietyTypes: CarpVarietyType[];
  isSaving: boolean;
  disabledSave: boolean;
  onChangeName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCarpVarietyType: (varietyId: null | number) => void;
  onChangeYear: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCarpPairingName: (uniqueKey: UniqueKey, e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>, uniqueKey: UniqueKey) => void;
  onAddCarpPairing: () => void;
  onRemoveCarpPairing: (uniqueKey: UniqueKey) => void;
  onClickSave: () => void;
  onClickCancel: () => void;
  onClickAdd: (() => void) | null;
  onClickDelete: (() => void) | null;
  onClickImage: (imageIds: number[], imageId: number) => void;
  onClickImageRemove: (uniqueKey: UniqueKey, imageId: number) => void;
}

export const ParentCarpForm = ({
  parentCarp,
  carpPairings,
  carpVarietyTypes,
  isSaving,
  disabledSave,
  onChangeName,
  onChangeCarpVarietyType,
  onChangeYear,
  onChangeCarpPairingName,
  onChangeFile,
  onAddCarpPairing,
  onRemoveCarpPairing,
  onClickSave,
  onClickCancel,
  onClickAdd,
  onClickDelete,
  onClickImage,
  onClickImageRemove,
}: ParentCarpFormProps) => {
  if (parentCarp === null) {
    return <Spinner />;
  }
  return (
    <MasterForm onClickAdd={onClickAdd} onClickDelete={onClickDelete}>
      {isSaving && <Spinner />}
      <Form>
        <FormTextInput label={"親鯉名"} value={parentCarp.name} onChange={onChangeName} />
        <FormSelect
          value={parentCarp.carpVarietyTypeId}
          label={"品種"}
          options={carpVarietyTypes}
          onChange={onChangeCarpVarietyType}
        />
        <FormNumberInput
          label={"年"}
          value={parentCarp.year === null ? "" : "" + parentCarp.year}
          onChange={onChangeYear}
        />
        <SectionHeader>組み合わせ一覧</SectionHeader>
        <SectionActions>
          <Button onClick={onAddCarpPairing}>組み合わせを追加</Button>
        </SectionActions>
        <PairingSection>
          {carpPairings.map((pair) =>
            mapPairRow(
              pair,
              onChangeCarpPairingName,
              onChangeFile,
              onClickImage,
              onClickImageRemove,
              onRemoveCarpPairing
            )
          )}
        </PairingSection>
        <MasterFormButton onClickSave={onClickSave} onClickCancel={onClickCancel} disabledSave={disabledSave} />
      </Form>
    </MasterForm>
  );
};

const mapPairRow = (
  pair: NewOrEditCarpPairing,
  onChangeCarpPairingName: (uniqueKey: UniqueKey, e: React.ChangeEvent<HTMLInputElement>) => void,
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>, uniqueKey: UniqueKey) => void,
  onClickImage: (imageIds: number[], imageId: number) => void,
  onClickImageRemove: (uniqueKey: UniqueKey, imageId: number) => void,
  onClickDelete: (uniqueKey: UniqueKey) => void
) => {
  return (
    <React.Fragment key={getUniqueKey(pair)}>
      <Actions>
        <MasterActionButton onClickAdd={null} onClickDelete={() => onClickDelete(getUniqueKey(pair))} />
      </Actions>
      <FormTextInput
        label={"組み合わせ名"}
        value={pair.name}
        onChange={(e) => onChangeCarpPairingName(getUniqueKey(pair), e)}
      />
      {pair.imageIds.map((id) => (
        <ImageBlock key={id}>
          <EditImage
            onClick={() => onClickImage(pair.imageIds, id)}
            onClickRemove={() => onClickImageRemove(getUniqueKey(pair), id)}
            isLoading={false}
            src={getImageSrcURL(id)}
          />
        </ImageBlock>
      ))}
      <ImageBlock>
        <ImageDummyBlock>
          <FormFileInput onChange={(e) => onChangeFile(e, getUniqueKey(pair))} />
        </ImageDummyBlock>
      </ImageBlock>
    </React.Fragment>
  );
};

const PairingSection = styled.div`
  padding: 1em 0;
`;
const SectionHeader = styled(Header).attrs({ as: "h3" })``;
const SectionActions = styled.div`
  text-align: right;
`;
const ImageBlock = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: ${DIMEN.X1};
  margin-bottom: ${DIMEN.X1};
`;
const ImageDummyBlock = styled.div`
  display: flex;
  width: 250px;
  height: 250px;
  justify-content: center;
  align-items: center;
`;
const Actions = styled.div``;
