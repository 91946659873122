import * as _ from "lodash";
import { default as moment } from "moment";
import {
  ADULT_CARP_REPORTS_MAX_COUNT,
  AdultCarpReportValidator,
  createNewAdultCarpReport,
  fillEmptyAdultCarpReport,
  getAdultSelectionName,
  NewAdultCarpReport,
  NewOrEditAdultCarpReport,
  removeEmptyAdultCarpReport,
} from "../adultCarpReport";
import { getFiscalYear } from "../calendar";
import { CarpMovingReportValidator, NewCarpMovingReport, NewOrEditCarpMovingReport } from "../carpMovingReport";
import {
  CarpSelectionReportValidator,
  createNewCarpSelectionReport,
  fillEmptyFryCarpSelectionReport,
  FRY_SELECTION_REPORTS_MAX_COUNT,
  getSelectionName,
  isReleaseCarpSelectionReport,
  isTakeoutCarpSelectionReport,
  NewCarpSelectionReport,
  NewOrEditCarpSelectionReport,
  overwriteSelectionReportBeforeSaving,
  removeEmptyCarpSelectionReport,
} from "../carpSelectionReport";
import { Entity, generateUid, getUniqueKey, isEntity, isNewEntity, NewEntity, UniqueKey } from "../entity";
import {
  createNewPondDisinfectionReport,
  fillEmptyPondDisinfectionReport,
  getDisinfectionName,
  NewOrEditPondDisinfectionReport,
  NewPondDisinfectionReport,
  PondDisinfectionReportValidator,
  removeEmptyPondDisinfectionReport,
} from "../pondDisinfectionReport";
import { isAdultPondType, isFryPondType, PondType } from "../pondType";
import { ScaleType } from "../scaleType";
import { getValidation, isLimitedNumber, Validation, Validator } from "../validation";

interface PondReportAttribute extends Record<string, unknown> {
  pondId: number;
  userId: number;
  pondTypeId: number;
  parentCarpId: null | number;
  carpPairingId: null | number;
  fiscalYear: string;
  isCompleted: boolean;
  subNumber: number;
  carpMovingReports: NewOrEditCarpMovingReport[];
  carpSelectionReports: NewOrEditCarpSelectionReport[];
  pondDisinfectionReports: NewOrEditPondDisinfectionReport[];
  adultCarpReports: NewOrEditAdultCarpReport[];
}

export interface NewPondReport extends PondReportAttribute, NewEntity {
  carpMovingReports: NewCarpMovingReport[];
  carpSelectionReports: NewCarpSelectionReport[];
  pondDisinfectionReports: NewPondDisinfectionReport[];
  adultCarpReports: NewAdultCarpReport[];
}
export interface EditPondReport extends PondReportAttribute, Entity {}

export type NewOrEditPondReport = NewPondReport | EditPondReport;
export type PondReport = EditPondReport;

// service
const NEW_ENTITY_PREFIX = "pr-";

export function createNewFryPondReport(
  pondId: number,
  userId: number,
  fiscalYear: number | string,
  pondTypeId: number,
  subNumber: number = 1
): NewPondReport {
  return {
    uid: generateUid(NEW_ENTITY_PREFIX),
    pondId,
    userId,
    pondTypeId,
    parentCarpId: null,
    carpPairingId: null,
    fiscalYear: "" + fiscalYear,
    isCompleted: false,
    subNumber,

    carpMovingReports: [],
    carpSelectionReports: _.range(0, FRY_SELECTION_REPORTS_MAX_COUNT, 1).map(createNewCarpSelectionReport),
    pondDisinfectionReports: [createNewPondDisinfectionReport()],
    adultCarpReports: [],
  };
}

export function createNewAdultPondReport(
  pondId: number,
  userId: number,
  fiscalYear: number | string,
  pondTypeId: number
): NewPondReport {
  return {
    uid: generateUid(NEW_ENTITY_PREFIX),
    pondId,
    userId,
    pondTypeId,
    parentCarpId: null,
    carpPairingId: null,
    fiscalYear: "" + fiscalYear,
    isCompleted: false,
    subNumber: 1,
    carpMovingReports: [],
    carpSelectionReports: [],
    pondDisinfectionReports: [],
    adultCarpReports: _.range(0, ADULT_CARP_REPORTS_MAX_COUNT, 1).map(createNewAdultCarpReport),
  };
}

export function isNewPondReport(report: NewOrEditPondReport): report is NewPondReport {
  return isNewEntity(report);
}

export function isEditPondReport(report: NewOrEditPondReport): report is EditPondReport {
  return isEntity(report);
}

/**
 * 指定した年度のPondReportに制限する.
 * @param pondReports
 * @param fiscalYear
 */
export function filterByFiscalYear(pondReports: NewOrEditPondReport[], fiscalYear: number): NewOrEditPondReport[] {
  return pondReports.filter((pr) => {
    return pr.fiscalYear === "" + fiscalYear;
  });
}

/**
 * 指定した野池のPondReportに制限する.
 * @param pondReports
 * @param pondId
 */
export function filterByPond(pondReports: NewOrEditPondReport[], pondId: null | number): NewOrEditPondReport[] {
  return pondReports.filter((pr) => pr.pondId === pondId);
}

interface FilterCriteria {
  fiscalYear?: number;
  pondId?: null | number;
}

export function filterPondReportsByCriteria(
  pondReports: NewOrEditPondReport[],
  { fiscalYear, pondId }: FilterCriteria
): NewOrEditPondReport[] {
  let reports: NewOrEditPondReport[] = pondReports;
  if (typeof fiscalYear !== "undefined") {
    reports = filterByFiscalYear(reports, fiscalYear);
  }
  if (typeof pondId !== "undefined") {
    reports = filterByPond(reports, pondId);
  }
  return reports;
}

export function getSubNumberName(pondReport: NewOrEditPondReport): string {
  return `${pondReport.subNumber}期`;
}

/**
 * 指定した野池の今年度のPondReportsを返す.
 * @param pondReports
 * @param pondId
 * @param date
 */
export function getCurrentPondReports(
  pondReports: NewOrEditPondReport[],
  pondId: null | number,
  date: Date = new Date()
): NewOrEditPondReport[] {
  const reportsByFiscalYear = filterByFiscalYear(pondReports, getFiscalYear(date));
  return filterByPond(reportsByFiscalYear, pondId);
}

/**
 * 指定した野池の今年度の最も新しいPondReportを返す.
 * @param pondReports
 * @param pondId
 * @param date
 */
export function getCurrentPondReport(
  pondReports: NewOrEditPondReport[],
  pondId: null | number,
  date: Date = new Date()
): NewOrEditPondReport | null {
  const currentReports = getCurrentPondReports(pondReports, pondId, date);
  if (currentReports.length === 0) {
    return null;
  }
  return currentReports.concat().sort((a, b) => b.subNumber - a.subNumber)[0] || null;
}

interface PondReportDate {
  uniqueKey: UniqueKey;
  startDate: Date | null;
  endDate: Date | null;
}

function getStartDateOfPondReport(pondReport: NewOrEditPondReport): Date | null {
  if (pondReport.pondDisinfectionReports.length !== 0) {
    const disinfection = pondReport.pondDisinfectionReports[0];
    if (disinfection.date !== null) {
      return disinfection.date;
    }
  }
  const selectionReports = removeEmptyCarpSelectionReport(pondReport.carpSelectionReports);
  if (selectionReports.length !== 0) {
    const selection = selectionReports[0];
    if (selection.date !== null) {
      return selection.date;
    }
    const movingReports = pondReport.carpMovingReports.filter(
      (cmr) => cmr.selectionNumber === selection.selectionNumber
    );
    if (movingReports.length !== 0) {
      const moving = movingReports[0];
      return moving.date;
    }
  }

  const adultCarpReports = removeEmptyAdultCarpReport(pondReport.adultCarpReports);
  if (adultCarpReports.length !== 0) {
    const adultCarp = adultCarpReports[0];
    if (adultCarp.date !== null) {
      return adultCarp.date;
    }
  }
  return null;
}

function getEndDateOfPondReport(pondReport: NewOrEditPondReport): Date | null {
  const selectionReports = removeEmptyCarpSelectionReport(pondReport.carpSelectionReports);
  if (selectionReports.length !== 0) {
    const selection = selectionReports[selectionReports.length - 1];
    const movingReports = pondReport.carpMovingReports.filter(
      (cmr) => cmr.selectionNumber === selection.selectionNumber
    );
    if (movingReports.length !== 0) {
      const moving = movingReports[movingReports.length - 1];
      return moving.date;
    }

    if (selection.date !== null) {
      return selection.date;
    }
  }
  if (pondReport.pondDisinfectionReports.length !== 0) {
    const disinfection = pondReport.pondDisinfectionReports[0];
    if (disinfection.date !== null) {
      return disinfection.date;
    }
  }
  const adultCarpReports = removeEmptyAdultCarpReport(pondReport.adultCarpReports);
  if (adultCarpReports.length !== 0) {
    const adultCarp = adultCarpReports[adultCarpReports.length - 1];
    if (adultCarp.date !== null) {
      return adultCarp.date;
    }
  }
  return null;
}

export function mapPondReportToPondReportDate(pondReport: NewOrEditPondReport): PondReportDate {
  return {
    uniqueKey: getUniqueKey(pondReport),
    startDate: getStartDateOfPondReport(pondReport),
    endDate: getEndDateOfPondReport(pondReport),
  };
}

/**
 * 指定した野池に属するPondReportかつ指定した日時に最も近いPondReportを返す.
 * @param pondReports
 * @param pondId
 * @param date
 */
export function getPondReportByDate(
  pondReports: NewOrEditPondReport[],
  pondId: null | number,
  date: Date
): NewOrEditPondReport | null {
  const reportsByFiscalYear = filterByFiscalYear(pondReports, getFiscalYear(date));
  const reportsByPond = filterByPond(reportsByFiscalYear, pondId);
  const pondReportDates = reportsByPond.map(mapPondReportToPondReportDate);
  const momentDate = moment(date);
  const targetPondReportDate = pondReportDates.reduce<{ id: UniqueKey | null; value: number }>(
    (result, reportDate) => {
      const momentStartDate = moment(reportDate.startDate || "invalid");
      const momentEndDate = moment(reportDate.endDate || "invalid");
      const startDiff = momentStartDate.isValid()
        ? Math.abs(momentDate.diff(momentStartDate))
        : Number.MAX_SAFE_INTEGER;
      const endDiff = momentEndDate.isValid() ? Math.abs(momentDate.diff(momentEndDate)) : Number.MAX_SAFE_INTEGER;
      const minDiff = Math.min(startDiff, endDiff);
      if (minDiff < result.value) {
        return { id: reportDate.uniqueKey, value: minDiff };
      }
      return result;
    },
    { id: null, value: Number.MAX_SAFE_INTEGER }
  );
  return reportsByPond.find((r) => getUniqueKey(r) === targetPondReportDate.id) || null;
}

/**
 * 空でない選別記録の最大のSelectionNumberを返す.
 * @param pondReport
 */
export function getFryMaxSelectionNumber(pondReport: NewOrEditPondReport): number {
  const selectionReports = removeEmptyCarpSelectionReport(pondReport.carpSelectionReports);
  if (selectionReports.length === 0) {
    return 0;
  }
  const numbers = selectionReports.map((csr) => csr.selectionNumber);
  return Math.max(...numbers);
}

/**
 * 指定したSelectionNumberの中で最大のSubNumberを返す.
 * @param pondReport
 * @param selectionNumber
 */
export function getFryMaxSubNumberOfSelectionNumber(pondReport: NewOrEditPondReport, selectionNumber: number): number {
  const movingReportsBySelectionNumber = pondReport.carpMovingReports.filter(
    (cmr) => cmr.selectionNumber === selectionNumber
  );
  if (movingReportsBySelectionNumber.length === 0) {
    return 0;
  }
  const numbers = movingReportsBySelectionNumber.map((cmr) => cmr.subNumber);
  return Math.max(...numbers);
}

/**
 * 最新の野池の引数を返す.
 * @param pondReport
 */
export function getFryCurrentAmount(pondReport: NewOrEditPondReport): number {
  const selectionReport = _.last(removeEmptyCarpSelectionReport(pondReport.carpSelectionReports));
  if (!selectionReport) {
    return 0;
  }
  const subNumberOfMovingReports = pondReport.carpMovingReports
    .filter((cmr) => cmr.selectionNumber === selectionReport.selectionNumber)
    .map((cmr) => cmr.subNumber);
  const subNumber = Math.max(...subNumberOfMovingReports, 0);
  return getFryAmount(pondReport, selectionReport.selectionNumber, subNumber);
}

export function getFryAmount(pondReport: NewOrEditPondReport, selectionNumber: number, subNumber: number): number {
  const selectionReport = removeEmptyCarpSelectionReport(pondReport.carpSelectionReports).find(
    (csr) => csr.selectionNumber === selectionNumber
  );
  if (!selectionReport) {
    return 0;
  }
  const movingReportsBySelectionNumber = pondReport.carpMovingReports.filter(
    (cmr) => cmr.selectionNumber === selectionReport.selectionNumber && cmr.subNumber <= subNumber
  );
  if (movingReportsBySelectionNumber.length === 0) {
    return Number(selectionReport.amount);
  }
  return movingReportsBySelectionNumber.reduce((sum: number, cmr) => {
    if (cmr.moverId !== null) {
      return sum - Number(cmr.amount);
    }
    if (cmr.movedId !== null) {
      return sum + Number(cmr.amount);
    }
    if (isNewEntity(cmr) && cmr.pairPondReportId !== null) {
      return sum - Number(cmr.amount);
    }
    return sum;
  }, Number(selectionReport.amount));
}

export function isFryPondReports(pondReports: NewOrEditPondReport[], pondTypes: PondType[]): boolean {
  return pondReports.some((pr) => {
    const type = pondTypes.find((t) => t.id === pr.pondTypeId);
    return !!type && isFryPondType(type);
  });
}
export function isAdultPondReports(pondReports: NewOrEditPondReport[], pondTypes: PondType[]): boolean {
  return pondReports.some((pr) => {
    const type = pondTypes.find((t) => t.id === pr.pondTypeId);
    return !!type && isAdultPondType(type);
  });
}

/**
 * 稚魚池の空のPondDisinfectionReport, CarpSelectionReportを表示上必要な数分埋める.
 * @param pondReports
 */
export function fillEmptyFryPondReports(pondReports: NewOrEditPondReport[]): NewOrEditPondReport[] {
  return pondReports.map((pr) => ({
    ...pr,
    pondDisinfectionReports: fillEmptyPondDisinfectionReport(pr.pondDisinfectionReports),
    carpSelectionReports: fillEmptyFryCarpSelectionReport(pr.carpSelectionReports),
  })) as NewOrEditPondReport[];
}

/**
 * 稚魚池の空のPondDisinfectionReport, CarpSelectionReportを取り除く.
 * @param pondReports
 */
export function removeEmptyFryPondReports(pondReports: NewOrEditPondReport[]): NewOrEditPondReport[] {
  return pondReports.map((pr) => ({
    ...pr,
    pondDisinfectionReports: removeEmptyPondDisinfectionReport(pr.pondDisinfectionReports),
    carpSelectionReports: removeEmptyCarpSelectionReport(pr.carpSelectionReports),
  })) as NewOrEditPondReport[];
}

/**
 * 2才以上池の空のAdultCarpReportを表示上必要な数分埋める.
 * @param pondReports
 */
export function fillEmptyAdultPondReports(pondReports: NewOrEditPondReport[]): NewOrEditPondReport[] {
  return pondReports.map((pr) => ({
    ...pr,
    adultCarpReports: fillEmptyAdultCarpReport(pr.adultCarpReports),
  })) as NewOrEditPondReport[];
}

/**
 * 2才以上池の空のAdultCarpReportを削除する.
 * @param pondReports
 */
export function removeEmptyAdultPondReports(pondReports: NewOrEditPondReport[]): NewOrEditPondReport[] {
  return pondReports.map((pr) => ({
    ...pr,
    adultCarpReports: removeEmptyAdultCarpReport(pr.adultCarpReports),
  })) as NewOrEditPondReport[];
}

export interface PondStatus {
  date: Date;
  selectionNumber: number;
  status: string;
  statusKey: "disinfection" | "selection" | "adult";
  amount: string;
  unit: string;
}

/**
 * (塩素/放鯉/選別/池揚げ)等の状態の内、最新の野池の状態を返す.
 * @param pondReport
 */
export function getCurrentPondStatus(pondReport: NewOrEditPondReport): PondStatus | null {
  const removedEmptySelectionReports = removeEmptyCarpSelectionReport(pondReport.carpSelectionReports);
  if (removedEmptySelectionReports.length > 0) {
    const selectionReport = removedEmptySelectionReports[removedEmptySelectionReports.length - 1];
    const status = getSelectionName(selectionReport.selectionNumber);
    const amount = getFryCurrentAmount(pondReport);
    const isEmptyAmount = amount === 0;
    return {
      date: selectionReport.date as Date,
      selectionNumber: selectionReport.selectionNumber,
      status,
      statusKey: "selection",
      amount: isEmptyAmount ? getEmptyAmountMessage() : "" + amount,
      unit: isEmptyAmount ? "" : "匹",
    };
  }

  const removedEmptyDisinfectionReports = removeEmptyPondDisinfectionReport(pondReport.pondDisinfectionReports);
  if (removedEmptyDisinfectionReports.length > 0) {
    const disinfectionReport = removedEmptyDisinfectionReports[0];
    return {
      date: disinfectionReport.date as Date,
      status: getDisinfectionName(),
      statusKey: "disinfection",
      amount: disinfectionReport.amount,
      unit: "本",
      selectionNumber: 0,
    };
  }

  const removedEmptyAdultCarpReports = removeEmptyAdultCarpReport(pondReport.adultCarpReports);
  if (removedEmptyAdultCarpReports.length > 0) {
    const adultReport = removedEmptyAdultCarpReports[removedEmptyAdultCarpReports.length - 1];
    const status = getAdultSelectionName(adultReport.selectionNumber);
    return {
      date: adultReport.date as Date,
      status,
      statusKey: "adult",
      amount: adultReport.amount,
      unit: "匹",
      selectionNumber: adultReport.selectionNumber,
    };
  }

  return null;
}
export function getEmptyAmountMessage(): string {
  return "空";
}

export function overwriteFryPondReportBeforeSaving(
  pondReport: NewOrEditPondReport,
  scaleTypes: ScaleType[]
): NewOrEditPondReport {
  if (isNewEntity(pondReport)) {
    return {
      ...pondReport,
      carpSelectionReports: pondReport.carpSelectionReports.map((csr) =>
        overwriteSelectionReportBeforeSaving(csr, scaleTypes)
      ) as NewCarpSelectionReport[],
    };
  }
  return {
    ...pondReport,
    carpSelectionReports: pondReport.carpSelectionReports.map((csr) =>
      overwriteSelectionReportBeforeSaving(csr, scaleTypes)
    ),
  };
}

/*** Validator ***/
export class PondReportValidator extends Validator<NewOrEditPondReport> {
  constructor(private pondReports: PondReport[]) {
    super();
  }

  public validate(entity: NewOrEditPondReport) {
    const fiscalYearValidation = validateFiscalYear(entity);
    if (fiscalYearValidation) {
      this.addMessages(fiscalYearValidation);
    }
    const pondValidation = validatePond(entity);
    if (pondValidation) {
      this.addMessages(pondValidation);
    }
    const pondTypeValidation = validatePondType(entity);
    if (pondTypeValidation) {
      this.addMessages(pondTypeValidation);
    }
    const workerValidation = validateWorker(entity);
    if (workerValidation) {
      this.addMessages(workerValidation);
    }

    // 関係性チェック
    if (entity.pondDisinfectionReports.length === 0 && entity.carpSelectionReports.length !== 0) {
      this.addMessages(getValidation("塩素を行わずに選別が行われています。塩素を入力してください。"));
    }
    if (
      entity.carpSelectionReports.length !== 0 &&
      !entity.carpSelectionReports.some(isReleaseCarpSelectionReport) &&
      entity.carpSelectionReports.some(isTakeoutCarpSelectionReport)
    ) {
      this.addMessages(getValidation("放鯉を行わずに池揚げが行われています。"));
    }

    entity.pondDisinfectionReports.forEach((pdr) => {
      const disinfectionValidator = new PondDisinfectionReportValidator();
      disinfectionValidator.validate(pdr);
      if (!disinfectionValidator.isValid()) {
        this.addMessages(getValidation(`塩素 : ${disinfectionValidator.getMessages()}`));
      }
    });
    entity.carpSelectionReports.forEach((csr) => {
      const carpSelectionReportValidator = new CarpSelectionReportValidator();
      carpSelectionReportValidator.validate(csr);
      if (!carpSelectionReportValidator.isValid()) {
        this.addMessages(getValidation(carpSelectionReportValidator.getMessages()));
      }
    });
    entity.carpMovingReports.forEach((cmr) => {
      const carpMovingReportValidator = new CarpMovingReportValidator(entity, this.pondReports);
      carpMovingReportValidator.validate(cmr);
      if (!carpMovingReportValidator.isValid()) {
        this.addMessages(getValidation(carpMovingReportValidator.getMessages()));
      }
    });

    entity.adultCarpReports.forEach((adr) => {
      const adultCarpReportValidator = new AdultCarpReportValidator();
      adultCarpReportValidator.validate(adr);
      if (!adultCarpReportValidator.isValid()) {
        this.addMessages(getValidation(adultCarpReportValidator.getMessages()));
      }
    });
  }
}

function validateFiscalYear({ fiscalYear }: NewOrEditPondReport): Validation | null {
  if (!isLimitedNumber("" + fiscalYear, 4)) {
    return getValidation("年度は4文字以下で入力してください。");
  }
  return null;
}
function validatePond({ pondId }: NewOrEditPondReport): Validation | null {
  if (pondId === null) {
    return getValidation("野池は必須です。");
  }
  return null;
}

function validatePondType({ pondTypeId }: NewOrEditPondReport): Validation | null {
  if (pondTypeId === null) {
    return getValidation("池の区分は必須です。");
  }
  return null;
}
function validateWorker({ userId }: NewOrEditPondReport): Validation | null {
  if (userId === null) {
    return getValidation("担当者は必須です。");
  }
  return null;
}
