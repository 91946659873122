import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { errorHandler, ErrorMessage } from "../../handler/errorHandlers";
import { ApplicationState } from "../../store/modules";
import { authStateActions } from "../../store/modules/auth/ducks";
import { notificationAlertStateActions } from "../../store/modules/notification/alert/ducks";
import { BaseNavigationContent, BaseNavigationHeader } from "../components/molecules/NavigationHeader";

interface StateType {
  errorMessage: string;
}

interface OwnProps {
  children?: any;
}

interface DispatchProps {
  showErrorMessage: (errorMessage: string) => void;
  loginFailed: () => void;
}

type WrapperProps = StateType & OwnProps & DispatchProps & RouteComponentProps;

interface WrapperState {
  error: Error | null;
}

export class Wrapper extends React.Component<WrapperProps, WrapperState> {
  public static getDerivedStateFromError(error: any) {
    return { error };
  }

  constructor(props: WrapperProps) {
    super(props);

    this.state = {
      error: null,
    };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    errorHandler(error, (errorMessage: string, statusCode?: number) => {
      if (statusCode === 401) {
        this.props.loginFailed();
        this.props.history.push("/login");
        return;
      }
    });
  }

  public render() {
    if (this.state.error !== null) {
      return (
        <>
          <BaseNavigationHeader title={"作業記録"} />
          <BaseNavigationContent>
            <h2>予期しないエラーが発生しました。</h2>
            <h3>画面をリロードして再度お試し頂くか、管理者にお問い合わせをお願いいたします。</h3>
          </BaseNavigationContent>
        </>
      );
    }
    return this.props.children;
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StateType & OwnProps => {
  return {
    errorMessage: state.notification.alertState.errorMessage,
    ...ownProps,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    showErrorMessage: (errorMessage: string) =>
      dispatch(notificationAlertStateActions.showErrorMessage({ errorMessage })),
    loginFailed: () => dispatch(authStateActions.loginFail({ errorMessage: ErrorMessage.UnAuthorized })),
  };
};

export const ErrorBoundaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapper));
