import { Dispatch } from "redux";
import { createNewFertilizer, FertilizerValidator } from "../../../domain/fertilizer";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { FertilizerRepository } from "../../../infrastracture/fertilizer/repository";
import { ApplicationState } from "../../../store/modules";
import { apiFertilizerActions } from "../../../store/modules/api/fertilizer/ducks";
import { masterFertilizerNewOrEditStateActions } from "../../../store/modules/master/fertilizer/newOrEditState/ducks";
import { notificationAlertStateActions } from "../../../store/modules/notification/alert/ducks";
import { notificationQueueStateActions } from "../../../store/modules/notification/queue/ducks";

interface INewStateService extends IApplicationService {
  createFertilizer: () => void;
  changeName: (name: string) => void;
  cancelFertilizer: () => void;
  saveFertilizer: () => void;
}

export class NewStateService implements INewStateService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public createFertilizer() {
    this.dispatch(masterFertilizerNewOrEditStateActions.createFertilizer({ fertilizer: createNewFertilizer() }));
  }

  @catchApplicationError()
  public changeName(name: string) {
    this.dispatch(masterFertilizerNewOrEditStateActions.changeFertilizer({ key: "name", value: name }));
  }

  @catchApplicationError()
  public cancelFertilizer() {
    this.dispatch(masterFertilizerNewOrEditStateActions.cancelFertilizer({ fertilizer: createNewFertilizer() }));
  }

  @catchApplicationError((dispatch) => dispatch(masterFertilizerNewOrEditStateActions.saveFail()))
  public async saveFertilizer() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(masterFertilizerNewOrEditStateActions.saveStart());
      const { newOrEditState } = getState().master.fertilizer;
      const newFertilizer = newOrEditState.fertilizer;
      if (newFertilizer === null) {
        throw new ApplicationError("肥料が見つかりませんでした。");
      }
      const validator = new FertilizerValidator();
      validator.validate(newFertilizer);
      if (!validator.isValid()) {
        this.dispatch(notificationAlertStateActions.showErrorMessage({ errorMessage: validator.getMessages() }));
        this.dispatch(masterFertilizerNewOrEditStateActions.saveFail());
        return;
      }
      const savedFertilizer = await new FertilizerRepository().postFertilizer(newFertilizer);
      this.dispatch(masterFertilizerNewOrEditStateActions.saveSuccess({ fertilizer: savedFertilizer }));
      this.dispatch(apiFertilizerActions.createFertilizer({ fertilizer: savedFertilizer }));
      this.dispatch(notificationQueueStateActions.addSaveMessage({ itemName: "肥料" }));
    });
  }
}
