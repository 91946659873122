import { Dispatch } from "redux";
import { getUniqueKey, UniqueKey } from "../../../domain/entity";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationState } from "../../../store/modules";
import { reportPondReportPairReportModalStateActions } from "../../../store/modules/report/pondReport/pairReportModalState/ducks";

interface IPairReportModalStateService extends IApplicationService {
  openModal: () => void;
  closeModal: () => void;
  selectPondReport: (movingReportUniqueKey: UniqueKey) => void;
}

export class PairReportModalStateService implements IPairReportModalStateService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public openModal() {
    this.dispatch(reportPondReportPairReportModalStateActions.openModal());
  }

  @catchApplicationError()
  public closeModal() {
    this.dispatch(reportPondReportPairReportModalStateActions.closeModal());
  }

  @catchApplicationError()
  public selectPondReport(movingReportUniqueKey: UniqueKey) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const pondReport = state.api.pondReport.pondReports.find(
        (pr) => !!pr.carpMovingReports.find((cmr) => getUniqueKey(cmr) === movingReportUniqueKey)
      );
      if (pondReport) {
        this.dispatch(reportPondReportPairReportModalStateActions.selectPondReport({ pondReport }));
      }
    });
  }
}
