// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * 錦鯉作業記録API
 * 錦鯉作業記録管理サービスで使用するAPI群
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as url from "url";
import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';

const BASE_PATH = "http://localhost".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 2才以上池の記録
 * @export
 * @interface AdultCarpReport
 */
export interface AdultCarpReport {
    /**
     * 
     * @type {number}
     * @memberof AdultCarpReport
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AdultCarpReport
     */
    pondReportId?: number;
    /**
     * 選別の回数
     * @type {number}
     * @memberof AdultCarpReport
     */
    selectionNumber: number;
    /**
     * 
     * @type {string}
     * @memberof AdultCarpReport
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof AdultCarpReport
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof AdultCarpReport
     */
    note: string;
}

/**
 * 鯉の移動記録
 * @export
 * @interface CarpMovingReport
 */
export interface CarpMovingReport {
    /**
     * 
     * @type {number}
     * @memberof CarpMovingReport
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CarpMovingReport
     */
    pondReportId?: number;
    /**
     * 移動記録を作成するときに対象の記録IDを送付します
     * @type {number}
     * @memberof CarpMovingReport
     */
    pairPondReportId?: number;
    /**
     * 
     * @type {number}
     * @memberof CarpMovingReport
     */
    movedId: number | null;
    /**
     * 
     * @type {number}
     * @memberof CarpMovingReport
     */
    moverId: number | null;
    /**
     * 基準となる選別の回数
     * @type {number}
     * @memberof CarpMovingReport
     */
    selectionNumber: number;
    /**
     * 移動記録の並び順
     * @type {number}
     * @memberof CarpMovingReport
     */
    subNumber: number;
    /**
     * 
     * @type {string}
     * @memberof CarpMovingReport
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof CarpMovingReport
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof CarpMovingReport
     */
    note: string;
}

/**
 * 親鯉の組み合わせ
 * @export
 * @interface CarpPairing
 */
export interface CarpPairing {
    /**
     * 
     * @type {number}
     * @memberof CarpPairing
     */
    id?: number;
    /**
     * 組み合わせ名
     * @type {string}
     * @memberof CarpPairing
     */
    name: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CarpPairing
     */
    imageIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CarpPairing
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof CarpPairing
     */
    isDeleted: boolean;
}

/**
 * 鯉の選別記録
 * @export
 * @interface CarpSelectionReport
 */
export interface CarpSelectionReport {
    /**
     * 
     * @type {number}
     * @memberof CarpSelectionReport
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CarpSelectionReport
     */
    pondReportId?: number;
    /**
     * 
     * @type {number}
     * @memberof CarpSelectionReport
     */
    carpSizeTypeId: number | null;
    /**
     * 
     * @type {number}
     * @memberof CarpSelectionReport
     */
    carpQualityTypeId: number | null;
    /**
     * 
     * @type {number}
     * @memberof CarpSelectionReport
     */
    malformationTypeId: number | null;
    /**
     * 
     * @type {number}
     * @memberof CarpSelectionReport
     */
    carpRatioTypeId: number | null;
    /**
     * 
     * @type {number}
     * @memberof CarpSelectionReport
     */
    carpScore: number | null;
    /**
     * 選別の回数
     * @type {number}
     * @memberof CarpSelectionReport
     */
    selectionNumber: number;
    /**
     * 
     * @type {string}
     * @memberof CarpSelectionReport
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof CarpSelectionReport
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof CarpSelectionReport
     */
    note: string;
}

/**
 * 鯉の品種
 * @export
 * @interface CarpVarietyType
 */
export interface CarpVarietyType {
    /**
     * 
     * @type {number}
     * @memberof CarpVarietyType
     */
    id: number;
    /**
     * 品種。紅白・昭和三色・大正三色
     * @type {string}
     * @memberof CarpVarietyType
     */
    name: string;
    /**
     * 放鯉後・第1選別前までの期限
     * @type {number}
     * @memberof CarpVarietyType
     */
    warningDays1: number | null;
    /**
     * 第1選別以降の次の選別までの期限
     * @type {number}
     * @memberof CarpVarietyType
     */
    warningDays2: number | null;
    /**
     * 放鯉後・第一選別前までの期限
     * @type {number}
     * @memberof CarpVarietyType
     */
    alertDays1: number | null;
    /**
     * 第1選別以降の次の選別までの期限
     * @type {number}
     * @memberof CarpVarietyType
     */
    alertDays2: number | null;
    /**
     * 
     * @type {number}
     * @memberof CarpVarietyType
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof CarpVarietyType
     */
    isDeleted: boolean;
}

/**
 * 環境記録
 * @export
 * @interface EnvironmentReport
 */
export interface EnvironmentReport {
    /**
     * 
     * @type {number}
     * @memberof EnvironmentReport
     */
    id?: number;
    /**
     * 担当者ID
     * @type {number}
     * @memberof EnvironmentReport
     */
    pondId: number;
    /**
     * 作業日
     * @type {string}
     * @memberof EnvironmentReport
     */
    date: string;
    /**
     * 水温
     * @type {number}
     * @memberof EnvironmentReport
     */
    value: number;
}

/**
 * 餌
 * @export
 * @interface Feed
 */
export interface Feed {
    /**
     * 
     * @type {number}
     * @memberof Feed
     */
    id?: number;
    /**
     * 餌
     * @type {string}
     * @memberof Feed
     */
    name: string;
    /**
     * 池野区分. 0: 稚魚池用, 1: 2才立て以上用
     * @type {number}
     * @memberof Feed
     */
    pondClassId: number;
    /**
     * 
     * @type {number}
     * @memberof Feed
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof Feed
     */
    isDeleted: boolean;
}

/**
 * 餌記録の理由・原因
 * @export
 * @interface FeedReasonType
 */
export interface FeedReasonType {
    /**
     * 
     * @type {number}
     * @memberof FeedReasonType
     */
    id: number;
    /**
     * 原因。通常・カラムナリス病・口腐れ
     * @type {string}
     * @memberof FeedReasonType
     */
    name: string;
    /**
     * 池区分. 0: 指定なし, 1: 稚魚, 2: 2才立て以上
     * @type {number}
     * @memberof FeedReasonType
     */
    pondClassId: number;
    /**
     * 
     * @type {number}
     * @memberof FeedReasonType
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof FeedReasonType
     */
    isDeleted: boolean;
}

/**
 * 作業記録_餌記録
 * @export
 * @interface FeedReport
 */
export interface FeedReport {
    /**
     * 
     * @type {number}
     * @memberof FeedReport
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof FeedReport
     */
    feedReasonTypeId: number;
    /**
     * 
     * @type {number}
     * @memberof FeedReport
     */
    feedId: number;
    /**
     * 
     * @type {number}
     * @memberof FeedReport
     */
    useMethodTypeId: number;
    /**
     * 回数。5, 6, 8, 12等
     * @type {number}
     * @memberof FeedReport
     */
    count: number;
    /**
     * 秒数
     * @type {number}
     * @memberof FeedReport
     */
    amount: number;
}

/**
 * 肥料
 * @export
 * @interface Fertilizer
 */
export interface Fertilizer {
    /**
     * 
     * @type {number}
     * @memberof Fertilizer
     */
    id?: number;
    /**
     * 肥料名
     * @type {string}
     * @memberof Fertilizer
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Fertilizer
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof Fertilizer
     */
    isDeleted: boolean;
}

/**
 * 作業記録_肥料記録
 * @export
 * @interface FertilizerReport
 */
export interface FertilizerReport {
    /**
     * 
     * @type {number}
     * @memberof FertilizerReport
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof FertilizerReport
     */
    fertilizerId: number;
    /**
     * 
     * @type {number}
     * @memberof FertilizerReport
     */
    useMethodTypeId: number;
    /**
     * 量
     * @type {number}
     * @memberof FertilizerReport
     */
    amount: number;
}

/**
 * 除草剤
 * @export
 * @interface Herbicide
 */
export interface Herbicide {
    /**
     * 
     * @type {number}
     * @memberof Herbicide
     */
    id?: number;
    /**
     * 除草剤名
     * @type {string}
     * @memberof Herbicide
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Herbicide
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof Herbicide
     */
    isDeleted: boolean;
}

/**
 * 作業記録_除草剤記録
 * @export
 * @interface HerbicideReport
 */
export interface HerbicideReport {
    /**
     * 
     * @type {number}
     * @memberof HerbicideReport
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof HerbicideReport
     */
    herbicideId: number;
    /**
     * 
     * @type {number}
     * @memberof HerbicideReport
     */
    useMethodTypeId: number;
    /**
     * 量
     * @type {number}
     * @memberof HerbicideReport
     */
    amount: number;
}

/**
 * 画像
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * 
     * @type {number}
     * @memberof Image
     */
    id?: number;
    /**
     * 画像取得用のユニークキー
     * @type {string}
     * @memberof Image
     */
    fileName: string;
    /**
     * アップロード時のファイル名
     * @type {string}
     * @memberof Image
     */
    originalFileName: string;
    /**
     * アップロードするファイルのMIMEType
     * @type {string}
     * @memberof Image
     */
    contentType: string;
    /**
     * アップロード完了済みフラグ
     * @type {boolean}
     * @memberof Image
     */
    activated: boolean;
    /**
     * リダイレクト先URL
     * @type {string}
     * @memberof Image
     */
    redirectURL?: string;
}

/**
 * ログイン
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    accountName: string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    password: string;
}

/**
 * 親鯉
 * @export
 * @interface ParentCarp
 */
export interface ParentCarp {
    /**
     * 
     * @type {number}
     * @memberof ParentCarp
     */
    id?: number;
    /**
     * 鯉名
     * @type {string}
     * @memberof ParentCarp
     */
    name: string;
    /**
     * 年度
     * @type {number}
     * @memberof ParentCarp
     */
    year: number;
    /**
     * 
     * @type {number}
     * @memberof ParentCarp
     */
    carpVarietyTypeId: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof ParentCarp
     */
    carpPairings: Array<any>;
    /**
     * 
     * @type {number}
     * @memberof ParentCarp
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParentCarp
     */
    isDeleted: boolean;
}

/**
 * 野池
 * @export
 * @interface Pond
 */
export interface Pond {
    /**
     * 
     * @type {number}
     * @memberof Pond
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Pond
     */
    pondAreaId: number | null;
    /**
     * 
     * @type {number}
     * @memberof Pond
     */
    pondTypeId: number | null;
    /**
     * 担当者Ids
     * @type {Array<number>}
     * @memberof Pond
     */
    userIds: Array<number>;
    /**
     * 所有者Ids
     * @type {Array<number>}
     * @memberof Pond
     */
    pondOwnerTypeIds: Array<number>;
    /**
     * 野池名
     * @type {string}
     * @memberof Pond
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Pond
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof Pond
     */
    isDeleted: boolean;
    /**
     * 未使用フラグ
     * @type {boolean}
     * @memberof Pond
     */
    isUnused: boolean;
}

/**
 * エリア
 * @export
 * @interface PondArea
 */
export interface PondArea {
    /**
     * 
     * @type {number}
     * @memberof PondArea
     */
    id?: number;
    /**
     * エリア名
     * @type {string}
     * @memberof PondArea
     */
    name: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PondArea
     */
    pondIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof PondArea
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof PondArea
     */
    isDeleted: boolean;
}

/**
 * 池の塩素記録
 * @export
 * @interface PondDisinfectionReport
 */
export interface PondDisinfectionReport {
    /**
     * 
     * @type {number}
     * @memberof PondDisinfectionReport
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof PondDisinfectionReport
     */
    pondReportId?: number;
    /**
     * 
     * @type {string}
     * @memberof PondDisinfectionReport
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof PondDisinfectionReport
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof PondDisinfectionReport
     */
    note: string;
}

/**
 * 池の所有者
 * @export
 * @interface PondOwnerType
 */
export interface PondOwnerType {
    /**
     * 
     * @type {number}
     * @memberof PondOwnerType
     */
    id: number;
    /**
     * 池の所有者。会社所有、顧客所有等
     * @type {string}
     * @memberof PondOwnerType
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof PondOwnerType
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof PondOwnerType
     */
    isDeleted: boolean;
}

/**
 * 池の状態記録
 * @export
 * @interface PondReport
 */
export interface PondReport {
    /**
     * 
     * @type {number}
     * @memberof PondReport
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof PondReport
     */
    pondId: number;
    /**
     * 
     * @type {number}
     * @memberof PondReport
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof PondReport
     */
    pondTypeId: number;
    /**
     * 
     * @type {number}
     * @memberof PondReport
     */
    parentCarpId: number;
    /**
     * 
     * @type {number}
     * @memberof PondReport
     */
    carpPairingId: number;
    /**
     * 年度
     * @type {number}
     * @memberof PondReport
     */
    fiscalYear: number;
    /**
     * 選別完了フラグ
     * @type {boolean}
     * @memberof PondReport
     */
    isCompleted: boolean;
    /**
     * 年度の記録の連番
     * @type {number}
     * @memberof PondReport
     */
    subNumber: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof PondReport
     */
    carpMovingReports: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof PondReport
     */
    carpSelectionReports: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof PondReport
     */
    pondDisinfectionReports: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof PondReport
     */
    adultCarpReports: Array<any>;
}

/**
 * 池の区分
 * @export
 * @interface PondType
 */
export interface PondType {
    /**
     * 
     * @type {number}
     * @memberof PondType
     */
    id: number;
    /**
     * 池の区分。稚魚池、2才立て、3才立て、4才立て、5才立て以上等
     * @type {string}
     * @memberof PondType
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof PondType
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof PondType
     */
    isDeleted: boolean;
}

/**
 * 尺度
 * @export
 * @interface ScaleType
 */
export interface ScaleType {
    /**
     * 
     * @type {number}
     * @memberof ScaleType
     */
    id: number;
    /**
     * サイズ: 小,中,大,特大. 品質: ✕,△,◯,◎. 奇形: 無,少,多. 数: 少,適正,多
     * @type {string}
     * @memberof ScaleType
     */
    name: string;
    /**
     * サイズ: carpSize. 品質: carpQuality. 奇形: malformation. 数: carpRatio
     * @type {string}
     * @memberof ScaleType
     */
    groupName: string;
    /**
     * 
     * @type {number}
     * @memberof ScaleType
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof ScaleType
     */
    isDeleted: boolean;
}

/**
 * 記録の作業種別
 * @export
 * @interface UseMethodType
 */
export interface UseMethodType {
    /**
     * 
     * @type {number}
     * @memberof UseMethodType
     */
    id: number;
    /**
     * 作業種別。給餌器, バケツ, 袋
     * @type {string}
     * @memberof UseMethodType
     */
    name: string;
    /**
     * 単位. 秒, 割, 袋
     * @type {string}
     * @memberof UseMethodType
     */
    unit: string;
    /**
     * 種別の分類キー. feeder, bucket, bag
     * @type {string}
     * @memberof UseMethodType
     */
    groupName: string;
    /**
     * 対象の記録を管理. 1: 餌記録. 2: 肥料記録. 3: 除草剤記録
     * @type {number}
     * @memberof UseMethodType
     */
    reportGroup: number;
    /**
     * 対象の池を管理. 0: 両方. 1: 稚魚池. 2: 2才以上池
     * @type {number}
     * @memberof UseMethodType
     */
    pondClassId: number;
    /**
     * 
     * @type {number}
     * @memberof UseMethodType
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof UseMethodType
     */
    isDeleted: boolean;
}

/**
 * 作業記録
 * @export
 * @interface WorkReport
 */
export interface WorkReport {
    /**
     * 
     * @type {number}
     * @memberof WorkReport
     */
    id?: number;
    /**
     * 担当者ID
     * @type {number}
     * @memberof WorkReport
     */
    userId: number;
    /**
     * 担当者ID
     * @type {number}
     * @memberof WorkReport
     */
    pondId: number;
    /**
     * 池区分ID
     * @type {number}
     * @memberof WorkReport
     */
    pondTypeId: number;
    /**
     * 作業日
     * @type {string}
     * @memberof WorkReport
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof WorkReport
     */
    note: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof WorkReport
     */
    imageIds: Array<number>;
    /**
     * 
     * @type {Array<any>}
     * @memberof WorkReport
     */
    feedReports: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof WorkReport
     */
    fertilizerReports: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof WorkReport
     */
    herbicideReports: Array<any>;
}

/**
 * 担当者
 * @export
 * @interface Worker
 */
export interface Worker {
    /**
     * 
     * @type {number}
     * @memberof Worker
     */
    id?: number;
    /**
     * 担当者名
     * @type {string}
     * @memberof Worker
     */
    name: string;
    /**
     * ログイン名
     * @type {string}
     * @memberof Worker
     */
    accountName: string;
    /**
     * パスワード。POST, PUT時のみ付与。GETでは付与されない項目
     * @type {string}
     * @memberof Worker
     */
    password?: string;
    /**
     * 
     * @type {number}
     * @memberof Worker
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof Worker
     */
    isDeleted: boolean;
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ログイン
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(login: Login, options: any = {}): RequestArgs {
            // verify required parameter 'login' is not null or undefined
            if (login === null || login === undefined) {
                throw new RequiredError('login','Required parameter login was null or undefined when calling login.');
            }
            const localVarPath = `/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Login" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(login || {}) : (login || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログアウト
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(body?: any, options: any = {}): RequestArgs {
            const localVarPath = `/logout`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"any" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * ログイン
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(login: Login, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = AuthApiAxiosParamCreator(configuration).login(login, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * ログアウト
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(body?: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = AuthApiAxiosParamCreator(configuration).logout(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * ログイン
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(login: Login, options?: any) {
            return AuthApiFp(configuration).login(login, options)(axios, basePath);
        },
        /**
         * ログアウト
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(body?: any, options?: any) {
            return AuthApiFp(configuration).logout(body, options)(axios, basePath);
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * ログイン
     * @param {Login} login 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(login: Login, options?: any) {
        return AuthApiFp(this.configuration).login(login, options)(this.axios, this.basePath);
    }

    /**
     * ログアウト
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public logout(body?: any, options?: any) {
        return AuthApiFp(this.configuration).logout(body, options)(this.axios, this.basePath);
    }

}

/**
 * CarpVarietyTypesApi - axios parameter creator
 * @export
 */
export const CarpVarietyTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 鯉の品種一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarpVarietyTypes(options: any = {}): RequestArgs {
            const localVarPath = `/carpVarietyTypes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CarpVarietyTypesApi - functional programming interface
 * @export
 */
export const CarpVarietyTypesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 鯉の品種一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarpVarietyTypes(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CarpVarietyType>> {
            const localVarAxiosArgs = CarpVarietyTypesApiAxiosParamCreator(configuration).getCarpVarietyTypes(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * CarpVarietyTypesApi - factory interface
 * @export
 */
export const CarpVarietyTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 鯉の品種一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarpVarietyTypes(options?: any) {
            return CarpVarietyTypesApiFp(configuration).getCarpVarietyTypes(options)(axios, basePath);
        },
    };
};

/**
 * CarpVarietyTypesApi - object-oriented interface
 * @export
 * @class CarpVarietyTypesApi
 * @extends {BaseAPI}
 */
export class CarpVarietyTypesApi extends BaseAPI {
    /**
     * 鯉の品種一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarpVarietyTypesApi
     */
    public getCarpVarietyTypes(options?: any) {
        return CarpVarietyTypesApiFp(this.configuration).getCarpVarietyTypes(options)(this.axios, this.basePath);
    }

}

/**
 * EnvironmentReportsApi - axios parameter creator
 * @export
 */
export const EnvironmentReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 環境記録の削除
         * @param {number} id 環境記録Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnvironmentReport(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteEnvironmentReport.');
            }
            const localVarPath = `/environmentReports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 環境記録の取得
         * @param {number} id 環境記録Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvironmentReport(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getEnvironmentReport.');
            }
            const localVarPath = `/environmentReports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 環境記録一覧の取得
         * @param {string} [startDate] 開始日
         * @param {string} [endDate] 終了日
         * @param {number} [pondId] 池ID
         * @param {number} [limit] 上限数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvironmentReports(startDate?: string, endDate?: string, pondId?: number, limit?: number, options: any = {}): RequestArgs {
            const localVarPath = `/environmentReports`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (pondId !== undefined) {
                localVarQueryParameter['pondId'] = pondId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 環境記録の登録
         * @param {EnvironmentReport} environmentReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEnvironmentReport(environmentReport: EnvironmentReport, options: any = {}): RequestArgs {
            // verify required parameter 'environmentReport' is not null or undefined
            if (environmentReport === null || environmentReport === undefined) {
                throw new RequiredError('environmentReport','Required parameter environmentReport was null or undefined when calling postEnvironmentReport.');
            }
            const localVarPath = `/environmentReports`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EnvironmentReport" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(environmentReport || {}) : (environmentReport || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 環境記録の更新
         * @param {number} id 環境記録Id
         * @param {EnvironmentReport} environmentReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEnvironmentReport(id: number, environmentReport: EnvironmentReport, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putEnvironmentReport.');
            }
            // verify required parameter 'environmentReport' is not null or undefined
            if (environmentReport === null || environmentReport === undefined) {
                throw new RequiredError('environmentReport','Required parameter environmentReport was null or undefined when calling putEnvironmentReport.');
            }
            const localVarPath = `/environmentReports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EnvironmentReport" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(environmentReport || {}) : (environmentReport || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnvironmentReportsApi - functional programming interface
 * @export
 */
export const EnvironmentReportsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 環境記録の削除
         * @param {number} id 環境記録Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnvironmentReport(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = EnvironmentReportsApiAxiosParamCreator(configuration).deleteEnvironmentReport(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 環境記録の取得
         * @param {number} id 環境記録Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvironmentReport(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvironmentReport> {
            const localVarAxiosArgs = EnvironmentReportsApiAxiosParamCreator(configuration).getEnvironmentReport(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 環境記録一覧の取得
         * @param {string} [startDate] 開始日
         * @param {string} [endDate] 終了日
         * @param {number} [pondId] 池ID
         * @param {number} [limit] 上限数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvironmentReports(startDate?: string, endDate?: string, pondId?: number, limit?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnvironmentReport>> {
            const localVarAxiosArgs = EnvironmentReportsApiAxiosParamCreator(configuration).getEnvironmentReports(startDate, endDate, pondId, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 環境記録の登録
         * @param {EnvironmentReport} environmentReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEnvironmentReport(environmentReport: EnvironmentReport, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvironmentReport> {
            const localVarAxiosArgs = EnvironmentReportsApiAxiosParamCreator(configuration).postEnvironmentReport(environmentReport, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 環境記録の更新
         * @param {number} id 環境記録Id
         * @param {EnvironmentReport} environmentReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEnvironmentReport(id: number, environmentReport: EnvironmentReport, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvironmentReport> {
            const localVarAxiosArgs = EnvironmentReportsApiAxiosParamCreator(configuration).putEnvironmentReport(id, environmentReport, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * EnvironmentReportsApi - factory interface
 * @export
 */
export const EnvironmentReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary 環境記録の削除
         * @param {number} id 環境記録Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnvironmentReport(id: number, options?: any) {
            return EnvironmentReportsApiFp(configuration).deleteEnvironmentReport(id, options)(axios, basePath);
        },
        /**
         * 環境記録の取得
         * @param {number} id 環境記録Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvironmentReport(id: number, options?: any) {
            return EnvironmentReportsApiFp(configuration).getEnvironmentReport(id, options)(axios, basePath);
        },
        /**
         * 環境記録一覧の取得
         * @param {string} [startDate] 開始日
         * @param {string} [endDate] 終了日
         * @param {number} [pondId] 池ID
         * @param {number} [limit] 上限数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvironmentReports(startDate?: string, endDate?: string, pondId?: number, limit?: number, options?: any) {
            return EnvironmentReportsApiFp(configuration).getEnvironmentReports(startDate, endDate, pondId, limit, options)(axios, basePath);
        },
        /**
         * 環境記録の登録
         * @param {EnvironmentReport} environmentReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEnvironmentReport(environmentReport: EnvironmentReport, options?: any) {
            return EnvironmentReportsApiFp(configuration).postEnvironmentReport(environmentReport, options)(axios, basePath);
        },
        /**
         * 環境記録の更新
         * @param {number} id 環境記録Id
         * @param {EnvironmentReport} environmentReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEnvironmentReport(id: number, environmentReport: EnvironmentReport, options?: any) {
            return EnvironmentReportsApiFp(configuration).putEnvironmentReport(id, environmentReport, options)(axios, basePath);
        },
    };
};

/**
 * EnvironmentReportsApi - object-oriented interface
 * @export
 * @class EnvironmentReportsApi
 * @extends {BaseAPI}
 */
export class EnvironmentReportsApi extends BaseAPI {
    /**
     * 
     * @summary 環境記録の削除
     * @param {number} id 環境記録Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentReportsApi
     */
    public deleteEnvironmentReport(id: number, options?: any) {
        return EnvironmentReportsApiFp(this.configuration).deleteEnvironmentReport(id, options)(this.axios, this.basePath);
    }

    /**
     * 環境記録の取得
     * @param {number} id 環境記録Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentReportsApi
     */
    public getEnvironmentReport(id: number, options?: any) {
        return EnvironmentReportsApiFp(this.configuration).getEnvironmentReport(id, options)(this.axios, this.basePath);
    }

    /**
     * 環境記録一覧の取得
     * @param {string} [startDate] 開始日
     * @param {string} [endDate] 終了日
     * @param {number} [pondId] 池ID
     * @param {number} [limit] 上限数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentReportsApi
     */
    public getEnvironmentReports(startDate?: string, endDate?: string, pondId?: number, limit?: number, options?: any) {
        return EnvironmentReportsApiFp(this.configuration).getEnvironmentReports(startDate, endDate, pondId, limit, options)(this.axios, this.basePath);
    }

    /**
     * 環境記録の登録
     * @param {EnvironmentReport} environmentReport 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentReportsApi
     */
    public postEnvironmentReport(environmentReport: EnvironmentReport, options?: any) {
        return EnvironmentReportsApiFp(this.configuration).postEnvironmentReport(environmentReport, options)(this.axios, this.basePath);
    }

    /**
     * 環境記録の更新
     * @param {number} id 環境記録Id
     * @param {EnvironmentReport} environmentReport 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentReportsApi
     */
    public putEnvironmentReport(id: number, environmentReport: EnvironmentReport, options?: any) {
        return EnvironmentReportsApiFp(this.configuration).putEnvironmentReport(id, environmentReport, options)(this.axios, this.basePath);
    }

}

/**
 * FeedReasonTypesApi - axios parameter creator
 * @export
 */
export const FeedReasonTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 餌記録の理由/原因一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedReasonTypes(options: any = {}): RequestArgs {
            const localVarPath = `/feedReasonTypes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedReasonTypesApi - functional programming interface
 * @export
 */
export const FeedReasonTypesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 餌記録の理由/原因一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedReasonTypes(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeedReasonType>> {
            const localVarAxiosArgs = FeedReasonTypesApiAxiosParamCreator(configuration).getFeedReasonTypes(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * FeedReasonTypesApi - factory interface
 * @export
 */
export const FeedReasonTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 餌記録の理由/原因一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedReasonTypes(options?: any) {
            return FeedReasonTypesApiFp(configuration).getFeedReasonTypes(options)(axios, basePath);
        },
    };
};

/**
 * FeedReasonTypesApi - object-oriented interface
 * @export
 * @class FeedReasonTypesApi
 * @extends {BaseAPI}
 */
export class FeedReasonTypesApi extends BaseAPI {
    /**
     * 餌記録の理由/原因一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedReasonTypesApi
     */
    public getFeedReasonTypes(options?: any) {
        return FeedReasonTypesApiFp(this.configuration).getFeedReasonTypes(options)(this.axios, this.basePath);
    }

}

/**
 * FeedsApi - axios parameter creator
 * @export
 */
export const FeedsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 餌の削除
         * @param {number} id 餌Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeed(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteFeed.');
            }
            const localVarPath = `/feeds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 餌の取得
         * @param {number} id 餌Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeed(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getFeed.');
            }
            const localVarPath = `/feeds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 餌一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeeds(options: any = {}): RequestArgs {
            const localVarPath = `/feeds`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 餌の登録
         * @param {Feed} feed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFeed(feed: Feed, options: any = {}): RequestArgs {
            // verify required parameter 'feed' is not null or undefined
            if (feed === null || feed === undefined) {
                throw new RequiredError('feed','Required parameter feed was null or undefined when calling postFeed.');
            }
            const localVarPath = `/feeds`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Feed" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(feed || {}) : (feed || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 餌の更新
         * @param {number} id 餌Id
         * @param {Feed} feed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFeed(id: number, feed: Feed, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putFeed.');
            }
            // verify required parameter 'feed' is not null or undefined
            if (feed === null || feed === undefined) {
                throw new RequiredError('feed','Required parameter feed was null or undefined when calling putFeed.');
            }
            const localVarPath = `/feeds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Feed" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(feed || {}) : (feed || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedsApi - functional programming interface
 * @export
 */
export const FeedsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 餌の削除
         * @param {number} id 餌Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeed(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feed> {
            const localVarAxiosArgs = FeedsApiAxiosParamCreator(configuration).deleteFeed(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 餌の取得
         * @param {number} id 餌Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeed(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feed> {
            const localVarAxiosArgs = FeedsApiAxiosParamCreator(configuration).getFeed(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 餌一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeeds(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Feed>> {
            const localVarAxiosArgs = FeedsApiAxiosParamCreator(configuration).getFeeds(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 餌の登録
         * @param {Feed} feed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFeed(feed: Feed, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feed> {
            const localVarAxiosArgs = FeedsApiAxiosParamCreator(configuration).postFeed(feed, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 餌の更新
         * @param {number} id 餌Id
         * @param {Feed} feed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFeed(id: number, feed: Feed, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feed> {
            const localVarAxiosArgs = FeedsApiAxiosParamCreator(configuration).putFeed(id, feed, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * FeedsApi - factory interface
 * @export
 */
export const FeedsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary 餌の削除
         * @param {number} id 餌Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeed(id: number, options?: any) {
            return FeedsApiFp(configuration).deleteFeed(id, options)(axios, basePath);
        },
        /**
         * 餌の取得
         * @param {number} id 餌Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeed(id: number, options?: any) {
            return FeedsApiFp(configuration).getFeed(id, options)(axios, basePath);
        },
        /**
         * 餌一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeeds(options?: any) {
            return FeedsApiFp(configuration).getFeeds(options)(axios, basePath);
        },
        /**
         * 餌の登録
         * @param {Feed} feed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFeed(feed: Feed, options?: any) {
            return FeedsApiFp(configuration).postFeed(feed, options)(axios, basePath);
        },
        /**
         * 餌の更新
         * @param {number} id 餌Id
         * @param {Feed} feed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFeed(id: number, feed: Feed, options?: any) {
            return FeedsApiFp(configuration).putFeed(id, feed, options)(axios, basePath);
        },
    };
};

/**
 * FeedsApi - object-oriented interface
 * @export
 * @class FeedsApi
 * @extends {BaseAPI}
 */
export class FeedsApi extends BaseAPI {
    /**
     * 
     * @summary 餌の削除
     * @param {number} id 餌Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public deleteFeed(id: number, options?: any) {
        return FeedsApiFp(this.configuration).deleteFeed(id, options)(this.axios, this.basePath);
    }

    /**
     * 餌の取得
     * @param {number} id 餌Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public getFeed(id: number, options?: any) {
        return FeedsApiFp(this.configuration).getFeed(id, options)(this.axios, this.basePath);
    }

    /**
     * 餌一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public getFeeds(options?: any) {
        return FeedsApiFp(this.configuration).getFeeds(options)(this.axios, this.basePath);
    }

    /**
     * 餌の登録
     * @param {Feed} feed 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public postFeed(feed: Feed, options?: any) {
        return FeedsApiFp(this.configuration).postFeed(feed, options)(this.axios, this.basePath);
    }

    /**
     * 餌の更新
     * @param {number} id 餌Id
     * @param {Feed} feed 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public putFeed(id: number, feed: Feed, options?: any) {
        return FeedsApiFp(this.configuration).putFeed(id, feed, options)(this.axios, this.basePath);
    }

}

/**
 * FertilizersApi - axios parameter creator
 * @export
 */
export const FertilizersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 肥料の削除
         * @param {number} id 肥料Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFertilizer(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteFertilizer.');
            }
            const localVarPath = `/fertilizers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 肥料の取得
         * @param {number} id 肥料Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFertilizer(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getFertilizer.');
            }
            const localVarPath = `/fertilizers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 肥料一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFertilizers(options: any = {}): RequestArgs {
            const localVarPath = `/fertilizers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 肥料の登録
         * @param {Fertilizer} fertilizer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFertilizer(fertilizer: Fertilizer, options: any = {}): RequestArgs {
            // verify required parameter 'fertilizer' is not null or undefined
            if (fertilizer === null || fertilizer === undefined) {
                throw new RequiredError('fertilizer','Required parameter fertilizer was null or undefined when calling postFertilizer.');
            }
            const localVarPath = `/fertilizers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Fertilizer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(fertilizer || {}) : (fertilizer || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 肥料の更新
         * @param {number} id 肥料Id
         * @param {Fertilizer} fertilizer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFertilizer(id: number, fertilizer: Fertilizer, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putFertilizer.');
            }
            // verify required parameter 'fertilizer' is not null or undefined
            if (fertilizer === null || fertilizer === undefined) {
                throw new RequiredError('fertilizer','Required parameter fertilizer was null or undefined when calling putFertilizer.');
            }
            const localVarPath = `/fertilizers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Fertilizer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(fertilizer || {}) : (fertilizer || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FertilizersApi - functional programming interface
 * @export
 */
export const FertilizersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 肥料の削除
         * @param {number} id 肥料Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFertilizer(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Fertilizer> {
            const localVarAxiosArgs = FertilizersApiAxiosParamCreator(configuration).deleteFertilizer(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 肥料の取得
         * @param {number} id 肥料Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFertilizer(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Fertilizer> {
            const localVarAxiosArgs = FertilizersApiAxiosParamCreator(configuration).getFertilizer(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 肥料一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFertilizers(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Fertilizer>> {
            const localVarAxiosArgs = FertilizersApiAxiosParamCreator(configuration).getFertilizers(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 肥料の登録
         * @param {Fertilizer} fertilizer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFertilizer(fertilizer: Fertilizer, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Fertilizer> {
            const localVarAxiosArgs = FertilizersApiAxiosParamCreator(configuration).postFertilizer(fertilizer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 肥料の更新
         * @param {number} id 肥料Id
         * @param {Fertilizer} fertilizer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFertilizer(id: number, fertilizer: Fertilizer, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Fertilizer> {
            const localVarAxiosArgs = FertilizersApiAxiosParamCreator(configuration).putFertilizer(id, fertilizer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * FertilizersApi - factory interface
 * @export
 */
export const FertilizersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary 肥料の削除
         * @param {number} id 肥料Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFertilizer(id: number, options?: any) {
            return FertilizersApiFp(configuration).deleteFertilizer(id, options)(axios, basePath);
        },
        /**
         * 肥料の取得
         * @param {number} id 肥料Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFertilizer(id: number, options?: any) {
            return FertilizersApiFp(configuration).getFertilizer(id, options)(axios, basePath);
        },
        /**
         * 肥料一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFertilizers(options?: any) {
            return FertilizersApiFp(configuration).getFertilizers(options)(axios, basePath);
        },
        /**
         * 肥料の登録
         * @param {Fertilizer} fertilizer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFertilizer(fertilizer: Fertilizer, options?: any) {
            return FertilizersApiFp(configuration).postFertilizer(fertilizer, options)(axios, basePath);
        },
        /**
         * 肥料の更新
         * @param {number} id 肥料Id
         * @param {Fertilizer} fertilizer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFertilizer(id: number, fertilizer: Fertilizer, options?: any) {
            return FertilizersApiFp(configuration).putFertilizer(id, fertilizer, options)(axios, basePath);
        },
    };
};

/**
 * FertilizersApi - object-oriented interface
 * @export
 * @class FertilizersApi
 * @extends {BaseAPI}
 */
export class FertilizersApi extends BaseAPI {
    /**
     * 
     * @summary 肥料の削除
     * @param {number} id 肥料Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FertilizersApi
     */
    public deleteFertilizer(id: number, options?: any) {
        return FertilizersApiFp(this.configuration).deleteFertilizer(id, options)(this.axios, this.basePath);
    }

    /**
     * 肥料の取得
     * @param {number} id 肥料Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FertilizersApi
     */
    public getFertilizer(id: number, options?: any) {
        return FertilizersApiFp(this.configuration).getFertilizer(id, options)(this.axios, this.basePath);
    }

    /**
     * 肥料一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FertilizersApi
     */
    public getFertilizers(options?: any) {
        return FertilizersApiFp(this.configuration).getFertilizers(options)(this.axios, this.basePath);
    }

    /**
     * 肥料の登録
     * @param {Fertilizer} fertilizer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FertilizersApi
     */
    public postFertilizer(fertilizer: Fertilizer, options?: any) {
        return FertilizersApiFp(this.configuration).postFertilizer(fertilizer, options)(this.axios, this.basePath);
    }

    /**
     * 肥料の更新
     * @param {number} id 肥料Id
     * @param {Fertilizer} fertilizer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FertilizersApi
     */
    public putFertilizer(id: number, fertilizer: Fertilizer, options?: any) {
        return FertilizersApiFp(this.configuration).putFertilizer(id, fertilizer, options)(this.axios, this.basePath);
    }

}

/**
 * HerbicidesApi - axios parameter creator
 * @export
 */
export const HerbicidesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 除草剤の削除
         * @param {number} id 除草剤Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHerbicide(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteHerbicide.');
            }
            const localVarPath = `/herbicides/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 除草剤の取得
         * @param {number} id 除草剤Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHerbicide(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getHerbicide.');
            }
            const localVarPath = `/herbicides/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 除草剤一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHerbicides(options: any = {}): RequestArgs {
            const localVarPath = `/herbicides`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 除草剤の登録
         * @param {Herbicide} herbicide 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postHerbicide(herbicide: Herbicide, options: any = {}): RequestArgs {
            // verify required parameter 'herbicide' is not null or undefined
            if (herbicide === null || herbicide === undefined) {
                throw new RequiredError('herbicide','Required parameter herbicide was null or undefined when calling postHerbicide.');
            }
            const localVarPath = `/herbicides`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Herbicide" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(herbicide || {}) : (herbicide || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 除草剤の更新
         * @param {number} id 除草剤Id
         * @param {Herbicide} herbicide 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putHerbicide(id: number, herbicide: Herbicide, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putHerbicide.');
            }
            // verify required parameter 'herbicide' is not null or undefined
            if (herbicide === null || herbicide === undefined) {
                throw new RequiredError('herbicide','Required parameter herbicide was null or undefined when calling putHerbicide.');
            }
            const localVarPath = `/herbicides/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Herbicide" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(herbicide || {}) : (herbicide || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HerbicidesApi - functional programming interface
 * @export
 */
export const HerbicidesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 除草剤の削除
         * @param {number} id 除草剤Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHerbicide(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Herbicide> {
            const localVarAxiosArgs = HerbicidesApiAxiosParamCreator(configuration).deleteHerbicide(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 除草剤の取得
         * @param {number} id 除草剤Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHerbicide(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Herbicide> {
            const localVarAxiosArgs = HerbicidesApiAxiosParamCreator(configuration).getHerbicide(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 除草剤一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHerbicides(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Herbicide>> {
            const localVarAxiosArgs = HerbicidesApiAxiosParamCreator(configuration).getHerbicides(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 除草剤の登録
         * @param {Herbicide} herbicide 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postHerbicide(herbicide: Herbicide, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Herbicide> {
            const localVarAxiosArgs = HerbicidesApiAxiosParamCreator(configuration).postHerbicide(herbicide, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 除草剤の更新
         * @param {number} id 除草剤Id
         * @param {Herbicide} herbicide 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putHerbicide(id: number, herbicide: Herbicide, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Herbicide> {
            const localVarAxiosArgs = HerbicidesApiAxiosParamCreator(configuration).putHerbicide(id, herbicide, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * HerbicidesApi - factory interface
 * @export
 */
export const HerbicidesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary 除草剤の削除
         * @param {number} id 除草剤Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHerbicide(id: number, options?: any) {
            return HerbicidesApiFp(configuration).deleteHerbicide(id, options)(axios, basePath);
        },
        /**
         * 除草剤の取得
         * @param {number} id 除草剤Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHerbicide(id: number, options?: any) {
            return HerbicidesApiFp(configuration).getHerbicide(id, options)(axios, basePath);
        },
        /**
         * 除草剤一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHerbicides(options?: any) {
            return HerbicidesApiFp(configuration).getHerbicides(options)(axios, basePath);
        },
        /**
         * 除草剤の登録
         * @param {Herbicide} herbicide 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postHerbicide(herbicide: Herbicide, options?: any) {
            return HerbicidesApiFp(configuration).postHerbicide(herbicide, options)(axios, basePath);
        },
        /**
         * 除草剤の更新
         * @param {number} id 除草剤Id
         * @param {Herbicide} herbicide 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putHerbicide(id: number, herbicide: Herbicide, options?: any) {
            return HerbicidesApiFp(configuration).putHerbicide(id, herbicide, options)(axios, basePath);
        },
    };
};

/**
 * HerbicidesApi - object-oriented interface
 * @export
 * @class HerbicidesApi
 * @extends {BaseAPI}
 */
export class HerbicidesApi extends BaseAPI {
    /**
     * 
     * @summary 除草剤の削除
     * @param {number} id 除草剤Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HerbicidesApi
     */
    public deleteHerbicide(id: number, options?: any) {
        return HerbicidesApiFp(this.configuration).deleteHerbicide(id, options)(this.axios, this.basePath);
    }

    /**
     * 除草剤の取得
     * @param {number} id 除草剤Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HerbicidesApi
     */
    public getHerbicide(id: number, options?: any) {
        return HerbicidesApiFp(this.configuration).getHerbicide(id, options)(this.axios, this.basePath);
    }

    /**
     * 除草剤一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HerbicidesApi
     */
    public getHerbicides(options?: any) {
        return HerbicidesApiFp(this.configuration).getHerbicides(options)(this.axios, this.basePath);
    }

    /**
     * 除草剤の登録
     * @param {Herbicide} herbicide 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HerbicidesApi
     */
    public postHerbicide(herbicide: Herbicide, options?: any) {
        return HerbicidesApiFp(this.configuration).postHerbicide(herbicide, options)(this.axios, this.basePath);
    }

    /**
     * 除草剤の更新
     * @param {number} id 除草剤Id
     * @param {Herbicide} herbicide 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HerbicidesApi
     */
    public putHerbicide(id: number, herbicide: Herbicide, options?: any) {
        return HerbicidesApiFp(this.configuration).putHerbicide(id, herbicide, options)(this.axios, this.basePath);
    }

}

/**
 * ImagesApi - axios parameter creator
 * @export
 */
export const ImagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 画像をDB上に一時的に保存し、画像保蔵用のリダイレクトURLの取得
         * @param {Image} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreateImageURL(image: Image, options: any = {}): RequestArgs {
            // verify required parameter 'image' is not null or undefined
            if (image === null || image === undefined) {
                throw new RequiredError('image','Required parameter image was null or undefined when calling getCreateImageURL.');
            }
            const localVarPath = `/images`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Image" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(image || {}) : (image || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 画像表示用のリダイレクトURLを取得
         * @param {number} id 画像Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageURL(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getImageURL.');
            }
            const localVarPath = `/images/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 画像の有効化
         * @param {number} id 画像Id
         * @param {Image} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putImage(id: number, image: Image, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putImage.');
            }
            // verify required parameter 'image' is not null or undefined
            if (image === null || image === undefined) {
                throw new RequiredError('image','Required parameter image was null or undefined when calling putImage.');
            }
            const localVarPath = `/images/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Image" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(image || {}) : (image || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImagesApi - functional programming interface
 * @export
 */
export const ImagesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 画像をDB上に一時的に保存し、画像保蔵用のリダイレクトURLの取得
         * @param {Image} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreateImageURL(image: Image, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Image> {
            const localVarAxiosArgs = ImagesApiAxiosParamCreator(configuration).getCreateImageURL(image, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 画像表示用のリダイレクトURLを取得
         * @param {number} id 画像Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageURL(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ImagesApiAxiosParamCreator(configuration).getImageURL(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 画像の有効化
         * @param {number} id 画像Id
         * @param {Image} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putImage(id: number, image: Image, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Image> {
            const localVarAxiosArgs = ImagesApiAxiosParamCreator(configuration).putImage(id, image, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * ImagesApi - factory interface
 * @export
 */
export const ImagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 画像をDB上に一時的に保存し、画像保蔵用のリダイレクトURLの取得
         * @param {Image} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreateImageURL(image: Image, options?: any) {
            return ImagesApiFp(configuration).getCreateImageURL(image, options)(axios, basePath);
        },
        /**
         * 画像表示用のリダイレクトURLを取得
         * @param {number} id 画像Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageURL(id: number, options?: any) {
            return ImagesApiFp(configuration).getImageURL(id, options)(axios, basePath);
        },
        /**
         * 画像の有効化
         * @param {number} id 画像Id
         * @param {Image} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putImage(id: number, image: Image, options?: any) {
            return ImagesApiFp(configuration).putImage(id, image, options)(axios, basePath);
        },
    };
};

/**
 * ImagesApi - object-oriented interface
 * @export
 * @class ImagesApi
 * @extends {BaseAPI}
 */
export class ImagesApi extends BaseAPI {
    /**
     * 画像をDB上に一時的に保存し、画像保蔵用のリダイレクトURLの取得
     * @param {Image} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public getCreateImageURL(image: Image, options?: any) {
        return ImagesApiFp(this.configuration).getCreateImageURL(image, options)(this.axios, this.basePath);
    }

    /**
     * 画像表示用のリダイレクトURLを取得
     * @param {number} id 画像Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public getImageURL(id: number, options?: any) {
        return ImagesApiFp(this.configuration).getImageURL(id, options)(this.axios, this.basePath);
    }

    /**
     * 画像の有効化
     * @param {number} id 画像Id
     * @param {Image} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public putImage(id: number, image: Image, options?: any) {
        return ImagesApiFp(this.configuration).putImage(id, image, options)(this.axios, this.basePath);
    }

}

/**
 * ParentCarpsApi - axios parameter creator
 * @export
 */
export const ParentCarpsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 親鯉の削除
         * @param {number} id 親鯉Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParentCarp(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteParentCarp.');
            }
            const localVarPath = `/parentCarps/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 親鯉の取得
         * @param {number} id 親鯉Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentCarp(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getParentCarp.');
            }
            const localVarPath = `/parentCarps/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 親鯉一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentCarps(options: any = {}): RequestArgs {
            const localVarPath = `/parentCarps`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 親鯉の登録
         * @param {ParentCarp} parentCarp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postParentCarp(parentCarp: ParentCarp, options: any = {}): RequestArgs {
            // verify required parameter 'parentCarp' is not null or undefined
            if (parentCarp === null || parentCarp === undefined) {
                throw new RequiredError('parentCarp','Required parameter parentCarp was null or undefined when calling postParentCarp.');
            }
            const localVarPath = `/parentCarps`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ParentCarp" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(parentCarp || {}) : (parentCarp || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 親鯉の更新
         * @param {number} id 親鯉Id
         * @param {ParentCarp} parentCarp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParentCarp(id: number, parentCarp: ParentCarp, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putParentCarp.');
            }
            // verify required parameter 'parentCarp' is not null or undefined
            if (parentCarp === null || parentCarp === undefined) {
                throw new RequiredError('parentCarp','Required parameter parentCarp was null or undefined when calling putParentCarp.');
            }
            const localVarPath = `/parentCarps/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ParentCarp" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(parentCarp || {}) : (parentCarp || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParentCarpsApi - functional programming interface
 * @export
 */
export const ParentCarpsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 親鯉の削除
         * @param {number} id 親鯉Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParentCarp(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParentCarp> {
            const localVarAxiosArgs = ParentCarpsApiAxiosParamCreator(configuration).deleteParentCarp(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 親鯉の取得
         * @param {number} id 親鯉Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentCarp(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParentCarp> {
            const localVarAxiosArgs = ParentCarpsApiAxiosParamCreator(configuration).getParentCarp(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 親鯉一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentCarps(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParentCarp>> {
            const localVarAxiosArgs = ParentCarpsApiAxiosParamCreator(configuration).getParentCarps(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 親鯉の登録
         * @param {ParentCarp} parentCarp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postParentCarp(parentCarp: ParentCarp, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParentCarp> {
            const localVarAxiosArgs = ParentCarpsApiAxiosParamCreator(configuration).postParentCarp(parentCarp, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 親鯉の更新
         * @param {number} id 親鯉Id
         * @param {ParentCarp} parentCarp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParentCarp(id: number, parentCarp: ParentCarp, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParentCarp> {
            const localVarAxiosArgs = ParentCarpsApiAxiosParamCreator(configuration).putParentCarp(id, parentCarp, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * ParentCarpsApi - factory interface
 * @export
 */
export const ParentCarpsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary 親鯉の削除
         * @param {number} id 親鯉Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParentCarp(id: number, options?: any) {
            return ParentCarpsApiFp(configuration).deleteParentCarp(id, options)(axios, basePath);
        },
        /**
         * 親鯉の取得
         * @param {number} id 親鯉Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentCarp(id: number, options?: any) {
            return ParentCarpsApiFp(configuration).getParentCarp(id, options)(axios, basePath);
        },
        /**
         * 親鯉一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentCarps(options?: any) {
            return ParentCarpsApiFp(configuration).getParentCarps(options)(axios, basePath);
        },
        /**
         * 親鯉の登録
         * @param {ParentCarp} parentCarp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postParentCarp(parentCarp: ParentCarp, options?: any) {
            return ParentCarpsApiFp(configuration).postParentCarp(parentCarp, options)(axios, basePath);
        },
        /**
         * 親鯉の更新
         * @param {number} id 親鯉Id
         * @param {ParentCarp} parentCarp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParentCarp(id: number, parentCarp: ParentCarp, options?: any) {
            return ParentCarpsApiFp(configuration).putParentCarp(id, parentCarp, options)(axios, basePath);
        },
    };
};

/**
 * ParentCarpsApi - object-oriented interface
 * @export
 * @class ParentCarpsApi
 * @extends {BaseAPI}
 */
export class ParentCarpsApi extends BaseAPI {
    /**
     * 
     * @summary 親鯉の削除
     * @param {number} id 親鯉Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentCarpsApi
     */
    public deleteParentCarp(id: number, options?: any) {
        return ParentCarpsApiFp(this.configuration).deleteParentCarp(id, options)(this.axios, this.basePath);
    }

    /**
     * 親鯉の取得
     * @param {number} id 親鯉Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentCarpsApi
     */
    public getParentCarp(id: number, options?: any) {
        return ParentCarpsApiFp(this.configuration).getParentCarp(id, options)(this.axios, this.basePath);
    }

    /**
     * 親鯉一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentCarpsApi
     */
    public getParentCarps(options?: any) {
        return ParentCarpsApiFp(this.configuration).getParentCarps(options)(this.axios, this.basePath);
    }

    /**
     * 親鯉の登録
     * @param {ParentCarp} parentCarp 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentCarpsApi
     */
    public postParentCarp(parentCarp: ParentCarp, options?: any) {
        return ParentCarpsApiFp(this.configuration).postParentCarp(parentCarp, options)(this.axios, this.basePath);
    }

    /**
     * 親鯉の更新
     * @param {number} id 親鯉Id
     * @param {ParentCarp} parentCarp 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentCarpsApi
     */
    public putParentCarp(id: number, parentCarp: ParentCarp, options?: any) {
        return ParentCarpsApiFp(this.configuration).putParentCarp(id, parentCarp, options)(this.axios, this.basePath);
    }

}

/**
 * PondAreasApi - axios parameter creator
 * @export
 */
export const PondAreasApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary エリアの削除
         * @param {number} id エリアId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePondArea(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePondArea.');
            }
            const localVarPath = `/pondAreas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エリアの取得
         * @param {number} id エリアId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPondArea(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPondArea.');
            }
            const localVarPath = `/pondAreas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エリア一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPondAreas(options: any = {}): RequestArgs {
            const localVarPath = `/pondAreas`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エリアの登録
         * @param {PondArea} pondArea 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPondArea(pondArea: PondArea, options: any = {}): RequestArgs {
            // verify required parameter 'pondArea' is not null or undefined
            if (pondArea === null || pondArea === undefined) {
                throw new RequiredError('pondArea','Required parameter pondArea was null or undefined when calling postPondArea.');
            }
            const localVarPath = `/pondAreas`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PondArea" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(pondArea || {}) : (pondArea || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エリアの更新
         * @param {number} id エリアId
         * @param {PondArea} pondArea 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPondArea(id: number, pondArea: PondArea, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putPondArea.');
            }
            // verify required parameter 'pondArea' is not null or undefined
            if (pondArea === null || pondArea === undefined) {
                throw new RequiredError('pondArea','Required parameter pondArea was null or undefined when calling putPondArea.');
            }
            const localVarPath = `/pondAreas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PondArea" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(pondArea || {}) : (pondArea || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PondAreasApi - functional programming interface
 * @export
 */
export const PondAreasApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary エリアの削除
         * @param {number} id エリアId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePondArea(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PondArea> {
            const localVarAxiosArgs = PondAreasApiAxiosParamCreator(configuration).deletePondArea(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * エリアの取得
         * @param {number} id エリアId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPondArea(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PondArea> {
            const localVarAxiosArgs = PondAreasApiAxiosParamCreator(configuration).getPondArea(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * エリア一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPondAreas(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PondArea>> {
            const localVarAxiosArgs = PondAreasApiAxiosParamCreator(configuration).getPondAreas(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * エリアの登録
         * @param {PondArea} pondArea 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPondArea(pondArea: PondArea, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PondArea> {
            const localVarAxiosArgs = PondAreasApiAxiosParamCreator(configuration).postPondArea(pondArea, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * エリアの更新
         * @param {number} id エリアId
         * @param {PondArea} pondArea 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPondArea(id: number, pondArea: PondArea, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PondArea> {
            const localVarAxiosArgs = PondAreasApiAxiosParamCreator(configuration).putPondArea(id, pondArea, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * PondAreasApi - factory interface
 * @export
 */
export const PondAreasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary エリアの削除
         * @param {number} id エリアId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePondArea(id: number, options?: any) {
            return PondAreasApiFp(configuration).deletePondArea(id, options)(axios, basePath);
        },
        /**
         * エリアの取得
         * @param {number} id エリアId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPondArea(id: number, options?: any) {
            return PondAreasApiFp(configuration).getPondArea(id, options)(axios, basePath);
        },
        /**
         * エリア一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPondAreas(options?: any) {
            return PondAreasApiFp(configuration).getPondAreas(options)(axios, basePath);
        },
        /**
         * エリアの登録
         * @param {PondArea} pondArea 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPondArea(pondArea: PondArea, options?: any) {
            return PondAreasApiFp(configuration).postPondArea(pondArea, options)(axios, basePath);
        },
        /**
         * エリアの更新
         * @param {number} id エリアId
         * @param {PondArea} pondArea 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPondArea(id: number, pondArea: PondArea, options?: any) {
            return PondAreasApiFp(configuration).putPondArea(id, pondArea, options)(axios, basePath);
        },
    };
};

/**
 * PondAreasApi - object-oriented interface
 * @export
 * @class PondAreasApi
 * @extends {BaseAPI}
 */
export class PondAreasApi extends BaseAPI {
    /**
     * 
     * @summary エリアの削除
     * @param {number} id エリアId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PondAreasApi
     */
    public deletePondArea(id: number, options?: any) {
        return PondAreasApiFp(this.configuration).deletePondArea(id, options)(this.axios, this.basePath);
    }

    /**
     * エリアの取得
     * @param {number} id エリアId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PondAreasApi
     */
    public getPondArea(id: number, options?: any) {
        return PondAreasApiFp(this.configuration).getPondArea(id, options)(this.axios, this.basePath);
    }

    /**
     * エリア一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PondAreasApi
     */
    public getPondAreas(options?: any) {
        return PondAreasApiFp(this.configuration).getPondAreas(options)(this.axios, this.basePath);
    }

    /**
     * エリアの登録
     * @param {PondArea} pondArea 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PondAreasApi
     */
    public postPondArea(pondArea: PondArea, options?: any) {
        return PondAreasApiFp(this.configuration).postPondArea(pondArea, options)(this.axios, this.basePath);
    }

    /**
     * エリアの更新
     * @param {number} id エリアId
     * @param {PondArea} pondArea 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PondAreasApi
     */
    public putPondArea(id: number, pondArea: PondArea, options?: any) {
        return PondAreasApiFp(this.configuration).putPondArea(id, pondArea, options)(this.axios, this.basePath);
    }

}

/**
 * PondOwnerTypesApi - axios parameter creator
 * @export
 */
export const PondOwnerTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 池の所有者一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPondOwnerTypes(options: any = {}): RequestArgs {
            const localVarPath = `/pondOwnerTypes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PondOwnerTypesApi - functional programming interface
 * @export
 */
export const PondOwnerTypesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 池の所有者一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPondOwnerTypes(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PondOwnerType>> {
            const localVarAxiosArgs = PondOwnerTypesApiAxiosParamCreator(configuration).getPondOwnerTypes(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * PondOwnerTypesApi - factory interface
 * @export
 */
export const PondOwnerTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 池の所有者一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPondOwnerTypes(options?: any) {
            return PondOwnerTypesApiFp(configuration).getPondOwnerTypes(options)(axios, basePath);
        },
    };
};

/**
 * PondOwnerTypesApi - object-oriented interface
 * @export
 * @class PondOwnerTypesApi
 * @extends {BaseAPI}
 */
export class PondOwnerTypesApi extends BaseAPI {
    /**
     * 池の所有者一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PondOwnerTypesApi
     */
    public getPondOwnerTypes(options?: any) {
        return PondOwnerTypesApiFp(this.configuration).getPondOwnerTypes(options)(this.axios, this.basePath);
    }

}

/**
 * PondReportsApi - axios parameter creator
 * @export
 */
export const PondReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 池の状態記録の削除
         * @param {number} id 池の状態記録Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePondReport(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePondReport.');
            }
            const localVarPath = `/pondReports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 池の状態記録の取得
         * @param {number} id 池の状態記録Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPondReport(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPondReport.');
            }
            const localVarPath = `/pondReports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 池の状態記録一覧の取得
         * @param {string} [startDate] 開始日
         * @param {string} [endDate] 終了日
         * @param {number} [pondId] 池ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPondReports(startDate?: string, endDate?: string, pondId?: number, options: any = {}): RequestArgs {
            const localVarPath = `/pondReports`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (pondId !== undefined) {
                localVarQueryParameter['pondId'] = pondId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 池の状態記録で移動と関連する状態記録を取得
         * @param {number} id 池の状態記録Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedPondReports(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getRelatedPondReports.');
            }
            const localVarPath = `/pondReports/{id}/relatedReports`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 池の状態記録の登録
         * @param {PondReport} pondReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPondReport(pondReport: PondReport, options: any = {}): RequestArgs {
            // verify required parameter 'pondReport' is not null or undefined
            if (pondReport === null || pondReport === undefined) {
                throw new RequiredError('pondReport','Required parameter pondReport was null or undefined when calling postPondReport.');
            }
            const localVarPath = `/pondReports`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PondReport" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(pondReport || {}) : (pondReport || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 池の状態記録の更新
         * @param {number} id 池の状態記録Id
         * @param {PondReport} pondReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPondReport(id: number, pondReport: PondReport, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putPondReport.');
            }
            // verify required parameter 'pondReport' is not null or undefined
            if (pondReport === null || pondReport === undefined) {
                throw new RequiredError('pondReport','Required parameter pondReport was null or undefined when calling putPondReport.');
            }
            const localVarPath = `/pondReports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PondReport" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(pondReport || {}) : (pondReport || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PondReportsApi - functional programming interface
 * @export
 */
export const PondReportsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 池の状態記録の削除
         * @param {number} id 池の状態記録Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePondReport(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = PondReportsApiAxiosParamCreator(configuration).deletePondReport(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 池の状態記録の取得
         * @param {number} id 池の状態記録Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPondReport(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PondReport> {
            const localVarAxiosArgs = PondReportsApiAxiosParamCreator(configuration).getPondReport(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 池の状態記録一覧の取得
         * @param {string} [startDate] 開始日
         * @param {string} [endDate] 終了日
         * @param {number} [pondId] 池ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPondReports(startDate?: string, endDate?: string, pondId?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PondReport>> {
            const localVarAxiosArgs = PondReportsApiAxiosParamCreator(configuration).getPondReports(startDate, endDate, pondId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 池の状態記録で移動と関連する状態記録を取得
         * @param {number} id 池の状態記録Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedPondReports(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PondReport>> {
            const localVarAxiosArgs = PondReportsApiAxiosParamCreator(configuration).getRelatedPondReports(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 池の状態記録の登録
         * @param {PondReport} pondReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPondReport(pondReport: PondReport, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PondReport> {
            const localVarAxiosArgs = PondReportsApiAxiosParamCreator(configuration).postPondReport(pondReport, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 池の状態記録の更新
         * @param {number} id 池の状態記録Id
         * @param {PondReport} pondReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPondReport(id: number, pondReport: PondReport, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PondReport> {
            const localVarAxiosArgs = PondReportsApiAxiosParamCreator(configuration).putPondReport(id, pondReport, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * PondReportsApi - factory interface
 * @export
 */
export const PondReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary 池の状態記録の削除
         * @param {number} id 池の状態記録Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePondReport(id: number, options?: any) {
            return PondReportsApiFp(configuration).deletePondReport(id, options)(axios, basePath);
        },
        /**
         * 池の状態記録の取得
         * @param {number} id 池の状態記録Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPondReport(id: number, options?: any) {
            return PondReportsApiFp(configuration).getPondReport(id, options)(axios, basePath);
        },
        /**
         * 池の状態記録一覧の取得
         * @param {string} [startDate] 開始日
         * @param {string} [endDate] 終了日
         * @param {number} [pondId] 池ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPondReports(startDate?: string, endDate?: string, pondId?: number, options?: any) {
            return PondReportsApiFp(configuration).getPondReports(startDate, endDate, pondId, options)(axios, basePath);
        },
        /**
         * 池の状態記録で移動と関連する状態記録を取得
         * @param {number} id 池の状態記録Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedPondReports(id: number, options?: any) {
            return PondReportsApiFp(configuration).getRelatedPondReports(id, options)(axios, basePath);
        },
        /**
         * 池の状態記録の登録
         * @param {PondReport} pondReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPondReport(pondReport: PondReport, options?: any) {
            return PondReportsApiFp(configuration).postPondReport(pondReport, options)(axios, basePath);
        },
        /**
         * 池の状態記録の更新
         * @param {number} id 池の状態記録Id
         * @param {PondReport} pondReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPondReport(id: number, pondReport: PondReport, options?: any) {
            return PondReportsApiFp(configuration).putPondReport(id, pondReport, options)(axios, basePath);
        },
    };
};

/**
 * PondReportsApi - object-oriented interface
 * @export
 * @class PondReportsApi
 * @extends {BaseAPI}
 */
export class PondReportsApi extends BaseAPI {
    /**
     * 
     * @summary 池の状態記録の削除
     * @param {number} id 池の状態記録Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PondReportsApi
     */
    public deletePondReport(id: number, options?: any) {
        return PondReportsApiFp(this.configuration).deletePondReport(id, options)(this.axios, this.basePath);
    }

    /**
     * 池の状態記録の取得
     * @param {number} id 池の状態記録Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PondReportsApi
     */
    public getPondReport(id: number, options?: any) {
        return PondReportsApiFp(this.configuration).getPondReport(id, options)(this.axios, this.basePath);
    }

    /**
     * 池の状態記録一覧の取得
     * @param {string} [startDate] 開始日
     * @param {string} [endDate] 終了日
     * @param {number} [pondId] 池ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PondReportsApi
     */
    public getPondReports(startDate?: string, endDate?: string, pondId?: number, options?: any) {
        return PondReportsApiFp(this.configuration).getPondReports(startDate, endDate, pondId, options)(this.axios, this.basePath);
    }

    /**
     * 池の状態記録で移動と関連する状態記録を取得
     * @param {number} id 池の状態記録Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PondReportsApi
     */
    public getRelatedPondReports(id: number, options?: any) {
        return PondReportsApiFp(this.configuration).getRelatedPondReports(id, options)(this.axios, this.basePath);
    }

    /**
     * 池の状態記録の登録
     * @param {PondReport} pondReport 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PondReportsApi
     */
    public postPondReport(pondReport: PondReport, options?: any) {
        return PondReportsApiFp(this.configuration).postPondReport(pondReport, options)(this.axios, this.basePath);
    }

    /**
     * 池の状態記録の更新
     * @param {number} id 池の状態記録Id
     * @param {PondReport} pondReport 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PondReportsApi
     */
    public putPondReport(id: number, pondReport: PondReport, options?: any) {
        return PondReportsApiFp(this.configuration).putPondReport(id, pondReport, options)(this.axios, this.basePath);
    }

}

/**
 * PondTypesApi - axios parameter creator
 * @export
 */
export const PondTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 池の区分一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPondTypes(options: any = {}): RequestArgs {
            const localVarPath = `/pondTypes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PondTypesApi - functional programming interface
 * @export
 */
export const PondTypesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 池の区分一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPondTypes(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PondType>> {
            const localVarAxiosArgs = PondTypesApiAxiosParamCreator(configuration).getPondTypes(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * PondTypesApi - factory interface
 * @export
 */
export const PondTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 池の区分一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPondTypes(options?: any) {
            return PondTypesApiFp(configuration).getPondTypes(options)(axios, basePath);
        },
    };
};

/**
 * PondTypesApi - object-oriented interface
 * @export
 * @class PondTypesApi
 * @extends {BaseAPI}
 */
export class PondTypesApi extends BaseAPI {
    /**
     * 池の区分一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PondTypesApi
     */
    public getPondTypes(options?: any) {
        return PondTypesApiFp(this.configuration).getPondTypes(options)(this.axios, this.basePath);
    }

}

/**
 * PondsApi - axios parameter creator
 * @export
 */
export const PondsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 野池の削除
         * @param {number} id 野池Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePond(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePond.');
            }
            const localVarPath = `/ponds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 野池の取得
         * @param {number} id 野池Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPond(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPond.');
            }
            const localVarPath = `/ponds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 野池一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPonds(options: any = {}): RequestArgs {
            const localVarPath = `/ponds`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 野池の登録
         * @param {Pond} pond 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPond(pond: Pond, options: any = {}): RequestArgs {
            // verify required parameter 'pond' is not null or undefined
            if (pond === null || pond === undefined) {
                throw new RequiredError('pond','Required parameter pond was null or undefined when calling postPond.');
            }
            const localVarPath = `/ponds`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Pond" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(pond || {}) : (pond || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 野池の更新
         * @param {number} id 野池Id
         * @param {Pond} pond 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPond(id: number, pond: Pond, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putPond.');
            }
            // verify required parameter 'pond' is not null or undefined
            if (pond === null || pond === undefined) {
                throw new RequiredError('pond','Required parameter pond was null or undefined when calling putPond.');
            }
            const localVarPath = `/ponds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Pond" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(pond || {}) : (pond || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PondsApi - functional programming interface
 * @export
 */
export const PondsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 野池の削除
         * @param {number} id 野池Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePond(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pond> {
            const localVarAxiosArgs = PondsApiAxiosParamCreator(configuration).deletePond(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 野池の取得
         * @param {number} id 野池Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPond(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pond> {
            const localVarAxiosArgs = PondsApiAxiosParamCreator(configuration).getPond(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 野池一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPonds(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Pond>> {
            const localVarAxiosArgs = PondsApiAxiosParamCreator(configuration).getPonds(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 野池の登録
         * @param {Pond} pond 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPond(pond: Pond, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pond> {
            const localVarAxiosArgs = PondsApiAxiosParamCreator(configuration).postPond(pond, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 野池の更新
         * @param {number} id 野池Id
         * @param {Pond} pond 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPond(id: number, pond: Pond, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pond> {
            const localVarAxiosArgs = PondsApiAxiosParamCreator(configuration).putPond(id, pond, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * PondsApi - factory interface
 * @export
 */
export const PondsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary 野池の削除
         * @param {number} id 野池Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePond(id: number, options?: any) {
            return PondsApiFp(configuration).deletePond(id, options)(axios, basePath);
        },
        /**
         * 野池の取得
         * @param {number} id 野池Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPond(id: number, options?: any) {
            return PondsApiFp(configuration).getPond(id, options)(axios, basePath);
        },
        /**
         * 野池一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPonds(options?: any) {
            return PondsApiFp(configuration).getPonds(options)(axios, basePath);
        },
        /**
         * 野池の登録
         * @param {Pond} pond 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPond(pond: Pond, options?: any) {
            return PondsApiFp(configuration).postPond(pond, options)(axios, basePath);
        },
        /**
         * 野池の更新
         * @param {number} id 野池Id
         * @param {Pond} pond 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPond(id: number, pond: Pond, options?: any) {
            return PondsApiFp(configuration).putPond(id, pond, options)(axios, basePath);
        },
    };
};

/**
 * PondsApi - object-oriented interface
 * @export
 * @class PondsApi
 * @extends {BaseAPI}
 */
export class PondsApi extends BaseAPI {
    /**
     * 
     * @summary 野池の削除
     * @param {number} id 野池Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PondsApi
     */
    public deletePond(id: number, options?: any) {
        return PondsApiFp(this.configuration).deletePond(id, options)(this.axios, this.basePath);
    }

    /**
     * 野池の取得
     * @param {number} id 野池Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PondsApi
     */
    public getPond(id: number, options?: any) {
        return PondsApiFp(this.configuration).getPond(id, options)(this.axios, this.basePath);
    }

    /**
     * 野池一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PondsApi
     */
    public getPonds(options?: any) {
        return PondsApiFp(this.configuration).getPonds(options)(this.axios, this.basePath);
    }

    /**
     * 野池の登録
     * @param {Pond} pond 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PondsApi
     */
    public postPond(pond: Pond, options?: any) {
        return PondsApiFp(this.configuration).postPond(pond, options)(this.axios, this.basePath);
    }

    /**
     * 野池の更新
     * @param {number} id 野池Id
     * @param {Pond} pond 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PondsApi
     */
    public putPond(id: number, pond: Pond, options?: any) {
        return PondsApiFp(this.configuration).putPond(id, pond, options)(this.axios, this.basePath);
    }

}

/**
 * ScaleTypesApi - axios parameter creator
 * @export
 */
export const ScaleTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 尺度の一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScaleTypes(options: any = {}): RequestArgs {
            const localVarPath = `/scaleTypes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScaleTypesApi - functional programming interface
 * @export
 */
export const ScaleTypesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 尺度の一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScaleTypes(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScaleType>> {
            const localVarAxiosArgs = ScaleTypesApiAxiosParamCreator(configuration).getScaleTypes(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * ScaleTypesApi - factory interface
 * @export
 */
export const ScaleTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 尺度の一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScaleTypes(options?: any) {
            return ScaleTypesApiFp(configuration).getScaleTypes(options)(axios, basePath);
        },
    };
};

/**
 * ScaleTypesApi - object-oriented interface
 * @export
 * @class ScaleTypesApi
 * @extends {BaseAPI}
 */
export class ScaleTypesApi extends BaseAPI {
    /**
     * 尺度の一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScaleTypesApi
     */
    public getScaleTypes(options?: any) {
        return ScaleTypesApiFp(this.configuration).getScaleTypes(options)(this.axios, this.basePath);
    }

}

/**
 * UseMethodTypesApi - axios parameter creator
 * @export
 */
export const UseMethodTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 記録の方法一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUseMethodTypes(options: any = {}): RequestArgs {
            const localVarPath = `/useMethodTypes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UseMethodTypesApi - functional programming interface
 * @export
 */
export const UseMethodTypesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 記録の方法一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUseMethodTypes(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UseMethodType>> {
            const localVarAxiosArgs = UseMethodTypesApiAxiosParamCreator(configuration).getUseMethodTypes(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * UseMethodTypesApi - factory interface
 * @export
 */
export const UseMethodTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 記録の方法一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUseMethodTypes(options?: any) {
            return UseMethodTypesApiFp(configuration).getUseMethodTypes(options)(axios, basePath);
        },
    };
};

/**
 * UseMethodTypesApi - object-oriented interface
 * @export
 * @class UseMethodTypesApi
 * @extends {BaseAPI}
 */
export class UseMethodTypesApi extends BaseAPI {
    /**
     * 記録の方法一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseMethodTypesApi
     */
    public getUseMethodTypes(options?: any) {
        return UseMethodTypesApiFp(this.configuration).getUseMethodTypes(options)(this.axios, this.basePath);
    }

}

/**
 * WorkReportsApi - axios parameter creator
 * @export
 */
export const WorkReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 作業記録の削除
         * @param {number} id 作業記録Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkReport(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteWorkReport.');
            }
            const localVarPath = `/workReports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 作業記録の取得
         * @param {number} id 作業記録Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkReport(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getWorkReport.');
            }
            const localVarPath = `/workReports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 作業記録一覧の取得
         * @param {string} [startDate] 開始日
         * @param {string} [endDate] 終了日
         * @param {number} [pondId] 池ID
         * @param {string} [report] 記録の種別を限定して、直近の記録を取得するため。ex) feed, fertilizer, herbicide
         * @param {number} [limit] 上限数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkReports(startDate?: string, endDate?: string, pondId?: number, report?: string, limit?: number, options: any = {}): RequestArgs {
            const localVarPath = `/workReports`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (pondId !== undefined) {
                localVarQueryParameter['pondId'] = pondId;
            }

            if (report !== undefined) {
                localVarQueryParameter['report'] = report;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 作業記録の登録
         * @param {WorkReport} workReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWorkReport(workReport: WorkReport, options: any = {}): RequestArgs {
            // verify required parameter 'workReport' is not null or undefined
            if (workReport === null || workReport === undefined) {
                throw new RequiredError('workReport','Required parameter workReport was null or undefined when calling postWorkReport.');
            }
            const localVarPath = `/workReports`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"WorkReport" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workReport || {}) : (workReport || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 作業記録の更新
         * @param {number} id 作業記録Id
         * @param {WorkReport} workReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWorkReport(id: number, workReport: WorkReport, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putWorkReport.');
            }
            // verify required parameter 'workReport' is not null or undefined
            if (workReport === null || workReport === undefined) {
                throw new RequiredError('workReport','Required parameter workReport was null or undefined when calling putWorkReport.');
            }
            const localVarPath = `/workReports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"WorkReport" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workReport || {}) : (workReport || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkReportsApi - functional programming interface
 * @export
 */
export const WorkReportsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 作業記録の削除
         * @param {number} id 作業記録Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkReport(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = WorkReportsApiAxiosParamCreator(configuration).deleteWorkReport(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 作業記録の取得
         * @param {number} id 作業記録Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkReport(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkReport> {
            const localVarAxiosArgs = WorkReportsApiAxiosParamCreator(configuration).getWorkReport(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 作業記録一覧の取得
         * @param {string} [startDate] 開始日
         * @param {string} [endDate] 終了日
         * @param {number} [pondId] 池ID
         * @param {string} [report] 記録の種別を限定して、直近の記録を取得するため。ex) feed, fertilizer, herbicide
         * @param {number} [limit] 上限数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkReports(startDate?: string, endDate?: string, pondId?: number, report?: string, limit?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkReport>> {
            const localVarAxiosArgs = WorkReportsApiAxiosParamCreator(configuration).getWorkReports(startDate, endDate, pondId, report, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 作業記録の登録
         * @param {WorkReport} workReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWorkReport(workReport: WorkReport, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkReport> {
            const localVarAxiosArgs = WorkReportsApiAxiosParamCreator(configuration).postWorkReport(workReport, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 作業記録の更新
         * @param {number} id 作業記録Id
         * @param {WorkReport} workReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWorkReport(id: number, workReport: WorkReport, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkReport> {
            const localVarAxiosArgs = WorkReportsApiAxiosParamCreator(configuration).putWorkReport(id, workReport, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * WorkReportsApi - factory interface
 * @export
 */
export const WorkReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary 作業記録の削除
         * @param {number} id 作業記録Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkReport(id: number, options?: any) {
            return WorkReportsApiFp(configuration).deleteWorkReport(id, options)(axios, basePath);
        },
        /**
         * 作業記録の取得
         * @param {number} id 作業記録Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkReport(id: number, options?: any) {
            return WorkReportsApiFp(configuration).getWorkReport(id, options)(axios, basePath);
        },
        /**
         * 作業記録一覧の取得
         * @param {string} [startDate] 開始日
         * @param {string} [endDate] 終了日
         * @param {number} [pondId] 池ID
         * @param {string} [report] 記録の種別を限定して、直近の記録を取得するため。ex) feed, fertilizer, herbicide
         * @param {number} [limit] 上限数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkReports(startDate?: string, endDate?: string, pondId?: number, report?: string, limit?: number, options?: any) {
            return WorkReportsApiFp(configuration).getWorkReports(startDate, endDate, pondId, report, limit, options)(axios, basePath);
        },
        /**
         * 作業記録の登録
         * @param {WorkReport} workReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWorkReport(workReport: WorkReport, options?: any) {
            return WorkReportsApiFp(configuration).postWorkReport(workReport, options)(axios, basePath);
        },
        /**
         * 作業記録の更新
         * @param {number} id 作業記録Id
         * @param {WorkReport} workReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWorkReport(id: number, workReport: WorkReport, options?: any) {
            return WorkReportsApiFp(configuration).putWorkReport(id, workReport, options)(axios, basePath);
        },
    };
};

/**
 * WorkReportsApi - object-oriented interface
 * @export
 * @class WorkReportsApi
 * @extends {BaseAPI}
 */
export class WorkReportsApi extends BaseAPI {
    /**
     * 
     * @summary 作業記録の削除
     * @param {number} id 作業記録Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkReportsApi
     */
    public deleteWorkReport(id: number, options?: any) {
        return WorkReportsApiFp(this.configuration).deleteWorkReport(id, options)(this.axios, this.basePath);
    }

    /**
     * 作業記録の取得
     * @param {number} id 作業記録Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkReportsApi
     */
    public getWorkReport(id: number, options?: any) {
        return WorkReportsApiFp(this.configuration).getWorkReport(id, options)(this.axios, this.basePath);
    }

    /**
     * 作業記録一覧の取得
     * @param {string} [startDate] 開始日
     * @param {string} [endDate] 終了日
     * @param {number} [pondId] 池ID
     * @param {string} [report] 記録の種別を限定して、直近の記録を取得するため。ex) feed, fertilizer, herbicide
     * @param {number} [limit] 上限数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkReportsApi
     */
    public getWorkReports(startDate?: string, endDate?: string, pondId?: number, report?: string, limit?: number, options?: any) {
        return WorkReportsApiFp(this.configuration).getWorkReports(startDate, endDate, pondId, report, limit, options)(this.axios, this.basePath);
    }

    /**
     * 作業記録の登録
     * @param {WorkReport} workReport 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkReportsApi
     */
    public postWorkReport(workReport: WorkReport, options?: any) {
        return WorkReportsApiFp(this.configuration).postWorkReport(workReport, options)(this.axios, this.basePath);
    }

    /**
     * 作業記録の更新
     * @param {number} id 作業記録Id
     * @param {WorkReport} workReport 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkReportsApi
     */
    public putWorkReport(id: number, workReport: WorkReport, options?: any) {
        return WorkReportsApiFp(this.configuration).putWorkReport(id, workReport, options)(this.axios, this.basePath);
    }

}

/**
 * WorkersApi - axios parameter creator
 * @export
 */
export const WorkersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 担当者の削除
         * @param {number} id 担当者Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorker(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteWorker.');
            }
            const localVarPath = `/workers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 担当者の取得
         * @param {number} id 担当者Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorker(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getWorker.');
            }
            const localVarPath = `/workers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインユーザーの取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkerMe(options: any = {}): RequestArgs {
            const localVarPath = `/workers/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 担当者一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkers(options: any = {}): RequestArgs {
            const localVarPath = `/workers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 担当者の登録
         * @param {Worker} worker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWorker(worker: Worker, options: any = {}): RequestArgs {
            // verify required parameter 'worker' is not null or undefined
            if (worker === null || worker === undefined) {
                throw new RequiredError('worker','Required parameter worker was null or undefined when calling postWorker.');
            }
            const localVarPath = `/workers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Worker" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(worker || {}) : (worker || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 担当者の更新
         * @param {number} id 担当者Id
         * @param {Worker} worker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWorker(id: number, worker: Worker, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putWorker.');
            }
            // verify required parameter 'worker' is not null or undefined
            if (worker === null || worker === undefined) {
                throw new RequiredError('worker','Required parameter worker was null or undefined when calling putWorker.');
            }
            const localVarPath = `/workers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Worker" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(worker || {}) : (worker || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkersApi - functional programming interface
 * @export
 */
export const WorkersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 担当者の削除
         * @param {number} id 担当者Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorker(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Worker> {
            const localVarAxiosArgs = WorkersApiAxiosParamCreator(configuration).deleteWorker(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 担当者の取得
         * @param {number} id 担当者Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorker(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Worker> {
            const localVarAxiosArgs = WorkersApiAxiosParamCreator(configuration).getWorker(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * ログインユーザーの取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkerMe(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Worker> {
            const localVarAxiosArgs = WorkersApiAxiosParamCreator(configuration).getWorkerMe(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 担当者一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkers(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Worker>> {
            const localVarAxiosArgs = WorkersApiAxiosParamCreator(configuration).getWorkers(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 担当者の登録
         * @param {Worker} worker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWorker(worker: Worker, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Worker> {
            const localVarAxiosArgs = WorkersApiAxiosParamCreator(configuration).postWorker(worker, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 担当者の更新
         * @param {number} id 担当者Id
         * @param {Worker} worker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWorker(id: number, worker: Worker, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Worker> {
            const localVarAxiosArgs = WorkersApiAxiosParamCreator(configuration).putWorker(id, worker, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * WorkersApi - factory interface
 * @export
 */
export const WorkersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary 担当者の削除
         * @param {number} id 担当者Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorker(id: number, options?: any) {
            return WorkersApiFp(configuration).deleteWorker(id, options)(axios, basePath);
        },
        /**
         * 担当者の取得
         * @param {number} id 担当者Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorker(id: number, options?: any) {
            return WorkersApiFp(configuration).getWorker(id, options)(axios, basePath);
        },
        /**
         * ログインユーザーの取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkerMe(options?: any) {
            return WorkersApiFp(configuration).getWorkerMe(options)(axios, basePath);
        },
        /**
         * 担当者一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkers(options?: any) {
            return WorkersApiFp(configuration).getWorkers(options)(axios, basePath);
        },
        /**
         * 担当者の登録
         * @param {Worker} worker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWorker(worker: Worker, options?: any) {
            return WorkersApiFp(configuration).postWorker(worker, options)(axios, basePath);
        },
        /**
         * 担当者の更新
         * @param {number} id 担当者Id
         * @param {Worker} worker 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWorker(id: number, worker: Worker, options?: any) {
            return WorkersApiFp(configuration).putWorker(id, worker, options)(axios, basePath);
        },
    };
};

/**
 * WorkersApi - object-oriented interface
 * @export
 * @class WorkersApi
 * @extends {BaseAPI}
 */
export class WorkersApi extends BaseAPI {
    /**
     * 
     * @summary 担当者の削除
     * @param {number} id 担当者Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkersApi
     */
    public deleteWorker(id: number, options?: any) {
        return WorkersApiFp(this.configuration).deleteWorker(id, options)(this.axios, this.basePath);
    }

    /**
     * 担当者の取得
     * @param {number} id 担当者Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkersApi
     */
    public getWorker(id: number, options?: any) {
        return WorkersApiFp(this.configuration).getWorker(id, options)(this.axios, this.basePath);
    }

    /**
     * ログインユーザーの取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkersApi
     */
    public getWorkerMe(options?: any) {
        return WorkersApiFp(this.configuration).getWorkerMe(options)(this.axios, this.basePath);
    }

    /**
     * 担当者一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkersApi
     */
    public getWorkers(options?: any) {
        return WorkersApiFp(this.configuration).getWorkers(options)(this.axios, this.basePath);
    }

    /**
     * 担当者の登録
     * @param {Worker} worker 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkersApi
     */
    public postWorker(worker: Worker, options?: any) {
        return WorkersApiFp(this.configuration).postWorker(worker, options)(this.axios, this.basePath);
    }

    /**
     * 担当者の更新
     * @param {number} id 担当者Id
     * @param {Worker} worker 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkersApi
     */
    public putWorker(id: number, worker: Worker, options?: any) {
        return WorkersApiFp(this.configuration).putWorker(id, worker, options)(this.axios, this.basePath);
    }

}

