import { actionCreatorFactory } from "typescript-fsa";
import { Image, NewImage } from "../../../../domain/image";
// Actions
const actionCreator = actionCreatorFactory("ApiImage");

// save
const uploadStart = actionCreator<UploadStartPayload>("uploadStart");
interface UploadStartPayload {
  image: NewImage;
}
const uploadImage = actionCreator<UploadImagePayload>("uploadImage");
interface UploadImagePayload {
  image: Image;
}
const uploadSuccess = actionCreator<UploadSuccessPayload>("uploadSuccess");
interface UploadSuccessPayload {
  image: Image;
}
const uploadFailed = actionCreator("uploadFailed");

export const apiImageActions = {
  uploadStart,
  uploadImage,
  uploadSuccess,
  uploadFailed,
};

// Reducers
import { getInitialState, ImageStateType, SaveHandlers } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const ImageReducer = reducerWithInitialState<ImageStateType>(getInitialState())
  .case(uploadStart, (state, { image }) => SaveHandlers.uploadStart(state, image))
  .case(uploadImage, (state, { image }) => SaveHandlers.uploadImage(state, image))
  .case(uploadSuccess, (state, { image }) => SaveHandlers.uploadSuccess(state, image))
  .case(uploadFailed, (state) => SaveHandlers.uploadFailed(state));
