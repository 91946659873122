import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NavigationService } from "../../../../application/mobile/navigation";
import { ReadApiService } from "../../../../application/mobile/pondSelection/readApi";
import { NewOrEditStateService } from "../../../../application/mobile/workReport/newOrEditState";
import { CarpVarietyType } from "../../../../domain/carpVarietyType";
import { isActiveEntity } from "../../../../domain/entity";
import { ParentCarp } from "../../../../domain/parentCarp";
import { isUsedPond, Pond } from "../../../../domain/pond";
import { PondArea } from "../../../../domain/pondArea";
import { PondOwnerType } from "../../../../domain/pondOwnerType";
import { PondReport } from "../../../../domain/pondReport";
import { PondType } from "../../../../domain/pondType";
import { Worker } from "../../../../domain/worker";
import { ApplicationState } from "../../../../store/modules";
import { AuthStateType } from "../../../../store/modules/auth/reducer";
import { NavigationStateType } from "../../../../store/modules/mobile/navigation/reducer";
import { EmptyMessage } from "../../../components/atoms/Message";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { PondSelectionEachArea } from "../../../components/organisms/PondSelection";
import { MobileLayout } from "../../Layout/Mobile";

interface StateProps {
  ponds: Pond[];
  pondAreas: PondArea[];
  pondTypes: PondType[];
  pondOwnerTypes: PondOwnerType[];
  parentCarps: ParentCarp[];
  pondReports: PondReport[];
  carpVarietyTypes: CarpVarietyType[];
  workers: Worker[];

  authState: AuthStateType;
  navigationState: NavigationStateType;
}

interface DispatchProps {
  readApiService: ReadApiService;
  newOrEditStateService: NewOrEditStateService;
  navigationService: NavigationService;
}

type PondSelectionPageProps = StateProps & DispatchProps & RouteComponentProps;

export function filterPondsByFromPage(
  ponds: Pond[],
  navigationState: NavigationStateType,
  authState: AuthStateType
): Pond[] {
  const { selectedPondAreaId, selectedWorkerId, currentPage } = navigationState;
  const loginUser = authState.user;
  // エリア一覧から遷移した場合のフィルタ
  if (currentPage === "area") {
    return ponds.filter((p) => p.pondAreaId === selectedPondAreaId);
  }
  // 担当者一覧から遷移した場合のフィルタ
  if (currentPage === "worker" && selectedWorkerId !== null) {
    return ponds.filter((p) => p.userIds.includes(selectedWorkerId));
  }
  // 担当野池一覧から遷移した場合のフィルタ
  const isAuthUser = currentPage === "authUser" || currentPage === null;
  if (isAuthUser && loginUser !== null) {
    return ponds.filter((p) => p.userIds.includes(loginUser.id));
  }
  return [];
}

function getTitleByFromPage(
  api: { pondAreas: PondArea[]; workers: Worker[] },
  navigationState: NavigationStateType
): string {
  const { selectedPondAreaId, selectedWorkerId, currentPage } = navigationState;
  const defaultTitle = "担当野池";
  if (currentPage === "area") {
    const pondArea = api.pondAreas.find((area) => area.id === selectedPondAreaId);
    return pondArea ? pondArea.name : defaultTitle;
  }
  if (currentPage === "worker") {
    const worker = api.workers.find((w) => w.id === selectedWorkerId);
    return worker ? worker.name : defaultTitle;
  }
  return defaultTitle;
}

export class Wrapped extends React.Component<PondSelectionPageProps> {
  public componentWillMount(): void {
    this.props.readApiService.init();

    this.initState(this.props);
  }

  public componentWillReceiveProps(nextProps: Readonly<PondSelectionPageProps>, nextContext: any): void {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.initState(nextProps);
    }
  }

  public render() {
    // 遷移元に応じて池のリストを絞り込み
    const ponds = filterPondsByFromPage(this.props.ponds, this.props.navigationState, this.props.authState);

    // 遷移元に応じて画面タイトルを選択
    let title = "担当野池";
    title = getTitleByFromPage(
      { pondAreas: this.props.pondAreas, workers: this.props.workers },
      this.props.navigationState
    );

    const isEmpty = ponds.filter((p) => p.pondAreaId !== null).length === 0;
    return (
      <MobileLayout>
        <BaseNavigationHeader title={title} onClickMenu={this.handleClickMenu} />
        <BaseNavigationContent>
          {isEmpty && <EmptyMessage>野池が見つかりませんでした。</EmptyMessage>}
          {!isEmpty && (
            <PondSelectionEachArea
              ponds={ponds}
              pondAreas={this.props.pondAreas}
              pondReports={this.props.pondReports}
              pondTypes={this.props.pondTypes}
              pondOwnerTypes={this.props.pondOwnerTypes}
              parentCarps={this.props.parentCarps}
              carpVarietyTypes={this.props.carpVarietyTypes}
              onClickPond={this.handleClickPond}
              shouldMoveScrollTopOnMounted={this.shouldMoveScrollTopOnMounted()}
            />
          )}
        </BaseNavigationContent>
      </MobileLayout>
    );
  }

  private initState(props: Readonly<PondSelectionPageProps>) {
    const { selectedPondAreaId, selectedWorkerId, selectedPondId } = props.navigationState;
    if (props.location.pathname.match("/ponds/workers/.+")) {
      if (selectedWorkerId === null) {
        props.history.push("/ponds/workers");
      }
      return;
    }
    if (props.location.pathname.match("/ponds/pond_areas/.+")) {
      if (selectedPondAreaId === null) {
        props.history.push("/ponds/pond_areas");
      }
      return;
    }
    if (selectedPondAreaId !== null || selectedWorkerId !== null || selectedPondId !== null) {
      props.navigationService.clearState();
    }
  }

  private shouldMoveScrollTopOnMounted(): boolean {
    const { prevSelectedWorkerId, prevSelectedPondAreaId, prevPage, selectedPondAreaId, selectedWorkerId, currentPage } = this.props.navigationState;
    return prevPage === currentPage && prevSelectedWorkerId === selectedWorkerId && prevSelectedPondAreaId === selectedPondAreaId;
  }

  private handleClickPond = async (id: number) => {
    this.props.navigationService.selectPond(id);
    const date = new Date();
    this.props.navigationService.changeDate(date);
    this.props.history.push("/reports/menu");
  };

  private handleClickMenu = () => {
    this.props.navigationService.openMenu();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { pondArea, pond, pondType, pondOwnerType, pondReport, parentCarp, carpVarietyType, worker } = state.api;
  return {
    pondAreas: pondArea.pondAreas.filter(isActiveEntity),
    ponds: pond.ponds.filter((p) => isActiveEntity(p) && isUsedPond(p) && p.pondTypeId !== null),
    pondTypes: pondType.pondTypes,
    pondOwnerTypes: pondOwnerType.pondOwnerTypes,
    pondReports: pondReport.pondReports,
    parentCarps: parentCarp.parentCarps.filter(isActiveEntity),
    carpVarietyTypes: carpVarietyType.carpVarietyTypes,
    workers: worker.workers,

    authState: state.auth,
    navigationState: state.mobile.navigation,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    readApiService: new ReadApiService(dispatch),
    newOrEditStateService: new NewOrEditStateService(dispatch),
    navigationService: new NavigationService(dispatch),
  };
};

export const PondSelectionPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
