import { default as moment } from "moment";
import { Dispatch } from "redux";
import {
  copyEnvironmentReport,
  createNewEnvironmentReport,
  EnvironmentReport,
  EnvironmentReportValidator,
  filterEnvironmentReportByPond,
  getLatestEnvironmentReport,
  isEditEnvironmentReport,
} from "../../../domain/environmentReport";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { EnvironmentReportRepository } from "../../../infrastracture/environmentReport/repository";
import { ApplicationState } from "../../../store/modules";
import { apiEnvironmentReportActions } from "../../../store/modules/api/environmentReport/ducks";
import { environmentReportNewOrEditStateActions } from "../../../store/modules/mobile/environmentReport/newOrEditState/ducks";
import { notificationAlertStateActions } from "../../../store/modules/notification/alert/ducks";
import { EnvironmentReportApiService } from "../../api/environmentReport";

interface INewOrEditStateService extends IApplicationService {
  initEnvironmentReport: () => void;
  resetEnvironmentReport: () => void;
  changeValue: (value: string) => void;
  incrementValue: () => void;
  decrementValue: () => void;
  saveEnvironmentReport: () => void;
  deleteEnvironmentReport: () => void;
}

export class NewOrEditStateService implements INewOrEditStateService {
  private environmentReportApiService: EnvironmentReportApiService;

  public constructor(private dispatch: Dispatch<any>) {
    this.environmentReportApiService = new EnvironmentReportApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public async initEnvironmentReport() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedPondId, selectedDate } = state.mobile.navigation;
      const pond = state.api.pond.ponds.find((p) => p.id === selectedPondId);
      if (!pond) {
        return;
      }
      const selectedMomentDate = moment(selectedDate);
      await Promise.all([
        this.environmentReportApiService.fetchReportByDate(selectedDate, pond.id),
        this.environmentReportApiService.fetchLatestReport(selectedDate, pond.id),
      ]);
      const fetchedState = getState();
      const environmentReport = fetchedState.api.environmentReport.environmentReports.find((er) => {
        const dateOfReport = moment(er.date);
        return pond.id === er.pondId && dateOfReport.isSame(selectedMomentDate, "date");
      });
      if (environmentReport) {
        this.dispatch(
          environmentReportNewOrEditStateActions.initialEnvironmentReport({
            environmentReport,
          })
        );
        return;
      }
      const filteredEnvironmentReportsByPond = fetchedState.api.environmentReport.environmentReports.filter((r) =>
        filterEnvironmentReportByPond(r, pond.id)
      );
      const latestReport = getLatestEnvironmentReport(filteredEnvironmentReportsByPond, selectedDate);
      const newEnvironmentReport = latestReport
        ? copyEnvironmentReport(latestReport, createNewEnvironmentReport(pond.id, selectedDate))
        : createNewEnvironmentReport(pond.id, selectedDate);
      this.dispatch(
        environmentReportNewOrEditStateActions.initialEnvironmentReport({ environmentReport: newEnvironmentReport })
      );
    });
  }

  @catchApplicationError()
  public resetEnvironmentReport() {
    this.dispatch(environmentReportNewOrEditStateActions.resetState());
  }

  @catchApplicationError()
  public changeValue(value: string) {
    this.dispatch(
      environmentReportNewOrEditStateActions.changeEnvironmentReport({
        key: "value",
        value,
      })
    );
  }

  @catchApplicationError()
  public incrementValue() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { environmentReport } = state.mobile.environmentReportState.newOrEditStateType;
      if (!environmentReport) {
        throw new ApplicationError("不正な動作です。");
      }

      const value = Number(environmentReport.value);
      if (Number.isNaN(value)) {
        return;
      }
      this.dispatch(
        environmentReportNewOrEditStateActions.changeEnvironmentReport({
          key: "value",
          value: value + 1 + "",
        })
      );
    });
  }

  @catchApplicationError()
  public decrementValue() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { environmentReport } = state.mobile.environmentReportState.newOrEditStateType;
      if (!environmentReport) {
        throw new ApplicationError("不正な動作です。");
      }

      const value = Number(environmentReport.value);
      if (Number.isNaN(value)) {
        return;
      }
      this.dispatch(
        environmentReportNewOrEditStateActions.changeEnvironmentReport({
          key: "value",
          value: value - 1 + "",
        })
      );
    });
  }

  @catchApplicationError((dispatch) => dispatch(environmentReportNewOrEditStateActions.saveFail()))
  public async saveEnvironmentReport() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(environmentReportNewOrEditStateActions.saveStart());
      const { newOrEditStateType } = getState().mobile.environmentReportState;
      const environmentReport = newOrEditStateType.environmentReport;
      if (environmentReport === null) {
        throw new ApplicationError("記録が見つかりませんでした。");
      }
      const validator = new EnvironmentReportValidator();
      validator.validate(environmentReport);
      if (!validator.isValid()) {
        this.dispatch(notificationAlertStateActions.showErrorMessage({ errorMessage: validator.getMessages() }));
        this.dispatch(environmentReportNewOrEditStateActions.saveFail());
        return;
      }

      if (isEditEnvironmentReport(environmentReport)) {
        const updatedReport = await new EnvironmentReportRepository().putEnvironmentReport(environmentReport);
        this.dispatch(environmentReportNewOrEditStateActions.saveSuccess({ environmentReport: updatedReport }));
        this.dispatch(apiEnvironmentReportActions.updateEnvironmentReport({ environmentReport: updatedReport }));
        return;
      }

      const createdReport = await new EnvironmentReportRepository().postEnvironmentReport(environmentReport);
      this.dispatch(environmentReportNewOrEditStateActions.saveSuccess({ environmentReport: createdReport }));
      this.dispatch(apiEnvironmentReportActions.createEnvironmentReport({ environmentReport: createdReport }));
    });
  }

  @catchApplicationError((dispatch) => dispatch(environmentReportNewOrEditStateActions.saveFail()))
  public async deleteEnvironmentReport() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(environmentReportNewOrEditStateActions.saveStart());
      const { newOrEditStateType } = getState().mobile.environmentReportState;
      const environmentReport = newOrEditStateType.environmentReport;
      if (environmentReport === null) {
        throw new ApplicationError("記録が見つかりませんでした。");
      }
      await new EnvironmentReportRepository().deleteEnvironmentReport(environmentReport as EnvironmentReport);
      this.dispatch(environmentReportNewOrEditStateActions.deleteSuccess());
      this.dispatch(
        apiEnvironmentReportActions.deleteEnvironmentReport({
          environmentReport: environmentReport as EnvironmentReport,
        })
      );
      await this.initEnvironmentReport();
    });
  }
}
