import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { EditStateService } from "../../../application/master/feed/editState";
import { NewStateService } from "../../../application/master/feed/newState";
import { ApplicationState } from "../../../store/modules";
import { NewOrEditStateType } from "../../../store/modules/master/feed/newOrEditState/reducer";
import { ConfirmModal } from "../../components/molecules/ConfirmModal";
import { FeedForm } from "../../components/organisms/FeedForm";

interface StateProps {
  newOrEditState: NewOrEditStateType;
}

interface DispatchProps {
  editStateService: EditStateService;
  newStateService: NewStateService;
}

type FeedEditPageProps = StateProps & DispatchProps;

interface FeedEditPageState {
  isOpenDeleteConfirm: boolean;
}

export class Wrapped extends React.Component<FeedEditPageProps, FeedEditPageState> {
  constructor(props: FeedEditPageProps) {
    super(props);

    this.state = {
      isOpenDeleteConfirm: false,
    };
  }

  public render() {
    const { feed, isSaving } = this.props.newOrEditState;
    return (
      <>
        <FeedForm
          feed={feed}
          isSaving={isSaving}
          onChangeName={this.handleChangeName}
          onChangePondClass={this.handleChangePondClass}
          onClickSave={this.handleClickSaveFeed}
          onClickCancel={this.handleClickCancelFeed}
          onClickAdd={this.handleClickAddFeed}
          onClickDelete={this.openDeleteConfirmModal}
        />
        <ConfirmModal
          isOpen={this.state.isOpenDeleteConfirm}
          onClose={this.closeDeleteConfirmModal}
          onClickCancel={this.closeDeleteConfirmModal}
          onClickOk={this.handleClickDeleteFeed}
          cancelText={"キャンセル"}
          okText={"削除"}
          confirmMessage={"餌を削除してもよろしいでしょうか？"}
        />
      </>
    );
  }

  private handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.editStateService.changeName(e.target.value);
  };

  private handleChangePondClass = (pondClassId: null | number) => {
    this.props.editStateService.changePondClass(pondClassId);
  };

  private handleClickSaveFeed = () => {
    this.props.editStateService.saveFeed();
  };

  private handleClickCancelFeed = () => {
    this.props.editStateService.cancelFeed();
  };

  private handleClickAddFeed = () => {
    this.props.newStateService.createFeed();
  };

  private handleClickDeleteFeed = () => {
    this.props.editStateService.deleteFeed();
  };

  private closeDeleteConfirmModal = () => {
    this.setState({ isOpenDeleteConfirm: false });
  };
  private openDeleteConfirmModal = () => {
    this.setState({ isOpenDeleteConfirm: true });
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    newOrEditState: state.master.feed.newOrEditState,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    editStateService: new EditStateService(dispatch),
    newStateService: new NewStateService(dispatch),
  };
};

export const FeedEditPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);
