import { default as moment } from "moment";
import { Dispatch } from "redux";
import { EnvironmentReport } from "../../../domain/environmentReport";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { EnvironmentReportRepository } from "../../../infrastracture/environmentReport/repository";
import { apiEnvironmentReportActions } from "../../../store/modules/api/environmentReport/ducks";

interface IEnvironmentReportApiService extends IApplicationService {
  init: (startDate?: Date, endDate?: Date, pondId?: number) => void;
  fetchReportByDate: (date: Date, pondId: number) => void;
  fetchReportByPeriod: (startDate: Date, endDate: Date, pondId: number) => void;
  fetchLatestReport: (date: Date, pondId: number) => void;
}

export class EnvironmentReportApiService implements IEnvironmentReportApiService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError((dispatch) => dispatch(apiEnvironmentReportActions.fetchFail()))
  public async init(startDate?: Date, endDate?: Date, pondId?: number) {
    this.dispatch(apiEnvironmentReportActions.fetchStart());
    const environmentReports: EnvironmentReport[] = await new EnvironmentReportRepository().getEnvironmentReports({
      startDate,
      endDate,
      pondId,
    });
    this.dispatch(apiEnvironmentReportActions.fetchComplete({ environmentReports }));
  }

  @catchApplicationError((dispatch) => dispatch(apiEnvironmentReportActions.fetchFail()))
  public async fetchReportByDate(date: Date, pondId: number) {
    this.dispatch(apiEnvironmentReportActions.fetchStart());
    const environmentReports: EnvironmentReport[] = await new EnvironmentReportRepository().getEnvironmentReports({
      startDate: date,
      endDate: date,
      pondId,
    });
    this.dispatch(apiEnvironmentReportActions.fetchComplete({ environmentReports }));
  }

  @catchApplicationError((dispatch) => dispatch(apiEnvironmentReportActions.fetchFail()))
  public async fetchReportByPeriod(startDate: Date, endDate: Date, pondId: number) {
    this.dispatch(apiEnvironmentReportActions.fetchStart());
    const environmentReports: EnvironmentReport[] = await new EnvironmentReportRepository().getEnvironmentReports({
      startDate,
      endDate,
      pondId,
    });
    this.dispatch(apiEnvironmentReportActions.fetchComplete({ environmentReports }));
  }

  @catchApplicationError((dispatch) => dispatch(apiEnvironmentReportActions.fetchFail()))
  public async fetchLatestReport(date: Date, pondId: number) {
    const m = moment(date).add(-1, "days");
    this.dispatch(apiEnvironmentReportActions.fetchStart());
    const environmentReports: EnvironmentReport[] = await new EnvironmentReportRepository().getEnvironmentReports({
      endDate: m.toDate(),
      pondId,
      limit: 1,
    });
    this.dispatch(apiEnvironmentReportActions.fetchComplete({ environmentReports }));
  }
}
