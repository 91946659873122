import * as React from "react";
import { getUniqueKey, UniqueKey } from "../../../../domain/entity";
import { NewOrEditPondDisinfectionReport } from "../../../../domain/pondDisinfectionReport";
import { FormDatePicker } from "../../atoms/Form/DatePicker";
import { FormNumberInput, FormTextInput } from "../../atoms/Form/Input";
import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "../../atoms/Table";
import { AmountCell, DateCell, RowHeaderTableCell } from "./elements";

interface DisinfectionReportTableProps {
  pondDisinfectionReports: NewOrEditPondDisinfectionReport[];
  onChangeDisinfectionReports: (
    disinfectionId: UniqueKey,
    key: keyof NewOrEditPondDisinfectionReport,
    value: any
  ) => void;
}

export class DisinfectionReportTable extends React.Component<DisinfectionReportTableProps> {
  public render() {
    const { pondDisinfectionReports } = this.props;
    if (pondDisinfectionReports.length === 0) {
      return null;
    }

    const report = pondDisinfectionReports[0];
    const disinfectionId = getUniqueKey(report);

    return (
      <Table definition={true}>
        <TableHeader>
          <TableRow>
            <TableHeaderCell />
            <TableHeaderCell>日付</TableHeaderCell>
            <TableHeaderCell>本数</TableHeaderCell>
            <TableHeaderCell>メモ</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <RowHeaderTableCell>塩素</RowHeaderTableCell>
            <TableCell>
              <DateCell>
                <FormDatePicker label={""} date={report.date} onChange={this.handleChangeDate(disinfectionId)} />
              </DateCell>
            </TableCell>
            <TableCell>
              <AmountCell>
                <FormNumberInput label={""} value={report.amount} onChange={this.handleChangeAmount(disinfectionId)} />
              </AmountCell>
            </TableCell>
            <TableCell>
              <FormTextInput label={""} value={report.note} onChange={this.handleChangeNote(disinfectionId)} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  private handleChangeDate = (disinfectionId: UniqueKey) => (date: Date) => {
    this.props.onChangeDisinfectionReports(disinfectionId, "date", date);
  };

  private handleChangeAmount = (disinfectionId: UniqueKey) => (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChangeDisinfectionReports(disinfectionId, "amount", e.target.value);
  };

  private handleChangeNote = (disinfectionId: UniqueKey) => (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChangeDisinfectionReports(disinfectionId, "note", e.target.value);
  };
}
