import { Dispatch } from "redux";
import { getUniqueKey, UniqueKey } from "../../../domain/entity";
import { copyFeedReport, createNewFeedReport, NewFeedReport } from "../../../domain/feedReport";
import { isFeedReportGroup } from "../../../domain/reportGroup";
import { isBucketUseMethodType, isFeederUseMethodType, UseMethodType } from "../../../domain/useMethodType";
import { filterWorkReportByPond, getLatestFeedReport } from "../../../domain/workReport";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { ApplicationState } from "../../../store/modules";
import { workReportNewOrEditStateActions } from "../../../store/modules/mobile/workReport/newOrEditState/ducks";
import { FeedApiService } from "../../api/feed";
import { FeedReasonTypeApiService } from "../../api/feedReasonType";
import { PondTypeApiService } from "../../api/pondType";
import { UseMethodTypeApiService } from "../../api/useMethodType";
import { WorkReportApiService } from "../../api/workReport";

interface IFeedReportStateService extends IApplicationService {
  fetchApi: () => void;
  addFeedReport: () => void;
  selectFeedReport: (uniqueKey: UniqueKey) => void;
  changeFeed: (id: null | number) => void;
  changeFeedReasonType: (id: null | number) => void;
  changeCount: (count: string) => void;
  changeAmount: (amount: string) => void;
  changeFeederUseMethodType: () => void;
  changeBucketUseMethodType: () => void;
  incrementAmount: () => void;
  decrementAmount: () => void;
}

export class FeedReportStateService implements IFeedReportStateService {
  private useMethodTypeApiService: UseMethodTypeApiService;
  private feedApiService: FeedApiService;
  private feedReasonTypeApiService: FeedReasonTypeApiService;
  private pondTypeApiService: PondTypeApiService;
  private workReportApiService: WorkReportApiService;

  public constructor(private dispatch: Dispatch<any>) {
    this.useMethodTypeApiService = new UseMethodTypeApiService(dispatch);
    this.feedApiService = new FeedApiService(dispatch);
    this.feedReasonTypeApiService = new FeedReasonTypeApiService(dispatch);
    this.pondTypeApiService = new PondTypeApiService(dispatch);
    this.workReportApiService = new WorkReportApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public async fetchApi() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedPondId, selectedDate } = state.mobile.navigation;
      if (selectedPondId === null) {
        throw new ApplicationError("不正な操作です。");
      }
      await Promise.all([
        this.feedApiService.fetchUnFetched(),
        this.useMethodTypeApiService.fetchUnFetched(),
        this.feedReasonTypeApiService.fetchUnFetched(),
        this.pondTypeApiService.fetchUnFetched(),
        this.workReportApiService.fetchLatestFeedReport(selectedDate, selectedPondId),
      ]);
    });
  }

  @catchApplicationError()
  public addFeedReport() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedDate, selectedPondId } = state.mobile.navigation;
      const feederUseMethod = state.api.useMethodType.useMethodTypes.find(
        (u) => isFeedReportGroup(u) && isFeederUseMethodType(u)
      ) as UseMethodType;
      const filteredReportsByPond = state.api.workReport.workReports.filter((r) =>
        filterWorkReportByPond(r, selectedPondId)
      );
      const latestFeedReport = getLatestFeedReport(filteredReportsByPond, selectedDate);
      const newFeedReport = latestFeedReport
        ? (copyFeedReport(latestFeedReport, createNewFeedReport(feederUseMethod.id)) as NewFeedReport)
        : createNewFeedReport(feederUseMethod.id);
      this.dispatch(workReportNewOrEditStateActions.addFeedReport({ feedReport: newFeedReport }));
      this.dispatch(
        workReportNewOrEditStateActions.selectAnyReport({
          selectedReportKey: "feed",
          selectedUniqueKey: newFeedReport.uid,
        })
      );
    });
  }

  @catchApplicationError()
  public selectFeedReport(uniqueKey: UniqueKey) {
    this.dispatch(
      workReportNewOrEditStateActions.selectAnyReport({
        selectedReportKey: "feed",
        selectedUniqueKey: uniqueKey,
      })
    );
  }

  @catchApplicationError()
  public changeFeed(id: null | number) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedReportKey, selectedUniqueKey } = state.mobile.workReportState.newOrEditStateType;
      if (selectedReportKey !== "feed" || selectedUniqueKey === null) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        workReportNewOrEditStateActions.changeFeedReport({
          feedUniqueKey: selectedUniqueKey,
          key: "feedId",
          value: id,
        })
      );
    });
  }

  @catchApplicationError()
  public changeFeedReasonType(id: null | number) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedReportKey, selectedUniqueKey } = state.mobile.workReportState.newOrEditStateType;
      if (selectedReportKey !== "feed" || selectedUniqueKey === null) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        workReportNewOrEditStateActions.changeFeedReport({
          feedUniqueKey: selectedUniqueKey,
          key: "feedReasonTypeId",
          value: id,
        })
      );
    });
  }

  @catchApplicationError()
  public changeCount(count: string) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedReportKey, selectedUniqueKey } = state.mobile.workReportState.newOrEditStateType;
      if (selectedReportKey !== "feed" || selectedUniqueKey === null) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        workReportNewOrEditStateActions.changeFeedReport({
          feedUniqueKey: selectedUniqueKey,
          key: "count",
          value: count,
        })
      );
    });
  }

  @catchApplicationError()
  public changeAmount(amount: string) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedReportKey, selectedUniqueKey } = state.mobile.workReportState.newOrEditStateType;
      if (selectedReportKey !== "feed" || selectedUniqueKey === null) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        workReportNewOrEditStateActions.changeFeedReport({
          feedUniqueKey: selectedUniqueKey,
          key: "amount",
          value: amount,
        })
      );
    });
  }

  @catchApplicationError()
  public changeFeederUseMethodType() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedReportKey, selectedUniqueKey } = state.mobile.workReportState.newOrEditStateType;
      if (selectedReportKey !== "feed" || selectedUniqueKey === null) {
        throw new ApplicationError("不正な動作です。");
      }
      const useMethod = state.api.useMethodType.useMethodTypes.find(
        (u) => isFeedReportGroup(u) && isFeederUseMethodType(u)
      );
      if (!useMethod) {
        throw new ApplicationError("使用方法が見つかりませんでした。");
      }
      this.dispatch(
        workReportNewOrEditStateActions.changeFeedReport({
          feedUniqueKey: selectedUniqueKey,
          key: "useMethodTypeId",
          value: useMethod.id,
        })
      );
    });
  }

  @catchApplicationError()
  public changeBucketUseMethodType() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedReportKey, selectedUniqueKey } = state.mobile.workReportState.newOrEditStateType;
      if (selectedReportKey !== "feed" || selectedUniqueKey === null) {
        throw new ApplicationError("不正な動作です。");
      }
      const useMethod = state.api.useMethodType.useMethodTypes.find(
        (u) => isFeedReportGroup(u) && isBucketUseMethodType(u)
      );
      if (!useMethod) {
        throw new ApplicationError("使用方法が見つかりませんでした。");
      }
      this.dispatch(
        workReportNewOrEditStateActions.changeFeedReport({
          feedUniqueKey: selectedUniqueKey,
          key: "useMethodTypeId",
          value: useMethod.id,
        })
      );
      this.dispatch(
        workReportNewOrEditStateActions.changeFeedReport({
          feedUniqueKey: selectedUniqueKey,
          key: "count",
          value: "0",
        })
      );
    });
  }

  @catchApplicationError()
  public incrementAmount() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { workReport, selectedReportKey, selectedUniqueKey } = state.mobile.workReportState.newOrEditStateType;
      if (!workReport || selectedReportKey !== "feed" || selectedUniqueKey === null) {
        throw new ApplicationError("不正な動作です。");
      }
      const feedReport = workReport.feedReports.find((fr) => getUniqueKey(fr) === selectedUniqueKey);
      if (!feedReport) {
        throw new ApplicationError("記録が見つかりませんでした。");
      }
      const amount = Number(feedReport.amount);
      if (Number.isNaN(amount)) {
        return;
      }
      this.dispatch(
        workReportNewOrEditStateActions.changeFeedReport({
          feedUniqueKey: selectedUniqueKey,
          key: "amount",
          value: amount + 1 + "",
        })
      );
    });
  }

  @catchApplicationError()
  public decrementAmount() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { workReport, selectedReportKey, selectedUniqueKey } = state.mobile.workReportState.newOrEditStateType;
      if (!workReport || selectedReportKey !== "feed" || selectedUniqueKey === null) {
        throw new ApplicationError("不正な動作です。");
      }
      const feedReport = workReport.feedReports.find((fr) => getUniqueKey(fr) === selectedUniqueKey);
      if (!feedReport) {
        throw new ApplicationError("記録が見つかりませんでした。");
      }
      const amount = Number(feedReport.amount);
      if (Number.isNaN(amount) || amount - 1 < 0) {
        return;
      }
      this.dispatch(
        workReportNewOrEditStateActions.changeFeedReport({
          feedUniqueKey: selectedUniqueKey,
          key: "amount",
          value: amount - 1 + "",
        })
      );
    });
  }
}
