import * as _ from "lodash";
import { default as moment } from "moment";
import * as React from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import styled from "styled-components";
import { FormDateInput, FormTextInput } from "./Input";

// 各メソッドはロケール"ja"を流用 (日本語)
import { ja } from "date-fns/locale";
const locale = {
  ...ja,
  options: {
    // optionsはロケール"en-US"を流用 (日曜始まり)
    weekStartsOn: 0 /* Sunday */,
    firstWeekContainsDate: 1,
  },
};
registerLocale("ja", locale);

interface FormDatePickerProps {
  label?: string;
  date: Date | null;
  onChange: (date: Date) => void;
}

export class FormDatePicker extends React.Component<FormDatePickerProps> {
  public render() {
    const { label } = this.props;
    return (
      <div>
        <ReactDatePicker
          {...{
            customInput: <FormTextInput label={label} value={""} onChange={_.noop} />,
            locale: "ja",
            selected: this.props.date,
            onChange: this.props.onChange,
            dateFormat: "yyyy/MM/dd",
            renderCustomHeader: ({ date, decreaseMonth, increaseMonth }) => {
              // HACK: yyyy年M月表記にするためにカスタムヘッダを利用
              return (
                <>
                  <button
                    type="button"
                    className="react-datepicker__navigation react-datepicker__navigation--previous"
                    onClick={decreaseMonth}>
                    Previous Month
                  </button>
                  <div className="react-datepicker__current-month">{`${date.getFullYear()}年${date.getMonth() +
                    1}月`}</div>
                  <button
                    type="button"
                    className="react-datepicker__navigation react-datepicker__navigation--next"
                    onClick={increaseMonth}>
                    Next Month
                  </button>
                </>
              );
            },
          }}
        />
      </div>
    );
  }
}

interface FormInputDatePickerProps {
  date: Date | null;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DateInput = styled.input.attrs({ type: "date" })`
  position: fixed;
  top: -999px;
`;
const DisplayDate = styled.div`
  display: inline-block;
  width: 100%;
`;

export class FormTransparentDatePicker extends React.Component<FormInputDatePickerProps> {
  private input: HTMLInputElement | null = null;

  public render() {
    const date = moment(this.props.date || "invalid");
    const format = date.isValid() ? date.format("YYYY-MM-DD") : "";
    return (
      <>
        <DisplayDate onClick={this.handleClick}>{format}</DisplayDate>
        <DateInput ref={(node) => (this.input = node)} type={"date"} value={format} onChange={this.props.onChange} />
      </>
    );
  }

  private handleClick = () => {
    if (!!this.input) {
      this.input.click();
      this.input.focus();
    }
  };
}

export const FormInputDatePicker = (props: FormInputDatePickerProps) => {
  const date = moment(props.date || "invalid");
  const value = date.isValid() ? date.format("YYYY-MM-DD") : "";
  return <FormDateInput value={value} onChange={props.onChange} />;
};
