import { EditParentCarp, NewParentCarp, ParentCarp } from "../../domain/parentCarp";
import * as api from "../../libs/client";
import { translateApiToParentCarp, translateParentCarpToApi } from "./translate";

interface IParentCarpRepository {
  getParentCarps: () => Promise<ParentCarp[]>;
  postParentCarp: (parentCarp: NewParentCarp) => Promise<ParentCarp>;
  putParentCarp: (parentCarp: EditParentCarp) => Promise<ParentCarp>;
  deleteParentCarp: (parentCarp: ParentCarp) => Promise<ParentCarp>;
}

export class ParentCarpRepository implements IParentCarpRepository {
  public async getParentCarps(): Promise<ParentCarp[]> {
    const parentCarpsApi = new api.ParentCarpsApi(api.serverConfig);
    const response = await parentCarpsApi.getParentCarps();
    return response.data.map((d) => translateApiToParentCarp(d));
  }
  public async postParentCarp(parentCarp: NewParentCarp): Promise<ParentCarp> {
    const parentCarpsApi = new api.ParentCarpsApi(api.serverConfig);
    const response = await parentCarpsApi.postParentCarp(translateParentCarpToApi(parentCarp));
    return translateApiToParentCarp(response.data);
  }

  public async putParentCarp(parentCarp: EditParentCarp): Promise<ParentCarp> {
    const parentCarpsApi = new api.ParentCarpsApi(api.serverConfig);
    const response = await parentCarpsApi.putParentCarp(parentCarp.id, translateParentCarpToApi(parentCarp));
    return translateApiToParentCarp(response.data);
  }

  public async deleteParentCarp(parentCarp: ParentCarp): Promise<ParentCarp> {
    const parentCarpsApi = new api.ParentCarpsApi(api.serverConfig);
    const response = await parentCarpsApi.deleteParentCarp(parentCarp.id);
    return translateApiToParentCarp(response.data);
  }
}
