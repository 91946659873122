import * as React from "react";
import { Header } from "semantic-ui-react";
import styled from "styled-components";
import { DIMEN } from "../../../styles/Dimen";
import { FormDatePicker } from "../../atoms/Form/DatePicker";
import { ReportActionButton } from "../ReportFormButton";

interface WorkReportFormDateProps {
  title: string;
  date: Date;
  onChangeDate: (date: Date) => void;
  onClickAdd: (() => void) | null;
  onClickDelete: (() => void) | null;
}

export const WorkReportFormDateBody = ({
  title,
  date,
  onChangeDate,
  onClickAdd,
  onClickDelete,
}: WorkReportFormDateProps) => {
  return (
    <>
      <Title>
        {title}
        <ReportActionButton onClickAdd={onClickAdd} onClickDelete={onClickDelete} />
      </Title>
      <Content>
        <FormDatePicker label={"日付"} date={date} onChange={onChangeDate} />
      </Content>
    </>
  );
};

const Content = styled.div`
  padding: ${DIMEN.X1};
`;

export const Title = styled(Header).attrs({ as: "h2" })`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${DIMEN.X1};
`;
