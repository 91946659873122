import { applyMiddleware, compose, createStore, StoreEnhancer, StoreEnhancerStoreCreator } from "redux";
import thunk from "redux-thunk";
import { restoreLocalStorage, storeLocalStorage } from "./middleware/storeLocalStorage";
import { rootReducer } from "./modules";

export function configureStore() {
  const windowIfDefined = typeof window === "undefined" ? null : (window as any);
  const devToolsExtension = windowIfDefined && (windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__ as () => StoreEnhancer);
  return createStore(rootReducer, restoreLocalStorage(),
    compose(applyMiddleware(thunk),
      applyMiddleware(storeLocalStorage),
      devToolsExtension ? devToolsExtension() : <S>(next: StoreEnhancerStoreCreator<S>) => next)
  );
}
