import * as _ from "lodash";
import * as React from "react";
import { isBrowser, isMobile } from "react-device-detect";
import { DropdownProps, FormSelect as _FormSelect, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { FormTextInput } from "./Input";

interface SelectItem {
  id: number;
  name: string;
}

interface FormSelectProps {
  value: null | number;
  options: SelectItem[];
  onChange: (value: null | number) => void;
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
  minWidth?: number | string;
}

export const FormSelect = (props: FormSelectProps) => {
  return (
    <>
      {isBrowser && <BrowserFormSelect {...props} />}
      {isMobile && <MobileFormSelect {...props} />}
    </>
  );
};

/*** Browser ***/
export const BrowserFormSelect = ({
  value,
  options,
  onChange,
  label,
  disabled,
  readOnly,
  minWidth,
}: FormSelectProps) => {
  const selectValue = value === null ? Number.MIN_SAFE_INTEGER : value;
  const formattedOptions = options.map((i) => ({
    key: i.id,
    text: i.name,
    value: i.id,
    disabled: !(selectValue === i.id) && readOnly,
  }));
  return (
    <_FormSelect
      label={label}
      value={selectValue}
      onChange={onChangeBrowserSelect(onChange)}
      options={selectValue === Number.MIN_SAFE_INTEGER ? [BROWSER_EMPTY_OPTION, ...formattedOptions] : formattedOptions}
      disabled={disabled}
      style={{
        minWidth,
      }}
    />
  );
};

const onChangeBrowserSelect = (onChange: (value: null | number) => void) => (
  event: React.SyntheticEvent<HTMLElement>,
  { value }: DropdownProps
) => {
  const changedValue = value === Number.MIN_SAFE_INTEGER ? null : (value as number);
  onChange(changedValue);
};

export const getOption = (id: number, name: string): SelectItem => {
  return { id, name };
};

const BROWSER_EMPTY_OPTION = {
  key: Number.MIN_SAFE_INTEGER,
  value: Number.MIN_SAFE_INTEGER,
  text: "　",
};

/*** Multi ***/
interface FormSelectMultiProps {
  selectedIds: number[];
  options: SelectItem[];
  onChange: (ids: number[]) => void;
  label?: string;
  disabled?: boolean;
}

export const FormSelectMulti = ({ selectedIds, options, onChange, label, disabled }: FormSelectMultiProps) => {
  return (
    <_FormSelect
      label={label}
      value={selectedIds}
      onChange={onChangeSelectMulti(onChange)}
      options={options.map((i) => ({ key: i.id, text: i.name, value: i.id }))}
      disabled={disabled}
      multiple={true}
    />
  );
};

const onChangeSelectMulti = (onChange: (ids: number[]) => void) => (
  event: React.SyntheticEvent<HTMLElement>,
  { value }: DropdownProps
) => {
  const changeValue = value === undefined ? [] : (value as number[]);
  onChange(changeValue);
};

/*** Mobile ***/
export class MobileFormSelect extends React.Component<FormSelectProps> {
  private select: HTMLSelectElement | null = null;

  public render() {
    const { value, options, onChange, label, disabled, readOnly, minWidth } = this.props;
    const selectValue = value === null ? Number.MIN_SAFE_INTEGER : value;
    const selectOption = options.find((o) => o.id === selectValue);
    const displayOptions = selectValue === Number.MIN_SAFE_INTEGER ? [MOBILE_EMPTY_OPTION, ...options] : options;
    return (
      <MobileFormSelectOuter>
        <FormTextInput
          label={label}
          value={selectOption ? selectOption.name : ""}
          onChange={_.noop}
          onClick={this.onClickInput}
          readOnly={true}
          disabled={disabled}
        />
        <SelectBox
          ref={(node) => (this.select = node)}
          value={selectValue}
          onChange={onChangeMobileSelect(onChange)}
          style={{
            minWidth,
          }}
          onFocus={(e) => (e.target.size = displayOptions.length)}
          onBlur={(e) => (e.target.size = 0)}>
          {displayOptions.map((o) => (
            <option key={o.id} value={o.id} disabled={!(selectValue === o.id) && readOnly}>
              {o.name}
            </option>
          ))}
        </SelectBox>
        <DropDownIcon />
      </MobileFormSelectOuter>
    );
  }

  public onClickInput = () => {
    if (this.props.disabled) {
      return;
    }
    if (this.select === null) {
      return;
    }
    this.select.focus();
  };
}

const DropDownIcon = styled(Icon).attrs({ name: "dropdown" })`
  bottom: 0.8em;
  right: 1em;
  z-index: 3;
  position: absolute;
  opacity: 0.8 !important;
`;
const SelectBox = styled.select`
  opacity 0;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-appearance: none;
`;
const MobileFormSelectOuter = styled.div`
  position: relative;
`;
const onChangeMobileSelect = (onChange: (value: null | number) => void) => (
  event: React.ChangeEvent<HTMLSelectElement>
) => {
  const value = event.target.value;
  const changedValue = value === "" + Number.MIN_SAFE_INTEGER ? null : Number(value);
  onChange(changedValue);
};
const MOBILE_EMPTY_OPTION = {
  id: Number.MIN_SAFE_INTEGER,
  name: "　",
};
