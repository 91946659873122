export interface ListStateType {
  selectedYear: null | number;
}

export const getInitialState = (): ListStateType => {
  return { selectedYear: null };
};

export const ChangeHandler = {
  selectYear(state: ListStateType, selectedYear: null | number) {
    return { ...state, selectedYear };
  },
};
