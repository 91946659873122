import { actionCreatorFactory } from "typescript-fsa";
import { Worker } from "../../../../domain/worker";
// Actions
const actionCreator = actionCreatorFactory("ApiWorker");

const fetchStart = actionCreator("fetchStart");
const fetchComplete = actionCreator<FetchCompletePayload>("fetchComplete");
interface FetchCompletePayload {
  workers: Worker[];
}
const fetchFail = actionCreator("fetchFail");
const shouldFetch = actionCreator("shouldFetch");

// save
const createWorker = actionCreator<CreateWorkerPayload>("createWorker");
interface CreateWorkerPayload {
  worker: Worker;
}
const updateWorker = actionCreator<UpdateWorkerPayload>("updateWorker");
interface UpdateWorkerPayload {
  worker: Worker;
}
const deleteWorker = actionCreator<DeleteWorkerPayload>("deleteWorker");
interface DeleteWorkerPayload {
  worker: Worker;
}

export const apiWorkerActions = {
  fetchStart,
  fetchComplete,
  fetchFail,
  shouldFetch,
  createWorker,
  updateWorker,
  deleteWorker,
};

// Reducers
import { FetchHandlers, getInitialState, SaveHandlers, WorkerStateType } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const WorkerReducer = reducerWithInitialState<WorkerStateType>(getInitialState())
  .case(fetchStart, (state) => FetchHandlers.start(state))
  .case(fetchComplete, (state, { workers }) => FetchHandlers.complete(state, workers))
  .case(fetchFail, (state) => FetchHandlers.fail(state))
  .case(createWorker, (state, { worker }) => SaveHandlers.createWorker(state, worker))
  .case(updateWorker, (state, { worker }) => SaveHandlers.updateWorker(state, worker))
  .case(deleteWorker, (state, { worker }) => SaveHandlers.deleteWorker(state, worker));
