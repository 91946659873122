import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { getImageSrcURL } from "../../domain/image";
import { ApplicationState } from "../../store/modules";
import { imageGalleryStateActions } from "../../store/modules/imageGallery/ducks";
import { ImageGallery } from "../components/molecules/ImageGallery";

interface StateType {
  isOpen: boolean;
  imageIds: number[];
  initialImageId: number | null;
}

interface DispatchProps {
  closeModal: () => void;
}

type WrapperProps = StateType & DispatchProps & RouteComponentProps;

export class Wrapper extends React.Component<WrapperProps> {
  public render() {
    const { isOpen, imageIds, initialImageId, closeModal } = this.props;
    const images = imageIds.map((id) => ({ id, url: getImageSrcURL(id) }));
    return <ImageGallery isOpen={isOpen} onClose={closeModal} images={images} initialImageId={initialImageId} />;
  }
}

const mapStateToProps = (state: ApplicationState): StateType => {
  return {
    isOpen: state.imageGallery.showImageGallery,
    imageIds: state.imageGallery.imageIds,
    initialImageId: state.imageGallery.initialImageId,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    closeModal: () => dispatch(imageGalleryStateActions.hideImages()),
  };
};

export const ImageGalleryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapper));
