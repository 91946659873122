import { EditHerbicide, Herbicide, NewHerbicide } from "../../domain/herbicide";
import * as api from "../../libs/client";
import { translateApiToHerbicide, translateHerbicideToApi } from "./translate";

interface IHerbicideRepository {
  getHerbicides: () => Promise<Herbicide[]>;
  postHerbicide: (herbicide: NewHerbicide) => Promise<Herbicide>;
  putHerbicide: (herbicide: EditHerbicide) => Promise<Herbicide>;
  deleteHerbicide: (herbicide: Herbicide) => Promise<Herbicide>;
}

export class HerbicideRepository implements IHerbicideRepository {
  public async getHerbicides(): Promise<Herbicide[]> {
    const herbicidesApi = new api.HerbicidesApi(api.serverConfig);
    const response = await herbicidesApi.getHerbicides();
    return response.data.map((d) => translateApiToHerbicide(d));
  }
  public async postHerbicide(herbicide: NewHerbicide): Promise<Herbicide> {
    const herbicidesApi = new api.HerbicidesApi(api.serverConfig);
    const response = await herbicidesApi.postHerbicide(translateHerbicideToApi(herbicide));
    return translateApiToHerbicide(response.data);
  }

  public async putHerbicide(herbicide: EditHerbicide): Promise<Herbicide> {
    const herbicidesApi = new api.HerbicidesApi(api.serverConfig);
    const response = await herbicidesApi.putHerbicide(herbicide.id, translateHerbicideToApi(herbicide));
    return translateApiToHerbicide(response.data);
  }

  public async deleteHerbicide(herbicide: Herbicide): Promise<Herbicide> {
    const herbicidesApi = new api.HerbicidesApi(api.serverConfig);
    const response = await herbicidesApi.deleteHerbicide(herbicide.id);
    return translateApiToHerbicide(response.data);
  }
}
