import * as React from "react";
import styled from "styled-components";
import { COLOR } from "../../styles/Color";
import { DIMEN } from "../../styles/Dimen";

interface ReportListLayoutProps {
  header: React.ReactNode;
  list: React.ReactNode;
  content: React.ReactNode;
  listColumn?: number;
}
export const ReportListLayout = ({ header, list, content, listColumn = 1 }: ReportListLayoutProps) => {
  return (
    <Layout>
      <BodyHeader>{header}</BodyHeader>
      <Body>
        <List column={listColumn}>{list}</List>
        <Content column={listColumn}>{content}</Content>
      </Body>
    </Layout>
  );
};

const LIST_WIDTH = "14rem";
const HEADER_HEIGHT = DIMEN.X7;

const BodyHeader = styled.div`
  height: ${HEADER_HEIGHT};
  padding: ${DIMEN.X1} ${DIMEN.X1_5};
  border-bottom: 3px double ${COLOR.Gray.base};
`;
const Body = styled.div`
  height: calc(100% - ${HEADER_HEIGHT});
  display: flex;
  width: 100%;
`;

const Layout = styled.div`
  height: 100%;
`;
const List = styled.div`
  height: 100%;
  flex-basis: ${(props: { column: number }) => `calc(${LIST_WIDTH} * ${props.column})`};
  border-right: 1px solid ${COLOR.Gray["lighter-1"]};
`;
const Content = styled.div`
  height: 100%;
  flex-basis: ${(props: { column: number }) => `calc(100% - (${LIST_WIDTH} * ${props.column}))`};
  display: flex;
`;
