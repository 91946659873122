import { actionCreatorFactory } from "typescript-fsa";
// Actions
const actionCreator = actionCreatorFactory("ReportNavigationState");

const openList = actionCreator("openList");
const closeList = actionCreator("closeList");

const selectDate = actionCreator<SelectDatePayload>("selectDate");
interface SelectDatePayload {
  selectedDate: Date;
}

const selectPondArea = actionCreator<SelectPondAreaPayload>("selectPondArea");
interface SelectPondAreaPayload {
  selectedPondAreaId: null | number;
}

const selectPond = actionCreator<SelectPondPayload>("selectPond");
interface SelectPondPayload {
  selectedPondId: null | number;
}

export const reportNavigationActions = {
  openList,
  closeList,
  selectDate,
  selectPondArea,
  selectPond,
};

// Reducers
import { getInitialState, InitialHandlers, NavigationStateType, SelectHandlers } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const NavigationStateReducer = reducerWithInitialState<NavigationStateType>(getInitialState())
  .case(openList, (state) => InitialHandlers.openList(state))
  .case(closeList, (state) => InitialHandlers.closeList(state))
  .case(selectDate, (state, { selectedDate }) => SelectHandlers.selectedDate(state, selectedDate))
  .case(selectPondArea, (state, { selectedPondAreaId }) => SelectHandlers.selectPondArea(state, selectedPondAreaId))
  .case(selectPond, (state, { selectedPondId }) => SelectHandlers.selectPond(state, selectedPondId));
