import { combineReducers } from "redux";
import { ListStateType } from "./listState/reducer";
import { MoveModalStateType } from "./moveModalState/reducer";
import { NewOrEditStateType } from "./newOrEditState/reducer";

export interface PondReportStateType {
  listState: ListStateType;
  newOrEditStateType: NewOrEditStateType;
  moveModalState: MoveModalStateType;
}
import { pondReportListStateReducer } from "./listState/ducks";
import { moveModalStateReducer } from "./moveModalState/ducks";
import { pondReportNewOrEditStateReducer } from "./newOrEditState/ducks";

export const pondReportStateReducer = combineReducers({
  listState: pondReportListStateReducer,
  newOrEditStateType: pondReportNewOrEditStateReducer,
  moveModalState: moveModalStateReducer,
});
