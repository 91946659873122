import * as React from "react";
import styled from "styled-components";
import { DIMEN } from "../../../styles/Dimen";
import { SaveButton } from "../../atoms/Button/Mobile";
import { Form } from "../../atoms/Form/Form";
import { ContentSection, PageSection } from "../../atoms/Section";
import { Spinner } from "../../atoms/Spinner";
import { ReportAmountInput } from "../ReportAmountInput";
import { WorkReportFormNoteBody } from "../WorkReportFormNote";

interface EnvironmentReportFormProps {
  value: string;
  note: string;
  imageIds: number[];
  onChangeValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickValueUp: () => void;
  onClickValueDown: () => void;
  onChangeNote: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickImage: (imageIds: number[], imageId: number) => void;
  onClickImageRemove: (id: number) => void;
}
export const EnvironmentReportForm = (
  props: EnvironmentReportFormProps & { onClickSave: () => void; isSaving: boolean; disabledSave: boolean }
) => {
  return (
    <Form>
      {props.isSaving && <Spinner />}
      <PageSection>水温を記録</PageSection>
      <EnvironmentReportFormBody {...props} />
      <SaveButton onClick={props.onClickSave} disabled={props.disabledSave} />
    </Form>
  );
};

export const EnvironmentReportFormBody = (props: EnvironmentReportFormProps) => {
  return (
    <>
      <ContentSection>水温</ContentSection>
      <Content>
        <ReportAmountInput
          amount={props.value}
          unit={"℃"}
          onChangeAmount={props.onChangeValue}
          onClickAmountUp={props.onClickValueUp}
          onClickAmountDown={props.onClickValueDown}
        />
      </Content>
      <WorkReportFormNoteBody
        note={props.note}
        imageIds={props.imageIds}
        onChangeNote={props.onChangeNote}
        onChangeFile={props.onChangeFile}
        onClickImage={props.onClickImage}
        onClickImageRemove={props.onClickImageRemove}
      />
    </>
  );
};

const Content = styled.div`
  padding: ${DIMEN.X1};
`;
