import { Dispatch } from "redux";
import { Fertilizer } from "../../../domain/fertilizer";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { FertilizerRepository } from "../../../infrastracture/fertilizer/repository";
import { ApplicationState } from "../../../store/modules";
import { apiFertilizerActions } from "../../../store/modules/api/fertilizer/ducks";

interface IFertilizerApiService extends IApplicationService {
  init: () => void;
  fetchUnFetched: () => void;
}

export class FertilizerApiService implements IFertilizerApiService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError((dispatch) => dispatch(apiFertilizerActions.fetchFail()))
  public async init() {
    this.dispatch(apiFertilizerActions.fetchStart());
    const fertilizers: Fertilizer[] = await new FertilizerRepository().getFertilizers();
    this.dispatch(apiFertilizerActions.fetchComplete({ fertilizers }));
  }

  @catchApplicationError((dispatch) => dispatch(apiFertilizerActions.fetchFail()))
  public async fetchUnFetched() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      if (getState().api.fertilizer.isFetched) {
        return;
      }
      this.dispatch(apiFertilizerActions.fetchStart());
      const fertilizers: Fertilizer[] = await new FertilizerRepository().getFertilizers();
      this.dispatch(apiFertilizerActions.fetchComplete({ fertilizers }));
    });
  }
}
