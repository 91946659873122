import { WorkReport } from "../../../../domain/workReport";
import { FetchLifeCycle } from "../../../lifeCycle/fetchLifeCycle";

export interface WorkReportStateType extends FetchLifeCycle {
  workReports: WorkReport[];
}

export const getInitialState = (): WorkReportStateType => {
  return { workReports: [], isFetched: false, isFetching: false, isError: null };
};

export const FetchHandlers = {
  start(state: WorkReportStateType) {
    return { ...state, isFetched: false, isFetching: true, isError: null };
  },
  complete(state: WorkReportStateType, workReports: WorkReport[]) {
    return { ...state, workReports, isFetched: true, isFetching: false };
  },
  completeMerge(state: WorkReportStateType, workReports: WorkReport[]) {
    const ids = state.workReports.map((p) => p.id);
    const newReports = workReports.filter((pr) => !ids.includes(pr.id));
    const updateReports = state.workReports.map((pr) => {
      const r = workReports.find((p) => p.id === pr.id);
      return !!r ? r : pr;
    });
    return { ...state, workReports: newReports.concat(updateReports), isFetched: true, isFetching: true };
  },
  fail(state: WorkReportStateType, message: string = "") {
    return { ...state, isFetched: false, isFetching: false, isError: { message } };
  },
  shouldFetch(state: WorkReportStateType): WorkReportStateType {
    return { ...state, isFetched: false };
  },
};

export const SaveHandlers = {
  createWorkReport(state: WorkReportStateType, workReport: WorkReport): WorkReportStateType {
    return { ...state, workReports: state.workReports.concat(workReport) };
  },
  updateWorkReport(state: WorkReportStateType, workReport: WorkReport): WorkReportStateType {
    const workReports = state.workReports.map((w) => (w.id === workReport.id ? workReport : w));
    return { ...state, workReports };
  },
  deleteWorkReport(state: WorkReportStateType, workReport: WorkReport): WorkReportStateType {
    const workReports = state.workReports.filter((w) => w.id !== workReport.id);
    return { ...state, workReports };
  },
};
