import * as React from "react";
import { Table as _Table } from "semantic-ui-react";

export const TableHeader = _Table.Header;
export const TableHeaderCell = _Table.HeaderCell;
export const TableBody = _Table.Body;
export const TableRow = _Table.Row;
export const TableCell = _Table.Cell;

interface TableProps {
  definition?: boolean;
  children?: any;
}

export const Table = ({ definition, children }: TableProps) => {
  return <_Table definition={definition}>{children}</_Table>;
};
