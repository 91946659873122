import * as _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { getFiscalYear, isSameFiscalYear } from "../../../../domain/calendar";
import { NewOrEditCarpMovingReport } from "../../../../domain/carpMovingReport";
import {
  isTakeoutCarpSelectionNumber,
  NewOrEditCarpSelectionReport,
  removeEmptyCarpSelectionReport,
} from "../../../../domain/carpSelectionReport";
import { getUniqueKey, isActiveEntity, UniqueKey } from "../../../../domain/entity";
import { getImageSrcURL } from "../../../../domain/image";
import { ParentCarp } from "../../../../domain/parentCarp";
import { Pond } from "../../../../domain/pond";
import { PondArea } from "../../../../domain/pondArea";
import { NewOrEditPondDisinfectionReport } from "../../../../domain/pondDisinfectionReport";
import {
  getCurrentPondReport,
  getCurrentPondStatus,
  getFryCurrentAmount,
  getSubNumberName,
  isNewPondReport,
  NewOrEditPondReport,
  PondReport,
} from "../../../../domain/pondReport";
import { PondType } from "../../../../domain/pondType";
import { ScaleType } from "../../../../domain/scaleType";
import { MENU_HEADER_WIDTH } from "../../../pages/Layout";
import { Button, ButtonGroup } from "../../atoms/Button";
import { Form } from "../../atoms/Form/Form";
import { FormNumberInput } from "../../atoms/Form/Input";
import { FormSelect } from "../../atoms/Form/Select";
import { Image } from "../../atoms/Image";
import { Spinner } from "../../atoms/Spinner";
import { MasterFormButton } from "../../molecules/MasterFormButton";
import { DisinfectionReportTable } from "../../molecules/PondReportTable/DisinfectionReportTable";
import { FrySelectionReportTable } from "../../molecules/PondReportTable/FrySelectionReportTable";
import { POND_REPORT_FORM_PADDING, PondReportForm } from "../../molecules/ReportForm";
import {
  PondName,
  PondNameButtonGroup,
  RightAlignButtonGroup,
  SubNumberName,
  TableSeparate,
  YearButtonGroup,
} from "./elements";

interface FryPondReportFormProps {
  ponds: Pond[];
  pondReports: NewOrEditPondReport[];
  relatedPondReports: PondReport[];
  selectedPondArea: PondArea | null;
  selectedPond: Pond;
  selectedDate: Date;
  pondTypes: PondType[];
  parentCarps: ParentCarp[];
  carpQualityTypes: ScaleType[];
  carpSizeTypes: ScaleType[];
  malformationTypes: ScaleType[];
  carpRatioTypes: ScaleType[];
  onChangeParentCarp: (pondReportId: UniqueKey) => (parentCarpId: number | null) => void;
  onChangeCarpPairing: (pondReportId: UniqueKey) => (carpPairingId: number | null) => void;
  onChangeFiscalYear: (pondReportId: UniqueKey) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePondType: (pondReportId: UniqueKey) => (pondTypeId: number | null) => void;
  onChangePondDisinfectionReport: (
    reportId: UniqueKey
  ) => (disinfectionId: UniqueKey, key: keyof NewOrEditPondDisinfectionReport, value: any) => void;
  onChangeCarpMovingReport: (
    reportId: UniqueKey
  ) => (movingId: UniqueKey, key: keyof NewOrEditCarpMovingReport, value: any) => void;
  onClickCarpMovingReport: (reportId: UniqueKey) => (movingId: UniqueKey) => void;
  onChangeCarpSelectionReport: (
    reportId: UniqueKey
  ) => (selectionId: UniqueKey, key: keyof NewOrEditCarpSelectionReport, value: any) => void;
  onClickNew: () => void;
  onClickCreateTwice: () => void;
  onClickPrevYear: () => void;
  onClickCurrentYear: () => void;
  onClickNextYear: () => void;
  onClickMove: () => void;
  onClickImage: (imageIds: number[], imageId: number) => void;
  onClickSave: () => void;
  onClickCancel: () => void;
  isSaving: boolean;
}
interface FryPondReportFormState {
  isShowDetail: boolean;
}

export class FryPondReportForm extends React.Component<FryPondReportFormProps, FryPondReportFormState> {
  constructor(props: FryPondReportFormProps) {
    super(props);

    this.state = {
      isShowDetail: false,
    };
  }

  public render() {
    const isNoReports = this.props.pondReports.length === 0;
    const firstReport = !isNoReports ? this.props.pondReports[0] : null;
    const currentReport = getCurrentPondReport(
      this.props.pondReports,
      this.props.selectedPond.id,
      this.props.selectedDate
    );
    const isSameFiscalYearNow = this.isSameFiscalYearNow(
      !!firstReport ? firstReport.fiscalYear : null,
      this.props.selectedDate
    );

    return (
      <PondReportForm minWidth={`calc(1280px - ${MENU_HEADER_WIDTH} - ${POND_REPORT_FORM_PADDING} * 2)`}>
        {this.props.isSaving && <Spinner />}
        <Form>
          <PondName>
            {this.props.selectedPondArea && `${this.props.selectedPondArea.name} `}
            {this.props.selectedPond.name}
            <PondNameButtonGroup>
              <Button onClick={this.props.onClickNew}>新規</Button>
              {this.canCreateTwiceReport() && <Button onClick={this.props.onClickCreateTwice}>二毛作</Button>}
            </PondNameButtonGroup>
          </PondName>
          {!!firstReport && (
            <FormNumberInput
              label={"年度"}
              value={firstReport.fiscalYear}
              onChange={this.props.onChangeFiscalYear(getUniqueKey(firstReport))}
              readOnly={!this.canEditFiscalYear()}
            />
          )}
          {!firstReport && (
            <FormNumberInput
              label={"年度"}
              value={"" + getFiscalYear(this.props.selectedDate)}
              onChange={_.noop}
              readOnly={true}
            />
          )}
          {!!firstReport && !isSameFiscalYearNow && (
            <FormSelect
              value={firstReport.pondTypeId}
              options={this.props.pondTypes}
              onChange={this.props.onChangePondType(getUniqueKey(firstReport))}
              readOnly={!this.canEditPondType()}
            />
          )}

          <YearButtonGroup>
            <ButtonGroup className={"separate"}>
              <Button onClick={this.props.onClickPrevYear}>前年度</Button>
              <Button onClick={this.props.onClickCurrentYear}>今年度</Button>
              <Button onClick={this.props.onClickNextYear}>次年度</Button>
            </ButtonGroup>
          </YearButtonGroup>
          {this.props.pondReports.map((r, index) => {
            const selectedParentCarp = this.props.parentCarps.find((c) => c.id === r.parentCarpId);
            const carpPairings = selectedParentCarp
              ? selectedParentCarp.carpPairings.filter((pair) => pair.id === r.carpPairingId || isActiveEntity(pair))
              : [];
            const selectedCarpPair = carpPairings.find((pair) => pair.id === r.carpPairingId);
            const reportUniqueKey = getUniqueKey(r);

            const parentCarps = this.props.parentCarps.filter(
              (carp) => (isActiveEntity(carp) && "" + carp.year === r.fiscalYear) || carp.id === r.parentCarpId
            );
            return (
              <React.Fragment key={reportUniqueKey}>
                <SubNumberName>{getSubNumberName(r)}</SubNumberName>
                <FormSelect
                  value={r.parentCarpId}
                  label={"親鯉"}
                  options={parentCarps}
                  onChange={this.props.onChangeParentCarp(reportUniqueKey)}
                />

                <FormSelect
                  value={r.carpPairingId}
                  label={"組み合わせ名"}
                  options={carpPairings}
                  onChange={this.props.onChangeCarpPairing(reportUniqueKey)}
                />
                {!!selectedCarpPair &&
                  selectedCarpPair.imageIds.map((id) => (
                    <ImageInner key={id}>
                      <Image
                        src={getImageSrcURL(id)}
                        onClick={() => this.props.onClickImage(selectedCarpPair.imageIds, id)}
                      />
                    </ImageInner>
                  ))}
                <DisinfectionReportTable
                  pondDisinfectionReports={r.pondDisinfectionReports}
                  onChangeDisinfectionReports={this.handleChangeDisinfectionReport(reportUniqueKey)}
                />

                <RightAlignButtonGroup>
                  <Button onClick={this.props.onClickMove} disabled={!this.canMoveCarp(currentReport, getUniqueKey(r))}>
                    鯉を移動
                  </Button>
                </RightAlignButtonGroup>
                <FrySelectionReportTable
                  ponds={this.props.ponds}
                  pondReports={this.props.relatedPondReports}
                  carpSizeTypes={this.props.carpSizeTypes}
                  carpQualityTypes={this.props.carpQualityTypes}
                  malformationTypes={this.props.malformationTypes}
                  carpRatioTypes={this.props.carpRatioTypes}
                  carpSelectionReports={r.carpSelectionReports}
                  carpMovingReports={r.carpMovingReports}
                  onChangeSelectionReport={this.handleChangeCarpSelectionReport(reportUniqueKey)}
                  onChangeMovingReport={this.handleChangeCarpMovingReport(reportUniqueKey)}
                  onClickMovingReport={this.handleClickCarpMovingReport(reportUniqueKey)}
                />
                {index !== this.props.pondReports.length - 1 && <TableSeparate />}
              </React.Fragment>
            );
          })}
          {!isNoReports && (
            <MasterFormButton onClickSave={this.props.onClickSave} onClickCancel={this.props.onClickCancel} />
          )}
        </Form>
      </PondReportForm>
    );
  }

  private canEditPondType = (): boolean => {
    return this.props.pondReports.every(isNewPondReport);
  };

  private canEditFiscalYear = (): boolean => {
    return this.props.pondReports.every(isNewPondReport);
  };

  private canCreateTwiceReport = (): boolean => {
    if (this.props.pondReports.length !== 1) {
      return false;
    }
    const firstReport = this.props.pondReports[0];
    if (!firstReport) {
      return false;
    }
    if (isNewPondReport(firstReport)) {
      return false;
    }

    const removedEmptyCarpSelectionReports = removeEmptyCarpSelectionReport(firstReport.carpSelectionReports);
    if (removedEmptyCarpSelectionReports.length === 0) {
      return false;
    }
    const currentAmountOfFirstReport = getFryCurrentAmount(firstReport);
    return currentAmountOfFirstReport === 0;
  };

  private canMoveCarp = (currentReport: NewOrEditPondReport | null, uniqueKey: UniqueKey): boolean => {
    if (currentReport === null) {
      return false;
    }
    if (getUniqueKey(currentReport) !== uniqueKey) {
      return false;
    }
    const currentStatus = getCurrentPondStatus(currentReport);
    if (currentStatus === null || currentStatus.statusKey !== "selection") {
      return false;
    }
    return !isTakeoutCarpSelectionNumber(currentStatus.selectionNumber);
  };

  private isSameFiscalYearNow = (fiscalYear: string | null, selectedDate: Date) => {
    if (fiscalYear === null) {
      return isSameFiscalYear(selectedDate, new Date());
    }
    return fiscalYear === "" + getFiscalYear(new Date());
  };

  private handleChangeDisinfectionReport = (reportId: UniqueKey) => {
    return this.props.onChangePondDisinfectionReport(reportId);
  };

  private handleChangeCarpMovingReport = (reportId: UniqueKey) => {
    return this.props.onChangeCarpMovingReport(reportId);
  };

  private handleClickCarpMovingReport = (reportId: UniqueKey) => {
    return this.props.onClickCarpMovingReport(reportId);
  };

  private handleChangeCarpSelectionReport = (reportId: UniqueKey) => {
    return this.props.onChangeCarpSelectionReport(reportId);
  };
}

const ImageInner = styled.div`
  vertical-align: top;
  display: inline-block;
  width: 250px;
  height: auto;
  margin: 0 auto;
`;

interface NoPondReportForm {
  selectedPondArea: PondArea | null;
  selectedPond: Pond;
  onClickNew: () => void;
}

export const NoPondReportForm = (props: NoPondReportForm) => {
  return (
    <PondReportForm>
      <Form>
        <PondName>
          {props.selectedPondArea && `${props.selectedPondArea.name} `}
          {props.selectedPond.name}
          <PondNameButtonGroup>
            <Button onClick={props.onClickNew}>新規</Button>
          </PondNameButtonGroup>
        </PondName>
      </Form>
    </PondReportForm>
  );
};
