import { NewOrEditAdultCarpReport } from "../../../../../domain/adultCarpReport";
import { NewOrEditCarpMovingReport } from "../../../../../domain/carpMovingReport";
import { NewOrEditCarpSelectionReport } from "../../../../../domain/carpSelectionReport";
import { getUniqueKey, isNewEntity, UniqueKey } from "../../../../../domain/entity";
import { NewOrEditPondDisinfectionReport } from "../../../../../domain/pondDisinfectionReport";
import { NewOrEditPondReport, NewPondReport } from "../../../../../domain/pondReport";
import { EditLifeCycle } from "../../../../lifeCycle/editLifeCycle";

export interface NewOrEditStateType extends EditLifeCycle {
  pondReports: NewOrEditPondReport[] | null;
  selectedDate: Date;
}

export const getInitialState = (): NewOrEditStateType => {
  return {
    pondReports: null,
    selectedDate: new Date(),
    isUnSave: false,
    isSaved: false,
    isSaving: false,
    isError: null,
  };
};

export const InitialHandler = {
  createPondReports(state: NewOrEditStateType, pondReports: NewPondReport[]): NewOrEditStateType {
    return { ...state, pondReports, isUnSave: false, isSaved: false, isError: null };
  },
  selectPondReports(state: NewOrEditStateType, pondReports: NewOrEditPondReport[]): NewOrEditStateType {
    return { ...state, pondReports, isUnSave: false, isSaved: false, isError: null };
  },
};

export const ChangeHandler = {
  addPondReport(state: NewOrEditStateType, pondReport: NewPondReport): NewOrEditStateType {
    if (state.pondReports === null) {
      return state;
    }
    return { ...state, pondReports: state.pondReports.concat(pondReport), isUnSave: true };
  },
  changeSelectedDate(state: NewOrEditStateType, selectedDate: Date): NewOrEditStateType {
    return { ...state, selectedDate };
  },
  changePondReport(
    state: NewOrEditStateType,
    reportUniqueKey: UniqueKey,
    key: keyof NewOrEditPondReport,
    value: any
  ): NewOrEditStateType {
    if (state.pondReports === null) {
      return state;
    }
    const pondReports = state.pondReports.map((pr) => {
      const uniqueKey = getUniqueKey(pr);
      if (uniqueKey !== reportUniqueKey) {
        return pr;
      }
      return {
        ...pr,
        [key]: value,
      };
    });
    return { ...state, pondReports, isUnSave: true };
  },
  changeCarpMovingReport(
    state: NewOrEditStateType,
    reportUniqueKey: UniqueKey,
    movingUniqueKey: UniqueKey,
    key: keyof NewOrEditCarpMovingReport,
    value: any
  ): NewOrEditStateType {
    if (state.pondReports === null) {
      return state;
    }
    const mapCarpMovingReports = (cmr: NewOrEditCarpMovingReport) => {
      const uk = getUniqueKey(cmr);
      if (uk !== movingUniqueKey) {
        return cmr;
      }
      return {
        ...cmr,
        [key]: value,
      };
    };
    const pondReports = state.pondReports.map((pr) => {
      const uniqueKey = getUniqueKey(pr);
      if (uniqueKey !== reportUniqueKey) {
        return pr;
      }
      const carpMovingReports = isNewEntity(pr)
        ? pr.carpMovingReports.map(mapCarpMovingReports)
        : pr.carpMovingReports.map(mapCarpMovingReports);
      return { ...pr, carpMovingReports } as NewOrEditPondReport;
    });
    return { ...state, pondReports, isUnSave: true };
  },
  changeCarpSelectionReport(
    state: NewOrEditStateType,
    reportUniqueKey: UniqueKey,
    selectionUniqueKey: UniqueKey,
    key: keyof NewOrEditCarpSelectionReport,
    value: any
  ): NewOrEditStateType {
    if (state.pondReports === null) {
      return state;
    }
    const mapCarpSelectionReports = (csr: NewOrEditCarpSelectionReport) => {
      const uk = getUniqueKey(csr);
      if (uk !== selectionUniqueKey) {
        return csr;
      }
      return {
        ...csr,
        [key]: value,
      };
    };
    const pondReports = state.pondReports.map((pr) => {
      const uniqueKey = getUniqueKey(pr);
      if (uniqueKey !== reportUniqueKey) {
        return pr;
      }
      const carpSelectionReports = isNewEntity(pr)
        ? pr.carpSelectionReports.map(mapCarpSelectionReports)
        : pr.carpSelectionReports.map(mapCarpSelectionReports);
      return { ...pr, carpSelectionReports } as NewOrEditPondReport;
    });
    return { ...state, pondReports, isUnSave: true };
  },
  changePondDisinfectionReport(
    state: NewOrEditStateType,
    reportUniqueKey: UniqueKey,
    disinfectionUniqueKey: UniqueKey,
    key: keyof NewOrEditPondDisinfectionReport,
    value: any
  ): NewOrEditStateType {
    if (state.pondReports === null) {
      return state;
    }
    const mapPondDisinfectionReports = (pdr: NewOrEditPondDisinfectionReport) => {
      const uk = getUniqueKey(pdr);
      if (uk !== disinfectionUniqueKey) {
        return pdr;
      }
      return {
        ...pdr,
        [key]: value,
      };
    };
    const pondReports = state.pondReports.map((pr) => {
      const uniqueKey = getUniqueKey(pr);
      if (uniqueKey !== reportUniqueKey) {
        return pr;
      }
      const pondDisinfectionReports = isNewEntity(pr)
        ? pr.pondDisinfectionReports.map(mapPondDisinfectionReports)
        : pr.pondDisinfectionReports.map(mapPondDisinfectionReports);
      return { ...pr, pondDisinfectionReports } as NewOrEditPondReport;
    });
    return { ...state, pondReports, isUnSave: true };
  },
  changeAdultCarpReport(
    state: NewOrEditStateType,
    reportUniqueKey: UniqueKey,
    adultUniqueKey: UniqueKey,
    key: keyof NewOrEditAdultCarpReport,
    value: any
  ): NewOrEditStateType {
    if (state.pondReports === null) {
      return state;
    }
    const mapAdultCarpReports = (csr: NewOrEditAdultCarpReport) => {
      const uk = getUniqueKey(csr);
      if (uk !== adultUniqueKey) {
        return csr;
      }
      return {
        ...csr,
        [key]: value,
      };
    };
    const pondReports = state.pondReports.map((pr) => {
      const uniqueKey = getUniqueKey(pr);
      if (uniqueKey !== reportUniqueKey) {
        return pr;
      }
      const adultCarpReports = isNewEntity(pr)
        ? pr.adultCarpReports.map(mapAdultCarpReports)
        : pr.adultCarpReports.map(mapAdultCarpReports);
      return { ...pr, adultCarpReports } as NewOrEditPondReport;
    });
    return { ...state, pondReports, isUnSave: true };
  },
};

export const SaveHandler = {
  cancelPondReports(state: NewOrEditStateType, pondReports: NewOrEditPondReport[]): NewOrEditStateType {
    return { ...state, pondReports, isUnSave: false, isSaved: false };
  },
  saveStart(state: NewOrEditStateType): NewOrEditStateType {
    return { ...state, isSaving: true, isSaved: false, isError: null };
  },
  saveSuccess(state: NewOrEditStateType, pondReports: NewOrEditPondReport[]): NewOrEditStateType {
    return { ...state, pondReports, isUnSave: false, isSaved: true, isSaving: false };
  },
  saveFail(state: NewOrEditStateType, message: string = ""): NewOrEditStateType {
    return { ...state, isUnSave: true, isSaved: false, isSaving: false, isError: { message } };
  },
};
