import { Header } from "semantic-ui-react";
import styled from "styled-components";
import { COLOR } from "../../../styles/Color";
import { DIMEN } from "../../../styles/Dimen";

export const PondName = styled(Header).attrs({ as: "h2" })`
  display: inline-block;
  margin-right: ${DIMEN.X2};
`;

export const RightAlignButtonGroup = styled.div`
  text-align: right;
`;

export const PondNameButtonGroup = styled(RightAlignButtonGroup)`
  display: inline-block;
  margin-left: ${DIMEN.X2_5};
`;

export const YearButtonGroup = styled(RightAlignButtonGroup)`
  margin-top: ${DIMEN.X1};
`;

export const TableSeparate = styled.div`
  margin: 2rem 0;
  border: 1px solid ${COLOR.Gray["lighter-2"]};
`;
