import styled from "styled-components";
import { COLOR } from "../../../styles/Color";
import { DIMEN } from "../../../styles/Dimen";

export const ListItem = styled.div`
  padding: ${DIMEN.X1};
  border-bottom: 1px solid ${COLOR.Gray.base};
  cursor: pointer;
  :hover {
    background-color: ${COLOR.Gray["lighter-3"]};
  }
  &.active {
    font-weight: bold;
    background-color: ${COLOR.Gray["lighter-2"]};
  }
`;
