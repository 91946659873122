import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NavigationService } from "../../../../application/mobile/navigation";
import { NewOrEditStateService } from "../../../../application/mobile/workReport/newOrEditState";
import { isSameFiscalYear } from "../../../../domain/calendar";
import { Pond } from "../../../../domain/pond";
import { PondType } from "../../../../domain/pondType";
import { WorkReport } from "../../../../domain/workReport";
import { ApplicationState } from "../../../../store/modules";
import { ImageStateType } from "../../../../store/modules/api/image/reducer";
import { imageGalleryStateActions } from "../../../../store/modules/imageGallery/ducks";
import { NavigationStateType } from "../../../../store/modules/mobile/navigation/reducer";
import { NewOrEditStateType } from "../../../../store/modules/mobile/workReport/newOrEditState/reducer";
import { Spinner } from "../../../components/atoms/Spinner";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { NotificationModal } from "../../../components/molecules/NotificationModal";
import { WorkReportFormNote } from "../../../components/molecules/WorkReportFormNote";
import { MobileLayout } from "../../Layout/Mobile";

interface StateProps {
  ponds: Pond[];
  pondTypes: PondType[];
  workReports: WorkReport[];
  navigationState: NavigationStateType;
  newOrEditState: NewOrEditStateType;
  image: ImageStateType;
  isLoaded: boolean;
}

interface DispatchProps {
  newOrEditStateService: NewOrEditStateService;
  navigationService: NavigationService;
  showImageGallery: (imageIds: number[], imageId: number) => void;
}

type WorkReportNotePageProps = StateProps & DispatchProps & RouteComponentProps;

interface WorkReportNotePageState {
  isOpenConfirmModal: boolean;
}

export class Wrapped extends React.Component<WorkReportNotePageProps, WorkReportNotePageState> {
  constructor(props: WorkReportNotePageProps) {
    super(props);

    this.state = {
      isOpenConfirmModal: false,
    };
  }

  public async componentWillMount() {
    if (this.props.navigationState.selectedPondId === null) {
      this.props.history.push("/");
      return;
    }
    if (!this.props.isLoaded) {
      this.props.history.push("/reports/menu");
      return;
    }
    await this.props.newOrEditStateService.initWorkReport();
  }

  public render() {
    const { workReport, isSaving } = this.props.newOrEditState;
    const pond = this.props.ponds.find((p) => p.id === this.props.navigationState.selectedPondId);

    if (!pond || !workReport) {
      return (
        <MobileLayout>
          <BaseNavigationHeader title={""} onClickReturn={this.handleClickReturn} />
          <BaseNavigationContent>
            <Spinner />
          </BaseNavigationContent>
        </MobileLayout>
      );
    }

    return (
      <MobileLayout>
        <BaseNavigationHeader title={pond.name} onClickReturn={this.handleClickReturn} />
        <BaseNavigationContent>
          <WorkReportFormNote
            pondTypes={this.props.pondTypes}
            note={workReport.note}
            imageIds={workReport.imageIds}
            pondTypeId={workReport.pondTypeId}
            onChangeNote={this.handleChangeNote}
            onChangeFile={this.handleChangeFile}
            onClickImage={this.handleClickImage}
            onClickImageRemove={this.handleClickImageRemove}
            onChangePondType={isSameFiscalYear(workReport.date, new Date()) ? null : this.handleChangePondType}
            onClickSave={this.handleClickSave}
            isSaving={isSaving}
            disabledSave={this.props.image.isSaving}
          />
        </BaseNavigationContent>
        <NotificationModal
          isOpen={this.state.isOpenConfirmModal}
          onClose={() => this.setState({ isOpenConfirmModal: false })}
          onClickOk={this.handleModalOk}
          okText={"OK"}
          message={"保存しました"}
        />
      </MobileLayout>
    );
  }

  private handleChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.props.newOrEditStateService.changeNote(e.target.value);
  };

  private handleClickImage = (imageIds: number[], imageId: number) => {
    this.props.showImageGallery(imageIds, imageId);
  };

  private handleClickImageRemove = (id: number) => {
    this.props.newOrEditStateService.removeImage(id);
  };

  private handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length !== 0) {
      this.props.newOrEditStateService.addImage(e.target.files[0]);
    }
  };

  private handleChangePondType = (id: number | null) => {
    if (id === null) {
      return;
    }
    this.props.newOrEditStateService.changePondType(id);
  };

  private handleClickReturn = () => {
    this.props.history.goBack();
  };

  private handleClickSave = async () => {
    await this.props.newOrEditStateService.saveWorkReport();
    if (this.props.newOrEditState.isSaved) {
      this.setState({ isOpenConfirmModal: true });
    }
  };

  private handleModalOk = () => {
    this.setState({ isOpenConfirmModal: false });
    this.props.history.goBack();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { pond, pondType, workReport } = state.api;
  return {
    ponds: pond.ponds,
    pondTypes: pondType.pondTypes,
    workReports: workReport.workReports,
    navigationState: state.mobile.navigation,
    newOrEditState: state.mobile.workReportState.newOrEditStateType,
    image: state.api.image,
    isLoaded: pond.isFetched,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    newOrEditStateService: new NewOrEditStateService(dispatch),
    navigationService: new NavigationService(dispatch),
    showImageGallery: (imageIds, imageId) =>
      dispatch(imageGalleryStateActions.showImages({ imageIds, initialImageId: imageId })),
  };
};

export const WorkReportNotePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
