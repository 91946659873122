import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { EditStateService } from "../../../application/master/parentCarps/editState";
import { ListStateService } from "../../../application/master/parentCarps/listState";
import { isActiveEntity, isEntity } from "../../../domain/entity";
import { ParentCarp } from "../../../domain/parentCarp";
import { ApplicationState } from "../../../store/modules";
import { ListStateType } from "../../../store/modules/master/parentCarp/listState/reducer";
import { NewOrEditStateType } from "../../../store/modules/master/parentCarp/newOrEditState/reducer";
import { MasterGroupList } from "../../components/molecules/MasterGroupList";
import { MasterItem, MasterList } from "../../components/molecules/MasterList";
import { COLOR } from "../../styles/Color";

interface StateProps {
  parentCarps: ParentCarp[];
  newOrEditState: NewOrEditStateType;
  listState: ListStateType;
}

interface DispatchProps {
  editStateService: EditStateService;
  listStateService: ListStateService;
}

type ParentCarpsListProps = StateProps & DispatchProps;

export class Wrapped extends React.Component<ParentCarpsListProps> {
  public render() {
    const { parentCarp } = this.props.newOrEditState;
    const { selectedYear } = this.props.listState;
    const filteredParentCarps = this.props.parentCarps.filter((p) => p.year === selectedYear);
    return (
      <Outer>
        <Inner>
          <MasterGroupList
            items={toGroupList(this.props.parentCarps)}
            selectedItemId={this.props.listState.selectedYear}
            onClickItem={this.handleClickYear}
          />
        </Inner>
        <Inner>
          <MasterList
            items={filteredParentCarps}
            selectedItemId={parentCarp !== null && isEntity(parentCarp) ? parentCarp.id : null}
            onClickItem={this.handleClickListItem}
          />
        </Inner>
      </Outer>
    );
  }

  private handleClickYear = (id: number) => {
    this.props.listStateService.selectYear(id);
  };

  private handleClickListItem = (id: number) => {
    this.props.editStateService.selectParentCarp(id);
  };
}

const toGroupList = (parentCarps: ParentCarp[]): MasterItem[] => {
  const years = parentCarps.reduce((yearObj, p) => {
    if (p.year === null) {
      return yearObj;
    }
    yearObj[p.year] = p.year;
    return yearObj;
  }, {});
  return Object.keys(years)
    .sort((a, b) => +b - +a)
    .map((year) => ({ id: +year, name: year }));
};

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { newOrEditState, listState } = state.master.parentCarp;
  return {
    parentCarps: state.api.parentCarp.parentCarps.filter(isActiveEntity),
    newOrEditState,
    listState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    editStateService: new EditStateService(dispatch),
    listStateService: new ListStateService(dispatch),
  };
};

export const ParentCarpsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);

const Outer = styled.div`
  display: flex;
  height: 100%;
`;
const Inner = styled.div`
  flex: 1 1 0;
  border-right: 1px solid ${COLOR.Gray.base};
  height: 100%;
  overflow-y: auto;
  &:last-child {
    border-right: none;
  }
`;
