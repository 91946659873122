import * as React from "react";
import { BrowserView } from "react-device-detect";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AlertModalContainer } from "../presentation/containers/AlertModal";
import { AuthenticationContainer } from "../presentation/containers/Authentication";
import { ErrorBoundaryContainer } from "../presentation/containers/ErrorBoundary";
import { ImageGalleryContainer } from "../presentation/containers/ImageGallery";
import { NotificationContainer } from "../presentation/containers/Notification";
import { EnvironmentReportPage } from "../presentation/pages/EnvironmentReport";
import { FeedsPage } from "../presentation/pages/Feeds";
import { FertilizersPage } from "../presentation/pages/Fertilizers";
import { HerbicidesPage } from "../presentation/pages/Herbicides";
import { LoginPage } from "../presentation/pages/Login";
import { ParentCarpsPage } from "../presentation/pages/ParentCarps";
import { PondAreasPage } from "../presentation/pages/PondAreas";
import { PondReportsPage } from "../presentation/pages/PondReport";
import { PondsPage } from "../presentation/pages/Ponds";
import { ReportListPage } from "../presentation/pages/ReportList";
import { WorkersPage } from "../presentation/pages/Workers";
import { WorkReportFeedPage } from "../presentation/pages/WorkReport/WorkReportFeed";
import { WorkReportFertilizerPage } from "../presentation/pages/WorkReport/WorkReportFertilizer";
import { WorkReportHerbicidePage } from "../presentation/pages/WorkReport/WorkReportHerbicide";
import { WorkReportNotePage } from "../presentation/pages/WorkReport/WorkReportNote";

export const PCRouter = () => {
  return (
    <BrowserView style={{ height: "100%", width: "100%" }}>
      <BrowserRouter>
        <ErrorBoundaryContainer>
          <AlertModalContainer />
          <NotificationContainer />
          <Switch>
            <Route path="/login" exact component={LoginPage} />
            <AuthenticationContainer>
              <ImageGalleryContainer />
              <Switch>
                <Route path="/workers" exact component={WorkersPage} />
                <Route path="/feeds" exact component={FeedsPage} />
                <Route path="/fertilizers" exact component={FertilizersPage} />
                <Route path="/pond_areas" exact component={PondAreasPage} />
                <Route path="/ponds" exact component={PondsPage} />
                <Route path="/parent_carps" exact component={ParentCarpsPage} />
                <Route path="/herbicides" exact component={HerbicidesPage} />
                <Route path="/reports/list" exact component={ReportListPage} />
                <Route path="/pond_reports" exact component={PondReportsPage} />
                <Route path="/work_reports/feed" exact component={WorkReportFeedPage} />
                <Route path="/work_reports/fertilizer" exact component={WorkReportFertilizerPage} />
                <Route path="/work_reports/herbicide" exact component={WorkReportHerbicidePage} />
                <Route path="/work_reports/note" exact component={WorkReportNotePage} />
                <Route path="/environment_reports" exact component={EnvironmentReportPage} />
                <Route component={ParentCarpsPage} />
              </Switch>
            </AuthenticationContainer>
          </Switch>
        </ErrorBoundaryContainer>
      </BrowserRouter>
    </BrowserView>
  );
};
