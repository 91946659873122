import { default as moment } from "moment";
import { Dispatch } from "redux";
import { getUniqueKey, UniqueKey } from "../../../domain/entity";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { ApplicationState } from "../../../store/modules";
import { pondReportNewOrEditStateActions } from "../../../store/modules/mobile/pondReport/newOrEditState/ducks";

interface IAdultCarpReportStateService extends IApplicationService {
  changeAmount: (amount: string) => void;
  changeAmountUp: () => void;
  changeAmountDown: () => void;
  changeDate: (date: Date) => void;
  changeDateToToday: () => void;
  changeDateToYesterday: () => void;
  changeDateToYesterdayMinusOne: () => void;
  changeNote: (note: string) => void;
}

export class AdultCarpReportStateService implements IAdultCarpReportStateService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public changeAmount(amount: string) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      if (!this.canEditState(state)) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        pondReportNewOrEditStateActions.changeAdultCarpReport({
          adultUniqueKey: this.getSelectionUniqueKey(state),
          key: "amount",
          value: amount,
        })
      );
    });
  }

  @catchApplicationError()
  public changeAmountUp() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      if (!this.canEditState(state)) {
        throw new ApplicationError("不正な動作です。");
      }
      const { listState, newOrEditStateType } = state.mobile.pondReportState;
      const { pondReport } = newOrEditStateType;
      if (!pondReport) {
        throw new ApplicationError("不正な動作です。");
      }
      const adultCarpReport = pondReport.adultCarpReports.find(
        (acr) => getUniqueKey(acr) === listState.selectedUniqueKey
      );
      if (!adultCarpReport) {
        throw new ApplicationError("不正な動作です。");
      }
      const amount = Number(adultCarpReport.amount);
      if (!Number.isNaN(amount)) {
        this.dispatch(
          pondReportNewOrEditStateActions.changeAdultCarpReport({
            adultUniqueKey: this.getSelectionUniqueKey(state),
            key: "amount",
            value: amount + 1,
          })
        );
      }
    });
  }

  @catchApplicationError()
  public changeAmountDown() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      if (!this.canEditState(state)) {
        throw new ApplicationError("不正な動作です。");
      }
      const { listState, newOrEditStateType } = state.mobile.pondReportState;
      const { pondReport } = newOrEditStateType;
      if (!pondReport) {
        throw new ApplicationError("不正な動作です。");
      }
      const adultCarpReport = pondReport.adultCarpReports.find(
        (acr) => getUniqueKey(acr) === listState.selectedUniqueKey
      );
      if (!adultCarpReport) {
        throw new ApplicationError("不正な動作です。");
      }
      const amount = Number(adultCarpReport.amount);
      if (!Number.isNaN(amount) && amount - 1 >= 0) {
        this.dispatch(
          pondReportNewOrEditStateActions.changeAdultCarpReport({
            adultUniqueKey: this.getSelectionUniqueKey(state),
            key: "amount",
            value: amount - 1,
          })
        );
      }
    });
  }

  @catchApplicationError()
  public changeDate(date: Date) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      if (!this.canEditState(state)) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        pondReportNewOrEditStateActions.changeAdultCarpReport({
          adultUniqueKey: this.getSelectionUniqueKey(state),
          key: "date",
          value: date,
        })
      );
    });
  }

  @catchApplicationError()
  public changeDateToToday() {
    const date = new Date();
    this.changeDate(date);
  }

  @catchApplicationError()
  public changeDateToYesterday() {
    const date = moment().add(-1, "days");
    this.changeDate(date.toDate());
  }

  @catchApplicationError()
  public changeDateToYesterdayMinusOne() {
    const date = moment().add(-2, "days");
    this.changeDate(date.toDate());
  }

  @catchApplicationError()
  public changeNote(note: string) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      if (!this.canEditState(state)) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        pondReportNewOrEditStateActions.changeAdultCarpReport({
          adultUniqueKey: this.getSelectionUniqueKey(state),
          key: "note",
          value: note,
        })
      );
    });
  }

  private canEditState(state: ApplicationState) {
    const { selectedReportKey, selectedUniqueKey } = state.mobile.pondReportState.listState;
    return selectedReportKey === "AdultCarp" && selectedUniqueKey !== null;
  }

  private getSelectionUniqueKey = (state: ApplicationState): UniqueKey => {
    const { selectedUniqueKey } = state.mobile.pondReportState.listState;
    if (selectedUniqueKey === null) {
      throw new Error("PondReport SelectedUniqueKey is null.");
    }
    return selectedUniqueKey;
  };
}
