import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NewOrEditStateService as EnvironmentReportService } from "../../../../application/mobile/environmentReport/newOrEditState";
import { NavigationService } from "../../../../application/mobile/navigation";
import { NewOrEditStateService as WorkReportService } from "../../../../application/mobile/workReport/newOrEditState";
import { NewOrEditEnvironmentReport } from "../../../../domain/environmentReport";
import { Pond } from "../../../../domain/pond";
import { isNumber } from "../../../../domain/validation";
import { NewOrEditWorkReport } from "../../../../domain/workReport";
import { ApplicationState } from "../../../../store/modules";
import { ImageStateType } from "../../../../store/modules/api/image/reducer";
import { imageGalleryStateActions } from "../../../../store/modules/imageGallery/ducks";
import { NewOrEditStateType as EnvironmentReportNewOrEditStateType } from "../../../../store/modules/mobile/environmentReport/newOrEditState/reducer";
import { NavigationStateType } from "../../../../store/modules/mobile/navigation/reducer";
import { NewOrEditStateType as WorkReportNewOrEditStateType } from "../../../../store/modules/mobile/workReport/newOrEditState/reducer";
import { Spinner } from "../../../components/atoms/Spinner";
import { EnvironmentReportForm } from "../../../components/molecules/EnvironmentReportForm";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { MobileLayout } from "../../Layout/Mobile";

interface StateProps {
  ponds: Pond[];
  navigationState: NavigationStateType;
  environmentReportNewOrEditState: EnvironmentReportNewOrEditStateType;
  workReportNewOrEditState: WorkReportNewOrEditStateType;
  image: ImageStateType;
}

interface OwnProps {
  environmentReport: NewOrEditEnvironmentReport;
  workReport: NewOrEditWorkReport;
  onClickSave: () => void;
  openActionModal: () => void;
}

interface DispatchProps {
  environmentReportService: EnvironmentReportService;
  navigationService: NavigationService;
  workReportService: WorkReportService;
  showImageGallery: (imageIds: number[], imageId: number) => void;
}

type EnvironmentReportPageProps = StateProps & OwnProps & DispatchProps & RouteComponentProps;

export class Wrapped extends React.Component<EnvironmentReportPageProps> {
  public componentWillMount(): void {
    if (this.props.navigationState.selectedPondId === null) {
      this.props.history.push("/");
      return;
    }
  }

  public render() {
    const pond = this.props.ponds.find((p) => p.id === this.props.navigationState.selectedPondId);
    const { isSaving } = this.props.environmentReportNewOrEditState;
    const { environmentReport, workReport } = this.props;

    if (!pond || !environmentReport || !workReport) {
      return (
        <MobileLayout>
          <BaseNavigationHeader title={""} onClickReturn={this.handleClickReturn} />
          <BaseNavigationContent>
            <Spinner />
          </BaseNavigationContent>
        </MobileLayout>
      );
    }

    return (
      <MobileLayout>
        <BaseNavigationHeader
          title={pond.name}
          onClickReturn={this.handleClickReturn}
          onClickAction={this.props.openActionModal}
        />
        <BaseNavigationContent>
          <EnvironmentReportForm
            value={environmentReport.value}
            note={workReport.note}
            imageIds={workReport.imageIds}
            onChangeValue={this.handleChangeValue}
            onClickValueUp={this.handleClickValueUp}
            onClickValueDown={this.handleClickValueDown}
            onChangeNote={this.handleChangeNote}
            onChangeFile={this.handleChangeFile}
            onClickImage={this.handleClickImage}
            onClickImageRemove={this.handleClickImageRemove}
            onClickSave={this.props.onClickSave}
            isSaving={isSaving}
            disabledSave={this.props.image.isSaving}
          />
        </BaseNavigationContent>
      </MobileLayout>
    );
  }

  private handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const num = e.target.value;
    if (isNumber(num)) {
      this.props.environmentReportService.changeValue(num);
    }
  };

  private handleClickValueUp = () => {
    this.props.environmentReportService.incrementValue();
  };

  private handleClickValueDown = () => {
    this.props.environmentReportService.decrementValue();
  };

  private handleChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.props.workReportService.changeNote(e.target.value);
  };

  private handleClickImage = (imageIds: number[], imageId: number) => {
    this.props.showImageGallery(imageIds, imageId);
  };

  private handleClickImageRemove = (id: number) => {
    this.props.workReportService.removeImage(id);
  };

  private handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length !== 0) {
      this.props.workReportService.addImage(e.target.files[0]);
    }
  };

  private handleClickReturn = () => {
    this.props.history.goBack();
  };
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StateProps & OwnProps => {
  const { pond } = state.api;
  return {
    ponds: pond.ponds,
    navigationState: state.mobile.navigation,
    environmentReportNewOrEditState: state.mobile.environmentReportState.newOrEditStateType,
    workReportNewOrEditState: state.mobile.workReportState.newOrEditStateType,
    image: state.api.image,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    environmentReportService: new EnvironmentReportService(dispatch),
    navigationService: new NavigationService(dispatch),
    workReportService: new WorkReportService(dispatch),
    showImageGallery: (imageIds, imageId) =>
      dispatch(imageGalleryStateActions.showImages({ imageIds, initialImageId: imageId })),
  };
};

export const EnvironmentReportNewOrEditPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
