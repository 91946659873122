import { actionCreatorFactory } from "typescript-fsa";
import { EditFertilizer, Fertilizer, NewFertilizer, NewOrEditFertilizer } from "../../../../../domain/fertilizer";
// Actions
const actionCreator = actionCreatorFactory("MasterFertilizerNewOrEditState");

const createFertilizer = actionCreator<CreateFertilizerPayload>("createFertilizer");
interface CreateFertilizerPayload {
  fertilizer: NewFertilizer;
}

const selectFertilizer = actionCreator<SelectFertilizerPayload>("selectFertilizer");
interface SelectFertilizerPayload {
  fertilizer: EditFertilizer;
}

const changeFertilizer = actionCreator<ChangeFertilizerPayload>("changeFertilizer");
interface ChangeFertilizerPayload {
  key: keyof NewOrEditFertilizer;
  value: any;
}

export const cancelFertilizer = actionCreator<CancelFertilizerPayload>("cancelFertilizer");
interface CancelFertilizerPayload {
  fertilizer: NewOrEditFertilizer;
}

export const saveStart = actionCreator("saveStart");
export const saveSuccess = actionCreator<SaveSuccessPayload>("saveSuccess");
interface SaveSuccessPayload {
  fertilizer: Fertilizer | null;
}
export const saveFail = actionCreator("saveFail");

export const masterFertilizerNewOrEditStateActions = {
  createFertilizer,
  selectFertilizer,
  changeFertilizer,
  cancelFertilizer,
  saveStart,
  saveSuccess,
  saveFail,
};

// Reducers
import { ChangeHandler, getInitialState, InitialHandler, NewOrEditStateType, SaveHandler } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const NewOrEditStateReducer = reducerWithInitialState<NewOrEditStateType>(getInitialState())
  .case(createFertilizer, (state, { fertilizer }) => {
    return InitialHandler.createFertilizer(state, fertilizer);
  })
  .case(selectFertilizer, (state, { fertilizer }) => {
    return InitialHandler.selectFertilizer(state, fertilizer);
  })
  .case(changeFertilizer, (state, { key, value }) => {
    return ChangeHandler.changeFertilizer(state, key, value);
  })
  .case(cancelFertilizer, (state, { fertilizer }) => {
    return SaveHandler.cancelFertilizer(state, fertilizer);
  })
  .case(saveStart, (state) => {
    return SaveHandler.saveStart(state);
  })
  .case(saveSuccess, (state, { fertilizer }) => {
    return SaveHandler.saveSuccess(state, fertilizer);
  })
  .case(saveFail, (state) => {
    return SaveHandler.saveFail(state);
  });
