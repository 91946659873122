import * as React from "react";
import styled from "styled-components";
import { DIMEN } from "../../../styles/Dimen";
import { AddNewButton, CancelButton, DeleteButton, SaveButton } from "../../atoms/Button";

interface MasterFormButtonProps {
  onClickSave: () => void;
  disabledSave?: boolean;
  onClickCancel: () => void;
}

export const MasterFormButton = ({ onClickSave, disabledSave, onClickCancel }: MasterFormButtonProps) => {
  return (
    <ButtonGroup>
      <SaveButton disabled={disabledSave} onClick={onClickSave}>
        保存
      </SaveButton>
      <CancelButton onClick={onClickCancel}>キャンセル</CancelButton>
    </ButtonGroup>
  );
};

export const ButtonGroup = styled.div`
  margin: ${DIMEN.X1} 0;
  text-align: center;
`;

interface MasterActionButton {
  onClickAdd: (() => void) | null;
  onClickDelete: (() => void) | null;
}

export const MasterActionButton = ({ onClickAdd, onClickDelete }: MasterActionButton) => {
  return (
    <ActionButtonGroup>
      {onClickDelete !== null && <DeleteButton onClick={onClickDelete} />}
      {onClickAdd !== null && <AddNewButton onClick={onClickAdd} />}
    </ActionButtonGroup>
  );
};

const ActionButtonGroup = styled.div`
  > button {
    margin-left: 1em !important;
  }
  text-align: right;
`;
