import { Dispatch } from "redux";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { masterParentCarpListStateActions } from "../../../store/modules/master/parentCarp/listState/ducks";

interface IListStateService extends IApplicationService {
  selectYear: (year: number) => void;
}

export class ListStateService implements IListStateService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public selectYear(year: number) {
    this.dispatch(masterParentCarpListStateActions.selectYear({ selectedYear: year }));
  }
}
