import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NavigationService } from "../../../../application/mobile/navigation";
import { ReportListStateService } from "../../../../application/mobile/reportList";
import { EnvironmentReport } from "../../../../domain/environmentReport";
import { Feed } from "../../../../domain/feed";
import { FeedReasonType } from "../../../../domain/feedReasonType";
import { Fertilizer } from "../../../../domain/fertilizer";
import { Herbicide } from "../../../../domain/herbicide";
import { ParentCarp } from "../../../../domain/parentCarp";
import { Pond } from "../../../../domain/pond";
import { PondReport } from "../../../../domain/pondReport";
import { ReportGroup } from "../../../../domain/reportGroup";
import { ScaleType } from "../../../../domain/scaleType";
import { UseMethodType } from "../../../../domain/useMethodType";
import { canGoBack } from "../../../../domain/window";
import { WorkReport } from "../../../../domain/workReport";
import { ApplicationState } from "../../../../store/modules";
import { NavigationStateType } from "../../../../store/modules/mobile/navigation/reducer";
import { ReportListStateType } from "../../../../store/modules/mobile/reportListState/reducer";
import { Spinner } from "../../../components/atoms/Spinner";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { DateSelectionSection } from "../../../components/molecules/ReportListSection";
import { ReportList } from "../../../components/organisms/ReportList";
import { MobileLayout } from "../../Layout/Mobile";

interface StateProps {
  workReports: WorkReport[];
  pondReports: PondReport[];
  environmentReports: EnvironmentReport[];

  ponds: Pond[];
  parentCarps: ParentCarp[];
  feeds: Feed[];
  feedReasonTypes: FeedReasonType[];
  fertilizers: Fertilizer[];
  herbicides: Herbicide[];
  useMethodTypes: UseMethodType[];
  scaleTypes: ScaleType[];

  reportListState: ReportListStateType;
  navigationState: NavigationStateType;
}

interface DispatchProps {
  reportListStateService: ReportListStateService;
  navigationService: NavigationService;
}

type ReportListPageProps = StateProps & DispatchProps & RouteComponentProps;

export class Wrapped extends React.Component<ReportListPageProps> {
  public componentWillMount(): void {
    if (this.props.navigationState.selectedPondId === null) {
      this.props.history.push("/");
      return;
    }

    Promise.all([
      this.props.reportListStateService.fetchMaster(),
      this.props.reportListStateService.fetchReport(this.props.reportListState.displayDate),
    ]);
  }

  public render() {
    const pond = this.props.ponds.find((p) => p.id === this.props.navigationState.selectedPondId);

    if (!pond) {
      return (
        <MobileLayout>
          <BaseNavigationHeader title={""} onClickReturn={this.handleClickReturn} />
          <BaseNavigationContent>
            <Spinner />
          </BaseNavigationContent>
        </MobileLayout>
      );
    }

    const { selectedReportTypeIds } = this.props.reportListState.reportFilterState;

    return (
      <MobileLayout>
        <BaseNavigationHeader
          title={pond.name}
          onClickReturn={this.handleClickReturn}
          onClickFilter={this.handleClickFilterIcon}
        />
        <BaseNavigationContent>
          <DateSelectionSection
            selectedDate={this.props.reportListState.displayDate}
            onClickPrevYear={this.handleClickPrevYear}
            onClickPrevMonth={this.handleClickPrevMonth}
            onClickNextMonth={this.handleClickNextMonth}
            onClickNextYear={this.handleClickNextYear}
          />
          <ReportList
            selectedDate={this.props.reportListState.displayDate}
            selectedPondId={this.props.navigationState.selectedPondId}
            // TODO: フィルターの条件を元に上手いことやる
            workReports={this.props.workReports}
            pondReports={this.props.pondReports}
            // TODO: フィルターの条件を元に上手いことやる
            environmentReports={this.props.environmentReports}
            ponds={this.props.ponds}
            parentCarps={this.props.parentCarps}
            feeds={this.props.feeds}
            feedReasonTypes={this.props.feedReasonTypes}
            fertilizers={this.props.fertilizers}
            herbicides={this.props.herbicides}
            useMethodTypes={this.props.useMethodTypes}
            scaleTypes={this.props.scaleTypes}
            isAscDate={this.props.reportListState.reportSortingState.isAscDate}
            isDisplayFeedReport={selectedReportTypeIds.includes(ReportGroup.Feed)}
            isDisplayFertilizerReport={selectedReportTypeIds.includes(ReportGroup.Fertilizer)}
            isDisplayHerbicideReport={selectedReportTypeIds.includes(ReportGroup.Herbicide)}
            isDisplayNoteReport={selectedReportTypeIds.includes(ReportGroup.Note)}
            isDisplayEnvironmentReport={selectedReportTypeIds.includes(ReportGroup.EnvironmentReport)}
            isDisplayPondReport={selectedReportTypeIds.includes(ReportGroup.PondReport)}
            onClickDate={this.handleClickDate}
          />
        </BaseNavigationContent>
      </MobileLayout>
    );
  }

  private handleClickFilterIcon = () => {
    this.props.history.push("/reports/filter");
  };

  private handleClickDate = (date: Date) => {
    this.props.reportListStateService.changeSelectedDate(date);
    this.props.history.push("/reports/detail");
  };
  private handleClickPrevYear = () => {
    this.props.reportListStateService.changePrevYear();
  };
  private handleClickPrevMonth = () => {
    this.props.reportListStateService.changePrevMonth();
  };
  private handleClickNextMonth = () => {
    this.props.reportListStateService.changeNextMonth();
  };
  private handleClickNextYear = () => {
    this.props.reportListStateService.changeNextYear();
  };

  private handleClickReturn = () => {
    if (canGoBack()) {
      this.props.history.goBack();
    }
    this.props.history.push("/reports/menu");
    return;
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const {
    workReport,
    pondReport,
    environmentReport,
    pond,
    parentCarp,
    feed,
    feedReasonType,
    fertilizer,
    herbicide,
    useMethodType,
    scaleType,
  } = state.api;
  return {
    workReports: workReport.workReports,
    pondReports: pondReport.pondReports,
    environmentReports: environmentReport.environmentReports,
    ponds: pond.ponds,
    parentCarps: parentCarp.parentCarps,
    feeds: feed.feeds,
    feedReasonTypes: feedReasonType.feedReasonTypes,
    fertilizers: fertilizer.fertilizers,
    herbicides: herbicide.herbicides,
    useMethodTypes: useMethodType.useMethodTypes,
    scaleTypes: scaleType.scaleTypes,

    reportListState: state.mobile.reportListState,
    navigationState: state.mobile.navigation,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    reportListStateService: new ReportListStateService(dispatch),
    navigationService: new NavigationService(dispatch),
  };
};

export const ReportListPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
