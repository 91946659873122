import { actionCreatorFactory } from "typescript-fsa";
import { ParentCarp } from "../../../../domain/parentCarp";

// Actions
const actionCreator = actionCreatorFactory("ApiParentCarp");

const fetchStart = actionCreator("fetchStart");
const fetchComplete = actionCreator<FetchCompletePayload>("fetchComplete");
interface FetchCompletePayload {
  parentCarps: ParentCarp[];
}
const fetchFail = actionCreator("fetchFail");
const shouldFetch = actionCreator("shouldFetch");

// save
const createParentCarp = actionCreator<CreateParentCarpPayload>("createParentCarp");
interface CreateParentCarpPayload {
  parentCarp: ParentCarp;
}
const updateParentCarp = actionCreator<UpdateParentCarpPayload>("updateParentCarp");
interface UpdateParentCarpPayload {
  parentCarp: ParentCarp;
}
const deleteParentCarp = actionCreator<DeleteParentCarpPayload>("deleteParentCarp");
interface DeleteParentCarpPayload {
  parentCarp: ParentCarp;
}

export const apiParentCarpActions = {
  fetchStart,
  fetchComplete,
  fetchFail,
  shouldFetch,
  createParentCarp,
  updateParentCarp,
  deleteParentCarp,
};

// Reducers
import { FetchHandlers, getInitialState, ParentCarpStateType, SaveHandlers } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const ParentCarpReducer = reducerWithInitialState<ParentCarpStateType>(getInitialState())
  .case(fetchStart, (state) => FetchHandlers.start(state))
  .case(fetchComplete, (state, { parentCarps }) => FetchHandlers.complete(state, parentCarps))
  .case(fetchFail, (state) => FetchHandlers.fail(state))
  .case(shouldFetch, (state) => FetchHandlers.shouldFetch(state))
  .case(createParentCarp, (state, { parentCarp }) => SaveHandlers.createParentCarp(state, parentCarp))
  .case(updateParentCarp, (state, { parentCarp }) => SaveHandlers.updateParentCarp(state, parentCarp))
  .case(deleteParentCarp, (state, { parentCarp }) => SaveHandlers.deleteParentCarp(state, parentCarp));
