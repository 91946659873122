import { CarpPairing, isNewCarpPairing, NewOrEditCarpPairing } from "../../domain/carpPairing";
import { isNewParentCarp, NewOrEditParentCarp, ParentCarp } from "../../domain/parentCarp";
import * as Api from "../../libs/apiClient";

export function translateApiToParentCarp(apiParentCarp: Api.ParentCarp): ParentCarp {
  return {
    id: apiParentCarp.id as number,
    name: apiParentCarp.name,
    year: apiParentCarp.year,
    carpVarietyTypeId: apiParentCarp.carpVarietyTypeId,
    carpPairings: apiParentCarp.carpPairings.map(translateApiToCarpPairing),
    position: apiParentCarp.position,
    isDeleted: apiParentCarp.isDeleted,
  };
}

export function translateParentCarpToApi(parentCarp: NewOrEditParentCarp): Api.ParentCarp {
  return {
    id: isNewParentCarp(parentCarp) ? undefined : parentCarp.id,
    name: parentCarp.name,
    year: parentCarp.year as number,
    carpVarietyTypeId: parentCarp.carpVarietyTypeId as number,
    carpPairings: isNewParentCarp(parentCarp)
      ? parentCarp.carpPairings.map(translateCarpPairingToApi)
      : parentCarp.carpPairings.map(translateCarpPairingToApi),
    position: parentCarp.position,
    isDeleted: parentCarp.isDeleted,
  };
}

export function translateApiToCarpPairing(apiCarpParing: Api.CarpPairing): CarpPairing {
  return {
    id: apiCarpParing.id as number,
    name: apiCarpParing.name,
    position: apiCarpParing.position,
    imageIds: apiCarpParing.imageIds,
    isDeleted: apiCarpParing.isDeleted,
  };
}

export function translateCarpPairingToApi(carpPairing: NewOrEditCarpPairing): Api.CarpPairing {
  return {
    id: isNewCarpPairing(carpPairing) ? undefined : carpPairing.id,
    name: carpPairing.name,
    position: carpPairing.position,
    imageIds: carpPairing.imageIds,
    isDeleted: carpPairing.isDeleted,
  };
}
