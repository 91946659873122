import * as React from "react";
import { Image as _Image } from "semantic-ui-react";
import styled from "styled-components";
import { COLOR } from "../../../styles/Color";
import { DIMEN } from "../../../styles/Dimen";
import { IconButton } from "../Button";
import { Spinner } from "../Spinner";

interface ImageProps {
  src: string;
  fluid?: boolean;
  size?: "small";
  onClick?: () => void;
}
export const Image = (props: ImageProps) => {
  return <_Image src={props.src} size={props.size} fluid={props.fluid} onClick={props.onClick} />;
};

export const ImageHeader = (props: ImageProps) => {
  return (
    <_Image
      src={props.src}
      height={DIMEN.X6}
      width={DIMEN.X6}
      style={{ backgroundColor: "#fff", padding: DIMEN.X0_5, borderRadius: "3px" }}
    />
  );
};

interface EditImageProps extends ImageProps {
  onClickRemove: () => void;
  isLoading: boolean;
}

export const EditImage = (props: EditImageProps) => {
  return (
    <ImageOuter onClick={props.onClick}>
      {!props.isLoading && (
        <ImageInner>
          <Image src={props.src} size={props.size} />
        </ImageInner>
      )}
      {props.isLoading && (
        <SpinnerOuter>
          <Spinner />
        </SpinnerOuter>
      )}
      <RemoveButton size={"tiny"} onClick={handleClickRemoveImage(props.onClickRemove)} />
    </ImageOuter>
  );
};
const handleClickRemoveImage = (onClickRemove: () => void) => (e: React.MouseEvent<HTMLButtonElement>) => {
  e.stopPropagation();
  onClickRemove();
};
const ImageOuter = styled.div`
  position: relative;
  display: inline-block;
`;
const SpinnerOuter = styled.div`
  height: 250px;
  width: 250px;
  background-color: ${COLOR.Gray.base};
`;
const ImageInner = styled.div`
  width: 250px;
  height: auto;
`;
const RemoveButton = styled(IconButton).attrs({ icon: "close" })`
  position: absolute;
  top: ${DIMEN.X0_5};
  right: ${DIMEN.X0_5};
  background-color: ${COLOR.Base.white} !important;
`;
