import * as React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";
import { COLOR } from "../../../styles/Color";
import { DIMEN } from "../../../styles/Dimen";

const ListItem = styled.div`
  padding: ${DIMEN.X1};
  padding-left: 5em;
  border-bottom: 1px solid ${COLOR.Gray["lighter-1"]};
  cursor: pointer;
  position: relative;
  &:hover {
    background-color: ${COLOR.Gray["lighter-3"]};
  }
`;
const CheckIcon = styled(Icon).attrs({ name: "check" })`
  color: ${COLOR.AccentColor.base};
  position: absolute;
  left: 2em;
`;

interface CheckListItemProps {
  onClick: () => void;
  active?: boolean;
  children?: any;
}

export const CheckListItem = (props: CheckListItemProps) => {
  return (
    <ListItem onClick={props.onClick}>
      {props.active && <CheckIcon />}
      {props.children}
    </ListItem>
  );
};
