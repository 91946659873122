import { isEntity } from "../../domain/entity";
import { Fertilizer, NewOrEditFertilizer } from "../../domain/fertilizer";
import * as Api from "../../libs/apiClient";

export function translateApiToFertilizer(apiFertilizer: Api.Fertilizer): Fertilizer {
  return {
    id: apiFertilizer.id as number,
    name: apiFertilizer.name,
    position: apiFertilizer.position,
    isDeleted: apiFertilizer.isDeleted,
  };
}

export function translateFertilizerToApi(fertilizer: NewOrEditFertilizer): Api.Fertilizer {
  return {
    id: isEntity(fertilizer) ? fertilizer.id : undefined,
    name: fertilizer.name,
    position: fertilizer.position,
    isDeleted: fertilizer.isDeleted,
  };
}
