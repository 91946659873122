import * as React from "react";
import styled from "styled-components";
import { DIMEN } from "../../../styles/Dimen";

interface ReportFormProps {
  children?: any;
}

export const ReportForm = (props: ReportFormProps) => {
  return <ReportOuter>{props.children}</ReportOuter>;
};

interface PondReportFormProps {
  minWidth?: string;
  children?: any;
}
export const PondReportForm = (props: PondReportFormProps) => {
  return <PondReportOuter style={{ minWidth: props.minWidth }}>{props.children}</PondReportOuter>;
};
export const POND_REPORT_FORM_PADDING = DIMEN.X2;

export const ReportOuter = styled.div``;
export const PondReportOuter = styled.div`
  padding: ${POND_REPORT_FORM_PADDING};
`;
