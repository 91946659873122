import * as _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NavigationStateService } from "../../../application/report/navigation";
import { FertilizerReportStateService } from "../../../application/report/workReport/fertilizerReportState";
import { NewOrEditStateService } from "../../../application/report/workReport/newOrEditState";
import { isSameFiscalYear } from "../../../domain/calendar";
import { getUniqueKey, isActiveEntity, isEntity, isNewEntity } from "../../../domain/entity";
import { Fertilizer } from "../../../domain/fertilizer";
import { Pond } from "../../../domain/pond";
import { PondArea } from "../../../domain/pondArea";
import { PondType } from "../../../domain/pondType";
import { isFertilizerReportGroup } from "../../../domain/reportGroup";
import { SearchQuery } from "../../../domain/searchQuery";
import { UseMethodType } from "../../../domain/useMethodType";
import { WorkReport } from "../../../domain/workReport";
import { ApplicationState } from "../../../store/modules";
import { ImageStateType } from "../../../store/modules/api/image/reducer";
import { imageGalleryStateActions } from "../../../store/modules/imageGallery/ducks";
import { notificationQueueStateActions } from "../../../store/modules/notification/queue/ducks";
import { NavigationStateType } from "../../../store/modules/report/navigation/reducer";
import { NewOrEditStateType } from "../../../store/modules/report/workReport/newOrEditState/reducer";
import { ConfirmModal } from "../../components/molecules/ConfirmModal";
import { PageHeader } from "../../components/molecules/PageHeader";
import { WorkReportFormFertilizer } from "../../components/organisms/WorkReportFormFertilizer";
import { MainLayout } from "../Layout";
import { ReportLayout } from "../Layout/Report";
import { DefaultPage, SpinnerPage } from "./DefaultPage";
import { PondsReportsList } from "./PondsReportsList";

interface StateProps {
  ponds: Pond[];
  pondAreas: PondArea[];
  pondTypes: PondType[];
  fertilizers: Fertilizer[];
  useMethodTypes: UseMethodType[];
  workReports: WorkReport[];
  newOrEditState: NewOrEditStateType;
  navigationState: NavigationStateType;
  imageState: ImageStateType;
}

interface DispatchProps {
  fertilizerReportStateService: FertilizerReportStateService;
  newOrEditStateService: NewOrEditStateService;
  navigationStateService: NavigationStateService;
  showImageGallery: (imageIds: number[], imageId: number) => void;

  notifySaveMessage: () => void;
  notifyDeleteMessage: () => void;
}

type WorkReportFertilizerPageProps = StateProps & DispatchProps & RouteComponentProps;
interface WorkReportFertilizerPageState {
  isOpenDeleteConfirm: boolean;
}

export class Wrapped extends React.Component<WorkReportFertilizerPageProps, WorkReportFertilizerPageState> {
  constructor(props: WorkReportFertilizerPageProps) {
    super(props);

    this.state = {
      isOpenDeleteConfirm: false,
    };
  }

  public componentWillMount(): void {
    this.initStateOnMounted();
  }

  public render() {
    return (
      <MainLayout>
        <ReportLayout
          header={<PageHeader title={"肥料を記録"} />}
          list={this.renderList()}
          form={this.renderForm()}
          listColumn={3}
        />
      </MainLayout>
    );
  }

  private async initStateOnMounted() {
    await Promise.all([
      this.props.navigationStateService.fetchApi(),
      this.props.fertilizerReportStateService.fetchApi(),
    ]);
    if (
      this.props.navigationState.selectedPondId !== null &&
      this.props.newOrEditState.selectedReportKey !== "fertilizer"
    ) {
      await Promise.all([
        this.props.newOrEditStateService.initWorkReport(),
        this.props.fertilizerReportStateService.fetchLatestFertilizerReport(),
      ]);
      this.props.fertilizerReportStateService.initFertilizerReport();
    }
  }

  private renderList() {
    const { workReport, selectedUniqueKey } = this.props.newOrEditState;
    const entity = workReport ? this.props.workReports.find((wr) => wr.id === getUniqueKey(workReport)) : null;

    const fertilizerReports = entity ? entity.fertilizerReports : [];
    const items = fertilizerReports.map((fr) => {
      const fertilizer = this.props.fertilizers.find((f) => f.id === fr.fertilizerId);

      return {
        id: getUniqueKey(fr) as number,
        name: fertilizer ? fertilizer.name : "",
      };
    });
    const selectedItemId = typeof selectedUniqueKey === "number" ? selectedUniqueKey : null;

    return (
      <PondsReportsList
        items={items}
        selectedItemId={selectedItemId}
        onClickItem={this.handleClickItem}
        onClickPond={this.handleClickPond}
      />
    );
  }

  private renderForm() {
    const pond = this.props.ponds.find((p) => p.id === this.props.navigationState.selectedPondId);
    const { newOrEditState, navigationState } = this.props;
    const { selectedPondId, selectedDate } = navigationState;
    if (selectedPondId === null) {
      this.renderEmpty();
    }
    const { workReport, selectedReportKey, selectedUniqueKey, isSaving } = newOrEditState;

    const fertilizerReport =
      selectedReportKey === "fertilizer" &&
      !!workReport &&
      workReport.fertilizerReports.find((fr) => getUniqueKey(fr) === selectedUniqueKey);

    if (!pond) {
      return this.renderEmpty();
    }

    if (!fertilizerReport || workReport === null) {
      return this.renderSpinner();
    }

    const fertilizers = this.props.fertilizers.filter(
      (fertilizer) => isActiveEntity(fertilizer) || fertilizerReport.fertilizerId === fertilizer.id
    );

    return (
      <>
        <WorkReportFormFertilizer
          title={this.getFormTitle()}
          date={selectedDate}
          fertilizers={fertilizers}
          useMethodTypes={this.props.useMethodTypes}
          pondTypes={this.props.pondTypes}
          fertilizerId={fertilizerReport.fertilizerId}
          amount={fertilizerReport.amount}
          useMethodTypeId={fertilizerReport.useMethodTypeId}
          note={workReport.note}
          imageIds={workReport.imageIds}
          pondTypeId={workReport.pondTypeId}
          onChangeDate={this.handleChangeDate}
          onChangeFertilizer={this.handleChangeFertilizer}
          onChangeAmount={this.handleChangeAmount}
          onChangeUseMethodType={this.handleChangeUseMethodType}
          onClickAmountUp={_.noop}
          onClickAmountDown={_.noop}
          onChangeNote={this.handleChangeNote}
          onChangeFile={this.handleChangeFile}
          onClickImage={this.handleClickImage}
          onClickImageRemove={this.handleClickImageRemove}
          onChangePondType={isSameFiscalYear(workReport.date, new Date()) ? null : this.handleChangePondType}
          onClickSave={this.handleClickSave}
          onClickCancel={this.handleClickCancel}
          onClickAdd={isNewEntity(fertilizerReport) ? null : this.handleClickAdd}
          onClickDelete={isNewEntity(fertilizerReport) ? null : this.openDeleteConfirmModal}
          isSaving={isSaving}
          disabledSave={this.props.imageState.isSaving}
        />
        <ConfirmModal
          isOpen={this.state.isOpenDeleteConfirm}
          onClose={this.closeDeleteConfirmModal}
          onClickCancel={this.closeDeleteConfirmModal}
          onClickOk={this.handleClickDelete}
          cancelText={"キャンセル"}
          okText={"削除"}
          confirmMessage={"肥料記録を削除してもよろしいでしょうか？"}
        />
      </>
    );
  }

  private getFormTitle(): string {
    const { ponds, pondAreas, navigationState } = this.props;
    const { selectedPondId, selectedPondAreaId } = navigationState;
    const pond = ponds.find((p) => p.id === selectedPondId);
    const pondArea = pondAreas.find((a) => a.id === selectedPondAreaId);

    return `${pondArea ? pondArea.name : ""} ${pond ? pond.name : ""}`;
  }

  private renderEmpty() {
    return <DefaultPage />;
  }

  private renderSpinner() {
    return <SpinnerPage />;
  }

  private handleClickPond = async (id: number) => {
    this.props.navigationStateService.selectPond(id);
    await Promise.all([
      this.props.newOrEditStateService.initWorkReport(),
      this.props.fertilizerReportStateService.fetchLatestFertilizerReport(),
    ]);
    this.props.fertilizerReportStateService.initFertilizerReport();
  };

  private handleClickItem = (id: number) => {
    this.props.fertilizerReportStateService.selectFertilizerReport(id);
  };

  private handleChangeDate = async (date: Date) => {
    this.props.navigationStateService.selectDate(date);
    await Promise.all([
      this.props.newOrEditStateService.initWorkReport(),
      this.props.fertilizerReportStateService.fetchLatestFertilizerReport(),
    ]);
    this.props.fertilizerReportStateService.initFertilizerReport();
  };

  private handleChangeFertilizer = (id: null | number) => {
    this.props.fertilizerReportStateService.changeFertilizer(id);
  };

  private handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const num = Number(e.target.value);
    if (!Number.isNaN(num)) {
      this.props.fertilizerReportStateService.changeAmount(e.target.value);
    }
  };

  private handleChangeUseMethodType = (id: null | number) => {
    this.props.fertilizerReportStateService.changeUseMethodType(id);
  };

  private handleChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.props.newOrEditStateService.changeNote(e.target.value);
  };

  private handleClickImage = (imageIds: number[], imageId: number) => {
    this.props.showImageGallery(imageIds, imageId);
  };

  private handleClickImageRemove = (id: number) => {
    this.props.newOrEditStateService.removeImage(id);
  };

  private handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length !== 0) {
      this.props.newOrEditStateService.addImage(e.target.files[0]);
    }
  };

  private handleChangePondType = (id: number | null) => {
    if (id === null) {
      return;
    }
    this.props.newOrEditStateService.changePondType(id);
  };

  private handleClickSave = async () => {
    await this.props.newOrEditStateService.saveWorkReport();
    if (this.props.newOrEditState.isSaved) {
      this.props.notifySaveMessage();
      const returnUrl = SearchQuery.getReturnURLFromState(this.props.location.search);
      if (!returnUrl) {
        return;
      }
      const url = SearchQuery.createURLFromState("", this.props.location.search);
      if (url !== "") {
        this.props.history.push(url);
        return;
      }
    }
  };

  private handleClickCancel = () => {
    const { workReport, selectedUniqueKey } = this.props.newOrEditState;
    const fertilizerReport =
      workReport && workReport.fertilizerReports.find((fr) => getUniqueKey(fr) === selectedUniqueKey);
    this.props.newOrEditStateService.cancelWorkReport();
    if (fertilizerReport && isEntity(fertilizerReport)) {
      this.props.fertilizerReportStateService.selectFertilizerReport(getUniqueKey(fertilizerReport));
    } else {
      this.props.fertilizerReportStateService.addFertilizerReport();
    }
  };

  private handleClickAdd = () => {
    this.props.fertilizerReportStateService.addFertilizerReport();
  };

  private handleClickDelete = async () => {
    await this.props.newOrEditStateService.deleteFertilizerReport();
    this.closeDeleteConfirmModal();
    this.props.notifyDeleteMessage();
    const returnUrl = SearchQuery.getReturnURLFromState(this.props.location.search);
    const url = SearchQuery.createURLFromState("", this.props.location.search);
    if (!returnUrl || url === "") {
      this.props.fertilizerReportStateService.initFertilizerReport();
      return;
    } else {
      this.props.history.push(url);
    }
  };

  private closeDeleteConfirmModal = () => {
    this.setState({ isOpenDeleteConfirm: false });
  };
  private openDeleteConfirmModal = () => {
    this.setState({ isOpenDeleteConfirm: true });
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { workReport, navigation } = state.report;
  return {
    ponds: state.api.pond.ponds,
    pondAreas: state.api.pondArea.pondAreas,
    pondTypes: state.api.pondType.pondTypes,
    fertilizers: state.api.fertilizer.fertilizers,
    useMethodTypes: state.api.useMethodType.useMethodTypes.filter(isFertilizerReportGroup),
    workReports: state.api.workReport.workReports,
    newOrEditState: workReport.newOrEditStateType,
    navigationState: navigation,
    imageState: state.api.image,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    fertilizerReportStateService: new FertilizerReportStateService(dispatch),
    newOrEditStateService: new NewOrEditStateService(dispatch),
    navigationStateService: new NavigationStateService(dispatch),
    showImageGallery: (imageIds, imageId) =>
      dispatch(imageGalleryStateActions.showImages({ imageIds, initialImageId: imageId })),
    notifySaveMessage: () => dispatch(notificationQueueStateActions.addSaveMessage({ itemName: "肥料記録" })),
    notifyDeleteMessage: () => dispatch(notificationQueueStateActions.addDeleteMessage({ itemName: "肥料記録" })),
  };
};

export const WorkReportFertilizerPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
