import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { NewStateService } from "../../../application/master/pondArea/newState";
import { isActiveEntity } from "../../../domain/entity";
import { Pond } from "../../../domain/pond";
import { ApplicationState } from "../../../store/modules";
import { NewOrEditStateType } from "../../../store/modules/master/pondArea/newOrEditState/reducer";
import { PondAreaForm } from "../../components/organisms/PondAreaForm";

interface StateProps {
  ponds: Pond[];
  newOrEditState: NewOrEditStateType;
}

interface DispatchProps {
  newStateService: NewStateService;
}

type PondAreaNewPageProps = StateProps & DispatchProps;

export class Wrapped extends React.Component<PondAreaNewPageProps> {
  public render() {
    const { pondArea, isSaving } = this.props.newOrEditState;
    return (
      <PondAreaForm
        ponds={this.props.ponds}
        pondArea={pondArea}
        originalPondArea={null}
        isSaving={isSaving}
        onChangeName={this.handleChangeName}
        onChangePonds={this.handleChangePonds}
        onClickSave={this.handleClickSavePondArea}
        onClickCancel={this.handleClickCancelPondArea}
        onClickAdd={this.handleClickAddPondArea}
        onClickDelete={null}
      />
    );
  }

  private handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.newStateService.changeName(e.target.value);
  };

  private handleChangePonds = (pondIds: number[]) => {
    this.props.newStateService.changePonds(pondIds);
  };

  private handleClickSavePondArea = () => {
    this.props.newStateService.savePondArea();
  };

  private handleClickCancelPondArea = () => {
    this.props.newStateService.cancelPondArea();
  };

  private handleClickAddPondArea = () => {
    this.props.newStateService.createPondArea();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { newOrEditState } = state.master.pondArea;
  return {
    ponds: state.api.pond.ponds.filter(isActiveEntity),
    newOrEditState,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    newStateService: new NewStateService(dispatch),
  };
};

export const PondAreaNewPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);
