import * as React from "react";
import { Header as _Header } from "semantic-ui-react";
import styled from "styled-components";
import { Text } from "../../atoms/Text";

const Header = styled(_Header).attrs({ as: "h1" })``;

interface PageHeaderProp {
  title: string;
}

export const PageHeader = ({ title }: PageHeaderProp) => {
  return (
    <Header>
      <Text>{title}</Text>
    </Header>
  );
};
