import { Dispatch } from "redux";
import { isEntity } from "../../../domain/entity";
import { HerbicideValidator } from "../../../domain/herbicide";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { HerbicideRepository } from "../../../infrastracture/herbicide/repository";
import { ApplicationState } from "../../../store/modules";
import { apiHerbicideActions } from "../../../store/modules/api/herbicide/ducks";
import { masterHerbicideNewOrEditStateActions } from "../../../store/modules/master/herbicide/newOrEditState/ducks";
import { notificationAlertStateActions } from "../../../store/modules/notification/alert/ducks";
import { notificationQueueStateActions } from "../../../store/modules/notification/queue/ducks";

interface IEditStateService extends IApplicationService {
  selectHerbicide: (herbicideId: number) => void;
  changeName: (name: string) => void;
  cancelHerbicide: () => void;
  saveHerbicide: () => void;
  deleteHerbicide: () => void;
}

export class EditStateService implements IEditStateService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public selectHerbicide(herbicideId: number) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const { api } = getState();
      const herbicide = api.herbicide.herbicides.find((h) => h.id === herbicideId);
      if (!herbicide) {
        throw new ApplicationError("除草剤が見つかりませんでした。");
      }
      this.dispatch(masterHerbicideNewOrEditStateActions.selectHerbicide({ herbicide }));
    });
  }

  @catchApplicationError()
  public changeName(name: string) {
    this.dispatch(masterHerbicideNewOrEditStateActions.changeHerbicide({ key: "name", value: name }));
  }

  @catchApplicationError()
  public cancelHerbicide() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const { api, master } = getState();
      const editHerbicide = master.herbicide.newOrEditState.herbicide;
      if (editHerbicide === null || !isEntity(editHerbicide)) {
        return;
      }
      const herbicide = api.herbicide.herbicides.find((h) => h.id === editHerbicide.id);
      if (!herbicide) {
        throw new ApplicationError("除草剤が見つかりませんでした。");
      }
      this.dispatch(masterHerbicideNewOrEditStateActions.cancelHerbicide({ herbicide }));
    });
  }

  @catchApplicationError((dispatch) => dispatch(masterHerbicideNewOrEditStateActions.saveFail()))
  public async saveHerbicide() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(masterHerbicideNewOrEditStateActions.saveStart());
      const { newOrEditState } = getState().master.herbicide;
      const editHerbicide = newOrEditState.herbicide;
      if (editHerbicide === null || !isEntity(editHerbicide)) {
        throw new ApplicationError("除草剤が見つかりませんでした。");
      }
      const validator = new HerbicideValidator();
      validator.validate(editHerbicide);
      if (!validator.isValid()) {
        this.dispatch(notificationAlertStateActions.showErrorMessage({ errorMessage: validator.getMessages() }));
        this.dispatch(masterHerbicideNewOrEditStateActions.saveFail());
        return;
      }
      const savedHerbicide = await new HerbicideRepository().putHerbicide(editHerbicide);
      this.dispatch(masterHerbicideNewOrEditStateActions.saveSuccess({ herbicide: savedHerbicide }));
      this.dispatch(apiHerbicideActions.updateHerbicide({ herbicide: savedHerbicide }));
      this.dispatch(notificationQueueStateActions.addSaveMessage({ itemName: "除草剤・薬品" }));
    });
  }

  @catchApplicationError((dispatch) => dispatch(masterHerbicideNewOrEditStateActions.saveFail()))
  public async deleteHerbicide() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(masterHerbicideNewOrEditStateActions.saveStart());
      const { newOrEditState } = getState().master.herbicide;
      const editHerbicide = newOrEditState.herbicide;
      if (editHerbicide === null || !isEntity(editHerbicide)) {
        throw new ApplicationError("除草剤が見つかりませんでした。");
      }
      const savedHerbicide = await new HerbicideRepository().deleteHerbicide(editHerbicide);
      this.dispatch(masterHerbicideNewOrEditStateActions.saveSuccess({ herbicide: null }));
      this.dispatch(apiHerbicideActions.deleteHerbicide({ herbicide: savedHerbicide }));
      this.dispatch(notificationQueueStateActions.addDeleteMessage({ itemName: "除草剤・薬品" }));
    });
  }
}
