import { Dispatch } from "redux";
import { createNewCarpPairing } from "../../../domain/carpPairing";
import { isEntity, UniqueKey } from "../../../domain/entity";
import { Image } from "../../../domain/image";
import { createNewParentCarp, ParentCarpValidator } from "../../../domain/parentCarp";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { ParentCarpRepository } from "../../../infrastracture/parentCarp/repository";
import { ApplicationState } from "../../../store/modules";
import { apiParentCarpActions } from "../../../store/modules/api/parentCarp/ducks";
import { masterParentCarpNewOrEditStateActions } from "../../../store/modules/master/parentCarp/newOrEditState/ducks";
import { notificationAlertStateActions } from "../../../store/modules/notification/alert/ducks";
import { notificationQueueStateActions } from "../../../store/modules/notification/queue/ducks";
import { ImageApiService } from "../../api/image";

interface INewStateService extends IApplicationService {
  createParentCarp: () => void;
  changeName: (name: string) => void;
  changeCarpVarietyType: (carpVarietyTypeId: number) => void;
  changeYear: (year: number | null) => void;
  changeCarpPairingName: (uniqueKey: UniqueKey, name: string) => void;
  addCarpPairingImage: (uniqueKey: UniqueKey, file: File) => void;
  removeCarpPairingImage: (uniqueKey: UniqueKey, imageId: number) => void;
  addCarpPairing: () => void;
  removeCarpPairing: (uniqueKey: UniqueKey) => void;
  cancelParentCarp: () => void;
  saveParentCarp: () => void;
}

export class NewStateService implements INewStateService {
  private readonly imageApiService: ImageApiService;

  public constructor(private dispatch: Dispatch<any>) {
    this.imageApiService = new ImageApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public createParentCarp() {
    this.dispatch(masterParentCarpNewOrEditStateActions.createParentCarp({ parentCarp: createNewParentCarp() }));
  }

  @catchApplicationError()
  public changeName(name: string) {
    this.dispatch(masterParentCarpNewOrEditStateActions.changeParentCarp({ key: "name", value: name }));
  }

  @catchApplicationError()
  public changeCarpVarietyType(carpVarietyId: null | number) {
    this.dispatch(
      masterParentCarpNewOrEditStateActions.changeParentCarp({ key: "carpVarietyTypeId", value: carpVarietyId })
    );
  }

  @catchApplicationError()
  public changeYear(year: number | null) {
    this.dispatch(masterParentCarpNewOrEditStateActions.changeParentCarp({ key: "year", value: year }));
  }

  @catchApplicationError()
  public changeCarpPairingName(uniqueKey: UniqueKey, name: string) {
    this.dispatch(masterParentCarpNewOrEditStateActions.changeCarpPairing({ uniqueKey, key: "name", value: name }));
  }

  @catchApplicationError()
  public async addCarpPairingImage(uniqueKey: UniqueKey, file: File) {
    await this.imageApiService.uploadImage(
      file,
      (image: Image) => {
        this.dispatch(masterParentCarpNewOrEditStateActions.addImageToCarpPairing({ uniqueKey, imageId: image.id }));
      },
      () => {
        // TODO: loading中を作るのであれば...
      }
    );
  }

  @catchApplicationError()
  public removeCarpPairingImage(uniqueKey: UniqueKey, imageId: number) {
    this.dispatch(masterParentCarpNewOrEditStateActions.removeImageToCarpPairing({ uniqueKey, imageId }));
  }

  @catchApplicationError()
  public addCarpPairing() {
    this.dispatch(masterParentCarpNewOrEditStateActions.addCarpPairing({ carpPairing: createNewCarpPairing() }));
  }

  @catchApplicationError()
  public removeCarpPairing(uniqueKey: UniqueKey) {
    this.dispatch(masterParentCarpNewOrEditStateActions.removeCarpPairing({ uniqueKey }));
  }

  @catchApplicationError()
  public cancelParentCarp() {
    this.dispatch(masterParentCarpNewOrEditStateActions.cancelParentCarp({ parentCarp: createNewParentCarp() }));
  }

  @catchApplicationError((dispatch) => dispatch(masterParentCarpNewOrEditStateActions.saveFail()))
  public async saveParentCarp() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(masterParentCarpNewOrEditStateActions.saveStart());
      const { newOrEditState } = getState().master.parentCarp;
      const newParentCarp = newOrEditState.parentCarp;
      if (newParentCarp === null || isEntity(newParentCarp)) {
        throw new ApplicationError("親鯉が見つかりませんでした。");
      }
      const validator = new ParentCarpValidator();
      validator.validate(newParentCarp);
      if (!validator.isValid()) {
        this.dispatch(notificationAlertStateActions.showErrorMessage({ errorMessage: validator.getMessages() }));
        this.dispatch(masterParentCarpNewOrEditStateActions.saveFail());
        return;
      }
      const savedParentCarp = await new ParentCarpRepository().postParentCarp(newParentCarp);
      this.dispatch(masterParentCarpNewOrEditStateActions.saveSuccess({ parentCarp: savedParentCarp }));
      this.dispatch(apiParentCarpActions.createParentCarp({ parentCarp: savedParentCarp }));
      this.dispatch(notificationQueueStateActions.addSaveMessage({ itemName: "親鯉" }));
    });
  }
}
