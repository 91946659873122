import * as _ from "lodash";
import { UniqueKey } from "../../../../domain/entity";

interface NotifiedMessage {
  id: UniqueKey;
  message: string;
}

export interface NotificationQueueStateType {
  messages: NotifiedMessage[];
}

export const getInitialState = (): NotificationQueueStateType => {
  return {
    messages: [],
  };
};

const MESSAGE_PREFIX = "nm-";

export const InitHandlers = {
  addMessage(state: NotificationQueueStateType, message: string): NotificationQueueStateType {
    const messages = state.messages.concat({ id: _.uniqueId(MESSAGE_PREFIX), message });
    return { ...state, messages };
  },
  removeMessage(state: NotificationQueueStateType, id: UniqueKey): NotificationQueueStateType {
    const messages = state.messages.filter((m) => m.id !== id);
    return { ...state, messages };
  },
};
