import { actionCreatorFactory } from "typescript-fsa";
import { FeedReasonType } from "../../../../domain/feedReasonType";

// Actions
const actionCreator = actionCreatorFactory("ApiFeedReasonType");

const fetchStart = actionCreator("fetchStart");
const fetchComplete = actionCreator<FetchCompletePayload>("fetchComplete");
interface FetchCompletePayload {
  feedReasonTypes: FeedReasonType[];
}
const fetchFail = actionCreator("fetchFail");
const shouldFetch = actionCreator("shouldFetch");

export const apiFeedReasonTypeActions = {
  fetchStart,
  fetchComplete,
  fetchFail,
  shouldFetch,
};

// Reducers
import { FeedReasonTypeStateType, FetchHandlers, getInitialState } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const FeedReasonTypeReducer = reducerWithInitialState<FeedReasonTypeStateType>(getInitialState())
  .case(fetchStart, (state) => FetchHandlers.start(state))
  .case(fetchComplete, (state, { feedReasonTypes }) => FetchHandlers.complete(state, feedReasonTypes))
  .case(fetchFail, (state) => FetchHandlers.fail(state))
  .case(shouldFetch, (state) => FetchHandlers.shouldFetch(state));
