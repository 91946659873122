import { Dispatch } from "redux";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { PondApiService } from "../../api/pond";
import { PondAreaApiService } from "../../api/pondArea";

interface IReadApiService extends IApplicationService {
  init: () => void;
}

export class ReadApiService implements IReadApiService {
  private pondApiService: PondApiService;
  private pondAreaApiService: PondAreaApiService;
  public constructor(private dispatch: Dispatch<any>) {
    this.pondAreaApiService = new PondAreaApiService(dispatch);
    this.pondApiService = new PondApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public async init() {
    await Promise.all([this.pondAreaApiService.init(), this.pondApiService.init()]);
  }
}
