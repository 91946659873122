import * as _ from "lodash";
import { Dispatch, Middleware } from "redux";
import { ApplicationState } from "../modules";
import {
  getInitialState as getInitialMobileNavigation,
  NavigationStateType as MobileNavigationStateType,
} from "../modules/mobile/navigation/reducer";
import {
  getInitialState as getInitialMobileReportListState,
  ReportListStateType as MobileReportListStateType,
} from "../modules/mobile/reportListState/reducer";
import { getInitialState as getInitialViewReportList, ReportListStateType } from "../modules/view/reportList/reducer";

const MOBILE_NAVIGATION_PREFIX = "mobile.navigation";
const MOBILE_REPORT_LIST_STATE_PREFIX = "mobile.reportListState";
const VIEW_REPORT_LIST_PREFIX = "view.reportList";

const mobileNavigationPersistedKeys: Array<keyof MobileNavigationStateType> = [
  "graphPeriodType",
  "selectedPondId",
  "selectedPondAreaId",
  "selectedWorkerId",
  "currentPage",
  "pondSortingState",
  "pondFilterState",
];
const mobileReportListStatePersistedKeys: Array<keyof MobileReportListStateType> = [
  "detailTab",
  "graphPeriodType",
  "reportFilterState",
  "reportSortingState",
];
const viewReportListPersistedKeys: Array<keyof ReportListStateType> = [
  "detailTab",
  "graphPeriodType",
  "reportFilterState",
  "reportSortingState",
];

const persistedStateKey = [
  ...mobileNavigationPersistedKeys.map((key) => MOBILE_NAVIGATION_PREFIX + "." + key),
  ...mobileReportListStatePersistedKeys.map((key) => MOBILE_REPORT_LIST_STATE_PREFIX + "." + key),
  ...viewReportListPersistedKeys.map((key) => VIEW_REPORT_LIST_PREFIX + "." + key),
];

function restoreState<T extends Record<string, unknown>>(state: T, key: string, prefix: string): T {
  const storageValue = localStorage.getItem(prefix + "." + key);
  if (storageValue !== null) {
    const value = JSON.parse(storageValue);
    state = _.set(state, key, value);
  }
  return state;
}
function restoreStates<T extends Record<string, unknown>>(state: T, keys: string[], prefix: string): T {
  keys.forEach((key) => {
    restoreState(state, key, prefix);
  });
  return state;
}

export const restoreLocalStorage = (): Record<string, unknown> => {
  return {
    mobile: {
      navigation: restoreStates<MobileNavigationStateType>(getInitialMobileNavigation(), mobileNavigationPersistedKeys as string[], MOBILE_NAVIGATION_PREFIX),
      reportListState: restoreStates<MobileReportListStateType>(
        getInitialMobileReportListState(),
        mobileReportListStatePersistedKeys as string[],
        MOBILE_REPORT_LIST_STATE_PREFIX
      ),
    },
    view: {
      reportList: restoreStates<ReportListStateType>(getInitialViewReportList(), viewReportListPersistedKeys as string[], VIEW_REPORT_LIST_PREFIX),
    },
  };
};

export const storeLocalStorage: Middleware<unknown, ApplicationState, Dispatch> = (store) => (next) => (action) => {
  next(action);

  const currentState: ApplicationState = store.getState();
  persistedStateKey.forEach((key) => {
    const value = _.get(currentState, key);
    if (value !== undefined) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  });
};
