import * as React from "react";
import styled from "styled-components";
import { CarpVarietyType } from "../../../../domain/carpVarietyType";
import { UniqueKey } from "../../../../domain/entity";
import { ParentCarp } from "../../../../domain/parentCarp";
import { getSubNumberName, PondReport } from "../../../../domain/pondReport";
import { ScaleType } from "../../../../domain/scaleType";
import { DIMEN } from "../../../styles/Dimen";
import { PageSection } from "../../atoms/Section";
import { FryPondReportList } from "../../molecules/FryPondReportList";

interface FryPondReportSelectionProps {
  currentPondReport: PondReport;
  prevPondReport: PondReport | null;
  parentCarps: ParentCarp[];
  carpVarietyTypes: CarpVarietyType[];
  carpSizeTypes: ScaleType[];
  carpQualityTypes: ScaleType[];
  malformationTypes: ScaleType[];
  carpRatioTypes: ScaleType[];
  onClickPondDisinfection: (uniqueKey: UniqueKey) => void;
  onClickCarpSelection: (uniqueKey: UniqueKey) => void;
  onClickCarpPairing: (imageIds: number[]) => void;
}
export const FryPondReportSelection = (props: FryPondReportSelectionProps) => {
  const parentCarp = props.parentCarps.find((c) => c.id === props.currentPondReport.parentCarpId) || null;
  const pairing = parentCarp
    ? parentCarp.carpPairings.find((p) => p.id === props.currentPondReport.carpPairingId)
    : null;
  return (
    <>
      <PageSection>
        選別（{getSubNumberName(props.currentPondReport)}）
        <PairingName
          onClick={
            pairing && pairing.imageIds.length > 0 ? () => props.onClickCarpPairing(pairing.imageIds) : undefined
          }>
          {pairing ? pairing.name : ""}
        </PairingName>
      </PageSection>
      <FryPondReportList
        currentPondReport={props.currentPondReport}
        prevPondReport={props.prevPondReport}
        parentCarp={parentCarp}
        carpVarietyTypes={props.carpVarietyTypes}
        carpSizeTypes={props.carpSizeTypes}
        carpQualityTypes={props.carpQualityTypes}
        malformationTypes={props.malformationTypes}
        carpRatioTypes={props.carpRatioTypes}
        onClickPondDisinfection={props.onClickPondDisinfection}
        onClickCarpSelection={props.onClickCarpSelection}
      />
    </>
  );
};

const PairingName = styled.div`
  font-size: 16px;
  margin: ${DIMEN.X1};
  display: inline-block;
`;
