import { EditPond, NewPond, Pond } from "../../domain/pond";
import * as api from "../../libs/client";
import { translateApiToPond, translatePondToApi } from "./translate";

interface IPondRepository {
  getPonds: () => Promise<Pond[]>;
  postPond: (pond: NewPond) => Promise<Pond>;
  putPond: (pond: EditPond) => Promise<Pond>;
  deletePond: (pond: EditPond) => Promise<Pond>;
}

export class PondRepository implements IPondRepository {
  public async getPonds(): Promise<Pond[]> {
    const pondsApi = new api.PondsApi(api.serverConfig);
    const response = await pondsApi.getPonds();
    return response.data.map((d) => translateApiToPond(d));
  }
  public async postPond(pond: NewPond): Promise<Pond> {
    const pondsApi = new api.PondsApi(api.serverConfig);
    const response = await pondsApi.postPond(translatePondToApi(pond));
    return translateApiToPond(response.data);
  }

  public async putPond(pond: EditPond): Promise<Pond> {
    const pondsApi = new api.PondsApi(api.serverConfig);
    const response = await pondsApi.putPond(pond.id, translatePondToApi(pond));
    return translateApiToPond(response.data);
  }

  public async deletePond(pond: EditPond): Promise<Pond> {
    const pondsApi = new api.PondsApi(api.serverConfig);
    const response = await pondsApi.deletePond(pond.id);
    return translateApiToPond(response.data);
  }
}
