import { Entity } from "../entity";

interface PondOwnerTypeAttribute extends Record<string, unknown> {
  name: string;
  position: number;
  isDeleted: boolean;
}

export interface NewPondOwnerType extends PondOwnerTypeAttribute {}
export interface EditPondOwnerType extends PondOwnerTypeAttribute, Entity {}

export type NewOrEditPondOwnerType = NewPondOwnerType | EditPondOwnerType;
export type PondOwnerType = EditPondOwnerType;

/*** Service ***/

export function getCompanyPondOwnerType(pondOwnerTypes: PondOwnerType[]): PondOwnerType | null {
  const ownerType = pondOwnerTypes.find(isCompanyPondOwnerType);
  return ownerType || null;
}

export function isCompanyPondOwnerType(pondOwnerType: PondOwnerType): boolean {
  return pondOwnerType.name === "会社所有";
}

export function isCustomerPondOwnerType(pondOwnerType: PondOwnerType): boolean {
  return pondOwnerType.name === "顧客所有";
}
