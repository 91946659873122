import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { AuthService } from "../../application/auth";
import { NavigationService } from "../../application/mobile/navigation";
import { ApplicationState } from "../../store/modules";
import { AuthStateType } from "../../store/modules/auth/reducer";
import { ActiveTabNames, NavigationSidebar } from "../components/organisms/NavigationSidebar";

interface StateProps {
  auth: AuthStateType;
  visibleMenu: boolean;
  selectedPondId: null | number;
}

interface DispatchProps {
  navigationService: NavigationService;
  authService: AuthService;
}

type WrapperProps = StateProps & DispatchProps & RouteComponentProps;

export class Wrapper extends React.Component<WrapperProps> {
  public render() {
    const { user } = this.props.auth;
    return (
      <NavigationSidebar
        activeTabName={this.getActiveTabName()}
        visible={this.props.visibleMenu}
        userName={user !== null ? user.name : ""}
        onHide={this.handleCloseMenu}
        onClickFromOwnPonds={this.handleClickFromOwnPonds}
        onClickFromPondAreas={this.handleClickFromPondAreas}
        onClickFromWorkers={this.handleClickFromWorkers}
        onClickFromFryPonds={this.handleClickFromFryPonds}
        onClickLogout={this.handleClickLogout}
      />
    );
  }

  private getActiveTabName = (): ActiveTabNames => {
    const { pathname } = this.props.location;
    if (pathname.match("/ponds/workers")) {
      return "from_workers";
    }
    if (pathname.match("/ponds/pond_areas")) {
      return "from_pond_areas";
    }
    if (pathname.match("/ponds/fry")) {
      return "from_fry_ponds";
    }
    return "from_own_ponds";
  };

  private handleCloseMenu = () => {
    this.props.navigationService.closeMenu();
  };

  private handleClickFromOwnPonds = () => {
    this.props.navigationService.changeCurrentPage("authUser");
    this.props.history.push("/");
  };

  private handleClickFromPondAreas = () => {
    this.props.navigationService.changeCurrentPage("area");
    this.props.history.push("/ponds/pond_areas");
  };

  private handleClickFromWorkers = () => {
    this.props.navigationService.changeCurrentPage("worker");
    this.props.history.push("/ponds/workers");
  };

  private handleClickFromFryPonds = () => {
    this.props.navigationService.changeCurrentPage("fry");
    this.props.history.push("/ponds/fry");
  };

  private handleClickLogout = async () => {
    await this.props.authService.logout();
    this.props.navigationService.clearStateAtLoggedOut();
    this.props.history.push("/login");
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { visibleMenu, selectedPondId } = state.mobile.navigation;
  return {
    auth: state.auth,
    visibleMenu,
    selectedPondId,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    authService: new AuthService(dispatch),
    navigationService: new NavigationService(dispatch),
  };
};

export const NavigationSidebarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapper));
