import { default as moment } from "moment";
import * as React from "react";
import { AutoSizer, List } from "react-virtualized";
import styled from "styled-components";
import { fillEmptyFryCarpSelectionReport, getCarpSelectionMessageState } from "../../../../domain/carpSelectionReport";
import { CarpVarietyType } from "../../../../domain/carpVarietyType";
import { ParentCarp } from "../../../../domain/parentCarp";
import { Pond } from "../../../../domain/pond";
import { isCompanyPondOwnerType, PondOwnerType } from "../../../../domain/pondOwnerType";
import {
  getCurrentPondReport,
  getCurrentPondStatus,
  getFryMaxSelectionNumber,
  PondReport,
} from "../../../../domain/pondReport";
import { PondType } from "../../../../domain/pondType";
import { COLOR } from "../../../styles/Color";
import { DIMEN } from "../../../styles/Dimen";
import { GroupListItem } from "../../atoms/GroupListItem";
import { CleanCompleteIcon, SelectionAlertIcon, SelectionWarningIcon } from "../FryPondReportList";

interface MobilePondListProps {
  ponds: Pond[];
  pondReports: PondReport[];
  parentCarps: ParentCarp[];
  pondTypes: PondType[];
  pondOwnerTypes: PondOwnerType[];
  carpVarietyTypes: CarpVarietyType[];
  onClickPond: (id: number) => void;
  scrollTop?: number | undefined;
  onScroll?: (props: { scrollTop: number }) => void;
}

interface MobilePondListRowProps {
  pond: Pond;
  pondReports: PondReport[];
  parentCarps: ParentCarp[];
  pondTypes: PondType[];
  pondOwnerTypes: PondOwnerType[];
  carpVarietyTypes: CarpVarietyType[];
  onClickPond: (id: number) => void;

  // virtualized props
  style: any;
}

export const POND_LIST_ROW_HEIGHT = 95;

export const PondList = (props: MobilePondListProps) => {
  return (
    <ListContent>
      {props.ponds.map((pond) => (
        <PondListRow key={pond.id} {...{ ...props, pond, style: {} }} />
      ))}
    </ListContent>
  );
};

export const PondListRow = (props: MobilePondListRowProps) => {
  const { pond, pondReports, pondTypes, pondOwnerTypes, parentCarps, carpVarietyTypes, onClickPond, style } = props;
  const pondReport = getCurrentPondReport(pondReports, pond.id);
  const pondOwnersOfPond = pondOwnerTypes.filter((o) => pond.pondOwnerTypeIds.includes(o.id));

  const pondType =
    (!!pondReport && pondTypes.find((t) => t.id === pondReport.pondTypeId)) ||
    pondTypes.find((t) => t.id === pond.pondTypeId);
  const parentCarp = !!pondReport && parentCarps.find((c) => pondReport.parentCarpId === c.id);
  const pairing =
    !!pondReport && !!parentCarp && parentCarp.carpPairings.find((p) => p.id === pondReport.carpPairingId);
  const currentPondStatus = !!pondReport ? getCurrentPondStatus(pondReport) : null;

  let messageState;

  if (!!pondReport && pondReport.carpSelectionReports.length === 0 && pondReport.pondDisinfectionReports.length !== 0) {
    messageState = "disinfection";
  }
  if (!!pondReport && pondReport.carpSelectionReports.length !== 0) {
    const carpVarietyType = !!parentCarp && carpVarietyTypes.find((type) => type.id === parentCarp.carpVarietyTypeId);
    messageState =
      carpVarietyType &&
      getCarpSelectionMessageState(
        fillEmptyFryCarpSelectionReport(pondReport.carpSelectionReports),
        getFryMaxSelectionNumber(pondReport) + 1,
        new Date(),
        carpVarietyType,
        pondReport.isCompleted
      );
  }

  return (
    <GroupListItem className={"mobile"} onClick={() => onClickPond(pond.id)} style={style}>
      <ListTitle>
        <PondName>{pond.name}</PondName>
        <OwnerIcons>
          {pondOwnersOfPond.map((o) => (
            <OwnerIcon key={o.id} className={isCompanyPondOwnerType(o) ? "company" : "customer"} />
          ))}
        </OwnerIcons>
        <PondTypeName>{pondType ? pondType.name : ""}</PondTypeName>
      </ListTitle>
      <ListCaption>
        {messageState === "disinfection" && (
          <IconCell>
            <CleanCompleteIcon />
          </IconCell>
        )}
        {messageState === "warning" && (
          <IconCell>
            <SelectionWarningIcon />
          </IconCell>
        )}
        {messageState === "alert" && (
          <IconCell>
            <SelectionAlertIcon />
          </IconCell>
        )}

        <IconCell />
        <PairingName>{pairing ? pairing.name : ""}</PairingName>
        <PondDetail>
          <CurrentDate>{currentPondStatus ? moment(currentPondStatus.date).format("YYYY/MM/DD") : ""}</CurrentDate>
          <CurrentStatus>{currentPondStatus ? currentPondStatus.status : ""}</CurrentStatus>
          <CurrentAmount>
            {currentPondStatus ? `${currentPondStatus.amount}${currentPondStatus.unit}` : ""}
          </CurrentAmount>
        </PondDetail>
      </ListCaption>
    </GroupListItem>
  );
};

export const PondListVirtualized = (props: MobilePondListProps) => {
  return (
    <AutoSizer>
      {({ width, height }) => (
        <List
          width={width}
          height={height}
          rowCount={props.ponds.length}
          rowHeight={POND_LIST_ROW_HEIGHT}
          overscanRowCount={10}
          rowRenderer={listRowRender(props)}
          scrollTop={props.scrollTop}
          onScroll={props.onScroll}
        />
      )}
    </AutoSizer>
  );
};
export const listRowRender = (args: MobilePondListProps) => function ListRow({
  index,
  key,
  style,
}: {
  index: number;
  key: string;
  style: any;
}) {
  const pond = args.ponds[index];
  return <PondListRow key={key} {...{ ...args, pond, style }} />;
};

const ListContent = styled.div``;

const ListTitle = styled.div`
  display: flex;
  padding: ${DIMEN.X0_5} 0;
  min-height: 28px;
`;

const PondName = styled.div`
  font-weight: bold;
  font-size: 18px;
  flex-basis: 9em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const OwnerIcons = styled.div`
  flex-basis: calc(20px * 2 + 4px * 4);
  margin-right: ${DIMEN.X0_5};
`;
const OwnerIcon = styled.span`
  background-color: ${COLOR.Primary.base};
  border-radius: 50%;
  color: ${COLOR.Base.white};
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  margin: 0 ${DIMEN.X0_5};

  &.company {
    &:before {
      content: "会";
    }
  }
  &.customer {
    &:before {
      content: "顧";
    }
  }
`;

const PondTypeName = styled.div``;

const ListCaption = styled.div`
  padding: ${DIMEN.X0_5} 1.5em;
  padding-right: 0;
  min-height: 50px;
  position: relative;
`;

const IconCell = styled.div`
  position: absolute;
  left calc(-1.5em / 2);
  top: calc(50% - 1.5em / 2);
`;

const PairingName = styled.div``;
const PondDetail = styled.div`
  margin-top: ${DIMEN.X0_5};
  display: flex;
  font-size: 14px;
`;

const CurrentDate = styled.div`
  margin-right: ${DIMEN.X1};
`;
const CurrentStatus = styled.div`
  flex-basis: 5em;
  margin-right: ${DIMEN.X1};
`;
const CurrentAmount = styled.div``;
