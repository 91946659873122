export type GraphPeriodType = "week" | "month" | "three_months" | "half_year";
export type PageType = "area" | "worker" | "authUser" | "fry" | null;

export interface PondFilterState {
  isOnlyEmptyPond: boolean;
  selectedPondStatusIds: number[];
  notSelectedCarpVarietyTypeIds: number[];
}

export interface PondSortingState {
  isAscDate: boolean;
}

export interface NavigationStateType extends Record<string, unknown> {
  visibleMenu: boolean;
  graphPeriodType: GraphPeriodType;
  selectedDate: Date;
  selectedPondAreaId: null | number;
  selectedPondId: null | number;
  selectedWorkerId: null | number;
  prevSelectedPondAreaId: null | number;
  prevSelectedWorkerId: null | number;
  pondSortingState: PondSortingState;
  pondFilterState: PondFilterState;
  prevPage: PageType;
  currentPage: PageType;
}

export const getInitialState = (): NavigationStateType => {
  return {
    visibleMenu: false,
    graphPeriodType: "week",
    selectedDate: new Date(),
    selectedPondAreaId: null,
    selectedPondId: null,
    selectedWorkerId: null,
    prevSelectedPondAreaId: null,
    prevSelectedWorkerId: null,
    pondSortingState: {
      isAscDate: false,
    },
    pondFilterState: {
      isOnlyEmptyPond: false,
      selectedPondStatusIds: [],
      notSelectedCarpVarietyTypeIds: [],
    },
    prevPage: null,
    currentPage: null,
  };
};

export const MenuHandlers = {
  openMenu(state: NavigationStateType): NavigationStateType {
    return { ...state, visibleMenu: true };
  },
  closeMenu(state: NavigationStateType): NavigationStateType {
    return { ...state, visibleMenu: false };
  },
};

export const InitializeHandlers = {
  resetState(state: NavigationStateType): NavigationStateType {
    return { ...state, ...getInitialState() };
  }
};

export const SelectHandlers = {
  selectPondArea(state: NavigationStateType, selectedPondAreaId: null | number): NavigationStateType {
    const prevSelectedPondAreaId = state.prevSelectedPondAreaId ?? selectedPondAreaId;
    return { ...state, prevSelectedPondAreaId, selectedPondAreaId };
  },
  selectPond(state: NavigationStateType, selectedPondId: null | number): NavigationStateType {
    return { ...state, selectedPondId };
  },
  selectWorker(state: NavigationStateType, selectedWorkerId: null | number): NavigationStateType {
    const prevSelectedWorkerId = state.prevSelectedWorkerId ?? selectedWorkerId;
    return { ...state, prevSelectedWorkerId, selectedWorkerId };
  },
  changeSelectedDate(state: NavigationStateType, selectedDate: Date): NavigationStateType {
    return { ...state, selectedDate };
  },
  changeGraphPeriodType(state: NavigationStateType, periodType: GraphPeriodType): NavigationStateType {
    return { ...state, graphPeriodType: periodType };
  },
  /**
   * 担当野池から、エリアから、担当者から、稚魚池から野池を選択する画面で最初に選択状態をリセットする。
   * この時、画面に合わせて前回選択状態を保持する。
   * @param state
   */
  clearSelectState(state: NavigationStateType): NavigationStateType {
    return {
      ...getInitialState(),
      prevSelectedPondAreaId: state.prevPage === "area" ? state.prevSelectedPondAreaId : null,
      prevSelectedWorkerId: state.prevPage === "worker" ? state.prevSelectedWorkerId : null,
      visibleMenu: state.visibleMenu,
      graphPeriodType: state.graphPeriodType,
      pondSortingState: state.pondSortingState,
      pondFilterState: state.pondFilterState,
      prevPage: state.prevPage,
      currentPage: state.currentPage,
    };
  },
  changeCurrentPage(state: NavigationStateType, currentPage: PageType): NavigationStateType {
    return { ...state, prevPage: state.currentPage ?? currentPage, currentPage };
  },
};

export const SortHandlers = {
  changeSortDate(state: NavigationStateType, isAscDate: boolean): NavigationStateType {
    return { ...state, pondSortingState: { ...state.pondSortingState, isAscDate } };
  },
};

export const FilterHandlers = {
  changeOnlyEmptyPond(state: NavigationStateType, isOnlyEmptyPond: boolean): NavigationStateType {
    return { ...state, pondFilterState: { ...state.pondFilterState, isOnlyEmptyPond } };
  },
  addSelectedPondStatus(state: NavigationStateType, pondStatusId: number): NavigationStateType {
    return {
      ...state,
      pondFilterState: {
        ...state.pondFilterState,
        selectedPondStatusIds: state.pondFilterState.selectedPondStatusIds.concat(pondStatusId),
      },
    };
  },
  removeSelectedPondStatus(state: NavigationStateType, pondStatusId: number): NavigationStateType {
    return {
      ...state,
      pondFilterState: {
        ...state.pondFilterState,
        selectedPondStatusIds: state.pondFilterState.selectedPondStatusIds.filter((id) => id !== pondStatusId),
      },
    };
  },
  addNotSelectedCarpVariety(state: NavigationStateType, carpVarietyId: number): NavigationStateType {
    return {
      ...state,
      pondFilterState: {
        ...state.pondFilterState,
        notSelectedCarpVarietyTypeIds: state.pondFilterState.notSelectedCarpVarietyTypeIds.concat(carpVarietyId),
      },
    };
  },
  removeNotSelectedCarpVariety(state: NavigationStateType, carpVarietyId: number): NavigationStateType {
    return {
      ...state,
      pondFilterState: {
        ...state.pondFilterState,
        notSelectedCarpVarietyTypeIds: state.pondFilterState.notSelectedCarpVarietyTypeIds.filter(
          (id) => id !== carpVarietyId
        ),
      },
    };
  },
};
