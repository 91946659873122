import { combineReducers } from "redux";
import { reportListStateReducer } from "./reportList/ducks";
import { ReportListStateType } from "./reportList/reducer";

export interface ViewState {
  reportList: ReportListStateType;
}
export const viewReducer = combineReducers({
  reportList: reportListStateReducer,
});
