import { default as classNames } from "classnames";
import * as React from "react";
import styled from "styled-components";
import { DIMEN } from "../../../styles/Dimen";
import { IconButton } from "../../atoms/Button";
import { GroupListItem } from "../../atoms/GroupListItem";
import { ThinInput } from "../../atoms/Input";

interface MasterItem {
  id: number;
  name: string;
}

interface MasterGroupListProps {
  items: MasterItem[];
  selectedItemId: number | null;
  onClickItem: (id: number) => void;
}

export const MasterGroupList = ({ items, selectedItemId, onClickItem }: MasterGroupListProps) => {
  return (
    <List>
      {items.map((i) => (
        <GroupListItem
          key={i.id}
          className={classNames({ active: i.id === selectedItemId })}
          onClick={() => onClickItem(i.id)}>
          {i.name}
        </GroupListItem>
      ))}
    </List>
  );
};

interface MasterGroupEditingListProps extends MasterGroupListProps {
  onDeleteItem: (id: number) => void;
  onSaveItem: (item: MasterItem) => void;
  onAddItem: () => void;
}

interface MasterGroupEditingListState {
  editingId: null | number;
  editingName: string;
}

export class MasterGroupEditingList extends React.Component<MasterGroupEditingListProps, MasterGroupEditingListState> {
  private editingListItem: HTMLDivElement | null = null;

  constructor(props: MasterGroupEditingListProps) {
    super(props);

    this.state = {
      editingId: null,
      editingName: "",
    };
  }

  public componentWillUnmount() {
    document.body.removeEventListener("click", this.handleOutsideClick);
  }

  public render() {
    const { items, selectedItemId, onClickItem } = this.props;
    return (
      <List>
        {items.map((i) => (
          <GroupListItem
            key={i.id}
            ref={(node) => {
              if (i.id === this.state.editingId) {
                this.editingListItem = node;
              }
            }}
            className={classNames({ active: i.id === selectedItemId })}
            onClick={() => onClickItem(i.id)}
            onDoubleClick={this.handleDoubleClick.bind(this, i)}>
            {i.id !== this.state.editingId ? (
              i.name
            ) : (
              <EditingList
                editingName={this.state.editingName}
                onChangeName={this.handleChangeInput}
                onClickDelete={this.handleClickDelete}
                onClickSave={this.handleClickSave}
                onClickCancel={this.handleClickCancel}
              />
            )}
          </GroupListItem>
        ))}
        <AddButtonGroup>
          <IconButton basic size={"tiny"} color={"blue"} onClick={this.props.onAddItem} icon={"plus"} />
        </AddButtonGroup>
      </List>
    );
  }

  private handleOutsideClick = (e: any) => {
    if (!!this.editingListItem && !this.editingListItem.contains(e.target)) {
      this.closeEditingList();
      document.body.removeEventListener("click", this.handleOutsideClick);
    }
  };

  private handleDoubleClick = (item: MasterItem) => {
    this.setState({ editingId: item.id, editingName: item.name });
    document.body.addEventListener("click", this.handleOutsideClick);
  };

  private handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ editingName: e.target.value });
  };

  private handleClickDelete = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (this.state.editingId !== null) {
      this.props.onDeleteItem(this.state.editingId);
    }
  };

  private handleClickSave = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (this.state.editingId !== null) {
      this.props.onSaveItem({ id: this.state.editingId, name: this.state.editingName });
    }
  };

  private handleClickCancel = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    this.closeEditingList();
  };

  private closeEditingList = () => {
    this.setState({ editingId: null, editingName: "" });
  };
}

interface EditingListProps {
  editingName: string;
  onChangeName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickDelete: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickSave: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickCancel: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const EditingList = ({ editingName, onChangeName, onClickDelete, onClickSave, onClickCancel }: EditingListProps) => {
  return (
    <EditingListItem>
      <ThinInput value={editingName} onChange={onChangeName} />
      <ButtonGroup>
        <IconButton size={"mini"} icon={"trash"} color={"red"} onClick={onClickDelete} />
        <IconButton size={"mini"} icon={"check"} color={"blue"} onClick={onClickSave} />
        <IconButton size={"mini"} icon={"x"} onClick={onClickCancel} />
      </ButtonGroup>
    </EditingListItem>
  );
};

const EditingListItem = styled.div`
  width: calc(100% - 2.5em);
`;
const List = styled.div``;
const ButtonGroup = styled.div`
  margin-top: ${DIMEN.X0_5};
  text-align: right;
  > button {
    margin-left: ${DIMEN.X1} !important;
  }
`;
const AddButtonGroup = styled.div`
  text-align: center;
  padding: ${DIMEN.X1_5} 0;
`;
