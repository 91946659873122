import * as _ from "lodash";

/*** Entity ***/
export interface Entity extends Record<string, unknown> {
  id: number;
}

export function isEntity(entity: Record<string, unknown>): entity is Entity {
  return "id" in entity;
}

function sortId(a: Entity, b: Entity, isAsc: boolean = true) {
  const asc = isAsc ? 1 : -1;
  return (a.id - b.id) * asc;
}

export function sortIdAsc(a: Entity, b: Entity) {
  return sortId(a, b, true);
}

export function sortIdDesc(a: Entity, b: Entity) {
  return sortId(a, b, false);
}

/*** New Entity ***/
export interface NewEntity extends Record<string, unknown> {
  uid: string;
}

export function isNewEntity(entity: Record<string, unknown>): entity is NewEntity {
  return "uid" in entity;
}
export function generateUid(prefix: string): string {
  return _.uniqueId(prefix);
}

export function removeUidPrefix(uid: string): number {
  const reg = /\d*/;
  if (reg.test(uid)) {
    const match = uid.match(reg);
    return !!match ? Number(match[0]) : Number.MAX_SAFE_INTEGER;
  }
  return Number.MAX_SAFE_INTEGER;
}

function sortUid(a: NewEntity, b: NewEntity, isAsc: boolean = true) {
  const asc = isAsc ? 1 : -1;
  return (removeUidPrefix(a.uid) - removeUidPrefix(b.uid)) * asc;
}

export function sortUidAsc(a: NewEntity, b: NewEntity) {
  return sortUid(a, b, true);
}

export function sortUidDesc(a: NewEntity, b: NewEntity) {
  return sortUid(a, b, false);
}

/*** NewEntityOrEntity ***/
export type NewEntityOrEntity = NewEntity | Entity;
export type UniqueKey = string | number;

function sortUniqueKey(a: NewEntityOrEntity, b: NewEntityOrEntity, isAsc: boolean = true) {
  if (isNewEntity(a) && isNewEntity(b)) {
    return sortUid(a, b, isAsc);
  }
  if (!isNewEntity(a) && !isNewEntity(b)) {
    return sortId(a, b, isAsc);
  }
  if (isNewEntity(a) && !isNewEntity(b)) {
    return 1;
  }
  return -1;
}

export function sortUniqueKeyAsc(a: NewEntityOrEntity, b: NewEntityOrEntity) {
  return sortUniqueKey(a, b, true);
}
export function sortUniqueKeyDesc(a: NewEntityOrEntity, b: NewEntityOrEntity) {
  return sortUniqueKey(a, b, false);
}

export function getUniqueKey(a: NewEntityOrEntity): UniqueKey {
  if (isNewEntity(a)) {
    return a.uid;
  }
  return a.id;
}

/*** MasterEntity ***/
export function isActiveEntity(e: { isDeleted: boolean }): boolean {
  return !e.isDeleted;
}

export function sortPosition(
  a: { id: number; position: number },
  b: { id: number; position: number },
  isAsc: boolean = true
) {
  const asc = isAsc ? 1 : -1;
  if (a.position !== b.position) {
    return (a.position - b.position) * asc;
  }
  return sortId(a, b, isAsc);
}
