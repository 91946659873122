import * as React from "react";
import { Form } from "../../atoms/Form/Form";
import { Spinner } from "../../atoms/Spinner";
import { EnvironmentReportFormBody } from "../../molecules/EnvironmentReportForm";
import { ReportFormButton } from "../../molecules/ReportFormButton";
import { WorkReportFormDateBody } from "../../molecules/WorkReportFormDate";

interface EnvironmentReportFormProps {
  title: string;
  date: Date;
  value: string;
  note: string;
  imageIds: number[];
  onChangeDate: (date: Date) => void;
  onChangeValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickValueUp: () => void;
  onClickValueDown: () => void;
  onChangeNote: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickImage: (imageIds: number[], imageId: number) => void;
  onClickImageRemove: (id: number) => void;
  onClickSave: () => void;
  onClickCancel: () => void;
  onClickDelete: (() => void) | null;
  isSaving: boolean;
  disabledSave: boolean;
}
export const EnvironmentReportForm = (props: EnvironmentReportFormProps) => {
  return (
    <Form>
      {props.isSaving && <Spinner />}
      <WorkReportFormDateBody
        title={props.title}
        date={props.date}
        onChangeDate={props.onChangeDate}
        onClickAdd={null}
        onClickDelete={props.onClickDelete}
      />
      <EnvironmentReportFormBody {...props} />
      <ReportFormButton
        onClickSave={props.onClickSave}
        onClickCancel={props.onClickCancel}
        disabledSave={props.disabledSave}
      />
    </Form>
  );
};
