import * as React from "react";
import { FormInput as _FormInput } from "semantic-ui-react";
import styled from "styled-components";

interface FormInputProps {
  label?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  disabled?: boolean;
}

const Wrapped = styled(_FormInput)`
  &.no-label {
    margin: 0 !important;
    > label {
      margin: 0 !important;
    }
  }
`;

export const FormTextInput = ({ label, value, onChange, readOnly, disabled, onClick }: FormInputProps) => {
  return (
    <Wrapped
      type="text"
      className={!label ? "no-label" : ""}
      label={label}
      // Mobile + Safari + SelectBox の場合にシャドウコンテンツでdivが入るため、absoluteの指定しているコンテンツがずれる。
      // ただ文字列が入っている場合にマシになるのでPlaceholderに空白をいれてごまかす
      placeholder={label || " "}
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      disabled={disabled}
      onClick={onClick}
    />
  );
};

export const FormNumberInput = ({ label, value, onChange, readOnly, disabled, onClick }: FormInputProps) => {
  return (
    <Wrapped
      type="number"
      className={!label ? "no-label" : ""}
      label={label}
      placeholder={label || ""}
      value={value}
      onKeyDown={(e: React.KeyboardEvent) => {
        if (shouldPreventKeyDown(e.keyCode)) {
          e.preventDefault();
        }
      }}
      onInput={onChange}
      readOnly={readOnly}
      disabled={disabled}
      onClick={onClick}
    />
  );
};

function shouldPreventKeyDown(keyCode: number): boolean {
  // eのキーだけ無効にする. コピペ等を行うためにはeのキーだけ無効にする必要がある
  return 69 === keyCode;
}

export const FormPasswordInput = ({ label, value, onChange, readOnly, disabled, onClick }: FormInputProps) => {
  return (
    <Wrapped
      type="password"
      className={!label ? "no-label" : ""}
      label={label}
      placeholder={label || ""}
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      disabled={disabled}
      onClick={onClick}
    />
  );
};

export const FormDateInput = ({ label, value, onChange, readOnly, disabled, onClick }: FormInputProps) => {
  return (
    <Wrapped
      type="date"
      className={!label ? "no-label" : ""}
      label={label}
      placeholder={label || ""}
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      disabled={disabled}
      onClick={onClick}
      style={{
        minHeight: "38px",
      }}
    />
  );
};
