import axios from "axios";
import { Dispatch } from "redux";
import { getContentType, Image, isAllowFileExtension, NewImage } from "../../../domain/image";
import { handleApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { ImageRepository } from "../../../infrastracture/image/repository";
import { apiImageActions } from "../../../store/modules/api/image/ducks";

interface IImageApiService extends IApplicationService {
  uploadImage: (file: File, saveCallback: (image: Image) => void, failedCallback: () => void) => void;
}

export class ImageApiService implements IImageApiService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  public async uploadImage(file: File, saveCallback: (image: Image) => void, failedCallback: () => void) {
    const fileName = file.name;
    if (!isAllowFileExtension(fileName)) {
      failedCallback();
      throw new ApplicationError("対応していない拡張子です。");
    }

    const newImage: NewImage = {
      fileName,
      originalFileName: fileName,
      contentType: getContentType(fileName),
      activated: false,
    };

    try {
      this.dispatch(apiImageActions.uploadStart({ image: newImage }));
      const repo = new ImageRepository();
      const postedImage = await repo.getCreateImageURL(newImage);
      this.dispatch(apiImageActions.uploadImage({ image: postedImage }));
      await axios({
        method: "PUT",
        url: postedImage.redirectURL,
        headers: {
          "Content-Type": getContentType(fileName),
        },
        data: file,
      });
      const savedImage = await repo.putImage(postedImage);
      this.dispatch(apiImageActions.uploadSuccess({ image: savedImage }));
      saveCallback(savedImage);
    } catch (e) {
      handleApplicationError(this.dispatch, e);
      this.dispatch(apiImageActions.uploadFailed());
      failedCallback();
    }
  }
}
