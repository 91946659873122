import * as React from "react";
import styled from "styled-components";
import { getImageSrcURL } from "../../../../domain/image";
import { PondType } from "../../../../domain/pondType";
import { DIMEN } from "../../../styles/Dimen";
import { SaveButton } from "../../atoms/Button/Mobile";
import { Form } from "../../atoms/Form/Form";
import { FormFileInput } from "../../atoms/Form/InputFile";
import { FormSelect } from "../../atoms/Form/Select";
import { FormTextArea } from "../../atoms/Form/TextArea";
import { Grid, GridColumn, GridRow } from "../../atoms/Grid";
import { EditImage } from "../../atoms/Image";
import { ContentSection, PageSection } from "../../atoms/Section";

interface WorkReportFormNoteProps {
  note: string;
  imageIds: number[];
  onChangeNote: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickImage: (imageIds: number[], imageId: number) => void;
  onClickImageRemove: (id: number) => void;
}
export const WorkReportFormNote = (
  props: WorkReportFormNoteProps & {
    pondTypes: PondType[];
    pondTypeId: number;
    onChangePondType: ((id: number | null) => void) | null;
    onClickSave: () => void;
    isSaving: boolean;
    disabledSave: boolean;
  }
) => {
  return (
    <Form>
      <PageSection>作業メモを記録</PageSection>
      <WorkReportFormNoteBody {...props} />
      {!!props.onChangePondType && (
        <>
          <ContentSection>池の区分</ContentSection>
          <ContentNote>
            <FormSelect value={props.pondTypeId} options={props.pondTypes} onChange={props.onChangePondType} />
          </ContentNote>
        </>
      )}
      <SaveButton onClick={props.onClickSave} disabled={props.disabledSave} />
    </Form>
  );
};

export const WorkReportFormNoteBody = ({
  note,
  imageIds,
  onChangeNote,
  onClickImage,
  onClickImageRemove,
  onChangeFile,
}: WorkReportFormNoteProps) => {
  return (
    <>
      <ContentSection>メモ</ContentSection>
      <ContentNote>
        <FormTextArea value={note} onChange={onChangeNote} />
      </ContentNote>
      <ContentNote>
        <ImageOuter>
          <ImageGridOuter>
            <Grid>
              <GridRow>
                {imageIds.map((id) => (
                  <GridColumn key={id} mobile={8}>
                    <EditImage
                      onClick={() => onClickImage(imageIds, id)}
                      onClickRemove={() => onClickImageRemove(id)}
                      isLoading={false}
                      src={getImageSrcURL(id)}
                    />
                  </GridColumn>
                ))}
              </GridRow>
            </Grid>
          </ImageGridOuter>
          <ImageButtonOuter>
            <ImageDummyBlock>
              <FormFileInput onChange={onChangeFile} />
            </ImageDummyBlock>
          </ImageButtonOuter>
        </ImageOuter>
      </ContentNote>
    </>
  );
};

export const ContentNote = styled.div`
  padding: ${DIMEN.X1};
`;

const ImageOuter = styled.div`
  display: flex;
`;
const ImageGridOuter = styled.div`
  flex: 3 3;
`;
const ImageButtonOuter = styled.div`
  flex: 1 1;
`;
const ImageDummyBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
