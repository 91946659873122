import { Entity, isEntity } from "../entity";
import {
  getValidation,
  isAlphanumericCharacterOrSymbol,
  isEmptyString,
  isTextLength,
  Validation,
  Validator,
} from "../validation";

interface WorkerAttribute extends Record<string, unknown> {
  name: string;
  accountName: string;
  password?: string;
  position: number;
  isDeleted: boolean;
}

export interface NewWorker extends WorkerAttribute {}
export interface EditWorker extends WorkerAttribute, Entity {}

export type NewOrEditWorker = NewWorker | EditWorker;
export type Worker = EditWorker;

/*** Service ***/
export function createNewWorker(): NewWorker {
  return {
    name: "",
    accountName: "",
    password: "",
    position: 1,
    isDeleted: false,
  };
}

/*** Validator ***/
export class WorkerValidator extends Validator<NewOrEditWorker> {
  public validate(worker: NewOrEditWorker) {
    const nameValidation = validateName(worker);
    if (nameValidation) {
      this.addMessages(nameValidation);
    }
    const accountNameValidation = validateAccountName(worker);
    if (accountNameValidation) {
      this.addMessages(accountNameValidation);
    }
    if (!!worker.password || !isEntity(worker)) {
      const passwordValidation = validatePassword(worker);
      if (passwordValidation) {
        this.addMessages(passwordValidation);
      }
    }
  }
}

function validateName({ name }: NewOrEditWorker): Validation | null {
  if (isEmptyString(name)) {
    return getValidation("担当者名は必須です。入力してください。");
  }
  if (!isTextLength(name, 0, 255)) {
    return getValidation("担当者名は255文字以下で入力してください。");
  }
  return null;
}

function validateAccountName({ accountName }: NewOrEditWorker): Validation | null {
  if (isEmptyString(accountName)) {
    return getValidation("アカウント名は必須です。入力してください。");
  }
  if (!isTextLength(accountName, 0, 255)) {
    return getValidation("アカウント名は255文字以下で入力してください。");
  }
  if (isAlphanumericCharacterOrSymbol(accountName)) {
    return getValidation("アカウント名は半角英数字と記号で入力してください。");
  }
  return null;
}

function validatePassword({ password }: NewOrEditWorker): Validation | null {
  if (!password || password === "") {
    return getValidation("パスワードは必須です。入力してください。");
  }

  if (!isTextLength(password, 12, 255)) {
    return getValidation("パスワードは12文字以上で入力してください。");
  }
  return null;
}
