import { actionCreatorFactory } from "typescript-fsa";
import { Worker } from "../../../domain/worker";
// Actions
const actionCreator = actionCreatorFactory("AuthState");

const loginStart = actionCreator("loginStart");
const loginSuccess = actionCreator<LoginSuccessPayload>("loginSuccess");
interface LoginSuccessPayload {
  user: Worker;
}
const loginFail = actionCreator<LoginFailPayload>("loginFail");
interface LoginFailPayload {
  errorMessage: string;
}

const logout = actionCreator("logout");

const changeAccountName = actionCreator<ChangeAccountNamePayload>("changeAccountName");
interface ChangeAccountNamePayload {
  accountName: string;
}
const changePassword = actionCreator<ChangePasswordPayload>("changePassword");
interface ChangePasswordPayload {
  password: string;
}

export const authStateActions = {
  loginStart,
  loginSuccess,
  loginFail,
  logout,
  changeAccountName,
  changePassword,
};

// Reducers
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { AuthStateType, getInitialState, LoginHandlers } from "./reducer";

export const authStateReducer = reducerWithInitialState<AuthStateType>(getInitialState())
  .case(loginStart, (state) => LoginHandlers.loginStart(state))
  .case(loginSuccess, (state, { user }) => LoginHandlers.loginSuccess(state, user))
  .case(loginFail, (state, { errorMessage }) => LoginHandlers.loginFail(state, errorMessage))
  .case(logout, (state) => LoginHandlers.logout(state))
  .case(changeAccountName, (state, { accountName }) => LoginHandlers.changeAccountName(state, accountName))
  .case(changePassword, (state, { password }) => LoginHandlers.changePassword(state, password));
