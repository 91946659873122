import { isEntity } from "../../domain/entity";
import { NewOrEditPond, Pond } from "../../domain/pond";
import * as Api from "../../libs/apiClient";

export function translateApiToPond(apiPond: Api.Pond): Pond {
  return {
    id: apiPond.id as number,
    name: apiPond.name,
    userIds: apiPond.userIds,
    pondAreaId: apiPond.pondAreaId,
    pondTypeId: apiPond.pondTypeId,
    pondOwnerTypeIds: apiPond.pondOwnerTypeIds,
    position: apiPond.position,
    isDeleted: apiPond.isDeleted,
    isUnused: apiPond.isUnused,
  };
}

export function translatePondToApi(pond: NewOrEditPond): Api.Pond {
  return {
    id: isEntity(pond) ? pond.id : undefined,
    name: pond.name,
    userIds: pond.userIds,
    pondAreaId: pond.pondAreaId,
    pondTypeId: pond.pondTypeId,
    pondOwnerTypeIds: pond.pondOwnerTypeIds,
    position: pond.position as number,
    isDeleted: pond.isDeleted,
    isUnused: pond.isUnused,
  };
}
