import { actionCreatorFactory } from "typescript-fsa";
import { GraphPeriodType } from "./reducer";
import { PageType } from "./reducer";

// Actions
const actionCreator = actionCreatorFactory("MobileNavigation");

const openMenu = actionCreator("openMenu");
const closeMenu = actionCreator("closeMenu");

const resetState = actionCreator("resetState");

const changeSelectedDate = actionCreator<ChangeSelectedDatePayload>("changeSelectedDate");
interface ChangeSelectedDatePayload {
  selectedDate: Date;
}

const changeGraphPeriodType = actionCreator<ChangeGraphPeriodTypePayload>("changeGraphPeriodType");
interface ChangeGraphPeriodTypePayload {
  periodType: GraphPeriodType;
}

const selectPondArea = actionCreator<SelectPondAreaPayload>("selectPondArea");
interface SelectPondAreaPayload {
  selectedPondAreaId: null | number;
}

const selectPond = actionCreator<SelectPondPayload>("selectPond");
interface SelectPondPayload {
  selectedPondId: null | number;
}
const selectWorker = actionCreator<SelectWorkerPayload>("selectWorker");
interface SelectWorkerPayload {
  selectedWorkerId: null | number;
}

const clearSelectState = actionCreator("clearSelectState");
const changeCurrentPage = actionCreator<changeCurrentPagePayload>("changeCurrentPage");
interface changeCurrentPagePayload {
  currentPage: PageType;
}

const changeSortDate = actionCreator<{
  isAscDate: boolean;
}>("changeSortDate");

const changeOnlyEmptyPond = actionCreator<{
  isOnlyEmptyPond: boolean;
}>("changeOnlyEmptyPond");
const addSelectedPondStatus = actionCreator<{
  pondStatusId: number;
}>("addSelectedPondStatus");
const removeSelectedPondStatus = actionCreator<{
  pondStatusId: number;
}>("removeSelectedPondStatus");
const addNotSelectedCarpVarietyType = actionCreator<{
  carpVarietyId: number;
}>("addNotSelectedCarpVarietyType");
const removeNotSelectedCarpVarietyType = actionCreator<{
  carpVarietyId: number;
}>("removeNotSelectedCarpVarietyType");

export const mobileNavigationActions = {
  openMenu,
  closeMenu,
  resetState,
  changeSelectedDate,
  changeGraphPeriodType,
  selectPondArea,
  selectPond,
  selectWorker,
  clearSelectState,
  changeCurrentPage,
  changeSortDate,
  changeOnlyEmptyPond,
  addSelectedPondStatus,
  removeSelectedPondStatus,
  addNotSelectedCarpVarietyType,
  removeNotSelectedCarpVarietyType,
};

// Reducers
import { reducerWithInitialState } from "typescript-fsa-reducers";
import {
  FilterHandlers,
  getInitialState,
  InitializeHandlers,
  MenuHandlers,
  NavigationStateType,
  SelectHandlers,
  SortHandlers,
} from "./reducer";

export const navigationStateReducer = reducerWithInitialState<NavigationStateType>(getInitialState())
  .case(openMenu, (state) => MenuHandlers.openMenu(state))
  .case(closeMenu, (state) => MenuHandlers.closeMenu(state))
  .case(resetState, (state) => InitializeHandlers.resetState(state))
  .case(changeSelectedDate, (state, { selectedDate }) => SelectHandlers.changeSelectedDate(state, selectedDate))
  .case(changeGraphPeriodType, (state, { periodType }) => SelectHandlers.changeGraphPeriodType(state, periodType))
  .case(selectPondArea, (state, { selectedPondAreaId }) => SelectHandlers.selectPondArea(state, selectedPondAreaId))
  .case(selectPond, (state, { selectedPondId }) => SelectHandlers.selectPond(state, selectedPondId))
  .case(selectWorker, (state, { selectedWorkerId }) => SelectHandlers.selectWorker(state, selectedWorkerId))
  .case(clearSelectState, (state) => SelectHandlers.clearSelectState(state))
  .case(changeCurrentPage, (state, { currentPage }) => SelectHandlers.changeCurrentPage(state, currentPage))
  .case(changeSortDate, (state, { isAscDate }) => SortHandlers.changeSortDate(state, isAscDate))
  .case(changeOnlyEmptyPond, (state, { isOnlyEmptyPond }) => FilterHandlers.changeOnlyEmptyPond(state, isOnlyEmptyPond))
  .case(addSelectedPondStatus, (state, { pondStatusId }) => FilterHandlers.addSelectedPondStatus(state, pondStatusId))
  .case(removeSelectedPondStatus, (state, { pondStatusId }) =>
    FilterHandlers.removeSelectedPondStatus(state, pondStatusId)
  )
  .case(addNotSelectedCarpVarietyType, (state, { carpVarietyId }) =>
    FilterHandlers.addNotSelectedCarpVariety(state, carpVarietyId)
  )
  .case(removeNotSelectedCarpVarietyType, (state, { carpVarietyId }) =>
    FilterHandlers.removeNotSelectedCarpVariety(state, carpVarietyId)
  );
