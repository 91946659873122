import * as React from "react";
import styled from "styled-components";
import { getPairPondReport, NewOrEditCarpMovingReport } from "../../../../domain/carpMovingReport";
import {
  getSelectionName,
  isReleaseCarpSelectionNumber,
  isTakeoutCarpSelectionNumber,
} from "../../../../domain/carpSelectionReport";
import { getUniqueKey } from "../../../../domain/entity";
import { Pond } from "../../../../domain/pond";
import { PondArea } from "../../../../domain/pondArea";
import { PondReport } from "../../../../domain/pondReport";
import { ScaleType } from "../../../../domain/scaleType";
import { DIMEN } from "../../../styles/Dimen";
import { DefaultButton, SaveButton } from "../../atoms/Button/Mobile";
import { Form } from "../../atoms/Form/Form";
import { Checkbox } from "../../atoms/Form/FormCheckbox";
import { FormTextInput } from "../../atoms/Form/Input";
import { FormSelect } from "../../atoms/Form/Select";
import { ContentSection, PageSection } from "../../atoms/Section";
import { Spinner } from "../../atoms/Spinner";
import { ReportAmountInputOnly } from "../ReportAmountInput";
import { ReportDateInput } from "../ReportDateInput";

interface PondReportFormSelectionProps {
  ponds: Pond[];
  pondAreas: PondArea[];
  pondReports: PondReport[];
  carpSizeTypes: ScaleType[];
  carpQualityTypes: ScaleType[];
  malformationTypes: ScaleType[];
  carpRatioTypes: ScaleType[];
  selectionNumber: number;
  amount: string;
  date: Date | null;
  carpSizeTypeId: null | number;
  carpQualityTypeId: null | number;
  malformationTypeId: null | number;
  carpRatioTypeId: null | number;
  carpScore: string;
  note: string;
  isCompleteSelection: boolean;
  disabledMoving: boolean;
  movingReports: NewOrEditCarpMovingReport[];
  onChangeAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDate: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickToday: () => void;
  onClickYesterday: () => void;
  onClickYesterdayMinusOne: () => void;
  onChangeCarpSize: (sizeId: null | number) => void;
  onChangeCarpQuality: (qualityId: null | number) => void;
  onChangeMalformation: (malformationId: null | number) => void;
  onChangeCarpRatio: (ratioId: null | number) => void;
  onChangeCarpScore: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeNote: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeIsCompleteSelection: (checked: boolean) => void;
  onClickCarpMoving: () => void;
}
export const PondReportFormSelection = (
  props: PondReportFormSelectionProps & { onClickSave: () => void; isSaving: boolean }
) => {
  return (
    <Form>
      {props.isSaving && <Spinner />}
      <PageSection>{getSelectionName(props.selectionNumber)}</PageSection>
      <PondReportFormBody {...props} />
      <SaveButton onClick={props.onClickSave} />
    </Form>
  );
};

export const PondReportFormBody = (props: PondReportFormSelectionProps) => {
  const isReleaseCarpSelectionReport = isReleaseCarpSelectionNumber(props.selectionNumber);
  return (
    <>
      <ContentSection>匹数</ContentSection>
      <Content>
        <ReportAmountInputOnly amount={props.amount} unit={"匹"} onChangeAmount={props.onChangeAmount} />
      </Content>
      {!isReleaseCarpSelectionReport && (
        <>
          <ContentSection>サイズ</ContentSection>
          <Content>
            <FormSelect value={props.carpSizeTypeId} options={props.carpSizeTypes} onChange={props.onChangeCarpSize} />
          </Content>
          <ContentSection>評価</ContentSection>
          <Content>
            <FormSelect
              value={props.carpQualityTypeId}
              options={props.carpQualityTypes}
              onChange={props.onChangeCarpQuality}
            />
          </Content>
          <ContentSection>点数</ContentSection>
          <Content>
            <ReportAmountInputOnly amount={props.carpScore} unit={"点"} onChangeAmount={props.onChangeCarpScore} />
          </Content>
          <ContentSection>奇形</ContentSection>
          <Content>
            <FormSelect
              value={props.malformationTypeId}
              options={props.malformationTypes}
              onChange={props.onChangeMalformation}
            />
          </Content>
          <ContentSection>数</ContentSection>
          <Content>
            <FormSelect
              value={props.carpRatioTypeId}
              options={props.carpRatioTypes}
              onChange={props.onChangeCarpRatio}
            />
          </Content>
        </>
      )}
      <ContentSection>日付</ContentSection>
      <Content>
        <ReportDateInput
          date={props.date}
          onChangeDate={props.onChangeDate}
          onClickToday={props.onClickToday}
          onClickYesterday={props.onClickYesterday}
          onClickYesterdayMinusOne={props.onClickYesterdayMinusOne}
        />
      </Content>
      <ContentSection>今年度選別完了</ContentSection>
      <Content>
        <Checkbox
          checked={props.isCompleteSelection}
          onChange={props.onChangeIsCompleteSelection}
          label={"今年度の選別はこれ以上行わない"}
        />
      </Content>
      {!isTakeoutCarpSelectionNumber(props.selectionNumber) && (
        <>
          <ContentSection>移動</ContentSection>
          <Content>
            <MoveOuter>
              <MoveTitle>
                {props.movingReports.map((mr) => {
                  const pairReport = getPairPondReport(mr, props.pondReports);
                  const pond = !!pairReport && props.ponds.find((p) => p.id === pairReport.pondId);
                  const pondArea = !!pond && props.pondAreas.find((a) => a.id === pond.pondAreaId);
                  return (
                    <MoveItem key={getUniqueKey(mr)}>{`${pondArea ? pondArea.name : ""} ${
                      pond ? pond.name : ""
                    }`}</MoveItem>
                  );
                })}
              </MoveTitle>
              <DefaultButton disabled={props.disabledMoving} onClick={props.onClickCarpMoving}>
                移動先野池選択
              </DefaultButton>
            </MoveOuter>
          </Content>
        </>
      )}
      <ContentSection>メモ</ContentSection>
      <Content>
        <FormTextInput value={props.note} onChange={props.onChangeNote} />
      </Content>
    </>
  );
};

const Content = styled.div`
  padding: ${DIMEN.X1};
`;

const MoveOuter = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const MoveTitle = styled.div`
  font-weight: 700;
`;
const MoveItem = styled.div``;
