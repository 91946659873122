import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { CarpSelectionReportStateService } from "../../../../application/mobile/pondReport/carpSelectionReportState";
import { MoveModalStateService } from "../../../../application/mobile/pondReport/moveModalState";
import { isActiveEntity } from "../../../../domain/entity";
import { isUsedPond, Pond } from "../../../../domain/pond";
import { PondArea } from "../../../../domain/pondArea";
import { PondReport } from "../../../../domain/pondReport";
import { ApplicationState } from "../../../../store/modules";
import { MoveModalStateType } from "../../../../store/modules/mobile/pondReport/moveModalState/reducer";
import { MobileCarpMovingFormModal } from "../../../components/molecules/CarpMovingFormModal";

interface StateProps {
  moveModalState: MoveModalStateType;
  pondReports: PondReport[];
  pondAreas: PondArea[];
  ponds: Pond[];
}

interface DispatchProps {
  carpSelectionReportStateService: CarpSelectionReportStateService;
  moveModalStateService: MoveModalStateService;
}

type MoveModalProps = StateProps & DispatchProps;

export class Wrapped extends React.Component<MoveModalProps> {
  public render() {
    return (
      <MobileCarpMovingFormModal
        isOpen={this.props.moveModalState.isOpen}
        modalState={this.props.moveModalState.modalState}
        pondAreas={this.props.pondAreas}
        ponds={this.props.ponds}
        onChangePondArea={this.handleChangePondArea}
        onChangePond={this.handleChangePond}
        onSave={this.handleClickSave}
        onClose={this.handleClickClose}
      />
    );
  }

  private handleChangePondArea = (pondAreaId: null | number) => {
    this.props.moveModalStateService.changePondArea(pondAreaId);
  };
  private handleChangePond = (pondId: null | number) => {
    this.props.moveModalStateService.changePond(pondId);
    this.props.moveModalStateService.getPondReports();
  };

  private handleClickSave = async () => {
    await this.props.carpSelectionReportStateService.addCarpMovingReport(() =>
      this.props.moveModalStateService.closeModal()
    );
  };

  private handleClickClose = () => {
    this.props.moveModalStateService.closeModal();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { pondReport, pondArea, pond } = state.api;
  const { moveModalState } = state.mobile.pondReportState;
  const { selectedPondId } = state.mobile.navigation;
  return {
    moveModalState,
    pondReports: pondReport.pondReports,
    pondAreas: pondArea.pondAreas.filter(isActiveEntity),
    // 自身のPondへの移動を禁止するため
    ponds: pond.ponds.filter((p) => isActiveEntity(p) && isUsedPond(p) && p.id !== selectedPondId),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    carpSelectionReportStateService: new CarpSelectionReportStateService(dispatch),
    moveModalStateService: new MoveModalStateService(dispatch),
  };
};

export const MoveModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);
