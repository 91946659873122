export interface MoveModalStateType {
  modalState: ModalStateType;
  isOpen: boolean;
}
export interface ModalStateType {
  pondAreaId: null | number;
  pondId: null | number;
  carpSizeTypeId: null | number;
  carpQualityTypeId: null | number;
  malformationTypeId: null | number;
  carpRatioTypeId: null | number;
  amount: string;
  note: string;
}

export const getInitialState = (): MoveModalStateType => {
  return {
    modalState: getInitialModalState(),
    isOpen: false,
  };
};
export const getInitialModalState = (): ModalStateType => {
  return {
    pondAreaId: null,
    pondId: null,
    carpSizeTypeId: null,
    carpQualityTypeId: null,
    malformationTypeId: null,
    carpRatioTypeId: null,
    amount: "",
    note: "",
  };
};

export const InitialHandler = {
  openModal(): MoveModalStateType {
    return { ...getInitialState(), isOpen: true };
  },
  closeModal(): MoveModalStateType {
    return { ...getInitialState(), isOpen: false };
  },
};

export const ChangeHandler = {
  changeModalState(state: MoveModalStateType, key: keyof ModalStateType, value: any): MoveModalStateType {
    return { ...state, modalState: { ...state.modalState, [key]: value } };
  },
};
