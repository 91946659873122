import * as _ from "lodash";
import { ApplicationError } from "../../handler/errors/applicationError";
import { Entity, generateUid, isNewEntity, NewEntity } from "../entity";
import { getValidation, isEmptyString, isLimitedNumber, Validation, Validator } from "../validation";

interface AdultCarpReportAttribute extends Record<string, unknown> {
  selectionNumber: number;
  date: Date | null;
  amount: string;
  note: string;
}

export interface NewAdultCarpReport extends AdultCarpReportAttribute, NewEntity {}
export interface EditAdultCarpReport extends AdultCarpReportAttribute, Entity {
  date: Date;
}

export type NewOrEditAdultCarpReport = NewAdultCarpReport | EditAdultCarpReport;
export type AdultCarpReport = EditAdultCarpReport;

// service
const NEW_ENTITY_PREFIX = "acr-";

export function createNewAdultCarpReport(selectionNumber: number): NewAdultCarpReport {
  return {
    uid: generateUid(NEW_ENTITY_PREFIX),
    selectionNumber,
    date: null,
    amount: "",
    note: "",
  };
}

export function isNewAdultCarpReport(adultCarpReport: NewOrEditAdultCarpReport): adultCarpReport is NewAdultCarpReport {
  return isNewEntity(adultCarpReport);
}

export function isEmptyAdultCarpReport(report: NewOrEditAdultCarpReport): boolean {
  if (report.date !== null) {
    return false;
  }
  if (report.amount !== "") {
    return false;
  }
  if (report.note !== "") {
    return false;
  }
  return true;
}

export function removeEmptyAdultCarpReport(reports: NewOrEditAdultCarpReport[]): NewOrEditAdultCarpReport[] {
  const lastIndex = _.findLastIndex(reports, (r) => !isEmptyAdultCarpReport(r));
  return reports.slice(0, lastIndex + 1);
}

export const ADULT_CARP_REPORTS_MAX_COUNT = 2;
export function fillEmptyAdultCarpReport(reports: NewOrEditAdultCarpReport[]): NewOrEditAdultCarpReport[] {
  const diff = ADULT_CARP_REPORTS_MAX_COUNT - reports.length;
  const fillReports = _.rangeRight(0, diff, 1).map((num) =>
    createNewAdultCarpReport(ADULT_CARP_REPORTS_MAX_COUNT - 1 - num)
  );
  return reports.concat(fillReports);
}

export function getAdultSelectionName(selectionNumber: number): string {
  if (selectionNumber === 0) {
    return "放鯉";
  }
  if (selectionNumber === 1) {
    return "池揚げ";
  }
  return "";
}

/*** Validator ***/
export class AdultCarpReportValidator extends Validator<NewOrEditAdultCarpReport> {
  private selectionNumber: number | null = null;

  public getMessages(): string {
    return `${this.getSelectionNumberString()} : ${super.getMessages()}`;
  }

  public validate(entity: NewOrEditAdultCarpReport) {
    this.selectionNumber = entity.selectionNumber;

    const dateValidation = validateDate(entity);
    if (dateValidation) {
      this.addMessages(dateValidation);
    }
    const amountValidation = validateAmount(entity);
    if (amountValidation) {
      this.addMessages(amountValidation);
    }
  }

  private getSelectionNumberString(): string {
    if (this.selectionNumber === null) {
      throw new ApplicationError("不正な操作です。");
    }
    return getAdultSelectionName(this.selectionNumber);
  }
}

function validateDate({ date }: NewOrEditAdultCarpReport): Validation | null {
  if (date == null) {
    return getValidation("日付は必須です。入力してください。");
  }
  return null;
}

function validateAmount({ amount }: NewOrEditAdultCarpReport): Validation | null {
  if (isEmptyString(amount)) {
    return getValidation("匹数は必須です。入力してください。");
  }
  if (!isLimitedNumber(amount, 9, 4)) {
    return getValidation("匹数は整数部9桁、小数部4桁で入力してください。");
  }
  return null;
}
