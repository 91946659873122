import * as React from "react";
import styled from "styled-components";
import { DIMEN } from "../../../styles/Dimen";
import { DefaultButton } from "../../atoms/Button/Mobile";
import { FormInputDatePicker } from "../../atoms/Form/DatePicker";

interface ReportDateInputProps {
  date: Date | null;
  onChangeDate: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickToday: () => void;
  onClickYesterday: () => void;
  onClickYesterdayMinusOne: () => void;
}
export const ReportDateInput = ({
  date,
  onChangeDate,
  onClickToday,
  onClickYesterday,
  onClickYesterdayMinusOne,
}: ReportDateInputProps) => {
  return (
    <>
      <FormInputDatePicker date={date} onChange={onChangeDate} />
      <ButtonGroup>
        <DefaultButton onClick={onClickToday}>今日</DefaultButton>
        <DefaultButton onClick={onClickYesterday}>昨日</DefaultButton>
        <DefaultButton onClick={onClickYesterdayMinusOne}>一昨日</DefaultButton>
      </ButtonGroup>
    </>
  );
};

const ButtonGroup = styled.div`
  margin: ${DIMEN.X2} 0;
  display: flex;
  justify-content: space-evenly;
`;
