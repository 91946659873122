import { EditPondArea, NewPondArea, PondArea } from "../../domain/pondArea";
import * as api from "../../libs/client";
import { translateApiToPondArea, translatePondOwnerToApi } from "./translate";

interface IPondAreaRepository {
  getPondAreas: () => Promise<PondArea[]>;
  postPondArea: (pondArea: NewPondArea) => Promise<PondArea>;
  putPondArea: (pondArea: EditPondArea) => Promise<PondArea>;
  deletePondArea: (pondArea: PondArea) => Promise<PondArea>;
}

export class PondAreaRepository implements IPondAreaRepository {
  public async getPondAreas(): Promise<PondArea[]> {
    const pondAreasApi = new api.PondAreasApi(api.serverConfig);
    const response = await pondAreasApi.getPondAreas();
    return response.data.map((d) => translateApiToPondArea(d));
  }
  public async postPondArea(pondArea: NewPondArea): Promise<PondArea> {
    const pondAreasApi = new api.PondAreasApi(api.serverConfig);
    const response = await pondAreasApi.postPondArea(translatePondOwnerToApi(pondArea));
    return translateApiToPondArea(response.data);
  }

  public async putPondArea(pondArea: EditPondArea): Promise<PondArea> {
    const pondAreasApi = new api.PondAreasApi(api.serverConfig);
    const response = await pondAreasApi.putPondArea(pondArea.id, translatePondOwnerToApi(pondArea));
    return translateApiToPondArea(response.data);
  }

  public async deletePondArea(pondArea: PondArea): Promise<PondArea> {
    const pondAreasApi = new api.PondAreasApi(api.serverConfig);
    const response = await pondAreasApi.deletePondArea(pondArea.id);
    return translateApiToPondArea(response.data);
  }
}
