import { actionCreatorFactory } from "typescript-fsa";
import { Feed } from "../../../../domain/feed";
// Actions
const actionCreator = actionCreatorFactory("ApiFeed");

const fetchStart = actionCreator("fetchStart");
const fetchComplete = actionCreator<FetchCompletePayload>("fetchComplete");
interface FetchCompletePayload {
  feeds: Feed[];
}
const fetchFail = actionCreator("fetchFail");
const shouldFetch = actionCreator("shouldFetch");

// save
const createFeed = actionCreator<CreateFeedPayload>("createFeed");
interface CreateFeedPayload {
  feed: Feed;
}
const updateFeed = actionCreator<UpdateFeedPayload>("updateFeed");
interface UpdateFeedPayload {
  feed: Feed;
}
const deleteFeed = actionCreator<DeleteFeedPayload>("deleteFeed");
interface DeleteFeedPayload {
  feed: Feed;
}

export const apiFeedActions = {
  fetchStart,
  fetchComplete,
  fetchFail,
  shouldFetch,
  createFeed,
  updateFeed,
  deleteFeed,
};

// Reducers
import { FeedStateType, FetchHandlers, getInitialState, SaveHandlers } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const FeedReducer = reducerWithInitialState<FeedStateType>(getInitialState())
  .case(fetchStart, (state) => FetchHandlers.start(state))
  .case(fetchComplete, (state, { feeds }) => FetchHandlers.complete(state, feeds))
  .case(fetchFail, (state) => FetchHandlers.fail(state))
  .case(shouldFetch, (state) => FetchHandlers.shouldFetch(state))
  .case(createFeed, (state, { feed }) => SaveHandlers.createFeed(state, feed))
  .case(updateFeed, (state, { feed }) => SaveHandlers.updateFeed(state, feed))
  .case(deleteFeed, (state, { feed }) => SaveHandlers.deleteFeed(state, feed));
