import { actionCreatorFactory } from "typescript-fsa";
import { Fertilizer } from "../../../../domain/fertilizer";
// Actions
const actionCreator = actionCreatorFactory("ApiFertilizer");

const fetchStart = actionCreator("fetchStart");
const fetchComplete = actionCreator<FetchCompletePayload>("fetchComplete");
interface FetchCompletePayload {
  fertilizers: Fertilizer[];
}
const fetchFail = actionCreator("fetchFail");
const shouldFetch = actionCreator("shouldFetch");

// save
const createFertilizer = actionCreator<CreateFertilizerPayload>("createFertilizer");
interface CreateFertilizerPayload {
  fertilizer: Fertilizer;
}
const updateFertilizer = actionCreator<UpdateFertilizerPayload>("updateFertilizer");
interface UpdateFertilizerPayload {
  fertilizer: Fertilizer;
}
const deleteFertilizer = actionCreator<DeleteFertilizerPayload>("deleteFertilizer");
interface DeleteFertilizerPayload {
  fertilizer: Fertilizer;
}

export const apiFertilizerActions = {
  fetchStart,
  fetchComplete,
  fetchFail,
  shouldFetch,
  createFertilizer,
  updateFertilizer,
  deleteFertilizer,
};

// Reducers
import { FertilizerStateType, FetchHandlers, getInitialState, SaveHandlers } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const FertilizerReducer = reducerWithInitialState<FertilizerStateType>(getInitialState())
  .case(fetchStart, (state) => FetchHandlers.start(state))
  .case(fetchComplete, (state, { fertilizers }) => FetchHandlers.complete(state, fertilizers))
  .case(fetchFail, (state) => FetchHandlers.fail(state))
  .case(createFertilizer, (state, { fertilizer }) => SaveHandlers.createFertilizer(state, fertilizer))
  .case(updateFertilizer, (state, { fertilizer }) => SaveHandlers.updateFertilizer(state, fertilizer))
  .case(deleteFertilizer, (state, { fertilizer }) => SaveHandlers.deleteFertilizer(state, fertilizer));
