export interface ImageGalleryStateType {
  showImageGallery: boolean;
  imageIds: number[];
  initialImageId: number | null;
}

export const getInitialState = (): ImageGalleryStateType => {
  return {
    showImageGallery: false,
    imageIds: [],
    initialImageId: null,
  };
};

export const InitHandlers = {
  showImages(state: ImageGalleryStateType, imageIds: number[], initialImageId: null | number): ImageGalleryStateType {
    return { ...state, showImageGallery: true, imageIds, initialImageId };
  },
  hideImages(state: ImageGalleryStateType): ImageGalleryStateType {
    return { ...state, showImageGallery: false, imageIds: [], initialImageId: null };
  },
};
