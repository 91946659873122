import { combineReducers } from "redux";
import { NewOrEditStateType } from "./newOrEditState/reducer";

export interface MasterFertilizerState {
  newOrEditState: NewOrEditStateType;
}

import { NewOrEditStateReducer } from "./newOrEditState/ducks";

export const fertilizerReducer = combineReducers({
  newOrEditState: NewOrEditStateReducer,
});
