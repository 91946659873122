import styled from "styled-components";
import { COLOR } from "../../../styles/Color";
import { DIMEN } from "../../../styles/Dimen";

export const PageSection = styled.div`
  background-color: ${COLOR.AccentColor.base};
  color: ${COLOR.Base.white};
  padding-left: 1em;

  &.large {
    padding: ${DIMEN.X1_5};
    padding-left: 1.5em;
  }
`;

export const ContentSection = styled.div`
  background-color: ${COLOR.Gray["lighter-2"]};
  padding: ${DIMEN.X0_5};
  padding-left: 1em;
  font-weight: 700;
`;

interface OverFlowSectionProps {
  height?: string;
}
export const OverFlowSection = styled.div`
  height: ${({ height }: OverFlowSectionProps) => height || "100%"};
  width: 100%;
  overflow-y: auto;
`;
