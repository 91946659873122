import { UniqueKey } from "../../../../../domain/entity";
import { FetchLifeCycle } from "../../../../lifeCycle/fetchLifeCycle";

export interface ListStateType extends FetchLifeCycle {
  selectedReportKey: "PondDisinfection" | "CarpSelection" | "AdultCarp" | null;
  selectedUniqueKey: UniqueKey | null;
}

export const getInitialState = (): ListStateType => {
  return {
    selectedReportKey: null,
    selectedUniqueKey: null,
    isFetching: false,
    isFetched: false,
    isError: null,
  };
};

export const InitialHandler = {
  initialStart(state: ListStateType): ListStateType {
    return { ...state, isFetching: true, isFetched: false, isError: null };
  },
  initialComplete(state: ListStateType): ListStateType {
    return { ...state, isFetching: false, isFetched: true };
  },
  initialFailed(state: ListStateType): ListStateType {
    return { ...state, isFetching: false, isFetched: false };
  },
};

export const SelectHandler = {
  selectPondDisinfectionReport(state: ListStateType, selectedUniqueKey: UniqueKey): ListStateType {
    return { ...state, selectedReportKey: "PondDisinfection", selectedUniqueKey };
  },
  selectCarpSelectionReport(state: ListStateType, selectedUniqueKey: UniqueKey): ListStateType {
    return { ...state, selectedReportKey: "CarpSelection", selectedUniqueKey };
  },
  selectAdultCarpReport(state: ListStateType, selectedUniqueKey: UniqueKey): ListStateType {
    return { ...state, selectedReportKey: "AdultCarp", selectedUniqueKey };
  },
};
