import { actionCreatorFactory } from "typescript-fsa";
import { ScaleType } from "../../../../domain/scaleType";

// Actions
const actionCreator = actionCreatorFactory("ApiScaleType");

const fetchStart = actionCreator("fetchStart");
const fetchComplete = actionCreator<FetchCompletePayload>("fetchComplete");
interface FetchCompletePayload {
  scaleTypes: ScaleType[];
}
const fetchFail = actionCreator("fetchFail");
const shouldFetch = actionCreator("shouldFetch");

export const apiScaleTypeActions = {
  fetchStart,
  fetchComplete,
  fetchFail,
  shouldFetch,
};

// Reducers
import { FetchHandlers, getInitialState, ScaleTypeStateType } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const ScaleTypeReducer = reducerWithInitialState<ScaleTypeStateType>(getInitialState())
  .case(fetchStart, (state) => FetchHandlers.start(state))
  .case(fetchComplete, (state, { scaleTypes }) => FetchHandlers.complete(state, scaleTypes))
  .case(fetchFail, (state) => FetchHandlers.fail(state))
  .case(shouldFetch, (state) => FetchHandlers.shouldFetch(state));
