import * as _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NewOrEditStateService as EnvironmentReportService } from "../../../application/report/environmentReport/newOrEditState";
import { NavigationStateService } from "../../../application/report/navigation";
import { NewOrEditStateService as WorkReportService } from "../../../application/report/workReport/newOrEditState";
import { isNewEntity } from "../../../domain/entity";
import { NewOrEditEnvironmentReport } from "../../../domain/environmentReport";
import { Pond } from "../../../domain/pond";
import { PondArea } from "../../../domain/pondArea";
import { SearchQuery } from "../../../domain/searchQuery";
import { isNumber } from "../../../domain/validation";
import { NewOrEditWorkReport } from "../../../domain/workReport";
import { ApplicationState } from "../../../store/modules";
import { ImageStateType } from "../../../store/modules/api/image/reducer";
import { imageGalleryStateActions } from "../../../store/modules/imageGallery/ducks";
import { notificationQueueStateActions } from "../../../store/modules/notification/queue/ducks";
import { NewOrEditStateType as EnvironmentReportNewOrEditStateType } from "../../../store/modules/report/environmentReport/newOrEditState/reducer";
import { NavigationStateType } from "../../../store/modules/report/navigation/reducer";
import { NewOrEditStateType as WorkReportNewOrEditStateType } from "../../../store/modules/report/workReport/newOrEditState/reducer";
import { ConfirmModal } from "../../components/molecules/ConfirmModal";
import { PageHeader } from "../../components/molecules/PageHeader";
import { EnvironmentReportForm } from "../../components/organisms/EnvironmentReportForm";
import { MainLayout } from "../Layout";
import { ReportLayout } from "../Layout/Report";
import { DefaultPage, SpinnerPage } from "./DefaultPage";
import { PondsList } from "./PondsList";

interface StateProps {
  ponds: Pond[];
  pondAreas: PondArea[];
  navigationState: NavigationStateType;
  environmentReportNewOrEditState: EnvironmentReportNewOrEditStateType;
  workReportNewOrEditState: WorkReportNewOrEditStateType;
  image: ImageStateType;
}

interface OwnProps {
  environmentReport: NewOrEditEnvironmentReport;
  workReport: NewOrEditWorkReport;
}

interface DispatchProps {
  environmentReportService: EnvironmentReportService;
  navigationStateService: NavigationStateService;
  workReportService: WorkReportService;
  showImageGallery: (imageIds: number[], imageId: number) => void;
  notifySaveMessage: () => void;
  notifyDeleteMessage: () => void;
}

type EnvironmentReportPageProps = StateProps & OwnProps & DispatchProps & RouteComponentProps;

interface EnvironmentReportPageState {
  isOpenDeleteConfirm: boolean;
}

export class Wrapped extends React.Component<EnvironmentReportPageProps, EnvironmentReportPageState> {
  constructor(props: EnvironmentReportPageProps) {
    super(props);

    this.state = {
      isOpenDeleteConfirm: false,
    };
  }

  public componentWillMount(): void {
    this.initStateOnMounted();
  }

  public render() {
    return (
      <MainLayout>
        <ReportLayout
          header={<PageHeader title={"水温を記録"} />}
          list={<PondsList onClickPond={this.handleClickPond} />}
          form={this.renderForm()}
          listColumn={2}
        />
      </MainLayout>
    );
  }

  private async initStateOnMounted() {
    await this.props.navigationStateService.fetchApi();
    if (this.props.navigationState.selectedPondId !== null) {
      await Promise.all([
        this.props.workReportService.initWorkReport(),
        this.props.environmentReportService.initEnvironmentReport(),
      ]);
    }
  }

  private renderForm() {
    const pond = this.props.ponds.find((p) => p.id === this.props.navigationState.selectedPondId);
    const { environmentReportNewOrEditState, workReportNewOrEditState, navigationState } = this.props;
    const { selectedPondId, selectedDate } = navigationState;
    if (selectedPondId === null) {
      this.renderEmpty();
    }
    const { environmentReport } = environmentReportNewOrEditState;
    const { workReport } = workReportNewOrEditState;
    const isSaving = environmentReportNewOrEditState.isSaving || workReportNewOrEditState.isSaving;

    if (!pond) {
      return this.renderEmpty();
    }

    if (environmentReport === null || workReport === null) {
      return this.renderSpinner();
    }

    return (
      <>
        <EnvironmentReportForm
          title={this.getFormTitle()}
          date={selectedDate}
          value={environmentReport.value}
          note={workReport.note}
          imageIds={workReport.imageIds}
          onChangeDate={this.handleChangeDate}
          onChangeValue={this.handleChangeValue}
          onClickValueUp={_.noop}
          onClickValueDown={_.noop}
          onChangeNote={this.handleChangeNote}
          onChangeFile={this.handleChangeFile}
          onClickImage={this.handleClickImage}
          onClickImageRemove={this.handleClickImageRemove}
          onClickSave={this.handleClickSave}
          onClickCancel={this.handleClickCancel}
          onClickDelete={isNewEntity(environmentReport) ? null : this.openDeleteConfirmModal}
          isSaving={isSaving}
          disabledSave={this.props.image.isSaving}
        />

        <ConfirmModal
          isOpen={this.state.isOpenDeleteConfirm}
          onClose={this.closeDeleteConfirmModal}
          onClickCancel={this.closeDeleteConfirmModal}
          onClickOk={this.handleClickRemove}
          cancelText={"キャンセル"}
          okText={"削除"}
          confirmMessage={"水温記録を削除してもよろしいでしょうか？"}
        />
      </>
    );
  }

  private getFormTitle(): string {
    const { ponds, pondAreas, navigationState } = this.props;
    const { selectedPondId, selectedPondAreaId } = navigationState;
    const pond = ponds.find((p) => p.id === selectedPondId);
    const pondArea = pondAreas.find((a) => a.id === selectedPondAreaId);

    return `${pondArea ? pondArea.name : ""} ${pond ? pond.name : ""}`;
  }

  private renderEmpty() {
    return <DefaultPage />;
  }

  private renderSpinner() {
    return <SpinnerPage />;
  }

  private handleClickPond = async (id: number) => {
    this.props.navigationStateService.selectPond(id);
    await Promise.all([
      this.props.workReportService.initWorkReport(),
      this.props.environmentReportService.initEnvironmentReport(),
    ]);
  };

  private handleChangeDate = async (date: Date) => {
    this.props.navigationStateService.selectDate(date);
    await Promise.all([
      this.props.workReportService.initWorkReport(),
      this.props.environmentReportService.initEnvironmentReport(),
    ]);
  };

  private handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const num = e.target.value;
    if (isNumber(num)) {
      this.props.environmentReportService.changeValue(num);
    }
  };

  private handleChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.props.workReportService.changeNote(e.target.value);
  };

  private handleClickImage = (imageIds: number[], imageId: number) => {
    this.props.showImageGallery(imageIds, imageId);
  };

  private handleClickImageRemove = (id: number) => {
    this.props.workReportService.removeImage(id);
  };

  private handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length !== 0) {
      this.props.workReportService.addImage(e.target.files[0]);
    }
  };

  private handleClickSave = async () => {
    await Promise.all([
      this.props.environmentReportService.saveEnvironmentReport(),
      this.props.workReportService.saveWorkReport(),
    ]);
    if (this.props.workReportNewOrEditState.isSaved && this.props.environmentReportNewOrEditState.isSaved) {
      this.props.notifySaveMessage();
      const returnUrl = SearchQuery.getReturnURLFromState(this.props.location.search);
      if (!returnUrl) {
        return;
      }
      const url = SearchQuery.createURLFromState("", this.props.location.search);
      if (url !== "") {
        this.props.history.push(url);
        return;
      }
    }
  };

  private handleClickCancel = () => {
    this.props.workReportService.cancelWorkReport();
    this.props.environmentReportService.cancelEnvironmentReport();
  };

  private handleClickRemove = async () => {
    await this.props.environmentReportService.deleteEnvironmentReport();
    this.closeDeleteConfirmModal();
    this.props.notifyDeleteMessage();
    const returnUrl = SearchQuery.getReturnURLFromState(this.props.location.search);
    const url = SearchQuery.createURLFromState("", this.props.location.search);
    if (!returnUrl || url === "") {
      await this.props.environmentReportService.initEnvironmentReport();
      return;
    } else {
      this.props.history.push(url);
    }
  };

  private closeDeleteConfirmModal = () => {
    this.setState({ isOpenDeleteConfirm: false });
  };
  private openDeleteConfirmModal = () => {
    this.setState({ isOpenDeleteConfirm: true });
  };
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StateProps & OwnProps => {
  const { pond, pondArea } = state.api;
  return {
    ponds: pond.ponds,
    pondAreas: pondArea.pondAreas,
    navigationState: state.report.navigation,
    environmentReportNewOrEditState: state.report.environmentReport.newOrEditStateType,
    workReportNewOrEditState: state.report.workReport.newOrEditStateType,
    image: state.api.image,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    environmentReportService: new EnvironmentReportService(dispatch),
    navigationStateService: new NavigationStateService(dispatch),
    workReportService: new WorkReportService(dispatch),
    showImageGallery: (imageIds, imageId) =>
      dispatch(imageGalleryStateActions.showImages({ imageIds, initialImageId: imageId })),
    notifySaveMessage: () => dispatch(notificationQueueStateActions.addSaveMessage({ itemName: "水温記録" })),
    notifyDeleteMessage: () => dispatch(notificationQueueStateActions.addDeleteMessage({ itemName: "水温記録" })),
  };
};

export const EnvironmentReportPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
