import * as React from "react";
import styled from "styled-components";
import { ListNotSelectedMessage } from "../../components/atoms/Message";
import { Spinner } from "../../components/atoms/Spinner";

export const DefaultPage = () => {
  return (
    <Outer>
      <ListNotSelectedMessage>左のリストから項目を選択して下さい。</ListNotSelectedMessage>
    </Outer>
  );
};

export const SpinnerPage = () => {
  return (
    <Outer>
      <Spinner />
    </Outer>
  );
};

const Outer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
