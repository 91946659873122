import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { NewStateService } from "../../../application/master/herbicide/newState";
import { ApplicationState } from "../../../store/modules";
import { NewOrEditStateType } from "../../../store/modules/master/herbicide/newOrEditState/reducer";
import { HerbicideForm } from "../../components/organisms/HerbicideForm";

interface StateProps {
  newOrEditState: NewOrEditStateType;
}

interface DispatchProps {
  newStateService: NewStateService;
}

type HerbicideNewPageProps = StateProps & DispatchProps;

export class Wrapped extends React.Component<HerbicideNewPageProps> {
  public render() {
    const { herbicide, isSaving } = this.props.newOrEditState;
    return (
      <HerbicideForm
        herbicide={herbicide}
        isSaving={isSaving}
        onChangeName={this.handleChangeName}
        onClickSave={this.handleClickSaveHerbicide}
        onClickCancel={this.handleClickCancelHerbicide}
        onClickAdd={this.handleClickAddHerbicide}
        onClickDelete={null}
      />
    );
  }

  private handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.newStateService.changeName(e.target.value);
  };

  private handleClickSaveHerbicide = () => {
    this.props.newStateService.saveHerbicide();
  };

  private handleClickCancelHerbicide = () => {
    this.props.newStateService.cancelHerbicide();
  };

  private handleClickAddHerbicide = () => {
    this.props.newStateService.createHerbicide();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { newOrEditState } = state.master.herbicide;
  return {
    newOrEditState,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    newStateService: new NewStateService(dispatch),
  };
};

export const HerbicideNewPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);
