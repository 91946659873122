import { Dispatch } from "redux";
import { getUniqueKey, UniqueKey } from "../../../domain/entity";
import { copyHerbicideReport, createNewHerbicideReport, NewHerbicideReport } from "../../../domain/herbicideReport";
import { isHerbicideReportGroup } from "../../../domain/reportGroup";
import { filterWorkReportByPond, getLatestHerbicideReport } from "../../../domain/workReport";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { ApplicationState } from "../../../store/modules";
import { workReportNewOrEditStateActions } from "../../../store/modules/report/workReport/newOrEditState/ducks";
import { HerbicideApiService } from "../../api/herbicide";
import { UseMethodTypeApiService } from "../../api/useMethodType";
import { WorkReportApiService } from "../../api/workReport";

interface IHerbicideReportStateService extends IApplicationService {
  fetchApi: () => void;
  fetchLatestHerbicideReport: () => void;
  initHerbicideReport: () => void;
  addHerbicideReport: () => void;
  selectHerbicideReport: (uniqueKey: UniqueKey) => void;
  changeHerbicide: (id: null | number) => void;
  changeAmount: (amount: string) => void;
  changeUseMethodType: (id: null | number) => void;
}

export class HerbicideReportStateService implements IHerbicideReportStateService {
  private useMethodTypeApiService: UseMethodTypeApiService;
  private herbicideApiService: HerbicideApiService;
  private workReportApiService: WorkReportApiService;

  public constructor(private dispatch: Dispatch<any>) {
    this.useMethodTypeApiService = new UseMethodTypeApiService(dispatch);
    this.herbicideApiService = new HerbicideApiService(dispatch);
    this.workReportApiService = new WorkReportApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public async fetchApi() {
    await Promise.all([this.herbicideApiService.init(), this.useMethodTypeApiService.fetchUnFetched()]);
  }

  @catchApplicationError()
  public async fetchLatestHerbicideReport() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedDate, selectedPondId } = state.report.navigation;
      if (selectedPondId === null) {
        throw new ApplicationError("不正な操作です。");
      }
      await this.workReportApiService.fetchLatestHerbicideReport(selectedDate, selectedPondId);
    });
  }

  @catchApplicationError()
  public initHerbicideReport() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const { workReport } = getState().report.workReport.newOrEditStateType;
      if (!workReport) {
        return;
      }
      const herbicideReportCounts = workReport.herbicideReports.length;
      if (herbicideReportCounts === 0) {
        this.addHerbicideReport();
        return;
      }
      const herbicideReport = workReport.herbicideReports[0];
      if (!!herbicideReport) {
        this.selectHerbicideReport(getUniqueKey(herbicideReport));
        return;
      }
    });
  }

  @catchApplicationError()
  public addHerbicideReport() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const herbicidesUseMethodTypes = state.api.useMethodType.useMethodTypes.filter(isHerbicideReportGroup);
      // 現状、除草剤は「袋」固定
      const herbicideUseMethodTypeId = herbicidesUseMethodTypes[0].id;
      const { selectedDate, selectedPondId } = state.report.navigation;
      const filteredReportsByPond = state.api.workReport.workReports.filter((r) =>
        filterWorkReportByPond(r, selectedPondId)
      );
      const latestHerbicideReport = getLatestHerbicideReport(filteredReportsByPond, selectedDate);
      const newHerbicideReport = latestHerbicideReport
        ? (copyHerbicideReport(
          latestHerbicideReport,
          createNewHerbicideReport(herbicideUseMethodTypeId)
        ) as NewHerbicideReport)
        : createNewHerbicideReport(herbicideUseMethodTypeId);
      this.dispatch(workReportNewOrEditStateActions.addHerbicideReport({ herbicideReport: newHerbicideReport }));
      this.dispatch(
        workReportNewOrEditStateActions.selectAnyReport({
          selectedReportKey: "herbicide",
          selectedUniqueKey: newHerbicideReport.uid,
        })
      );
    });
  }

  @catchApplicationError()
  public selectHerbicideReport(uniqueKey: UniqueKey) {
    this.dispatch(
      workReportNewOrEditStateActions.selectAnyReport({
        selectedReportKey: "herbicide",
        selectedUniqueKey: uniqueKey,
      })
    );
  }

  @catchApplicationError()
  public changeHerbicide(id: null | number) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedReportKey, selectedUniqueKey } = state.report.workReport.newOrEditStateType;
      if (selectedReportKey !== "herbicide" || selectedUniqueKey === null) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        workReportNewOrEditStateActions.changeHerbicideReport({
          herbicideUniqueKey: selectedUniqueKey,
          key: "herbicideId",
          value: id,
        })
      );
    });
  }

  @catchApplicationError()
  public changeAmount(amount: string) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedReportKey, selectedUniqueKey } = state.report.workReport.newOrEditStateType;
      if (selectedReportKey !== "herbicide" || selectedUniqueKey === null) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        workReportNewOrEditStateActions.changeHerbicideReport({
          herbicideUniqueKey: selectedUniqueKey,
          key: "amount",
          value: amount,
        })
      );
    });
  }

  @catchApplicationError()
  public changeUseMethodType(id: null | number) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedReportKey, selectedUniqueKey } = state.report.workReport.newOrEditStateType;
      if (selectedReportKey !== "herbicide" || selectedUniqueKey === null) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        workReportNewOrEditStateActions.changeHerbicideReport({
          herbicideUniqueKey: selectedUniqueKey,
          key: "useMethodTypeId",
          value: id,
        })
      );
    });
  }
}
