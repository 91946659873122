import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NewOrEditStateService as EnvironmentReportService } from "../../../../application/mobile/environmentReport/newOrEditState";
import { NavigationService } from "../../../../application/mobile/navigation";
import { NewOrEditStateService as WorkReportService } from "../../../../application/mobile/workReport/newOrEditState";
import { Pond } from "../../../../domain/pond";
import { WorkReport } from "../../../../domain/workReport";
import { ApplicationState } from "../../../../store/modules";
import { NewOrEditStateType as EnvironmentReportNewOrEditState } from "../../../../store/modules/mobile/environmentReport/newOrEditState/reducer";
import { NavigationStateType } from "../../../../store/modules/mobile/navigation/reducer";
import { NewOrEditStateType as WorkReportNewOrEditState } from "../../../../store/modules/mobile/workReport/newOrEditState/reducer";
import { Spinner } from "../../../components/atoms/Spinner";
import { ActionsModal } from "../../../components/molecules/ActionsModal";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { NotificationModal } from "../../../components/molecules/NotificationModal";
import { MobileLayout } from "../../Layout/Mobile";
import { EnvironmentReportNewOrEditPage } from "./NewOrEdit";

interface StateProps {
  ponds: Pond[];
  workReports: WorkReport[];
  navigationState: NavigationStateType;
  environmentReportNewOrEditState: EnvironmentReportNewOrEditState;
  workReportNewOrEditState: WorkReportNewOrEditState;
  isLoaded: boolean;
}

interface DispatchProps {
  environmentReportService: EnvironmentReportService;
  navigationService: NavigationService;
  workReportService: WorkReportService;
}

type EnvironmentReportPageProps = StateProps & DispatchProps & RouteComponentProps;
interface EnvironmentReportPageState {
  isOpenNotificationModal: boolean;
  isOpenActionModal: boolean;
  notifiedMessage: string;
}

export class Wrapped extends React.Component<EnvironmentReportPageProps, EnvironmentReportPageState> {
  constructor(props: EnvironmentReportPageProps) {
    super(props);

    this.state = {
      isOpenNotificationModal: false,
      isOpenActionModal: false,
      notifiedMessage: "",
    };
  }

  public componentWillMount() {
    if (this.props.navigationState.selectedPondId === null) {
      this.props.history.push("/");
      return;
    }
    if (!this.props.isLoaded) {
      this.props.history.push("/reports/menu");
      return;
    }
    this.props.environmentReportService.resetEnvironmentReport();
    this.props.environmentReportService.initEnvironmentReport();
    this.props.workReportService.initWorkReport();
  }

  public render() {
    const { environmentReport } = this.props.environmentReportNewOrEditState;
    const { workReport } = this.props.workReportNewOrEditState;
    const pond = this.props.ponds.find((p) => p.id === this.props.navigationState.selectedPondId);

    if (!pond || !environmentReport || !workReport) {
      return this.renderEmpty();
    }

    return (
      <>
        <EnvironmentReportNewOrEditPage
          environmentReport={environmentReport}
          workReport={workReport}
          onClickSave={this.handleClickSave}
          openActionModal={this.openActionModal}
        />
        {this.renderModal()}
      </>
    );
  }

  private renderEmpty = () => {
    return (
      <MobileLayout>
        <BaseNavigationHeader title={""} onClickMenu={this.handleClickMenu} />
        <BaseNavigationContent>
          <Spinner />
          {this.renderModal()}
        </BaseNavigationContent>
      </MobileLayout>
    );
  };

  private renderModal = () => {
    return (
      <>
        <NotificationModal
          onClose={this.handleModalOk}
          isOpen={this.state.isOpenNotificationModal}
          onClickOk={this.handleModalOk}
          okText={"OK"}
          message={this.state.notifiedMessage}
        />
        <ActionsModal
          isOpen={this.state.isOpenActionModal}
          onClose={this.closeActionModal}
          actions={[{ text: "水温記録を削除する", onClick: this.handleClickRemove }]}
        />
      </>
    );
  };

  private handleClickMenu = () => {
    this.props.navigationService.openMenu();
  };

  private handleClickSave = async () => {
    await Promise.all([
      this.props.environmentReportService.saveEnvironmentReport(),
      this.props.workReportService.saveWorkReport(),
    ]);
    if (this.props.workReportNewOrEditState.isSaved && this.props.environmentReportNewOrEditState.isSaved) {
      this.openNotificationModal("保存しました");
    }
  };

  private handleClickRemove = async () => {
    this.closeActionModal();
    await this.props.environmentReportService.deleteEnvironmentReport();
    this.openNotificationModal("削除しました");
  };

  private handleModalOk = () => {
    this.closeNotificationModal();
    this.props.history.goBack();
  };

  private openActionModal = () => this.setState({ isOpenActionModal: true });
  private closeActionModal = () => this.setState({ isOpenActionModal: false });
  private openNotificationModal = (message: string) =>
    this.setState({ isOpenNotificationModal: true, notifiedMessage: message });
  private closeNotificationModal = () => this.setState({ isOpenNotificationModal: false, notifiedMessage: "" });
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { pond, workReport } = state.api;
  return {
    ponds: pond.ponds,
    workReports: workReport.workReports,
    navigationState: state.mobile.navigation,
    environmentReportNewOrEditState: state.mobile.environmentReportState.newOrEditStateType,
    workReportNewOrEditState: state.mobile.workReportState.newOrEditStateType,
    isLoaded: pond.isFetched,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    environmentReportService: new EnvironmentReportService(dispatch),
    navigationService: new NavigationService(dispatch),
    workReportService: new WorkReportService(dispatch),
  };
};

export const EnvironmentReportPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
