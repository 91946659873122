import * as _ from "lodash";
import { Entity, generateUid, NewEntity } from "../entity";
import { getValidation, isEmptyString, isLimitedNumber, Validation, Validator } from "../validation";

interface PondDisinfectionReportAttribute extends Record<string, unknown> {
  date: Date | null;
  amount: string;
  note: string;
}

export interface NewPondDisinfectionReport extends PondDisinfectionReportAttribute, NewEntity {}
export interface EditPondDisinfectionReport extends PondDisinfectionReportAttribute, Entity {}

export type NewOrEditPondDisinfectionReport = NewPondDisinfectionReport | EditPondDisinfectionReport;
export type PondDisinfectionReport = EditPondDisinfectionReport;

// service
const NEW_ENTITY_PREFIX = "pdr-";

export function createNewPondDisinfectionReport(): NewPondDisinfectionReport {
  return {
    uid: generateUid(NEW_ENTITY_PREFIX),
    date: null,
    amount: "",
    note: "",
  };
}

export function isNewPondDisinfectionReport(
  pondDisinfectionReport: NewOrEditPondDisinfectionReport
): pondDisinfectionReport is NewPondDisinfectionReport {
  return "uid" in pondDisinfectionReport;
}

export function isEmptyPondDisinfectionReport(report: NewOrEditPondDisinfectionReport): boolean {
  if (report.date !== null) {
    return false;
  }
  if (report.amount !== "") {
    return false;
  }
  if (report.note !== "") {
    return false;
  }
  return true;
}

export function removeEmptyPondDisinfectionReport(
  reports: NewOrEditPondDisinfectionReport[]
): NewOrEditPondDisinfectionReport[] {
  const lastIndex = _.findLastIndex(reports, (r) => !isEmptyPondDisinfectionReport(r));
  return reports.slice(0, lastIndex + 1);
}

export const POND_DISINFECTION_REPORTS_MAX_COUNT = 1;
export function fillEmptyPondDisinfectionReport(
  reports: NewOrEditPondDisinfectionReport[]
): NewOrEditPondDisinfectionReport[] {
  const diff = POND_DISINFECTION_REPORTS_MAX_COUNT - reports.length;
  const fillReports = _.rangeRight(0, diff, 1).map(createNewPondDisinfectionReport);
  return reports.concat(fillReports);
}

export function getDisinfectionName() {
  return "塩素";
}

/*** Validator ***/
export class PondDisinfectionReportValidator extends Validator<NewOrEditPondDisinfectionReport> {
  public validate(entity: NewOrEditPondDisinfectionReport) {
    const dateValidation = validateDate(entity);
    if (dateValidation) {
      this.addMessages(dateValidation);
    }
    const amountValidation = validateAmount(entity);
    if (amountValidation) {
      this.addMessages(amountValidation);
    }
  }
}

function validateDate({ date }: NewOrEditPondDisinfectionReport): Validation | null {
  if (date == null) {
    return getValidation("日付は必須です。入力してください。");
  }
  return null;
}

function validateAmount({ amount }: NewOrEditPondDisinfectionReport): Validation | null {
  if (isEmptyString(amount)) {
    return getValidation("本数は必須です。入力してください。");
  }
  if (!isLimitedNumber(amount, 9, 4)) {
    return getValidation("本数は整数部9桁、小数部4桁で入力してください。");
  }
  return null;
}
