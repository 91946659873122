import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NavigationService } from "../../../../application/mobile/navigation";
import { FertilizerReportStateService } from "../../../../application/mobile/workReport/fertilizerReportState";
import { NewOrEditStateService } from "../../../../application/mobile/workReport/newOrEditState";
import { isSameFiscalYear } from "../../../../domain/calendar";
import { isActiveEntity } from "../../../../domain/entity";
import { Fertilizer } from "../../../../domain/fertilizer";
import { NewOrEditFertilizerReport } from "../../../../domain/fertilizerReport";
import { Pond } from "../../../../domain/pond";
import { PondType } from "../../../../domain/pondType";
import { isFertilizerReportGroup } from "../../../../domain/reportGroup";
import { UseMethodType } from "../../../../domain/useMethodType";
import { NewOrEditWorkReport } from "../../../../domain/workReport";
import { ApplicationState } from "../../../../store/modules";
import { ImageStateType } from "../../../../store/modules/api/image/reducer";
import { imageGalleryStateActions } from "../../../../store/modules/imageGallery/ducks";
import { NavigationStateType } from "../../../../store/modules/mobile/navigation/reducer";
import { Spinner } from "../../../components/atoms/Spinner";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { WorkReportFormFertilizer } from "../../../components/molecules/WorkReportFormFertilizer";
import { MobileLayout } from "../../Layout/Mobile";

interface StateProps {
  ponds: Pond[];
  pondTypes: PondType[];
  fertilizers: Fertilizer[];
  useMethodTypes: UseMethodType[];
  navigationState: NavigationStateType;
  image: ImageStateType;
}

interface OwnProps {
  workReport: NewOrEditWorkReport | null;
  fertilizerReport: NewOrEditFertilizerReport | null;
  isSaving: boolean;
  onClickSave: () => void;
  openActionModal: () => void;
}

interface DispatchProps {
  newOrEditStateService: NewOrEditStateService;
  navigationService: NavigationService;
  fertilizerReportStateService: FertilizerReportStateService;
  showImageGallery: (imageIds: number[], imageId: number) => void;
}

type WorkReportFertilizerPageProps = StateProps & OwnProps & DispatchProps & RouteComponentProps;

export class Wrapped extends React.Component<WorkReportFertilizerPageProps> {
  public componentWillMount(): void {
    if (this.props.navigationState.selectedPondId === null) {
      this.props.history.push("/");
      return;
    }
  }

  public render() {
    const pond = this.props.ponds.find((p) => p.id === this.props.navigationState.selectedPondId);
    const { workReport, fertilizerReport, isSaving } = this.props;

    if (!pond || !workReport || !fertilizerReport) {
      return (
        <MobileLayout>
          <BaseNavigationHeader title={""} onClickReturn={this.handleClickReturn} />
          <BaseNavigationContent>
            <Spinner />
          </BaseNavigationContent>
        </MobileLayout>
      );
    }
    const fertilizers = this.props.fertilizers.filter(
      (fertilizer) => isActiveEntity(fertilizer) || fertilizerReport.fertilizerId === fertilizer.id
    );

    return (
      <MobileLayout>
        <BaseNavigationHeader
          title={pond.name}
          onClickReturn={this.handleClickReturn}
          onClickAction={this.props.openActionModal}
        />
        <BaseNavigationContent>
          <WorkReportFormFertilizer
            fertilizers={fertilizers}
            useMethodTypes={this.props.useMethodTypes}
            pondTypes={this.props.pondTypes}
            fertilizerId={fertilizerReport.fertilizerId}
            amount={fertilizerReport.amount}
            useMethodTypeId={fertilizerReport.useMethodTypeId}
            note={workReport.note}
            imageIds={workReport.imageIds}
            pondTypeId={workReport.pondTypeId}
            onChangeFertilizer={this.handleChangeFertilizer}
            onChangeAmount={this.handleChangeAmount}
            onChangeUseMethodType={this.handleChangeUseMethodType}
            onClickAmountUp={this.handleClickAmountUp}
            onClickAmountDown={this.handleClickAmountDown}
            onChangeNote={this.handleChangeNote}
            onChangeFile={this.handleChangeFile}
            onClickImage={this.handleClickImage}
            onClickImageRemove={this.handleClickImageRemove}
            onChangePondType={isSameFiscalYear(workReport.date, new Date()) ? null : this.handleChangePondType}
            onClickSave={this.props.onClickSave}
            isSaving={isSaving}
            disabledSave={this.props.image.isSaving}
          />
        </BaseNavigationContent>
      </MobileLayout>
    );
  }

  private handleChangeFertilizer = (id: null | number) => {
    this.props.fertilizerReportStateService.changeFertilizer(id);
  };

  private handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const num = Number(e.target.value);
    if (!Number.isNaN(num)) {
      this.props.fertilizerReportStateService.changeAmount(e.target.value);
    }
  };

  private handleChangeUseMethodType = (id: null | number) => {
    this.props.fertilizerReportStateService.changeUseMethodType(id);
  };

  private handleClickAmountUp = () => {
    this.props.fertilizerReportStateService.incrementAmount();
  };

  private handleClickAmountDown = () => {
    this.props.fertilizerReportStateService.decrementAmount();
  };

  private handleChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.props.newOrEditStateService.changeNote(e.target.value);
  };

  private handleClickImage = (imageIds: number[], imageId: number) => {
    this.props.showImageGallery(imageIds, imageId);
  };

  private handleClickImageRemove = (id: number) => {
    this.props.newOrEditStateService.removeImage(id);
  };

  private handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length !== 0) {
      this.props.newOrEditStateService.addImage(e.target.files[0]);
    }
  };

  private handleChangePondType = (id: number | null) => {
    if (id === null) {
      return;
    }
    this.props.newOrEditStateService.changePondType(id);
  };

  private handleClickReturn = () => {
    this.props.history.goBack();
  };
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StateProps & OwnProps => {
  const { pond, pondType, fertilizer, useMethodType } = state.api;
  return {
    ponds: pond.ponds,
    pondTypes: pondType.pondTypes,
    fertilizers: fertilizer.fertilizers,
    useMethodTypes: useMethodType.useMethodTypes.filter(isFertilizerReportGroup),
    navigationState: state.mobile.navigation,
    image: state.api.image,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    newOrEditStateService: new NewOrEditStateService(dispatch),
    navigationService: new NavigationService(dispatch),
    fertilizerReportStateService: new FertilizerReportStateService(dispatch),
    showImageGallery: (imageIds, imageId) =>
      dispatch(imageGalleryStateActions.showImages({ imageIds, initialImageId: imageId })),
  };
};

export const WorkReportFertilizerNewOrEditPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
