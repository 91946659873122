import { actionCreatorFactory } from "typescript-fsa";
import { CarpVarietyType } from "../../../../domain/carpVarietyType";

// Actions
const actionCreator = actionCreatorFactory("ApiCarpVarietyType");

const fetchStart = actionCreator("fetchStart");
const fetchComplete = actionCreator<FetchCompletePayload>("fetchComplete");
interface FetchCompletePayload {
  carpVarietyTypes: CarpVarietyType[];
}
const fetchFail = actionCreator("fetchFail");
const shouldFetch = actionCreator("shouldFetch");

export const apiCarpVarietyTypeActions = {
  fetchStart,
  fetchComplete,
  fetchFail,
  shouldFetch,
};

// Reducers
import { CarpVarietyTypeStateType, FetchHandlers, getInitialState } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const CarpVarietyTypeReducer = reducerWithInitialState<CarpVarietyTypeStateType>(getInitialState())
  .case(fetchStart, (state) => FetchHandlers.start(state))
  .case(fetchComplete, (state, { carpVarietyTypes }) => FetchHandlers.complete(state, carpVarietyTypes))
  .case(fetchFail, (state) => FetchHandlers.fail(state))
  .case(shouldFetch, (state) => FetchHandlers.shouldFetch(state));
