import { actionCreatorFactory } from "typescript-fsa";
import { NewOrEditPondArea } from "../../../../../domain/pondArea";
// Actions
const actionCreator = actionCreatorFactory("MasterPondPondAreaState");

const selectPondArea = actionCreator<SelectPondAreaPayload>("selectPondArea");
interface SelectPondAreaPayload {
  pondArea: NewOrEditPondArea | null;
}

export const saveStart = actionCreator("saveStart");
export const saveSuccess = actionCreator<SaveSuccessPayload>("saveSuccess");
interface SaveSuccessPayload {
  pondArea: NewOrEditPondArea;
}
export const saveFail = actionCreator("saveFail");

export const masterPondPondAreaStateActions = {
  selectPondArea,
  saveStart,
  saveSuccess,
  saveFail,
};

// Reducers
import { getInitialState, InitialHandler, PondAreaStateType, SaveHandler } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const PondAreaStateReducer = reducerWithInitialState<PondAreaStateType>(getInitialState())
  .case(selectPondArea, (state, { pondArea }) => {
    return InitialHandler.selectPondArea(state, pondArea);
  })
  .case(saveStart, (state) => {
    return SaveHandler.saveStart(state);
  })
  .case(saveSuccess, (state, { pondArea }) => {
    return SaveHandler.saveSuccess(state, pondArea);
  })
  .case(saveFail, (state) => {
    return SaveHandler.saveFail(state);
  });
