import * as React from "react";
import { Button as _Button, ButtonGroup as _ButtonGroup, ButtonProps } from "semantic-ui-react";
import styled from "styled-components";
import { DIMEN } from "../../../styles/Dimen";

const BUTTON_WIDTH = DIMEN.X14;

export const SaveButton = styled(_Button).attrs({ type: "submit", color: "blue" })`
  width: ${BUTTON_WIDTH};
`;

export const CancelButton = styled(_Button).attrs({ type: "button" })`
  width: ${BUTTON_WIDTH};
`;

export const IconButton = styled(_Button).attrs({ type: "button", circular: true })``;

export const AddNewButton = styled(_Button).attrs({
  type: "button",
  circular: true,
  icon: "plus",
  basic: true,
  color: "blue",
  size: "tiny",
})``;
export const DeleteButton = styled(_Button).attrs({
  type: "button",
  circular: true,
  icon: "trash",
  basic: true,
  size: "tiny",
})``;

export const Button = styled(_Button).attrs({ type: "button", basic: true, color: "blue" })``;
export const ButtonGroup = styled(_ButtonGroup)`
  &.separate {
    .button + .button {
      margin-left: 0.25em !important;
    }
  }
`;

export const SelectButton = (props: ButtonProps) => {
  return <_Button {...props} type={"button"} color={"blue"} basic={!props.active} />;
};
export const UpButton = styled(_Button).attrs({ type: "button", icon: "angle up" })``;
export const DownButton = styled(_Button).attrs({ type: "button", icon: "angle down" })``;

interface UpDownButtonProps {
  onClickUp: () => void;
  onClickDown: () => void;
}

export const UpDownButton = ({ onClickUp, onClickDown }: UpDownButtonProps) => {
  return (
    <UpDownOuter>
      <UpDownInner>
        <UpButton onClick={onClickUp} />
      </UpDownInner>
      <UpDownInner>
        <DownButton onClick={onClickDown} />
      </UpDownInner>
    </UpDownOuter>
  );
};
export const UpDownOuter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const UpDownInner = styled.div`
  margin-bottom: ${DIMEN.X0_5};
  &:last-child {
    margin-bottom: 0;
  }
`;
