import { PondType } from "../../domain/pondType";
import * as Api from "../../libs/apiClient";

export function translateApiToPondType(apiPondType: Api.PondType): PondType {
  return {
    id: apiPondType.id,
    name: apiPondType.name,
    position: apiPondType.position,
    isDeleted: apiPondType.isDeleted,
  };
}

export function translatePondTypeToApi(pondType: PondType): Api.PondType {
  return {
    id: pondType.id,
    name: pondType.name,
    position: pondType.position,
    isDeleted: pondType.isDeleted,
  };
}
