import { actionCreatorFactory } from "typescript-fsa";
import { PondReport } from "../../../../domain/pondReport";
// Actions
const actionCreator = actionCreatorFactory("ApiPondReport");

const fetchStart = actionCreator("fetchStart");
const fetchComplete = actionCreator<FetchCompletePayload>("fetchComplete");
interface FetchCompletePayload {
  pondReports: PondReport[];
}
const fetchFail = actionCreator("fetchFail");
const shouldFetch = actionCreator("shouldFetch");

// save
const savePondReports = actionCreator<SavePondReportsPayload>("savePondReports");
interface SavePondReportsPayload {
  pondReports: PondReport[];
}
const createPondReport = actionCreator<CreatePondReportPayload>("createPondReport");
interface CreatePondReportPayload {
  pondReport: PondReport;
}
const updatePondReport = actionCreator<UpdatePondReportPayload>("updatePondReport");
interface UpdatePondReportPayload {
  pondReport: PondReport;
}
const deletePondReport = actionCreator<DeletePondReportPayload>("deletePondReport");
interface DeletePondReportPayload {
  pondReport: PondReport;
}

export const apiPondReportActions = {
  fetchStart,
  fetchComplete,
  fetchFail,
  shouldFetch,
  savePondReports,
  createPondReport,
  updatePondReport,
  deletePondReport,
};

// Reducers
import { FetchHandlers, getInitialState, PondReportStateType, SaveHandlers } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const PondReportReducer = reducerWithInitialState<PondReportStateType>(getInitialState())
  .case(fetchStart, (state) => FetchHandlers.start(state))
  .case(fetchComplete, (state, { pondReports }) => FetchHandlers.completeMerge(state, pondReports))
  .case(fetchFail, (state) => FetchHandlers.fail(state))
  .case(shouldFetch, (state) => FetchHandlers.shouldFetch(state))
  .case(savePondReports, (state, { pondReports }) => SaveHandlers.savePondReports(state, pondReports))
  .case(createPondReport, (state, { pondReport }) => SaveHandlers.createPondReport(state, pondReport))
  .case(updatePondReport, (state, { pondReport }) => SaveHandlers.updatePondReport(state, pondReport))
  .case(deletePondReport, (state, { pondReport }) => SaveHandlers.deletePondReport(state, pondReport));
