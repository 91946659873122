import { actionCreatorFactory } from "typescript-fsa";
import { Pond } from "../../../../domain/pond";

// Actions
const actionCreator = actionCreatorFactory("ApiPond");

const fetchStart = actionCreator("fetchStart");
const fetchComplete = actionCreator<FetchCompletePayload>("fetchComplete");
interface FetchCompletePayload {
  ponds: Pond[];
}
const fetchFail = actionCreator("fetchFail");
const shouldFetch = actionCreator("shouldFetch");

// save
const createPond = actionCreator<CreatePondPayload>("createPond");
interface CreatePondPayload {
  pond: Pond;
}
const updatePond = actionCreator<UpdatePondPayload>("updatePond");
interface UpdatePondPayload {
  pond: Pond;
}
const deletePond = actionCreator<DeletePondPayload>("deletePond");
interface DeletePondPayload {
  pond: Pond;
}

const replacePonds = actionCreator<ReplacePondsPayload>("replacePonds");
interface ReplacePondsPayload {
  ponds: Pond[];
}

export const apiPondActions = {
  fetchStart,
  fetchComplete,
  fetchFail,
  shouldFetch,
  createPond,
  updatePond,
  deletePond,
  replacePonds,
};

// Reducers
import { FetchHandlers, getInitialState, PondStateType, SaveHandlers } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const PondReducer = reducerWithInitialState<PondStateType>(getInitialState())
  .case(fetchStart, (state) => FetchHandlers.start(state))
  .case(fetchComplete, (state, { ponds }) => FetchHandlers.complete(state, ponds))
  .case(fetchFail, (state) => FetchHandlers.fail(state))
  .case(shouldFetch, (state) => FetchHandlers.shouldFetch(state))
  .case(createPond, (state, { pond }) => SaveHandlers.createPond(state, pond))
  .case(updatePond, (state, { pond }) => SaveHandlers.updatePond(state, pond))
  .case(deletePond, (state, { pond }) => SaveHandlers.deletePond(state, pond))
  .case(replacePonds, (state, { ponds }) => SaveHandlers.replacePonds(state, ponds));
