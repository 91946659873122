import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import styled from "styled-components";
import { UniqueKey } from "../../domain/entity";
import { ApplicationState } from "../../store/modules";
import { notificationQueueStateActions } from "../../store/modules/notification/queue/ducks";
import { NotificationQueueStateType } from "../../store/modules/notification/queue/reducer";
import { Notification } from "../components/atoms/Notification";
import { DIMEN } from "../styles/Dimen";

interface StateType {
  queueState: NotificationQueueStateType;
}

interface DispatchProps {
  closeNotification: (id: UniqueKey) => void;
}

type WrapperProps = StateType & DispatchProps & RouteComponentProps;

export class Wrapper extends React.Component<WrapperProps> {
  public render() {
    return (
      <Outer>
        {this.props.queueState.messages.map((m) => (
          <Notification key={m.id} message={m.message} disappear={() => this.props.closeNotification(m.id)} />
        ))}
      </Outer>
    );
  }
}

const Outer = styled.div`
  position: fixed;
  bottom: ${DIMEN.X1_5};
  right: ${DIMEN.X1_5};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 1000;
`;

const mapStateToProps = (state: ApplicationState): StateType => {
  return {
    queueState: state.notification.queueState,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    closeNotification: (id: UniqueKey) => dispatch(notificationQueueStateActions.removeMessage({ id })),
  };
};

export const NotificationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapper));
