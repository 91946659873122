import { Dispatch } from "redux";
import { getUniqueKey, UniqueKey } from "../../../domain/entity";
import { copyFertilizerReport, createNewFertilizerReport, NewFertilizerReport } from "../../../domain/fertilizerReport";
import { filterWorkReportByPond, getLatestFertilizerReport } from "../../../domain/workReport";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { ApplicationState } from "../../../store/modules";
import { workReportNewOrEditStateActions } from "../../../store/modules/mobile/workReport/newOrEditState/ducks";
import { FertilizerApiService } from "../../api/fertilizer";
import { UseMethodTypeApiService } from "../../api/useMethodType";
import { WorkReportApiService } from "../../api/workReport";

interface IFertilizerReportStateService extends IApplicationService {
  fetchApi: () => void;
  addFertilizerReport: () => void;
  selectFertilizerReport: (uniqueKey: UniqueKey) => void;
  changeFertilizer: (id: null | number) => void;
  changeAmount: (amount: string) => void;
  changeUseMethodType: (id: null | number) => void;
  incrementAmount: () => void;
  decrementAmount: () => void;
}

export class FertilizerReportStateService implements IFertilizerReportStateService {
  private useMethodTypeApiService: UseMethodTypeApiService;
  private fertilizerApiService: FertilizerApiService;
  private workReportApiService: WorkReportApiService;

  public constructor(private dispatch: Dispatch<any>) {
    this.useMethodTypeApiService = new UseMethodTypeApiService(dispatch);
    this.fertilizerApiService = new FertilizerApiService(dispatch);
    this.workReportApiService = new WorkReportApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public async fetchApi() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedDate, selectedPondId } = state.mobile.navigation;
      if (selectedPondId === null) {
        throw new ApplicationError("不正な操作です。");
      }
      await Promise.all([
        this.fertilizerApiService.fetchUnFetched(),
        this.useMethodTypeApiService.fetchUnFetched(),
        this.workReportApiService.fetchLatestFertilizerReport(selectedDate, selectedPondId),
      ]);
    });
  }

  @catchApplicationError()
  public addFertilizerReport() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedDate, selectedPondId } = state.mobile.navigation;
      const filteredReportsByPond = state.api.workReport.workReports.filter((r) =>
        filterWorkReportByPond(r, selectedPondId)
      );
      const latestFertilizerReport = getLatestFertilizerReport(filteredReportsByPond, selectedDate);
      const newFertilizerReport = latestFertilizerReport
        ? (copyFertilizerReport(latestFertilizerReport, createNewFertilizerReport()) as NewFertilizerReport)
        : createNewFertilizerReport();
      this.dispatch(workReportNewOrEditStateActions.addFertilizerReport({ fertilizerReport: newFertilizerReport }));
      this.dispatch(
        workReportNewOrEditStateActions.selectAnyReport({
          selectedReportKey: "fertilizer",
          selectedUniqueKey: newFertilizerReport.uid,
        })
      );
    });
  }

  @catchApplicationError()
  public selectFertilizerReport(uniqueKey: UniqueKey) {
    this.dispatch(
      workReportNewOrEditStateActions.selectAnyReport({
        selectedReportKey: "fertilizer",
        selectedUniqueKey: uniqueKey,
      })
    );
  }

  @catchApplicationError()
  public changeFertilizer(id: null | number) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedReportKey, selectedUniqueKey } = state.mobile.workReportState.newOrEditStateType;
      if (selectedReportKey !== "fertilizer" || selectedUniqueKey === null) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        workReportNewOrEditStateActions.changeFertilizerReport({
          fertilizerUniqueKey: selectedUniqueKey,
          key: "fertilizerId",
          value: id,
        })
      );
    });
  }

  @catchApplicationError()
  public changeAmount(amount: string) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedReportKey, selectedUniqueKey } = state.mobile.workReportState.newOrEditStateType;
      if (selectedReportKey !== "fertilizer" || selectedUniqueKey === null) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        workReportNewOrEditStateActions.changeFertilizerReport({
          fertilizerUniqueKey: selectedUniqueKey,
          key: "amount",
          value: amount,
        })
      );
    });
  }

  @catchApplicationError()
  public changeUseMethodType(id: null | number) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedReportKey, selectedUniqueKey } = state.mobile.workReportState.newOrEditStateType;
      if (selectedReportKey !== "fertilizer" || selectedUniqueKey === null) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        workReportNewOrEditStateActions.changeFertilizerReport({
          fertilizerUniqueKey: selectedUniqueKey,
          key: "useMethodTypeId",
          value: id,
        })
      );
    });
  }

  @catchApplicationError()
  public incrementAmount() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { workReport, selectedReportKey, selectedUniqueKey } = state.mobile.workReportState.newOrEditStateType;
      if (!workReport || selectedReportKey !== "fertilizer" || selectedUniqueKey === null) {
        throw new ApplicationError("不正な動作です。");
      }
      const fertilizerReport = workReport.fertilizerReports.find((fr) => getUniqueKey(fr) === selectedUniqueKey);
      if (!fertilizerReport) {
        throw new ApplicationError("記録が見つかりませんでした。");
      }
      const amount = Number(fertilizerReport.amount);
      if (Number.isNaN(amount)) {
        return;
      }
      this.dispatch(
        workReportNewOrEditStateActions.changeFertilizerReport({
          fertilizerUniqueKey: selectedUniqueKey,
          key: "amount",
          value: amount + 1 + "",
        })
      );
    });
  }

  @catchApplicationError()
  public decrementAmount() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { workReport, selectedReportKey, selectedUniqueKey } = state.mobile.workReportState.newOrEditStateType;
      if (!workReport || selectedReportKey !== "fertilizer" || selectedUniqueKey === null) {
        throw new ApplicationError("不正な動作です。");
      }
      const fertilizerReport = workReport.fertilizerReports.find((fr) => getUniqueKey(fr) === selectedUniqueKey);
      if (!fertilizerReport) {
        throw new ApplicationError("記録が見つかりませんでした。");
      }
      const amount = Number(fertilizerReport.amount);
      if (Number.isNaN(amount) || amount - 1 < 0) {
        return;
      }
      this.dispatch(
        workReportNewOrEditStateActions.changeFertilizerReport({
          fertilizerUniqueKey: selectedUniqueKey,
          key: "amount",
          value: amount - 1 + "",
        })
      );
    });
  }
}
