import { Dispatch } from "redux";
import { UseMethodType } from "../../../domain/useMethodType";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { UseMethodTypeRepository } from "../../../infrastracture/useMethodType/repository";
import { ApplicationState } from "../../../store/modules";
import { apiUseMethodTypeActions } from "../../../store/modules/api/useMethodType/ducks";

interface IUseMethodTypeApiService extends IApplicationService {
  init: () => void;
  fetchUnFetched: () => void;
}

export class UseMethodTypeApiService implements IUseMethodTypeApiService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError((dispatch) => dispatch(apiUseMethodTypeActions.fetchFail()))
  public async init() {
    this.dispatch(apiUseMethodTypeActions.fetchStart());
    const useMethodTypes: UseMethodType[] = await new UseMethodTypeRepository().getUseMethodTypes();
    this.dispatch(apiUseMethodTypeActions.fetchComplete({ useMethodTypes }));
  }

  @catchApplicationError((dispatch) => dispatch(apiUseMethodTypeActions.fetchFail()))
  public async fetchUnFetched() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      if (getState().api.useMethodType.isFetched) {
        return;
      }
      this.dispatch(apiUseMethodTypeActions.fetchStart());
      const useMethodTypes: UseMethodType[] = await new UseMethodTypeRepository().getUseMethodTypes();
      this.dispatch(apiUseMethodTypeActions.fetchComplete({ useMethodTypes }));
    });
  }
}
