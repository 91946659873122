export interface NavigationStateType {
  isOpenList: boolean;
  selectedDate: Date;
  selectedPondAreaId: null | number;
  selectedPondId: null | number;
}

export const getInitialState = (): NavigationStateType => {
  return { isOpenList: true, selectedDate: new Date(), selectedPondAreaId: null, selectedPondId: null };
};

export const InitialHandlers = {
  openList(state: NavigationStateType): NavigationStateType {
    return { ...state, isOpenList: true };
  },
  closeList(state: NavigationStateType): NavigationStateType {
    return { ...state, isOpenList: false };
  },
};

export const SelectHandlers = {
  selectedDate(state: NavigationStateType, selectedDate: Date): NavigationStateType {
    return { ...state, selectedDate };
  },
  selectPondArea(state: NavigationStateType, selectedPondAreaId: null | number) {
    return { ...state, selectedPondAreaId };
  },
  selectPond(state: NavigationStateType, selectedPondId: null | number) {
    return { ...state, selectedPondId };
  },
};
