import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { FryPondNewOrEditStateService } from "../../../application/report/pondReport/fryPondNewOrEditState";
import { MoveModalStateService } from "../../../application/report/pondReport/moveModalState";
import { NewOrEditStateService } from "../../../application/report/pondReport/newOrEditState";
import { PairReportModalStateService } from "../../../application/report/pondReport/pairReportModalState";
import { ReadApiService } from "../../../application/report/pondReport/readApi";
import { NewOrEditCarpMovingReport } from "../../../domain/carpMovingReport";
import { NewOrEditCarpSelectionReport } from "../../../domain/carpSelectionReport";
import { UniqueKey } from "../../../domain/entity";
import { ParentCarp } from "../../../domain/parentCarp";
import { Pond } from "../../../domain/pond";
import { PondArea } from "../../../domain/pondArea";
import { NewOrEditPondDisinfectionReport } from "../../../domain/pondDisinfectionReport";
import { PondReport } from "../../../domain/pondReport";
import { PondType } from "../../../domain/pondType";
import {
  isCarpQualityScaleType,
  isCarpRatioScaleType,
  isCarpSizeScaleType,
  isMalformationScaleType,
  ScaleType,
} from "../../../domain/scaleType";
import { SearchQuery } from "../../../domain/searchQuery";
import { ApplicationState } from "../../../store/modules";
import { imageGalleryStateActions } from "../../../store/modules/imageGallery/ducks";
import { notificationQueueStateActions } from "../../../store/modules/notification/queue/ducks";
import { NavigationStateType } from "../../../store/modules/report/navigation/reducer";
import { NewOrEditStateType } from "../../../store/modules/report/pondReport/newOrEditState/reducer";
import { FryPondReportForm, NoPondReportForm } from "../../components/organisms/FryPondReportForm";

interface StateProps {
  ponds: Pond[];
  pondAreas: PondArea[];
  pondTypes: PondType[];
  parentCarps: ParentCarp[];
  carpSizeTypes: ScaleType[];
  carpQualityTypes: ScaleType[];
  malformationTypes: ScaleType[];
  carpRatioTypes: ScaleType[];
  pondReports: PondReport[];
  newOrEditState: NewOrEditStateType;
  navigationState: NavigationStateType;
}

interface DispatchProps {
  fryPondNewOrEditStateService: FryPondNewOrEditStateService;
  readApiService: ReadApiService;
  moveModalStateService: MoveModalStateService;
  newOrEditStateService: NewOrEditStateService;
  pairReportModalStateService: PairReportModalStateService;
  showImageGallery: (imageIds: number[], imageId: number) => void;
  notifySaveMessage: () => void;
}

type PondReportEditPageProps = StateProps & DispatchProps & RouteComponentProps;

export class Wrapped extends React.Component<PondReportEditPageProps> {
  public render() {
    const { pondReports, selectedDate, isSaving } = this.props.newOrEditState;
    const { selectedPondId } = this.props.navigationState;
    const selectedPond = this.props.ponds.find((p) => p.id === selectedPondId);
    const selectedPondArea = selectedPond && this.props.pondAreas.find((p) => p.id === selectedPond.pondAreaId);

    if (pondReports === null) {
      return (
        <NoPondReportForm
          selectedPondArea={selectedPondArea as PondArea}
          selectedPond={selectedPond as Pond}
          onClickNew={this.handleClickNewPondReport}
        />
      );
    }

    return (
      <FryPondReportForm
        ponds={this.props.ponds}
        pondTypes={this.props.pondTypes}
        pondReports={pondReports}
        relatedPondReports={this.props.pondReports}
        selectedPond={selectedPond as Pond}
        selectedPondArea={selectedPondArea || null}
        selectedDate={selectedDate}
        parentCarps={this.props.parentCarps}
        carpSizeTypes={this.props.carpSizeTypes}
        carpQualityTypes={this.props.carpQualityTypes}
        malformationTypes={this.props.malformationTypes}
        carpRatioTypes={this.props.carpRatioTypes}
        onChangeParentCarp={this.handleChangeParentCarp}
        onChangeCarpPairing={this.handleChangeCarpPairing}
        onChangeFiscalYear={this.handleChangeFiscalYear}
        onChangePondType={this.handleChangePondType}
        onChangePondDisinfectionReport={this.handleChangePondDisinfectionReport}
        onChangeCarpMovingReport={this.handleChangeCarpMovingReport}
        onChangeCarpSelectionReport={this.handleChangeCarpSelectionReport}
        onClickCarpMovingReport={this.handleClickCarpMovingReport}
        onClickNew={this.handleClickNewPondReport}
        onClickCreateTwice={this.handleClickTwice}
        onClickPrevYear={this.handleClickPrevYear}
        onClickCurrentYear={this.handleClickCurrentYear}
        onClickNextYear={this.handleClickNextYear}
        onClickMove={this.handleClickMove}
        onClickImage={this.handleClickImage}
        onClickSave={this.handleClickSavePondReport}
        onClickCancel={this.handleClickCancelPondReport}
        isSaving={isSaving}
      />
    );
  }

  private handleChangeParentCarp = (reportUniqueKey: UniqueKey) => (parentCarpId: number | null) => {
    if (parentCarpId === null) {
      return;
    }
    this.props.fryPondNewOrEditStateService.changeParentCarp(reportUniqueKey, parentCarpId);
  };

  private handleChangeCarpPairing = (reportUniqueKey: UniqueKey) => (pairingId: number | null) => {
    if (pairingId === null) {
      return;
    }
    this.props.fryPondNewOrEditStateService.changeCarpPairing(reportUniqueKey, pairingId);
  };

  private handleChangeFiscalYear = (reportUniqueKey: UniqueKey) => (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.fryPondNewOrEditStateService.changeFiscalYear(reportUniqueKey, e.target.value);
  };

  private handleChangePondType = (__: UniqueKey) => (pondTypeId: number | null) => {
    if (pondTypeId === null) {
      return;
    }
    this.props.newOrEditStateService.changePondType(pondTypeId);
  };

  private handleChangeCarpMovingReport = (reportUniqueKey: UniqueKey) => (
    movingUniqueKey: UniqueKey,
    key: keyof NewOrEditCarpMovingReport,
    value: any
  ) => {
    this.props.fryPondNewOrEditStateService.changeCarpMovingReport(reportUniqueKey, movingUniqueKey, key, value);
  };

  private handleClickCarpMovingReport = (__: UniqueKey) => (movingUniqueKey: UniqueKey) => {
    this.props.pairReportModalStateService.openModal();
    this.props.pairReportModalStateService.selectPondReport(movingUniqueKey);
    this.props.readApiService.getPondReportsOnClickPairPondReport();
  };

  private handleChangeCarpSelectionReport = (reportUniqueKey: UniqueKey) => (
    selectionUniqueKey: UniqueKey,
    key: keyof NewOrEditCarpSelectionReport,
    value: any
  ) => {
    this.props.fryPondNewOrEditStateService.changeCarpSelectionReport(reportUniqueKey, selectionUniqueKey, key, value);
  };

  private handleChangePondDisinfectionReport = (reportUniqueKey: UniqueKey) => (
    disinfectionUniqueKey: UniqueKey,
    key: keyof NewOrEditPondDisinfectionReport,
    value: any
  ) => {
    this.props.fryPondNewOrEditStateService.changePondDisinfectionReport(
      reportUniqueKey,
      disinfectionUniqueKey,
      key,
      value
    );
  };

  private handleClickPrevYear = async () => {
    this.props.newOrEditStateService.changePrevYear();
    await this.props.readApiService.getPondReports();
    this.props.newOrEditStateService.selectPondReports();
  };
  private handleClickCurrentYear = async () => {
    this.props.newOrEditStateService.changeCurrentYear();
    await this.props.readApiService.getPondReports();
    this.props.newOrEditStateService.selectPondReports();
  };
  private handleClickNextYear = async () => {
    this.props.newOrEditStateService.changeNextYear();
    await this.props.readApiService.getPondReports();
    this.props.newOrEditStateService.selectPondReports();
  };

  private handleClickNewPondReport = () => {
    this.props.newOrEditStateService.createPondReports();
  };

  private handleClickMove = () => {
    this.props.moveModalStateService.openModal();
  };

  private handleClickTwice = () => {
    this.props.fryPondNewOrEditStateService.addPondReport();
  };

  private handleClickImage = (imageIds: number[], imageId: number) => {
    this.props.showImageGallery(imageIds, imageId);
  };

  private handleClickSavePondReport = async () => {
    await this.props.fryPondNewOrEditStateService.savePondReports();
    if (this.props.newOrEditState.isSaved) {
      this.props.notifySaveMessage();
      const returnURL = SearchQuery.getReturnURLFromState(this.props.location.search);
      if (!returnURL) {
        this.props.newOrEditStateService.selectPondReports();
        return;
      }
      const url = SearchQuery.createURLFromState("", this.props.location.search);
      if (url !== "") {
        this.props.history.push(url);
      }
    }
  };

  private handleClickCancelPondReport = () => {
    this.props.newOrEditStateService.selectPondReports();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { scaleTypes } = state.api.scaleType;
  const { newOrEditState } = state.report.pondReport;
  return {
    ponds: state.api.pond.ponds,
    pondAreas: state.api.pondArea.pondAreas,
    pondTypes: state.api.pondType.pondTypes,
    parentCarps: state.api.parentCarp.parentCarps,
    carpSizeTypes: scaleTypes.filter(isCarpSizeScaleType),
    carpQualityTypes: scaleTypes.filter(isCarpQualityScaleType),
    malformationTypes: scaleTypes.filter(isMalformationScaleType),
    carpRatioTypes: scaleTypes.filter(isCarpRatioScaleType),
    pondReports: state.api.pondReport.pondReports,
    newOrEditState,
    navigationState: state.report.navigation,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    fryPondNewOrEditStateService: new FryPondNewOrEditStateService(dispatch),
    readApiService: new ReadApiService(dispatch),
    moveModalStateService: new MoveModalStateService(dispatch),
    newOrEditStateService: new NewOrEditStateService(dispatch),
    pairReportModalStateService: new PairReportModalStateService(dispatch),
    showImageGallery: (imageIds, imageId) =>
      dispatch(imageGalleryStateActions.showImages({ imageIds, initialImageId: imageId })),
    notifySaveMessage: () => dispatch(notificationQueueStateActions.addSaveMessage({ itemName: "選別記録" })),
  };
};

export const FryPondPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
