import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { AuthService } from "../../application/auth";
import { ApplicationState } from "../../store/modules";
import { AuthStateType } from "../../store/modules/auth/reducer";
import { ActiveTabNames, NavigationHeader } from "../components/organisms/NavigationHeader";

interface StateProps {
  auth: AuthStateType;
}

interface DispatchProps {
  authService: AuthService;
}

type WrapperProps = StateProps & DispatchProps & RouteComponentProps;

export class Wrapper extends React.Component<WrapperProps> {
  public render() {
    const { user } = this.props.auth;
    return (
      <NavigationHeader
        activeTabName={this.getActiveTabName()}
        userName={user !== null ? user.name : ""}
        onClickWorkers={this.handleClickWorkers}
        onClickFeeds={this.handleClickFeeds}
        onClickFertilizers={this.handleClickFertilizers}
        onClickPondAreas={this.handleClickPondAreas}
        onClickPonds={this.handleClickPonds}
        onClickHerbicides={this.handleClickHerbicides}
        onClickReportsList={this.handleClickReportsList}
        onClickPondReports={this.handleClickPondReports}
        onClickParentCarps={this.handleClickParentCarps}
        onClickWorkReportsFeed={this.handleClickWorkReportsFeed}
        onClickWorkReportsFertilizer={this.handleClickWorkReportsFertilizer}
        onClickWorkReportsHerbicide={this.handleClickWorkReportsHerbicide}
        onClickWorkReportsNote={this.handleClickWorkReportsNote}
        onClickEnvironmentReport={this.handleClickEnvironmentReport}
        onClickLogout={this.handleClickLogout}
      />
    );
  }

  private getActiveTabName = (): ActiveTabNames => {
    return this.props.location.pathname.slice(1) as ActiveTabNames;
  };

  private handleClickWorkers = () => {
    this.props.history.push("/workers");
  };

  private handleClickFeeds = () => {
    this.props.history.push("/feeds");
  };

  private handleClickFertilizers = () => {
    this.props.history.push("/fertilizers");
  };

  private handleClickPondAreas = () => {
    this.props.history.push("/pond_areas");
  };

  private handleClickPonds = () => {
    this.props.history.push("/ponds");
  };

  private handleClickParentCarps = () => {
    this.props.history.push("/parent_carps");
  };

  private handleClickHerbicides = () => {
    this.props.history.push("/herbicides");
  };

  private handleClickReportsList = () => {
    this.props.history.push("/reports/list");
  };

  private handleClickPondReports = () => {
    this.props.history.push("/pond_reports");
  };

  private handleClickWorkReportsFeed = () => {
    this.props.history.push("/work_reports/feed");
  };

  private handleClickWorkReportsFertilizer = () => {
    this.props.history.push("/work_reports/fertilizer");
  };

  private handleClickWorkReportsHerbicide = () => {
    this.props.history.push("/work_reports/herbicide");
  };

  private handleClickWorkReportsNote = () => {
    this.props.history.push("/work_reports/note");
  };

  private handleClickEnvironmentReport = () => {
    this.props.history.push("/environment_reports");
  };

  private handleClickLogout = async () => {
    await this.props.authService.logout();
    this.props.history.push("/login");
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    authService: new AuthService(dispatch),
  };
};

export const NavigationHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapper));
