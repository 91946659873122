import * as React from "react";
import styled from "styled-components";
import { COLOR } from "../../../styles/Color";
import { DIMEN } from "../../../styles/Dimen";
import { Modal, ModalContent } from "../../atoms/Modal";
import { ReportFilterForm } from "../ReportFilterForm";

interface SortingState {
  isAscDate: boolean;
}

interface FilterState {
  selectedReportTypeIds: number[];
}

interface ReportListFilterModal {
  isOpen: boolean;

  sortingState: SortingState;
  filterState: FilterState;

  onChangeSortDate: (isAscDate: boolean) => void;
  onChangeReportType: (reportTypeId: number) => void;
  onClose: () => void;
  size?: "small" | "mini";
}

export const ReportListFilterModal = (props: ReportListFilterModal) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size={props.size || "small"}>
      <ModalContent className={"no-padding"}>
        <ReportFilterForm {...props} />
      </ModalContent>
      <Actions>
        <OkButton onClick={props.onClose}>OK</OkButton>
      </Actions>
    </Modal>
  );
};

const Actions = styled.div`
  display: flex;
  border-top: 1px solid ${COLOR.Gray["lighter-2"]};
  text-align: center;
  font-weight: 700;
`;
const OkButton = styled.div`
  flex 1 1;
  padding: ${DIMEN.X1_5};
`;
