import { Dispatch } from "redux";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { WorkerRepository } from "../../../infrastracture/worker/repository";
import { ApplicationState } from "../../../store/modules";
import { apiWorkerActions } from "../../../store/modules/api/worker/ducks";

interface IWorkerApiService extends IApplicationService {
  init: () => void;
  fetchUnFetched: () => void;
}

export class WorkerApiService implements IWorkerApiService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError((dispatch) => dispatch(apiWorkerActions.fetchFail()))
  public async init() {
    this.dispatch(apiWorkerActions.fetchStart());
    const workers = await new WorkerRepository().getWorkers();
    this.dispatch(apiWorkerActions.fetchComplete({ workers }));
  }

  @catchApplicationError((dispatch) => dispatch(apiWorkerActions.fetchFail()))
  public async fetchUnFetched() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      if (getState().api.worker.isFetched) {
        return;
      }
      this.dispatch(apiWorkerActions.fetchStart());
      const workers = await new WorkerRepository().getWorkers();
      this.dispatch(apiWorkerActions.fetchComplete({ workers }));
    });
  }
}
