import { Dispatch } from "redux";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { FeedApiService } from "../../api/feed";

interface IReadApiService extends IApplicationService {
  init: () => void;
}

export class ReadApiService implements IReadApiService {
  private FeedApiService: FeedApiService;

  public constructor(private dispatch: Dispatch<any>) {
    this.FeedApiService = new FeedApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public async init() {
    await Promise.all([this.FeedApiService.init()]);
  }
}
