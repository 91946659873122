import { CarpVarietyType } from "../../../../domain/carpVarietyType";
import { sortPosition } from "../../../../domain/entity";
import { FetchLifeCycle } from "../../../lifeCycle/fetchLifeCycle";

export interface CarpVarietyTypeStateType extends FetchLifeCycle {
  carpVarietyTypes: CarpVarietyType[];
}

export const getInitialState = (): CarpVarietyTypeStateType => {
  return { carpVarietyTypes: [], isFetched: false, isFetching: false, isError: null };
};

export const FetchHandlers = {
  start(state: CarpVarietyTypeStateType) {
    return { ...state, isFetched: false, isFetching: true, isError: null };
  },
  complete(state: CarpVarietyTypeStateType, carpVarietyTypes: CarpVarietyType[]) {
    const sortedEntity = carpVarietyTypes.concat().sort(sortPosition);
    return { ...state, carpVarietyTypes: sortedEntity, isFetched: true, isFetching: false };
  },
  fail(state: CarpVarietyTypeStateType, message: string = "") {
    return { ...state, isFetched: false, isFetching: false, isError: { message } };
  },
  shouldFetch(state: CarpVarietyTypeStateType): CarpVarietyTypeStateType {
    return { ...state, isFetched: false };
  },
};
