import { Dispatch } from "redux";
import { getFiscalEndDate, getFiscalStartDate } from "../../../domain/calendar";
import { PondReport } from "../../../domain/pondReport";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { PondReportRepository } from "../../../infrastracture/pondReport/repository";
import { ApplicationState } from "../../../store/modules";
import { apiPondReportActions } from "../../../store/modules/api/pondReport/ducks";

interface IPondReportApiService extends IApplicationService {
  init: (fiscalYear?: number, pondId?: number) => void;
  fetchUnFetched: () => void;
  fetchPondReports: (startDate?: Date, endDate?: Date, pondId?: number) => void;
  fetchRelatedPondReports: (pondReportId: number) => void;
}

export class PondReportApiService implements IPondReportApiService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError((dispatch) => dispatch(apiPondReportActions.fetchFail()))
  public async init(fiscalYear?: number, pondId?: number) {
    this.dispatch(apiPondReportActions.fetchStart());
    const pondReports: PondReport[] = await new PondReportRepository().getPondReports({
      startDate: this.getQueryStartDate(fiscalYear),
      endDate: this.getQueryEndDate(fiscalYear),
      pondId,
    });
    this.dispatch(apiPondReportActions.fetchComplete({ pondReports }));
  }

  @catchApplicationError((dispatch) => dispatch(apiPondReportActions.fetchFail()))
  public async fetchUnFetched(fiscalYear?: number, pondId?: number) {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      if (getState().api.pondReport.isFetched) {
        return;
      }
      this.dispatch(apiPondReportActions.fetchStart());
      const pondReports: PondReport[] = await new PondReportRepository().getPondReports({
        startDate: this.getQueryStartDate(fiscalYear),
        endDate: this.getQueryEndDate(fiscalYear),
        pondId,
      });
      this.dispatch(apiPondReportActions.fetchComplete({ pondReports }));
    });
  }

  @catchApplicationError((dispatch) => dispatch(apiPondReportActions.fetchFail()))
  public async fetchPondReports(startDate?: Date, endDate?: Date, pondId?: number) {
    this.dispatch(apiPondReportActions.fetchStart());
    const pondReports: PondReport[] = await new PondReportRepository().getPondReports({ startDate, endDate, pondId });
    this.dispatch(apiPondReportActions.fetchComplete({ pondReports }));
  }

  @catchApplicationError((dispatch) => dispatch(apiPondReportActions.fetchFail()))
  public async fetchRelatedPondReports(pondReportId: number) {
    this.dispatch(apiPondReportActions.fetchStart());
    const pondReports = await new PondReportRepository().getRelatedPondReports(pondReportId);
    this.dispatch(apiPondReportActions.fetchComplete({ pondReports }));
  }

  private getQueryStartDate(fiscalYear?: number): Date | undefined {
    return fiscalYear ? getFiscalStartDate(fiscalYear) : undefined;
  }

  private getQueryEndDate(fiscalYear?: number): Date | undefined {
    return fiscalYear ? getFiscalEndDate(fiscalYear) : undefined;
  }
}
