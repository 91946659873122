import { Dispatch } from "redux";
import { getFiscalYear } from "../../../domain/calendar";
import { filterByFiscalYear, PondReport } from "../../../domain/pondReport";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { ApplicationState } from "../../../store/modules";
import { ParentCarpApiService } from "../../api/parentCarps";
import { PondApiService } from "../../api/pond";
import { PondAreaApiService } from "../../api/pondArea";
import { PondReportApiService } from "../../api/pondReport";
import { PondTypeApiService } from "../../api/pondType";
import { ScaleTypeApiService } from "../../api/scaleType";

interface IReadApiService extends IApplicationService {
  init: () => void;
  getPondReports: () => void;
  getPondReportOnChangePondOnMoveModal: () => void;
  getPondReportsOnClickPairPondReport: () => void;
}

export class ReadApiService implements IReadApiService {
  private pondApiService: PondApiService;
  private pondAreaApiService: PondAreaApiService;
  private parentCarpApiService: ParentCarpApiService;
  private pondTypeApiService: PondTypeApiService;
  private pondReportApiService: PondReportApiService;
  private scaleTypeApiService: ScaleTypeApiService;

  public constructor(private dispatch: Dispatch<any>) {
    this.pondApiService = new PondApiService(dispatch);
    this.pondAreaApiService = new PondAreaApiService(dispatch);
    this.parentCarpApiService = new ParentCarpApiService(dispatch);
    this.pondTypeApiService = new PondTypeApiService(dispatch);
    this.pondReportApiService = new PondReportApiService(dispatch);
    this.scaleTypeApiService = new ScaleTypeApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public async init() {
    await Promise.all([
      this.pondApiService.init(),
      this.pondAreaApiService.init(),
      this.parentCarpApiService.init(),
      this.pondTypeApiService.fetchUnFetched(),
      this.scaleTypeApiService.fetchUnFetched(),
    ]);
  }

  @catchApplicationError()
  public async getPondReports() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { selectedPondId } = state.report.navigation;
      const pond = state.api.pond.ponds.find((p) => p.id === selectedPondId);
      if (!pond) {
        throw new ApplicationError("池が選択されていません。");
      }
      const selectedDate = state.report.pondReport.newOrEditState.selectedDate;

      const fiscalYear = getFiscalYear(selectedDate);
      await this.pondReportApiService.init(fiscalYear, pond.id);
      const fetchedState = getState();
      const pondReportsByPondId = fetchedState.api.pondReport.pondReports.filter((pr) => pr.pondId === selectedPondId);
      const pondReports = filterByFiscalYear(pondReportsByPondId, fiscalYear) as PondReport[];
      const pondReportIds = pondReports.map((pr) => pr.id);
      for (const id of pondReportIds) {
        await this.pondReportApiService.fetchRelatedPondReports(id);
      }
    });
  }

  @catchApplicationError()
  public async getPondReportOnChangePondOnMoveModal() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { pondId } = state.report.pondReport.moveModalState.modalState;
      if (pondId === null) {
        return;
      }
      const fiscalYear = getFiscalYear(new Date());
      await this.pondReportApiService.init(fiscalYear, pondId);
    });
  }

  @catchApplicationError()
  public async getPondReportsOnClickPairPondReport() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { pondReport } = state.report.pondReport.pairReportModalState;
      if (pondReport) {
        this.pondReportApiService.fetchRelatedPondReports(pondReport.id);
      }
    });
  }
}
