import { CarpPairing, CarpPairingValidator, EditCarpPairing, NewCarpPairing } from "../carpPairing";
import { Entity, generateUid, isNewEntity, NewEntity } from "../entity";
import { getValidation, isEmptyString, isLimitedNumber, isTextLength, Validation, Validator } from "../validation";

interface ParentCarpAttribute extends Record<string, unknown> {
  name: string;
  year: number | null;
  position: number;
  isDeleted: boolean;
}

export interface NewParentCarp extends ParentCarpAttribute, NewEntity {
  carpVarietyTypeId: null | number;
  carpPairings: NewCarpPairing[];
}
export interface EditParentCarp extends ParentCarpAttribute, Entity {
  carpVarietyTypeId: number;
  carpPairings: Array<NewCarpPairing | EditCarpPairing>;
}

export type NewOrEditParentCarp = NewParentCarp | EditParentCarp;
export interface ParentCarp extends ParentCarpAttribute, Entity {
  year: number;
  carpVarietyTypeId: number;
  carpPairings: CarpPairing[];
}

// service
const NEW_ENTITY_PREFIX = "pc-";

export function createNewParentCarp(): NewParentCarp {
  return {
    uid: generateUid(NEW_ENTITY_PREFIX),
    name: "",
    carpVarietyTypeId: null,
    year: null,
    carpPairings: [],
    position: 1,
    isDeleted: false,
  };
}

export function isNewParentCarp(parentCarp: NewOrEditParentCarp): parentCarp is NewParentCarp {
  return isNewEntity(parentCarp);
}

/*** Validator ***/
export class ParentCarpValidator extends Validator<NewOrEditParentCarp> {
  public validate(entity: NewOrEditParentCarp) {
    const nameValidation = validateName(entity);
    if (nameValidation) {
      this.addMessages(nameValidation);
    }
    const carpVarietyTypeValidation = validateCarpVarietyType(entity);
    if (carpVarietyTypeValidation) {
      this.addMessages(carpVarietyTypeValidation);
    }
    const yearValidation = validateYear(entity);
    if (yearValidation) {
      this.addMessages(yearValidation);
    }
    entity.carpPairings.forEach((pair, index) => {
      const pairValidator = new CarpPairingValidator();
      pairValidator.validate(pair);
      if (!pairValidator.isValid()) {
        this.addMessages(getValidation(`${index + 1}番目の組み合わせ: ${pairValidator.getMessages()}`));
      }
    });
  }
}

function validateName({ name }: NewOrEditParentCarp): Validation | null {
  if (isEmptyString(name)) {
    return getValidation("親鯉名は必須です。入力してください。");
  }
  if (!isTextLength(name, 0, 255)) {
    return getValidation("親鯉名は255文字以下で入力してください。");
  }
  return null;
}
function validateCarpVarietyType({ carpVarietyTypeId }: NewOrEditParentCarp): Validation | null {
  if (carpVarietyTypeId === null) {
    return getValidation("品種は必須です。");
  }
  return null;
}
function validateYear({ year }: NewOrEditParentCarp): Validation | null {
  if (year === null) {
    return getValidation("年は必須です。入力してください。");
  }

  if (!isLimitedNumber("" + year, 4)) {
    return getValidation("年は4文字以下で入力してください。");
  }
  return null;
}
