import * as _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NavigationStateService } from "../../../application/report/navigation";
import { FeedReportStateService } from "../../../application/report/workReport/feedReportState";
import { NewOrEditStateService } from "../../../application/report/workReport/newOrEditState";
import { isSameFiscalYear } from "../../../domain/calendar";
import { getUniqueKey, isActiveEntity, isEntity, isNewEntity } from "../../../domain/entity";
import { Feed } from "../../../domain/feed";
import { FeedReasonType } from "../../../domain/feedReasonType";
import { NewOrEditFeedReport } from "../../../domain/feedReport";
import { Pond } from "../../../domain/pond";
import { PondArea } from "../../../domain/pondArea";
import { isAdultPondClass, isFryPondClass } from "../../../domain/pondClass";
import { isFryPondType, PondType } from "../../../domain/pondType";
import { isFeedReportGroup } from "../../../domain/reportGroup";
import { SearchQuery } from "../../../domain/searchQuery";
import { UseMethodType } from "../../../domain/useMethodType";
import { ApplicationState } from "../../../store/modules";
import { ImageStateType } from "../../../store/modules/api/image/reducer";
import { imageGalleryStateActions } from "../../../store/modules/imageGallery/ducks";
import { notificationQueueStateActions } from "../../../store/modules/notification/queue/ducks";
import { NavigationStateType } from "../../../store/modules/report/navigation/reducer";
import { NewOrEditStateType } from "../../../store/modules/report/workReport/newOrEditState/reducer";
import { ConfirmModal } from "../../components/molecules/ConfirmModal";
import { PageHeader } from "../../components/molecules/PageHeader";
import { WorkReportFormFeed } from "../../components/organisms/WorkReportFormFeed";
import { MainLayout } from "../Layout";
import { ReportLayout } from "../Layout/Report";
import { DefaultPage, SpinnerPage } from "./DefaultPage";
import { PondsList } from "./PondsList";

interface StateProps {
  ponds: Pond[];
  pondAreas: PondArea[];
  pondTypes: PondType[];
  feeds: Feed[];
  feedReasonTypes: FeedReasonType[];
  useMethodTypes: UseMethodType[];
  newOrEditState: NewOrEditStateType;
  navigationState: NavigationStateType;
  imageState: ImageStateType;
}

interface DispatchProps {
  feedReportStateService: FeedReportStateService;
  newOrEditStateService: NewOrEditStateService;
  navigationStateService: NavigationStateService;
  showImageGallery: (imageIds: number[], imageId: number) => void;
  notifySaveMessage: () => void;
  notifyDeleteMessage: () => void;
}

type WorkReportFeedPageProps = StateProps & DispatchProps & RouteComponentProps;
interface WorkReportFeedPageState {
  isOpenDeleteConfirm: boolean;
}

export class Wrapped extends React.Component<WorkReportFeedPageProps, WorkReportFeedPageState> {
  constructor(props: WorkReportFeedPageProps) {
    super(props);

    this.state = {
      isOpenDeleteConfirm: false,
    };
  }

  public componentWillMount(): void {
    this.initStateOnMounted();
  }

  public render() {
    return (
      <MainLayout>
        <ReportLayout
          header={<PageHeader title={"給餌を記録"} />}
          list={<PondsList onClickPond={this.handleClickPond} />}
          form={this.renderForm()}
          listColumn={2}
        />
      </MainLayout>
    );
  }

  private async initStateOnMounted() {
    await Promise.all([this.props.navigationStateService.fetchApi(), this.props.feedReportStateService.fetchApi()]);
    if (this.props.navigationState.selectedPondId !== null && this.props.newOrEditState.selectedReportKey !== "feed") {
      await Promise.all([
        this.props.newOrEditStateService.initWorkReport(),
        this.props.feedReportStateService.fetchLatestFeedReport(),
      ]);
      this.props.feedReportStateService.initFeedReport();
    }
  }

  private renderForm() {
    const pond = this.props.ponds.find((p) => p.id === this.props.navigationState.selectedPondId);
    const { newOrEditState, navigationState } = this.props;
    const { selectedPondId, selectedDate } = navigationState;
    if (selectedPondId === null) {
      this.renderEmpty();
    }
    const { workReport, selectedReportKey, selectedUniqueKey, isSaving } = newOrEditState;

    const feedReport =
      selectedReportKey === "feed" &&
      !!workReport &&
      workReport.feedReports.find((fr) => getUniqueKey(fr) === selectedUniqueKey);

    if (!pond) {
      return this.renderEmpty();
    }

    if (!feedReport || workReport === null) {
      return this.renderSpinner();
    }

    const pondType = this.props.pondTypes.find((t) => t.id === workReport.pondTypeId);
    if (!pondType) {
      return this.renderSpinner();
    }

    return (
      <>
        <WorkReportFormFeed
          title={this.getFormTitle()}
          date={selectedDate}
          feeds={this.filterFeeds(feedReport, pondType)}
          feedReasonTypes={this.filterFeedReasons(feedReport, pondType)}
          useMethodTypes={this.props.useMethodTypes}
          pondTypes={this.props.pondTypes}
          feedId={feedReport.feedId}
          feedReasonTypeId={feedReport.feedReasonTypeId}
          count={feedReport.count}
          amount={feedReport.amount}
          useMethodTypeId={feedReport.useMethodTypeId}
          note={workReport.note}
          imageIds={workReport.imageIds}
          pondTypeId={workReport.pondTypeId}
          onChangeDate={this.handleChangeDate}
          onChangeFeed={this.handleChangeFeed}
          onChangeFeedReasonType={this.handleChangeFeedReasonType}
          onChangeCount={this.handleChangeCount}
          onChangeAmount={this.handleChangeAmount}
          onClickAmountUp={_.noop}
          onClickAmountDown={_.noop}
          onChangeNote={this.handleChangeNote}
          onChangeFile={this.handleChangeFile}
          onClickImage={this.handleClickImage}
          onClickImageRemove={this.handleClickImageRemove}
          onChangeFeederUseMethodType={this.handleChangeFeederUseMethodType}
          onChangeBucketUseMethodType={this.handleChangeBucketUseMethodType}
          onChangePondType={isSameFiscalYear(workReport.date, new Date()) ? null : this.handleChangePondType}
          onClickSave={this.handleClickSave}
          onClickCancel={this.handleClickCancel}
          onClickDelete={isNewEntity(feedReport) ? null : this.openDeleteConfirmModal}
          isSaving={isSaving}
          disabledSave={this.props.imageState.isSaving}
        />
        <ConfirmModal
          isOpen={this.state.isOpenDeleteConfirm}
          onClose={this.closeDeleteConfirmModal}
          onClickCancel={this.closeDeleteConfirmModal}
          onClickOk={this.handleClickDelete}
          cancelText={"キャンセル"}
          okText={"削除"}
          confirmMessage={"給餌記録を削除してもよろしいでしょうか？"}
        />
      </>
    );
  }

  private getFormTitle(): string {
    const { ponds, pondAreas, navigationState } = this.props;
    const { selectedPondId, selectedPondAreaId } = navigationState;
    const pond = ponds.find((p) => p.id === selectedPondId);
    const pondArea = pondAreas.find((a) => a.id === selectedPondAreaId);

    return `${pondArea ? pondArea.name : ""} ${pond ? pond.name : ""}`;
  }

  private renderEmpty() {
    return <DefaultPage />;
  }

  private renderSpinner() {
    return <SpinnerPage />;
  }

  private filterFeeds(feedReport: NewOrEditFeedReport, pondType: PondType): Feed[] {
    return this.props.feeds.filter((feed) => {
      if (feedReport.feedId === feed.id) {
        return true;
      }
      if (!isActiveEntity(feed)) {
        return false;
      }
      if (isFryPondType(pondType)) {
        return isFryPondClass(feed);
      }
      return isAdultPondClass(feed);
    });
  }

  private filterFeedReasons(feedReport: NewOrEditFeedReport, pondType: PondType): FeedReasonType[] {
    return this.props.feedReasonTypes.filter((reason) => {
      if (feedReport.feedReasonTypeId === reason.id) {
        return true;
      }
      if (!isActiveEntity(reason)) {
        return false;
      }
      if (isFryPondType(pondType)) {
        return isFryPondClass(reason);
      }
      return isAdultPondClass(reason);
    });
  }

  private handleClickPond = async (id: number) => {
    this.props.navigationStateService.selectPond(id);
    await Promise.all([
      this.props.newOrEditStateService.initWorkReport(),
      this.props.feedReportStateService.fetchLatestFeedReport(),
    ]);
    this.props.feedReportStateService.initFeedReport();
  };

  private handleChangeDate = async (date: Date) => {
    this.props.navigationStateService.selectDate(date);
    await Promise.all([
      this.props.newOrEditStateService.initWorkReport(),
      this.props.feedReportStateService.fetchLatestFeedReport(),
    ]);
    this.props.feedReportStateService.initFeedReport();
  };

  private handleChangeFeed = (id: null | number) => {
    this.props.feedReportStateService.changeFeed(id);
  };

  private handleChangeFeedReasonType = (id: null | number) => {
    this.props.feedReportStateService.changeFeedReasonType(id);
  };

  private handleChangeCount = (count: string) => {
    this.props.feedReportStateService.changeCount(count);
  };

  private handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const num = Number(e.target.value);
    if (!Number.isNaN(num)) {
      this.props.feedReportStateService.changeAmount(e.target.value);
    }
  };

  private handleChangeFeederUseMethodType = () => {
    this.props.feedReportStateService.changeFeederUseMethodType();
  };

  private handleChangeBucketUseMethodType = () => {
    this.props.feedReportStateService.changeBucketUseMethodType();
  };

  private handleChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.props.newOrEditStateService.changeNote(e.target.value);
  };

  private handleClickImage = (imageIds: number[], imageId: number) => {
    this.props.showImageGallery(imageIds, imageId);
  };

  private handleClickImageRemove = (id: number) => {
    this.props.newOrEditStateService.removeImage(id);
  };

  private handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length !== 0) {
      this.props.newOrEditStateService.addImage(e.target.files[0]);
    }
  };

  private handleChangePondType = (id: number | null) => {
    if (id === null) {
      return;
    }
    this.props.newOrEditStateService.changePondType(id);
  };

  private handleClickSave = async () => {
    await this.props.newOrEditStateService.saveWorkReport();
    if (this.props.newOrEditState.isSaved) {
      this.props.notifySaveMessage();
      const returnUrl = SearchQuery.getReturnURLFromState(this.props.location.search);
      if (!returnUrl) {
        return;
      }
      const url = SearchQuery.createURLFromState("", this.props.location.search);
      if (url !== "") {
        this.props.history.push(url);
        return;
      }
    }
  };

  private handleClickCancel = () => {
    const { workReport, selectedUniqueKey } = this.props.newOrEditState;
    const feedReport = workReport && workReport.feedReports.find((fr) => getUniqueKey(fr) === selectedUniqueKey);
    this.props.newOrEditStateService.cancelWorkReport();
    if (feedReport && isEntity(feedReport)) {
      this.props.feedReportStateService.selectFeedReport(getUniqueKey(feedReport));
    } else {
      this.props.feedReportStateService.addFeedReport();
    }
  };

  private handleClickDelete = async () => {
    await this.props.newOrEditStateService.deleteFeedReport();
    this.closeDeleteConfirmModal();
    this.props.notifyDeleteMessage();
    const returnUrl = SearchQuery.getReturnURLFromState(this.props.location.search);
    const url = SearchQuery.createURLFromState("", this.props.location.search);
    if (!returnUrl || url === "") {
      this.props.feedReportStateService.initFeedReport();
      return;
    } else {
      this.props.history.push(url);
    }
  };

  private closeDeleteConfirmModal = () => {
    this.setState({ isOpenDeleteConfirm: false });
  };

  private openDeleteConfirmModal = () => {
    this.setState({ isOpenDeleteConfirm: true });
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { workReport, navigation } = state.report;
  return {
    ponds: state.api.pond.ponds,
    pondAreas: state.api.pondArea.pondAreas,
    pondTypes: state.api.pondType.pondTypes,
    feeds: state.api.feed.feeds,
    feedReasonTypes: state.api.feedReasonType.feedReasonTypes,
    useMethodTypes: state.api.useMethodType.useMethodTypes.filter(isFeedReportGroup),
    newOrEditState: workReport.newOrEditStateType,
    navigationState: navigation,
    imageState: state.api.image,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    feedReportStateService: new FeedReportStateService(dispatch),
    newOrEditStateService: new NewOrEditStateService(dispatch),
    navigationStateService: new NavigationStateService(dispatch),
    showImageGallery: (imageIds, imageId) =>
      dispatch(imageGalleryStateActions.showImages({ imageIds, initialImageId: imageId })),
    notifySaveMessage: () => dispatch(notificationQueueStateActions.addSaveMessage({ itemName: "給餌記録" })),
    notifyDeleteMessage: () => dispatch(notificationQueueStateActions.addDeleteMessage({ itemName: "給餌記録" })),
  };
};

export const WorkReportFeedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
