import { actionCreatorFactory } from "typescript-fsa";
import { Herbicide } from "../../../../domain/herbicide";
// Actions
const actionCreator = actionCreatorFactory("ApiHerbicide");

const fetchStart = actionCreator("fetchStart");
const fetchComplete = actionCreator<FetchCompletePayload>("fetchComplete");
interface FetchCompletePayload {
  herbicides: Herbicide[];
}
const fetchFail = actionCreator("fetchFail");
const shouldFetch = actionCreator("shouldFetch");

// save
const createHerbicide = actionCreator<CreateHerbicidePayload>("createHerbicide");
interface CreateHerbicidePayload {
  herbicide: Herbicide;
}
const updateHerbicide = actionCreator<UpdateHerbicidePayload>("updateHerbicide");
interface UpdateHerbicidePayload {
  herbicide: Herbicide;
}
const deleteHerbicide = actionCreator<DeleteHerbicidePayload>("deleteHerbicide");
interface DeleteHerbicidePayload {
  herbicide: Herbicide;
}

export const apiHerbicideActions = {
  fetchStart,
  fetchComplete,
  fetchFail,
  shouldFetch,
  createHerbicide,
  updateHerbicide,
  deleteHerbicide,
};

// Reducers
import { FetchHandlers, getInitialState, HerbicideStateType, SaveHandlers } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const HerbicideReducer = reducerWithInitialState<HerbicideStateType>(getInitialState())
  .case(fetchStart, (state) => FetchHandlers.start(state))
  .case(fetchComplete, (state, { herbicides }) => FetchHandlers.complete(state, herbicides))
  .case(fetchFail, (state) => FetchHandlers.fail(state))
  .case(createHerbicide, (state, { herbicide }) => SaveHandlers.createHerbicide(state, herbicide))
  .case(updateHerbicide, (state, { herbicide }) => SaveHandlers.updateHerbicide(state, herbicide))
  .case(deleteHerbicide, (state, { herbicide }) => SaveHandlers.deleteHerbicide(state, herbicide));
