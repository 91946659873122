import classNames from "classnames";
import * as React from "react";
import styled from "styled-components";
import { UniqueKey } from "../../../../domain/entity";
import { GroupListItem } from "../../atoms/GroupListItem";

interface ListItem {
  id: UniqueKey;
  name: string;
}

interface MobileListProps {
  itemClassName?: "large";
  items: ListItem[];
  onClickItem: (id: UniqueKey) => void;
}

export const MobileList = ({ itemClassName, items, onClickItem }: MobileListProps) => {
  return (
    <List>
      {items.map((item) => (
        <GroupListItem
          className={classNames("mobile", itemClassName)}
          key={item.id}
          onClick={() => onClickItem(item.id)}>
          {item.name}
        </GroupListItem>
      ))}
    </List>
  );
};

export const MobileListItem = GroupListItem;

const List = styled.div``;
