import { Worker } from "../../../domain/worker";

export interface AuthStateType {
  isLoggingIn: boolean;
  isLoggedIn: boolean;
  accountName: string;
  password: string;
  user: Worker | null;
  errorMessage: string;
}

export const getInitialState = (): AuthStateType => {
  return {
    isLoggingIn: false,
    isLoggedIn: false,
    accountName: "",
    password: "",
    user: null,
    errorMessage: "",
  };
};

export const LoginHandlers = {
  changeAccountName(state: AuthStateType, accountName: string): AuthStateType {
    return { ...state, accountName };
  },
  changePassword(state: AuthStateType, password: string): AuthStateType {
    return { ...state, password };
  },
  loginStart(state: AuthStateType): AuthStateType {
    return { ...state, isLoggingIn: true, errorMessage: "" };
  },
  loginSuccess(state: AuthStateType, user: Worker): AuthStateType {
    return { ...state, isLoggingIn: false, isLoggedIn: true, user, accountName: "", password: "" };
  },
  loginFail(state: AuthStateType, errorMessage: string): AuthStateType {
    return { ...state, isLoggingIn: false, isLoggedIn: false, user: null, errorMessage };
  },
  logout(state: AuthStateType): AuthStateType {
    return { ...state, isLoggedIn: false, user: null, accountName: "", password: "" };
  },
};
