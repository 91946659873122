import { Dispatch } from "redux";
import { PondOwnerType } from "../../../domain/pondOwnerType";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { PondOwnerTypeRepository } from "../../../infrastracture/pondOwnerType/repository";
import { ApplicationState } from "../../../store/modules";
import { apiPondOwnerTypeActions } from "../../../store/modules/api/pondOwnerType/ducks";

interface IPondOwnerTypeApiService extends IApplicationService {
  init: () => void;
  fetchUnFetched: () => void;
}

export class PondOwnerTypeApiService implements IPondOwnerTypeApiService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError((dispatch) => dispatch(apiPondOwnerTypeActions.fetchFail()))
  public async init() {
    this.dispatch(apiPondOwnerTypeActions.fetchStart());
    const pondOwnerTypes: PondOwnerType[] = await new PondOwnerTypeRepository().getPondOwnerTypes();
    this.dispatch(apiPondOwnerTypeActions.fetchComplete({ pondOwnerTypes }));
  }

  @catchApplicationError((dispatch) => dispatch(apiPondOwnerTypeActions.fetchFail()))
  public async fetchUnFetched() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      if (getState().api.pondOwnerType.isFetched) {
        return;
      }
      this.dispatch(apiPondOwnerTypeActions.fetchStart());
      const pondOwnerTypes: PondOwnerType[] = await new PondOwnerTypeRepository().getPondOwnerTypes();
      this.dispatch(apiPondOwnerTypeActions.fetchComplete({ pondOwnerTypes }));
    });
  }
}
