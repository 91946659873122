import * as React from "react";
import { Checkbox as _Checkbox, FormCheckbox as _FormCheckbox } from "semantic-ui-react";

interface CheckboxProps {
  label?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}
export const Checkbox = ({ label, checked, onChange }: CheckboxProps) => {
  return <_Checkbox label={label} checked={checked} onChange={(__, data) => onChange(data.checked || false)} />;
};

export const FormCheckbox = ({ label, checked, onChange }: CheckboxProps) => {
  return <_FormCheckbox label={label} checked={checked} onChange={(__, data) => onChange(data.checked || false)} />;
};
