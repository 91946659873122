import { FeedReasonType } from "../../domain/feedReasonType";
import * as Api from "../../libs/apiClient";

export function translateApiToFeedReasonType(apiFeedReasonType: Api.FeedReasonType): FeedReasonType {
  return {
    id: apiFeedReasonType.id,
    name: apiFeedReasonType.name,
    pondClassId: apiFeedReasonType.pondClassId,
    position: apiFeedReasonType.position,
    isDeleted: apiFeedReasonType.isDeleted,
  };
}

export function translateCarpVarietyToApi(feedReasonType: FeedReasonType): Api.FeedReasonType {
  return {
    id: feedReasonType.id,
    name: feedReasonType.name,
    pondClassId: feedReasonType.pondClassId,
    position: feedReasonType.position,
    isDeleted: feedReasonType.isDeleted,
  };
}
