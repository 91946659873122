import * as _ from "lodash";
import { default as moment } from "moment";
import { ApplicationError } from "../../handler/errors/applicationError";
import { CarpVarietyType } from "../carpVarietyType";
import { Entity, generateUid, isNewEntity, NewEntity } from "../entity";
import { isEmptyScaleType, ScaleType } from "../scaleType";
import {
  getValidation,
  isEmptyString,
  isLessThanMaxNumber,
  isLimitedNumber,
  Validation,
  Validator,
} from "../validation";

interface CarpSelectionReportAttribute extends Record<string, unknown> {
  carpSizeTypeId: null | number;
  carpQualityTypeId: null | number;
  malformationTypeId: null | number;
  carpRatioTypeId: null | number;
  carpScore: string;
  selectionNumber: number;
  date: Date | null;
  amount: string;
  note: string;
}

export interface NewCarpSelectionReport extends CarpSelectionReportAttribute, NewEntity {}
export interface EditCarpSelectionReport extends CarpSelectionReportAttribute, Entity {
  date: Date;
}

export type NewOrEditCarpSelectionReport = NewCarpSelectionReport | EditCarpSelectionReport;
export type CarpSelectionReport = EditCarpSelectionReport;

export enum SelectionNumberOfSelectionReport {
  ReleaseCarp,
  OneSelection,
  TwoSelection,
  ThreeSelection,
  FourSelection,
  TakeoutCarp,
}

// service
const NEW_ENTITY_PREFIX = "csr-";

export function createNewCarpSelectionReport(selectionNumber: number): NewCarpSelectionReport {
  return {
    uid: generateUid(NEW_ENTITY_PREFIX),
    carpSizeTypeId: null,
    carpQualityTypeId: null,
    malformationTypeId: null,
    carpRatioTypeId: null,
    carpScore: "",
    selectionNumber,
    date: null,
    amount: "",
    note: "",
  };
}

export function isNewCarpSelectionReport(
  carpSelectionReport: NewOrEditCarpSelectionReport
): carpSelectionReport is NewCarpSelectionReport {
  return isNewEntity(carpSelectionReport);
}

export function isEmptyCarpSelectionReport(report: NewOrEditCarpSelectionReport): boolean {
  if (report.carpSizeTypeId !== null) {
    return false;
  }
  if (report.carpQualityTypeId !== null) {
    return false;
  }
  if (report.malformationTypeId !== null) {
    return false;
  }
  if (report.carpRatioTypeId !== null) {
    return false;
  }

  if (report.carpScore !== "") {
    return false;
  }

  if (report.date !== null) {
    return false;
  }
  if (report.amount !== "") {
    return false;
  }
  if (report.note !== "") {
    return false;
  }
  return true;
}

export function removeEmptyCarpSelectionReport(
  reports: NewOrEditCarpSelectionReport[]
): NewOrEditCarpSelectionReport[] {
  const takeoutCarpReport = reports.find((r) => isTakeoutCarpSelectionReport(r) && !isEmptyCarpSelectionReport(r));
  if (takeoutCarpReport) {
    const removedReports = reports.filter((r) => !isTakeoutCarpSelectionReport(r));
    const lastIndexWithoutTakeoutReport = _.findLastIndex(removedReports, (r) => !isEmptyCarpSelectionReport(r));
    return removedReports.slice(0, lastIndexWithoutTakeoutReport + 1).concat(takeoutCarpReport);
  }
  const lastIndex = _.findLastIndex(reports, (r) => !isEmptyCarpSelectionReport(r));
  return reports.slice(0, lastIndex + 1);
}

export const FRY_SELECTION_REPORTS_MAX_COUNT = 6;
export function fillEmptyFryCarpSelectionReport(
  reports: NewOrEditCarpSelectionReport[]
): NewOrEditCarpSelectionReport[] {
  const takeoutCarpReport = reports.find(isTakeoutCarpSelectionReport);
  if (takeoutCarpReport) {
    const removedReports = reports.filter((r) => !isTakeoutCarpSelectionReport(r));
    const diffWithoutTakeoutReport = FRY_SELECTION_REPORTS_MAX_COUNT - removedReports.length;
    const fillReportsWithoutTakeoutReports = _.rangeRight(1, diffWithoutTakeoutReport, 1).map((num) =>
      createNewCarpSelectionReport(FRY_SELECTION_REPORTS_MAX_COUNT - 1 - num)
    );
    return removedReports.concat(fillReportsWithoutTakeoutReports).concat(takeoutCarpReport);
  }
  const diff = FRY_SELECTION_REPORTS_MAX_COUNT - reports.length;
  const fillReports = _.rangeRight(0, diff, 1).map((num) =>
    createNewCarpSelectionReport(FRY_SELECTION_REPORTS_MAX_COUNT - 1 - num)
  );
  return reports.concat(fillReports);
}

export type CarpSelectionMessageState = "warning" | "alert" | null;

/**
 * 選別前のアラート状態を返す.
 * @param reports
 * @param selectionNumber
 * @param date
 * @param carpVarietyType
 * @param isSelectionComplete
 */
export function getCarpSelectionMessageState(
  reports: NewOrEditCarpSelectionReport[],
  selectionNumber: number,
  date: Date,
  carpVarietyType: CarpVarietyType,
  isSelectionComplete: boolean
): CarpSelectionMessageState {
  if (isSelectionComplete) {
    return null;
  }
  if (selectionNumber === SelectionNumberOfSelectionReport.OneSelection) {
    return getCarpSelectionMessageStateFirst(reports, selectionNumber, date, carpVarietyType);
  }
  return getCarpSelectionMessageStateSecond(reports, selectionNumber, date, carpVarietyType);
}

/**
 * 放鯉後から第1選別前のアラート有無を返す.
 * @param reports
 * @param selectionNumber
 * @param date
 * @param carpVarietyType
 */
function getCarpSelectionMessageStateFirst(
  reports: NewOrEditCarpSelectionReport[],
  selectionNumber: number,
  date: Date,
  carpVarietyType: CarpVarietyType
): CarpSelectionMessageState {
  const report = reports.find((r) => r.selectionNumber === selectionNumber);
  if (!report || report.date) {
    return null;
  }
  const prevReport = reports.find((r) => r.selectionNumber === selectionNumber - 1);
  if (!prevReport || prevReport.date === null || isEmptyCarpSelectionReport(prevReport)) {
    return null;
  }
  const momentDate = moment(date);
  const diff = Math.abs(momentDate.diff(moment(prevReport.date), "days"));
  if (carpVarietyType.warningDays1 === null || carpVarietyType.alertDays1 === null) {
    return null;
  }
  if (carpVarietyType.alertDays1 <= diff) {
    return "alert";
  }
  if (carpVarietyType.warningDays1 <= diff) {
    return "warning";
  }
  return null;
}

/**
 * 第1選別以降のアラート有無を返す.
 */
function getCarpSelectionMessageStateSecond(
  reports: NewOrEditCarpSelectionReport[],
  selectionNumber: number,
  date: Date,
  carpVarietyType: CarpVarietyType
): CarpSelectionMessageState {
  const report = reports.find((r) => r.selectionNumber === selectionNumber);
  if (!report || report.date) {
    return null;
  }
  const prevReport = reports.find((r) => r.selectionNumber === selectionNumber - 1);
  if (!prevReport || prevReport.date === null || isEmptyCarpSelectionReport(prevReport)) {
    return null;
  }
  const momentDate = moment(date);
  const diff = Math.abs(momentDate.diff(moment(prevReport.date), "days"));
  if (carpVarietyType.warningDays2 === null || carpVarietyType.alertDays2 === null) {
    return null;
  }
  if (carpVarietyType.alertDays2 <= diff) {
    return "alert";
  }
  if (carpVarietyType.warningDays2 <= diff) {
    return "warning";
  }
  return null;
}

export function getSelectionName(selectionNumber: number): string {
  if (selectionNumber === SelectionNumberOfSelectionReport.ReleaseCarp) {
    return "放鯉";
  }
  if (selectionNumber === SelectionNumberOfSelectionReport.TakeoutCarp) {
    return "池揚げ";
  }
  return `第${selectionNumber}選別`;
}

export function isReleaseCarpSelectionReport(selectionReport: NewOrEditCarpSelectionReport): boolean {
  return isReleaseCarpSelectionNumber(selectionReport.selectionNumber);
}

export function isReleaseCarpSelectionNumber(selectionNumber: number): boolean {
  return selectionNumber === SelectionNumberOfSelectionReport.ReleaseCarp;
}

export function isTakeoutCarpSelectionReport(selectionReport: NewOrEditCarpSelectionReport): boolean {
  return selectionReport.selectionNumber === SelectionNumberOfSelectionReport.TakeoutCarp;
}
export function isTakeoutCarpSelectionNumber(selectionNumber: number): boolean {
  return selectionNumber === SelectionNumberOfSelectionReport.TakeoutCarp;
}

export function overwriteSelectionReportBeforeSaving(
  selectionReport: NewOrEditCarpSelectionReport,
  scaleTypes: ScaleType[]
): NewOrEditCarpSelectionReport {
  if (isReleaseCarpSelectionReport(selectionReport)) {
    const emptyScaleType = scaleTypes.find(isEmptyScaleType);
    const emptyScaleId = emptyScaleType ? emptyScaleType.id : null;
    return {
      ...selectionReport,
      carpSizeTypeId: emptyScaleId,
      carpQualityTypeId: emptyScaleId,
      malformationTypeId: emptyScaleId,
      carpRatioTypeId: emptyScaleId,
      carpScore: "",
    };
  }
  return selectionReport;
}

/*** Validator ***/
export class CarpSelectionReportValidator extends Validator<NewOrEditCarpSelectionReport> {
  private selectionNumber: number | null = null;

  public getMessages(): string {
    return `${this.getSelectionNumberString()} : ${super.getMessages()}`;
  }

  public validate(entity: NewOrEditCarpSelectionReport) {
    this.selectionNumber = entity.selectionNumber;

    const dateValidation = validateDate(entity);
    if (dateValidation) {
      this.addMessages(dateValidation);
    }
    const amountValidation = validateAmount(entity);
    if (amountValidation) {
      this.addMessages(amountValidation);
    }
    if (!isReleaseCarpSelectionReport(entity)) {
      const carpSizeValidation = validateCarpSize(entity);
      if (carpSizeValidation) {
        this.addMessages(carpSizeValidation);
      }
      const carpQualityValidation = validateCarpQuality(entity);
      if (carpQualityValidation) {
        this.addMessages(carpQualityValidation);
      }
      const malformationValidation = validateMalformation(entity);
      if (malformationValidation) {
        this.addMessages(malformationValidation);
      }
      const carpRatioValidation = validateCarpRatio(entity);
      if (carpRatioValidation) {
        this.addMessages(carpRatioValidation);
      }
      const carpScoreValidation = validateCarpScore(entity);
      if (carpScoreValidation) {
        this.addMessages(carpScoreValidation);
      }
    }
  }

  private getSelectionNumberString(): string {
    if (this.selectionNumber === null) {
      throw new ApplicationError("不正な操作です。");
    }
    return getSelectionName(this.selectionNumber);
  }
}

function validateDate({ date }: NewOrEditCarpSelectionReport): Validation | null {
  if (date == null) {
    return getValidation("日付は必須です。入力してください。");
  }
  return null;
}

function validateAmount({ amount }: NewOrEditCarpSelectionReport): Validation | null {
  if (isEmptyString(amount)) {
    return getValidation("匹数は必須です。入力してください。");
  }
  if (!isLimitedNumber(amount, 9, 4)) {
    return getValidation("匹数は整数部9桁、小数部4桁で入力してください。");
  }
  return null;
}
function validateCarpSize({ carpSizeTypeId }: NewOrEditCarpSelectionReport): Validation | null {
  if (carpSizeTypeId === null) {
    return getValidation("サイズは必須です。");
  }
  return null;
}
function validateCarpQuality({ carpQualityTypeId }: NewOrEditCarpSelectionReport): Validation | null {
  if (carpQualityTypeId === null) {
    return getValidation("評価は必須です。");
  }
  return null;
}
function validateMalformation({ malformationTypeId }: NewOrEditCarpSelectionReport): Validation | null {
  if (malformationTypeId === null) {
    return getValidation("奇形は必須です。");
  }
  return null;
}
function validateCarpRatio({ carpRatioTypeId }: NewOrEditCarpSelectionReport): Validation | null {
  if (carpRatioTypeId === null) {
    return getValidation("数は必須です。");
  }
  return null;
}

function validateCarpScore({ carpScore }: NewOrEditCarpSelectionReport): Validation | null {
  if (carpScore === "") {
    return null;
  }
  if (isLimitedNumber(carpScore, 3, 0) && isLessThanMaxNumber(carpScore, 100, true)) {
    return null;
  }
  return getValidation("点数は0から100の整数で入力してください。");
}
