import { PondReport } from "../../../../../domain/pondReport";

export interface PairReportModalStateType {
  pondReport: PondReport | null;
  isOpen: boolean;
}

export const getInitialState = (): PairReportModalStateType => {
  return {
    pondReport: null,
    isOpen: false,
  };
};
export const InitialHandler = {
  openModal(): PairReportModalStateType {
    return { ...getInitialState(), isOpen: true };
  },
  closeModal(): PairReportModalStateType {
    return { ...getInitialState(), isOpen: false };
  },
};

export const ChangeHandler = {
  selectPondReport(state: PairReportModalStateType, pondReport: PondReport): PairReportModalStateType {
    return { ...state, pondReport };
  },
};
