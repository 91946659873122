import * as React from "react";
import styled from "styled-components";
import { IconButton } from "../Button";

interface FormFileInputProps {
  label?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  disabled?: boolean;
  icon?: "camera" | "image";
}

const Outer = styled.div``;

export class FormFileInput extends React.Component<FormFileInputProps> {
  private input: HTMLInputElement | null = null;

  public render() {
    return (
      <Outer>
        <input
          ref={(node) => (this.input = node)}
          type={"file"}
          onChange={this.handleOnChange}
          style={{ display: "none" }}
        />
        <IconButton icon={this.props.icon || "camera"} onClick={this.handleClickButton} />
      </Outer>
    );
  }

  private handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.input) {
      this.props.onChange(e);
      this.input.value = "";
    }
  };

  private handleClickButton = () => {
    if (this.input) {
      this.input.click();
    }
  };
}
