import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { ApplicationState } from "../../store/modules";
import { notificationAlertStateActions } from "../../store/modules/notification/alert/ducks";
import { NotificationModal } from "../components/molecules/NotificationModal";

interface StateType {
  isOpen: boolean;
  errorMessage: string;
}

interface DispatchProps {
  closeModal: () => void;
}

type WrapperProps = StateType & DispatchProps & RouteComponentProps;

export class Wrapper extends React.Component<WrapperProps> {
  public render() {
    const { isOpen, errorMessage, closeModal } = this.props;
    return (
      <NotificationModal
        isOpen={isOpen}
        onClose={closeModal}
        onClickOk={closeModal}
        okText={"OK"}
        message={errorMessage}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateType => {
  return {
    isOpen: state.notification.alertState.showErrorMessage,
    errorMessage: state.notification.alertState.errorMessage,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    closeModal: () => dispatch(notificationAlertStateActions.hideErrorMessage()),
  };
};

export const AlertModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapper));
