import { Dispatch } from "redux";
import { isEntity } from "../../../domain/entity";
import { PondValidator } from "../../../domain/pond";
import { getCompanyPondOwnerType } from "../../../domain/pondOwnerType";
import { isFryPondType } from "../../../domain/pondType";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { PondRepository } from "../../../infrastracture/pond/repository";
import { ApplicationState } from "../../../store/modules";
import { apiPondActions } from "../../../store/modules/api/pond/ducks";
import { masterPondNewOrEditStateActions } from "../../../store/modules/master/pond/newOrEditState/ducks";
import { notificationAlertStateActions } from "../../../store/modules/notification/alert/ducks";
import { notificationQueueStateActions } from "../../../store/modules/notification/queue/ducks";
import { PondAreaApiService } from "../../api/pondArea";

interface IEditStateService extends IApplicationService {
  selectPond: (pondId: number) => void;
  changeName: (name: string) => void;
  changePondArea: (pondAreaId: null | number) => void;
  changePosition: (position: number | null) => void;
  changePondType: (typeId: null | number) => void;
  changePondOwnerTypes: (ownerTypeIds: number[]) => void;
  changeWorkers: (workerIds: number[]) => void;
  changeIsUnused: (checked: boolean) => void;
  cancelPond: () => void;
  savePond: () => void;
  deletePond: () => void;
}

export class EditStateService implements IEditStateService {
  private readonly pondAreaApiService: PondAreaApiService;

  public constructor(private dispatch: Dispatch<any>) {
    this.pondAreaApiService = new PondAreaApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public selectPond(pondId: number) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const ponds = getState().api.pond.ponds;
      const pond = ponds.find((p) => p.id === pondId);
      if (!pond) {
        throw new ApplicationError("野池が見つかりませんでした。");
      }
      this.dispatch(masterPondNewOrEditStateActions.selectPond({ pond }));
    });
  }

  @catchApplicationError()
  public changePondArea(pondAreaId: null | number) {
    this.dispatch(masterPondNewOrEditStateActions.changePond({ key: "pondAreaId", value: pondAreaId }));
  }

  @catchApplicationError()
  public changeName(name: string) {
    this.dispatch(masterPondNewOrEditStateActions.changePond({ key: "name", value: name }));
  }

  @catchApplicationError()
  public changePosition(position: number | null) {
    this.dispatch(masterPondNewOrEditStateActions.changePond({ key: "position", value: position }));
  }

  @catchApplicationError()
  public changePondType(typeId: null | number) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const pondType = state.api.pondType.pondTypes.find((t) => t.id === typeId);
      this.dispatch(masterPondNewOrEditStateActions.changePond({ key: "pondTypeId", value: typeId }));
      if (!!pondType && isFryPondType(pondType)) {
        const companyPondOwnerType = getCompanyPondOwnerType(state.api.pondOwnerType.pondOwnerTypes);
        const pondOwnerTypeIds = companyPondOwnerType ? [companyPondOwnerType.id] : [];
        this.dispatch(masterPondNewOrEditStateActions.changePond({ key: "pondOwnerTypeIds", value: pondOwnerTypeIds }));
      }
    });
  }

  @catchApplicationError()
  public changePondOwnerTypes(ownerTypeIds: number[]) {
    this.dispatch(masterPondNewOrEditStateActions.changePond({ key: "pondOwnerTypeIds", value: ownerTypeIds }));
  }

  @catchApplicationError()
  public changeWorkers(workerIds: number[]) {
    this.dispatch(masterPondNewOrEditStateActions.changePond({ key: "userIds", value: workerIds }));
  }

  @catchApplicationError()
  public changeIsUnused(checked: boolean) {
    this.dispatch(masterPondNewOrEditStateActions.changePond({ key: "isUnused", value: checked }));
  }

  @catchApplicationError()
  public cancelPond() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const { api, master } = getState();
      const editPond = master.pond.newOrEditState.pond;
      if (editPond === null || !isEntity(editPond)) {
        return;
      }
      const pond = api.pond.ponds.find((p) => p.id === editPond.id);
      if (!pond) {
        throw new ApplicationError("野池が見つかりませんでした。");
      }
      this.dispatch(masterPondNewOrEditStateActions.cancelPond({ pond }));
    });
  }

  @catchApplicationError((dispatch) => dispatch(masterPondNewOrEditStateActions.saveFail()))
  public async savePond() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(masterPondNewOrEditStateActions.saveStart());
      const { newOrEditState } = getState().master.pond;
      const editPond = newOrEditState.pond;
      if (editPond === null || !isEntity(editPond)) {
        throw new ApplicationError("野池が見つかりませんでした。");
      }
      const validator = new PondValidator();
      validator.validate(editPond);
      if (!validator.isValid()) {
        this.dispatch(notificationAlertStateActions.showErrorMessage({ errorMessage: validator.getMessages() }));
        this.dispatch(masterPondNewOrEditStateActions.saveFail());
        return;
      }
      const savedPond = await new PondRepository().putPond(editPond);
      this.dispatch(masterPondNewOrEditStateActions.saveSuccess({ pond: savedPond }));
      this.dispatch(apiPondActions.updatePond({ pond: savedPond }));
      this.pondAreaApiService.updateByPond(savedPond);
      this.dispatch(notificationQueueStateActions.addSaveMessage({ itemName: "野池" }));
    });
  }

  @catchApplicationError((dispatch) => dispatch(masterPondNewOrEditStateActions.saveFail()))
  public async deletePond() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(masterPondNewOrEditStateActions.saveStart());
      const { newOrEditState } = getState().master.pond;
      const editPond = newOrEditState.pond;
      if (editPond === null || !isEntity(editPond)) {
        throw new ApplicationError("野池が見つかりませんでした。");
      }
      const savedPond = await new PondRepository().deletePond(editPond);
      this.dispatch(masterPondNewOrEditStateActions.saveSuccess({ pond: null }));
      this.dispatch(apiPondActions.deletePond({ pond: savedPond }));
      this.pondAreaApiService.updateByPond(savedPond);
      this.dispatch(notificationQueueStateActions.addDeleteMessage({ itemName: "野池" }));
    });
  }
}
