import * as React from "react";
import { Herbicide } from "../../../../domain/herbicide";
import { PondType } from "../../../../domain/pondType";
import { UseMethodType } from "../../../../domain/useMethodType";
import { Form } from "../../atoms/Form/Form";
import { Spinner } from "../../atoms/Spinner";
import { ReportFormButton } from "../../molecules/ReportFormButton";
import { WorkReportFormDateBody } from "../../molecules/WorkReportFormDate";
import { WorkReportFormHerbicideBody } from "../../molecules/WorkReportFormHerbicide";

interface WorkReportFormHerbicideProps {
  title: string;
  date: Date;
  herbicides: Herbicide[];
  useMethodTypes: UseMethodType[];
  pondTypes: PondType[];
  herbicideId: null | number;
  amount: string;
  useMethodTypeId: null | number;
  note: string;
  imageIds: number[];
  pondTypeId: number;
  onChangeDate: (date: Date) => void;
  onChangeHerbicide: (id: null | number) => void;
  onChangeAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeUseMethodType: (id: number | null) => void;
  onClickAmountUp: () => void;
  onClickAmountDown: () => void;
  onChangeNote: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickImage: (imageIds: number[], imageId: number) => void;
  onClickImageRemove: (id: number) => void;
  onChangePondType: ((id: number | null) => void) | null;
  onClickSave: () => void;
  onClickCancel: () => void;
  onClickAdd: (() => void) | null;
  onClickDelete: (() => void) | null;
  isSaving: boolean;
  disabledSave: boolean;
}
export const WorkReportFormHerbicide = (props: WorkReportFormHerbicideProps) => {
  return (
    <Form>
      {props.isSaving && <Spinner />}
      <WorkReportFormDateBody
        title={props.title}
        date={props.date}
        onChangeDate={props.onChangeDate}
        onClickAdd={props.onClickAdd}
        onClickDelete={props.onClickDelete}
      />
      <WorkReportFormHerbicideBody {...props} />
      <ReportFormButton
        onClickSave={props.onClickSave}
        onClickCancel={props.onClickCancel}
        disabledSave={props.disabledSave}
      />
    </Form>
  );
};
