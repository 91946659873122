import { Header } from "semantic-ui-react";
import styled from "styled-components";
import { DIMEN } from "../../../styles/Dimen";

export const EmptyMessage = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  padding: ${DIMEN.X2} ${DIMEN.X1};
`;

export const ListNotSelectedMessage = styled(Header).attrs({ as: "h3" })`
  padding: 0.25em 0.5em;
`;
