import { ReportGroup } from "../../../../domain/reportGroup";

type DetailTabState = "detail" | "graph";
export type GraphPeriodType = "week" | "month" | "three_months" | "half_year";

interface SortingState {
  isAscDate: boolean;
}

interface FilterState {
  selectedReportTypeIds: number[];
}

export interface ReportListStateType extends Record<string, unknown> {
  displayDate: Date;
  selectedDate: Date;
  reportSortingState: SortingState;
  reportFilterState: FilterState;
  detailTab: DetailTabState;
  graphPeriodType: GraphPeriodType;
  isOpenDetailActionModal: boolean;
}

export const getInitialState = (): ReportListStateType => {
  return {
    displayDate: new Date(),
    selectedDate: new Date(),
    reportSortingState: {
      isAscDate: true,
    },
    reportFilterState: {
      selectedReportTypeIds: [
        ReportGroup.Feed,
        ReportGroup.Fertilizer,
        ReportGroup.Herbicide,
        ReportGroup.Note,
        ReportGroup.EnvironmentReport,
        ReportGroup.PondReport,
      ],
    },
    detailTab: "detail",
    graphPeriodType: "week",
    isOpenDetailActionModal: false,
  };
};

export const InitialHandler = {
  initialState(state: ReportListStateType): ReportListStateType {
    return { ...state, ...getInitialState() };
  },
};

export const ModalHandler = {
  openDetailActionModal(state: ReportListStateType): ReportListStateType {
    return { ...state, isOpenDetailActionModal: true };
  },
  closeDetailActionModal(state: ReportListStateType): ReportListStateType {
    return { ...state, isOpenDetailActionModal: false };
  },
};

export const SelectHandlers = {
  changeDisplayDate(state: ReportListStateType, displayDate: Date): ReportListStateType {
    return { ...state, displayDate };
  },
  changeSelectedDate(state: ReportListStateType, selectedDate: Date): ReportListStateType {
    return { ...state, selectedDate };
  },
  changeDetailTab(state: ReportListStateType, detailTab: DetailTabState): ReportListStateType {
    return { ...state, detailTab };
  },
  changeGraphPeriodType(state: ReportListStateType, periodType: GraphPeriodType): ReportListStateType {
    return { ...state, graphPeriodType: periodType };
  },
};

export const SortHandlers = {
  changeSortDate(state: ReportListStateType, isAscDate: boolean): ReportListStateType {
    return { ...state, reportSortingState: { ...state.reportSortingState, isAscDate } };
  },
};

export const FilterHandlers = {
  addSelectedReportType(state: ReportListStateType, reportTypeId: number): ReportListStateType {
    const selectedReportTypeIds = state.reportFilterState.selectedReportTypeIds.concat(reportTypeId);
    return { ...state, reportFilterState: { ...state.reportFilterState, selectedReportTypeIds } };
  },
  removeSelectedReportType(state: ReportListStateType, reportTypeId: number): ReportListStateType {
    const selectedReportTypeIds = state.reportFilterState.selectedReportTypeIds.filter((id) => id !== reportTypeId);
    return { ...state, reportFilterState: { ...state.reportFilterState, selectedReportTypeIds } };
  },
};
