import * as _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { AuthService } from "../../../application/auth";
import { ApplicationState } from "../../../store/modules";
import { AuthStateType } from "../../../store/modules/auth/reducer";
import { LoginForm } from "../../components/molecules/LoginForm";
import { BaseNavigationContent, BaseNavigationHeader } from "../../components/molecules/NavigationHeader";

interface StateProps {
  authState: AuthStateType;
}

interface DispatchProps {
  authService: AuthService;
}

type LoginPageProps = StateProps & DispatchProps & RouteComponentProps;

export class Wrapped extends React.Component<LoginPageProps> {
  public componentWillMount() {
    this.props.authService.refresh(_.noop).then(() => {
      if (this.props.authState.isLoggedIn) {
        this.props.history.push("/");
      }
    });
  }

  public render() {
    const { accountName, password, isLoggingIn } = this.props.authState;
    return (
      <>
        <BaseNavigationHeader title={"作業記録"} />
        <BaseNavigationContent>
          <LoginForm
            accountName={accountName}
            password={password}
            onChangeAccountName={this.handleChangeAccountName}
            onChangePassword={this.handleChangePassword}
            onClickLogin={this.handleLogin}
            isLoggingIn={isLoggingIn}
          />
        </BaseNavigationContent>
      </>
    );
  }

  private handleChangeAccountName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.authService.changeAccountName(e.target.value);
  };
  private handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.authService.changePassword(e.target.value);
  };

  private handleLogin = async () => {
    await this.props.authService.login();
    this.props.history.push("/");
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    authState: state.auth,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return { authService: new AuthService(dispatch) };
};

export const LoginPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
