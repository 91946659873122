import { combineReducers } from "redux";
import { NewOrEditStateType } from "./newOrEditState/reducer";

export interface MasterFeedState {
  newOrEditState: NewOrEditStateType;
}

import { NewOrEditStateReducer } from "./newOrEditState/ducks";

export const feedReducer = combineReducers({
  newOrEditState: NewOrEditStateReducer,
});
