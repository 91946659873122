import { actionCreatorFactory } from "typescript-fsa";
import { EditPondArea, NewOrEditPondArea, NewPondArea, PondArea } from "../../../../../domain/pondArea";
// Actions
const actionCreator = actionCreatorFactory("MasterPondAreaNewOrEditState");

const createPondArea = actionCreator<CreatePondAreaPayload>("createPondArea");
interface CreatePondAreaPayload {
  pondArea: NewPondArea;
}

const selectPondArea = actionCreator<SelectPondAreaPayload>("selectPondArea");
interface SelectPondAreaPayload {
  pondArea: EditPondArea;
}

const changePondArea = actionCreator<ChangePondAreaPayload>("changePondArea");
interface ChangePondAreaPayload {
  key: keyof NewOrEditPondArea;
  value: any;
}

export const cancelPondArea = actionCreator<CancelPondAreaPayload>("cancelPondArea");
interface CancelPondAreaPayload {
  pondArea: NewOrEditPondArea;
}

export const saveStart = actionCreator("saveStart");
export const saveSuccess = actionCreator<SaveSuccessPayload>("saveSuccess");
interface SaveSuccessPayload {
  pondArea: PondArea | null;
}
export const saveFail = actionCreator("saveFail");

export const masterPondAreaNewOrEditStateActions = {
  createPondArea,
  selectPondArea,
  changePondArea,
  cancelPondArea,
  saveStart,
  saveSuccess,
  saveFail,
};

// Reducers
import { ChangeHandler, getInitialState, InitialHandler, NewOrEditStateType, SaveHandler } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const NewOrEditStateReducer = reducerWithInitialState<NewOrEditStateType>(getInitialState())
  .case(createPondArea, (state, { pondArea }) => {
    return InitialHandler.createPondArea(state, pondArea);
  })
  .case(selectPondArea, (state, { pondArea }) => {
    return InitialHandler.selectPondArea(state, pondArea);
  })
  .case(changePondArea, (state, { key, value }) => {
    return ChangeHandler.changePondArea(state, key, value);
  })
  .case(cancelPondArea, (state, { pondArea }) => {
    return SaveHandler.cancelPondArea(state, pondArea);
  })
  .case(saveStart, (state) => {
    return SaveHandler.saveStart(state);
  })
  .case(saveSuccess, (state, { pondArea }) => {
    return SaveHandler.saveSuccess(state, pondArea);
  })
  .case(saveFail, (state) => {
    return SaveHandler.saveFail(state);
  });
