import { PondType } from "../../domain/pondType";
import * as api from "../../libs/client";
import { translateApiToPondType } from "./translate";

interface IPondTypeRepository {
  getPondTypes: () => Promise<PondType[]>;
}

export class PondTypeRepository implements IPondTypeRepository {
  public async getPondTypes(): Promise<PondType[]> {
    const pondTypesApi = new api.PondTypesApi(api.serverConfig);
    const response = await pondTypesApi.getPondTypes();
    return response.data.map((d) => translateApiToPondType(d));
  }
}
