import { Dispatch } from "redux";
import {
  EditPondReport,
  fillEmptyAdultPondReports,
  fillEmptyFryPondReports,
  getCurrentPondReport,
  isEditPondReport,
  overwriteFryPondReportBeforeSaving,
  PondReportValidator,
  removeEmptyAdultPondReports,
  removeEmptyFryPondReports,
} from "../../../domain/pondReport";
import { isFryPondType } from "../../../domain/pondType";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { PondReportRepository } from "../../../infrastracture/pondReport/repository";
import { ApplicationState } from "../../../store/modules";
import { apiPondReportActions } from "../../../store/modules/api/pondReport/ducks";
import { pondReportNewOrEditStateActions } from "../../../store/modules/mobile/pondReport/newOrEditState/ducks";
import { notificationAlertStateActions } from "../../../store/modules/notification/alert/ducks";
import { PondReportApiService } from "../../api/pondReport";

interface INewOrEditStateService extends IApplicationService {
  initPondReport: () => void;
  selectPondReport: (reportId: number) => void;
  resetPondReport: () => void;
  savePondReport: () => void;
}

export class NewOrEditStateService implements INewOrEditStateService {
  private readonly pondReportApiService: PondReportApiService;

  public constructor(private dispatch: Dispatch<any>) {
    this.pondReportApiService = new PondReportApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError((dispatch) => dispatch(pondReportNewOrEditStateActions.initialFailed()))
  public async initPondReport() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(pondReportNewOrEditStateActions.initialStart());
      const state = getState();
      const { selectedPondId, selectedDate } = state.mobile.navigation;
      const pond = state.api.pond.ponds.find((p) => p.id === selectedPondId);
      if (!pond) {
        throw new ApplicationError("不正な動作です。");
      }
      const currentPondReport = getCurrentPondReport(state.api.pondReport.pondReports, pond.id, selectedDate);
      if (!currentPondReport) {
        this.dispatch(pondReportNewOrEditStateActions.initialFailed());
        return;
      }
      const pondType = state.api.pondType.pondTypes.find((p) => p.id === currentPondReport.pondTypeId);
      if (!pondType) {
        throw new ApplicationError("不正な動作です。");
      }
      const isFryReport = isFryPondType(pondType);

      const filledReport = isFryReport
        ? fillEmptyFryPondReports([currentPondReport])[0]
        : fillEmptyAdultPondReports([currentPondReport])[0];
      this.dispatch(pondReportNewOrEditStateActions.initialComplete({ pondReport: filledReport }));
      await this.pondReportApiService.fetchRelatedPondReports((currentPondReport as EditPondReport).id);
    });
  }

  @catchApplicationError((dispatch) => dispatch(pondReportNewOrEditStateActions.initialFailed()))
  public async selectPondReport(reportId: number) {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(pondReportNewOrEditStateActions.initialStart());
      const state = getState();
      const currentPondReport = state.api.pondReport.pondReports.find((pr) => pr.id === reportId);
      if (!currentPondReport) {
        this.dispatch(pondReportNewOrEditStateActions.initialFailed());
        return;
      }
      const pondType = state.api.pondType.pondTypes.find((p) => p.id === currentPondReport.pondTypeId);
      if (!pondType) {
        throw new ApplicationError("不正な動作です。");
      }
      const isFryReport = isFryPondType(pondType);

      const filledReport = isFryReport
        ? fillEmptyFryPondReports([currentPondReport])[0]
        : fillEmptyAdultPondReports([currentPondReport])[0];
      this.dispatch(pondReportNewOrEditStateActions.initialComplete({ pondReport: filledReport }));
      await this.pondReportApiService.fetchRelatedPondReports((currentPondReport as EditPondReport).id);
    });
  }

  @catchApplicationError()
  public resetPondReport() {
    this.dispatch(pondReportNewOrEditStateActions.resetState());
  }

  @catchApplicationError((dispatch) => dispatch(pondReportNewOrEditStateActions.saveFail()))
  public async savePondReport() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(pondReportNewOrEditStateActions.saveStart());
      const state = getState();
      const { newOrEditStateType } = state.mobile.pondReportState;
      const pondReport = newOrEditStateType.pondReport;
      if (pondReport === null) {
        throw new ApplicationError("記録が見つかりませんでした。");
      }
      const pondType = state.api.pondType.pondTypes.find((p) => p.id === pondReport.pondTypeId);
      if (!pondType) {
        throw new ApplicationError("池の区分が見つかりませんでした。");
      }
      const isFryReport = isFryPondType(pondType);

      const removedReport = isFryReport
        ? overwriteFryPondReportBeforeSaving(removeEmptyFryPondReports([pondReport])[0], state.api.scaleType.scaleTypes)
        : removeEmptyAdultPondReports([pondReport])[0];

      const validator = new PondReportValidator(state.api.pondReport.pondReports);
      validator.validate(removedReport);
      if (!validator.isValid()) {
        this.dispatch(notificationAlertStateActions.showErrorMessage({ errorMessage: validator.getMessages() }));
        this.dispatch(pondReportNewOrEditStateActions.saveFail());
        return;
      }
      if (isEditPondReport(removedReport)) {
        const updatedPondReport = await new PondReportRepository().putPondReport(removedReport);
        const filledUpdatedPondReport = isFryReport
          ? (fillEmptyFryPondReports([updatedPondReport])[0] as EditPondReport)
          : (fillEmptyAdultPondReports([updatedPondReport])[0] as EditPondReport);

        this.dispatch(
          pondReportNewOrEditStateActions.saveSuccess({
            pondReport: filledUpdatedPondReport,
          })
        );
        this.dispatch(apiPondReportActions.updatePondReport({ pondReport: updatedPondReport }));
        return;
      }
      const createdPondReport = await new PondReportRepository().postPondReport(removedReport);
      const filledCreatedPondReport = isFryReport
        ? (fillEmptyFryPondReports([createdPondReport])[0] as EditPondReport)
        : (fillEmptyAdultPondReports([createdPondReport])[0] as EditPondReport);

      this.dispatch(
        pondReportNewOrEditStateActions.saveSuccess({
          pondReport: filledCreatedPondReport as EditPondReport,
        })
      );
      this.dispatch(apiPondReportActions.createPondReport({ pondReport: createdPondReport }));
    });
  }
}
