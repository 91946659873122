import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NavigationService } from "../../../../application/mobile/navigation";
import { FertilizerReportStateService } from "../../../../application/mobile/workReport/fertilizerReportState";
import { NewOrEditStateService } from "../../../../application/mobile/workReport/newOrEditState";
import { getUniqueKey } from "../../../../domain/entity";
import { Pond } from "../../../../domain/pond";
import { SearchQueryMobile } from "../../../../domain/searchQuery";
import { WorkReport } from "../../../../domain/workReport";
import { ApplicationState } from "../../../../store/modules";
import { NavigationStateType } from "../../../../store/modules/mobile/navigation/reducer";
import { NewOrEditStateType } from "../../../../store/modules/mobile/workReport/newOrEditState/reducer";
import { Spinner } from "../../../components/atoms/Spinner";
import { ActionsModal } from "../../../components/molecules/ActionsModal";
import { ConfirmModal } from "../../../components/molecules/ConfirmModal";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { NotificationModal } from "../../../components/molecules/NotificationModal";
import { MobileLayout } from "../../Layout/Mobile";
import { WorkReportFertilizerListPage } from "./List";
import { WorkReportFertilizerNewOrEditPage } from "./NewOrEdit";

interface StateProps {
  ponds: Pond[];
  workReports: WorkReport[];
  navigationState: NavigationStateType;
  newOrEditState: NewOrEditStateType;
  isLoaded: boolean;
}

interface DispatchProps {
  newOrEditStateService: NewOrEditStateService;
  navigationService: NavigationService;
  fertilizerReportStateService: FertilizerReportStateService;
}

type WorkReportFertilizerPageProps = StateProps & DispatchProps & RouteComponentProps;

interface WorkReportFertilizerPageState {
  isOpenConfirmModal: boolean;
  isOpenNotificationModal: boolean;
  isOpenActionModal: boolean;
}

export class Wrapped extends React.Component<WorkReportFertilizerPageProps, WorkReportFertilizerPageState> {
  constructor(props: WorkReportFertilizerPageProps) {
    super(props);

    this.state = {
      isOpenConfirmModal: false,
      isOpenNotificationModal: false,
      isOpenActionModal: false,
    };
  }
  public async componentWillMount() {
    if (this.props.navigationState.selectedPondId === null) {
      this.props.history.push("/");
      return;
    }
    if (!this.props.isLoaded) {
      this.props.history.push("/reports/menu");
      return;
    }
    await this.props.newOrEditStateService.initWorkReport();

    this.props.fertilizerReportStateService.fetchApi().then(() => {
      const { workReport } = this.props.newOrEditState;
      const fertilizerReportsCount = !!workReport ? workReport.fertilizerReports.length : null;
      if (fertilizerReportsCount === 0) {
        this.props.fertilizerReportStateService.addFertilizerReport();
      }
    });
  }

  public render() {
    const { workReport, selectedReportKey, selectedUniqueKey, isSaving } = this.props.newOrEditState;
    const pond = this.props.ponds.find((p) => p.id === this.props.navigationState.selectedPondId);
    const fertilizerReport =
      selectedReportKey === "fertilizer" &&
      !!workReport &&
      workReport.fertilizerReports.find((fr) => getUniqueKey(fr) === selectedUniqueKey);

    if (!pond || !workReport) {
      return this.renderEmpty();
    }
    if (!fertilizerReport) {
      if (workReport.fertilizerReports.length === 0) {
        return this.renderEmpty();
      }
      return (
        <>
          <WorkReportFertilizerListPage workReport={workReport} />
          {this.renderModal()}
        </>
      );
    }

    return (
      <>
        <WorkReportFertilizerNewOrEditPage
          workReport={workReport}
          fertilizerReport={fertilizerReport}
          isSaving={isSaving}
          onClickSave={this.handleClickSave}
          openActionModal={this.openActionModal}
        />
        {this.renderModal()}
      </>
    );
  }

  private renderEmpty = () => {
    return (
      <MobileLayout>
        <BaseNavigationHeader title={""} onClickMenu={this.handleClickMenu} />
        <BaseNavigationContent>
          <Spinner />
          {this.renderModal()}
        </BaseNavigationContent>
      </MobileLayout>
    );
  };

  private renderModal = () => {
    return (
      <>
        <ConfirmModal
          isOpen={this.state.isOpenConfirmModal}
          onClose={this.handleConfirmModalCancel}
          onClickCancel={this.handleConfirmModalCancel}
          onClickOk={this.handleConfirmModalOk}
          cancelText={`${this.getCancelMessageOfConfirmModal()}に戻る`}
          okText={"続けて作成"}
          confirmMessage={"保存しました\n続けて肥料の記録を作成しますか?"}
        />
        <NotificationModal
          isOpen={this.state.isOpenNotificationModal}
          onClose={this.handleNotificationModalOk}
          onClickOk={this.handleNotificationModalOk}
          okText={"OK"}
          message={"削除しました"}
        />
        <ActionsModal
          isOpen={this.state.isOpenActionModal}
          onClose={this.closeActionModal}
          actions={[{ text: "肥料記録を削除する", onClick: this.handleClickRemove }]}
        />
      </>
    );
  };

  private getCancelMessageOfConfirmModal = () => {
    const returnURL = SearchQueryMobile.getReturnURLFromState(this.props.location.search);
    if (returnURL === "reports") {
      return "一覧";
    }
    return "メニュー";
  };

  private handleClickMenu = () => {
    this.props.navigationService.openMenu();
  };

  private handleClickSave = async () => {
    await this.props.newOrEditStateService.saveWorkReport();
    if (this.props.newOrEditState.isSaved) {
      this.openConfirmModal();
    }
  };

  private handleClickRemove = async () => {
    this.closeActionModal();
    await this.props.newOrEditStateService.deleteFertilizerReport();
    if (this.props.newOrEditState.isSaved) {
      this.openNotificationModal();
    }
  };

  private handleConfirmModalCancel = () => {
    this.closeConfirmModal();
    this.props.history.goBack();
  };

  private handleConfirmModalOk = () => {
    this.closeConfirmModal();
    this.props.fertilizerReportStateService.addFertilizerReport();
  };

  private handleNotificationModalOk = () => {
    this.closeNotificationModal();
    this.props.history.goBack();
  };

  private openActionModal = () => this.setState({ isOpenActionModal: true });
  private closeActionModal = () => this.setState({ isOpenActionModal: false });
  private openConfirmModal = () => this.setState({ isOpenConfirmModal: true });
  private closeConfirmModal = () => this.setState({ isOpenConfirmModal: false });
  private openNotificationModal = () => this.setState({ isOpenNotificationModal: true });
  private closeNotificationModal = () => this.setState({ isOpenNotificationModal: false });
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { pond, workReport } = state.api;
  return {
    ponds: pond.ponds,
    workReports: workReport.workReports,
    navigationState: state.mobile.navigation,
    newOrEditState: state.mobile.workReportState.newOrEditStateType,
    isLoaded: pond.isFetched,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    newOrEditStateService: new NewOrEditStateService(dispatch),
    navigationService: new NavigationService(dispatch),
    fertilizerReportStateService: new FertilizerReportStateService(dispatch),
  };
};

export const WorkReportFertilizerPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
