import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { FryPondNewOrEditStateService } from "../../../application/report/pondReport/fryPondNewOrEditState";
import { MoveModalStateService } from "../../../application/report/pondReport/moveModalState";
import { ReadApiService } from "../../../application/report/pondReport/readApi";
import { isActiveEntity } from "../../../domain/entity";
import { isUsedPond, Pond } from "../../../domain/pond";
import { PondArea } from "../../../domain/pondArea";
import { PondReport } from "../../../domain/pondReport";
import { ApplicationState } from "../../../store/modules";
import { MoveModalStateType } from "../../../store/modules/report/pondReport/moveModalState/reducer";
import { NewOrEditStateType } from "../../../store/modules/report/pondReport/newOrEditState/reducer";
import { CarpMovingFormModal } from "../../components/molecules/CarpMovingFormModal";

interface StateProps {
  moveModalState: MoveModalStateType;
  pondReports: PondReport[];
  pondAreas: PondArea[];
  ponds: Pond[];
  newOrEditState: NewOrEditStateType;
}

interface DispatchProps {
  fryPondNewOrEditStateService: FryPondNewOrEditStateService;
  readApiService: ReadApiService;
  moveModalStateService: MoveModalStateService;
}

type MoveModalProps = StateProps & DispatchProps;

export class Wrapped extends React.Component<MoveModalProps> {
  public render() {
    return (
      <CarpMovingFormModal
        isOpen={this.props.moveModalState.isOpen}
        modalState={this.props.moveModalState.modalState}
        selectedDate={this.props.newOrEditState.selectedDate}
        pondReports={this.props.pondReports}
        pondAreas={this.props.pondAreas}
        ponds={this.props.ponds}
        onChangePondArea={this.handleChangePondArea}
        onChangePond={this.handleChangePond}
        onChangeAmount={this.handleChangeAmount}
        onChangeNote={this.handleChangeNote}
        onSave={this.handleClickSave}
        onClose={this.handleClickClose}
        isSaving={this.props.newOrEditState.isSaving}
      />
    );
  }

  private handleChangePondArea = (pondAreaId: null | number) => {
    this.props.moveModalStateService.changePond(null);
    this.props.moveModalStateService.changePondArea(pondAreaId);
  };
  private handleChangePond = (pondId: null | number) => {
    this.props.moveModalStateService.changePond(pondId);
    this.props.readApiService.getPondReportOnChangePondOnMoveModal();
  };

  private handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.moveModalStateService.changeAmount(e.target.value);
  };
  private handleChangeNote = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.moveModalStateService.changeNote(e.target.value);
  };

  private handleClickSave = async () => {
    await this.props.fryPondNewOrEditStateService.addCarpMovingReport();
    if (this.props.newOrEditState.isSaved) {
      this.props.moveModalStateService.closeModal();
    }
  };

  private handleClickClose = () => {
    this.props.moveModalStateService.closeModal();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { pondReport, pondArea, pond } = state.api;
  const { moveModalState, newOrEditState } = state.report.pondReport;
  return {
    moveModalState,
    pondReports: pondReport.pondReports,
    pondAreas: pondArea.pondAreas.filter(isActiveEntity),
    // 自身のPondへの移動を禁止するため
    ponds: pond.ponds.filter(
      (p) => isActiveEntity(p) && isUsedPond(p) && p.id !== state.report.navigation.selectedPondId
    ),
    newOrEditState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    fryPondNewOrEditStateService: new FryPondNewOrEditStateService(dispatch),
    readApiService: new ReadApiService(dispatch),
    moveModalStateService: new MoveModalStateService(dispatch),
  };
};

export const MoveModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);
