import { combineReducers } from "redux";
import { apiReducer, ApiState } from "./api";
import { authStateReducer } from "./auth/ducks";
import { AuthStateType } from "./auth/reducer";
import { imageGalleryStateReducer } from "./imageGallery/ducks";
import { ImageGalleryStateType } from "./imageGallery/reducer";
import { masterReducer, MasterState } from "./master";
import { mobileReducer, MobileState } from "./mobile";
import { notificationStateReducer, NotificationStateType } from "./notification";
import { reportReducer, ReportState } from "./report";
import { viewReducer, ViewState } from "./view";

export interface ApplicationState {
  auth: AuthStateType;
  api: ApiState;
  imageGallery: ImageGalleryStateType;
  master: MasterState;
  mobile: MobileState;
  notification: NotificationStateType;
  report: ReportState;
  view: ViewState;
}

export const rootReducer = combineReducers({
  auth: authStateReducer,
  api: apiReducer,
  imageGallery: imageGalleryStateReducer,
  master: masterReducer,
  mobile: mobileReducer,
  notification: notificationStateReducer,
  report: reportReducer,
  view: viewReducer,
});
