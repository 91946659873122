import { Entity, generateUid, isEntity, isNewEntity, NewEntity } from "../entity";
import { isFeederUseMethodType, UseMethodType } from "../useMethodType";
import { getValidation, isEmptyString, isLimitedNumber, isZeroString, Validation, Validator } from "../validation";

interface FeedReportAttribute extends Record<string, unknown> {
  feedId: null | number;
  feedReasonTypeId: null | number;
  useMethodTypeId: null | number;
  count: string;
  amount: string;
}

export interface NewFeedReport extends FeedReportAttribute, NewEntity {}
export interface EditFeedReport extends FeedReportAttribute, Entity {}

export type NewOrEditFeedReport = NewFeedReport | EditFeedReport;
export type FeedReport = EditFeedReport;

// service
const NEW_ENTITY_PREFIX = "fe-";

export function createNewFeedReport(useMethodTypeId: number): NewFeedReport {
  return {
    uid: generateUid(NEW_ENTITY_PREFIX),
    feedId: null,
    feedReasonTypeId: null,
    useMethodTypeId,
    count: "",
    amount: "",
  };
}

export function isNewFeedReport(report: NewOrEditFeedReport): report is NewFeedReport {
  return isNewEntity(report);
}

export function isEditFeedReport(report: NewOrEditFeedReport): report is EditFeedReport {
  return isEntity(report);
}

export function copyFeedReport(source: NewOrEditFeedReport, target: NewOrEditFeedReport): NewOrEditFeedReport {
  return {
    ...target,
    feedId: source.feedId,
    feedReasonTypeId: source.feedReasonTypeId,
    useMethodTypeId: source.useMethodTypeId,
    count: source.count,
    amount: source.amount,
  };
}

/*** Validator ***/
export class FeedReportValidator extends Validator<NewOrEditFeedReport> {
  constructor(private useMethodTypes: UseMethodType[]) {
    super();
  }

  public validate(entity: NewOrEditFeedReport) {
    const useMethodTypeValidation = validateUseMethodType(entity);
    if (useMethodTypeValidation) {
      this.addMessages(useMethodTypeValidation);
    }

    const amountValidation = validateAmount(entity);
    if (amountValidation) {
      this.addMessages(amountValidation);
    }
    const useMethodType = this.useMethodTypes.find((m) => m.id === entity.useMethodTypeId);
    if (!!useMethodType && isFeederUseMethodType(useMethodType)) {
      const countValidation = validateCount(entity);
      if (countValidation) {
        this.addMessages(countValidation);
      }
    }

    const feedReasonTypeValidation = validateFeedReasonType(entity);
    if (feedReasonTypeValidation) {
      this.addMessages(feedReasonTypeValidation);
    }
    const feedValidation = validateFeed(entity);
    if (feedValidation) {
      this.addMessages(feedValidation);
    }
  }
}

function validateUseMethodType({ useMethodTypeId }: NewOrEditFeedReport): Validation | null {
  if (useMethodTypeId === null) {
    return getValidation("単位は必須です。");
  }
  return null;
}

function validateAmount({ amount }: NewOrEditFeedReport): Validation | null {
  if (isEmptyString(amount)) {
    return getValidation("量は必須です。入力してください。");
  }
  if (!isLimitedNumber(amount, 9, 4)) {
    return getValidation("量は整数部9桁、小数部4桁で入力してください。");
  }
  return null;
}

function validateCount({ count }: NewOrEditFeedReport): Validation | null {
  if (isEmptyString(count) || isZeroString(count)) {
    return getValidation("回数は必須です。入力してください。");
  }
  if (!isLimitedNumber(count, 9, 4)) {
    return getValidation("回数は整数部9桁、小数部4桁で入力してください。");
  }
  return null;
}

function validateFeedReasonType({ feedReasonTypeId }: NewOrEditFeedReport): Validation | null {
  if (feedReasonTypeId === null) {
    return getValidation("理由は必須です。");
  }
  return null;
}

function validateFeed({ feedId }: NewOrEditFeedReport): Validation | null {
  if (feedId === null) {
    return getValidation("餌は必須です。");
  }
  return null;
}
