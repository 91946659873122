import * as React from "react";
import { Button as _Button } from "semantic-ui-react";
import styled from "styled-components";
import { COLOR } from "../../../styles/Color";
import { DIMEN } from "../../../styles/Dimen";
import { Button, IconButton } from "./index";

const SAVE_BUTTON_HEIGHT = "35px";
const FixedSaveButton = styled(_Button).attrs({ children: "保存" })`
  background-color: ${COLOR.Primary.base} !important;
  color: ${COLOR.Base.white} !important;
  padding: ${DIMEN.X1} 0 !important;
  width: 100%;
  position: fixed;
  bottom: 0;
  height: ${SAVE_BUTTON_HEIGHT};
  border-radius: 0 !important;
`;
const EmptyBlock = styled.div`
  height: ${SAVE_BUTTON_HEIGHT};
`;

interface SaveButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

export const SaveButton = ({ onClick, disabled }: SaveButtonProps) => {
  return (
    <>
      <EmptyBlock />
      <FixedSaveButton onClick={onClick} disabled={disabled} />
    </>
  );
};
export const AddButton = styled(IconButton).attrs({ icon: "plus" })`
  color: ${COLOR.Base.white} !important;
  background-color: ${COLOR.Primary.base} !important;
  position: fixed;
  bottom: ${DIMEN.X2};
  right: ${DIMEN.X2};
`;
export const DefaultButton = styled(Button)``;
