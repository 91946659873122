import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NavigationService } from "../../../../application/mobile/navigation";
import { ReadApiService } from "../../../../application/mobile/pondSelection/readApi";
import { NewOrEditStateService } from "../../../../application/mobile/workReport/newOrEditState";
import { isActiveEntity, UniqueKey } from "../../../../domain/entity";
import { PondArea } from "../../../../domain/pondArea";
import { ApplicationState } from "../../../../store/modules";
import { EmptyMessage } from "../../../components/atoms/Message";
import { MobileList } from "../../../components/molecules/MobileList";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { MobileLayout } from "../../Layout/Mobile";

interface StateProps {
  pondAreas: PondArea[];
}

interface DispatchProps {
  readApiService: ReadApiService;
  newOrEditStateService: NewOrEditStateService;
  navigationService: NavigationService;
}

type PondAreaSelectionPageProps = StateProps & DispatchProps & RouteComponentProps;

export class Wrapped extends React.Component<PondAreaSelectionPageProps> {
  public componentWillMount(): void {
    this.props.navigationService.clearState();
    this.props.readApiService.fetchPondAreas();
  }

  public render() {
    const isEmpty = this.props.pondAreas.length === 0;
    return (
      <MobileLayout>
        <BaseNavigationHeader title={"エリア"} onClickMenu={this.handleClickMenu} />
        <BaseNavigationContent>
          {isEmpty && <EmptyMessage>エリアが見つかりませんでした。</EmptyMessage>}
          {!isEmpty && (
            <MobileList itemClassName={"large"} items={this.props.pondAreas} onClickItem={this.handleClickPondArea} />
          )}
        </BaseNavigationContent>
      </MobileLayout>
    );
  }

  private handleClickPondArea = (id: UniqueKey) => {
    if (typeof id === "string") {
      return;
    }
    this.props.navigationService.selectPondArea(id);
    this.props.history.push(`/ponds/pond_areas/${id}`);
  };

  private handleClickMenu = () => {
    this.props.navigationService.openMenu();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { pondArea } = state.api;
  return {
    pondAreas: pondArea.pondAreas.filter(isActiveEntity),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    readApiService: new ReadApiService(dispatch),
    newOrEditStateService: new NewOrEditStateService(dispatch),
    navigationService: new NavigationService(dispatch),
  };
};

export const PondAreaSelectionPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
