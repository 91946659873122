import { Image, NewImage, NewOrEditImage } from "../../../../domain/image";
import { EditLifeCycle } from "../../../lifeCycle/editLifeCycle";

export interface ImageStateType extends EditLifeCycle {
  image: NewOrEditImage | null;
}

export const getInitialState = (): ImageStateType => {
  return { image: null, isSaving: false, isUnSave: false, isSaved: false, isError: null };
};

export const SaveHandlers = {
  uploadStart(state: ImageStateType, image: NewImage): ImageStateType {
    return { ...state, image, isSaving: true, isSaved: false, isError: null };
  },
  uploadImage(state: ImageStateType, image: Image): ImageStateType {
    return { ...state, image };
  },
  uploadSuccess(state: ImageStateType, image: Image): ImageStateType {
    return { ...state, image, isSaving: false, isSaved: true, isError: null };
  },
  uploadFailed(state: ImageStateType): ImageStateType {
    return { ...state, isSaving: false, isSaved: false, isUnSave: true, isError: null };
  },
};
