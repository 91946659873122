import * as _ from "lodash";
import * as React from "react";
import { NewOrEditAdultCarpReport } from "../../../../domain/adultCarpReport";
import { getFiscalYear, isSameFiscalYear } from "../../../../domain/calendar";
import { getUniqueKey, UniqueKey } from "../../../../domain/entity";
import { Pond } from "../../../../domain/pond";
import { PondArea } from "../../../../domain/pondArea";
import { isNewPondReport, NewOrEditPondReport } from "../../../../domain/pondReport";
import { PondType } from "../../../../domain/pondType";
import { DIMEN } from "../../../styles/Dimen";
import { Button, ButtonGroup } from "../../atoms/Button";
import { Form } from "../../atoms/Form/Form";
import { FormNumberInput } from "../../atoms/Form/Input";
import { FormSelect } from "../../atoms/Form/Select";
import { Spinner } from "../../atoms/Spinner";
import { MasterFormButton } from "../../molecules/MasterFormButton";
import { AdultCarpReportTable } from "../../molecules/PondReportTable/AdultCarpReportTable";
import { PondReportForm } from "../../molecules/ReportForm";
import { PondName, PondNameButtonGroup, TableSeparate, YearButtonGroup } from "./elements";

interface AdultPondReportFormProps {
  pondTypes: PondType[];
  pondReports: NewOrEditPondReport[];
  selectedPondArea: PondArea | null;
  selectedPond: Pond;
  selectedDate: Date;
  onChangeFiscalYear: (pondReportId: UniqueKey) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePondType: (pondReportId: UniqueKey) => (pondTypeId: number | null) => void;
  onChangeAdultCarpReport: (
    reportId: UniqueKey
  ) => (adultUniqueKey: UniqueKey, key: keyof NewOrEditAdultCarpReport, value: any) => void;
  onClickNew: () => void;
  onClickPrevYear: () => void;
  onClickCurrentYear: () => void;
  onClickNextYear: () => void;
  onClickSave: () => void;
  onClickCancel: () => void;
  isSaving: boolean;
}

export class AdultPondReportForm extends React.Component<AdultPondReportFormProps> {
  public render() {
    const isNoReports = this.props.pondReports.length === 0;
    const firstReport = !isNoReports ? this.props.pondReports[0] : null;
    const isSameFiscalYearNow = this.isSameFiscalYearNow(
      !!firstReport ? firstReport.fiscalYear : null,
      this.props.selectedDate
    );

    return (
      <PondReportForm>
        {this.props.isSaving && <Spinner />}
        <Form style={{ padding: DIMEN.X2 }}>
          <PondName>
            {this.props.selectedPondArea && `${this.props.selectedPondArea.name} `}
            {this.props.selectedPond.name}
            <PondNameButtonGroup>
              <Button onClick={this.props.onClickNew}>新規</Button>
            </PondNameButtonGroup>
          </PondName>
          {!!firstReport && (
            <FormNumberInput
              label={"年度"}
              value={firstReport.fiscalYear}
              onChange={this.props.onChangeFiscalYear(getUniqueKey(firstReport))}
              readOnly={!this.canEditFiscalYear()}
            />
          )}
          {!firstReport && (
            <FormNumberInput
              label={"年度"}
              value={"" + getFiscalYear(this.props.selectedDate)}
              onChange={_.noop}
              readOnly={true}
            />
          )}
          {!!firstReport && !isSameFiscalYearNow && (
            <FormSelect
              value={firstReport.pondTypeId}
              options={this.props.pondTypes}
              onChange={this.props.onChangePondType(getUniqueKey(firstReport))}
              readOnly={!this.canEditPondType()}
            />
          )}

          <YearButtonGroup>
            <ButtonGroup className={"separate"}>
              <Button onClick={this.props.onClickPrevYear}>前年度</Button>
              <Button onClick={this.props.onClickCurrentYear}>今年度</Button>
              <Button onClick={this.props.onClickNextYear}>次年度</Button>
            </ButtonGroup>
          </YearButtonGroup>
          {this.props.pondReports.map((r, index) => {
            const reportUniqueKey = getUniqueKey(r);
            return (
              <React.Fragment key={reportUniqueKey}>
                <AdultCarpReportTable
                  adultCarpReports={r.adultCarpReports}
                  onChangeAdultCarpReport={this.handleChangeAdultCarpReport(reportUniqueKey)}
                />
                {index !== this.props.pondReports.length - 1 && <TableSeparate />}
              </React.Fragment>
            );
          })}
          {!isNoReports && (
            <MasterFormButton onClickSave={this.props.onClickSave} onClickCancel={this.props.onClickCancel} />
          )}
        </Form>
      </PondReportForm>
    );
  }

  private canEditPondType = (): boolean => {
    return this.props.pondReports.every(isNewPondReport);
  };

  private canEditFiscalYear = () => {
    return this.props.pondReports.every(isNewPondReport);
  };

  private isSameFiscalYearNow = (fiscalYear: string | null, selectedDate: Date) => {
    if (fiscalYear === null) {
      return isSameFiscalYear(selectedDate, new Date());
    }
    return fiscalYear === "" + getFiscalYear(new Date());
  };

  private handleChangeAdultCarpReport = (reportId: UniqueKey) => {
    return this.props.onChangeAdultCarpReport(reportId);
  };
}

interface NoPondReportForm {
  selectedPondArea: PondArea | null;
  selectedPond: Pond;
  onClickNew: () => void;
}

export const NoPondReportForm = (props: NoPondReportForm) => {
  return (
    <PondReportForm>
      <Form>
        <PondName>
          {props.selectedPondArea && `${props.selectedPondArea.name} `}
          {props.selectedPond.name}
          <PondNameButtonGroup>
            <Button onClick={props.onClickNew}>新規</Button>
          </PondNameButtonGroup>
        </PondName>
      </Form>
    </PondReportForm>
  );
};
