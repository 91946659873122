import { combineReducers } from "redux";
import { feedReducer, MasterFeedState } from "./feed";
import { fertilizerReducer, MasterFertilizerState } from "./fertilizer";
import { herbicideReducer, MasterHerbicideState } from "./herbicide";
import { MasterParentCarpState, parentCarpReducer } from "./parentCarp";
import { MasterPondState, pondReducer } from "./pond";
import { MasterPondAreaState, pondAreaReducer } from "./pondArea";
import { MasterWorkerState, workerReducer } from "./worker";

export interface MasterState {
  feed: MasterFeedState;
  fertilizer: MasterFertilizerState;
  pondArea: MasterPondAreaState;
  pond: MasterPondState;
  parentCarp: MasterParentCarpState;
  herbicide: MasterHerbicideState;
  worker: MasterWorkerState;
}
export const masterReducer = combineReducers({
  feed: feedReducer,
  fertilizer: fertilizerReducer,
  pondArea: pondAreaReducer,
  pond: pondReducer,
  parentCarp: parentCarpReducer,
  herbicide: herbicideReducer,
  worker: workerReducer,
});
