import { actionCreatorFactory } from "typescript-fsa";
import { EnvironmentReport, NewOrEditEnvironmentReport } from "../../../../../domain/environmentReport";

// Actions
const actionCreator = actionCreatorFactory("MobileEnvironmentReportNewOrEditState");

const initialEnvironmentReport = actionCreator<InitialEnvironmentReportPayload>("initialEnvironmentReport");
interface InitialEnvironmentReportPayload {
  environmentReport: NewOrEditEnvironmentReport;
}
const resetState = actionCreator("resetState");

const changeEnvironmentReport = actionCreator<ChangeEnvironmentReportPayload>("changeEnvironmentReport");
interface ChangeEnvironmentReportPayload {
  key: keyof NewOrEditEnvironmentReport;
  value: any;
}
// save
const saveStart = actionCreator("saveStart");
const saveSuccess = actionCreator<SaveSuccessPayload>("saveSuccess");
interface SaveSuccessPayload {
  environmentReport: EnvironmentReport;
}
const deleteSuccess = actionCreator("deleteSuccess");
const saveFail = actionCreator("saveFail");

export const environmentReportNewOrEditStateActions = {
  initialEnvironmentReport,
  resetState,
  changeEnvironmentReport,
  saveStart,
  saveSuccess,
  deleteSuccess,
  saveFail,
};

// Reducers
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { ChangeHandler, getInitialState, InitialHandler, NewOrEditStateType, SaveHandler } from "./reducer";

export const environmentReportNewOrEditStateReducer = reducerWithInitialState<NewOrEditStateType>(getInitialState())
  .case(initialEnvironmentReport, (state, { environmentReport }) =>
    InitialHandler.initialEnvironmentReport(state, environmentReport)
  )
  .case(resetState, (state) => InitialHandler.resetState(state))
  .case(changeEnvironmentReport, (state, { key, value }) => ChangeHandler.changeEnvironmentReport(state, key, value))
  .case(saveStart, (state) => SaveHandler.saveStart(state))
  .case(saveSuccess, (state, { environmentReport }) => SaveHandler.saveSuccess(state, environmentReport))
  .case(deleteSuccess, (state) => SaveHandler.deleteSuccess(state))
  .case(saveFail, (state) => SaveHandler.saveFail(state));
