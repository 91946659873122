import { sortPosition } from "../../../../domain/entity";
import { FeedReasonType } from "../../../../domain/feedReasonType";
import { FetchLifeCycle } from "../../../lifeCycle/fetchLifeCycle";

export interface FeedReasonTypeStateType extends FetchLifeCycle {
  feedReasonTypes: FeedReasonType[];
}

export const getInitialState = (): FeedReasonTypeStateType => {
  return { feedReasonTypes: [], isFetched: false, isFetching: false, isError: null };
};

export const FetchHandlers = {
  start(state: FeedReasonTypeStateType): FeedReasonTypeStateType {
    return { ...state, isFetched: false, isFetching: true, isError: null };
  },
  complete(state: FeedReasonTypeStateType, feedReasonTypes: FeedReasonType[]): FeedReasonTypeStateType {
    const sortedEntity = feedReasonTypes.concat().sort(sortPosition);
    return { ...state, feedReasonTypes: sortedEntity, isFetched: true, isFetching: false };
  },
  fail(state: FeedReasonTypeStateType, message: string = ""): FeedReasonTypeStateType {
    return { ...state, isFetched: false, isFetching: false, isError: { message } };
  },
  shouldFetch(state: FeedReasonTypeStateType): FeedReasonTypeStateType {
    return { ...state, isFetched: false };
  },
};
