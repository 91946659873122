import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NavigationService } from "../../../../application/mobile/navigation";
import { FertilizerReportStateService } from "../../../../application/mobile/workReport/fertilizerReportState";
import { NewOrEditStateService } from "../../../../application/mobile/workReport/newOrEditState";
import { getUniqueKey, UniqueKey } from "../../../../domain/entity";
import { Fertilizer } from "../../../../domain/fertilizer";
import { FertilizerReport } from "../../../../domain/fertilizerReport";
import { Pond } from "../../../../domain/pond";
import { NewOrEditWorkReport } from "../../../../domain/workReport";
import { ApplicationState } from "../../../../store/modules";
import { NavigationStateType } from "../../../../store/modules/mobile/navigation/reducer";
import { AddButton } from "../../../components/atoms/Button/Mobile";
import { PageSection } from "../../../components/atoms/Section";
import { Spinner } from "../../../components/atoms/Spinner";
import { MobileList } from "../../../components/molecules/MobileList";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { MobileLayout } from "../../Layout/Mobile";

interface StateProps {
  ponds: Pond[];
  fertilizers: Fertilizer[];
  navigationState: NavigationStateType;
}

interface OwnProps {
  workReport: NewOrEditWorkReport | null;
}

interface DispatchProps {
  newOrEditStateService: NewOrEditStateService;
  navigationService: NavigationService;
  fertilizerReportStateService: FertilizerReportStateService;
}

type WorkReportFertilizerPageProps = StateProps & OwnProps & DispatchProps & RouteComponentProps;

export class Wrapped extends React.Component<WorkReportFertilizerPageProps> {
  public componentWillMount(): void {
    if (this.props.navigationState.selectedPondId === null) {
      this.props.history.push("/");
      return;
    }
  }

  public render() {
    const pond = this.props.ponds.find((p) => p.id === this.props.navigationState.selectedPondId);
    const { workReport } = this.props;

    if (!pond || !workReport) {
      return (
        <MobileLayout>
          <BaseNavigationHeader title={""} onClickReturn={this.handleClickReturn} />
          <BaseNavigationContent>
            <Spinner />
          </BaseNavigationContent>
        </MobileLayout>
      );
    }
    const items = (workReport.fertilizerReports as FertilizerReport[]).map((fr) => {
      const fertilizer = this.props.fertilizers.find((f) => f.id === fr.fertilizerId);
      return { id: getUniqueKey(fr), name: fertilizer ? fertilizer.name : "" };
    });
    return (
      <MobileLayout>
        <BaseNavigationHeader title={pond.name} onClickReturn={this.handleClickReturn} />
        <BaseNavigationContent>
          <PageSection>肥料</PageSection>
          <MobileList itemClassName={"large"} items={items} onClickItem={this.handleClickFertilizer} />
          <AddButton onClick={this.handleClickAddFertilizer} />
        </BaseNavigationContent>
      </MobileLayout>
    );
  }
  private handleClickFertilizer = (key: UniqueKey) => {
    this.props.fertilizerReportStateService.selectFertilizerReport(key);
  };

  private handleClickAddFertilizer = () => {
    this.props.fertilizerReportStateService.addFertilizerReport();
  };

  private handleClickReturn = () => {
    this.props.history.goBack();
  };
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StateProps & OwnProps => {
  const { pond, fertilizer } = state.api;
  return {
    ponds: pond.ponds,
    fertilizers: fertilizer.fertilizers,
    navigationState: state.mobile.navigation,
    workReport: ownProps.workReport,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    newOrEditStateService: new NewOrEditStateService(dispatch),
    navigationService: new NavigationService(dispatch),
    fertilizerReportStateService: new FertilizerReportStateService(dispatch),
  };
};

export const WorkReportFertilizerListPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
