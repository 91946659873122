import { default as moment } from "moment";
import * as React from "react";
import { Header } from "semantic-ui-react";
import styled from "styled-components";
import { AdultCarpReport } from "../../../../domain/adultCarpReport";
import { CarpMovingReport } from "../../../../domain/carpMovingReport";
import { CarpSelectionReport, isReleaseCarpSelectionReport } from "../../../../domain/carpSelectionReport";
import { getUniqueKey } from "../../../../domain/entity";
import { EnvironmentReport } from "../../../../domain/environmentReport";
import { Feed } from "../../../../domain/feed";
import { FeedReasonType, shouldAlertReason } from "../../../../domain/feedReasonType";
import { FeedReport } from "../../../../domain/feedReport";
import { Fertilizer } from "../../../../domain/fertilizer";
import { FertilizerReport } from "../../../../domain/fertilizerReport";
import { Herbicide } from "../../../../domain/herbicide";
import { HerbicideReport } from "../../../../domain/herbicideReport";
import { ParentCarp } from "../../../../domain/parentCarp";
import { Pond } from "../../../../domain/pond";
import { PondDisinfectionReport } from "../../../../domain/pondDisinfectionReport";
import { PondReport } from "../../../../domain/pondReport";
import { ScaleType } from "../../../../domain/scaleType";
import {
  mapAdultCarpReportToAdultSelectionReportVM,
  mapMovingReportToFrySelectionReportVM,
  mapSelectionReportToFrySelectionReportVM,
} from "../../../../domain/selectionReportVM";
import { isFeederUseMethodType, UseMethodType } from "../../../../domain/useMethodType";
import { WorkReport } from "../../../../domain/workReport";
import { COLOR } from "../../../styles/Color";
import { DIMEN } from "../../../styles/Dimen";
import { SelectionAlertIcon } from "../FryPondReportList";
import { MobileListItem } from "../MobileList";

interface ReportListItem {
  date: Date;
  workReports: WorkReport[];
  pondReports: PondReport[];
  environmentReports: EnvironmentReport[];
  ponds: Pond[];
  pairPondReports: PondReport[];
  parentCarps: ParentCarp[];
  feeds: Feed[];
  feedReasonTypes: FeedReasonType[];
  fertilizers: Fertilizer[];
  herbicides: Herbicide[];
  useMethodTypes: UseMethodType[];
  scaleTypes: ScaleType[];
  isDisplayFeedReport: boolean;
  isDisplayFertilizerReport: boolean;
  isDisplayHerbicideReport: boolean;
  isDisplayNoteReport: boolean;
  onClickListItem: () => void;
}

export const ReportListItem = (props: ReportListItem) => {
  return (
    <MobileListItem onClick={props.onClickListItem}>
      <ListFlex>
        <DateHeader>{moment(props.date).format("DD")}</DateHeader>
        <ListContent>
          {props.workReports.map((wr) => (
            <React.Fragment key={wr.id}>
              {props.isDisplayFeedReport && (
                <FeedReportItem
                  feeds={props.feeds}
                  feedReasonTypes={props.feedReasonTypes}
                  useMethodTypes={props.useMethodTypes}
                  feedReports={wr.feedReports as FeedReport[]}
                />
              )}
              {props.isDisplayFertilizerReport && (
                <FertilizerReportItem
                  fertilizers={props.fertilizers}
                  useMethodTypes={props.useMethodTypes}
                  fertilizerReports={wr.fertilizerReports as FertilizerReport[]}
                />
              )}
              {props.isDisplayHerbicideReport && (
                <HerbicideReportItem
                  herbicides={props.herbicides}
                  useMethodTypes={props.useMethodTypes}
                  herbicideReports={wr.herbicideReports as HerbicideReport[]}
                />
              )}
            </React.Fragment>
          ))}
          {props.pondReports.map((pr) => {
            const parentCarp = props.parentCarps.find((pc) => pc.id === pr.parentCarpId);
            const pairing = parentCarp && parentCarp.carpPairings.find((c) => c.id === pr.carpPairingId);
            const pairingName = pairing ? pairing.name : "";
            return (
              <React.Fragment key={pr.id}>
                <PondDisinfectionReportItem
                  date={props.date}
                  pondDisinfectionReports={pr.pondDisinfectionReports as PondDisinfectionReport[]}
                  pairingName={pairingName}
                />
                <CarpSelectionReportItem
                  date={props.date}
                  carpSelectionReports={pr.carpSelectionReports as CarpSelectionReport[]}
                  scaleTypes={props.scaleTypes}
                  pairingName={pairingName}
                />
                <CarpMovingReportItem
                  date={props.date}
                  carpMovingReports={pr.carpMovingReports as CarpMovingReport[]}
                  pairingName={pairingName}
                  ponds={props.ponds}
                  pairPondReports={props.pairPondReports}
                />
                <AdultCarpReportItem date={props.date} adultCarpReports={pr.adultCarpReports as AdultCarpReport[]} />
              </React.Fragment>
            );
          })}
          {props.environmentReports.map((e) => (
            <WaterTemperatureReportItem key={e.id} environmentReport={e} />
          ))}
        </ListContent>
      </ListFlex>
    </MobileListItem>
  );
};

interface FeedReportItemProps {
  feedReports: FeedReport[];
  feeds: Feed[];
  feedReasonTypes: FeedReasonType[];
  useMethodTypes: UseMethodType[];
}
const FeedReportItem = (props: FeedReportItemProps) => {
  return (
    <>
      {props.feedReports.map((fr) => {
        const feed = props.feeds.find((f) => f.id === fr.feedId);
        const feedReasonType = props.feedReasonTypes.find((r) => fr.feedReasonTypeId === r.id);
        const useMethodType = props.useMethodTypes.find((u) => u.id === fr.useMethodTypeId);
        return (
          <ItemGroup key={getUniqueKey(fr)}>
            <ItemType type={"feed"} />
            <ItemTitle>{feed ? feed.name : null}</ItemTitle>
            <ItemDetail>
              {useMethodType && isFeederUseMethodType(useMethodType) && <ItemText>{fr.count}回/日</ItemText>}
              <ItemText>{`${fr.amount} ${useMethodType ? useMethodType.unit : ""}`}</ItemText>
              {feedReasonType && <ItemText>{feedReasonType.name}</ItemText>}
              {feedReasonType && shouldAlertReason(feedReasonType) && <SelectionAlertIcon />}
            </ItemDetail>
          </ItemGroup>
        );
      })}
    </>
  );
};

interface FertilizerReportItemProps {
  fertilizerReports: FertilizerReport[];
  fertilizers: Fertilizer[];
  useMethodTypes: UseMethodType[];
}
const FertilizerReportItem = (props: FertilizerReportItemProps) => {
  return (
    <>
      {props.fertilizerReports.map((fr) => {
        const fertilizer = props.fertilizers.find((f) => f.id === fr.fertilizerId);
        const useMethodType = props.useMethodTypes.find((u) => u.id === fr.useMethodTypeId);
        return (
          <ItemGroup key={getUniqueKey(fr)}>
            <ItemType type={"fertilizer"} />
            <ItemTitle>{fertilizer ? fertilizer.name : ""}</ItemTitle>
            <ItemDetail>
              <ItemText>{`${fr.amount} ${useMethodType ? useMethodType.unit : ""}`}</ItemText>
            </ItemDetail>
          </ItemGroup>
        );
      })}
    </>
  );
};

interface HerbicideReportItemProps {
  herbicideReports: HerbicideReport[];
  herbicides: Herbicide[];
  useMethodTypes: UseMethodType[];
}
const HerbicideReportItem = (props: HerbicideReportItemProps) => {
  return (
    <>
      {props.herbicideReports.map((hr) => {
        const herbicide = props.herbicides.find((h) => h.id === hr.herbicideId);
        const useMethodType = props.useMethodTypes.find((u) => u.id === hr.useMethodTypeId);
        return (
          <ItemGroup key={hr.id}>
            <ItemType type={"herbicide"} />
            <ItemTitle>{herbicide ? herbicide.name : ""}</ItemTitle>
            <ItemDetail>
              <ItemText>{`${hr.amount} ${useMethodType ? useMethodType.unit : ""}`}</ItemText>
            </ItemDetail>
          </ItemGroup>
        );
      })}
    </>
  );
};

interface PondDisinfectionReportItemProps {
  date: Date;
  pondDisinfectionReports: PondDisinfectionReport[];
  pairingName: string;
}

const PondDisinfectionReportItem = (props: PondDisinfectionReportItemProps) => {
  return (
    <>
      {props.pondDisinfectionReports
        .filter((r) => filterByDate(r, props.date))
        .map((pdr) => {
          return (
            <ItemGroup key={pdr.id} direction={"column"}>
              <ItemType type={"fryCarp"} />
              <ItemTitle>{`塩素 ${props.pairingName}`}</ItemTitle>
              <ItemDetail>
                <DetailHeader>
                  <AmountCell>本数</AmountCell>
                  <SizeCell />
                  <QualityCell />
                  <MalformationCell />
                  <RatioCell />
                </DetailHeader>
                <CurrentDetail>
                  <AmountCell>{pdr.amount}本</AmountCell>
                  <SizeCell />
                  <QualityCell />
                  <MalformationCell />
                  <RatioCell />
                </CurrentDetail>
              </ItemDetail>
            </ItemGroup>
          );
        })}
    </>
  );
};

interface CarpSelectionReportItemProps {
  date: Date;
  carpSelectionReports: CarpSelectionReport[];
  scaleTypes: ScaleType[];
  pairingName: string;
}

const CarpSelectionReportItem = (props: CarpSelectionReportItemProps) => {
  return (
    <>
      {props.carpSelectionReports
        .filter((r) => filterByDate(r, props.date))
        .map(mapSelectionReportToFrySelectionReportVM)
        .map((csr) => {
          const size = props.scaleTypes.find((s) => s.id === csr.carpSizeTypeId);
          const quality = props.scaleTypes.find((s) => s.id === csr.carpQualityTypeId);
          const malformation = props.scaleTypes.find((s) => s.id === csr.malformationTypeId);
          const ratio = props.scaleTypes.find((s) => s.id === csr.carpRatioTypeId);
          const isReleaseCarp = isReleaseCarpSelectionReport(csr);

          return (
            <ItemGroup key={getUniqueKey(csr)} direction={"column"}>
              <ItemType type={"fryCarp"} />
              <ItemTitle>{`${csr.name} ${props.pairingName}`}</ItemTitle>
              <ItemDetail>
                <DetailHeader>
                  <AmountCell>匹数</AmountCell>
                  <SizeCell>{!isReleaseCarp && "サイズ"}</SizeCell>
                  <QualityCell>{!isReleaseCarp && "評価"}</QualityCell>
                  <ScoreCell>{!isReleaseCarp && "点数"}</ScoreCell>
                  <MalformationCell>{!isReleaseCarp && "奇形"}</MalformationCell>
                  <RatioCell>{!isReleaseCarp && "数"}</RatioCell>
                </DetailHeader>
                <CurrentDetail>
                  <AmountCell>{csr.amount}匹</AmountCell>
                  <SizeCell>{!isReleaseCarp && (size ? size.name : "")}</SizeCell>
                  <QualityCell>{!isReleaseCarp && (quality ? quality.name : "")}</QualityCell>
                  <ScoreCell>{!isReleaseCarp && csr.carpScore}</ScoreCell>
                  <MalformationCell>{!isReleaseCarp && (malformation ? malformation.name : "")}</MalformationCell>
                  <RatioCell>{!isReleaseCarp && (ratio ? ratio.name : "")}</RatioCell>
                </CurrentDetail>
              </ItemDetail>
            </ItemGroup>
          );
        })}
    </>
  );
};

interface CarpMovingReportItemProps {
  date: Date;
  carpMovingReports: CarpMovingReport[];
  pairingName: string;
  ponds: Pond[];
  pairPondReports: PondReport[];
}

const CarpMovingReportItem = (props: CarpMovingReportItemProps) => {
  return (
    <>
      {props.carpMovingReports
        .filter((r) => filterByDate(r, props.date))
        .map((r) => mapMovingReportToFrySelectionReportVM(r, props.ponds, props.pairPondReports))
        .map((cmr) => {
          return (
            <ItemGroup key={getUniqueKey(cmr)} direction={"column"}>
              <ItemType type={"fryCarp"} />
              <ItemTitle>{`${cmr.name} ${props.pairingName}`}</ItemTitle>
              <ItemDetail>
                <DetailHeader>
                  <AmountCell>匹数</AmountCell>
                  <SizeCell />
                  <QualityCell />
                  <MalformationCell />
                  <RatioCell />
                </DetailHeader>
                <CurrentDetail>
                  <AmountCell>{cmr.amount}匹</AmountCell>
                  <SizeCell />
                  <QualityCell />
                  <MalformationCell />
                  <RatioCell />
                </CurrentDetail>
              </ItemDetail>
            </ItemGroup>
          );
        })}
    </>
  );
};

interface AdultCarpReportItemProps {
  date: Date;
  adultCarpReports: AdultCarpReport[];
}
const AdultCarpReportItem = (props: AdultCarpReportItemProps) => {
  return (
    <>
      {props.adultCarpReports
        .filter((r) => filterByDate(r, props.date))
        .map(mapAdultCarpReportToAdultSelectionReportVM)
        .map((acr) => {
          return (
            <ItemGroup key={getUniqueKey(acr)}>
              <ItemType type={"adultCarp"} />
              <ItemTitle>{acr.name}</ItemTitle>
              <ItemDetail>
                <ItemText>{acr.amount}匹</ItemText>
              </ItemDetail>
            </ItemGroup>
          );
        })}
    </>
  );
};

interface WaterTemperatureReportItemProps {
  environmentReport: EnvironmentReport;
}

const WaterTemperatureReportItem = (props: WaterTemperatureReportItemProps) => {
  return (
    <ItemGroup>
      <ItemType type={"temperature"} />
      <ItemTitle>水温</ItemTitle>
      <ItemDetail>
        <ItemText>{props.environmentReport.value}℃</ItemText>
      </ItemDetail>
    </ItemGroup>
  );
};

const filterByDate = (report: { date: Date | null }, date: Date): boolean => {
  const reportDate = moment(report.date || "invalid_date");
  return moment(date).isSame(reportDate, "date");
};

const ListFlex = styled.div`
  display: flex;
  align-items: center;
`;
const DateHeader = styled(Header).attrs({ as: "h3" })`
  width: ${DIMEN.X4};
  height: 100%;
  margin: 0 !important;
`;
const ListContent = styled.div`
  width: calc(100% - ${DIMEN.X4});
`;
const ItemGroup = styled.div`
  padding: ${DIMEN.X0_5} 0;
  padding-left: ${DIMEN.X1_5};
  position: relative;
  display: flex;
  flex-direction: ${(props: { direction?: "row" | "column" }) => (props.direction ? props.direction : "row")};
`;
const ItemType = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  margin: 0 ${DIMEN.X0_5};
  height: 100%;
  width: ${DIMEN.X0_5};
  background-color: ${(props: {
    type: "feed" | "fertilizer" | "herbicide" | "temperature" | "fryCarp" | "adultCarp";
  }) => {
    if (props.type === "feed") {
      return COLOR.ReportColor.Feed;
    }
    if (props.type === "fertilizer") {
      return COLOR.ReportColor.Fertilizer;
    }
    if (props.type === "herbicide") {
      return COLOR.ReportColor.Herbicide;
    }
    if (props.type === "temperature") {
      return COLOR.ReportColor.Temperature;
    }
    if (props.type === "fryCarp") {
      return COLOR.ReportColor.FryCarp;
    }
    if (props.type === "adultCarp") {
      return COLOR.ReportColor.AdultCarp;
    }
    return "";
  }};
`;

const ItemText = styled.span`
  margin-right: 0.5em;
`;

const ItemTitle = styled.div`
  font-weight: 700;
`;
const ItemDetail = styled.div`
  margin-left: 0.5em;
  flex: 1 1;
  max-width: calc(4em * 5);
`;

const DetailHeader = styled.div`
  display: flex;
  color: ${COLOR.Gray["darken-1"]};
  font-size: 12px;
  margin-bottom: ${DIMEN.X0_5};
  > div {
    padding: ${DIMEN.X0_5} 0;
    border-bottom: 1px solid ${COLOR.Gray["darken-1"]};
  }
`;
const CurrentDetail = styled.div`
  display: flex;
`;
const BaseCell = styled.div`
  flex: 1 1 0;
  text-align: center;
`;
const AmountCell = styled(BaseCell)`
  flex: 1 1 4rem;
`;
const SizeCell = styled(BaseCell)`
  min-width: 36px;
`;
const QualityCell = styled(BaseCell)``;
const ScoreCell = styled(BaseCell)``;
const MalformationCell = styled(BaseCell)``;
const RatioCell = styled(BaseCell)``;
