import styled from "styled-components";
import { COLOR } from "../../../styles/Color";
import { TableCell } from "../../atoms/Table";

export const CellTitle = styled.span`
  color: ${COLOR.AccentColor.base};
  cursor: pointer;
`;
export const RowHeaderTableCell = styled(TableCell)`
  width: 160px;
`;

export const DateCell = styled.div`
  width: 9em;
`;
export const AmountCell = styled.div`
  width: 6em;
  &.text-center {
    text-align: center;
  }
`;
export const SizeCell = styled.div`
  width: 6em;
`;
export const MalformationCell = styled.div`
  width: 5em;
`;
export const RatioCell = styled.div`
  width: 6em;
`;

export const NoteCell = styled.div`
  width: 12em;
`;
