import * as _ from "lodash";
import * as React from "react";

interface AutoSizerProps {
  children: (props: { width: number; height: number }) => any;
}

interface AutoSizerState {
  width: number;
  height: number;
}

export class AutoSizer extends React.Component<AutoSizerProps, AutoSizerState> {
  private container: HTMLDivElement | null = null;
  private readonly debounceWindowResizeHandler: () => void;

  constructor(props: AutoSizerProps) {
    super(props);

    this.state = {
      width: 0,
      height: 0,
    };

    this.debounceWindowResizeHandler = _.debounce(this.handleWindowResize, 100);
  }

  public componentWillMount(): void {
    window.addEventListener("resize", this.debounceWindowResizeHandler);
  }

  public componentDidMount(): void {
    this.updateSize();
  }

  public componentDidUpdate(): void {
    this.updateSize();
  }

  public componentWillUnmount(): void {
    window.removeEventListener("resize", this.debounceWindowResizeHandler);
  }

  public render() {
    return (
      <div ref={(node) => (this.container = node)} style={{ width: "100%", height: "100%" }}>
        {this.props.children(this.state)}
      </div>
    );
  }

  private shouldUpdateSize = (): boolean => {
    if (this.container === null) {
      return false;
    }
    const rect = this.container.getBoundingClientRect();
    const { width, height } = rect;
    return width !== this.state.width || height !== this.state.height;
  };

  private updateSize = () => {
    if (this.container === null || !this.shouldUpdateSize()) {
      return;
    }
    const rect = this.container.getBoundingClientRect();
    const { width, height } = rect;
    this.setState({ width, height });
  };

  private handleWindowResize = () => {
    this.updateSize();
  };
}
