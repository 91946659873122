import { actionCreatorFactory } from "typescript-fsa";
import { EditWorker, NewOrEditWorker, NewWorker, Worker } from "../../../../../domain/worker";
// Actions
const actionCreator = actionCreatorFactory("MasterWorkerNewOrEditState");

const createWorker = actionCreator<CreateWorkerPayload>("createWorker");
interface CreateWorkerPayload {
  worker: NewWorker;
}
const selectWorker = actionCreator<SelectWorkerPayload>("selectWorker");
interface SelectWorkerPayload {
  worker: EditWorker;
}

const changeWorker = actionCreator<ChangeWorkerPayload>("changeWorker");
interface ChangeWorkerPayload {
  key: keyof NewOrEditWorker;
  value: any;
}

export const cancelWorker = actionCreator<CancelWorkerPayload>("cancelWorker");
interface CancelWorkerPayload {
  worker: NewOrEditWorker;
}

export const saveStart = actionCreator("saveStart");
export const saveSuccess = actionCreator<SaveSuccessPayload>("saveSuccess");
interface SaveSuccessPayload {
  worker: Worker | null;
}
export const saveFail = actionCreator("saveFail");

export const masterWorkerNewOrEditStateActions = {
  createWorker,
  selectWorker,
  changeWorker,
  cancelWorker,
  saveStart,
  saveSuccess,
  saveFail,
};

// Reducers
import { ChangeHandler, getInitialState, InitialHandler, NewOrEditStateType, SaveHandler } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const NewOrEditStateReducer = reducerWithInitialState<NewOrEditStateType>(getInitialState())
  .case(createWorker, (state, { worker }) => {
    return InitialHandler.createWorker(state, worker);
  })
  .case(selectWorker, (state, { worker }) => {
    return InitialHandler.selectWorker(state, worker);
  })
  .case(changeWorker, (state, { key, value }) => {
    return ChangeHandler.changeWorker(state, key, value);
  })
  .case(cancelWorker, (state, { worker }) => {
    return SaveHandler.cancelWorker(state, worker);
  })
  .case(saveStart, (state) => {
    return SaveHandler.saveStart(state);
  })
  .case(saveSuccess, (state, { worker }) => {
    return SaveHandler.saveSuccess(state, worker);
  })
  .case(saveFail, (state) => {
    return SaveHandler.saveFail(state);
  });
