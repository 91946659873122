import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NavigationService } from "../../../../application/mobile/navigation";
import {
  EnvironmentReport,
  getEnvironmentReportsFromDate,
  NewOrEditEnvironmentReport,
} from "../../../../domain/environmentReport";
import { Pond } from "../../../../domain/pond";
import { ApplicationState } from "../../../../store/modules";
import { NavigationStateType } from "../../../../store/modules/mobile/navigation/reducer";
import { GraphPeriodType } from "../../../../store/modules/mobile/reportListState/reducer";
import { Spinner } from "../../../components/atoms/Spinner";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { ReportListTemperatureContent } from "../../../components/molecules/ReportListTemperatureContent";
import { MobileLayout } from "../../Layout/Mobile";

interface StateProps {
  ponds: Pond[];
  environmentReports: EnvironmentReport[];

  navigationState: NavigationStateType;
  isLoaded: boolean;
}

interface DispatchProps {
  navigationService: NavigationService;
}

type EnvironmentReportDetailPageProps = StateProps & DispatchProps & RouteComponentProps;

export class Wrapped extends React.Component<EnvironmentReportDetailPageProps> {
  public componentWillMount(): void {
    if (this.props.navigationState.selectedPondId === null) {
      this.props.history.push("/");
      return;
    }
    if (!this.props.isLoaded) {
      this.props.history.push("/reports/menu");
      return;
    }
    this.props.navigationService.fetchEnvironmentReports();
  }

  public render() {
    const pond = this.props.ponds.find((p) => p.id === this.props.navigationState.selectedPondId);

    if (!pond) {
      return this.renderEmpty();
    }

    return (
      <MobileLayout>
        <BaseNavigationHeader title={pond.name} onClickReturn={this.handleClickReturn} />
        <BaseNavigationContent>
          <ReportListTemperatureContent
            environmentReports={this.filterEnvironmentReportsForGraph()}
            selectedDate={this.props.navigationState.selectedDate}
            activePeriodType={this.props.navigationState.graphPeriodType}
            onClickPeriod={this.handleClickPeriodType}
          />
        </BaseNavigationContent>
      </MobileLayout>
    );
  }

  private renderEmpty = () => {
    return (
      <MobileLayout>
        <BaseNavigationHeader title={""} onClickReturn={this.handleClickReturn} />
        <BaseNavigationContent>
          <Spinner />
        </BaseNavigationContent>
      </MobileLayout>
    );
  };

  private filterEnvironmentReportsForGraph = (): NewOrEditEnvironmentReport[] => {
    const { selectedPondId, selectedDate, graphPeriodType } = this.props.navigationState;
    if (selectedPondId === null) {
      return [];
    }
    const reports = this.props.environmentReports.filter((e) => e.pondId === selectedPondId);
    return getEnvironmentReportsFromDate(reports, selectedPondId, selectedDate, graphPeriodType);
  };

  private handleClickPeriodType = (periodType: GraphPeriodType) => {
    this.props.navigationService.changePeriodType(periodType);
  };

  private handleClickReturn = () => {
    this.props.history.goBack();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { environmentReport, pond } = state.api;
  return {
    ponds: pond.ponds,
    environmentReports: environmentReport.environmentReports,

    navigationState: state.mobile.navigation,
    isLoaded: pond.isFetched,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    navigationService: new NavigationService(dispatch),
  };
};

export const EnvironmentReportDetailPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
