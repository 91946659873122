import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { NewStateService } from "../../../application/master/worker/newState";
import { ApplicationState } from "../../../store/modules";
import { NewOrEditStateType } from "../../../store/modules/master/worker/newOrEditState/reducer";
import { WorkerForm } from "../../components/organisms/WorkerForm";

interface StateProps {
  newOrEditState: NewOrEditStateType;
}

interface DispatchProps {
  newStateService: NewStateService;
}

type WorkerNewPageProps = StateProps & DispatchProps;

export class Wrapped extends React.Component<WorkerNewPageProps> {
  public render() {
    const { worker, isSaving } = this.props.newOrEditState;
    return (
      <WorkerForm
        worker={worker}
        isSaving={isSaving}
        onChangeName={this.handleChangeName}
        onChangeAccountName={this.handleChangeAccountName}
        onChangePassword={this.handleChangePassword}
        onClickSave={this.handleClickSaveWorker}
        onClickCancel={this.handleClickCancelWorker}
        onClickAdd={this.handleClickAddWorker}
        onClickDelete={null}
      />
    );
  }

  private handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.newStateService.changeName(e.target.value);
  };

  private handleChangeAccountName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.newStateService.changeAccountName(e.target.value);
  };

  private handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.newStateService.changePassword(e.target.value);
  };

  private handleClickSaveWorker = () => {
    this.props.newStateService.saveWorker();
  };

  private handleClickCancelWorker = () => {
    this.props.newStateService.cancelWorker();
  };

  private handleClickAddWorker = () => {
    this.props.newStateService.createWorker();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { newOrEditState } = state.master.worker;
  return {
    newOrEditState,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    newStateService: new NewStateService(dispatch),
  };
};

export const WorkerNewPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);
