import * as React from "react";
import { Header } from "semantic-ui-react";
import styled from "styled-components";
import { COLOR } from "../../../styles/Color";
import { DIMEN } from "../../../styles/Dimen";
import { Form } from "../../atoms/Form/Form";
import { FormPasswordInput, FormTextInput } from "../../atoms/Form/Input";
import { Spinner } from "../../atoms/Spinner";

interface LoginFormProps {
  accountName: string;
  password: string;
  onChangeAccountName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickLogin: () => void;
  isLoggingIn: boolean;
}

export const LoginForm = ({
  accountName,
  password,
  onChangeAccountName,
  onChangePassword,
  onClickLogin,
  isLoggingIn,
}: LoginFormProps) => {
  return (
    <Outer>
      {isLoggingIn && <Spinner />}
      <Form>
        <FormOuter>
          <Message>ログインしてください</Message>
          <FormTextInput label={"アカウント"} value={accountName} onChange={onChangeAccountName} />
          <FormPasswordInput label={"パスワード"} value={password} onChange={onChangePassword} />
        </FormOuter>
        <LoginButtonGroup>
          <LoginButton onClick={onClickLogin}>ログイン</LoginButton>
        </LoginButtonGroup>
      </Form>
    </Outer>
  );
};

const Outer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${COLOR.Gray["lighter-1"]};
  padding: ${DIMEN.X4} ${DIMEN.X2} 0;
`;

const Message = styled(Header).attrs({ as: "h2" })`
  text-align: center;
  margin: ${DIMEN.X4} 0 !important;
`;

const FormOuter = styled.div`
  background-color: ${COLOR.Base.white};
  padding: ${DIMEN.X4} ${DIMEN.X2};
  max-width: 380px;
  margin: 0 auto;
`;

const LoginButtonGroup = styled.div`
  text-align: center;
  margin: ${DIMEN.X2} auto 0;
  max-width: 380px;
`;

const LoginButton = styled.button`
  background-color: ${COLOR.Primary.base};
  color: ${COLOR.Base.white};
  padding: ${DIMEN.X1_5};
  line-height: 1;
  outline: none;
  width: 100%;
`;
