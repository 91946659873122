import * as React from "react";
import { Feed } from "../../../../domain/feed";
import { FeedReasonType } from "../../../../domain/feedReasonType";
import { PondType } from "../../../../domain/pondType";
import { UseMethodType } from "../../../../domain/useMethodType";
import { Form } from "../../atoms/Form/Form";
import { Spinner } from "../../atoms/Spinner";
import { ReportFormButton } from "../../molecules/ReportFormButton";
import { WorkReportFormDateBody } from "../../molecules/WorkReportFormDate";
import { WorkReportFormFeedBody } from "../../molecules/WorkReportFormFeed";

interface WorkReportFormFeedProps {
  title: string;
  date: Date;
  feeds: Feed[];
  feedReasonTypes: FeedReasonType[];
  useMethodTypes: UseMethodType[];
  pondTypes: PondType[];
  feedId: null | number;
  feedReasonTypeId: null | number;
  count: string;
  amount: string;
  useMethodTypeId: null | number;
  note: string;
  imageIds: number[];
  pondTypeId: number;
  onChangeDate: (date: Date) => void;
  onChangeFeed: (id: null | number) => void;
  onChangeFeedReasonType: (id: null | number) => void;
  onChangeCount: (count: string) => void;
  onChangeAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickAmountUp: () => void;
  onClickAmountDown: () => void;
  onChangeNote: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickImage: (imageIds: number[], imageId: number) => void;
  onClickImageRemove: (id: number) => void;
  onChangeFeederUseMethodType: () => void;
  onChangeBucketUseMethodType: () => void;
  onChangePondType: ((id: number | null) => void) | null;
  onClickSave: () => void;
  onClickCancel: () => void;
  onClickDelete: (() => void) | null;
  isSaving: boolean;
  disabledSave: boolean;
}
export const WorkReportFormFeed = (props: WorkReportFormFeedProps) => {
  return (
    <Form>
      {props.isSaving && <Spinner />}
      <WorkReportFormDateBody
        title={props.title}
        date={props.date}
        onChangeDate={props.onChangeDate}
        onClickAdd={null}
        onClickDelete={props.onClickDelete}
      />
      <WorkReportFormFeedBody {...props} />
      <ReportFormButton
        onClickSave={props.onClickSave}
        onClickCancel={props.onClickCancel}
        disabledSave={props.disabledSave}
      />
    </Form>
  );
};
