import { actionCreatorFactory } from "typescript-fsa";
import { UniqueKey } from "../../../../../domain/entity";
import { NewFeedReport, NewOrEditFeedReport } from "../../../../../domain/feedReport";
import { NewFertilizerReport, NewOrEditFertilizerReport } from "../../../../../domain/fertilizerReport";
import { NewHerbicideReport, NewOrEditHerbicideReport } from "../../../../../domain/herbicideReport";
import { NewOrEditWorkReport, WorkReport } from "../../../../../domain/workReport";

// Actions
const actionCreator = actionCreatorFactory("MobileWorkReportNewOrEditState");

const initialWorkReport = actionCreator<InitialWorkReportPayload>("initialWorkReport");
interface InitialWorkReportPayload {
  workReport: NewOrEditWorkReport;
}
const resetWorkReport = actionCreator<ResetWorkReportPayload>("resetWorkReport");
interface ResetWorkReportPayload {}

const selectAnyReport = actionCreator<SelectAnyReportPayload>("selectAnyReport");
interface SelectAnyReportPayload {
  selectedReportKey: "feed" | "fertilizer" | "herbicide";
  selectedUniqueKey: UniqueKey;
}

const changeWorkReport = actionCreator<ChangeWorkReportPayload>("changeWorkReport");
interface ChangeWorkReportPayload {
  key: keyof NewOrEditWorkReport;
  value: any;
}
const changeFeedReport = actionCreator<ChangeFeedReportPayload>("changeFeedReport");
interface ChangeFeedReportPayload {
  feedUniqueKey: UniqueKey;
  key: keyof NewOrEditFeedReport;
  value: any;
}
const changeFertilizerReport = actionCreator<ChangeFertilizerReportPayload>("changeFertilizerReport");
interface ChangeFertilizerReportPayload {
  fertilizerUniqueKey: UniqueKey;
  key: keyof NewOrEditFertilizerReport;
  value: any;
}
const changeHerbicideReport = actionCreator<ChangeHerbicideReportPayload>("changeHerbicideReport");
interface ChangeHerbicideReportPayload {
  herbicideUniqueKey: UniqueKey;
  key: keyof NewOrEditHerbicideReport;
  value: any;
}

const addImage = actionCreator<AddImagePayload>("addImage");
interface AddImagePayload {
  imageId: number;
}
const removeImage = actionCreator<RemoveImagePayload>("removeImage");
interface RemoveImagePayload {
  imageId: number;
}

const addFeedReport = actionCreator<AddFeedReportPayload>("addFeedReport");
interface AddFeedReportPayload {
  feedReport: NewFeedReport;
}
const addFertilizerReport = actionCreator<AddFertilizerReportPayload>("addFertilizerReport");
interface AddFertilizerReportPayload {
  fertilizerReport: NewFertilizerReport;
}
const addHerbicideReport = actionCreator<AddHerbicideReportPayload>("addHerbicideReport");
interface AddHerbicideReportPayload {
  herbicideReport: NewHerbicideReport;
}
const removeFeedReport = actionCreator("removeFeedReport");
const removeFertilizerReport = actionCreator("removeFertilizerReport");
const removeHerbicideReport = actionCreator("removeHerbicideReport");

// save
const saveStart = actionCreator("saveStart");
const saveSuccess = actionCreator<SaveSuccessPayload>("saveSuccess");
interface SaveSuccessPayload {
  workReport: WorkReport;
}
const saveFail = actionCreator("saveFail");

export const workReportNewOrEditStateActions = {
  initialWorkReport,
  resetWorkReport,
  selectAnyReport,
  changeWorkReport,
  changeFeedReport,
  changeFertilizerReport,
  changeHerbicideReport,
  addImage,
  addFeedReport,
  addFertilizerReport,
  addHerbicideReport,
  removeImage,
  removeFeedReport,
  removeFertilizerReport,
  removeHerbicideReport,
  saveStart,
  saveSuccess,
  saveFail,
};

// Reducers
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { ChangeHandler, getInitialState, InitialHandler, NewOrEditStateType, SaveHandler } from "./reducer";

export const workReportNewOrEditStateReducer = reducerWithInitialState<NewOrEditStateType>(getInitialState())
  .case(initialWorkReport, (state, { workReport }) => InitialHandler.initialWorkReport(state, workReport))
  .case(resetWorkReport, (state) => InitialHandler.resetWorkReport(state))
  .case(selectAnyReport, (state, { selectedReportKey, selectedUniqueKey }) =>
    InitialHandler.selectAnyReport(state, selectedReportKey, selectedUniqueKey)
  )
  .case(changeWorkReport, (state, { key, value }) => ChangeHandler.changeWorkReport(state, key, value))
  .case(changeFeedReport, (state, { feedUniqueKey, key, value }) =>
    ChangeHandler.changeFeedReport(state, feedUniqueKey, key, value)
  )
  .case(changeFertilizerReport, (state, { fertilizerUniqueKey, key, value }) =>
    ChangeHandler.changeFertilizerReport(state, fertilizerUniqueKey, key, value)
  )
  .case(changeHerbicideReport, (state, { herbicideUniqueKey, key, value }) =>
    ChangeHandler.changeHerbicideReport(state, herbicideUniqueKey, key, value)
  )
  .case(addImage, (state, { imageId }) => ChangeHandler.addImage(state, imageId))
  .case(addFeedReport, (state, { feedReport }) => ChangeHandler.addFeedReport(state, feedReport))
  .case(addFertilizerReport, (state, { fertilizerReport }) =>
    ChangeHandler.addFertilizerReport(state, fertilizerReport)
  )
  .case(removeImage, (state, { imageId }) => ChangeHandler.removeImage(state, imageId))
  .case(removeFeedReport, (state) => ChangeHandler.removeFeedReport(state))
  .case(removeFertilizerReport, (state) => ChangeHandler.removeFertilizerReport(state))
  .case(removeHerbicideReport, (state) => ChangeHandler.removeHerbicideReport(state))
  .case(addHerbicideReport, (state, { herbicideReport }) => ChangeHandler.addHerbicideReport(state, herbicideReport))
  .case(saveStart, (state) => SaveHandler.saveStart(state))
  .case(saveSuccess, (state, { workReport }) => SaveHandler.saveSuccess(state, workReport))
  .case(saveFail, (state) => SaveHandler.saveFail(state));
