import * as React from "react";
import { NewOrEditPond } from "../../../../domain/pond";
import { PondArea } from "../../../../domain/pondArea";
import { PondOwnerType } from "../../../../domain/pondOwnerType";
import { isFryPondType, PondType } from "../../../../domain/pondType";
import { Worker } from "../../../../domain/worker";
import { Form, FormGroup } from "../../atoms/Form/Form";
import { FormCheckbox } from "../../atoms/Form/FormCheckbox";
import { FormNumberInput, FormTextInput } from "../../atoms/Form/Input";
import { FormSelect, FormSelectMulti } from "../../atoms/Form/Select";
import { Spinner } from "../../atoms/Spinner";
import { MasterForm } from "../../molecules/MasterForm";
import { MasterFormButton } from "../../molecules/MasterFormButton";

interface PondFormProps {
  pond: NewOrEditPond | null;
  pondAreas: PondArea[];
  pondTypes: PondType[];
  pondOwnerTypes: PondOwnerType[];
  workers: Worker[];
  isSaving: boolean;
  onChangePondArea: (pondAreaId: number | null) => void;
  onChangeName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePosition: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePondType: (typeId: null | number) => void;
  onChangePondOwnerTypes: (ownerTypeIds: number[]) => void;
  onChangeWorkers: (workerIds: number[]) => void;
  onChangeIsUnused: (checked: boolean) => void;
  onClickSave: () => void;
  onClickCancel: () => void;
  onClickAdd: (() => void) | null;
  onClickDelete: (() => void) | null;
}

export class PondForm extends React.Component<PondFormProps> {
  public render() {
    const {
      pondAreas,
      pond,
      pondTypes,
      pondOwnerTypes,
      workers,
      isSaving,
      onChangePondArea,
      onChangeName,
      onChangePosition,
      onChangePondType,
      onChangePondOwnerTypes,
      onChangeWorkers,
      onChangeIsUnused,
      onClickSave,
      onClickCancel,
      onClickAdd,
      onClickDelete,
    } = this.props;

    if (pond === null) {
      return <Spinner />;
    }

    const selectedPondType = pondTypes.find((t) => t.id === pond.pondTypeId);
    const isFry = !!selectedPondType ? isFryPondType(selectedPondType) : false;

    return (
      <MasterForm onClickAdd={onClickAdd} onClickDelete={onClickDelete}>
        {isSaving && <Spinner />}
        <Form>
          <FormSelect
            value={pond.pondAreaId === null ? NOT_SELECTED_ID : pond.pondAreaId}
            label={"エリア"}
            options={getPondAreaOptions(pondAreas)}
            onChange={onChangePondAreaOption(onChangePondArea)}
          />
          <FormTextInput label={"野池名"} value={pond.name} onChange={onChangeName} />
          <FormSelectMulti selectedIds={pond.userIds} label={"担当者"} options={workers} onChange={onChangeWorkers} />
          <FormSelect
            value={pond.pondTypeId === null ? NOT_SELECTED_ID : pond.pondTypeId}
            label={"池の区分"}
            options={getPondTypeOptions(pondTypes)}
            onChange={onChangePondTypeOptions(onChangePondType)}
          />
          <FormSelectMulti
            disabled={isFry}
            selectedIds={pond.pondOwnerTypeIds}
            label={"鯉の所有者"}
            options={pondOwnerTypes}
            onChange={onChangePondOwnerTypes}
          />
          <FormNumberInput
            label={"並び順"}
            value={pond.position === null ? "" : "" + pond.position}
            onChange={onChangePosition}
          />
          <FormGroup grouped>
            <label>未使用</label>
            <FormCheckbox label={"未使用にする"} checked={pond.isUnused} onChange={onChangeIsUnused} />
          </FormGroup>
          <MasterFormButton onClickSave={onClickSave} onClickCancel={onClickCancel} />
        </Form>
      </MasterForm>
    );
  }
}

const NOT_SELECTED_ID = -1;

function getPondTypeOptions(pondTypes: PondType[]): Array<{ id: number; name: string }> {
  const options = pondTypes.map((type) => ({ id: type.id, name: type.name }));
  return [{ id: NOT_SELECTED_ID, name: "　" }].concat(options);
}

const onChangePondTypeOptions = (onChange: (id: number | null) => void) => (pondTypeId: number | null) => {
  const selectedId = pondTypeId === NOT_SELECTED_ID ? null : pondTypeId;
  onChange(selectedId);
};

function getPondAreaOptions(pondAreas: PondArea[]): Array<{ id: number; name: string }> {
  return pondAreas
    .map((area) => ({ id: area.id, name: area.name }))
    .concat({ id: NOT_SELECTED_ID, name: "未割り当て" });
}

const onChangePondAreaOption = (onChange: (id: number | null) => void) => (pondAreaId: number | null) => {
  const selectedId = pondAreaId === NOT_SELECTED_ID ? null : pondAreaId;
  onChange(selectedId);
};
