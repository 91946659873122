import * as React from "react";
import { NewOrEditAdultCarpReport } from "../../../../domain/adultCarpReport";
import { getUniqueKey, UniqueKey } from "../../../../domain/entity";
import { AdultSelectionReportVM, ToAdultSelectionReportVM } from "../../../../domain/selectionReportVM";
import { FormDatePicker } from "../../atoms/Form/DatePicker";
import { FormNumberInput, FormTextInput } from "../../atoms/Form/Input";
import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "../../atoms/Table";
import { AmountCell, DateCell, RowHeaderTableCell } from "./elements";

interface AdultCarpReportTableProps {
  adultCarpReports: NewOrEditAdultCarpReport[];
  onChangeAdultCarpReport: (adultUid: UniqueKey, key: keyof NewOrEditAdultCarpReport, value: any) => void;
}

export class AdultCarpReportTable extends React.Component<AdultCarpReportTableProps> {
  public render() {
    const reports: AdultSelectionReportVM[] = ToAdultSelectionReportVM(this.props.adultCarpReports);

    return (
      <Table definition={true}>
        <TableHeader>
          <TableRow>
            <TableHeaderCell />
            <TableHeaderCell>日付</TableHeaderCell>
            <TableHeaderCell>匹数</TableHeaderCell>
            <TableHeaderCell>メモ</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {reports.map((report) => {
            const adultUniqueKey = getUniqueKey(report);
            return (
              <AdultTableRow
                key={adultUniqueKey}
                cellTitle={report.name}
                date={report.date}
                amount={report.amount}
                note={report.note}
                onChangeDate={this.handleChangeDateOfAdultReport(adultUniqueKey)}
                onChangeAmount={this.handleChangeAmountOfAdultReport(adultUniqueKey)}
                onChangeNote={this.handleChangeNoteOfAdultReport(adultUniqueKey)}
              />
            );
          })}
        </TableBody>
      </Table>
    );
  }

  /*** AdultReport ***/
  private handleChangeDateOfAdultReport = (adultUniqueKey: UniqueKey) => (date: Date) => {
    this.props.onChangeAdultCarpReport(adultUniqueKey, "date", date);
  };
  private handleChangeAmountOfAdultReport = (adultUniqueKey: UniqueKey) => (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChangeAdultCarpReport(adultUniqueKey, "amount", e.target.value);
  };

  private handleChangeNoteOfAdultReport = (adultUniqueKey: UniqueKey) => (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChangeAdultCarpReport(adultUniqueKey, "note", e.target.value);
  };
}

interface AdultRowProps {
  cellTitle: string;
  date: Date | null;
  amount: string;
  note: string;
  onChangeDate: (date: Date) => void;
  onChangeAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeNote: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AdultTableRow = (props: AdultRowProps) => {
  const { cellTitle, date, amount, note } = props;
  return (
    <TableRow>
      <RowHeaderTableCell>{cellTitle}</RowHeaderTableCell>
      <TableCell>
        <DateCell>
          <FormDatePicker label={""} date={date} onChange={props.onChangeDate} />
        </DateCell>
      </TableCell>
      <TableCell>
        <AmountCell>
          <FormNumberInput label={""} value={amount} onChange={props.onChangeAmount} />
        </AmountCell>
      </TableCell>
      <TableCell>
        <FormTextInput label={""} value={note} onChange={props.onChangeNote} />
      </TableCell>
    </TableRow>
  );
};
