import { actionCreatorFactory } from "typescript-fsa";
import { PondReport } from "../../../../../domain/pondReport";

// Actions
const actionCreator = actionCreatorFactory("ReportPondReportPairReportModalState");

const openModal = actionCreator("openModal");
const closeModal = actionCreator("closeModal");
const selectPondReport = actionCreator<SelectPondReportPayload>("selectPondReport");
interface SelectPondReportPayload {
  pondReport: PondReport;
}

export const reportPondReportPairReportModalStateActions = {
  openModal,
  closeModal,
  selectPondReport,
};

// Reducers
import { ChangeHandler, getInitialState, InitialHandler, PairReportModalStateType } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const PairReportModalStateReducer = reducerWithInitialState<PairReportModalStateType>(getInitialState())
  .case(openModal, () => {
    return InitialHandler.openModal();
  })
  .case(closeModal, () => {
    return InitialHandler.closeModal();
  })
  .case(selectPondReport, (state, { pondReport }) => {
    return ChangeHandler.selectPondReport(state, pondReport);
  });
