import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NavigationService } from "../../../../application/mobile/navigation";
import { FeedReportStateService } from "../../../../application/mobile/workReport/feedReportState";
import { NewOrEditStateService } from "../../../../application/mobile/workReport/newOrEditState";
import { getUniqueKey } from "../../../../domain/entity";
import { Pond } from "../../../../domain/pond";
import { NewOrEditWorkReport, WorkReport } from "../../../../domain/workReport";
import { ApplicationState } from "../../../../store/modules";
import { NavigationStateType } from "../../../../store/modules/mobile/navigation/reducer";
import { NewOrEditStateType } from "../../../../store/modules/mobile/workReport/newOrEditState/reducer";
import { Spinner } from "../../../components/atoms/Spinner";
import { ActionsModal } from "../../../components/molecules/ActionsModal";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { NotificationModal } from "../../../components/molecules/NotificationModal";
import { MobileLayout } from "../../Layout/Mobile";
import { WorkReportFeedNewOrEditPage } from "./NewOrEdit";

interface StateProps {
  ponds: Pond[];
  workReports: WorkReport[];
  navigationState: NavigationStateType;
  newOrEditState: NewOrEditStateType;
  isLoaded: boolean;
}

interface DispatchProps {
  newOrEditStateService: NewOrEditStateService;
  navigationService: NavigationService;
  feedReportStateService: FeedReportStateService;
}

type WorkReportFeedPageProps = StateProps & DispatchProps & RouteComponentProps;
interface WorkReportFeedPageState {
  isOpenNotificationModal: boolean;
  isOpenActionModal: boolean;
  notifiedMessage: string;
}

export class Wrapped extends React.Component<WorkReportFeedPageProps, WorkReportFeedPageState> {
  constructor(props: WorkReportFeedPageProps) {
    super(props);

    this.state = {
      isOpenActionModal: false,
      isOpenNotificationModal: false,
      notifiedMessage: "",
    };
  }

  public async componentWillMount() {
    if (this.props.navigationState.selectedPondId === null) {
      this.props.history.push("/");
      return;
    }
    if (!this.props.isLoaded) {
      this.props.history.push("/reports/menu");
      return;
    }
    await this.props.newOrEditStateService.initWorkReport();

    this.props.feedReportStateService.fetchApi().then(() => {
      const { workReport } = this.props.newOrEditState;
      const feedReportsCount = !!workReport ? workReport.feedReports.length : null;
      if (feedReportsCount === 0) {
        this.props.feedReportStateService.addFeedReport();
      } else if (feedReportsCount === 1) {
        const feedReport = !!workReport && workReport.feedReports[0];
        if (!!feedReport) {
          this.props.feedReportStateService.selectFeedReport(getUniqueKey(feedReport));
        }
      }
    });
  }

  public render() {
    const { workReport, selectedReportKey, selectedUniqueKey, isSaving } = this.props.newOrEditState;
    const pond = this.props.ponds.find((p) => p.id === this.props.navigationState.selectedPondId);
    const feedReport =
      selectedReportKey === "feed" &&
      !!workReport &&
      workReport.feedReports.find((fr) => getUniqueKey(fr) === selectedUniqueKey);

    if (!pond || !workReport) {
      return this.renderEmpty();
    }
    if (!feedReport) {
      if (workReport.feedReports.length === 0) {
        return this.renderEmpty();
      }
      return this.renderWorkReportFeedListPage(workReport);
    }

    return (
      <>
        <WorkReportFeedNewOrEditPage
          workReport={workReport}
          feedReport={feedReport}
          isSaving={isSaving}
          onClickSave={this.handleClickSave}
          openActionModal={this.openActionModal}
        />
        {this.renderModal()}
      </>
    );
  }

  private renderEmpty = () => {
    return (
      <MobileLayout>
        <BaseNavigationHeader title={""} onClickMenu={this.handleClickMenu} />
        <BaseNavigationContent>
          <Spinner />
          {this.renderModal()}
        </BaseNavigationContent>
      </MobileLayout>
    );
  };

  private renderWorkReportFeedListPage = (__: NewOrEditWorkReport) => {
    // 餌はリスト表示は不要
    // return <WorkReportFeedListPage workReport={workReport} />;
    return this.renderEmpty();
  };

  private renderModal = () => {
    return (
      <>
        <NotificationModal
          onClose={this.handleModalOk}
          isOpen={this.state.isOpenNotificationModal}
          onClickOk={this.handleModalOk}
          okText={"OK"}
          message={this.state.notifiedMessage}
        />
        <ActionsModal
          isOpen={this.state.isOpenActionModal}
          onClose={this.closeActionModal}
          actions={[{ text: "給餌記録を削除する", onClick: this.handleClickRemove }]}
        />
      </>
    );
  };

  private handleClickMenu = () => {
    this.props.navigationService.openMenu();
  };

  private handleClickSave = async () => {
    await this.props.newOrEditStateService.saveWorkReport();
    if (this.props.newOrEditState.isSaved) {
      this.openNotificationModal("保存しました");
    }
  };

  private handleClickRemove = async () => {
    this.closeActionModal();
    await this.props.newOrEditStateService.deleteFeedReport();
    if (this.props.newOrEditState.isSaved) {
      this.openNotificationModal("削除しました");
    }
  };

  private openActionModal = () => this.setState({ isOpenActionModal: true });
  private closeActionModal = () => this.setState({ isOpenActionModal: false });
  private openNotificationModal = (message: string) =>
    this.setState({ isOpenNotificationModal: true, notifiedMessage: message });
  private closeNotificationModal = () => this.setState({ isOpenNotificationModal: false, notifiedMessage: "" });

  private handleModalOk = () => {
    this.closeNotificationModal();
    this.props.history.goBack();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { pond, workReport } = state.api;
  return {
    ponds: pond.ponds,
    workReports: workReport.workReports,
    navigationState: state.mobile.navigation,
    newOrEditState: state.mobile.workReportState.newOrEditStateType,
    isLoaded: pond.isFetched,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    newOrEditStateService: new NewOrEditStateService(dispatch),
    navigationService: new NavigationService(dispatch),
    feedReportStateService: new FeedReportStateService(dispatch),
  };
};

export const WorkReportFeedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
