import { sortPosition } from "../../../../domain/entity";
import { Pond } from "../../../../domain/pond";
import { FetchLifeCycle } from "../../../lifeCycle/fetchLifeCycle";

export interface PondStateType extends FetchLifeCycle {
  ponds: Pond[];
}

export const getInitialState = (): PondStateType => {
  return {
    ponds: [],
    isFetched: false,
    isFetching: false,
    isError: null,
  };
};

export const FetchHandlers = {
  start(state: PondStateType) {
    return { ...state, isFetched: false, isFetching: true, isError: null };
  },
  complete(state: PondStateType, ponds: Pond[]) {
    const sortedEntity = ponds.concat().sort(sortPosition);
    return { ...state, ponds: sortedEntity, isFetched: true, isFetching: false };
  },
  fail(state: PondStateType, message: string = "") {
    return { ...state, isFetched: false, isFetching: false, isError: { message } };
  },
  shouldFetch(state: PondStateType): PondStateType {
    return { ...state, isFetched: false };
  },
};

export const SaveHandlers = {
  createPond(state: PondStateType, pond: Pond): PondStateType {
    const ponds = state.ponds.concat(pond);
    const sortedEntity = ponds.concat().sort(sortPosition);
    return { ...state, ponds: sortedEntity };
  },
  updatePond(state: PondStateType, pond: Pond): PondStateType {
    const ponds = state.ponds.map((p) => (p.id === pond.id ? pond : p));
    const sortedEntity = ponds.concat().sort(sortPosition);
    return { ...state, ponds: sortedEntity };
  },
  deletePond(state: PondStateType, pond: Pond): PondStateType {
    const ponds = state.ponds.map((p) => (p.id === pond.id ? pond : p));
    const sortedEntity = ponds.concat().sort(sortPosition);
    return { ...state, ponds: sortedEntity };
  },
  replacePonds(state: PondStateType, ponds: Pond[]): PondStateType {
    return { ...state, ponds };
  },
};
