import { getUniqueKey, UniqueKey } from "../../../../../domain/entity";
import { EditFeedReport, isEditFeedReport, NewFeedReport, NewOrEditFeedReport } from "../../../../../domain/feedReport";
import {
  EditFertilizerReport,
  isEditFertilizerReport,
  NewFertilizerReport,
  NewOrEditFertilizerReport,
} from "../../../../../domain/fertilizerReport";
import {
  EditHerbicideReport,
  isEditHerbicideReport,
  NewHerbicideReport,
  NewOrEditHerbicideReport,
} from "../../../../../domain/herbicideReport";
import { isEditWorkReport, NewOrEditWorkReport, WorkReport } from "../../../../../domain/workReport";
import { EditLifeCycle } from "../../../../lifeCycle/editLifeCycle";

export interface NewOrEditStateType extends EditLifeCycle {
  workReport: NewOrEditWorkReport | null;
  selectedReportKey: "feed" | "fertilizer" | "herbicide" | null;
  selectedUniqueKey: UniqueKey | null;
}

export const getInitialState = (): NewOrEditStateType => {
  return {
    workReport: null,
    selectedReportKey: null,
    selectedUniqueKey: null,
    isSaving: false,
    isUnSave: false,
    isSaved: false,
    isError: null,
  };
};

export const InitialHandler = {
  initialWorkReport(state: NewOrEditStateType, workReport: NewOrEditWorkReport): NewOrEditStateType {
    return { ...state, workReport, selectedReportKey: null, selectedUniqueKey: null };
  },
  selectAnyReport(
    state: NewOrEditStateType,
    selectedReportKey: "feed" | "fertilizer" | "herbicide",
    selectedUniqueKey: UniqueKey
  ): NewOrEditStateType {
    return { ...state, selectedReportKey, selectedUniqueKey };
  },
};

export const ChangeHandler = {
  changeWorkReport(state: NewOrEditStateType, key: keyof NewOrEditWorkReport, value: any): NewOrEditStateType {
    if (state.workReport === null) {
      return state;
    }
    return { ...state, workReport: { ...state.workReport, [key]: value }, isUnSave: true };
  },
  changeFeedReport(
    state: NewOrEditStateType,
    feedUniqueKey: UniqueKey,
    key: keyof NewOrEditFeedReport,
    value: any
  ): NewOrEditStateType {
    if (state.workReport === null) {
      return state;
    }
    const mapFeedReport = (feedReport: NewOrEditFeedReport) => {
      const uniqueKey = getUniqueKey(feedReport);
      if (uniqueKey !== feedUniqueKey) {
        return feedReport as NewOrEditFeedReport;
      }
      return { ...feedReport, [key]: value } as NewOrEditFeedReport;
    };
    const feedReports = isEditWorkReport(state.workReport)
      ? state.workReport.feedReports.map(mapFeedReport)
      : state.workReport.feedReports.map(mapFeedReport);
    return { ...state, workReport: { ...state.workReport, feedReports } as NewOrEditWorkReport, isUnSave: true };
  },
  changeFertilizerReport(
    state: NewOrEditStateType,
    fertilizerUniqueKey: UniqueKey,
    key: keyof NewOrEditFertilizerReport,
    value: any
  ): NewOrEditStateType {
    if (state.workReport === null) {
      return state;
    }
    const mapFertilizerReport = (fertilizerReport: NewOrEditFertilizerReport) => {
      const uniqueKey = getUniqueKey(fertilizerReport);
      if (uniqueKey !== fertilizerUniqueKey) {
        return fertilizerReport as NewOrEditFertilizerReport;
      }
      return { ...fertilizerReport, [key]: value } as NewOrEditFertilizerReport;
    };
    const fertilizerReports = isEditWorkReport(state.workReport)
      ? state.workReport.fertilizerReports.map(mapFertilizerReport)
      : state.workReport.fertilizerReports.map(mapFertilizerReport);
    return { ...state, workReport: { ...state.workReport, fertilizerReports } as NewOrEditWorkReport, isUnSave: true };
  },
  changeHerbicideReport(
    state: NewOrEditStateType,
    herbicideUniqueKey: UniqueKey,
    key: keyof NewOrEditHerbicideReport,
    value: any
  ): NewOrEditStateType {
    if (state.workReport === null) {
      return state;
    }
    const mapHerbicideReport = (herbicideReport: NewOrEditHerbicideReport) => {
      const uniqueKey = getUniqueKey(herbicideReport);
      if (uniqueKey !== herbicideUniqueKey) {
        return herbicideReport as NewOrEditHerbicideReport;
      }
      return { ...herbicideReport, [key]: value } as NewOrEditHerbicideReport;
    };
    const herbicideReports = isEditWorkReport(state.workReport)
      ? state.workReport.herbicideReports.map(mapHerbicideReport)
      : state.workReport.herbicideReports.map(mapHerbicideReport);
    return { ...state, workReport: { ...state.workReport, herbicideReports } as NewOrEditWorkReport, isUnSave: true };
  },
  addImage(state: NewOrEditStateType, imageId: number): NewOrEditStateType {
    if (state.workReport === null) {
      return state;
    }
    return { ...state, workReport: { ...state.workReport, imageIds: state.workReport.imageIds.concat(imageId) } };
  },
  addFeedReport(state: NewOrEditStateType, feedReport: NewFeedReport): NewOrEditStateType {
    if (state.workReport === null) {
      return state;
    }
    const feedReports = state.workReport.feedReports.concat(feedReport);
    return { ...state, workReport: { ...state.workReport, feedReports } as NewOrEditWorkReport };
  },
  addFertilizerReport(state: NewOrEditStateType, fertilizerReport: NewFertilizerReport): NewOrEditStateType {
    if (state.workReport === null) {
      return state;
    }
    const fertilizerReports = state.workReport.fertilizerReports.concat(fertilizerReport);
    return { ...state, workReport: { ...state.workReport, fertilizerReports } as NewOrEditWorkReport };
  },
  addHerbicideReport(state: NewOrEditStateType, herbicideReport: NewHerbicideReport): NewOrEditStateType {
    if (state.workReport === null) {
      return state;
    }
    const herbicideReports = state.workReport.herbicideReports.concat(herbicideReport);
    return { ...state, workReport: { ...state.workReport, herbicideReports } as NewOrEditWorkReport };
  },
  removeImage(state: NewOrEditStateType, imageId: number): NewOrEditStateType {
    if (state.workReport === null) {
      return state;
    }
    return {
      ...state,
      workReport: { ...state.workReport, imageIds: state.workReport.imageIds.filter((id) => id !== imageId) },
    };
  },
  removeFeedReport(state: NewOrEditStateType): NewOrEditStateType {
    if (state.workReport === null || state.selectedReportKey !== "feed") {
      return state;
    }
    const feedReports = state.workReport.feedReports.filter((fr) => getUniqueKey(fr) !== state.selectedUniqueKey);
    return { ...state, workReport: { ...state.workReport, feedReports } as NewOrEditWorkReport };
  },
  removeFertilizerReport(state: NewOrEditStateType): NewOrEditStateType {
    if (state.workReport === null || state.selectedReportKey !== "fertilizer") {
      return state;
    }
    const fertilizerReports = state.workReport.fertilizerReports.filter(
      (fr) => getUniqueKey(fr) !== state.selectedUniqueKey
    );
    return { ...state, workReport: { ...state.workReport, fertilizerReports } as NewOrEditWorkReport };
  },
  removeHerbicideReport(state: NewOrEditStateType): NewOrEditStateType {
    if (state.workReport === null || state.selectedReportKey !== "herbicide") {
      return state;
    }
    const herbicideReports = state.workReport.herbicideReports.filter(
      (hr) => getUniqueKey(hr) !== state.selectedUniqueKey
    );
    return { ...state, workReport: { ...state.workReport, herbicideReports } as NewOrEditWorkReport };
  },
};

export const SaveHandler = {
  saveStart(state: NewOrEditStateType): NewOrEditStateType {
    return { ...state, isSaving: true, isSaved: false, isUnSave: true, isError: null };
  },
  saveSuccess(state: NewOrEditStateType, workReport: WorkReport): NewOrEditStateType {
    const baseNextState = { ...state, workReport, isSaving: false, isSaved: true, isUnSave: false, isError: null };
    if (!state.workReport) {
      return baseNextState;
    }
    if (state.selectedReportKey === "feed") {
      const feedReportIds = state.workReport.feedReports
        .filter(isEditFeedReport)
        .map((fr) => (fr as EditFeedReport).id);
      const targetReport = workReport.feedReports.find((fr) => {
        if (isEditFeedReport(fr)) {
          return !feedReportIds.includes(fr.id);
        }
        return false;
      });
      if (targetReport) {
        return { ...baseNextState, selectedUniqueKey: getUniqueKey(targetReport) };
      }
    }
    if (state.selectedReportKey === "fertilizer") {
      const fertilizerReportIds = state.workReport.fertilizerReports
        .filter(isEditFertilizerReport)
        .map((fr) => (fr as EditFertilizerReport).id);
      const targetReport = workReport.fertilizerReports.find((fr) => {
        if (isEditFertilizerReport(fr)) {
          return !fertilizerReportIds.includes(fr.id);
        }
        return false;
      });
      if (targetReport) {
        return { ...baseNextState, selectedUniqueKey: getUniqueKey(targetReport) };
      }
    }
    if (state.selectedReportKey === "herbicide") {
      const herbicideReportIds = state.workReport.herbicideReports
        .filter(isEditHerbicideReport)
        .map((hr) => (hr as EditHerbicideReport).id);
      const targetReport = workReport.herbicideReports.find((hr) => {
        if (isEditHerbicideReport(hr)) {
          return !herbicideReportIds.includes(hr.id);
        }
        return false;
      });
      if (targetReport) {
        return { ...baseNextState, selectedUniqueKey: getUniqueKey(targetReport) };
      }
    }

    return baseNextState;
  },
  saveFail(state: NewOrEditStateType): NewOrEditStateType {
    return { ...state, isSaving: false, isUnSave: true, isSaved: false, isError: null };
  },
};
