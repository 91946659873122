import { actionCreatorFactory } from "typescript-fsa";
import { EditHerbicide, Herbicide, NewHerbicide, NewOrEditHerbicide } from "../../../../../domain/herbicide";
// Actions
const actionCreator = actionCreatorFactory("MasterHerbicideNewOrEditState");

const createHerbicide = actionCreator<CreateHerbicidePayload>("createHerbicide");
interface CreateHerbicidePayload {
  herbicide: NewHerbicide;
}

const selectHerbicide = actionCreator<SelectHerbicidePayload>("selectHerbicide");
interface SelectHerbicidePayload {
  herbicide: EditHerbicide;
}

const changeHerbicide = actionCreator<ChangeHerbicidePayload>("changeHerbicide");
interface ChangeHerbicidePayload {
  key: keyof NewOrEditHerbicide;
  value: any;
}

export const cancelHerbicide = actionCreator<CancelHerbicidePayload>("cancelHerbicide");
interface CancelHerbicidePayload {
  herbicide: NewOrEditHerbicide;
}

export const saveStart = actionCreator("saveStart");
export const saveSuccess = actionCreator<SaveSuccessPayload>("saveSuccess");
interface SaveSuccessPayload {
  herbicide: Herbicide | null;
}
export const saveFail = actionCreator("saveFail");

export const masterHerbicideNewOrEditStateActions = {
  createHerbicide,
  selectHerbicide,
  changeHerbicide,
  cancelHerbicide,
  saveStart,
  saveSuccess,
  saveFail,
};

// Reducers
import { ChangeHandler, getInitialState, InitialHandler, NewOrEditStateType, SaveHandler } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const NewOrEditStateReducer = reducerWithInitialState<NewOrEditStateType>(getInitialState())
  .case(createHerbicide, (state, { herbicide }) => {
    return InitialHandler.createHerbicide(state, herbicide);
  })
  .case(selectHerbicide, (state, { herbicide }) => {
    return InitialHandler.selectHerbicide(state, herbicide);
  })
  .case(changeHerbicide, (state, { key, value }) => {
    return ChangeHandler.changeHerbicide(state, key, value);
  })
  .case(cancelHerbicide, (state, { herbicide }) => {
    return SaveHandler.cancelHerbicide(state, herbicide);
  })
  .case(saveStart, (state) => {
    return SaveHandler.saveStart(state);
  })
  .case(saveSuccess, (state, { herbicide }) => {
    return SaveHandler.saveSuccess(state, herbicide);
  })
  .case(saveFail, (state) => {
    return SaveHandler.saveFail(state);
  });
