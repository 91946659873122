import { combineReducers } from "redux";
import { environmentReportStateReducer, EnvironmentReportStateType } from "./environmentReport";
import { NavigationStateReducer } from "./navigation/ducks";
import { NavigationStateType } from "./navigation/reducer";
import { pondReportReducer, ReportPondReportState } from "./pondReport";
import { ReportWorkReportState, workReportReducer } from "./workReport";

export interface ReportState {
  environmentReport: EnvironmentReportStateType;
  navigation: NavigationStateType;
  pondReport: ReportPondReportState;
  workReport: ReportWorkReportState;
}
export const reportReducer = combineReducers({
  environmentReport: environmentReportStateReducer,
  navigation: NavigationStateReducer,
  pondReport: pondReportReducer,
  workReport: workReportReducer,
});
