import * as React from "react";
import { NewOrEditFeed } from "../../../../domain/feed";
import { PondClass } from "../../../../domain/pondClass";
import { Form } from "../../atoms/Form/Form";
import { FormTextInput } from "../../atoms/Form/Input";
import { FormSelect, getOption } from "../../atoms/Form/Select";
import { Spinner } from "../../atoms/Spinner";
import { MasterForm } from "../../molecules/MasterForm";
import { MasterFormButton } from "../../molecules/MasterFormButton";

interface FeedFormProps {
  feed: NewOrEditFeed | null;
  isSaving: boolean;
  onChangeName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePondClass: (pondClassId: null | number) => void;
  onClickSave: () => void;
  onClickCancel: () => void;
  onClickAdd: (() => void) | null;
  onClickDelete: (() => void) | null;
}

export const FeedForm = ({
  feed,
  isSaving,
  onChangeName,
  onChangePondClass,
  onClickSave,
  onClickCancel,
  onClickAdd,
  onClickDelete,
}: FeedFormProps) => {
  if (feed === null) {
    return <Spinner />;
  }
  return (
    <MasterForm onClickAdd={onClickAdd} onClickDelete={onClickDelete}>
      {isSaving && <Spinner />}
      <Form>
        <FormTextInput label={"餌名"} value={feed.name} onChange={onChangeName} />
        <FormSelect value={feed.pondClassId} label={"区分"} options={POND_TYPE_OPTIONS} onChange={onChangePondClass} />
        <MasterFormButton onClickSave={onClickSave} onClickCancel={onClickCancel} />
      </Form>
    </MasterForm>
  );
};

const POND_TYPE_OPTIONS = [getOption(PondClass.Fry, "稚魚用"), getOption(PondClass.Adult, "2才立て以上用")];
