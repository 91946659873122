import * as _ from "lodash";
import * as React from "react";
import { AutoSizer, List } from "react-virtualized";
import { CarpVarietyType } from "../../../../domain/carpVarietyType";
import { ParentCarp } from "../../../../domain/parentCarp";
import { Pond } from "../../../../domain/pond";
import { PondArea } from "../../../../domain/pondArea";
import { PondOwnerType } from "../../../../domain/pondOwnerType";
import { PondReport } from "../../../../domain/pondReport";
import { PondType } from "../../../../domain/pondType";
import { useScrollTopOfVirtualList, VirtualListScrollTopStoreKey } from "../../../../hooks/scrollHooks";
import { PageSection } from "../../atoms/Section";
import { POND_LIST_ROW_HEIGHT, PondListRow, PondListVirtualized } from "../../molecules/PondList";

interface PondSelectionEachAreaProps {
  ponds: Pond[];
  pondAreas: PondArea[];
  pondReports: PondReport[];
  pondTypes: PondType[];
  pondOwnerTypes: PondOwnerType[];
  parentCarps: ParentCarp[];
  carpVarietyTypes: CarpVarietyType[];
  onClickPond: (id: number) => void;
  shouldMoveScrollTopOnMounted: boolean;
}

export const POND_AREA_ROW_HEIGHT = 20;

export const PondSelectionEachArea = (props: PondSelectionEachAreaProps) => {
  const nestedList = props.pondAreas
    .filter((area) => {
      return props.ponds.filter((p) => area.pondIds.includes(p.id)).length !== 0;
    })
    .map((area) => {
      const ponds = props.ponds.filter((p) => area.pondIds.includes(p.id));
      return [area, ...ponds];
    });
  const list = _.flatten(nestedList);
  const { scrollTop, onScroll } = useScrollTopOfVirtualList(VirtualListScrollTopStoreKey.PondSelectionEachArea);
  return (
    <AutoSizer>
      {({ width, height }) => (
        <List
          rowCount={list.length}
          rowHeight={getRowHeightOfListEachArea({ list })}
          width={width}
          height={height}
          rowRenderer={listEachAreaRowRender({ ...props, list })}
          scrollTop={props.shouldMoveScrollTopOnMounted ? scrollTop : undefined}
          onScroll={onScroll}
        />
      )}
    </AutoSizer>
  );
};

function isPond(entity: Record<string, unknown>): entity is Pond {
  return "pondAreaId" in entity;
}
const getRowHeightOfListEachArea = ({ list }: { list: Array<PondArea | Pond> }) => ({ index }: { index: number }) => {
  const entity = list[index];
  return isPond(entity) ? POND_LIST_ROW_HEIGHT : POND_AREA_ROW_HEIGHT;
};

const listEachAreaRowRender = (args: PondSelectionEachAreaProps & { list: Array<PondArea | Pond> }) => function ListEachAreaRow({
  index,
  key,
  style,
}: {
  index: number;
  key: string;
  style: any;
}) {
  const entity = args.list[index];
  if (isPond(entity)) {
    return (
      <PondListRow
        key={key}
        pond={entity}
        pondReports={args.pondReports}
        parentCarps={args.parentCarps}
        pondTypes={args.pondTypes}
        pondOwnerTypes={args.pondOwnerTypes}
        carpVarietyTypes={args.carpVarietyTypes}
        onClickPond={args.onClickPond}
        style={style}
      />
    );
  }
  return (
    <PageSection key={key} style={style}>
      {entity.name}
    </PageSection>
  );
};

interface PondSelectionProps {
  ponds: Pond[];
  pondReports: PondReport[];
  pondTypes: PondType[];
  pondOwnerTypes: PondOwnerType[];
  parentCarps: ParentCarp[];
  carpVarietyTypes: CarpVarietyType[];
  onClickPond: (id: number) => void;
  shouldMoveScrollTopOnMounted: boolean;
}
export const PondSelection = (props: PondSelectionProps) => {
  const { scrollTop, onScroll } = useScrollTopOfVirtualList(VirtualListScrollTopStoreKey.PondSelection);
  return (
    <PondListVirtualized
      ponds={props.ponds}
      pondReports={props.pondReports}
      parentCarps={props.parentCarps}
      pondTypes={props.pondTypes}
      pondOwnerTypes={props.pondOwnerTypes}
      carpVarietyTypes={props.carpVarietyTypes}
      onClickPond={props.onClickPond}
      scrollTop={props.shouldMoveScrollTopOnMounted ? scrollTop : undefined}
      onScroll={onScroll}
    />
  );
};
