import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { EditStateService } from "../../../application/master/parentCarps/editState";
import { NewStateService } from "../../../application/master/parentCarps/newState";
import { CarpVarietyType } from "../../../domain/carpVarietyType";
import { isActiveEntity, UniqueKey } from "../../../domain/entity";
import { ApplicationState } from "../../../store/modules";
import { ImageStateType } from "../../../store/modules/api/image/reducer";
import { imageGalleryStateActions } from "../../../store/modules/imageGallery/ducks";
import { NewOrEditStateType } from "../../../store/modules/master/parentCarp/newOrEditState/reducer";
import { ConfirmModal } from "../../components/molecules/ConfirmModal";
import { ParentCarpForm } from "../../components/organisms/ParentCarpForm";

interface StateProps {
  carpVarietyTypes: CarpVarietyType[];
  newOrEditState: NewOrEditStateType;
  image: ImageStateType;
}

interface DispatchProps {
  editStateService: EditStateService;
  newStateService: NewStateService;
  showImageGallery: (imageIds: number[], imageId: number) => void;
}

type ParentCarpEditPageProps = StateProps & DispatchProps;

interface ParentCarpEditPageState {
  isOpenDeleteConfirm: boolean;
}

export class Wrapped extends React.Component<ParentCarpEditPageProps, ParentCarpEditPageState> {
  constructor(props: ParentCarpEditPageProps) {
    super(props);

    this.state = {
      isOpenDeleteConfirm: false,
    };
  }

  public render() {
    const { parentCarp, isSaving } = this.props.newOrEditState;
    return (
      <>
        <ParentCarpForm
          parentCarp={parentCarp}
          carpPairings={parentCarp ? parentCarp.carpPairings.filter(isActiveEntity) : []}
          carpVarietyTypes={this.props.carpVarietyTypes}
          isSaving={isSaving}
          disabledSave={this.props.image.isSaving}
          onChangeName={this.handleChangeName}
          onChangeCarpVarietyType={this.handleChangeCarpVarietyType}
          onChangeYear={this.handleChangeYear}
          onChangeCarpPairingName={this.handleChangeCarpPairingName}
          onAddCarpPairing={this.handleAddCarpPairing}
          onRemoveCarpPairing={this.handleRemoveCarpPairing}
          onClickSave={this.handleClickSaveParentCarp}
          onClickCancel={this.handleClickCancelParentCarp}
          onClickAdd={this.handleClickAddParentCarp}
          onClickDelete={this.openDeleteConfirmModal}
          onChangeFile={this.handleChangeFile}
          onClickImage={this.handleClickImage}
          onClickImageRemove={this.handleClickImageRemove}
        />

        <ConfirmModal
          isOpen={this.state.isOpenDeleteConfirm}
          onClose={this.closeDeleteConfirmModal}
          onClickCancel={this.closeDeleteConfirmModal}
          onClickOk={this.handleClickDeleteParentCarp}
          cancelText={"キャンセル"}
          okText={"削除"}
          confirmMessage={"親鯉を削除してもよろしいでしょうか？"}
        />
      </>
    );
  }

  private handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.editStateService.changeName(e.target.value);
  };

  private handleChangeCarpVarietyType = (varietyId: null | number) => {
    this.props.editStateService.changeCarpVarietyType(varietyId);
  };

  private handleChangeYear = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      this.props.editStateService.changeYear(null);
      return;
    }

    const year = +e.target.value;
    if (!Number.isNaN(year)) {
      this.props.editStateService.changeYear(year);
    }
  };

  private handleChangeCarpPairingName = (uniqueKey: UniqueKey, e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.editStateService.changeCarpPairingName(uniqueKey, e.target.value);
  };

  private handleAddCarpPairing = () => {
    this.props.editStateService.addCarpPairing();
  };

  private handleRemoveCarpPairing = (uniqueKey: UniqueKey) => {
    this.props.editStateService.removeCarpPairing(uniqueKey);
  };

  private handleClickSaveParentCarp = () => {
    this.props.editStateService.saveParentCarp();
  };

  private handleClickCancelParentCarp = () => {
    this.props.editStateService.cancelParentCarp();
  };

  private handleClickAddParentCarp = () => {
    this.props.newStateService.createParentCarp();
  };

  private handleClickDeleteParentCarp = () => {
    this.props.editStateService.deleteParentCarp();
    this.closeDeleteConfirmModal();
  };

  private handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>, uniqueKey: UniqueKey) => {
    if (e.target.files && e.target.files.length !== 0) {
      this.props.editStateService.addCarpPairingImage(uniqueKey, e.target.files[0]);
    }
  };

  private handleClickImage = (imageIds: number[], imageId: number) => {
    this.props.showImageGallery(imageIds, imageId);
  };

  private handleClickImageRemove = (uniqueKey: UniqueKey, imageId: number) => {
    this.props.editStateService.removeCarpPairingImage(uniqueKey, imageId);
  };

  private closeDeleteConfirmModal = () => {
    this.setState({ isOpenDeleteConfirm: false });
  };
  private openDeleteConfirmModal = () => {
    this.setState({ isOpenDeleteConfirm: true });
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { newOrEditState } = state.master.parentCarp;
  return {
    carpVarietyTypes: state.api.carpVarietyType.carpVarietyTypes,
    newOrEditState,
    image: state.api.image,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    editStateService: new EditStateService(dispatch),
    newStateService: new NewStateService(dispatch),
    showImageGallery: (imageIds, imageId) =>
      dispatch(imageGalleryStateActions.showImages({ imageIds, initialImageId: imageId })),
  };
};

export const ParentCarpEditPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);
