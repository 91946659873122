import { EditFertilizer, Fertilizer, NewFertilizer } from "../../domain/fertilizer";
import * as api from "../../libs/client";
import { translateApiToFertilizer, translateFertilizerToApi } from "./translate";

interface IFertilizerRepository {
  getFertilizers: () => Promise<Fertilizer[]>;
  postFertilizer: (fertilizer: NewFertilizer) => Promise<Fertilizer>;
  putFertilizer: (fertilizer: EditFertilizer) => Promise<Fertilizer>;
  deleteFertilizer: (fertilizer: Fertilizer) => Promise<Fertilizer>;
}

export class FertilizerRepository implements IFertilizerRepository {
  public async getFertilizers(): Promise<Fertilizer[]> {
    const fertilizersApi = new api.FertilizersApi(api.serverConfig);
    const response = await fertilizersApi.getFertilizers();
    return response.data.map((d) => translateApiToFertilizer(d));
  }
  public async postFertilizer(fertilizer: NewFertilizer): Promise<Fertilizer> {
    const fertilizersApi = new api.FertilizersApi(api.serverConfig);
    const response = await fertilizersApi.postFertilizer(translateFertilizerToApi(fertilizer));
    return translateApiToFertilizer(response.data);
  }

  public async putFertilizer(fertilizer: EditFertilizer): Promise<Fertilizer> {
    const fertilizersApi = new api.FertilizersApi(api.serverConfig);
    const response = await fertilizersApi.putFertilizer(fertilizer.id, translateFertilizerToApi(fertilizer));
    return translateApiToFertilizer(response.data);
  }

  public async deleteFertilizer(fertilizer: Fertilizer): Promise<Fertilizer> {
    const fertilizersApi = new api.FertilizersApi(api.serverConfig);
    const response = await fertilizersApi.deleteFertilizer(fertilizer.id);
    return translateApiToFertilizer(response.data);
  }
}
