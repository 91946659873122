import * as React from "react";
import { getFiscalYear } from "../../../../domain/calendar";
import { EnvironmentReport, filterEnvironmentReportByCriteria } from "../../../../domain/environmentReport";
import { Feed } from "../../../../domain/feed";
import { FeedReasonType } from "../../../../domain/feedReasonType";
import { Fertilizer } from "../../../../domain/fertilizer";
import { Herbicide } from "../../../../domain/herbicide";
import { ParentCarp } from "../../../../domain/parentCarp";
import { Pond } from "../../../../domain/pond";
import { filterPondReportsByCriteria, PondReport } from "../../../../domain/pondReport";
import { ScaleType } from "../../../../domain/scaleType";
import { UseMethodType } from "../../../../domain/useMethodType";
import { filterEmptyWorkReport, filterWorkReportsByCriteria, WorkReport } from "../../../../domain/workReport";
import { ReportListDetailContent } from "../../molecules/ReportListDetailContent";

interface ReportListDetailProps {
  selectedDate: Date;
  selectedPondId: number | null;
  ponds: Pond[];
  workReports: WorkReport[];
  pondReports: PondReport[];
  environmentReports: EnvironmentReport[];
  parentCarps: ParentCarp[];
  feeds: Feed[];
  feedReasonTypes: FeedReasonType[];
  fertilizers: Fertilizer[];
  herbicides: Herbicide[];
  scaleTypes: ScaleType[];
  useMethodTypes: UseMethodType[];
  isDisplayFeedReport: boolean;
  isDisplayFertilizerReport: boolean;
  isDisplayHerbicideReport: boolean;
  isDisplayNoteReport: boolean;
  isDisplayEnvironmentReport: boolean;
  isDisplayPondReport: boolean;
  onClickWorkReport: (workReportId: number, reportKey: "feed" | "fertilizer" | "herbicide", itemId: number) => void;
  onClickWorkReportNote: () => void;
  onClickWorkReportImage: (imageIds: number[], imageId: number) => void;
  onClickPondReport: (
    pondReportId: number,
    reportKey: "disinfection" | "selection" | "moving" | "adultCarp",
    itemId: number
  ) => void;
  onClickEnvironmentReport: (environmentReportId: number) => void;
}
export const ReportListDetail = (props: ReportListDetailProps) => {
  const workReports = filterWorkReportsByCriteria(props.workReports, {
    date: props.selectedDate,
    pondId: props.selectedPondId,
  }).filter((w) => filterEmptyWorkReport(w, props)) as WorkReport[];
  const fiscalYear = getFiscalYear(props.selectedDate);
  const pondReports = props.isDisplayPondReport
    ? (filterPondReportsByCriteria(props.pondReports, {
      fiscalYear,
      pondId: props.selectedPondId,
    }) as PondReport[])
    : [];
  const environmentReports = props.isDisplayEnvironmentReport
    ? (filterEnvironmentReportByCriteria(props.environmentReports, {
      date: props.selectedDate,
      pondId: props.selectedPondId,
    }) as EnvironmentReport[])
    : [];
  return (
    <ReportListDetailContent
      date={props.selectedDate}
      workReports={workReports}
      pondReports={pondReports}
      environmentReports={environmentReports}
      ponds={props.ponds}
      pairPondReports={props.pondReports}
      parentCarps={props.parentCarps}
      feeds={props.feeds}
      feedReasonTypes={props.feedReasonTypes}
      fertilizers={props.fertilizers}
      herbicides={props.herbicides}
      useMethodTypes={props.useMethodTypes}
      scaleTypes={props.scaleTypes}
      isDisplayFeedReport={props.isDisplayFeedReport}
      isDisplayFertilizerReport={props.isDisplayFertilizerReport}
      isDisplayHerbicideReport={props.isDisplayHerbicideReport}
      isDisplayNoteReport={props.isDisplayNoteReport}
      onClickWorkReport={props.onClickWorkReport}
      onClickWorkReportNote={props.onClickWorkReportNote}
      onClickWorkReportImage={props.onClickWorkReportImage}
      onClickPondReport={props.onClickPondReport}
      onClickEnvironmentReport={props.onClickEnvironmentReport}
    />
  );
};
