import { EditFeed, Feed, NewOrEditFeed } from "../../../../../domain/feed";
import { EditLifeCycle } from "../../../../lifeCycle/editLifeCycle";

export interface NewOrEditStateType extends EditLifeCycle {
  feed: NewOrEditFeed | null;
}

export const getInitialState = (): NewOrEditStateType => {
  return { feed: null, isUnSave: false, isSaved: false, isSaving: false, isError: null };
};

export const InitialHandler = {
  createFeed(state: NewOrEditStateType, feed: NewOrEditFeed): NewOrEditStateType {
    return { ...state, feed, isUnSave: false, isSaved: false, isError: null };
  },
  selectFeed(state: NewOrEditStateType, feed: EditFeed) {
    return { ...state, feed, isUnSave: false, isSaved: false, isError: null };
  },
};

export const ChangeHandler = {
  changeFeed(state: NewOrEditStateType, key: keyof NewOrEditFeed, value: any): NewOrEditStateType {
    if (state.feed === null) {
      return state;
    }
    return { ...state, feed: { ...state.feed, [key]: value }, isUnSave: true };
  },
};

export const SaveHandler = {
  cancelFeed(state: NewOrEditStateType, feed: NewOrEditFeed): NewOrEditStateType {
    return { ...state, feed, isUnSave: false, isSaved: false };
  },
  saveStart(state: NewOrEditStateType): NewOrEditStateType {
    return { ...state, isSaving: true, isError: null };
  },
  saveSuccess(state: NewOrEditStateType, feed: Feed | null): NewOrEditStateType {
    return { ...state, feed, isUnSave: false, isSaved: true, isSaving: false };
  },
  saveFail(state: NewOrEditStateType, message: string = ""): NewOrEditStateType {
    return { ...state, isUnSave: true, isSaved: false, isSaving: false, isError: { message } };
  },
};
