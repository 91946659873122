import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { ListStateService } from "../../../../application/mobile/pondReport/listState";
import { UniqueKey } from "../../../../domain/entity";
import { Pond } from "../../../../domain/pond";
import { EditPondReport, fillEmptyAdultPondReports, PondReport } from "../../../../domain/pondReport";
import { ApplicationState } from "../../../../store/modules";
import { ListStateType } from "../../../../store/modules/mobile/pondReport/listState/reducer";
import { Spinner } from "../../../components/atoms/Spinner";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { AdultPondReportSelection } from "../../../components/organisms/AdultPondReportSelection";
import { MobileLayout } from "../../Layout/Mobile";

interface StateProps {
  pondReports: PondReport[];
  listState: ListStateType;
}

interface OwnProps {
  pond: Pond;
  currentPondReport: EditPondReport;
  prevPondReport: EditPondReport;
}

interface DispatchProps {
  listStateService: ListStateService;
}

type AdultPondReportSelectionPageProps = StateProps & OwnProps & DispatchProps & RouteComponentProps;

export class Wrapped extends React.Component<AdultPondReportSelectionPageProps> {
  public render() {
    return (
      <MobileLayout>
        <BaseNavigationHeader title={this.props.pond.name} onClickReturn={this.handleClickReturn} />
        <BaseNavigationContent>
          {this.props.listState.isFetching && <Spinner />}
          <AdultPondReportSelection
            currentPondReport={fillEmptyAdultPondReports([this.props.currentPondReport])[0] as EditPondReport}
            prevPondReport={this.props.prevPondReport}
            onClickAdultCarp={this.handleClickAdultCarpReport}
          />
        </BaseNavigationContent>
      </MobileLayout>
    );
  }

  private handleClickAdultCarpReport = (uniqueKey: UniqueKey) => {
    this.props.listStateService.selectAdultCarpReport(uniqueKey);
    this.props.history.push("/reports/pond_reports/adult_carp_reports");
  };

  private handleClickReturn = () => {
    this.props.history.goBack();
  };
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StateProps & OwnProps => {
  const { pondReport } = state.api;
  return {
    pondReports: pondReport.pondReports,
    listState: state.mobile.pondReportState.listState,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    listStateService: new ListStateService(dispatch),
  };
};

export const AdultPondReportSelectionPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
