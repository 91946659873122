import * as api from "../../libs/client";

interface IAuthRepository {
  login: (accountName: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
}

export class AuthRepository implements IAuthRepository {
  public async login(accountName: string, password: string): Promise<void> {
    const authApi = new api.AuthApi(api.serverConfig);
    await authApi.login({ accountName, password });
  }

  public async logout(): Promise<void> {
    const authApi = new api.AuthApi(api.serverConfig);
    await authApi.logout();
  }
}
