import { default as moment } from "moment";
import { AdultCarpReport, NewOrEditAdultCarpReport } from "../../domain/adultCarpReport";
import { CarpMovingReport, NewOrEditCarpMovingReport } from "../../domain/carpMovingReport";
import { CarpSelectionReport, NewOrEditCarpSelectionReport } from "../../domain/carpSelectionReport";
import { getUniqueKey, isEntity, isNewEntity } from "../../domain/entity";
import { NewOrEditPondDisinfectionReport, PondDisinfectionReport } from "../../domain/pondDisinfectionReport";
import { isEditPondReport, NewOrEditPondReport, PondReport } from "../../domain/pondReport";
import * as Api from "../../libs/apiClient";

/*** PondReport ***/
export function translateApiToPondReport(apiPondReport: Api.PondReport): PondReport {
  return {
    id: apiPondReport.id as number,

    pondId: apiPondReport.pondId,
    userId: apiPondReport.userId,
    pondTypeId: apiPondReport.pondTypeId,
    parentCarpId: apiPondReport.parentCarpId,
    carpPairingId: apiPondReport.carpPairingId,
    fiscalYear: "" + apiPondReport.fiscalYear,
    isCompleted: apiPondReport.isCompleted,
    subNumber: apiPondReport.subNumber,

    carpMovingReports: apiPondReport.carpMovingReports.map(translateApiToCarpMovingReport),
    carpSelectionReports: apiPondReport.carpSelectionReports
      .map(translateApiToCarpSelectionReport)
      .sort(sortCarpSelectionReport),
    pondDisinfectionReports: apiPondReport.pondDisinfectionReports.map(translateApiToPondDisinfectionReport),
    adultCarpReports: apiPondReport.adultCarpReports.map(translateApiToAdultCarpReport),
  };
}

export function translatePondReportToApi(pondReport: NewOrEditPondReport): Api.PondReport {
  return {
    id: isEntity(pondReport) ? pondReport.id : undefined,

    pondId: pondReport.pondId,
    userId: pondReport.userId,
    pondTypeId: pondReport.pondTypeId,
    parentCarpId: pondReport.parentCarpId as number,
    carpPairingId: pondReport.carpPairingId as number,
    fiscalYear: Number(pondReport.fiscalYear),
    isCompleted: pondReport.isCompleted,
    subNumber: pondReport.subNumber,

    carpMovingReports: isEditPondReport(pondReport)
      ? pondReport.carpMovingReports.map(translateCarpMovingReportToApi)
      : pondReport.carpMovingReports.map(translateCarpMovingReportToApi),
    carpSelectionReports: isEditPondReport(pondReport)
      ? pondReport.carpSelectionReports.map(translateCarpSelectionReportToApi)
      : pondReport.carpSelectionReports.map(translateCarpSelectionReportToApi),
    pondDisinfectionReports: isEditPondReport(pondReport)
      ? pondReport.pondDisinfectionReports.map(translatePondDisinfectionReportToApi)
      : pondReport.pondDisinfectionReports.map(translatePondDisinfectionReportToApi),
    adultCarpReports: isEditPondReport(pondReport)
      ? pondReport.adultCarpReports.map(translateAdultCarpReportToApi)
      : pondReport.adultCarpReports.map(translateAdultCarpReportToApi),
  };
}

/*** CarpMovingReport ***/
export function translateApiToCarpMovingReport(apiCarpMovingReport: Api.CarpMovingReport): CarpMovingReport {
  return {
    id: apiCarpMovingReport.id as number,

    movedId: apiCarpMovingReport.movedId,
    moverId: apiCarpMovingReport.moverId,
    selectionNumber: apiCarpMovingReport.selectionNumber,
    subNumber: apiCarpMovingReport.subNumber,

    date: moment(apiCarpMovingReport.date, "YYYY-MM-DD").toDate(),
    amount: "" + apiCarpMovingReport.amount,
    note: apiCarpMovingReport.note,
  };
}

export function translateCarpMovingReportToApi(carpMovingReport: NewOrEditCarpMovingReport): Api.CarpMovingReport {
  return {
    id: isEntity(carpMovingReport) ? carpMovingReport.id : undefined,

    movedId: carpMovingReport.movedId,
    moverId: carpMovingReport.moverId,
    selectionNumber: carpMovingReport.selectionNumber,
    subNumber: carpMovingReport.subNumber,

    date: moment(carpMovingReport.date).format("YYYY-MM-DD"),
    amount: Number(carpMovingReport.amount),
    note: carpMovingReport.note,

    pairPondReportId:
      isNewEntity(carpMovingReport) && carpMovingReport.pairPondReportId !== null
        ? carpMovingReport.pairPondReportId
        : undefined,
  };
}

/*** CarpSelectionReport ***/
export function translateApiToCarpSelectionReport(
  apiCarpSelectionReport: Api.CarpSelectionReport
): CarpSelectionReport {
  return {
    id: apiCarpSelectionReport.id as number,

    carpSizeTypeId: apiCarpSelectionReport.carpSizeTypeId,
    carpQualityTypeId: apiCarpSelectionReport.carpQualityTypeId,
    malformationTypeId: apiCarpSelectionReport.malformationTypeId,
    carpRatioTypeId: apiCarpSelectionReport.carpRatioTypeId,
    carpScore: apiCarpSelectionReport.carpScore === null ? "" : "" + apiCarpSelectionReport.carpScore,
    selectionNumber: apiCarpSelectionReport.selectionNumber,

    date: moment(apiCarpSelectionReport.date, "YYYY-MM-DD").toDate(),
    amount: "" + apiCarpSelectionReport.amount,
    note: apiCarpSelectionReport.note,
  };
}

export function translateCarpSelectionReportToApi(
  carpSelectionReport: NewOrEditCarpSelectionReport
): Api.CarpSelectionReport {
  const date = carpSelectionReport.date;
  if (date === null) {
    throw new Error(`CarpSelectionReport is not null. id: ${getUniqueKey(carpSelectionReport)}`);
  }
  return {
    id: isEntity(carpSelectionReport) ? carpSelectionReport.id : undefined,

    carpSizeTypeId: carpSelectionReport.carpSizeTypeId,
    carpQualityTypeId: carpSelectionReport.carpQualityTypeId,
    malformationTypeId: carpSelectionReport.malformationTypeId,
    carpRatioTypeId: carpSelectionReport.carpRatioTypeId,
    carpScore: carpSelectionReport.carpScore === "" ? null : Number(carpSelectionReport.carpScore),
    selectionNumber: carpSelectionReport.selectionNumber,

    date: moment(date).format("YYYY-MM-DD"),
    amount: Number(carpSelectionReport.amount),
    note: carpSelectionReport.note,
  };
}

export function sortCarpSelectionReport(a: CarpSelectionReport, b: CarpSelectionReport): number {
  if (a.selectionNumber === b.selectionNumber) {
    return a.id - b.id;
  }
  return a.selectionNumber - b.selectionNumber;
}

/*** PondDisinfectionReport ***/
export function translateApiToPondDisinfectionReport(
  apiPondDisinfectionReport: Api.PondDisinfectionReport
): PondDisinfectionReport {
  return {
    id: apiPondDisinfectionReport.id as number,

    date: moment(apiPondDisinfectionReport.date, "YYYY-MM-DD").toDate(),
    amount: "" + apiPondDisinfectionReport.amount,
    note: apiPondDisinfectionReport.note,
  };
}

export function translatePondDisinfectionReportToApi(
  pondDisinfectionReport: NewOrEditPondDisinfectionReport
): Api.PondDisinfectionReport {
  const date = pondDisinfectionReport.date;
  if (date === null) {
    throw new Error(`PondDisinfectionReport is not null. id: ${getUniqueKey(pondDisinfectionReport)}`);
  }
  return {
    id: isEntity(pondDisinfectionReport) ? pondDisinfectionReport.id : undefined,

    date: moment(date).format("YYYY-MM-DD"),
    amount: Number(pondDisinfectionReport.amount),
    note: pondDisinfectionReport.note,
  };
}

/*** AdultCarpReport ***/
export function translateApiToAdultCarpReport(apiAdultCarpReport: Api.AdultCarpReport): AdultCarpReport {
  return {
    id: apiAdultCarpReport.id as number,

    selectionNumber: apiAdultCarpReport.selectionNumber,

    date: moment(apiAdultCarpReport.date, "YYYY-MM-DD").toDate(),
    amount: "" + apiAdultCarpReport.amount,
    note: apiAdultCarpReport.note,
  };
}

export function translateAdultCarpReportToApi(adultCarpReport: NewOrEditAdultCarpReport): Api.AdultCarpReport {
  const date = adultCarpReport.date;
  if (date === null) {
    throw new Error(`AdultCarpReport is not null. id: ${getUniqueKey(adultCarpReport)}`);
  }
  return {
    id: isEntity(adultCarpReport) ? adultCarpReport.id : undefined,

    selectionNumber: adultCarpReport.selectionNumber,

    date: moment(date).format("YYYY-MM-DD"),
    amount: Number(adultCarpReport.amount),
    note: adultCarpReport.note,
  };
}
