import { Dispatch } from "redux";
import { getFiscalYear } from "../../../domain/calendar";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationState } from "../../../store/modules";
import { mobilePondReportMoveModalStateActions } from "../../../store/modules/mobile/pondReport/moveModalState/ducks";
import { PondReportApiService } from "../../api/pondReport";

interface IMoveModalStateService extends IApplicationService {
  openModal: () => void;
  closeModal: () => void;
  changePondArea: (pondAreaId: null | number) => void;
  changePond: (pondId: null | number) => void;
  getPondReports: () => void;
}

export class MoveModalStateService implements IMoveModalStateService {
  private pondReportApiService: PondReportApiService;
  public constructor(private dispatch: Dispatch<any>) {
    this.pondReportApiService = new PondReportApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public openModal() {
    this.dispatch(mobilePondReportMoveModalStateActions.openModal());
  }

  @catchApplicationError()
  public closeModal() {
    this.dispatch(mobilePondReportMoveModalStateActions.closeModal());
  }

  @catchApplicationError()
  public changePondArea(pondAreaId: null | number) {
    this.dispatch(mobilePondReportMoveModalStateActions.changeModalState({ key: "pondAreaId", value: pondAreaId }));
  }

  @catchApplicationError()
  public changePond(pondId: null | number) {
    this.dispatch(mobilePondReportMoveModalStateActions.changeModalState({ key: "pondId", value: pondId }));
  }

  @catchApplicationError()
  public async getPondReports() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const { pondId } = state.mobile.pondReportState.moveModalState.modalState;
      if (pondId === null) {
        return;
      }
      const fiscalYear = getFiscalYear(state.mobile.navigation.selectedDate);
      await this.pondReportApiService.init(fiscalYear, pondId);
    });
  }
}
