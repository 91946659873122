import { sortPosition } from "../../../../domain/entity";
import { ScaleType } from "../../../../domain/scaleType";
import { FetchLifeCycle } from "../../../lifeCycle/fetchLifeCycle";

export interface ScaleTypeStateType extends FetchLifeCycle {
  scaleTypes: ScaleType[];
}

export const getInitialState = (): ScaleTypeStateType => {
  return { scaleTypes: [], isFetched: false, isFetching: false, isError: null };
};

export const FetchHandlers = {
  start(state: ScaleTypeStateType) {
    return { ...state, isFetched: false, isFetching: true, isError: null };
  },
  complete(state: ScaleTypeStateType, scaleTypes: ScaleType[]) {
    const sortedEntity = scaleTypes.concat().sort(sortPosition);
    return { ...state, scaleTypes: sortedEntity, isFetched: true, isFetching: false };
  },
  fail(state: ScaleTypeStateType, message: string = "") {
    return { ...state, isFetched: false, isFetching: false, isError: { message } };
  },
  shouldFetch(state: ScaleTypeStateType): ScaleTypeStateType {
    return { ...state, isFetched: false };
  },
};
