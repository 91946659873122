import { sortPosition } from "../../../../domain/entity";
import { Worker } from "../../../../domain/worker";
import { FetchLifeCycle } from "../../../lifeCycle/fetchLifeCycle";

export interface WorkerStateType extends FetchLifeCycle {
  workers: Worker[];
}

export const getInitialState = (): WorkerStateType => {
  return { workers: [], isFetched: false, isFetching: false, isError: null };
};

export const FetchHandlers = {
  start(state: WorkerStateType) {
    return { ...state, isFetched: false, isFetching: true, isError: null };
  },
  complete(state: WorkerStateType, workers: Worker[]) {
    const sortedEntity = workers.concat().sort(sortPosition);
    return { ...state, workers: sortedEntity, isFetched: true, isFetching: false };
  },
  fail(state: WorkerStateType, message: string = "") {
    return { ...state, isFetched: false, isFetching: false, isError: { message } };
  },
  shouldFetch(state: WorkerStateType): WorkerStateType {
    return { ...state, isFetched: false };
  },
};

export const SaveHandlers = {
  createWorker(state: WorkerStateType, worker: Worker): WorkerStateType {
    const workers = state.workers.concat(worker);
    const sortedEntity = workers.concat().sort(sortPosition);
    return { ...state, workers: sortedEntity };
  },
  updateWorker(state: WorkerStateType, worker: Worker): WorkerStateType {
    const workers = state.workers.map((w) => (w.id === worker.id ? worker : w));
    const sortedEntity = workers.concat().sort(sortPosition);
    return { ...state, workers: sortedEntity };
  },
  deleteWorker(state: WorkerStateType, worker: Worker): WorkerStateType {
    const workers = state.workers.map((w) => (w.id === worker.id ? worker : w));
    const sortedEntity = workers.concat().sort(sortPosition);
    return { ...state, workers: sortedEntity };
  },
};
