import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NavigationService } from "../../../../application/mobile/navigation";
import { ReadApiService } from "../../../../application/mobile/pondSelection/readApi";
import { CarpVarietyType } from "../../../../domain/carpVarietyType";
import { canGoBack } from "../../../../domain/window";
import { ApplicationState } from "../../../../store/modules";
import { NavigationStateType } from "../../../../store/modules/mobile/navigation/reducer";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { PondFilterForm } from "../../../components/molecules/PondFilterForm";
import { MobileLayout } from "../../Layout/Mobile";

interface StateProps {
  carpVarietyTypes: CarpVarietyType[];

  navigationState: NavigationStateType;
}

interface DispatchProps {
  readApiService: ReadApiService;
  navigationStateService: NavigationService;
}

type PondFilterPageProps = StateProps & DispatchProps & RouteComponentProps;

export class Wrapped extends React.Component<PondFilterPageProps> {
  public componentWillMount(): void {
    this.props.readApiService.fetchUnFetchedApi();
  }

  public render() {
    const { pondSortingState, pondFilterState } = this.props.navigationState;
    const selectedCarpVarietyTypeIds = this.props.carpVarietyTypes
      .map((v) => v.id)
      .filter((id) => !pondFilterState.notSelectedCarpVarietyTypeIds.includes(id));
    return (
      <MobileLayout>
        <BaseNavigationHeader title={"稚魚池一覧"} onClickReturn={this.handleClickReturn} />
        <BaseNavigationContent>
          <PondFilterForm
            carpVarietyTypes={this.props.carpVarietyTypes}
            sortingState={pondSortingState}
            filterState={{
              isOnlyEmptyPond: pondFilterState.isOnlyEmptyPond,
              selectedPondStatusIds: pondFilterState.selectedPondStatusIds,
              selectedCarpVarietyTypeIds,
            }}
            onChangeSortDate={this.handleChangeSortDate}
            onChangeOnlyEmptyPond={this.handleChangeOnlyEmptyPond}
            onChangePondStatus={this.handleChangePondStatus}
            onChangeCarpVarietyType={this.handleChangeCarpVariety}
          />
        </BaseNavigationContent>
      </MobileLayout>
    );
  }

  private handleChangeSortDate = (isAscDate: boolean) => {
    this.props.navigationStateService.changeSortDate(isAscDate);
  };
  private handleChangeOnlyEmptyPond = () => {
    this.props.navigationStateService.changeOnlyEmptyPond();
  };
  private handleChangePondStatus = (pondStatusId: number) => {
    this.props.navigationStateService.changePondStatus(pondStatusId);
  };
  private handleChangeCarpVariety = (carpVarietyTypeId: number) => {
    this.props.navigationStateService.changeCarpVariety(carpVarietyTypeId);
  };

  private handleClickReturn = () => {
    if (canGoBack()) {
      this.props.history.goBack();
    }
    this.props.history.push("/ponds/fry");
    return;
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { carpVarietyType } = state.api;
  return {
    carpVarietyTypes: carpVarietyType.carpVarietyTypes,

    navigationState: state.mobile.navigation,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    readApiService: new ReadApiService(dispatch),
    navigationStateService: new NavigationService(dispatch),
  };
};

export const PondFilterPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
