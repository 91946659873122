import { EditFeed, Feed, NewFeed } from "../../domain/feed";
import * as api from "../../libs/client";
import { translateApiToFeed, translateFeedToApi } from "./translate";

interface IFeedRepository {
  getFeeds: () => Promise<Feed[]>;
  postFeed: (feed: NewFeed) => Promise<Feed>;
  putFeed: (feed: EditFeed) => Promise<Feed>;
  deleteFeed: (feed: Feed) => Promise<Feed>;
}

export class FeedRepository implements IFeedRepository {
  public async getFeeds(): Promise<Feed[]> {
    const feedsApi = new api.FeedsApi(api.serverConfig);
    const response = await feedsApi.getFeeds();
    return response.data.map((d) => translateApiToFeed(d));
  }
  public async postFeed(feed: NewFeed): Promise<Feed> {
    const feedsApi = new api.FeedsApi(api.serverConfig);
    const response = await feedsApi.postFeed(translateFeedToApi(feed));
    return translateApiToFeed(response.data);
  }

  public async putFeed(feed: EditFeed): Promise<Feed> {
    const feedsApi = new api.FeedsApi(api.serverConfig);
    const response = await feedsApi.putFeed(feed.id, translateFeedToApi(feed));
    return translateApiToFeed(response.data);
  }

  public async deleteFeed(feed: Feed): Promise<Feed> {
    const feedsApi = new api.FeedsApi(api.serverConfig);
    const response = await feedsApi.deleteFeed(feed.id);
    return translateApiToFeed(response.data);
  }
}
