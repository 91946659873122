import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "semantic-ui-css/semantic.min.css";
import { GlobalStyle } from "./presentation/styles/GlobalStyle";
import { unregister } from "./registerServiceWorker";
import { Router } from "./router";
import { configureStore } from "./store/configureStore";

import "moment/locale/ja";
import "react-datepicker/dist/react-datepicker.css";

const store = configureStore();
ReactDOM.render(
  <Provider store={store}>
    <GlobalStyle />
    <Router />
  </Provider>,
  document.getElementById("root") as HTMLElement
);
unregister();
