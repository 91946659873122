import { UniqueKey } from "../entity";

export interface Validation {
  meta?: {
    uniqueKey: UniqueKey; // idとか識別したいとかあれば設定する
  };
  message: string;
}
export abstract class Validator<T> {
  private validations: Validation[] = [];

  public getMessages(): string {
    return this.validations.map((err) => err.message).join("\n");
  }

  public addMessages = (validation: Validation) => {
    this.validations.push(validation);
  };

  public isValid = () => {
    return this.validations.length === 0;
  };

  public abstract validate(entity: T): void;
}

export function getValidation(message: string = "", meta?: { uniqueKey: UniqueKey }): Validation {
  return { message, meta };
}

/**
 * 半角数字列であるか判定する
 * @param {string} str
 * @returns {boolean}
 */
export function isDigitString(str: string): boolean {
  return /^\d*$/.test(str);
}

export function isAlphanumericCharacterOrSymbol(str: string): boolean {
  return /^(0-9a-zA-Z!"#$%&'()\*\+-\.,\/:;<=>?@\[\\\]^_`{|}~)+$/.test(str);
}

/**
 * 桁数・正負に関わらず数値であるか判定する
 * @param str
 */
export function isNumber(str: string): boolean {
  const reg = new RegExp("^-?(0?|[1-9]?[0-9]*)\\.?[0-9]*$");
  return reg.test(str);
}

/**
 * 0を含まない正の整数(自然数)であるか判定する.
 * @param str
 * @param maxDigit 整数部の桁数
 */
export function isNaturalNumber(str: string, maxDigit: number = 1): boolean {
  const integerReg = new RegExp(`^([1-9][0-9]{0,${maxDigit - 1}})$`);
  return integerReg.test(str);
}

/**
 * 0以上の数値であるか判定する.
 * @param str
 * @param integerDigit 整数部の桁数
 * @param decimalDigit 小数部の桁数
 */
export function isLimitedNumber(str: string, integerDigit: number = 1, decimalDigit: number = 0): boolean {
  if (decimalDigit === 0) {
    const integerReg = new RegExp(`^(0|[1-9][0-9]{0,${integerDigit - 1}})$`);
    return integerReg.test(str);
  }
  const reg = new RegExp(`^(0|[1-9][0-9]{0,${integerDigit - 1}})(?:\\.[0-9]{1,${decimalDigit}})?$`);

  return reg.test(str);
}

/**
 * 0以下の数値であるか判定する.
 * @param str
 * @param integerDigit
 * @param decimalDigit
 */
export function isLimitedNegativeNumber(str: string, integerDigit: number = 1, decimalDigit: number = 0): boolean {
  if (decimalDigit === 0) {
    const integerReg = new RegExp(`^-(0|[1-9][0-9]{0,${integerDigit - 1}})$`);
    return integerReg.test(str);
  }
  const reg = new RegExp(`^-(0|[1-9][0-9]{0,${integerDigit - 1}})(?:\\.[0-9]{1,${decimalDigit}})?$`);

  return reg.test(str);
}

/**
 * 桁数限定なしの0以上の数値であるか判定する。
 * @param str
 */
export function isPositiveNumber(str: string): boolean {
  const reg = new RegExp("^(0|[1-9][0-9]*)(\\.[0-9]+)?$");
  return reg.test(str);
}

/**
 * 最大値よりも小さい数値であるか判定する。
 * @param str
 * @param max: 最大値
 * @param eq
 */
export function isLessThanMaxNumber(str: string, max: number, eq: boolean = false): boolean {
  const num = Number(str);
  if (str === "" || Number.isNaN(num)) {
    return false;
  }
  return eq ? num <= max : num < max;
}

/**
 * 最小値よりも大きい数値であるか判定する。
 * @param str
 * @param min: 最小値
 * @param eq
 */
export function isGraterThanMinNumber(str: string, min: number, eq: boolean = false): boolean {
  const num = Number(str);
  if (str === "" || Number.isNaN(num)) {
    return false;
  }
  return eq ? num >= min : num > min;
}

/**
 * 文字列が空文字かどうか判定する
 * @param str
 */
export function isEmptyString(str: string): boolean {
  return str === "";
}

/**
 * 文字列が0と同等か判定する
 * @param str
 */
export function isZeroString(str: string): boolean {
  return "0" === str;
}

/**
 * minNumber以上maxNumber以下の文字列を判定する.
 * @param str
 * @param minNumber
 * @param maxNumber
 */
export function isTextLength(str: string, minNumber: number = 0, maxNumber: number = 0): boolean {
  const length = str.length;
  return minNumber <= length && length <= maxNumber;
}

/**
 * カタカナかどうか判定する
 * @param {string} str
 * @returns {boolean}
 */
export function isKatakana(str: string): boolean {
  return /^[　 ァ-ヶー]*$/.test(str);
}

/**
 * 郵便番号か判定する
 * @param {string} str
 * @returns {boolean}
 */
export function isZipCode(str: string): boolean {
  return /^\d{3}-\d{4}$/.test(str);
}

/**
 * 電話番号か判定する
 * @param {string} str
 * @returns {boolean}
 */
export function isPhoneNumber(str: string): boolean {
  return /^\d{1,5}-\d{1,4}-\d{1,4}$/.test(str);
}

/**
 * メールアドレスか判定する
 * @param {string} str
 * @returns {boolean}
 */
export function isMailAddress(str: string): boolean {
  return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(str);
}

/**
 * 比較対象よりも後の日付か
 * @param {string} dateStr
 * @param {string} comparedDateStr
 * @returns {boolean}
 */
export function isDateAfter(dateStr: string, comparedDateStr: string): boolean {
  const startDateDate = new Date(dateStr);
  const endDateDate = new Date(comparedDateStr);
  return startDateDate.getTime() > endDateDate.getTime();
}
