import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { ListStateService } from "../../../../application/mobile/pondReport/listState";
import { CarpVarietyType } from "../../../../domain/carpVarietyType";
import { UniqueKey } from "../../../../domain/entity";
import { ParentCarp } from "../../../../domain/parentCarp";
import { Pond } from "../../../../domain/pond";
import { EditPondReport, fillEmptyFryPondReports, PondReport } from "../../../../domain/pondReport";
import {
  isCarpQualityScaleType,
  isCarpRatioScaleType,
  isCarpSizeScaleType,
  isMalformationScaleType,
  ScaleType,
} from "../../../../domain/scaleType";
import { ApplicationState } from "../../../../store/modules";
import { imageGalleryStateActions } from "../../../../store/modules/imageGallery/ducks";
import { ListStateType } from "../../../../store/modules/mobile/pondReport/listState/reducer";
import { Spinner } from "../../../components/atoms/Spinner";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { FryPondReportSelection } from "../../../components/organisms/FryPondReportSelection";
import { MobileLayout } from "../../Layout/Mobile";

interface StateProps {
  pondReports: PondReport[];
  parentCarps: ParentCarp[];
  carpVarietyTypes: CarpVarietyType[];
  carpSizeTypes: ScaleType[];
  carpQualityTypes: ScaleType[];
  malformationTypes: ScaleType[];
  carpRatioTypes: ScaleType[];
  listState: ListStateType;
}

interface OwnProps {
  pond: Pond;
  currentPondReport: EditPondReport;
  prevPondReport: EditPondReport;
}

interface DispatchProps {
  listStateService: ListStateService;
  showImageGallery: (imageIds: number[]) => void;
}

type FryPondReportSelectionPageProps = StateProps & OwnProps & DispatchProps & RouteComponentProps;

export class Wrapped extends React.Component<FryPondReportSelectionPageProps> {
  public render() {
    return (
      <MobileLayout>
        <BaseNavigationHeader title={this.props.pond.name} onClickReturn={this.handleClickReturn} />
        <BaseNavigationContent>
          {this.props.listState.isFetching && <Spinner />}
          <FryPondReportSelection
            currentPondReport={fillEmptyFryPondReports([this.props.currentPondReport])[0] as EditPondReport}
            prevPondReport={this.props.prevPondReport}
            parentCarps={this.props.parentCarps}
            carpVarietyTypes={this.props.carpVarietyTypes}
            carpSizeTypes={this.props.carpSizeTypes}
            carpQualityTypes={this.props.carpQualityTypes}
            malformationTypes={this.props.malformationTypes}
            carpRatioTypes={this.props.carpRatioTypes}
            onClickPondDisinfection={this.handleClickPondDisinfectionReport}
            onClickCarpSelection={this.handleClickCarpSelectionReport}
            onClickCarpPairing={this.handleClickCarpPairing}
          />
        </BaseNavigationContent>
      </MobileLayout>
    );
  }

  private handleClickPondDisinfectionReport = (uniqueKey: UniqueKey) => {
    this.props.listStateService.selectPondDisinfectionReport(uniqueKey);
    this.props.history.push("/reports/pond_reports/pond_disinfection_reports");
  };

  private handleClickCarpSelectionReport = (uniqueKey: UniqueKey) => {
    this.props.listStateService.selectCarpSelectionReport(uniqueKey);
    this.props.history.push("/reports/pond_reports/carp_selection_reports");
  };

  private handleClickCarpPairing = (imageIds: number[]) => {
    this.props.showImageGallery(imageIds);
  };

  private handleClickReturn = () => {
    this.props.history.goBack();
  };
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StateProps & OwnProps => {
  const { scaleType, pondReport, parentCarp, carpVarietyType } = state.api;
  const scaleTypes = scaleType.scaleTypes;
  return {
    pondReports: pondReport.pondReports,
    parentCarps: parentCarp.parentCarps,
    carpVarietyTypes: carpVarietyType.carpVarietyTypes,
    carpSizeTypes: scaleTypes.filter(isCarpSizeScaleType),
    carpQualityTypes: scaleTypes.filter(isCarpQualityScaleType),
    malformationTypes: scaleTypes.filter(isMalformationScaleType),
    carpRatioTypes: scaleTypes.filter(isCarpRatioScaleType),
    listState: state.mobile.pondReportState.listState,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    listStateService: new ListStateService(dispatch),
    showImageGallery: (imageIds) => dispatch(imageGalleryStateActions.showImages({ imageIds, initialImageId: null })),
  };
};

export const FryPondReportSelectionPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
