import { combineReducers } from "redux";
import { NewOrEditStateType } from "./newOrEditState/reducer";

export interface WorkReportStateType {
  newOrEditStateType: NewOrEditStateType;
}
import { workReportNewOrEditStateReducer } from "./newOrEditState/ducks";

export const workReportStateReducer = combineReducers({
  newOrEditStateType: workReportNewOrEditStateReducer,
});
