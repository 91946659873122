import { actionCreatorFactory } from "typescript-fsa";
// Actions
const actionCreator = actionCreatorFactory("ImageGalleryState");

export const showImages = actionCreator<ShowImagesPayload>("showImages");
interface ShowImagesPayload {
  imageIds: number[];
  initialImageId: null | number;
}
export const hideImages = actionCreator("hideImages");

export const imageGalleryStateActions = {
  showImages,
  hideImages,
};

// Reducers
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { getInitialState, ImageGalleryStateType, InitHandlers } from "./reducer";

export const imageGalleryStateReducer = reducerWithInitialState<ImageGalleryStateType>(getInitialState())
  .case(showImages, (state, { imageIds, initialImageId }) => InitHandlers.showImages(state, imageIds, initialImageId))
  .case(hideImages, (state) => InitHandlers.hideImages(state));
