import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { NewStateService } from "../../../application/master/pond/newState";
import { isActiveEntity } from "../../../domain/entity";
import { PondArea } from "../../../domain/pondArea";
import { PondOwnerType } from "../../../domain/pondOwnerType";
import { PondType } from "../../../domain/pondType";
import { Worker } from "../../../domain/worker";
import { ApplicationState } from "../../../store/modules";
import { NewOrEditStateType } from "../../../store/modules/master/pond/newOrEditState/reducer";
import { PondForm } from "../../components/organisms/PondForm";

interface StateProps {
  pondAreas: PondArea[];
  pondTypes: PondType[];
  pondOwnerTypes: PondOwnerType[];
  workers: Worker[];
  newOrEditState: NewOrEditStateType;
}

interface DispatchProps {
  newStateService: NewStateService;
}

type PondEditPageProps = StateProps & DispatchProps;

export class Wrapped extends React.Component<PondEditPageProps> {
  public render() {
    const { pond, isSaving } = this.props.newOrEditState;
    return (
      <PondForm
        pond={pond}
        pondAreas={this.props.pondAreas}
        pondTypes={this.props.pondTypes}
        pondOwnerTypes={this.props.pondOwnerTypes}
        workers={this.props.workers}
        isSaving={isSaving}
        onChangePondArea={this.handleChangePondArea}
        onChangeName={this.handleChangeName}
        onChangePosition={this.handleChangePosition}
        onChangePondType={this.handleChangePondType}
        onChangePondOwnerTypes={this.handleChangePondOwnerTypes}
        onChangeWorkers={this.handleChangeWorkers}
        onChangeIsUnused={this.handleChangeIsUnused}
        onClickSave={this.handleClickSavePond}
        onClickCancel={this.handleClickCancelPond}
        onClickAdd={this.handleClickAddPond}
        onClickDelete={null}
      />
    );
  }

  private handleChangePondArea = (pondAreaId: null | number) => {
    this.props.newStateService.changePondArea(pondAreaId);
  };

  private handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.newStateService.changeName(e.target.value);
  };

  private handleChangePosition = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      this.props.newStateService.changePosition(null);
      return;
    }

    const value = +e.target.value;
    if (!Number.isNaN(value)) {
      this.props.newStateService.changePosition(value);
    }
  };

  private handleChangePondType = (typeId: null | number) => {
    this.props.newStateService.changePondType(typeId);
  };

  private handleChangePondOwnerTypes = (ownerTypeIds: number[]) => {
    this.props.newStateService.changePondOwnerTypes(ownerTypeIds);
  };

  private handleChangeWorkers = (workerIds: number[]) => {
    this.props.newStateService.changeWorkers(workerIds);
  };

  private handleChangeIsUnused = (checked: boolean) => {
    this.props.newStateService.changeIsUnused(checked);
  };

  private handleClickSavePond = () => {
    this.props.newStateService.savePond();
  };

  private handleClickCancelPond = () => {
    this.props.newStateService.cancelPond();
  };

  private handleClickAddPond = () => {
    this.props.newStateService.createPond();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { newOrEditState } = state.master.pond;
  return {
    pondAreas: state.api.pondArea.pondAreas.filter(isActiveEntity),
    pondTypes: state.api.pondType.pondTypes,
    pondOwnerTypes: state.api.pondOwnerType.pondOwnerTypes,
    workers: state.api.worker.workers.filter(isActiveEntity),
    newOrEditState,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    newStateService: new NewStateService(dispatch),
  };
};

export const PondNewPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);
