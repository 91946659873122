import { default as moment } from "moment";
import { Dispatch } from "redux";
import { WorkReport } from "../../../domain/workReport";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { WorkReportRepository } from "../../../infrastracture/workReport/repository";
import { apiWorkReportActions } from "../../../store/modules/api/workReport/ducks";

interface IWorkReportApiService extends IApplicationService {
  init: (startDate?: Date, endDate?: Date, pondId?: number) => void;
  fetchReportByDate: (date: Date, pondId: number) => void;
  fetchReportByPeriod: (startDate: Date, endDate: Date, pondId: number) => void;
  fetchLatestFeedReport: (date: Date, pondId: number) => void;
  fetchLatestFertilizerReport: (date: Date, pondId: number) => void;
  fetchLatestHerbicideReport: (date: Date, pondId: number) => void;
}

export class WorkReportApiService implements IWorkReportApiService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError((dispatch) => dispatch(apiWorkReportActions.fetchFail()))
  public async init(startDate?: Date, endDate?: Date, pondId?: number) {
    this.dispatch(apiWorkReportActions.fetchStart());
    const workReports: WorkReport[] = await new WorkReportRepository().getWorkReports({ startDate, endDate, pondId });
    this.dispatch(apiWorkReportActions.fetchComplete({ workReports }));
  }

  @catchApplicationError((dispatch) => dispatch(apiWorkReportActions.fetchFail()))
  public async fetchReportByDate(date: Date, pondId: number) {
    this.dispatch(apiWorkReportActions.fetchStart());
    const workReports: WorkReport[] = await new WorkReportRepository().getWorkReports({
      startDate: date,
      endDate: date,
      pondId,
    });
    this.dispatch(apiWorkReportActions.fetchComplete({ workReports }));
  }

  @catchApplicationError((dispatch) => dispatch(apiWorkReportActions.fetchFail()))
  public async fetchReportByPeriod(startDate: Date, endDate: Date, pondId: number) {
    this.dispatch(apiWorkReportActions.fetchStart());
    const workReports: WorkReport[] = await new WorkReportRepository().getWorkReports({
      startDate,
      endDate,
      pondId,
    });
    this.dispatch(apiWorkReportActions.fetchComplete({ workReports }));
  }

  @catchApplicationError((dispatch) => dispatch(apiWorkReportActions.fetchFail()))
  public async fetchLatestFeedReport(date: Date, pondId: number) {
    await this.fetchLatestReport(date, pondId, "feed");
  }

  @catchApplicationError((dispatch) => dispatch(apiWorkReportActions.fetchFail()))
  public async fetchLatestFertilizerReport(date: Date, pondId: number) {
    await this.fetchLatestReport(date, pondId, "fertilizer");
  }

  @catchApplicationError((dispatch) => dispatch(apiWorkReportActions.fetchFail()))
  public async fetchLatestHerbicideReport(date: Date, pondId: number) {
    await this.fetchLatestReport(date, pondId, "herbicide");
  }

  private async fetchLatestReport(date: Date, pondId: number, report: "feed" | "fertilizer" | "herbicide") {
    const m = moment(date).add(-1, "days");
    this.dispatch(apiWorkReportActions.fetchStart());
    const workReports: WorkReport[] = await new WorkReportRepository().getWorkReports({
      endDate: m.toDate(),
      pondId,
      report,
      limit: 1,
    });
    this.dispatch(apiWorkReportActions.fetchComplete({ workReports }));
  }
}
