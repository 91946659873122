import { isEntity } from "../../domain/entity";
import { NewOrEditPondArea, PondArea } from "../../domain/pondArea";
import * as Api from "../../libs/apiClient";

export function translateApiToPondArea(apiPondArea: Api.PondArea): PondArea {
  return {
    id: apiPondArea.id as number,
    name: apiPondArea.name,
    pondIds: apiPondArea.pondIds,
    position: apiPondArea.position,
    isDeleted: apiPondArea.isDeleted,
  };
}

export function translatePondOwnerToApi(pondArea: NewOrEditPondArea): Api.PondArea {
  return {
    id: isEntity(pondArea) ? pondArea.id : undefined,
    name: pondArea.name,
    pondIds: pondArea.pondIds,
    position: pondArea.position,
    isDeleted: pondArea.isDeleted,
  };
}
