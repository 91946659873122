import * as React from "react";
import { UniqueKey } from "../../../../domain/entity";
import { PondReport } from "../../../../domain/pondReport";
import { PageSection } from "../../atoms/Section";
import { AdultPondReportList } from "../../molecules/AdultPondReportList";

interface AdultPondReportSelectionProps {
  currentPondReport: PondReport;
  prevPondReport: PondReport | null;
  onClickAdultCarp: (uniqueKey: UniqueKey) => void;
}
export const AdultPondReportSelection = (props: AdultPondReportSelectionProps) => {
  return (
    <>
      <PageSection>放鯉・池揚げ</PageSection>
      <AdultPondReportList
        currentPondReport={props.currentPondReport}
        prevPondReport={props.prevPondReport}
        onClickAdultCarp={props.onClickAdultCarp}
      />
    </>
  );
};
