import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import styled from "styled-components";
import { ReportListService } from "../../../application/view/reportList";
import { isActiveEntity } from "../../../domain/entity";
import { EnvironmentReport } from "../../../domain/environmentReport";
import { Feed } from "../../../domain/feed";
import { FeedReasonType } from "../../../domain/feedReasonType";
import { Fertilizer } from "../../../domain/fertilizer";
import { Herbicide } from "../../../domain/herbicide";
import { ParentCarp } from "../../../domain/parentCarp";
import { Pond } from "../../../domain/pond";
import { PondReport } from "../../../domain/pondReport";
import { ReportGroup } from "../../../domain/reportGroup";
import { ScaleType } from "../../../domain/scaleType";
import { UseMethodType } from "../../../domain/useMethodType";
import { WorkReport } from "../../../domain/workReport";
import { ApplicationState } from "../../../store/modules";
import { ReportListStateType } from "../../../store/modules/view/reportList/reducer";
import { OverFlowSection } from "../../components/atoms/Section";
import { PageHeader } from "../../components/molecules/PageHeader";
import { NoContentDetailSection } from "../../components/molecules/ReportListDetailSection";
import { ReportListFilterModal } from "../../components/molecules/ReportListFilterModal";
import { DateSelectionSection } from "../../components/molecules/ReportListSection";
import { ReportList } from "../../components/organisms/ReportList";
import { MainLayout } from "../Layout";
import { ReportListLayout } from "../Layout/ReportList";
import { DefaultPage } from "./DefaultPage";
import { ReportListDetailContainer } from "./Detail";
import { PondsList } from "./PondsList";

interface StateProps {
  workReports: WorkReport[];
  pondReports: PondReport[];
  environmentReports: EnvironmentReport[];

  ponds: Pond[];
  parentCarps: ParentCarp[];
  feeds: Feed[];
  feedReasonTypes: FeedReasonType[];
  fertilizers: Fertilizer[];
  herbicides: Herbicide[];
  useMethodTypes: UseMethodType[];
  scaleTypes: ScaleType[];

  reportList: ReportListStateType;
}

interface DispatchProps {
  reportListService: ReportListService;
}

type ReportListPageProps = StateProps & DispatchProps & RouteComponentProps;

const HEADER_HEIGHT = "47px";

export class Wrapped extends React.Component<ReportListPageProps> {
  public componentWillMount(): void {
    this.props.reportListService.fetchMaster();
  }

  public render() {
    const { reportList } = this.props;
    const { selectedPondId } = reportList;

    const pond = this.props.ponds.find((p) => p.id === selectedPondId);

    if (!pond) {
      return (
        <MainLayout>
          <ReportListLayout
            header={<PageHeader title={"一覧"} />}
            list={<PondsList />}
            content={
              <>
                <ReportListWrapper>
                  <DateSelectionSection
                    selectedDate={this.props.reportList.displayDate}
                    onClickPrevYear={this.handleClickPrevYear}
                    onClickPrevMonth={this.handleClickPrevMonth}
                    onClickNextMonth={this.handleClickNextMonth}
                    onClickNextYear={this.handleClickNextYear}
                    onClickFilter={this.handleOpenFilterModal}
                  />
                  <OverFlowSection height={`calc(100% - ${HEADER_HEIGHT})`}>
                    <DefaultPage />
                  </OverFlowSection>
                  <ReportListFilterModal
                    isOpen={reportList.isOpenFilterModal}
                    sortingState={reportList.reportSortingState}
                    filterState={reportList.reportFilterState}
                    onChangeSortDate={this.handleChangeSortDate}
                    onChangeReportType={this.handleChangeReportType}
                    onClose={this.handleCloseFilterModal}
                    size={"mini"}
                  />
                </ReportListWrapper>
                <ReportDetailWrapper>
                  <NoContentDetailSection />
                </ReportDetailWrapper>
              </>
            }
            listColumn={2}
          />
        </MainLayout>
      );
    }

    const { selectedReportTypeIds } = reportList.reportFilterState;

    return (
      <MainLayout>
        <ReportListLayout
          header={<PageHeader title={"一覧"} />}
          list={<PondsList />}
          content={
            <>
              <ReportListWrapper>
                <DateSelectionSection
                  selectedDate={this.props.reportList.displayDate}
                  onClickPrevYear={this.handleClickPrevYear}
                  onClickPrevMonth={this.handleClickPrevMonth}
                  onClickNextMonth={this.handleClickNextMonth}
                  onClickNextYear={this.handleClickNextYear}
                  onClickFilter={this.handleOpenFilterModal}
                />
                <OverFlowSection height={`calc(100% - ${HEADER_HEIGHT})`}>
                  <ReportList
                    selectedDate={this.props.reportList.displayDate}
                    selectedPondId={selectedPondId}
                    workReports={this.props.workReports}
                    pondReports={this.props.pondReports}
                    environmentReports={this.props.environmentReports}
                    ponds={this.props.ponds}
                    parentCarps={this.props.parentCarps}
                    feeds={this.props.feeds}
                    feedReasonTypes={this.props.feedReasonTypes}
                    fertilizers={this.props.fertilizers}
                    herbicides={this.props.herbicides}
                    useMethodTypes={this.props.useMethodTypes}
                    scaleTypes={this.props.scaleTypes}
                    isAscDate={reportList.reportSortingState.isAscDate}
                    isDisplayFeedReport={selectedReportTypeIds.includes(ReportGroup.Feed)}
                    isDisplayFertilizerReport={selectedReportTypeIds.includes(ReportGroup.Fertilizer)}
                    isDisplayHerbicideReport={selectedReportTypeIds.includes(ReportGroup.Herbicide)}
                    isDisplayNoteReport={selectedReportTypeIds.includes(ReportGroup.Note)}
                    isDisplayEnvironmentReport={selectedReportTypeIds.includes(ReportGroup.EnvironmentReport)}
                    isDisplayPondReport={selectedReportTypeIds.includes(ReportGroup.PondReport)}
                    onClickDate={this.handleClickDate}
                  />
                </OverFlowSection>
                <ReportListFilterModal
                  isOpen={reportList.isOpenFilterModal}
                  sortingState={reportList.reportSortingState}
                  filterState={reportList.reportFilterState}
                  onChangeSortDate={this.handleChangeSortDate}
                  onChangeReportType={this.handleChangeReportType}
                  onClose={this.handleCloseFilterModal}
                  size={"mini"}
                />
              </ReportListWrapper>
              <ReportDetailWrapper>
                <ReportListDetailContainer />
              </ReportDetailWrapper>
            </>
          }
          listColumn={2}
        />
      </MainLayout>
    );
  }

  private handleChangeSortDate = (isAscDate: boolean) => {
    this.props.reportListService.changeSortDate(isAscDate);
  };
  private handleChangeReportType = (reportTypeId: number) => {
    this.props.reportListService.changeReportType(reportTypeId);
  };

  private handleOpenFilterModal = () => {
    this.props.reportListService.openFilterModal();
  };

  private handleCloseFilterModal = async () => {
    this.props.reportListService.closeFilterModal();
  };

  private handleClickDate = (date: Date) => {
    this.props.reportListService.changeSelectedDate(date);
  };
  private handleClickPrevYear = async () => {
    await this.props.reportListService.changePrevYear();
  };
  private handleClickPrevMonth = async () => {
    await this.props.reportListService.changePrevMonth();
  };
  private handleClickNextMonth = async () => {
    await this.props.reportListService.changeNextMonth();
  };
  private handleClickNextYear = async () => {
    await this.props.reportListService.changeNextYear();
  };
}

const ReportListWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;
const ReportDetailWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const mapStateToProps = (state: ApplicationState): StateProps => {
  const {
    workReport,
    pondReport,
    environmentReport,
    pond,
    parentCarp,
    feed,
    feedReasonType,
    fertilizer,
    herbicide,
    useMethodType,
    scaleType,
  } = state.api;
  return {
    workReports: workReport.workReports,
    pondReports: pondReport.pondReports,
    environmentReports: environmentReport.environmentReports,
    ponds: pond.ponds.filter(isActiveEntity),
    parentCarps: parentCarp.parentCarps,
    feeds: feed.feeds,
    feedReasonTypes: feedReasonType.feedReasonTypes,
    fertilizers: fertilizer.fertilizers,
    herbicides: herbicide.herbicides,
    useMethodTypes: useMethodType.useMethodTypes,
    scaleTypes: scaleType.scaleTypes,

    reportList: state.view.reportList,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    reportListService: new ReportListService(dispatch),
  };
};

export const ReportListPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
