import { UseMethodType } from "../../domain/useMethodType";
import * as api from "../../libs/client";
import { translateApiToUseMethodType } from "./translate";

interface IUseMethodTypeRepository {
  getUseMethodTypes: () => Promise<UseMethodType[]>;
}

export class UseMethodTypeRepository implements IUseMethodTypeRepository {
  public async getUseMethodTypes(): Promise<UseMethodType[]> {
    const useMethodTypesApi = new api.UseMethodTypesApi(api.serverConfig);
    const response = await useMethodTypesApi.getUseMethodTypes();
    return response.data.map((d) => translateApiToUseMethodType(d));
  }
}
