import { EditFertilizer, Fertilizer, NewFertilizer, NewOrEditFertilizer } from "../../../../../domain/fertilizer";
import { EditLifeCycle } from "../../../../lifeCycle/editLifeCycle";

export interface NewOrEditStateType extends EditLifeCycle {
  fertilizer: NewOrEditFertilizer | null;
}

export const getInitialState = (): NewOrEditStateType => {
  return { fertilizer: null, isUnSave: false, isSaved: false, isSaving: false, isError: null };
};

export const InitialHandler = {
  createFertilizer(state: NewOrEditStateType, fertilizer: NewFertilizer): NewOrEditStateType {
    return { ...state, fertilizer, isUnSave: false, isSaved: false, isError: null };
  },
  selectFertilizer(state: NewOrEditStateType, fertilizer: EditFertilizer): NewOrEditStateType {
    return { ...state, fertilizer, isUnSave: false, isSaved: false, isError: null };
  },
};

export const ChangeHandler = {
  changeFertilizer(state: NewOrEditStateType, key: keyof NewOrEditFertilizer, value: any): NewOrEditStateType {
    if (state.fertilizer === null) {
      return state;
    }
    return { ...state, fertilizer: { ...state.fertilizer, [key]: value }, isUnSave: true };
  },
};

export const SaveHandler = {
  cancelFertilizer(state: NewOrEditStateType, fertilizer: NewOrEditFertilizer): NewOrEditStateType {
    return { ...state, fertilizer, isUnSave: false, isSaved: false };
  },
  saveStart(state: NewOrEditStateType): NewOrEditStateType {
    return { ...state, isSaving: true, isError: null };
  },
  saveSuccess(state: NewOrEditStateType, fertilizer: Fertilizer | null): NewOrEditStateType {
    return { ...state, fertilizer, isUnSave: false, isSaved: true, isSaving: false };
  },
  saveFail(state: NewOrEditStateType, message: string = ""): NewOrEditStateType {
    return { ...state, isUnSave: true, isSaved: false, isSaving: false, isError: { message } };
  },
};
