import { sortPosition } from "../../../../domain/entity";
import { PondOwnerType } from "../../../../domain/pondOwnerType";
import { FetchLifeCycle } from "../../../lifeCycle/fetchLifeCycle";

export interface PondOwnerTypeStateType extends FetchLifeCycle {
  pondOwnerTypes: PondOwnerType[];
}

export const getInitialState = (): PondOwnerTypeStateType => {
  return {
    pondOwnerTypes: [],
    isFetched: false,
    isFetching: false,
    isError: null,
  };
};

export const FetchHandlers = {
  start(state: PondOwnerTypeStateType) {
    return { ...state, isFetched: false, isFetching: true, isError: null };
  },
  complete(state: PondOwnerTypeStateType, pondOwnerTypes: PondOwnerType[]) {
    const sortedEntity = pondOwnerTypes.concat().sort(sortPosition);
    return { ...state, pondOwnerTypes: sortedEntity, isFetched: true, isFetching: false };
  },
  fail(state: PondOwnerTypeStateType, message: string = "") {
    return { ...state, isFetched: false, isFetching: false, isError: { message } };
  },
  shouldFetch(state: PondOwnerTypeStateType): PondOwnerTypeStateType {
    return { ...state, isFetched: false };
  },
};
