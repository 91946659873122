import * as React from "react";
import styled from "styled-components";
import { IconButton } from "../../components/atoms/Button";
import { COLOR } from "../../styles/Color";
import { DIMEN } from "../../styles/Dimen";
import { MENU_HEADER_WIDTH } from "./index";

interface ReportLayoutProps {
  header: React.ReactNode;
  list: React.ReactNode;
  form: React.ReactNode;
  listColumn?: number;
  isOpenList?: boolean;
  onClickOpen?: () => void;
  onClickClose?: () => void;
}
export const ReportLayout = ({
  header,
  list,
  form,
  listColumn = 1,
  isOpenList,
  onClickOpen,
  onClickClose,
}: ReportLayoutProps) => {
  const shouldToggleList = typeof isOpenList !== "undefined";
  const isOpen = shouldToggleList ? isOpenList : true;
  return (
    <Layout>
      <Header>{header}</Header>
      <Content>
        <List className={isOpen ? "" : "none"} column={listColumn}>
          {list}
        </List>
        <Form className={isOpen ? "" : "single"} column={listColumn}>
          {form}
          {shouldToggleList && (
            <ToggleButtonGroup className={isOpen ? "" : "none"} column={listColumn}>
              {isOpen ? (
                <IconButton icon="arrow left" onClick={onClickClose} />
              ) : (
                <IconButton icon="arrow right" onClick={onClickOpen} />
              )}
            </ToggleButtonGroup>
          )}
        </Form>
      </Content>
    </Layout>
  );
};

const HEADER_HEIGHT = DIMEN.X7;

const Layout = styled.div`
  height: 100%;
  width: 100%;
`;
const Header = styled.div`
  height: ${HEADER_HEIGHT};
  padding: ${DIMEN.X1} ${DIMEN.X1_5};
  border-bottom: 3px double ${COLOR.Gray.base};
`;
const Content = styled.div`
  height: calc(100% - ${HEADER_HEIGHT});
  display: flex;
  width: 100%;
`;

const LIST_WIDTH = "14rem";
const List = styled.div`
  height: 100%;
  flex-basis: ${(props: { column: number }) => `calc(${LIST_WIDTH} * ${props.column})`};
  overflow-y: auto;
  border-right: 1px solid ${COLOR.Gray.base};
  transition: all 300ms 0s ease;
  &.none {
    opacity: 0;
    flex-basis: 0;
  }
`;

const Form = styled.div`
  height: 100%;
  flex-basis: ${(props: { column: number }) => `calc(100% - (${LIST_WIDTH} * ${props.column}))`};
  overflow-y: auto;
  overflow-x: auto;
  transition: all 300ms 0s ease;
  &.single {
    flex-basis: 100%;
  }
`;

const ToggleButtonGroup = styled.div`
  position: fixed;
  left: ${(props: { column: number }) => `calc(${MENU_HEADER_WIDTH} + ${LIST_WIDTH} * ${props.column} + 3em)`};
  bottom: 2em;
  transition: all 300ms 0s ease;
  &.none {
    left: calc(${MENU_HEADER_WIDTH} + 3em);
  }
`;
