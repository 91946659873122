import { actionCreatorFactory } from "typescript-fsa";
import { WorkReport } from "../../../../domain/workReport";
// Actions
const actionCreator = actionCreatorFactory("ApiWorkReport");

const fetchStart = actionCreator("fetchStart");
const fetchComplete = actionCreator<FetchCompletePayload>("fetchComplete");
interface FetchCompletePayload {
  workReports: WorkReport[];
}
const fetchFail = actionCreator("fetchFail");
const shouldFetch = actionCreator("shouldFetch");

// save
const createWorkReport = actionCreator<CreateWorkReportPayload>("createWorkReport");
interface CreateWorkReportPayload {
  workReport: WorkReport;
}
const updateWorkReport = actionCreator<UpdateWorkReportPayload>("updateWorkReport");
interface UpdateWorkReportPayload {
  workReport: WorkReport;
}
const deleteWorkReport = actionCreator<DeleteWorkReportPayload>("deleteWorkReport");
interface DeleteWorkReportPayload {
  workReport: WorkReport;
}

export const apiWorkReportActions = {
  fetchStart,
  fetchComplete,
  fetchFail,
  shouldFetch,
  createWorkReport,
  updateWorkReport,
  deleteWorkReport,
};

// Reducers
import { FetchHandlers, getInitialState, SaveHandlers, WorkReportStateType } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const WorkReportReducer = reducerWithInitialState<WorkReportStateType>(getInitialState())
  .case(fetchStart, (state) => FetchHandlers.start(state))
  .case(fetchComplete, (state, { workReports }) => FetchHandlers.completeMerge(state, workReports))
  .case(fetchFail, (state) => FetchHandlers.fail(state))
  .case(createWorkReport, (state, { workReport }) => SaveHandlers.createWorkReport(state, workReport))
  .case(updateWorkReport, (state, { workReport }) => SaveHandlers.updateWorkReport(state, workReport))
  .case(deleteWorkReport, (state, { workReport }) => SaveHandlers.deleteWorkReport(state, workReport));
