import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { EditStateService } from "../../../application/master/feed/editState";
import { NewStateService } from "../../../application/master/feed/newState";
import { ReadApiService } from "../../../application/master/feed/readApi";
import { isActiveEntity, isEntity, isNewEntity } from "../../../domain/entity";
import { Feed } from "../../../domain/feed";
import { ApplicationState } from "../../../store/modules";
import { NewOrEditStateType } from "../../../store/modules/master/feed/newOrEditState/reducer";
import { MasterFormDefault } from "../../components/molecules/MasterForm";
import { MasterList } from "../../components/molecules/MasterList";
import { PageHeader } from "../../components/molecules/PageHeader";
import { MainLayout } from "../Layout";
import { MasterLayout } from "../Layout/Master";
import { FeedEditPage } from "./EditPage";
import { FeedNewPage } from "./NewPage";

interface StateProps {
  feeds: Feed[];
  newOrEditState: NewOrEditStateType;
}

interface DispatchProps {
  readApiService: ReadApiService;
  editStateService: EditStateService;
  newStateService: NewStateService;
}

type FeedsPageProps = StateProps & DispatchProps;

export class Wrapped extends React.Component<FeedsPageProps> {
  public componentDidMount(): void {
    this.props.readApiService.init().then(() => {
      const { feed } = this.props.newOrEditState;
      if (feed !== null && isEntity(feed)) {
        this.props.editStateService.selectFeed(feed.id);
      }
    });
  }

  public render() {
    const feed = this.props.newOrEditState.feed;
    let form;
    if (feed !== null) {
      form = isEntity(feed) ? <FeedEditPage /> : <FeedNewPage />;
    } else {
      form = (
        <MasterFormDefault
          onClickAdd={feed !== null && isNewEntity(feed) ? null : this.handleClickAddFeed}
          onClickDelete={feed !== null && isEntity(feed) ? this.handleClickDeleteFeed : null}
        />
      );
    }

    return (
      <MainLayout>
        <MasterLayout
          header={<PageHeader title={"餌"} />}
          list={
            <MasterList
              items={this.props.feeds}
              selectedItemId={feed !== null && isEntity(feed) ? feed.id : null}
              onClickItem={this.handleClickListItem}
            />
          }
          form={form}
        />
      </MainLayout>
    );
  }

  private handleClickListItem = (id: number) => {
    this.props.editStateService.selectFeed(id);
  };

  private handleClickAddFeed = () => {
    this.props.newStateService.createFeed();
  };

  private handleClickDeleteFeed = () => {
    this.props.editStateService.deleteFeed();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    feeds: state.api.feed.feeds.filter(isActiveEntity),
    newOrEditState: state.master.feed.newOrEditState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    readApiService: new ReadApiService(dispatch),
    editStateService: new EditStateService(dispatch),
    newStateService: new NewStateService(dispatch),
  };
};

export const FeedsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);
