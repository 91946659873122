import { Dispatch } from "redux";
import { isEntity } from "../../../domain/entity";
import { WorkerValidator } from "../../../domain/worker";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { WorkerRepository } from "../../../infrastracture/worker/repository";
import { ApplicationState } from "../../../store/modules";
import { apiWorkerActions } from "../../../store/modules/api/worker/ducks";
import { masterWorkerNewOrEditStateActions } from "../../../store/modules/master/worker/newOrEditState/ducks";
import { notificationAlertStateActions } from "../../../store/modules/notification/alert/ducks";
import { notificationQueueStateActions } from "../../../store/modules/notification/queue/ducks";

interface IEditStateService extends IApplicationService {
  selectWorker: (workerId: number) => void;
  changeName: (name: string) => void;
  changeAccountName: (accountName: string) => void;
  changePassword: (password: string) => void;
  cancelWorker: () => void;
  saveWorker: () => void;
  deleteWorker: () => void;
}

export class EditStateService implements IEditStateService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public selectWorker(workerId: number) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const { api } = getState();
      const worker = api.worker.workers.find((w) => w.id === workerId);
      if (!worker) {
        throw new ApplicationError("担当者が見つかりませんでした。");
      }
      this.dispatch(masterWorkerNewOrEditStateActions.selectWorker({ worker }));
    });
  }

  @catchApplicationError()
  public changeName(name: string) {
    this.dispatch(masterWorkerNewOrEditStateActions.changeWorker({ key: "name", value: name }));
  }

  @catchApplicationError()
  public changeAccountName(accountName: string) {
    this.dispatch(masterWorkerNewOrEditStateActions.changeWorker({ key: "accountName", value: accountName }));
  }

  @catchApplicationError()
  public changePassword(password: string) {
    this.dispatch(masterWorkerNewOrEditStateActions.changeWorker({ key: "password", value: password }));
  }

  @catchApplicationError()
  public cancelWorker() {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const { api, master } = getState();
      const editWorker = master.worker.newOrEditState.worker;
      if (editWorker === null || !isEntity(editWorker)) {
        return;
      }
      const worker = api.worker.workers.find((w) => w.id === editWorker.id);
      if (!worker) {
        throw new ApplicationError("担当者が見つかりませんでした。");
      }
      this.dispatch(masterWorkerNewOrEditStateActions.cancelWorker({ worker }));
    });
  }

  @catchApplicationError((dispatch) => dispatch(masterWorkerNewOrEditStateActions.saveFail()))
  public async saveWorker() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(masterWorkerNewOrEditStateActions.saveStart());
      const { newOrEditState } = getState().master.worker;
      const editWorker = newOrEditState.worker;
      if (editWorker === null || !isEntity(editWorker)) {
        throw new ApplicationError("担当者が見つかりませんでした。");
      }
      const validator = new WorkerValidator();
      validator.validate(editWorker);
      if (!validator.isValid()) {
        this.dispatch(notificationAlertStateActions.showErrorMessage({ errorMessage: validator.getMessages() }));
        this.dispatch(masterWorkerNewOrEditStateActions.saveFail());
        return;
      }
      const savedWorker = await new WorkerRepository().putWorker(editWorker);
      this.dispatch(masterWorkerNewOrEditStateActions.saveSuccess({ worker: savedWorker }));
      this.dispatch(apiWorkerActions.updateWorker({ worker: savedWorker }));
      this.dispatch(notificationQueueStateActions.addSaveMessage({ itemName: "担当者" }));
    });
  }

  @catchApplicationError((dispatch) => dispatch(masterWorkerNewOrEditStateActions.saveFail()))
  public async deleteWorker() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(masterWorkerNewOrEditStateActions.saveStart());
      const { newOrEditState } = getState().master.worker;
      const editWorker = newOrEditState.worker;
      if (editWorker === null || !isEntity(editWorker)) {
        throw new ApplicationError("担当者が見つかりませんでした。");
      }
      const savedWorker = await new WorkerRepository().deleteWorker(editWorker);
      this.dispatch(masterWorkerNewOrEditStateActions.saveSuccess({ worker: null }));
      this.dispatch(apiWorkerActions.deleteWorker({ worker: savedWorker }));
      this.dispatch(notificationQueueStateActions.addDeleteMessage({ itemName: "担当者" }));
    });
  }
}
