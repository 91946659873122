import { combineReducers } from "redux";
import { ListStateType } from "./listState/reducer";
import { NewOrEditStateType } from "./newOrEditState/reducer";

export interface MasterParentCarpState {
  listState: ListStateType;
  newOrEditState: NewOrEditStateType;
}

import { ListStateReducer } from "./listState/ducks";
import { NewOrEditStateReducer } from "./newOrEditState/ducks";

export const parentCarpReducer = combineReducers({
  listState: ListStateReducer,
  newOrEditState: NewOrEditStateReducer,
});
