import * as React from "react";
import { Icon, Sidebar } from "semantic-ui-react";
import styled from "styled-components";
import { BottomItem, ClickHandler, Menu, MenuItem } from "./elements";

export type ActiveTabNames = "from_own_ponds" | "from_pond_areas" | "from_workers" | "from_fry_ponds";

interface NavigationSidebarProps {
  activeTabName: ActiveTabNames;
  visible: boolean;
  userName: string;
  onHide: () => void;
  onClickFromOwnPonds: () => void;
  onClickFromPondAreas: () => void;
  onClickFromWorkers: () => void;
  onClickFromFryPonds: () => void;
  onClickLogout: () => void;
}

export const NavigationSidebar = (props: NavigationSidebarProps) => {
  return (
    <Sidebar animation="overlay" visible={props.visible} onHide={props.onHide}>
      <Menu>
        <ClickHandler onClick={props.onHide}>
          <MenuItem
            name="from_own_ponds"
            active={"from_own_ponds" === props.activeTabName}
            onClick={props.onClickFromOwnPonds}>
            <MenuIcon name={"user"} />
            担当の野池一覧
          </MenuItem>
          <MenuItem
            name="from_pond_areas"
            active={"from_pond_areas" === props.activeTabName}
            onClick={props.onClickFromPondAreas}>
            <MenuIcon name={"map"} />
            エリア一覧
          </MenuItem>
          <MenuItem
            name="from_workers"
            active={"from_workers" === props.activeTabName}
            onClick={props.onClickFromWorkers}>
            <MenuIcon name={"users"} />
            他の人の野池一覧
          </MenuItem>
          <MenuItem
            name="from_fry_ponds"
            active={"from_fry_ponds" === props.activeTabName}
            onClick={props.onClickFromFryPonds}>
            <MenuIcon name={"th"} />
            稚魚池一覧
          </MenuItem>
          <BottomItem>
            <MenuItem>
              <MenuIcon name={"user circle"} />
              {props.userName}
            </MenuItem>
            <MenuItem onClick={props.onClickLogout}>
              <MenuIcon name={"sign-out"} />
              ログアウト
            </MenuItem>
          </BottomItem>
        </ClickHandler>
      </Menu>
    </Sidebar>
  );
};

const MenuIcon = styled(Icon)`
  float: none !important;
  margin-right: 1em !important;
`;
