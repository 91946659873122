import * as React from "react";
import { PondType } from "../../../../domain/pondType";
import { Form } from "../../atoms/Form/Form";
import { FormSelect } from "../../atoms/Form/Select";
import { ContentSection } from "../../atoms/Section";
import { Spinner } from "../../atoms/Spinner";
import { ReportFormButton } from "../../molecules/ReportFormButton";
import { WorkReportFormDateBody } from "../../molecules/WorkReportFormDate";
import { ContentNote, WorkReportFormNoteBody } from "../../molecules/WorkReportFormNote";

interface WorkReportFormNoteProps {
  pondTypes: PondType[];
  title: string;
  date: Date;
  note: string;
  imageIds: number[];
  pondTypeId: number;
  onChangeDate: (date: Date) => void;
  onChangeNote: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickImage: (imageIds: number[], imageId: number) => void;
  onClickImageRemove: (id: number) => void;
  onChangePondType: ((id: number | null) => void) | null;
  onClickSave: () => void;
  onClickCancel: () => void;
  isSaving: boolean;
  disabledSave: boolean;
}
export const WorkReportFormNote = (props: WorkReportFormNoteProps) => {
  return (
    <Form>
      {props.isSaving && <Spinner />}
      <WorkReportFormDateBody
        title={props.title}
        date={props.date}
        onChangeDate={props.onChangeDate}
        onClickAdd={null}
        onClickDelete={null}
      />
      <WorkReportFormNoteBody {...props} />
      {!!props.onChangePondType && (
        <>
          <ContentSection>池の区分</ContentSection>
          <ContentNote>
            <FormSelect value={props.pondTypeId} options={props.pondTypes} onChange={props.onChangePondType} />
          </ContentNote>
        </>
      )}
      <ReportFormButton
        onClickSave={props.onClickSave}
        onClickCancel={props.onClickCancel}
        disabledSave={props.disabledSave}
      />
    </Form>
  );
};
