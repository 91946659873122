import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { AuthService } from "../../application/auth";
import { ApplicationState } from "../../store/modules";
import { AuthStateType } from "../../store/modules/auth/reducer";
import { Spinner } from "../components/atoms/Spinner";
import { BaseNavigationContent, BaseNavigationHeader } from "../components/molecules/NavigationHeader";

interface StateType {
  authState: AuthStateType;
}

interface OwnProps {
  children?: any;
}

interface DispatchProps {
  authStateService: AuthService;
}

type WrapperProps = StateType & OwnProps & DispatchProps & RouteComponentProps;

export class Wrapper extends React.Component<WrapperProps> {
  public componentWillMount(): void {
    this.props.authStateService.refresh(() => this.props.history.push("/login"));
  }

  public componentWillReceiveProps(nextProps: WrapperProps): void {
    if (nextProps.authState.isLoggedIn !== this.props.authState.isLoggedIn && !nextProps.authState.isLoggedIn) {
      nextProps.authStateService.refresh(() => nextProps.history.push("/login"));
    }
  }

  public render() {
    if (!this.props.authState.isLoggedIn) {
      return (
        <>
          <BaseNavigationHeader title={"作業記録"} />
          <BaseNavigationContent>
            <Spinner />
          </BaseNavigationContent>
        </>
      );
    }
    return this.props.children;
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StateType & OwnProps => {
  return {
    authState: state.auth,
    ...ownProps,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    authStateService: new AuthService(dispatch),
  };
};

export const AuthenticationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapper));
