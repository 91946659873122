import { actionCreatorFactory } from "typescript-fsa";
import { PondType } from "../../../../domain/pondType";

// Actions
const actionCreator = actionCreatorFactory("ApiPondType");

const fetchStart = actionCreator("fetchStart");
const fetchComplete = actionCreator<FetchCompletePayload>("fetchComplete");
interface FetchCompletePayload {
  pondTypes: PondType[];
}
const fetchFail = actionCreator("fetchFail");
const shouldFetch = actionCreator("shouldFetch");

export const apiPondTypeActions = {
  fetchStart,
  fetchComplete,
  fetchFail,
  shouldFetch,
};

// Reducers
import { FetchHandlers, getInitialState, PondTypeStateType } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const PondTypeReducer = reducerWithInitialState<PondTypeStateType>(getInitialState())
  .case(fetchStart, (state) => FetchHandlers.start(state))
  .case(fetchComplete, (state, { pondTypes }) => FetchHandlers.complete(state, pondTypes))
  .case(fetchFail, (state) => FetchHandlers.fail(state))
  .case(shouldFetch, (state) => FetchHandlers.shouldFetch(state));
