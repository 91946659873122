import {
  Menu as _Menu,
  MenuHeader as _MenuHeader,
  MenuItem as _MenuItem,
  MenuMenu as _MenuMenu,
} from "semantic-ui-react";
import styled from "styled-components";
import { COLOR } from "../../../styles/Color";
import { DIMEN } from "../../../styles/Dimen";

export const OuterImage = styled.div`
  display: flex;
  align-items: center;
  padding: ${DIMEN.X1} ${DIMEN.X2};
  margin-bottom: ${DIMEN.X1};
`;

export const Menu = styled(_Menu).attrs({ vertical: true, borderless: true })`
  background-color: inherit !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 16px !important;
  width: 13rem !important;
`;

export const MenuItem = styled(_MenuItem)`
  color: ${COLOR.Base.white} !important;
`;

export const BottomMenu = styled.div`
  position: absolute !important;
  bottom: 0;
`;

export const MenuHeader = styled(_MenuHeader)`
  color: ${COLOR.AccentColor["lighter-1"]};
`;

export const MenuInMenu = styled(_MenuMenu)`
  padding-left: ${DIMEN.X2};
`;

export const MenuInMenuItem = styled(_MenuItem)`
  color: ${COLOR.Base.white} !important;
`;
