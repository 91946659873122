import * as React from "react";
import styled from "styled-components";
import { NavigationHeaderContainer } from "../../containers/NavigationHeader";
import { COLOR } from "../../styles/Color";
import { DIMEN } from "../../styles/Dimen";

interface MainLayoutProps {
  children?: any;
}
export const MainLayout = (props: MainLayoutProps) => {
  return (
    <Layout>
      <Header>
        <NavigationHeaderContainer />
      </Header>
      <Content>{props.children}</Content>
    </Layout>
  );
};

export const MENU_HEADER_WIDTH = DIMEN.X23;

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;
const Header = styled.div`
  flex-basis: ${MENU_HEADER_WIDTH};
  height: 100%;
  color: ${COLOR.Base.white};
  background-color: ${COLOR.Primary.base};
`;
const Content = styled.div`
  flex-basis: calc(100% - ${MENU_HEADER_WIDTH});
  height: 100%;
  width: calc(100% - ${MENU_HEADER_WIDTH});
`;
