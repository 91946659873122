import * as React from "react";
import styled from "styled-components";
import { DIMEN } from "../../../styles/Dimen";
import { SaveButton } from "../../atoms/Button/Mobile";
import { Form } from "../../atoms/Form/Form";
import { FormTextInput } from "../../atoms/Form/Input";
import { ContentSection, PageSection } from "../../atoms/Section";
import { Spinner } from "../../atoms/Spinner";
import { ReportAmountInput } from "../ReportAmountInput";
import { ReportDateInput } from "../ReportDateInput";

interface PondReportFormDisinfectionProps {
  amount: string;
  date: Date | null;
  note: string;
  onChangeAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickAmountUp: () => void;
  onClickAmountDown: () => void;
  onChangeDate: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickToday: () => void;
  onClickYesterday: () => void;
  onClickYesterdayMinusOne: () => void;
  onChangeNote: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export const PondReportFormDisinfection = (
  props: PondReportFormDisinfectionProps & { onClickSave: () => void; isSaving: boolean }
) => {
  return (
    <Form>
      {props.isSaving && <Spinner />}
      <PageSection>塩素</PageSection>
      <PondReportFormBody {...props} />
      <SaveButton onClick={props.onClickSave} />
    </Form>
  );
};

export const PondReportFormBody = (props: PondReportFormDisinfectionProps) => {
  return (
    <>
      <ContentSection>本数</ContentSection>
      <Content>
        <ReportAmountInput
          amount={props.amount}
          unit={"本"}
          onChangeAmount={props.onChangeAmount}
          onClickAmountUp={props.onClickAmountUp}
          onClickAmountDown={props.onClickAmountDown}
        />
      </Content>
      <ContentSection>日付</ContentSection>
      <Content>
        <ReportDateInput
          date={props.date}
          onChangeDate={props.onChangeDate}
          onClickToday={props.onClickToday}
          onClickYesterday={props.onClickYesterday}
          onClickYesterdayMinusOne={props.onClickYesterdayMinusOne}
        />
      </Content>
      <ContentSection>メモ</ContentSection>
      <Content>
        <FormTextInput value={props.note} onChange={props.onChangeNote} />
      </Content>
    </>
  );
};

const Content = styled.div`
  padding: ${DIMEN.X1};
`;
