import { default as moment } from "moment";
import { EditEnvironmentReport, EnvironmentReport, NewEnvironmentReport } from "../../domain/environmentReport";
import * as api from "../../libs/client";
import { translateApiToEnvironmentReport, translateEnvironmentReportToApi } from "./translate";

interface IEnvironmentReportRepository {
  getEnvironmentReports: (query: GetSearchQuery) => Promise<EnvironmentReport[]>;
  postEnvironmentReport: (environmentReport: NewEnvironmentReport) => Promise<EnvironmentReport>;
  putEnvironmentReport: (environmentReport: EditEnvironmentReport) => Promise<EnvironmentReport>;
  deleteEnvironmentReport: (environmentReport: EnvironmentReport) => Promise<boolean>;
}

interface GetSearchQuery {
  startDate?: Date;
  endDate?: Date;
  pondId?: number;
  limit?: number;
}

export class EnvironmentReportRepository implements IEnvironmentReportRepository {
  public async getEnvironmentReports(query: GetSearchQuery = {}): Promise<EnvironmentReport[]> {
    const environmentReportsApi = new api.EnvironmentReportsApi(api.serverConfig);
    const startDate = moment(query.startDate || "invalid");
    const endDate = moment(query.endDate || "invalid");
    const response = await environmentReportsApi.getEnvironmentReports(
      startDate.isValid() ? startDate.format("YYYY-MM-DD") : undefined,
      endDate.isValid() ? endDate.format("YYYY-MM-DD") : undefined,
      query.pondId,
      query.limit
    );
    return response.data.map((d) => translateApiToEnvironmentReport(d));
  }

  public async postEnvironmentReport(environmentReport: NewEnvironmentReport): Promise<EnvironmentReport> {
    const environmentReportsApi = new api.EnvironmentReportsApi(api.serverConfig);
    const response = await environmentReportsApi.postEnvironmentReport(
      translateEnvironmentReportToApi(environmentReport)
    );
    return translateApiToEnvironmentReport(response.data);
  }

  public async putEnvironmentReport(environmentReport: EditEnvironmentReport): Promise<EnvironmentReport> {
    const environmentReportsApi = new api.EnvironmentReportsApi(api.serverConfig);
    const response = await environmentReportsApi.putEnvironmentReport(
      environmentReport.id,
      translateEnvironmentReportToApi(environmentReport)
    );
    return translateApiToEnvironmentReport(response.data);
  }

  public async deleteEnvironmentReport(environmentReport: EnvironmentReport): Promise<boolean> {
    const environmentReportsApi = new api.EnvironmentReportsApi(api.serverConfig);
    const response = await environmentReportsApi.deleteEnvironmentReport(environmentReport.id);
    return response.data;
  }
}
