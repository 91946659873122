import { Entity } from "../entity";
import { getValidation, isEmptyString, isTextLength, Validator } from "../validation";

interface PondAreaAttribute extends Record<string, unknown> {
  name: string;
  pondIds: number[];
  position: number;
  isDeleted: boolean;
}

export interface NewPondArea extends PondAreaAttribute {}
export interface EditPondArea extends PondAreaAttribute, Entity {}

export type NewOrEditPondArea = NewPondArea | EditPondArea;
export type PondArea = EditPondArea;

// service

export function createNewPondArea(): NewPondArea {
  return {
    name: "",
    pondIds: [],
    position: 1,
    isDeleted: false,
  };
}

/*** Validator ***/
export class PondAreaValidator extends Validator<NewOrEditPondArea> {
  public validate(pondArea: NewOrEditPondArea) {
    const nameValidation = validateName(pondArea);
    if (nameValidation) {
      this.addMessages(nameValidation);
    }
  }
}

function validateName({ name }: NewOrEditPondArea) {
  if (isEmptyString(name)) {
    return getValidation("エリア名は必須です。入力してください。");
  }
  if (!isTextLength(name, 0, 255)) {
    return getValidation("エリア名は255文字以下で入力してください。");
  }
  return null;
}
