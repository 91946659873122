import { CarpVarietyType } from "../../domain/carpVarietyType";
import * as Api from "../../libs/apiClient";

export function translateApiToCarpVarietyType(apiCarpVarietyType: Api.CarpVarietyType): CarpVarietyType {
  return {
    id: apiCarpVarietyType.id,
    name: apiCarpVarietyType.name,
    warningDays1: apiCarpVarietyType.warningDays1,
    warningDays2: apiCarpVarietyType.warningDays2,
    alertDays1: apiCarpVarietyType.alertDays1,
    alertDays2: apiCarpVarietyType.alertDays2,
    position: apiCarpVarietyType.position,
    isDeleted: apiCarpVarietyType.isDeleted,
  };
}

export function translateCarpVarietyToApi(carpVarietyType: CarpVarietyType): Api.CarpVarietyType {
  return {
    id: carpVarietyType.id,
    name: carpVarietyType.name,
    warningDays1: carpVarietyType.warningDays1,
    warningDays2: carpVarietyType.warningDays2,
    alertDays1: carpVarietyType.alertDays1,
    alertDays2: carpVarietyType.alertDays2,
    position: carpVarietyType.position,
    isDeleted: carpVarietyType.isDeleted,
  };
}
