import { default as moment } from "moment";
import { EditPondReport, NewPondReport, PondReport } from "../../domain/pondReport";
import * as api from "../../libs/client";
import { translateApiToPondReport, translatePondReportToApi } from "./translate";

interface IPondReportRepository {
  getPondReports: (query: GetSearchQuery) => Promise<PondReport[]>;
  getRelatedPondReports: (pondReportId: number) => Promise<PondReport[]>;
  postPondReport: (pondReport: NewPondReport) => Promise<PondReport>;
  putPondReport: (pondReport: EditPondReport) => Promise<PondReport>;
  deletePondReport: (pondReport: PondReport) => Promise<boolean>;
}

interface GetSearchQuery {
  startDate?: Date;
  endDate?: Date;
  pondId?: number;
}

export class PondReportRepository implements IPondReportRepository {
  public async getPondReports(query: GetSearchQuery = {}): Promise<PondReport[]> {
    const pondReportsApi = new api.PondReportsApi(api.serverConfig);
    const startDate = moment(query.startDate || "invalid");
    const endDate = moment(query.endDate || "invalid");
    const response = await pondReportsApi.getPondReports(
      startDate.isValid() ? startDate.format("YYYY-MM-DD") : undefined,
      endDate.isValid() ? endDate.format("YYYY-MM-DD") : undefined,
      query.pondId
    );
    return response.data.map((d) => translateApiToPondReport(d));
  }

  public async getRelatedPondReports(pondReportId: number): Promise<PondReport[]> {
    const pondReportsApi = new api.PondReportsApi(api.serverConfig);
    const response = await pondReportsApi.getRelatedPondReports(pondReportId);
    return response.data.map((d) => translateApiToPondReport(d));
  }

  public async postPondReport(pondReport: NewPondReport): Promise<PondReport> {
    const pondReportsApi = new api.PondReportsApi(api.serverConfig);
    const response = await pondReportsApi.postPondReport(translatePondReportToApi(pondReport));
    return translateApiToPondReport(response.data);
  }

  public async putPondReport(pondReport: EditPondReport): Promise<PondReport> {
    const pondReportsApi = new api.PondReportsApi(api.serverConfig);
    const response = await pondReportsApi.putPondReport(pondReport.id, translatePondReportToApi(pondReport));
    return translateApiToPondReport(response.data);
  }

  public async deletePondReport(pondReport: PondReport): Promise<boolean> {
    const pondReportsApi = new api.PondReportsApi(api.serverConfig);
    const response = await pondReportsApi.deletePondReport(pondReport.id);
    return response.data;
  }
}
