import * as React from "react";
import { Message as _Message, Transition } from "semantic-ui-react";
import styled from "styled-components";

interface NotificationProps {
  message: string;
  disappear: () => void;
}
interface NotificationState {
  visible: boolean;
}

export class Notification extends React.Component<NotificationProps, NotificationState> {
  constructor(props: NotificationProps) {
    super(props);

    this.state = {
      visible: true,
    };
  }

  public render() {
    const { message } = this.props;
    return (
      <Transition
        onShow={this.autoDisappear}
        onHide={this.props.disappear}
        transitionOnMount={true}
        visible={this.state.visible}
        animation={"slide down"}
        duration={500}>
        <Message positive={true} onClick={this.disappear}>
          {message}
        </Message>
      </Transition>
    );
  }

  private autoDisappear = () => {
    window.setTimeout(this.disappear, 2000);
  };

  private disappear = () => {
    if (this.state.visible) {
      this.setState({ visible: false });
    }
  };
}

const Message = styled(_Message)`
  min-width: 200px;
`;
