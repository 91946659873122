import { combineReducers } from "redux";
import { NewOrEditStateType } from "./newOrEditState/reducer";

export interface EnvironmentReportStateType {
  newOrEditStateType: NewOrEditStateType;
}
import { environmentReportNewOrEditStateReducer } from "./newOrEditState/ducks";

export const environmentReportStateReducer = combineReducers({
  newOrEditStateType: environmentReportNewOrEditStateReducer,
});
