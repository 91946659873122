import { EnvironmentReport } from "../../../../domain/environmentReport";
import { FetchLifeCycle } from "../../../lifeCycle/fetchLifeCycle";

export interface EnvironmentReportStateType extends FetchLifeCycle {
  environmentReports: EnvironmentReport[];
}

export const getInitialState = (): EnvironmentReportStateType => {
  return { environmentReports: [], isFetched: false, isFetching: false, isError: null };
};

export const FetchHandlers = {
  start(state: EnvironmentReportStateType) {
    return { ...state, isFetched: false, isFetching: true, isError: null };
  },
  complete(state: EnvironmentReportStateType, environmentReports: EnvironmentReport[]) {
    return { ...state, environmentReports, isFetched: true, isFetching: false };
  },
  completeMerge(state: EnvironmentReportStateType, environmentReports: EnvironmentReport[]) {
    const ids = state.environmentReports.map((p) => p.id);
    const newReports = environmentReports.filter((pr) => !ids.includes(pr.id));
    const updateReports = state.environmentReports.map((pr) => {
      const r = environmentReports.find((p) => p.id === pr.id);
      return !!r ? r : pr;
    });
    return { ...state, environmentReports: newReports.concat(updateReports), isFetched: true, isFetching: true };
  },
  fail(state: EnvironmentReportStateType, message: string = "") {
    return { ...state, isFetched: false, isFetching: false, isError: { message } };
  },
  shouldFetch(state: EnvironmentReportStateType): EnvironmentReportStateType {
    return { ...state, isFetched: false };
  },
};

export const SaveHandlers = {
  createEnvironmentReport(
    state: EnvironmentReportStateType,
    environmentReport: EnvironmentReport
  ): EnvironmentReportStateType {
    return { ...state, environmentReports: state.environmentReports.concat(environmentReport) };
  },
  updateEnvironmentReport(
    state: EnvironmentReportStateType,
    environmentReport: EnvironmentReport
  ): EnvironmentReportStateType {
    const environmentReports = state.environmentReports.map((w) =>
      w.id === environmentReport.id ? environmentReport : w
    );
    return { ...state, environmentReports };
  },
  deleteEnvironmentReport(
    state: EnvironmentReportStateType,
    environmentReport: EnvironmentReport
  ): EnvironmentReportStateType {
    const environmentReports = state.environmentReports.filter((w) => w.id !== environmentReport.id);
    return { ...state, environmentReports };
  },
};
