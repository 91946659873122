import * as React from "react";
import styled from "styled-components";
import { CarpVarietyType } from "../../../../domain/carpVarietyType";
import { DIMEN } from "../../../styles/Dimen";
import { SelectButton } from "../../atoms/Button";
import { Form } from "../../atoms/Form/Form";
import { ContentSection, PageSection } from "../../atoms/Section";

interface SortingState {
  isAscDate: boolean;
}

export enum FilterByPondStatus {
  DoneDisinfection = 1,
  NotDoneOneSelection,
  NotDoneTwoSelection,
  NotDoneThreeSelection,
}

interface FilterState {
  isOnlyEmptyPond: boolean;
  selectedPondStatusIds: number[];
  selectedCarpVarietyTypeIds: number[];
}

interface PondFilterFormProps {
  carpVarietyTypes: CarpVarietyType[];
  sortingState: SortingState;
  filterState: FilterState;

  onChangeSortDate: (isAscDate: boolean) => void;
  onChangeOnlyEmptyPond: () => void;
  onChangePondStatus: (pondStatusId: number) => void;
  onChangeCarpVarietyType: (carpVarietyTypeId: number) => void;
}
export const PondFilterForm = (props: PondFilterFormProps) => {
  const {
    sortingState,
    filterState,
    onChangeSortDate,
    onChangeOnlyEmptyPond,
    onChangePondStatus,
    onChangeCarpVarietyType,
  } = props;
  return (
    <Form>
      <PageSection>ソート</PageSection>
      <ContentSection>日付</ContentSection>
      <Content>
        <Button active={sortingState.isAscDate} onClick={() => onChangeSortDate(true)}>
          古い順
        </Button>
        <Button active={!sortingState.isAscDate} onClick={() => onChangeSortDate(false)}>
          新しい順
        </Button>
      </Content>
      <PageSection>絞り込み</PageSection>
      <ContentSection>空の野池</ContentSection>
      <Content>
        <Button active={filterState.isOnlyEmptyPond} onClick={onChangeOnlyEmptyPond}>
          空の野池のみ
        </Button>
      </Content>
      <ContentSection>選別記録のステータス</ContentSection>
      <Content>
        <Button
          active={filterState.selectedPondStatusIds.includes(FilterByPondStatus.DoneDisinfection)}
          onClick={() => onChangePondStatus(FilterByPondStatus.DoneDisinfection)}>
          塩素中
        </Button>
        <Button
          active={filterState.selectedPondStatusIds.includes(FilterByPondStatus.NotDoneOneSelection)}
          onClick={() => onChangePondStatus(FilterByPondStatus.NotDoneOneSelection)}>
          第1選別未実施
        </Button>
        <Button
          active={filterState.selectedPondStatusIds.includes(FilterByPondStatus.NotDoneTwoSelection)}
          onClick={() => onChangePondStatus(FilterByPondStatus.NotDoneTwoSelection)}>
          第2選別未実施
        </Button>
        <Button
          active={filterState.selectedPondStatusIds.includes(FilterByPondStatus.NotDoneThreeSelection)}
          onClick={() => onChangePondStatus(FilterByPondStatus.NotDoneThreeSelection)}>
          第3選別未実施
        </Button>
      </Content>
      <ContentSection>品種</ContentSection>
      <Content>
        {props.carpVarietyTypes.map((v) => (
          <Button
            key={v.id}
            active={filterState.selectedCarpVarietyTypeIds.includes(v.id)}
            onClick={() => onChangeCarpVarietyType(v.id)}>
            {v.name}
          </Button>
        ))}
      </Content>
    </Form>
  );
};
const Content = styled.div`
  padding: ${DIMEN.X1};
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const Button = styled(SelectButton)`
  min-width: 10em;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
`;
