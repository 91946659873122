import * as _ from "lodash";
import { Entity } from "../entity";
import { PondArea } from "../pondArea";
import { isAdultPondType, isFryPondType, PondType } from "../pondType";
import { getValidation, isEmptyString, isTextLength, Validation, Validator } from "../validation";

interface PondAttribute extends Record<string, unknown> {
  name: string;
  pondAreaId: null | number;
  pondTypeId: null | number;
  pondOwnerTypeIds: number[];
  userIds: number[];
  position: number | null;
  isDeleted: boolean;
  isUnused: boolean;
}

export interface NewPond extends PondAttribute {}
export interface EditPond extends PondAttribute, Entity {}

export type NewOrEditPond = NewPond | EditPond;
export interface Pond extends EditPond {
  position: number;
}

// service

export function createNewPond(pondAreaId: null | number): NewPond {
  return {
    name: "",
    pondAreaId,
    pondTypeId: null,
    pondOwnerTypeIds: [],
    userIds: [],
    position: 1,
    isDeleted: false,
    isUnused: false,
  };
}

export function isFryPond(pond: NewOrEditPond, pondTypes: PondType[]): boolean {
  const pondType = pondTypes.find((t) => t.id === pond.pondTypeId);
  return !!pondType ? isFryPondType(pondType) : false;
}

export function isAdultPond(pond: NewOrEditPond, pondTypes: PondType[]): boolean {
  const pondType = pondTypes.find((t) => t.id === pond.pondTypeId);
  return !!pondType ? isAdultPondType(pondType) : false;
}

export function isUsedPond(pond: NewOrEditPond): boolean {
  return !pond.isUnused;
}

function getNextOrPrevPond(
  pondId: number,
  isNext: boolean,
  api: { ponds: Pond[]; pondAreas: PondArea[] }
): Pond | null {
  let ponds: Pond[];
  if (api.pondAreas.length === 0) {
    ponds = api.ponds;
  } else {
    ponds = _.flatten(api.pondAreas.map((area) => {
      return api.ponds.filter((p) => area.pondIds.includes(p.id));
    }));
  }

  const currentPondIndex = ponds.findIndex((p) => p.id === pondId);

  if (currentPondIndex < 0) {
    return null;
  }

  const targetPondIndex = isNext ? currentPondIndex + 1 : currentPondIndex - 1;
  if (0 <= targetPondIndex && targetPondIndex < ponds.length) {
    const targetPond = ponds[targetPondIndex];
    // 現在の池が末尾の場合はnull
    return targetPond || null;
  }
  return null;
}

export function getNextPond(pondId: number, api: { ponds: Pond[]; pondAreas: PondArea[] }): Pond | null {
  return getNextOrPrevPond(pondId, true, api);
}

export function getPrevPond(pondId: number, api: { ponds: Pond[]; pondAreas: PondArea[] }): Pond | null {
  return getNextOrPrevPond(pondId, false, api);
}

/*** Validator ***/
export class PondValidator extends Validator<NewOrEditPond> {
  public validate(pond: NewOrEditPond) {
    const nameValidation = validateName(pond);
    if (nameValidation) {
      this.addMessages(nameValidation);
    }
    const workerValidation = validateWorker(pond);
    if (workerValidation) {
      this.addMessages(workerValidation);
    }
    const positionValidation = validatePosition(pond);
    if (positionValidation) {
      this.addMessages(positionValidation);
    }
  }
}

function validateName({ name }: NewOrEditPond): Validation | null {
  if (isEmptyString(name)) {
    return getValidation("野池名は必須です。入力してください。");
  }
  if (!isTextLength(name, 0, 255)) {
    return getValidation("野池名は255文字以下で入力してください。");
  }
  return null;
}

function validateWorker({ userIds }: NewOrEditPond): Validation | null {
  if (userIds.length === 0) {
    return getValidation("担当者は必須です。");
  }
  return null;
}

function validatePosition({ position }: NewOrEditPond): Validation | null {
  if (position === null) {
    return getValidation("並び順は必須です。入力してください。");
  }
  return null;
}
