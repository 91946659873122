import styled from "styled-components";
import { COLOR } from "../../../styles/Color";
import { DIMEN } from "../../../styles/Dimen";

export const GroupListItem = styled.div`
  padding: ${DIMEN.X1} ${DIMEN.X3} ${DIMEN.X1} ${DIMEN.X1};
  border-bottom: 1px solid ${COLOR.Gray.base};
  cursor: pointer;
  position: relative;
  :hover {
    background-color: ${COLOR.Gray["lighter-3"]};
  }
  &.active {
    font-weight: bold;
    background-color: ${COLOR.Gray["lighter-2"]};
  }
  &:after {
    width: 0.8em;
    height: 0.8em;
    position: absolute;
    transform: rotate(-45deg);
    top: calc(50% - 0.4em);
    right: 1em;
    content: "";
    border-right: 2px solid ${COLOR.Gray.base};
    border-bottom: 2px solid ${COLOR.Gray.base};
  }

  &.mobile {
    padding-left: ${DIMEN.X2};
    border-bottom: 1px solid ${COLOR.Gray["lighter-1"]};
  }

  &.large {
    padding-top: ${DIMEN.X2};
    padding-bottom: ${DIMEN.X2};
    padding-left: ${DIMEN.X2};
  }
`;
