const Primary = {
  "darken-1": "#212121",
  base: "#313B3E",
  "lighter-1": "#7F7F7F",
};

const AccentColor = {
  "darken-1": "#B20D13",
  base: "#E64D3D",
  "lighter-1": "#E64D3D",
};

const Gray = {
  "darken-1": "#909090",
  base: "#e0e0e0",
  "lighter-1": "#f0f0f0",
  "lighter-2": "#f2f2f2",
  "lighter-3": "#fafafa",
};

const Base = {
  white: "#fff",
  black: "#313B3E",
};

const ReportColor = {
  Feed: "#FFB367",
  Fertilizer: "#A08043",
  Herbicide: "#76D34A",
  Temperature: "#5AC2FF",
  FryCarp: "#E64D3D",
  AdultCarp: "#6C6C6C",
};

export const COLOR = {
  Primary,
  AccentColor,
  Gray,
  Base,
  ReportColor,
};
