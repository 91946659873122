import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NavigationStateService } from "../../../application/report/navigation";
import { NewOrEditStateService } from "../../../application/report/workReport/newOrEditState";
import { isSameFiscalYear } from "../../../domain/calendar";
import { Pond } from "../../../domain/pond";
import { PondArea } from "../../../domain/pondArea";
import { PondType } from "../../../domain/pondType";
import { SearchQuery } from "../../../domain/searchQuery";
import { ApplicationState } from "../../../store/modules";
import { ImageStateType } from "../../../store/modules/api/image/reducer";
import { imageGalleryStateActions } from "../../../store/modules/imageGallery/ducks";
import { notificationQueueStateActions } from "../../../store/modules/notification/queue/ducks";
import { NavigationStateType } from "../../../store/modules/report/navigation/reducer";
import { NewOrEditStateType } from "../../../store/modules/report/workReport/newOrEditState/reducer";
import { PageHeader } from "../../components/molecules/PageHeader";
import { WorkReportFormNote } from "../../components/organisms/WorkReportFormNote";
import { MainLayout } from "../Layout";
import { ReportLayout } from "../Layout/Report";
import { DefaultPage } from "./DefaultPage";
import { PondsList } from "./PondsList";

interface StateProps {
  ponds: Pond[];
  pondAreas: PondArea[];
  pondTypes: PondType[];
  newOrEditState: NewOrEditStateType;
  navigationState: NavigationStateType;
  imageState: ImageStateType;
}

interface DispatchProps {
  newOrEditStateService: NewOrEditStateService;
  navigationStateService: NavigationStateService;
  showImageGallery: (imageIds: number[], imageId: number) => void;

  notifySaveMessage: () => void;
}

type WorkReportNotePageProps = StateProps & DispatchProps & RouteComponentProps;

export class Wrapped extends React.Component<WorkReportNotePageProps> {
  public componentWillMount(): void {
    this.initStateOnMounted();
  }

  public render() {
    return (
      <MainLayout>
        <ReportLayout
          header={<PageHeader title={"メモ"} />}
          list={<PondsList />}
          form={this.renderForm()}
          listColumn={2}
        />
      </MainLayout>
    );
  }

  private async initStateOnMounted() {
    await this.props.navigationStateService.fetchApi();
    if (this.props.navigationState.selectedPondId !== null) {
      await this.props.newOrEditStateService.initWorkReport();
    }
  }

  private renderForm() {
    const { newOrEditState, navigationState } = this.props;
    const { selectedPondId, selectedDate } = navigationState;
    if (selectedPondId === null) {
      this.renderEmpty();
    }
    const { workReport, isSaving } = newOrEditState;

    if (workReport === null) {
      return this.renderEmpty();
    }

    return (
      <WorkReportFormNote
        pondTypes={this.props.pondTypes}
        title={this.getFormTitle()}
        date={selectedDate}
        note={workReport.note}
        imageIds={workReport.imageIds}
        pondTypeId={workReport.pondTypeId}
        onChangeDate={this.handleChangeDate}
        onChangeNote={this.handleChangeNote}
        onChangeFile={this.handleChangeFile}
        onClickImage={this.handleClickImage}
        onClickImageRemove={this.handleClickImageRemove}
        onChangePondType={isSameFiscalYear(workReport.date, new Date()) ? null : this.handleChangePondType}
        onClickSave={this.handleClickSave}
        onClickCancel={this.handleClickCancel}
        isSaving={isSaving}
        disabledSave={this.props.imageState.isSaving}
      />
    );
  }

  private getFormTitle(): string {
    const { ponds, pondAreas, navigationState } = this.props;
    const { selectedPondId, selectedPondAreaId } = navigationState;
    const pond = ponds.find((p) => p.id === selectedPondId);
    const pondArea = pondAreas.find((a) => a.id === selectedPondAreaId);

    return `${pondArea ? pondArea.name : ""} ${pond ? pond.name : ""}`;
  }

  private renderEmpty() {
    return <DefaultPage />;
  }

  private handleChangeDate = async (date: Date) => {
    this.props.navigationStateService.selectDate(date);
    await this.props.newOrEditStateService.initWorkReport();
  };

  private handleChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.props.newOrEditStateService.changeNote(e.target.value);
  };

  private handleClickImage = (imageIds: number[], imageId: number) => {
    this.props.showImageGallery(imageIds, imageId);
  };

  private handleClickImageRemove = (id: number) => {
    this.props.newOrEditStateService.removeImage(id);
  };

  private handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length !== 0) {
      this.props.newOrEditStateService.addImage(e.target.files[0]);
    }
  };

  private handleChangePondType = (id: number | null) => {
    if (id === null) {
      return;
    }
    this.props.newOrEditStateService.changePondType(id);
  };

  private handleClickSave = async () => {
    await this.props.newOrEditStateService.saveWorkReport();
    if (this.props.newOrEditState.isSaved) {
      this.props.notifySaveMessage();
      const returnUrl = SearchQuery.getReturnURLFromState(this.props.location.search);
      if (!returnUrl) {
        return;
      }
      const url = SearchQuery.createURLFromState("", this.props.location.search);
      if (url !== "") {
        this.props.history.push(url);
        return;
      }
    }
  };

  private handleClickCancel = () => {
    this.props.newOrEditStateService.cancelWorkReport();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { workReport, navigation } = state.report;
  return {
    ponds: state.api.pond.ponds,
    pondAreas: state.api.pondArea.pondAreas,
    pondTypes: state.api.pondType.pondTypes,
    newOrEditState: workReport.newOrEditStateType,
    navigationState: navigation,
    imageState: state.api.image,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    newOrEditStateService: new NewOrEditStateService(dispatch),
    navigationStateService: new NavigationStateService(dispatch),
    showImageGallery: (imageIds, imageId) =>
      dispatch(imageGalleryStateActions.showImages({ imageIds, initialImageId: imageId })),
    notifySaveMessage: () => dispatch(notificationQueueStateActions.addSaveMessage({ itemName: "作業メモ" })),
  };
};

export const WorkReportNotePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
