import { actionCreatorFactory } from "typescript-fsa";
import { NewOrEditAdultCarpReport } from "../../../../../domain/adultCarpReport";
import { NewCarpMovingReport, NewOrEditCarpMovingReport } from "../../../../../domain/carpMovingReport";
import { NewOrEditCarpSelectionReport } from "../../../../../domain/carpSelectionReport";
import { UniqueKey } from "../../../../../domain/entity";
import { NewOrEditPondDisinfectionReport } from "../../../../../domain/pondDisinfectionReport";
import { NewOrEditPondReport, PondReport } from "../../../../../domain/pondReport";

// Actions
const actionCreator = actionCreatorFactory("MobilePondReportNewOrEditState");

const initialStart = actionCreator("initialStart");
const initialComplete = actionCreator<InitialPondReportPayload>("initialComplete");
interface InitialPondReportPayload {
  pondReport: NewOrEditPondReport;
}
const initialFailed = actionCreator("initialFailed");
const resetState = actionCreator("resetState");

const changePondReport = actionCreator<ChangePondReportPayload>("changePondReport");
interface ChangePondReportPayload {
  key: keyof NewOrEditPondReport;
  value: any;
}
const changeCarpMovingReport = actionCreator<ChangeCarpMovingReportPayload>("changeCarpMovingReport");
interface ChangeCarpMovingReportPayload {
  movingUniqueKey: UniqueKey;
  key: keyof NewOrEditCarpMovingReport;
  value: any;
}
const changeCarpSelectionReport = actionCreator<ChangeCarpSelectionReportPayload>("changeCarpSelectionReport");
interface ChangeCarpSelectionReportPayload {
  selectionUniqueKey: UniqueKey;
  key: keyof NewOrEditCarpSelectionReport;
  value: any;
}
const changePondDisinfectionReport = actionCreator<ChangePondDisinfectionReportPayload>("changePondDisinfectionReport");
interface ChangePondDisinfectionReportPayload {
  disinfectionUniqueKey: UniqueKey;
  key: keyof NewOrEditPondDisinfectionReport;
  value: any;
}
const changeAdultCarpReport = actionCreator<ChangeAdultCarpReportPayload>("changeAdultCarpReport");
interface ChangeAdultCarpReportPayload {
  adultUniqueKey: UniqueKey;
  key: keyof NewOrEditAdultCarpReport;
  value: any;
}

const addCarpMovingReport = actionCreator<AddCarpMovingReportPayload>("addCarpMovingReport");
interface AddCarpMovingReportPayload {
  carpMovingReport: NewCarpMovingReport;
}

// save
const saveStart = actionCreator("saveStart");
const saveSuccess = actionCreator<SaveSuccessPayload>("saveSuccess");
interface SaveSuccessPayload {
  pondReport: PondReport;
}
const saveFail = actionCreator("saveFail");

export const pondReportNewOrEditStateActions = {
  initialStart,
  initialComplete,
  initialFailed,
  resetState,
  changePondReport,
  changeCarpMovingReport,
  changeCarpSelectionReport,
  changePondDisinfectionReport,
  changeAdultCarpReport,
  addCarpMovingReport,
  saveStart,
  saveSuccess,
  saveFail,
};

// Reducers
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { ChangeHandler, getInitialState, InitialHandler, NewOrEditStateType, SaveHandler } from "./reducer";

export const pondReportNewOrEditStateReducer = reducerWithInitialState<NewOrEditStateType>(getInitialState())
  .case(initialStart, (state) => InitialHandler.initialStart(state))
  .case(initialComplete, (state, { pondReport }) => InitialHandler.initialComplete(state, pondReport))
  .case(initialFailed, (state) => InitialHandler.initialFailed(state))
  .case(resetState, (state) => InitialHandler.resetState(state))
  .case(changePondReport, (state, { key, value }) => ChangeHandler.changePondReport(state, key, value))
  .case(changeCarpMovingReport, (state, { movingUniqueKey, key, value }) => {
    return ChangeHandler.changeCarpMovingReport(state, movingUniqueKey, key, value);
  })
  .case(changeCarpSelectionReport, (state, { selectionUniqueKey, key, value }) => {
    return ChangeHandler.changeCarpSelectionReport(state, selectionUniqueKey, key, value);
  })
  .case(changePondDisinfectionReport, (state, { disinfectionUniqueKey, key, value }) => {
    return ChangeHandler.changePondDisinfectionReport(state, disinfectionUniqueKey, key, value);
  })
  .case(changeAdultCarpReport, (state, { adultUniqueKey, key, value }) => {
    return ChangeHandler.changeAdultCarpReport(state, adultUniqueKey, key, value);
  })
  .case(addCarpMovingReport, (state, { carpMovingReport }) => {
    return ChangeHandler.addCarpMovingReport(state, carpMovingReport);
  })
  .case(saveStart, (state) => SaveHandler.saveStart(state))
  .case(saveSuccess, (state, { pondReport }) => SaveHandler.saveSuccess(state, pondReport))
  .case(saveFail, (state) => SaveHandler.saveFail(state));
