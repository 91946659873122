import { default as moment } from "moment";
import { Entity, generateUid, isEntity, isNewEntity, NewEntity, sortUniqueKeyDesc } from "../entity";
import { FeedReport, FeedReportValidator, NewFeedReport, NewOrEditFeedReport } from "../feedReport";
import {
  FertilizerReport,
  FertilizerReportValidator,
  NewFertilizerReport,
  NewOrEditFertilizerReport,
} from "../fertilizerReport";
import {
  HerbicideReport,
  HerbicideReportValidator,
  NewHerbicideReport,
  NewOrEditHerbicideReport,
} from "../herbicideReport";
import { UseMethodType } from "../useMethodType";
import { getValidation, Validation, Validator } from "../validation";

interface WorkReportAttribute extends Record<string, unknown> {
  pondId: number;
  userId: number;
  pondTypeId: number;
  date: Date;
  note: string;
  imageIds: number[];
  feedReports: NewOrEditFeedReport[];
  fertilizerReports: NewOrEditFertilizerReport[];
  herbicideReports: NewOrEditHerbicideReport[];
}

export interface NewWorkReport extends WorkReportAttribute, NewEntity {
  feedReports: NewFeedReport[];
  fertilizerReports: NewFertilizerReport[];
  herbicideReports: NewHerbicideReport[];
}
export interface EditWorkReport extends WorkReportAttribute, Entity {}

export type NewOrEditWorkReport = NewWorkReport | EditWorkReport;
export type WorkReport = EditWorkReport;

// service
const NEW_ENTITY_PREFIX = "wr-";

export function createNewWorkReport(pondId: number, userId: number, pondTypeId: number, date: Date): NewWorkReport {
  return {
    uid: generateUid(NEW_ENTITY_PREFIX),
    pondId,
    userId,
    pondTypeId,
    date,
    note: "",
    imageIds: [],
    feedReports: [],
    fertilizerReports: [],
    herbicideReports: [],
  };
}

export function isNewWorkReport(report: NewOrEditWorkReport): report is NewWorkReport {
  return isNewEntity(report);
}

export function isEditWorkReport(report: NewOrEditWorkReport): report is EditWorkReport {
  return isEntity(report);
}

export function filterWorkReportByDates(report: NewOrEditWorkReport, date: Date): boolean {
  const reportDate = moment(report.date);
  return reportDate.isSame(date, "date");
}
export function filterWorkReportByPond(report: NewOrEditWorkReport, pondId: null | number): boolean {
  return report.pondId === pondId;
}

interface FilterCriteria {
  date?: Date;
  pondId?: null | number;
}
export function filterWorkReportsByCriteria(
  workReports: NewOrEditWorkReport[],
  { date, pondId }: FilterCriteria
): NewOrEditWorkReport[] {
  let reports = workReports;
  if (typeof date !== "undefined") {
    reports = reports.filter((r) => filterWorkReportByDates(r, date));
  }
  if (typeof pondId !== "undefined") {
    reports = reports.filter((r) => filterWorkReportByPond(r, pondId));
  }
  return reports;
}

interface EmptyCriteria {
  isDisplayFeedReport: boolean;
  isDisplayFertilizerReport: boolean;
  isDisplayHerbicideReport: boolean;
  isDisplayNoteReport: boolean;
}

export function filterEmptyWorkReport(
  workReport: NewOrEditWorkReport,
  { isDisplayFeedReport, isDisplayFertilizerReport, isDisplayHerbicideReport, isDisplayNoteReport }: EmptyCriteria
): boolean {
  return (
    (isDisplayFeedReport && workReport.feedReports.length !== 0) ||
    (isDisplayFertilizerReport && workReport.fertilizerReports.length !== 0) ||
    (isDisplayHerbicideReport && workReport.herbicideReports.length !== 0) ||
    (isDisplayNoteReport && workReport.imageIds.length !== 0) ||
    (isDisplayNoteReport && workReport.note !== "")
  );
}

function sortWorkReportByDate(a: NewOrEditWorkReport, b: NewOrEditWorkReport, isAsc: boolean): number {
  const asc = isAsc ? 1 : -1;
  const dateA = moment(a.date);
  const dateB = moment(b.date);
  if (dateA.isSame(dateB)) {
    return 0;
  }

  const diff = dateA.isBefore(b.date, "date") ? -1 : 1;
  return diff * asc;
}

export function sortWorkReportByDateAsc(a: NewOrEditWorkReport, b: NewOrEditWorkReport): number {
  return sortWorkReportByDate(a, b, true);
}
export function sortWorkReportByDateDesc(a: NewOrEditWorkReport, b: NewOrEditWorkReport): number {
  return sortWorkReportByDate(a, b, false);
}

export function getLatestFeedReport(reports: WorkReport[], date: Date): FeedReport | null {
  const momentDate = moment(date);
  const descendingReports = reports.concat().sort(sortWorkReportByDateDesc);
  const report = descendingReports.find((r) => {
    const reportDate = moment(r.date);
    if (reportDate.isAfter(momentDate, "date")) {
      return false;
    }
    return r.feedReports.length > 0;
  });
  if (!report) {
    return null;
  }
  const feedReport = report.feedReports.concat().sort(sortUniqueKeyDesc)[0] as FeedReport;
  return feedReport || null;
}

export function getLatestFertilizerReport(reports: WorkReport[], date: Date): FertilizerReport | null {
  const momentDate = moment(date);
  const descendingReports = reports.concat().sort(sortWorkReportByDateDesc);
  const report = descendingReports.find((r) => {
    const reportDate = moment(r.date);
    if (reportDate.isAfter(momentDate, "date")) {
      return false;
    }
    return r.fertilizerReports.length > 0;
  });
  if (!report) {
    return null;
  }
  const fertilizerReport = report.fertilizerReports.concat().sort(sortUniqueKeyDesc)[0] as FertilizerReport;
  return fertilizerReport || null;
}

export function getLatestHerbicideReport(reports: WorkReport[], date: Date): HerbicideReport | null {
  const momentDate = moment(date);
  const descendingReports = reports.concat().sort(sortWorkReportByDateDesc);
  const report = descendingReports.find((r) => {
    const reportDate = moment(r.date);
    if (reportDate.isAfter(momentDate, "date")) {
      return false;
    }
    return r.herbicideReports.length > 0;
  });
  if (!report) {
    return null;
  }
  const herbicideReport = report.herbicideReports.concat().sort(sortUniqueKeyDesc)[0] as HerbicideReport;
  return herbicideReport || null;
}

/*** Validator ***/
export class WorkReportValidator extends Validator<NewOrEditWorkReport> {
  constructor(private useMethodTypes: UseMethodType[]) {
    super();
  }

  public validate(entity: NewOrEditWorkReport) {
    const dateValidation = validateDate(entity);
    if (dateValidation) {
      this.addMessages(dateValidation);
    }
    const pondValidation = validatePond(entity);
    if (pondValidation) {
      this.addMessages(pondValidation);
    }
    const pondTypeValidation = validatePondType(entity);
    if (pondTypeValidation) {
      this.addMessages(pondTypeValidation);
    }
    const workerValidation = validateWorker(entity);
    if (workerValidation) {
      this.addMessages(workerValidation);
    }

    entity.feedReports.forEach((feedRepo, index) => {
      const feedReportValidator = new FeedReportValidator(this.useMethodTypes);
      feedReportValidator.validate(feedRepo);
      if (!feedReportValidator.isValid()) {
        this.addMessages(getValidation(`${index + 1}番目の給餌記録 : ${feedReportValidator.getMessages()}`));
      }
    });
    entity.fertilizerReports.forEach((fertilizerRepo, index) => {
      const fertilizerReportValidator = new FertilizerReportValidator();
      fertilizerReportValidator.validate(fertilizerRepo);
      if (!fertilizerReportValidator.isValid()) {
        this.addMessages(getValidation(`${index + 1}番目の肥料記録 : ${fertilizerReportValidator.getMessages()}`));
      }
    });
    entity.herbicideReports.forEach((herbicideRepo, index) => {
      const herbicideReportValidator = new HerbicideReportValidator();
      herbicideReportValidator.validate(herbicideRepo);
      if (!herbicideReportValidator.isValid()) {
        this.addMessages(
          getValidation(`${index + 1}番目の除草剤・薬品記録 : ${herbicideReportValidator.getMessages()}`)
        );
      }
    });
  }
}

function validateDate({ date }: NewOrEditWorkReport): Validation | null {
  if (date === null) {
    return getValidation("日付は必須です。入力してください。");
  }
  return null;
}

function validatePond({ pondId }: NewOrEditWorkReport): Validation | null {
  if (pondId === null) {
    return getValidation("野池は必須です。");
  }
  return null;
}

function validatePondType({ pondTypeId }: NewOrEditWorkReport): Validation | null {
  if (pondTypeId === null) {
    return getValidation("池の区分は必須です。");
  }
  return null;
}
function validateWorker({ userId }: NewOrEditWorkReport): Validation | null {
  if (userId === null) {
    return getValidation("担当者は必須です。");
  }
  return null;
}
