import { isEntity } from "../../domain/entity";
import { Image, NewOrEditImage } from "../../domain/image";
import * as Api from "../../libs/apiClient";

export function translateApiToImage(apiImage: Api.Image): Image {
  return {
    id: apiImage.id as number,
    fileName: apiImage.fileName,
    originalFileName: apiImage.originalFileName,
    contentType: apiImage.contentType,
    activated: apiImage.activated,
    redirectURL: apiImage.redirectURL || undefined,
  };
}

export function translateImageToApi(image: NewOrEditImage): Api.Image {
  return {
    id: isEntity(image) ? image.id : undefined,
    fileName: image.fileName,
    originalFileName: image.originalFileName,
    contentType: image.contentType,
    activated: image.activated,
  };
}
