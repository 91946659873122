import { default as moment } from "moment";
import * as React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";
import { AdultCarpReport } from "../../../../domain/adultCarpReport";
import { CarpMovingReport } from "../../../../domain/carpMovingReport";
import { CarpSelectionReport, isReleaseCarpSelectionReport } from "../../../../domain/carpSelectionReport";
import { getUniqueKey } from "../../../../domain/entity";
import { EnvironmentReport } from "../../../../domain/environmentReport";
import { Feed } from "../../../../domain/feed";
import { FeedReasonType } from "../../../../domain/feedReasonType";
import { FeedReport } from "../../../../domain/feedReport";
import { Fertilizer } from "../../../../domain/fertilizer";
import { FertilizerReport } from "../../../../domain/fertilizerReport";
import { Herbicide } from "../../../../domain/herbicide";
import { HerbicideReport } from "../../../../domain/herbicideReport";
import { getImageSrcURL } from "../../../../domain/image";
import { ParentCarp } from "../../../../domain/parentCarp";
import { Pond } from "../../../../domain/pond";
import { PondDisinfectionReport } from "../../../../domain/pondDisinfectionReport";
import { PondReport } from "../../../../domain/pondReport";
import { ScaleType } from "../../../../domain/scaleType";
import {
  mapAdultCarpReportToAdultSelectionReportVM,
  mapMovingReportToFrySelectionReportVM,
  mapSelectionReportToFrySelectionReportVM,
} from "../../../../domain/selectionReportVM";
import { isFeederUseMethodType, UseMethodType } from "../../../../domain/useMethodType";
import { WorkReport } from "../../../../domain/workReport";
import { COLOR } from "../../../styles/Color";
import { DIMEN } from "../../../styles/Dimen";
import { CardContent, CardHeader as _CardHeader, FluidCard } from "../../atoms/Card";
import { Grid, GridColumn, GridRow } from "../../atoms/Grid";
import { Image } from "../../atoms/Image";
import { TextArea } from "../../atoms/Text";

interface ReportListDetailContentProps {
  date: Date;
  workReports: WorkReport[];
  pondReports: PondReport[];
  environmentReports: EnvironmentReport[];
  ponds: Pond[];
  pairPondReports: PondReport[];
  parentCarps: ParentCarp[];
  feeds: Feed[];
  feedReasonTypes: FeedReasonType[];
  fertilizers: Fertilizer[];
  herbicides: Herbicide[];
  useMethodTypes: UseMethodType[];
  scaleTypes: ScaleType[];
  isDisplayFeedReport: boolean;
  isDisplayFertilizerReport: boolean;
  isDisplayHerbicideReport: boolean;
  isDisplayNoteReport: boolean;
  onClickWorkReport: (workReportId: number, reportKey: "feed" | "fertilizer" | "herbicide", itemId: number) => void;
  onClickWorkReportNote: () => void;
  onClickWorkReportImage: (imageIds: number[], imageId: number) => void;
  onClickPondReport: (
    pondReportId: number,
    reportKey: "disinfection" | "selection" | "moving" | "adultCarp",
    itemId: number
  ) => void;
  onClickEnvironmentReport: (environmentReportId: number) => void;
}

export const ReportListDetailContent = (props: ReportListDetailContentProps) => {
  const isEmpty = isEmptyList(props.workReports, props.pondReports, props.environmentReports, props.date);
  if (isEmpty) {
    return null;
  }

  return (
    <ContentOuter>
      {props.workReports.map((wr) => (
        <React.Fragment key={wr.id}>
          {props.isDisplayFeedReport && (
            <FeedReportItem
              feeds={props.feeds}
              feedReasonTypes={props.feedReasonTypes}
              useMethodTypes={props.useMethodTypes}
              feedReports={wr.feedReports as FeedReport[]}
              onClickFeedReport={(feedReportId) => props.onClickWorkReport(wr.id, "feed", feedReportId)}
            />
          )}
          {props.isDisplayFertilizerReport && (
            <FertilizerReportItem
              fertilizers={props.fertilizers}
              useMethodTypes={props.useMethodTypes}
              fertilizerReports={wr.fertilizerReports as FertilizerReport[]}
              onClickFertilizerReport={(fertilizerReportId) =>
                props.onClickWorkReport(wr.id, "fertilizer", fertilizerReportId)
              }
            />
          )}
          {props.isDisplayHerbicideReport && (
            <HerbicideReportItem
              herbicides={props.herbicides}
              useMethodTypes={props.useMethodTypes}
              herbicideReports={wr.herbicideReports as HerbicideReport[]}
              onClickHerbicideReport={(herbicideReportId) =>
                props.onClickWorkReport(wr.id, "herbicide", herbicideReportId)
              }
            />
          )}
          {props.isDisplayNoteReport && (
            <NoteItem
              workReport={wr}
              onClickNote={props.onClickWorkReportNote}
              onClickImage={props.onClickWorkReportImage}
            />
          )}
        </React.Fragment>
      ))}
      {props.pondReports.map((pr) => {
        const parentCarp = props.parentCarps.find((pc) => pc.id === pr.parentCarpId);
        const pairing = parentCarp && parentCarp.carpPairings.find((c) => c.id === pr.carpPairingId);
        const pairingName = pairing ? pairing.name : "";
        return (
          <React.Fragment key={pr.id}>
            <PondDisinfectionReportItem
              date={props.date}
              pondDisinfectionReports={pr.pondDisinfectionReports as PondDisinfectionReport[]}
              pairingName={pairingName}
              onClickPondReport={(disinfectionId: number) =>
                props.onClickPondReport(pr.id, "disinfection", disinfectionId)
              }
            />
            <CarpSelectionReportItem
              date={props.date}
              carpSelectionReports={pr.carpSelectionReports as CarpSelectionReport[]}
              scaleTypes={props.scaleTypes}
              pairingName={pairingName}
              onClickPondReport={(selectionId: number) => props.onClickPondReport(pr.id, "selection", selectionId)}
            />
            <CarpMovingReportItem
              date={props.date}
              carpMovingReports={pr.carpMovingReports as CarpMovingReport[]}
              pairingName={pairingName}
              ponds={props.ponds}
              pairPondReports={props.pairPondReports}
              onClickPondReport={(movingId: number) => props.onClickPondReport(pr.id, "moving", movingId)}
            />
            <AdultCarpReportItem
              date={props.date}
              adultCarpReports={pr.adultCarpReports as AdultCarpReport[]}
              onClickPondReport={(adultCarpId: number) => props.onClickPondReport(pr.id, "adultCarp", adultCarpId)}
            />
          </React.Fragment>
        );
      })}
      {props.environmentReports.map((e) => (
        <WaterTemperatureReportItem
          key={e.id}
          environmentReport={e}
          onClickEditEnvironmentReport={() => props.onClickEnvironmentReport(e.id)}
        />
      ))}
    </ContentOuter>
  );
};

interface NoteItemProps {
  workReport: WorkReport;
  onClickNote: () => void;
  onClickImage: (imageIds: number[], imageId: number) => void;
}
const NoteItem = (props: NoteItemProps) => {
  const { imageIds, note } = props.workReport;
  if (note === "" && imageIds.length === 0) {
    return null;
  }
  return (
    <FluidCard>
      <CardContent>
        <ItemType />
        <CardHeader>
          メモ
          <EditIcon onClick={props.onClickNote} />
        </CardHeader>
      </CardContent>
      <CardContent>
        <ItemDetail>
          <ItemText>
            <TextArea text={note} />
          </ItemText>
          <Grid>
            <GridRow>
              {imageIds.map((id) => (
                <GridColumn key={id} mobile={8}>
                  <Image onClick={() => props.onClickImage(imageIds, id)} src={getImageSrcURL(id)} />
                </GridColumn>
              ))}
            </GridRow>
          </Grid>
        </ItemDetail>
      </CardContent>
    </FluidCard>
  );
};

interface FeedReportItemProps {
  feedReports: FeedReport[];
  feeds: Feed[];
  feedReasonTypes: FeedReasonType[];
  useMethodTypes: UseMethodType[];
  onClickFeedReport: (feedReportId: number) => void;
}
const FeedReportItem = (props: FeedReportItemProps) => {
  return (
    <>
      {props.feedReports.map((fr) => {
        const feed = props.feeds.find((f) => f.id === fr.feedId);
        const feedReasonType = props.feedReasonTypes.find((r) => fr.feedReasonTypeId === r.id);
        const useMethodType = props.useMethodTypes.find((u) => u.id === fr.useMethodTypeId);
        return (
          <FluidCard key={fr.id}>
            <CardContent>
              <ItemType type={"feed"} />
              <CardHeader>
                {feed ? feed.name : null}
                <EditIcon onClick={() => props.onClickFeedReport(fr.id)} />
              </CardHeader>
            </CardContent>
            <CardContent>
              <ItemDetail>
                {useMethodType && isFeederUseMethodType(useMethodType) && <ItemText>{fr.count}回/日</ItemText>}
                <ItemText>{`${fr.amount} ${useMethodType ? useMethodType.unit : ""}`}</ItemText>
                {feedReasonType && <ItemText>{feedReasonType.name}</ItemText>}
              </ItemDetail>
            </CardContent>
          </FluidCard>
        );
      })}
    </>
  );
};

interface FertilizerReportItemProps {
  fertilizerReports: FertilizerReport[];
  fertilizers: Fertilizer[];
  useMethodTypes: UseMethodType[];
  onClickFertilizerReport: (fertilizerReportId: number) => void;
}
const FertilizerReportItem = (props: FertilizerReportItemProps) => {
  return (
    <>
      {props.fertilizerReports.map((fr) => {
        const fertilizer = props.fertilizers.find((f) => f.id === fr.fertilizerId);
        const useMethodType = props.useMethodTypes.find((u) => u.id === fr.useMethodTypeId);
        return (
          <FluidCard key={fr.id}>
            <CardContent>
              <ItemType type={"fertilizer"} />
              <CardHeader>
                {fertilizer ? fertilizer.name : ""}
                <EditIcon onClick={() => props.onClickFertilizerReport(fr.id)} />
              </CardHeader>
            </CardContent>
            <CardContent>
              <ItemDetail>
                <ItemText>{`${fr.amount} ${useMethodType ? useMethodType.unit : ""}`}</ItemText>
              </ItemDetail>
            </CardContent>
          </FluidCard>
        );
      })}
    </>
  );
};

interface HerbicideReportItemProps {
  herbicideReports: HerbicideReport[];
  herbicides: Herbicide[];
  useMethodTypes: UseMethodType[];
  onClickHerbicideReport: (herbicideReportId: number) => void;
}
const HerbicideReportItem = (props: HerbicideReportItemProps) => {
  return (
    <>
      {props.herbicideReports.map((hr) => {
        const herbicide = props.herbicides.find((h) => h.id === hr.herbicideId);
        const useMethodType = props.useMethodTypes.find((u) => u.id === hr.useMethodTypeId);
        return (
          <FluidCard key={hr.id}>
            <CardContent>
              <ItemType type={"herbicide"} />
              <CardHeader>
                {herbicide ? herbicide.name : ""}
                <EditIcon onClick={() => props.onClickHerbicideReport(hr.id)} />
              </CardHeader>
            </CardContent>
            <CardContent>
              <ItemDetail>
                <ItemText>{`${hr.amount} ${useMethodType ? useMethodType.unit : ""}`}</ItemText>
              </ItemDetail>
            </CardContent>
          </FluidCard>
        );
      })}
    </>
  );
};

interface PondDisinfectionReportItemProps {
  date: Date;
  pondDisinfectionReports: PondDisinfectionReport[];
  pairingName: string;
  onClickPondReport: (disinfectionId: number) => void;
}

const PondDisinfectionReportItem = (props: PondDisinfectionReportItemProps) => {
  return (
    <>
      {props.pondDisinfectionReports
        .filter((r) => filterByDate(r, props.date))
        .map((pdr) => {
          return (
            <FluidCard key={pdr.id}>
              <CardContent>
                <ItemType type={"fryCarp"} />
                <CardHeader>
                  {`塩素 ${props.pairingName}`}
                  <EditIcon onClick={() => props.onClickPondReport(pdr.id)} />
                </CardHeader>
              </CardContent>
              <CardContent>
                <ItemDetail>
                  <DetailHeader>
                    <AmountCell>本数</AmountCell>
                    <SizeCell />
                    <QualityCell />
                    <MalformationCell />
                    <RatioCell />
                  </DetailHeader>
                  <CurrentDetail>
                    <AmountCell>{pdr.amount}本</AmountCell>
                    <SizeCell />
                    <QualityCell />
                    <MalformationCell />
                    <RatioCell />
                  </CurrentDetail>
                </ItemDetail>
                <ItemDetail>
                  <TextArea text={pdr.note} />
                </ItemDetail>
              </CardContent>
            </FluidCard>
          );
        })}
    </>
  );
};

interface CarpSelectionReportItemProps {
  date: Date;
  carpSelectionReports: CarpSelectionReport[];
  scaleTypes: ScaleType[];
  pairingName: string;
  onClickPondReport: (selectionId: number) => void;
}

const CarpSelectionReportItem = (props: CarpSelectionReportItemProps) => {
  return (
    <>
      {props.carpSelectionReports
        .filter((r) => filterByDate(r, props.date))
        .map(mapSelectionReportToFrySelectionReportVM)
        .map((csr) => {
          const size = props.scaleTypes.find((s) => s.id === csr.carpSizeTypeId);
          const quality = props.scaleTypes.find((s) => s.id === csr.carpQualityTypeId);
          const malformation = props.scaleTypes.find((s) => s.id === csr.malformationTypeId);
          const ratio = props.scaleTypes.find((s) => s.id === csr.carpRatioTypeId);
          const isReleaseCarp = isReleaseCarpSelectionReport(csr);

          return (
            <FluidCard key={getUniqueKey(csr)}>
              <CardContent>
                <ItemType type={"fryCarp"} />
                <CardHeader>
                  {`${csr.name} ${props.pairingName}`}
                  <EditIcon onClick={() => props.onClickPondReport(getUniqueKey(csr) as number)} />
                </CardHeader>
              </CardContent>
              <CardContent>
                <ItemDetail>
                  <DetailHeader>
                    <AmountCell>匹数</AmountCell>
                    <SizeCell>{!isReleaseCarp && "サイズ"}</SizeCell>
                    <QualityCell>{!isReleaseCarp && "評価"}</QualityCell>
                    <ScoreCell>{!isReleaseCarp && "点数"}</ScoreCell>
                    <MalformationCell>{!isReleaseCarp && "奇形"}</MalformationCell>
                    <RatioCell>{!isReleaseCarp && "数"}</RatioCell>
                  </DetailHeader>
                  <CurrentDetail>
                    <AmountCell>{csr.amount}匹</AmountCell>
                    <SizeCell>{!isReleaseCarp && (size ? size.name : "")}</SizeCell>
                    <QualityCell>{!isReleaseCarp && (quality ? quality.name : "")}</QualityCell>
                    <ScoreCell>{!isReleaseCarp && csr.carpScore}</ScoreCell>
                    <MalformationCell>{!isReleaseCarp && (malformation ? malformation.name : "")}</MalformationCell>
                    <RatioCell>{!isReleaseCarp && (ratio ? ratio.name : "")}</RatioCell>
                  </CurrentDetail>
                </ItemDetail>
                <ItemDetail>
                  <TextArea text={csr.note} />
                </ItemDetail>
              </CardContent>
            </FluidCard>
          );
        })}
    </>
  );
};

interface CarpMovingReportItemProps {
  date: Date;
  carpMovingReports: CarpMovingReport[];
  pairingName: string;
  ponds: Pond[];
  pairPondReports: PondReport[];
  onClickPondReport: (movingId: number) => void;
}

const CarpMovingReportItem = (props: CarpMovingReportItemProps) => {
  return (
    <>
      {props.carpMovingReports
        .filter((r) => filterByDate(r, props.date))
        .map((r) => mapMovingReportToFrySelectionReportVM(r, props.ponds, props.pairPondReports))
        .map((cmr) => {
          return (
            <FluidCard key={getUniqueKey(cmr)}>
              <CardContent>
                <ItemType type={"fryCarp"} />
                <CardHeader>
                  {`${cmr.name} ${props.pairingName}`}
                  <EditIcon onClick={() => props.onClickPondReport(getUniqueKey(cmr) as number)} />
                </CardHeader>
              </CardContent>
              <CardContent>
                <ItemDetail>
                  <DetailHeader>
                    <AmountCell>匹数</AmountCell>
                    <SizeCell />
                    <QualityCell />
                    <MalformationCell />
                    <RatioCell />
                  </DetailHeader>
                  <CurrentDetail>
                    <AmountCell>{cmr.amount}匹</AmountCell>
                    <SizeCell />
                    <QualityCell />
                    <MalformationCell />
                    <RatioCell />
                  </CurrentDetail>
                </ItemDetail>
                <ItemDetail>
                  <TextArea text={cmr.note} />
                </ItemDetail>
              </CardContent>
            </FluidCard>
          );
        })}
    </>
  );
};

interface AdultCarpReportItemProps {
  date: Date;
  adultCarpReports: AdultCarpReport[];
  onClickPondReport: (adultCarpId: number) => void;
}
const AdultCarpReportItem = (props: AdultCarpReportItemProps) => {
  return (
    <>
      {props.adultCarpReports
        .filter((r) => filterByDate(r, props.date))
        .map(mapAdultCarpReportToAdultSelectionReportVM)
        .map((acr) => {
          return (
            <FluidCard key={getUniqueKey(acr)}>
              <CardContent>
                <ItemType type={"adultCarp"} />
                <CardHeader>
                  {acr.name}
                  <EditIcon onClick={() => props.onClickPondReport(getUniqueKey(acr) as number)} />
                </CardHeader>
              </CardContent>
              <CardContent>
                <ItemDetail>
                  <ItemText>{acr.amount}匹</ItemText>
                </ItemDetail>
                <ItemDetail>
                  <TextArea text={acr.note} />
                </ItemDetail>
              </CardContent>
            </FluidCard>
          );
        })}
    </>
  );
};

interface WaterTemperatureReportItemProps {
  environmentReport: EnvironmentReport;
  onClickEditEnvironmentReport: () => void;
}

const WaterTemperatureReportItem = (props: WaterTemperatureReportItemProps) => {
  return (
    <FluidCard>
      <CardContent>
        <ItemType type={"temperature"} />
        <CardHeader>
          水温
          <EditIcon onClick={props.onClickEditEnvironmentReport} />
        </CardHeader>
      </CardContent>
      <CardContent>
        <ItemDetail>
          <ItemText>{props.environmentReport.value}℃</ItemText>
        </ItemDetail>
      </CardContent>
    </FluidCard>
  );
};

const isEmptyList = (
  workReports: WorkReport[],
  pondReports: PondReport[],
  environmentReports: EnvironmentReport[],
  date: Date
) => {
  if (workReports.length !== 0) {
    return false;
  }
  if (environmentReports.length !== 0) {
    return false;
  }
  return !pondReports.some((pr) => {
    return (
      existReportByDate(pr.carpSelectionReports, date) ||
      existReportByDate(pr.pondDisinfectionReports, date) ||
      existReportByDate(pr.carpMovingReports, date) ||
      existReportByDate(pr.adultCarpReports, date)
    );
  });
};

const existReportByDate = (reports: Array<{ date: Date | null }>, date: Date): boolean => {
  return reports.some((r) => {
    const reportDate = moment(r.date || "invalid_date");
    return reportDate.isSame(date, "date");
  });
};

const filterByDate = (report: { date: Date | null }, date: Date): boolean => {
  const reportDate = moment(report.date || "invalid_date");
  return moment(date).isSame(reportDate, "date");
};

const ContentOuter = styled.div`
  padding: ${DIMEN.X2};
`;

const ItemType = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  height: 100%;
  width: ${DIMEN.X0_5};
  border-radius: 0.28571429rem 0 0 0.28571429rem;
  background-color: ${(props: {
    type?: "feed" | "fertilizer" | "herbicide" | "temperature" | "fryCarp" | "adultCarp";
  }) => {
    if (props.type === "feed") {
      return COLOR.ReportColor.Feed;
    }
    if (props.type === "fertilizer") {
      return COLOR.ReportColor.Fertilizer;
    }
    if (props.type === "herbicide") {
      return COLOR.ReportColor.Herbicide;
    }
    if (props.type === "temperature") {
      return COLOR.ReportColor.Temperature;
    }
    if (props.type === "fryCarp") {
      return COLOR.ReportColor.FryCarp;
    }
    if (props.type === "adultCarp") {
      return COLOR.ReportColor.AdultCarp;
    }
    return "";
  }};
`;

const CardHeader = styled(_CardHeader)`
  display: flex !important;
  justify-content: space-between;
`;

const ItemText = styled.span`
  margin-right: 0.5em;
`;

const ItemDetail = styled.div`
  margin-left: 0.5em;
  flex: 1 1;
  max-width: calc(4em * 5);
  & + & {
    margin-top: 0.5em;
  }
`;

const EditIcon = styled(Icon).attrs({ name: "edit" })`
  cursor: pointer;
`;

const DetailHeader = styled.div`
  display: flex;
  color: ${COLOR.Gray["darken-1"]};
  font-size: 12px;
  margin-bottom: ${DIMEN.X0_5};
  > div {
    padding: ${DIMEN.X0_5} 0;
    border-bottom: 1px solid ${COLOR.Gray["darken-1"]};
  }
`;
const CurrentDetail = styled.div`
  display: flex;
`;
const BaseCell = styled.div`
  flex: 1 1 0;
  text-align: center;
`;
const AmountCell = styled(BaseCell)`
  flex: 1 1 4rem;
`;
const SizeCell = styled(BaseCell)``;
const QualityCell = styled(BaseCell)``;
const ScoreCell = styled(BaseCell)``;
const MalformationCell = styled(BaseCell)``;
const RatioCell = styled(BaseCell)``;
