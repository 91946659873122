import { Dispatch } from "redux";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { reportNavigationActions } from "../../../store/modules/report/navigation/ducks";
import { PondApiService } from "../../api/pond";
import { PondAreaApiService } from "../../api/pondArea";
import { PondTypeApiService } from "../../api/pondType";

interface INavigationStateService extends IApplicationService {
  fetchApi: () => void;
  selectDate: (date: Date) => void;
  selectPondArea: (id: number) => void;
  selectPond: (id: number) => void;
  openList: () => void;
  closeList: () => void;
}

export class NavigationStateService implements INavigationStateService {
  private readonly pondApiService: PondApiService;
  private readonly pondAreaApiService: PondAreaApiService;
  private readonly pondTypeApiService: PondTypeApiService;

  public constructor(private dispatch: Dispatch<any>) {
    this.pondApiService = new PondApiService(dispatch);
    this.pondAreaApiService = new PondAreaApiService(dispatch);
    this.pondTypeApiService = new PondTypeApiService(dispatch);
  }

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public async fetchApi() {
    await Promise.all([
      this.pondApiService.init(),
      this.pondAreaApiService.init(),
      this.pondTypeApiService.fetchUnFetched(),
    ]);
  }

  @catchApplicationError()
  public selectDate(date: Date) {
    this.dispatch(reportNavigationActions.selectDate({ selectedDate: date }));
  }

  @catchApplicationError()
  public selectPondArea(id: number) {
    this.dispatch(reportNavigationActions.selectPondArea({ selectedPondAreaId: id }));
  }

  @catchApplicationError()
  public selectPond(id: number) {
    this.dispatch(reportNavigationActions.selectPond({ selectedPondId: id }));
  }

  @catchApplicationError()
  public openList() {
    this.dispatch(reportNavigationActions.openList());
  }

  @catchApplicationError()
  public closeList() {
    this.dispatch(reportNavigationActions.closeList());
  }
}
