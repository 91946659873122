import * as queryString from "query-string";
import { ParsedQuery } from "query-string";

type ReturnUrlMobile = "reports";

export interface MobileQuery {
  back: ReturnUrlMobile | undefined;
}

export class SearchQueryMobile {
  public static createMobileQuery(back: ReturnUrlMobile): MobileQuery {
    return {
      back,
    };
  }

  public static createURLFromMobileQuery(baseURL: string, query: MobileQuery): string {
    return this.createURL(baseURL, query);
  }

  public static createURLFromState(defaultURL: string, query: string): string {
    const queryMobile = this.mapQueryStringToMobileQuery(queryString.parse(query));
    if (queryMobile.back === "reports") {
      return "/reports";
    }
    return defaultURL;
  }

  public static getReturnURLFromState(query: string): ReturnUrlMobile | undefined {
    const queryMobile = this.mapQueryStringToMobileQuery(queryString.parse(query));
    return queryMobile.back;
  }

  private static createURL(baseURL: string, queryMobile: MobileQuery): string {
    const query = this.createQueryString(queryMobile);
    return `${baseURL}${query !== "" ? "?" : ""}${query}`;
  }

  private static createQueryString(query: MobileQuery): string {
    return `${queryString.stringify(query)}`;
  }

  private static mapQueryStringToMobileQuery(query: ParsedQuery): MobileQuery {
    return {
      back: this.getReturnUrlMobile(query),
    };
  }

  private static getReturnUrlMobile(query: ParsedQuery): ReturnUrlMobile | undefined {
    if (!query.back || typeof query.back !== "string") {
      return undefined;
    }
    if (query.back === "reports") {
      return "reports";
    }
    return undefined;
  }
}

type ReturnUrl = "reports_list";

export interface Query {
  back: ReturnUrl | undefined;
}

export class SearchQuery {
  public static createQuery(back: ReturnUrl): Query {
    return {
      back,
    };
  }

  public static createURLFromQuery(baseURL: string, query: Query): string {
    return this.createURL(baseURL, query);
  }

  public static createURLFromState(defaultURL: string, queryParam: string): string {
    const query = this.mapQueryStringToQuery(queryString.parse(queryParam));
    if (query.back === "reports_list") {
      return "/reports/list";
    }
    return defaultURL;
  }

  public static getReturnURLFromState(queryParam: string): ReturnUrl | undefined {
    const query = this.mapQueryStringToQuery(queryString.parse(queryParam));
    return query.back;
  }

  private static createURL(baseURL: string, query: Query): string {
    const queryParam = this.createQueryString(query);
    return `${baseURL}${queryParam !== "" ? "?" : ""}${queryParam}`;
  }

  private static createQueryString(query: Query): string {
    return `${queryString.stringify(query)}`;
  }

  private static mapQueryStringToQuery(query: ParsedQuery): Query {
    return {
      back: this.getReturnUrl(query),
    };
  }

  private static getReturnUrl(query: ParsedQuery): ReturnUrl | undefined {
    if (!query.back || typeof query.back !== "string") {
      return undefined;
    }
    if (query.back === "reports_list") {
      return "reports_list";
    }
    return undefined;
  }
}
