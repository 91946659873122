import { default as moment } from "moment";
import * as React from "react";
import { Header, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { DIMEN } from "../../../styles/Dimen";
import { PageSection } from "../../atoms/Section";

interface DateSelectionSectionProps {
  selectedDate: Date;
  onClickPrevYear: () => void;
  onClickPrevMonth: () => void;
  onClickNextMonth: () => void;
  onClickNextYear: () => void;
  onClickFilter?: () => void;
}
export const DateSelectionSection = ({
  selectedDate,
  onClickNextMonth,
  onClickNextYear,
  onClickPrevMonth,
  onClickPrevYear,
  onClickFilter,
}: DateSelectionSectionProps) => {
  const formattedDate = moment(selectedDate).format("YYYY年MM月");
  return (
    <PageSection className={"large"}>
      <Flex>
        <DateOuter>
          <DateIcon className={"sub"} size={"large"} name={"angle double left"} onClick={onClickPrevYear} />
          <DateIcon name={"angle left"} size={"large"} onClick={onClickPrevMonth} />
          <DateTitle>{formattedDate}</DateTitle>
          <DateIcon name={"angle right"} size={"large"} onClick={onClickNextMonth} />
          <DateIcon className={"sub"} size={"large"} name={"angle double right"} onClick={onClickNextYear} />
        </DateOuter>
        {onClickFilter && (
          <CalendarButtonGroup>
            <FilterIcon size={"large"} onClick={onClickFilter} />
          </CalendarButtonGroup>
        )}
      </Flex>
    </PageSection>
  );
};

const Flex = styled.div`
  display: flex;
`;

const DateOuter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
`;
const DateTitle = styled(Header).attrs({ as: "h3" })`
  font-weight: 700;
  margin: 0 ${DIMEN.X2};
`;
const DateIcon = styled(Icon)`
  margin: 0 ${DIMEN.X1} !important;
  cursor: pointer;
  &.sub {
    opacity: 0.5 !important;
  }
`;

const FilterIcon = styled(Icon).attrs({ name: "filter" })`
  cursor: pointer;
`;
const CalendarButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1em;
  flex-grow: 1;
  flex-shrink: 1;
`;
