import { Dispatch } from "redux";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { reportPondReportMoveModalStateActions } from "../../../store/modules/report/pondReport/moveModalState/ducks";

interface IMoveModalStateService extends IApplicationService {
  openModal: () => void;
  closeModal: () => void;
  changePondArea: (pondAreaId: null | number) => void;
  changePond: (pondId: null | number) => void;
  changeCarpSizeType: (sizeTypeId: null | number) => void;
  changeCarpQualityType: (qualityTypeId: null | number) => void;
  changeMalformationType: (malformationTypeId: null | number) => void;
  changeCarpRatioType: (ratioTypeId: null | number) => void;
  changeAmount: (amount: string) => void;
  changeNote: (note: string) => void;
}

export class MoveModalStateService implements IMoveModalStateService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public openModal() {
    this.dispatch(reportPondReportMoveModalStateActions.openModal());
  }

  @catchApplicationError()
  public closeModal() {
    this.dispatch(reportPondReportMoveModalStateActions.closeModal());
  }

  @catchApplicationError()
  public changePondArea(pondAreaId: null | number) {
    this.dispatch(reportPondReportMoveModalStateActions.changeModalState({ key: "pondAreaId", value: pondAreaId }));
  }

  @catchApplicationError()
  public changePond(pondId: null | number) {
    this.dispatch(reportPondReportMoveModalStateActions.changeModalState({ key: "pondId", value: pondId }));
  }

  @catchApplicationError()
  public changeCarpSizeType(carpSizeTypeId: null | number) {
    this.dispatch(
      reportPondReportMoveModalStateActions.changeModalState({ key: "carpSizeTypeId", value: carpSizeTypeId })
    );
  }

  @catchApplicationError()
  public changeCarpQualityType(carpQualityTypeId: null | number) {
    this.dispatch(
      reportPondReportMoveModalStateActions.changeModalState({ key: "carpQualityTypeId", value: carpQualityTypeId })
    );
  }

  @catchApplicationError()
  public changeMalformationType(malformationTypeId: null | number) {
    this.dispatch(
      reportPondReportMoveModalStateActions.changeModalState({ key: "malformationTypeId", value: malformationTypeId })
    );
  }

  @catchApplicationError()
  public changeCarpRatioType(ratioTypeId: null | number) {
    this.dispatch(
      reportPondReportMoveModalStateActions.changeModalState({ key: "carpRatioTypeId", value: ratioTypeId })
    );
  }

  @catchApplicationError()
  public changeAmount(amount: string) {
    this.dispatch(reportPondReportMoveModalStateActions.changeModalState({ key: "amount", value: amount }));
  }

  @catchApplicationError()
  public changeNote(note: string) {
    this.dispatch(reportPondReportMoveModalStateActions.changeModalState({ key: "note", value: note }));
  }
}
