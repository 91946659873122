import { Dispatch } from "redux";
import {
  NewAdultCarpReport,
  NewOrEditAdultCarpReport,
  removeEmptyAdultCarpReport,
} from "../../../domain/adultCarpReport";
import { getFiscalStartDate } from "../../../domain/calendar";
import { UniqueKey } from "../../../domain/entity";
import {
  EditPondReport,
  fillEmptyAdultPondReports,
  isNewPondReport,
  NewOrEditPondReport,
  NewPondReport,
  PondReport,
  PondReportValidator,
  removeEmptyAdultPondReports,
} from "../../../domain/pondReport";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { PondReportRepository } from "../../../infrastracture/pondReport/repository";
import { ApplicationState } from "../../../store/modules";
import { apiPondReportActions } from "../../../store/modules/api/pondReport/ducks";
import { notificationAlertStateActions } from "../../../store/modules/notification/alert/ducks";
import { reportPondReportNewOrEditStateActions } from "../../../store/modules/report/pondReport/newOrEditState/ducks";

interface IAdultPondNewOrEditStateService extends IApplicationService {
  changeFiscalYear: (reportUniqueKey: UniqueKey, fiscalYear: string) => void;
  changeAdultCarpReport: (
    reportUniqueKey: UniqueKey,
    adultUniqueKey: UniqueKey,
    key: keyof NewOrEditAdultCarpReport,
    value: any
  ) => void;
  savePondReports: () => void;
}

export class AdultPondNewOrEditStateService implements IAdultPondNewOrEditStateService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public changeFiscalYear(reportUniqueKey: UniqueKey, fiscalYear: string) {
    this.dispatch(
      reportPondReportNewOrEditStateActions.changePondReport({ reportUniqueKey, key: "fiscalYear", value: fiscalYear })
    );
  }

  @catchApplicationError()
  public changeAdultCarpReport(
    reportUniqueKey: UniqueKey,
    adultUniqueKey: UniqueKey,
    key: keyof NewOrEditAdultCarpReport,
    value: any
  ) {
    this.dispatch(
      reportPondReportNewOrEditStateActions.changeAdultCarpReport({
        reportUniqueKey,
        adultUniqueKey,
        key,
        value,
      })
    );
  }

  @catchApplicationError((dispatch) => dispatch(reportPondReportNewOrEditStateActions.saveFail()))
  public async savePondReports() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      this.dispatch(reportPondReportNewOrEditStateActions.saveStart());
      const state = getState();
      const { newOrEditState } = state.report.pondReport;
      if (newOrEditState.pondReports === null || newOrEditState.pondReports.length === 0) {
        throw new ApplicationError("池の状態記録が見つかりませんでした。");
      }
      const errorMessage = this.validatePondReports(newOrEditState.pondReports, {
        pondReports: state.api.pondReport.pondReports,
      });
      if (errorMessage !== null) {
        this.dispatch(notificationAlertStateActions.showErrorMessage({ errorMessage }));
        this.dispatch(reportPondReportNewOrEditStateActions.saveFail());
        return;
      }

      const savedPondReports = await Promise.all(newOrEditState.pondReports.map(this.savePondReport));

      const filledReports = fillEmptyAdultPondReports(savedPondReports) as PondReport[];
      this.dispatch(reportPondReportNewOrEditStateActions.saveSuccess({ pondReports: filledReports }));
      this.dispatch(apiPondReportActions.savePondReports({ pondReports: savedPondReports }));
      const firstReport = filledReports[0];
      if (firstReport) {
        const fiscalYear = Number(firstReport.fiscalYear);
        this.dispatch(
          reportPondReportNewOrEditStateActions.changeSelectedDate({
            selectedDate: getFiscalStartDate(fiscalYear),
          })
        );
      }
    });
  }

  private async savePondReport(pondReport: NewOrEditPondReport): Promise<PondReport> {
    if (isNewPondReport(pondReport)) {
      const removedNewPondReport: NewPondReport = {
        ...pondReport,
        adultCarpReports: removeEmptyAdultCarpReport(pondReport.adultCarpReports) as NewAdultCarpReport[],
      };
      return await new PondReportRepository().postPondReport(removedNewPondReport);
    }
    const removedEditPondReport: EditPondReport = {
      ...pondReport,
      adultCarpReports: removeEmptyAdultCarpReport(pondReport.adultCarpReports),
    };
    return await new PondReportRepository().putPondReport(removedEditPondReport);
  }

  private validatePondReports(
    pondReports: NewOrEditPondReport[],
    apiValue: { pondReports: PondReport[] }
  ): string | null {
    const errorMessages: string[] = [];
    removeEmptyAdultPondReports(pondReports).forEach((pr, index) => {
      const validator = new PondReportValidator(apiValue.pondReports);
      validator.validate(pr);
      if (!validator.isValid()) {
        errorMessages.push(`${index + 1}番目の記録 : ${validator.getMessages()}`);
      }
    });
    if (errorMessages.length === 0) {
      return null;
    }
    return errorMessages.join("\n");
  }
}
