import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { EditStateService } from "../../../application/master/parentCarps/editState";
import { NewStateService } from "../../../application/master/parentCarps/newState";
import { ReadApiService } from "../../../application/master/parentCarps/readApi";
import { isActiveEntity, isEntity, isNewEntity } from "../../../domain/entity";
import { ParentCarp } from "../../../domain/parentCarp";
import { ApplicationState } from "../../../store/modules";
import { NewOrEditStateType } from "../../../store/modules/master/parentCarp/newOrEditState/reducer";
import { MasterFormDefault } from "../../components/molecules/MasterForm";
import { PageHeader } from "../../components/molecules/PageHeader";
import { MainLayout } from "../Layout";
import { MasterLayout } from "../Layout/Master";
import { ParentCarpEditPage } from "./EditPage";
import { ParentCarpsList } from "./List";
import { ParentCarpNewPage } from "./NewPage";

interface StateProps {
  parentCarps: ParentCarp[];
  newOrEditState: NewOrEditStateType;
}

interface DispatchProps {
  readApiService: ReadApiService;
  editStateService: EditStateService;
  newStateService: NewStateService;
}

type parentCarpsPageProps = StateProps & DispatchProps;

export class Wrapped extends React.Component<parentCarpsPageProps> {
  public componentDidMount(): void {
    this.props.readApiService.init().then(() => {
      const { parentCarp } = this.props.newOrEditState;
      if (parentCarp !== null && isEntity(parentCarp)) {
        this.props.editStateService.selectParentCarp(parentCarp.id);
      }
    });
  }

  public render() {
    const parentCarp = this.props.newOrEditState.parentCarp;
    let form;
    if (parentCarp !== null) {
      form = isEntity(parentCarp) ? <ParentCarpEditPage /> : <ParentCarpNewPage />;
    } else {
      form = (
        <MasterFormDefault
          onClickAdd={parentCarp !== null && isNewEntity(parentCarp) ? null : this.handleClickAddParentCarp}
          onClickDelete={parentCarp !== null && isEntity(parentCarp) ? this.handleClickDeleteParentCarp : null}
        />
      );
    }

    return (
      <MainLayout>
        <MasterLayout header={<PageHeader title={"親鯉"} />} list={<ParentCarpsList />} form={form} listColumn={2} />
      </MainLayout>
    );
  }

  private handleClickAddParentCarp = () => {
    this.props.newStateService.createParentCarp();
  };

  private handleClickDeleteParentCarp = () => {
    this.props.editStateService.deleteParentCarp();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { newOrEditState } = state.master.parentCarp;
  return {
    parentCarps: state.api.parentCarp.parentCarps.filter(isActiveEntity),
    newOrEditState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    readApiService: new ReadApiService(dispatch),
    editStateService: new EditStateService(dispatch),
    newStateService: new NewStateService(dispatch),
  };
};

export const ParentCarpsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);
