import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { NavigationService } from "../../../../application/mobile/navigation";
import { NewOrEditStateService } from "../../../../application/mobile/pondReport/newOrEditState";
import { getUniqueKey } from "../../../../domain/entity";
import { Pond } from "../../../../domain/pond";
import { ApplicationState } from "../../../../store/modules";
import { NavigationStateType } from "../../../../store/modules/mobile/navigation/reducer";
import { ListStateType } from "../../../../store/modules/mobile/pondReport/listState/reducer";
import { NewOrEditStateType } from "../../../../store/modules/mobile/pondReport/newOrEditState/reducer";
import { Spinner } from "../../../components/atoms/Spinner";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { NotificationModal } from "../../../components/molecules/NotificationModal";
import { MobileLayout } from "../../Layout/Mobile";
import { MoveModal } from "./MoveModal";
import { PondReportCarpSelectionNewOrEditPage } from "./NewOrEdit";

interface StateProps {
  ponds: Pond[];
  navigationState: NavigationStateType;
  listState: ListStateType;
  newOrEditState: NewOrEditStateType;
}

interface DispatchProps {
  navigationService: NavigationService;
  newOrEditStateService: NewOrEditStateService;
}

interface PondReportCarpSelectionPageState {
  isOpenConfirmModal: boolean;
}

type PondReportCarpSelectionPageProps = StateProps & DispatchProps & RouteComponentProps;

export class Wrapped extends React.Component<PondReportCarpSelectionPageProps, PondReportCarpSelectionPageState> {
  constructor(props: PondReportCarpSelectionPageProps) {
    super(props);

    this.state = {
      isOpenConfirmModal: false,
    };
  }

  public componentWillMount() {
    if (this.props.navigationState.selectedPondId === null) {
      this.props.history.push("/");
      return;
    }
    if (this.props.newOrEditState.pondReport === null || this.props.listState.selectedReportKey !== "CarpSelection") {
      this.props.history.push("/reports/menu");
      return;
    }
  }

  public render() {
    const { selectedReportKey } = this.props.listState;
    if (selectedReportKey !== "CarpSelection") {
      return this.renderEmpty();
    }
    const pond = this.props.ponds.find((p) => p.id === this.props.navigationState.selectedPondId);
    const { pondReport } = this.props.newOrEditState;
    if (!pond || !pondReport) {
      return this.renderEmpty();
    }
    const selectionReport = pondReport.carpSelectionReports.find(
      (csr) => getUniqueKey(csr) === this.props.listState.selectedUniqueKey
    );
    if (!selectionReport) {
      return this.renderEmpty();
    }
    return (
      <>
        <PondReportCarpSelectionNewOrEditPage
          pond={pond}
          pondReport={pondReport}
          selectionReport={selectionReport}
          onClickSave={this.handleClickSave}
        />
        {this.renderNotificationModal()}
        <MoveModal />
      </>
    );
  }

  private renderEmpty = () => {
    return (
      <MobileLayout>
        <BaseNavigationHeader title={""} onClickMenu={this.handleClickMenu} />
        <BaseNavigationContent>
          <Spinner />
          {this.renderNotificationModal()}
          <MoveModal />
        </BaseNavigationContent>
      </MobileLayout>
    );
  };

  private renderNotificationModal = () => {
    return (
      <NotificationModal
        isOpen={this.state.isOpenConfirmModal}
        onClose={() => this.setState({ isOpenConfirmModal: false })}
        onClickOk={this.handleModalOk}
        okText={"OK"}
        message={"保存しました"}
      />
    );
  };

  private handleClickMenu = () => {
    this.props.navigationService.openMenu();
  };

  private handleClickSave = async () => {
    await this.props.newOrEditStateService.savePondReport();
    if (this.props.newOrEditState.isSaved) {
      this.setState({ isOpenConfirmModal: true });
    }
  };

  private handleModalOk = () => {
    this.setState({ isOpenConfirmModal: false });
    this.props.history.goBack();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    ponds: state.api.pond.ponds,
    navigationState: state.mobile.navigation,
    listState: state.mobile.pondReportState.listState,
    newOrEditState: state.mobile.pondReportState.newOrEditStateType,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    navigationService: new NavigationService(dispatch),
    newOrEditStateService: new NewOrEditStateService(dispatch),
  };
};

export const PondReportCarpSelectionPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
