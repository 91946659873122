import * as React from "react";
import styled from "styled-components";
import { COLOR } from "../../styles/Color";
import { DIMEN } from "../../styles/Dimen";

interface MasterLayoutProps {
  header: React.ReactNode;
  list: React.ReactNode;
  form: React.ReactNode;
  listColumn?: number;
}
export const MasterLayout = ({ header, list, form, listColumn = 1 }: MasterLayoutProps) => {
  return (
    <Layout>
      <Header>{header}</Header>
      <Content>
        <List column={listColumn}>{list}</List>
        <Form column={listColumn}>{form}</Form>
      </Content>
    </Layout>
  );
};

const HEADER_HEIGHT = DIMEN.X7;

const Layout = styled.div`
  height: 100%;
`;
const Header = styled.div`
  height: ${HEADER_HEIGHT};
  padding: ${DIMEN.X1} ${DIMEN.X1_5};
  border-bottom: 3px double ${COLOR.Gray.base};
`;
const Content = styled.div`
  height: calc(100% - ${HEADER_HEIGHT});
  display: flex;
`;

const LIST_WIDTH = "14rem";
const List = styled.div`
  height: 100%;
  flex-basis: ${(props: { column: number }) => `calc(${LIST_WIDTH} * ${props.column})`};
  overflow-y: auto;
  border-right: 1px solid ${COLOR.Gray.base};
`;

const Form = styled.div`
  height: 100%;
  flex-basis: ${(props: { column: number }) => `calc(100% - (${LIST_WIDTH} * ${props.column}))`};
  overflow-y: auto;
`;
