import * as React from "react";
import { Pond } from "../../../../domain/pond";
import { NewOrEditPondArea, PondArea } from "../../../../domain/pondArea";
import { Form } from "../../atoms/Form/Form";
import { FormTextInput } from "../../atoms/Form/Input";
import { FormSelectMulti } from "../../atoms/Form/Select";
import { Spinner } from "../../atoms/Spinner";
import { MasterForm } from "../../molecules/MasterForm";
import { MasterFormButton } from "../../molecules/MasterFormButton";

interface PondAreaFormProps {
  ponds: Pond[];
  pondArea: NewOrEditPondArea | null;
  originalPondArea: PondArea | null;
  isSaving: boolean;
  onChangeName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePonds: (pondIds: number[]) => void;
  onClickSave: () => void;
  onClickCancel: () => void;
  onClickAdd: (() => void) | null;
  onClickDelete: (() => void) | null;
}

export const PondAreaForm = ({
  ponds,
  pondArea,
  originalPondArea,
  isSaving,
  onChangeName,
  onChangePonds,
  onClickSave,
  onClickCancel,
  onClickAdd,
  onClickDelete,
}: PondAreaFormProps) => {
  if (pondArea === null) {
    return <Spinner />;
  }
  return (
    <MasterForm onClickAdd={onClickAdd} onClickDelete={onClickDelete}>
      {isSaving && <Spinner />}
      <Form>
        <FormTextInput label={"エリア名"} value={pondArea.name} onChange={onChangeName} />
        <FormSelectMulti
          selectedIds={pondArea.pondIds}
          label={"野池"}
          options={getPondOptions(originalPondArea, ponds)}
          onChange={onChangePonds}
        />
        <MasterFormButton onClickSave={onClickSave} onClickCancel={onClickCancel} />
      </Form>
    </MasterForm>
  );
};

function getPondOptions(pondArea: PondArea | null, ponds: Pond[]): Pond[] {
  const pondIds = pondArea === null ? [] : pondArea.pondIds;
  return ponds.filter((p) => p.pondAreaId === null || pondIds.includes(p.id));
}
