import { PondReport } from "../../../../domain/pondReport";
import { FetchLifeCycle } from "../../../lifeCycle/fetchLifeCycle";

export interface PondReportStateType extends FetchLifeCycle {
  pondReports: PondReport[];
}

export const getInitialState = (): PondReportStateType => {
  return { pondReports: [], isFetched: false, isFetching: false, isError: null };
};

export const FetchHandlers = {
  start(state: PondReportStateType) {
    return { ...state, isFetched: false, isFetching: true, isError: null };
  },
  complete(state: PondReportStateType, pondReports: PondReport[]) {
    return { ...state, pondReports, isFetched: true, isFetching: false };
  },
  completeMerge(state: PondReportStateType, pondReports: PondReport[]) {
    const ids = state.pondReports.map((p) => p.id);
    const newReports = pondReports.filter((pr) => !ids.includes(pr.id));
    const updateReports = state.pondReports.map((pr) => {
      const r = pondReports.find((p) => p.id === pr.id);
      return !!r ? r : pr;
    });
    return { ...state, pondReports: newReports.concat(updateReports), isFetched: true, isFetching: true };
  },
  fail(state: PondReportStateType, message: string = "") {
    return { ...state, isFetched: false, isFetching: false, isError: { message } };
  },
  shouldFetch(state: PondReportStateType): PondReportStateType {
    return { ...state, isFetched: false };
  },
};

export const SaveHandlers = {
  savePondReports(state: PondReportStateType, pondReports: PondReport[]): PondReportStateType {
    // TODO: ソート処理
    const stateIds = state.pondReports.map((pr) => pr.id);
    const newPondReports = pondReports.filter((pr) => !stateIds.includes(pr.id));
    const updatePondReports = state.pondReports.map((pr) => {
      const report = pondReports.find((argReport) => argReport.id === pr.id);
      return report || pr;
    });
    return { ...state, pondReports: updatePondReports.concat(newPondReports) };
  },
  createPondReport(state: PondReportStateType, pondReport: PondReport): PondReportStateType {
    return { ...state, pondReports: state.pondReports.concat(pondReport) };
  },
  updatePondReport(state: PondReportStateType, pondReport: PondReport): PondReportStateType {
    const pondReports = state.pondReports.map((pr) => (pr.id === pondReport.id ? pondReport : pr));
    return { ...state, pondReports };
  },
  deletePondReport(state: PondReportStateType, pondReport: PondReport): PondReportStateType {
    const pondReports = state.pondReports.filter((pr) => pr.id !== pondReport.id);
    return { ...state, pondReports };
  },
};
