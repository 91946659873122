import * as React from "react";
import styled from "styled-components";
import {
  ActionButton,
  FilterButton,
  LeftButtonOuter,
  MenuButton,
  MOBILE_NAVIGATION_HEADER_HEIGHT,
  Outer,
  ReturnButton,
  RightButtonOuter,
  Title,
} from "./elements";

interface BaseNavigationHeaderProps {
  title: string;
  onClickMenu?: () => void;
  onClickReturn?: () => void;
  onClickAction?: () => void;
  onClickFilter?: () => void;
}

export const BaseNavigationHeader = (props: BaseNavigationHeaderProps) => {
  return (
    <Outer>
      <LeftButtonOuter>
        {props.onClickMenu && <MenuButton onClick={props.onClickMenu} />}
        {props.onClickReturn && <ReturnButton onClick={props.onClickReturn} />}
      </LeftButtonOuter>
      <Title>{props.title}</Title>
      <RightButtonOuter>
        {props.onClickAction && <ActionButton onClick={props.onClickAction} />}
        {props.onClickFilter && <FilterButton onClick={props.onClickFilter} />}
      </RightButtonOuter>
    </Outer>
  );
};

export const BaseNavigationContent = styled.div`
  height: calc(100% - ${MOBILE_NAVIGATION_HEADER_HEIGHT});
  overflow-y: auto;
`;
