import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { EditStateService } from "../../../application/master/pondArea/editState";
import { NewStateService } from "../../../application/master/pondArea/newState";
import { isActiveEntity, isEntity } from "../../../domain/entity";
import { Pond } from "../../../domain/pond";
import { PondArea } from "../../../domain/pondArea";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { ApplicationState } from "../../../store/modules";
import { NewOrEditStateType } from "../../../store/modules/master/pondArea/newOrEditState/reducer";
import { ConfirmModal } from "../../components/molecules/ConfirmModal";
import { PondAreaForm } from "../../components/organisms/PondAreaForm";

interface StateProps {
  ponds: Pond[];
  pondAreas: PondArea[];
  newOrEditState: NewOrEditStateType;
}

interface DispatchProps {
  editStateService: EditStateService;
  newStateService: NewStateService;
}

type PondAreaEditPageProps = StateProps & DispatchProps;
interface PondAreaEditPageState {
  isOpenDeleteConfirm: boolean;
}

export class Wrapped extends React.Component<PondAreaEditPageProps, PondAreaEditPageState> {
  constructor(props: PondAreaEditPageProps) {
    super(props);

    this.state = {
      isOpenDeleteConfirm: false,
    };
  }

  public render() {
    const { pondArea, isSaving } = this.props.newOrEditState;
    const originalPondArea = pondArea && isEntity(pondArea) && this.props.pondAreas.find((a) => a.id === pondArea.id);
    return (
      <>
        <PondAreaForm
          ponds={this.props.ponds}
          pondArea={pondArea}
          originalPondArea={originalPondArea || null}
          isSaving={isSaving}
          onChangeName={this.handleChangeName}
          onChangePonds={this.handleChangePonds}
          onClickSave={this.handleClickSavePondArea}
          onClickCancel={this.handleClickCancelPondArea}
          onClickAdd={this.handleClickAddPondArea}
          onClickDelete={this.openDeleteConfirmModal}
        />
        <ConfirmModal
          isOpen={this.state.isOpenDeleteConfirm}
          onClose={this.closeDeleteConfirmModal}
          onClickCancel={this.closeDeleteConfirmModal}
          onClickOk={this.handleClickDeletePondArea}
          cancelText={"キャンセル"}
          okText={"削除"}
          confirmMessage={this.getConfirmMessage()}
        />
      </>
    );
  }

  private getConfirmMessage = (): string => {
    if (!this.state.isOpenDeleteConfirm) {
      return "";
    }

    const { pondArea } = this.props.newOrEditState;
    if (pondArea === null || !isEntity(pondArea)) {
      throw new ApplicationError("エリアが見つかりませんでした。");
    }
    const entity = this.props.pondAreas.find((area) => area.id === pondArea.id);
    if (!entity) {
      throw new ApplicationError("エリアが見つかりませんでした。");
    }
    const suffix = "エリアを削除してもよろしいでしょうか？";
    if (entity.pondIds.length !== 0) {
      return `このエリアの野池はすべて未割当となります。\n${suffix}`;
    }
    return suffix;
  };

  private handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.editStateService.changeName(e.target.value);
  };

  private handleChangePonds = (pondIds: number[]) => {
    this.props.editStateService.changePonds(pondIds);
  };

  private handleClickSavePondArea = () => {
    this.props.editStateService.savePondArea();
  };

  private handleClickCancelPondArea = () => {
    this.props.editStateService.cancelPondArea();
  };

  private handleClickAddPondArea = () => {
    this.props.newStateService.createPondArea();
  };

  private handleClickDeletePondArea = async () => {
    await this.props.editStateService.deletePondArea();
    this.closeDeleteConfirmModal();
  };

  private closeDeleteConfirmModal = () => {
    this.setState({ isOpenDeleteConfirm: false });
  };
  private openDeleteConfirmModal = () => {
    this.setState({ isOpenDeleteConfirm: true });
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { newOrEditState } = state.master.pondArea;
  return {
    ponds: state.api.pond.ponds.filter(isActiveEntity),
    pondAreas: state.api.pondArea.pondAreas.filter(isActiveEntity),
    newOrEditState,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    editStateService: new EditStateService(dispatch),
    newStateService: new NewStateService(dispatch),
  };
};

export const PondAreaEditPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapped);
