import { default as moment } from "moment";
import * as React from "react";
import styled from "styled-components";
import { getUniqueKey, UniqueKey } from "../../../../domain/entity";
import { PondReport } from "../../../../domain/pondReport";
import { ToAdultSelectionReportVM } from "../../../../domain/selectionReportVM";
import { COLOR } from "../../../styles/Color";
import { DIMEN } from "../../../styles/Dimen";
import { GroupListItem } from "../../atoms/GroupListItem";

interface MobileAdultPondReportListProps {
  currentPondReport: PondReport;
  prevPondReport: PondReport | null;
  onClickAdultCarp: (uniqueKey: UniqueKey) => void;
}

export const AdultPondReportList = (props: MobileAdultPondReportListProps) => {
  return (
    <List>
      <AdultCarpReportList
        currentPondReport={props.currentPondReport}
        prevPondReport={props.prevPondReport}
        onClickAdultCarp={props.onClickAdultCarp}
      />
    </List>
  );
};

interface AdultCarpReportListProps {
  currentPondReport: PondReport;
  prevPondReport: PondReport | null;
  onClickAdultCarp: (uniqueKey: UniqueKey) => void;
}
const AdultCarpReportList = (props: AdultCarpReportListProps) => {
  const currentReports = ToAdultSelectionReportVM(props.currentPondReport.adultCarpReports);
  const prevReports = props.prevPondReport ? ToAdultSelectionReportVM(props.prevPondReport.adultCarpReports) : [];

  return (
    <>
      {currentReports.map((r) => {
        const currentUniqueKey = getUniqueKey(r);
        const prevReport = prevReports.find((pr) => pr.selectionNumber === r.selectionNumber);
        return (
          <GroupListItem
            className={"mobile"}
            key={currentUniqueKey}
            onClick={() => props.onClickAdultCarp(currentUniqueKey)}>
            <ListTitle>
              <SelectionName>{r.name}</SelectionName>
            </ListTitle>
            <ListCaption>
              <DetailHeader>
                <DateCell />
                <AmountCell>匹数</AmountCell>
                <SizeCell />
                <QualityCell />
                <MalformationCell />
                <RatioCell />
              </DetailHeader>
              <CurrentDetail>
                <DateCell>{r.date ? moment(r.date).format("YYYY/MM/DD") : "-"}</DateCell>
                <AmountCell>{r.amount ? `${r.amount}匹` : "- 匹"}</AmountCell>
                <SizeCell />
                <QualityCell />
                <MalformationCell />
                <RatioCell />
              </CurrentDetail>
              <PrevDetail>
                <DateCell>
                  {prevReport && prevReport.date ? moment(prevReport.date).format("YYYY/MM/DD") : "-"}
                </DateCell>
                <AmountCell>{prevReport && prevReport.amount ? `${prevReport.amount}匹` : "- 匹"}</AmountCell>
                <SizeCell />
                <QualityCell />
                <MalformationCell />
                <RatioCell />
              </PrevDetail>
            </ListCaption>
          </GroupListItem>
        );
      })}
    </>
  );
};

const List = styled.div``;

const ListTitle = styled.div`
  display: flex;
  padding: ${DIMEN.X0_5} 0;
  min-height: 28px;
`;

const SelectionName = styled.div`
  font-weight: bold;
  font-size: 18px;
  flex-basis: 7em;
`;

const ListCaption = styled.div`
  padding: ${DIMEN.X0_5} 1.5em;
  min-height: 50px;
  position: relative;
`;

const DetailHeader = styled.div`
  display: flex;
  color: ${COLOR.Gray["darken-1"]};
  font-size: 12px;
  margin-bottom: ${DIMEN.X0_5};
  > div {
    padding: ${DIMEN.X0_5} 0;
    border-bottom: 1px solid ${COLOR.Gray["darken-1"]};
    &:first-child {
      border-bottom: 0;
    }
  }
`;
const CurrentDetail = styled.div`
  display: flex;
`;
const PrevDetail = styled.div`
  display: flex;
  font-size: 12px;
  text-align: center;
  color: ${COLOR.Gray["darken-1"]};
`;
const BaseCell = styled.div`
  flex: 1 1 0;
  text-align: center;
`;
const DateCell = styled(BaseCell)`
  flex: 1 1 4rem;
`;
const AmountCell = styled(BaseCell)`
  flex: 1 1 4rem;
`;
const SizeCell = styled(BaseCell)``;
const QualityCell = styled(BaseCell)``;
const MalformationCell = styled(BaseCell)``;
const RatioCell = styled(BaseCell)``;
