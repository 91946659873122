import * as React from "react";
import { MobileView } from "react-device-detect";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AlertModalContainer } from "../presentation/containers/AlertModal";
import { AuthenticationContainer } from "../presentation/containers/Authentication";
import { ErrorBoundaryContainer } from "../presentation/containers/ErrorBoundary";
import { ImageGalleryContainer } from "../presentation/containers/ImageGallery";
import { LoginPage } from "../presentation/pages/Login";
import { EnvironmentReportPage } from "../presentation/pages/mobile/EnvironmentReport";
import { EnvironmentReportDetailPage } from "../presentation/pages/mobile/EnvironmentReportDetail";
import { FryPondSelectionPage } from "../presentation/pages/mobile/FryPondSelection";
import { PondFilterPage } from "../presentation/pages/mobile/FryPondSelectionFilter";
import { PondAreaSelectionPage } from "../presentation/pages/mobile/PondAreaSelection";
import { PondReportAdultCarpPage } from "../presentation/pages/mobile/PondReportAdultCarp";
import { PondReportCarpSelectionPage } from "../presentation/pages/mobile/PondReportCarpSelection";
import { PondReportPondDisinfectionPage } from "../presentation/pages/mobile/PondReportPondDisinfection";
import { PondReportSelectionPage } from "../presentation/pages/mobile/PondReportSelection";
import { PondSelectionPage } from "../presentation/pages/mobile/PondSelection";
import { ReportListPage } from "../presentation/pages/mobile/ReportList";
import { ReportListDetailPage } from "../presentation/pages/mobile/ReportListDetail";
import { ReportListFilterPage } from "../presentation/pages/mobile/ReportListFilter";
import { WorkerSelectionPage } from "../presentation/pages/mobile/WorkerSelection";
import { WorkReportFeedPage } from "../presentation/pages/mobile/WorkReportFeed";
import { WorkReportFertilizerPage } from "../presentation/pages/mobile/WorkReportFertilizer";
import { WorkReportHerbicidePage } from "../presentation/pages/mobile/WorkReportHerbicide";
import { WorkReportMenuPage } from "../presentation/pages/mobile/WorkReportMenu";
import { WorkReportNotePage } from "../presentation/pages/mobile/WorkReportNote";

export const MobileRouter = () => {
  return (
    <MobileView style={{ height: "100%", width: "100%" }}>
      <BrowserRouter>
        <ErrorBoundaryContainer>
          <AlertModalContainer />
          <Switch>
            <Route path="/login" exact component={LoginPage} />
            <AuthenticationContainer>
              <ImageGalleryContainer />
              <Switch>
                <Route path="/reports/pond_reports/adult_carp_reports" exact component={PondReportAdultCarpPage} />
                <Route
                  path="/reports/pond_reports/pond_disinfection_reports"
                  exact
                  component={PondReportPondDisinfectionPage}
                />
                <Route
                  path="/reports/pond_reports/carp_selection_reports"
                  exact
                  component={PondReportCarpSelectionPage}
                />
                <Route path="/reports/pond_reports" exact component={PondReportSelectionPage} />
                <Route path="/reports/environment_reports" exact component={EnvironmentReportPage} />
                <Route path="/reports/environment_reports/detail" exact component={EnvironmentReportDetailPage} />
                <Route path="/reports/feeds" exact component={WorkReportFeedPage} />
                <Route path="/reports/herbicides" exact component={WorkReportHerbicidePage} />
                <Route path="/reports/fertilizers" exact component={WorkReportFertilizerPage} />
                <Route path="/reports/notes" exact component={WorkReportNotePage} />
                <Route path="/reports/menu" exact component={WorkReportMenuPage} />
                <Route path="/reports/detail" exact component={ReportListDetailPage} />
                <Route path="/reports/filter" exact component={ReportListFilterPage} />
                <Route path="/reports" exact component={ReportListPage} />
                <Route path="/ponds/fry/filter" exact component={PondFilterPage} />
                <Route path="/ponds/fry" exact component={FryPondSelectionPage} />
                <Route path="/ponds/pond_areas" exact component={PondAreaSelectionPage} />
                <Route path="/ponds/pond_areas" component={PondSelectionPage} />
                <Route path="/ponds/workers" exact component={WorkerSelectionPage} />
                <Route path="/ponds/workers" component={PondSelectionPage} />
                <Route path="/ponds" exact component={PondSelectionPage} />
                <Route component={PondSelectionPage} />
              </Switch>
            </AuthenticationContainer>
          </Switch>
        </ErrorBoundaryContainer>
      </BrowserRouter>
    </MobileView>
  );
};
