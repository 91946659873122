import { EditImage, Image, NewImage } from "../../domain/image";
import * as api from "../../libs/client";
import { translateApiToImage, translateImageToApi } from "./translate";

interface IImageRepository {
  getCreateImageURL: (image: NewImage) => Promise<Image>;
  putImage: (image: EditImage) => Promise<Image>;
}

export class ImageRepository implements IImageRepository {
  public async getCreateImageURL(image: NewImage): Promise<Image> {
    const imagesApi = new api.ImagesApi(api.serverConfig);
    // TODO: Validationが必要になりそう
    const response = await imagesApi.getCreateImageURL(translateImageToApi(image));
    return translateApiToImage(response.data);
  }

  public async putImage(image: EditImage): Promise<Image> {
    const imagesApi = new api.ImagesApi(api.serverConfig);
    // TODO: Validationが必要になりそう
    const response = await imagesApi.putImage(image.id, translateImageToApi(image));
    return translateApiToImage(response.data);
  }
}
