import * as _ from "lodash";
import { default as moment } from "moment";
import { Entity, generateUid, isEntity, isNewEntity, NewEntity } from "../entity";
import {
  getValidation,
  isEmptyString,
  isLimitedNegativeNumber,
  isLimitedNumber,
  Validation,
  Validator,
} from "../validation";

interface EnvironmentReportAttribute extends Record<string, unknown> {
  pondId: number;
  date: Date;
  value: string;
}

export interface NewEnvironmentReport extends EnvironmentReportAttribute, NewEntity {}
export interface EditEnvironmentReport extends EnvironmentReportAttribute, Entity {}

export type NewOrEditEnvironmentReport = NewEnvironmentReport | EditEnvironmentReport;
export type EnvironmentReport = EditEnvironmentReport;

// service
const NEW_ENTITY_PREFIX = "er-";

export function createNewEnvironmentReport(pondId: number, date: Date): NewEnvironmentReport {
  return {
    uid: generateUid(NEW_ENTITY_PREFIX),
    pondId,
    date,
    value: "",
  };
}

export function isNewEnvironmentReport(report: NewOrEditEnvironmentReport): report is NewEnvironmentReport {
  return isNewEntity(report);
}

export function isEditEnvironmentReport(report: NewOrEditEnvironmentReport): report is EditEnvironmentReport {
  return isEntity(report);
}
export function filterEnvironmentReportByDates(report: NewOrEditEnvironmentReport, date: Date): boolean {
  const reportDate = moment(report.date);
  return reportDate.isSame(date, "dates");
}
export function filterEnvironmentReportByPond(report: NewOrEditEnvironmentReport, pondId: null | number): boolean {
  return report.pondId === pondId;
}

interface FilterCriteria {
  date?: Date;
  pondId?: null | number;
}
export function filterEnvironmentReportByCriteria(
  environmentReports: NewOrEditEnvironmentReport[],
  { date, pondId }: FilterCriteria
): NewOrEditEnvironmentReport[] {
  let reports = environmentReports;
  if (typeof date !== "undefined") {
    reports = reports.filter((r) => filterEnvironmentReportByDates(r, date));
  }
  if (typeof pondId !== "undefined") {
    reports = reports.filter((r) => filterEnvironmentReportByPond(r, pondId));
  }
  return reports;
}

function sortEnvironmentReportByDate(
  a: NewOrEditEnvironmentReport,
  b: NewOrEditEnvironmentReport,
  isAsc: boolean
): number {
  const asc = isAsc ? 1 : -1;
  const dateA = moment(a.date);
  const dateB = moment(b.date);
  if (dateA.isSame(dateB)) {
    return 0;
  }

  const diff = dateA.isBefore(b.date, "date") ? -1 : 1;
  return diff * asc;
}

export function sortEnvironmentReportByDateAsc(a: NewOrEditEnvironmentReport, b: NewOrEditEnvironmentReport): number {
  return sortEnvironmentReportByDate(a, b, true);
}
export function sortEnvironmentReportByDateDesc(a: NewOrEditEnvironmentReport, b: NewOrEditEnvironmentReport): number {
  return sortEnvironmentReportByDate(a, b, false);
}

export function copyEnvironmentReport(
  source: NewOrEditEnvironmentReport,
  target: NewOrEditEnvironmentReport
): NewOrEditEnvironmentReport {
  return {
    ...target,
    value: source.value,
  };
}
export function getLatestEnvironmentReport(reports: EnvironmentReport[], date: Date): EnvironmentReport | null {
  const momentDate = moment(date);
  const descendingReports = reports.concat().sort(sortEnvironmentReportByDateDesc);
  const report = descendingReports.find((r) => {
    const reportDate = moment(r.date);
    return reportDate.isSameOrBefore(momentDate, "date");
  });
  return report || null;
}

const WEEK_COUNT_FROM_BASE = 3; // 自身を含んで7日分とする
const MONTH_COUNT_FROM_BASE = 15; // 自身を含んで31日分とする
const THREE_MONTHS_COUNT_FROM_BASE = MONTH_COUNT_FROM_BASE * 3 + 1; // 自身を含んで93日分とする
const HALF_YEAR_COUNT_FROM_BASE = MONTH_COUNT_FROM_BASE * 6 + 3; // 自身を含んで187日分とする

export function getCountFromBase(periodType: "week" | "month" | "three_months" | "half_year"): number {
  switch (periodType) {
    case "week":
      return WEEK_COUNT_FROM_BASE;
    case "month":
      return MONTH_COUNT_FROM_BASE;
    case "three_months":
      return THREE_MONTHS_COUNT_FROM_BASE;
    case "half_year":
      return HALF_YEAR_COUNT_FROM_BASE;
    default:
      return 0;
  }
}

export function getCountFromEdgeBase(
  periodType: "week" | "month" | "three_months" | "half_year",
  includeOwn: boolean = true
): number {
  return getCountFromBase(periodType) * 2 + (includeOwn ? 1 : 0);
}

function createEmptyEnvironmentReport(pondId: number, date: Date): NewOrEditEnvironmentReport {
  return {
    ...createNewEnvironmentReport(pondId, date),
    value: "",
  };
}

export function getEnvironmentReportsBasedOnDate(
  environmentReports: EnvironmentReport[],
  pondId: number,
  baseDate: Date,
  periodType: "week" | "month" | "three_months" | "half_year"
): NewOrEditEnvironmentReport[] {
  const base = moment(baseDate);
  const startDate = base.clone().subtract(getCountFromBase(periodType), "days");
  return _.range(0, getCountFromEdgeBase(periodType)).map((num) => {
    const date = startDate.clone().add(num, "days");
    const report = environmentReports.find((r) => date.isSame(r.date, "dates"));
    return report || createEmptyEnvironmentReport(pondId, date.toDate());
  });
}
export function getEnvironmentReportsFromDate(
  environmentReports: EnvironmentReport[],
  pondId: number,
  fromDate: Date,
  periodType: "week" | "month" | "three_months" | "half_year"
): NewOrEditEnvironmentReport[] {
  const base = moment(fromDate);
  const startDate = base.clone().subtract(getCountFromEdgeBase(periodType, false), "days");
  return _.range(0, getCountFromEdgeBase(periodType)).map((num) => {
    const date = startDate.clone().add(num, "days");
    const report = environmentReports.find((r) => date.isSame(r.date, "dates"));
    return report || createEmptyEnvironmentReport(pondId, date.toDate());
  });
}

/*** Validator ***/
export class EnvironmentReportValidator extends Validator<NewOrEditEnvironmentReport> {
  public validate(entity: NewOrEditEnvironmentReport) {
    const dateValidation = validateDate(entity);
    if (dateValidation) {
      this.addMessages(dateValidation);
    }
    const pondValidation = validatePond(entity);
    if (pondValidation) {
      this.addMessages(pondValidation);
    }
    const valueValidation = validateValue(entity);
    if (valueValidation) {
      this.addMessages(valueValidation);
    }
  }
}

function validateDate({ date }: NewOrEditEnvironmentReport): Validation | null {
  if (date === null) {
    return getValidation("日付は必須です。入力してください。");
  }
  return null;
}

function validatePond({ pondId }: NewOrEditEnvironmentReport): Validation | null {
  if (pondId === null) {
    return getValidation("野池は必須です。");
  }
  return null;
}

function validateValue({ value }: NewOrEditEnvironmentReport): Validation | null {
  if (isEmptyString(value)) {
    return getValidation("水温は必須です。入力してください。");
  }
  const isPositiveNumber = isLimitedNumber(value, 9, 4);
  const isNegativeNumber = isLimitedNegativeNumber(value, 9, 4);
  if (!isPositiveNumber && !isNegativeNumber) {
    return getValidation("水温は整数部9桁、小数部4桁で入力してください。");
  }
  return null;
}
