import { Entity, generateUid, isEntity, isNewEntity, NewEntity } from "../entity";
import { getValidation, isEmptyString, isLimitedNumber, Validation, Validator } from "../validation";

interface FertilizerReportAttribute extends Record<string, unknown> {
  fertilizerId: null | number;
  useMethodTypeId: null | number;
  amount: string;
}

export interface NewFertilizerReport extends FertilizerReportAttribute, NewEntity {}
export interface EditFertilizerReport extends FertilizerReportAttribute, Entity {}

export type NewOrEditFertilizerReport = NewFertilizerReport | EditFertilizerReport;
export type FertilizerReport = EditFertilizerReport;

// service
const NEW_ENTITY_PREFIX = "fer-";

export function createNewFertilizerReport(): NewFertilizerReport {
  return {
    uid: generateUid(NEW_ENTITY_PREFIX),
    fertilizerId: null,
    useMethodTypeId: null,
    amount: "",
  };
}

export function isNewFertilizerReport(report: NewOrEditFertilizerReport): report is NewFertilizerReport {
  return isNewEntity(report);
}

export function isEditFertilizerReport(report: NewOrEditFertilizerReport): report is EditFertilizerReport {
  return isEntity(report);
}

export function copyFertilizerReport(
  source: NewOrEditFertilizerReport,
  target: NewOrEditFertilizerReport
): NewOrEditFertilizerReport {
  return {
    ...target,
    fertilizerId: source.fertilizerId,
    useMethodTypeId: source.useMethodTypeId,
    amount: source.amount,
  };
}

/*** Validator ***/
export class FertilizerReportValidator extends Validator<NewOrEditFertilizerReport> {
  public validate(entity: NewOrEditFertilizerReport) {
    const amountValidation = validateAmount(entity);
    if (amountValidation) {
      this.addMessages(amountValidation);
    }
    const useMethodTypeValidation = validateUseMethodType(entity);
    if (useMethodTypeValidation) {
      this.addMessages(useMethodTypeValidation);
    }
    const fertilizerValidation = validateFertilizer(entity);
    if (fertilizerValidation) {
      this.addMessages(fertilizerValidation);
    }
  }
}

function validateAmount({ amount }: NewOrEditFertilizerReport): Validation | null {
  if (isEmptyString(amount)) {
    return getValidation("量は必須です。入力してください。");
  }
  if (!isLimitedNumber(amount, 9, 4)) {
    return getValidation("量は整数部9桁、小数部4桁で入力してください。");
  }
  return null;
}

function validateUseMethodType({ useMethodTypeId }: NewOrEditFertilizerReport): Validation | null {
  if (useMethodTypeId === null) {
    return getValidation("単位は必須です。");
  }
  return null;
}

function validateFertilizer({ fertilizerId }: NewOrEditFertilizerReport): Validation | null {
  if (fertilizerId === null) {
    return getValidation("肥料は必須です。");
  }
  return null;
}
