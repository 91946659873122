import * as React from "react";
import { BottomMenu, Menu, MenuHeader, MenuInMenu, MenuInMenuItem, MenuItem, OuterImage } from "./elements";

export type ActiveTabNames =
  | "workers"
  | "feeds"
  | "fertilizers"
  | "pond_areas"
  | "ponds"
  | "parent_carps"
  | "herbicides"
  | "reports/list"
  | "pond_reports"
  | "work_reports/feed"
  | "work_reports/fertilizer"
  | "work_reports/herbicide"
  | "work_reports/note"
  | "environment_reports";

interface NavigationHeaderProps {
  activeTabName: ActiveTabNames;
  userName: string;
  onClickWorkers: () => void;
  onClickFeeds: () => void;
  onClickFertilizers: () => void;
  onClickPondAreas: () => void;
  onClickPonds: () => void;
  onClickParentCarps: () => void;
  onClickHerbicides: () => void;
  onClickReportsList: () => void;
  onClickPondReports: () => void;
  onClickWorkReportsFeed: () => void;
  onClickWorkReportsFertilizer: () => void;
  onClickWorkReportsHerbicide: () => void;
  onClickWorkReportsNote: () => void;
  onClickEnvironmentReport: () => void;
  onClickLogout: () => void;
}

export const NavigationHeader = (props: NavigationHeaderProps) => {
  return (
    <>
      <OuterImage />
      <Menu>
        <MenuItem>
          <MenuHeader>基本情報</MenuHeader>
          <MenuInMenu>
            <MenuInMenuItem
              name="parent_carps"
              active={"parent_carps" === props.activeTabName}
              onClick={props.onClickParentCarps}>
              親鯉
            </MenuInMenuItem>
            <MenuInMenuItem
              name="pond_areas"
              active={"pond_areas" === props.activeTabName}
              onClick={props.onClickPondAreas}>
              エリア
            </MenuInMenuItem>
            <MenuInMenuItem name="ponds" active={"ponds" === props.activeTabName} onClick={props.onClickPonds}>
              野池
            </MenuInMenuItem>
            <MenuInMenuItem name="workers" active={"workers" === props.activeTabName} onClick={props.onClickWorkers}>
              担当者
            </MenuInMenuItem>
            <MenuInMenuItem name="feeds" active={"feeds" === props.activeTabName} onClick={props.onClickFeeds}>
              餌
            </MenuInMenuItem>
            <MenuInMenuItem
              name="fertilizers"
              active={"fertilizers" === props.activeTabName}
              onClick={props.onClickFertilizers}>
              肥料
            </MenuInMenuItem>
            <MenuInMenuItem
              name="herbicides"
              active={"herbicides" === props.activeTabName}
              onClick={props.onClickHerbicides}>
              除草剤・薬品
            </MenuInMenuItem>
          </MenuInMenu>
        </MenuItem>

        <MenuItem>
          <MenuHeader>記録閲覧</MenuHeader>
          <MenuInMenu>
            <MenuInMenuItem
              name="reports/list"
              active={"reports/list" === props.activeTabName}
              onClick={props.onClickReportsList}>
              一覧
            </MenuInMenuItem>
          </MenuInMenu>
        </MenuItem>

        <MenuItem>
          <MenuHeader>記録編集</MenuHeader>
          <MenuInMenu>
            <MenuInMenuItem
              name="pond_reports"
              active={"pond_reports" === props.activeTabName}
              onClick={props.onClickPondReports}>
              選別・放鯉・池揚げ
            </MenuInMenuItem>

            <MenuInMenuItem
              name="work_reports/feed"
              active={"work_reports/feed" === props.activeTabName}
              onClick={props.onClickWorkReportsFeed}>
              給餌
            </MenuInMenuItem>

            <MenuInMenuItem
              name="environment_reports"
              active={"environment_reports" === props.activeTabName}
              onClick={props.onClickEnvironmentReport}>
              水温
            </MenuInMenuItem>
            <MenuInMenuItem
              name="work_reports/fertilizer"
              active={"work_reports/fertilizer" === props.activeTabName}
              onClick={props.onClickWorkReportsFertilizer}>
              肥料
            </MenuInMenuItem>
            <MenuInMenuItem
              name="work_reports/herbicide"
              active={"work_reports/herbicide" === props.activeTabName}
              onClick={props.onClickWorkReportsHerbicide}>
              除草剤（池）・薬品
            </MenuInMenuItem>
            <MenuInMenuItem
              name="work_reports/note"
              active={"work_reports/note" === props.activeTabName}
              onClick={props.onClickWorkReportsNote}>
              メモ
            </MenuInMenuItem>
          </MenuInMenu>
        </MenuItem>

        <BottomMenu>
          <MenuItem>{props.userName}</MenuItem>
          <MenuItem onClick={props.onClickLogout}>ログアウト</MenuItem>
        </BottomMenu>
      </Menu>
    </>
  );
};
