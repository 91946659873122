import { Dispatch } from "redux";
import { ParentCarp } from "../../../domain/parentCarp";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ParentCarpRepository } from "../../../infrastracture/parentCarp/repository";
import { ApplicationState } from "../../../store/modules";
import { apiParentCarpActions } from "../../../store/modules/api/parentCarp/ducks";

interface IParentCarpApiService extends IApplicationService {
  init: () => void;
  fetchUnFetched: () => void;
}

export class ParentCarpApiService implements IParentCarpApiService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError((dispatch) => dispatch(apiParentCarpActions.fetchFail()))
  public async init() {
    this.dispatch(apiParentCarpActions.fetchStart());
    const parentCarps: ParentCarp[] = await new ParentCarpRepository().getParentCarps();
    this.dispatch(apiParentCarpActions.fetchComplete({ parentCarps }));
  }

  @catchApplicationError((dispatch) => dispatch(apiParentCarpActions.fetchFail()))
  public async fetchUnFetched() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      if (getState().api.parentCarp.isFetched) {
        return;
      }
      this.dispatch(apiParentCarpActions.fetchStart());
      const parentCarps: ParentCarp[] = await new ParentCarpRepository().getParentCarps();
      this.dispatch(apiParentCarpActions.fetchComplete({ parentCarps }));
    });
  }
}
