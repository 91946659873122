import * as _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { Feed } from "../../../../domain/feed";
import { FeedReasonType } from "../../../../domain/feedReasonType";
import { PondType } from "../../../../domain/pondType";
import { isBucketUseMethodType, UseMethodType } from "../../../../domain/useMethodType";
import { COLOR } from "../../../styles/Color";
import { DIMEN } from "../../../styles/Dimen";
import { SaveButton } from "../../atoms/Button/Mobile";
import { CheckListItem } from "../../atoms/CheckListItem";
import { Form } from "../../atoms/Form/Form";
import { FormSelect } from "../../atoms/Form/Select";
import { ContentSection, PageSection } from "../../atoms/Section";
import { Spinner } from "../../atoms/Spinner";
import { ReportAmountUnitInput } from "../ReportAmountInput";
import { WorkReportFormNoteBody } from "../WorkReportFormNote";

interface WorkReportFormFeedProps {
  feeds: Feed[];
  feedReasonTypes: FeedReasonType[];
  useMethodTypes: UseMethodType[];
  pondTypes: PondType[];
  feedId: null | number;
  feedReasonTypeId: null | number;
  count: string;
  amount: string;
  useMethodTypeId: null | number;
  note: string;
  imageIds: number[];
  pondTypeId: number;
  onChangeFeed: (id: number | null) => void;
  onChangeFeedReasonType: (id: null | number) => void;
  onChangeCount: (count: string) => void;
  onChangeAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickAmountUp: () => void;
  onClickAmountDown: () => void;
  onChangeNote: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickImage: (imageIds: number[], imageId: number) => void;
  onClickImageRemove: (id: number) => void;
  onChangeFeederUseMethodType: () => void;
  onChangeBucketUseMethodType: () => void;
  onChangePondType: ((id: null | number) => void) | null;
}
export const WorkReportFormFeed = (
  props: WorkReportFormFeedProps & { onClickSave: () => void; isSaving: boolean; disabledSave: boolean }
) => {
  return (
    <Form>
      {props.isSaving && <Spinner />}
      <PageSection>給餌を記録</PageSection>
      <WorkReportFormFeedBody {...props} />
      <SaveButton onClick={props.onClickSave} disabled={props.disabledSave} />
    </Form>
  );
};

export const WorkReportFormFeedBody = (props: WorkReportFormFeedProps) => {
  const selectedUseMethodType = props.useMethodTypes.find((u) => u.id === props.useMethodTypeId);
  const isBucket = selectedUseMethodType ? isBucketUseMethodType(selectedUseMethodType) : false;

  return (
    <>
      <ContentSection>
        <SwitchContentHeader className={!isBucket ? "active" : ""} onClick={props.onChangeFeederUseMethodType}>
          時間
        </SwitchContentHeader>
        <SwitchContentHeader className={isBucket ? "active" : ""} onClick={props.onChangeBucketUseMethodType}>
          バケツ
        </SwitchContentHeader>
      </ContentSection>
      <Content>
        <ReportAmountUnitInput
          useMethodTypes={selectedUseMethodType ? [selectedUseMethodType] : []}
          amount={props.amount}
          useMethodTypeId={props.useMethodTypeId}
          onChangeAmount={props.onChangeAmount}
          onChangeUseMethodType={_.noop}
          onClickAmountUp={props.onClickAmountUp}
          onClickAmountDown={props.onClickAmountDown}
        />
      </Content>
      <ContentSection>理由</ContentSection>
      <Content>
        <FormSelect
          value={props.feedReasonTypeId}
          options={props.feedReasonTypes}
          onChange={props.onChangeFeedReasonType}
        />
      </Content>

      <WorkReportFormNoteBody
        note={props.note}
        imageIds={props.imageIds}
        onChangeNote={props.onChangeNote}
        onChangeFile={props.onChangeFile}
        onClickImage={props.onClickImage}
        onClickImageRemove={props.onClickImageRemove}
      />

      <ContentSection>種類</ContentSection>
      <Content>
        <FormSelect value={props.feedId} options={props.feeds} onChange={props.onChangeFeed} />
      </Content>

      {!isBucket && (
        <>
          <ContentSection>回数</ContentSection>
          <Content>
            <CountCheckList count={props.count} onClickCount={props.onChangeCount} />
          </Content>
        </>
      )}

      {!!props.onChangePondType && (
        <>
          <ContentSection>池の区分</ContentSection>
          <Content>
            <FormSelect value={props.pondTypeId} options={props.pondTypes} onChange={props.onChangePondType} />
          </Content>
        </>
      )}
    </>
  );
};

interface CountCheckListProps {
  count: string;
  onClickCount: (count: string) => void;
}
const CountCheckList = ({ count, onClickCount }: CountCheckListProps) => {
  return (
    <>
      <CheckListItem onClick={() => onClickCount("5")} active={"5" === count}>
        5回
      </CheckListItem>
      <CheckListItem onClick={() => onClickCount("6")} active={"6" === count}>
        6回
      </CheckListItem>
      <CheckListItem onClick={() => onClickCount("8")} active={"8" === count}>
        8回
      </CheckListItem>
      <CheckListItem onClick={() => onClickCount("12")} active={"12" === count}>
        12回
      </CheckListItem>
    </>
  );
};

const Content = styled.div`
  padding: ${DIMEN.X1};
`;

const SwitchContentHeader = styled.span`
  color: ${COLOR.Gray["darken-1"]};
  margin-right: ${DIMEN.X2};
  cursor: pointer;
  &.active {
    font-weight: 700;
    color: ${COLOR.Base.black};
  }
`;
