import { default as moment } from "moment";
import { EditWorkReport, NewWorkReport, WorkReport } from "../../domain/workReport";
import * as api from "../../libs/client";
import { translateApiToWorkReport, translateWorkReportToApi } from "./translate";

interface IWorkReportRepository {
  getWorkReports: (query: GetSearchQuery) => Promise<WorkReport[]>;
  postWorkReport: (workReport: NewWorkReport) => Promise<WorkReport>;
  putWorkReport: (workReport: EditWorkReport) => Promise<WorkReport>;
  deleteWorkReport: (workReport: WorkReport) => Promise<boolean>;
}

interface GetSearchQuery {
  startDate?: Date;
  endDate?: Date;
  pondId?: number;
  report?: "feed" | "fertilizer" | "herbicide";
  limit?: number;
}

export class WorkReportRepository implements IWorkReportRepository {
  public async getWorkReports(query: GetSearchQuery = {}): Promise<WorkReport[]> {
    const workReportsApi = new api.WorkReportsApi(api.serverConfig);
    const startDate = moment(query.startDate || "invalid");
    const endDate = moment(query.endDate || "invalid");
    const response = await workReportsApi.getWorkReports(
      startDate.isValid() ? startDate.format("YYYY-MM-DD") : undefined,
      endDate.isValid() ? endDate.format("YYYY-MM-DD") : undefined,
      query.pondId,
      query.report,
      query.limit
    );
    return response.data.map((d) => translateApiToWorkReport(d));
  }

  public async postWorkReport(workReport: NewWorkReport): Promise<WorkReport> {
    const workReportsApi = new api.WorkReportsApi(api.serverConfig);
    const response = await workReportsApi.postWorkReport(translateWorkReportToApi(workReport));
    return translateApiToWorkReport(response.data);
  }

  public async putWorkReport(workReport: EditWorkReport): Promise<WorkReport> {
    const workReportsApi = new api.WorkReportsApi(api.serverConfig);
    const response = await workReportsApi.putWorkReport(workReport.id, translateWorkReportToApi(workReport));
    return translateApiToWorkReport(response.data);
  }

  public async deleteWorkReport(workReport: WorkReport): Promise<boolean> {
    const workReportsApi = new api.WorkReportsApi(api.serverConfig);
    const response = await workReportsApi.deleteWorkReport(workReport.id);
    return response.data;
  }
}
