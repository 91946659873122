/*
 * モバイルの野池一覧画面でのソートを行うための関数群。
 *
 */

import { default as moment } from "moment";
import { sortPosition } from "../entity";
import { getCurrentPondReport, getCurrentPondStatus, PondReport } from "../pondReport";
import { Pond } from "./index";

export function sortByPondReportDate(
  ponds: Pond[],
  pondReports: PondReport[],
  isAscDate: boolean,
  selectedDate: Date = new Date()
): Pond[] {
  const asc = isAscDate ? 1 : -1;

  return ponds.concat().sort((a, b) => {
    const pondReportA = getCurrentPondReport(pondReports, a.id, selectedDate);
    const pondReportB = getCurrentPondReport(pondReports, b.id, selectedDate);

    const pondStatusA = (!!pondReportA && getCurrentPondStatus(pondReportA)) || null;
    const pondStatusB = (!!pondReportB && getCurrentPondStatus(pondReportB)) || null;
    if (pondStatusA === null && pondStatusB === null) {
      return sortPosition(a, b, true);
    }
    if (pondStatusA === null) {
      return -1 * asc;
    }
    if (pondStatusB === null) {
      return 1 * asc;
    }
    const dateA = moment(pondStatusA.date);
    const dateB = moment(pondStatusB.date);
    if (dateA.isBefore(dateB, "dates")) {
      return -1 * asc;
    }
    if (dateA.isAfter(dateB, "dates")) {
      return 1 * asc;
    }
    return 0;
  });
}
