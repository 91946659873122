import { default as moment } from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { PondArea } from "src/domain/pondArea";
import { NavigationService } from "../../../../application/mobile/navigation";
import { ReadApiService } from "../../../../application/mobile/pondSelection/readApi";
import { NewOrEditStateService } from "../../../../application/mobile/workReport/newOrEditState";
import { CarpVarietyType } from "../../../../domain/carpVarietyType";
import { isActiveEntity } from "../../../../domain/entity";
import { ParentCarp } from "../../../../domain/parentCarp";
import { getNextPond, getPrevPond, isUsedPond, Pond } from "../../../../domain/pond";
import { PondReport } from "../../../../domain/pondReport";
import { PondType } from "../../../../domain/pondType";
import { WorkReport } from "../../../../domain/workReport";
import { ApplicationState } from "../../../../store/modules";
import { AuthStateType } from "../../../../store/modules/auth/reducer";
import { NavigationStateType } from "../../../../store/modules/mobile/navigation/reducer";
import { NewOrEditStateType } from "../../../../store/modules/mobile/workReport/newOrEditState/reducer";
import { Spinner } from "../../../components/atoms/Spinner";
import { BaseNavigationContent, BaseNavigationHeader } from "../../../components/molecules/NavigationHeader";
import { WorkReportMenu } from "../../../components/organisms/WorkReportMenu";
import { MobileLayout } from "../../Layout/Mobile";
import { filterPondsByFryPond } from "../FryPondSelection";
import { filterPondsByFromPage } from "../PondSelection";

interface StateProps {
  ponds: Pond[];
  pondAreas: PondArea[];
  workReports: WorkReport[];
  pondTypes: PondType[];
  parentCarps: ParentCarp[];
  pondReports: PondReport[];
  carpVarietyTypes: CarpVarietyType[];
  navigationState: NavigationStateType;
  newOrEditState: NewOrEditStateType;
  authState: AuthStateType;
}

interface DispatchProps {
  newOrEditStateService: NewOrEditStateService;
  navigationService: NavigationService;
  pondSelectionReadApiService: ReadApiService;
}

type WorkReportMenuPageProps = StateProps & DispatchProps & RouteComponentProps;

export class Wrapped extends React.Component<WorkReportMenuPageProps> {
  public componentWillMount(): void {
    this.initState(this.props);
  }

  public componentWillReceiveProps(nextProps: Readonly<WorkReportMenuPageProps>, nextContext: any): void {
    if (this.props.navigationState.selectedPondId !== nextProps.navigationState.selectedPondId) {
      this.initState(nextProps);
    }
  }

  public render() {
    const pond = this.props.ponds.find((p) => p.id === this.props.navigationState.selectedPondId);

    if (!pond) {
      return (
        <MobileLayout>
          <BaseNavigationHeader title={""} onClickMenu={this.handleClickMenu} />
          <BaseNavigationContent>
            <Spinner />
          </BaseNavigationContent>
        </MobileLayout>
      );
    }

    return (
      <MobileLayout>
        <BaseNavigationHeader title={pond.name} onClickMenu={this.handleClickMenu} />
        <BaseNavigationContent>
          <WorkReportMenu
            selectedDate={this.props.navigationState.selectedDate}
            onClickNextPond={this.handleClickNextPond}
            onClickPrevPond={this.handleClickPrevPond}
            onChangeDate={this.handleChangeDate}
            onClickFeed={this.handleClickFeed}
            onClickWaterTemperature={this.handleClickWaterTemperature}
            onClickFertilizer={this.handleClickFertilizer}
            onClickHerbicide={this.handleClickHerbicide}
            onClickNote={this.handleClickNote}
            onClickPondReport={this.handleClickPondReport}
            onClickEnvironment={this.handleClickEnvironment}
            onClickReportList={this.handleClickReportList}
          />
        </BaseNavigationContent>
      </MobileLayout>
    );
  }

  public initState = (props: Readonly<WorkReportMenuPageProps>) => {
    if (props.navigationState.selectedPondId === null) {
      props.history.push("/");
      return;
    }
    props.pondSelectionReadApiService.fetchUnFetchedApi();
  };

  private handleNextOrPrevPond = (isNext: boolean) => {
    const { selectedPondId, currentPage } = this.props.navigationState;
    if (selectedPondId === null) {
      return;
    }

    let ponds: Pond[];
    let pondAreas: PondArea[];
    if (currentPage === "fry") {
      ponds = filterPondsByFryPond(this.props.navigationState, this.props);
      pondAreas = [];
    } else {
      // エリア-担当者-担当野池 or 画面リロード時
      ponds = filterPondsByFromPage(
        this.props.ponds,
        this.props.navigationState,
        this.props.authState
      );
      pondAreas = this.props.pondAreas;
    }
    const pond = isNext ? getNextPond(selectedPondId, { ponds, pondAreas }) : getPrevPond(selectedPondId, { ponds, pondAreas });
    if (pond === null) {
      return;
    }
    this.props.navigationService.selectPond(pond.id);
  };

  private handleClickNextPond = () => {
    this.handleNextOrPrevPond(true);
  };

  private handleClickPrevPond = () => {
    this.handleNextOrPrevPond(false);
  };

  private handleChangeDate = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const m = moment(e.target.value, "YYYY-MM-DD");
    if (m.isValid()) {
      const date = m.toDate();
      this.props.navigationService.changeDate(date);
    }
  };
  private handleClickFeed = () => {
    this.props.history.push("/reports/feeds");
  };
  private handleClickWaterTemperature = () => {
    this.props.history.push("/reports/environment_reports");
  };
  private handleClickFertilizer = () => {
    this.props.history.push("/reports/fertilizers");
  };
  private handleClickHerbicide = () => {
    this.props.history.push("/reports/herbicides");
  };
  private handleClickNote = () => {
    this.props.history.push("/reports/notes");
  };
  private handleClickPondReport = () => {
    this.props.history.push("/reports/pond_reports");
  };
  private handleClickEnvironment = () => {
    this.props.history.push("/reports/environment_reports/detail");
  };

  private handleClickReportList = () => {
    this.props.history.push("/reports");
  };

  private handleClickMenu = () => {
    this.props.navigationService.openMenu();
  };
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  const { pond, pondArea, workReport, pondType, pondReport, parentCarp, carpVarietyType } = state.api;
  return {
    pondAreas: pondArea.pondAreas.filter(isActiveEntity),
    ponds: pond.ponds.filter((p) => isActiveEntity(p) && isUsedPond(p) && p.pondTypeId !== null),
    workReports: workReport.workReports,
    pondTypes: pondType.pondTypes,
    pondReports: pondReport.pondReports,
    parentCarps: parentCarp.parentCarps.filter(isActiveEntity),
    carpVarietyTypes: carpVarietyType.carpVarietyTypes,
    navigationState: state.mobile.navigation,
    newOrEditState: state.mobile.workReportState.newOrEditStateType,
    authState: state.auth,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    newOrEditStateService: new NewOrEditStateService(dispatch),
    navigationService: new NavigationService(dispatch),
    pondSelectionReadApiService: new ReadApiService(dispatch),
  };
};

export const WorkReportMenuPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wrapped));
