import { actionCreatorFactory } from "typescript-fsa";
import { UseMethodType } from "../../../../domain/useMethodType";

// Actions
const actionCreator = actionCreatorFactory("ApiUseMethodType");

const fetchStart = actionCreator("fetchStart");
const fetchComplete = actionCreator<FetchCompletePayload>("fetchComplete");
interface FetchCompletePayload {
  useMethodTypes: UseMethodType[];
}
const fetchFail = actionCreator("fetchFail");
const shouldFetch = actionCreator("shouldFetch");

export const apiUseMethodTypeActions = {
  fetchStart,
  fetchComplete,
  fetchFail,
  shouldFetch,
};

// Reducers
import { FetchHandlers, getInitialState, UseMethodTypeStateType } from "./reducer";

import { reducerWithInitialState } from "typescript-fsa-reducers";

export const UseMethodTypeReducer = reducerWithInitialState<UseMethodTypeStateType>(getInitialState())
  .case(fetchStart, (state) => FetchHandlers.start(state))
  .case(fetchComplete, (state, { useMethodTypes }) => FetchHandlers.complete(state, useMethodTypes))
  .case(fetchFail, (state) => FetchHandlers.fail(state))
  .case(shouldFetch, (state) => FetchHandlers.shouldFetch(state));
