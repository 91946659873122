import { default as moment } from "moment";
import { Dispatch } from "redux";
import { createNewCarpMovingReport, NewCarpMovingReport } from "../../../domain/carpMovingReport";
import { getUniqueKey, UniqueKey } from "../../../domain/entity";
import {
  getCurrentPondReport,
  getFryMaxSubNumberOfSelectionNumber,
  isAdultPondReports,
  PondReport,
} from "../../../domain/pondReport";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { ApplicationState } from "../../../store/modules";
import { pondReportNewOrEditStateActions } from "../../../store/modules/mobile/pondReport/newOrEditState/ducks";

interface ICarpSelectionReportStateService extends IApplicationService {
  changeAmount: (amount: string) => void;
  changeCarpSize: (sizeId: null | number) => void;
  changeCarpQuality: (qualityId: null | number) => void;
  changeMalformation: (malformationId: null | number) => void;
  changeCarpRatio: (ratioId: null | number) => void;
  changeDate: (date: Date) => void;
  changeDateToToday: () => void;
  changeDateToYesterday: () => void;
  changeDateToYesterdayMinusOne: () => void;
  changeCarpScore: (carpScore: string) => void;
  changeNote: (note: string) => void;
  changeIsCompleted: (isCompleted: boolean) => void;
  addCarpMovingReport: (successCallback: () => void) => void;
}

export class CarpSelectionReportStateService implements ICarpSelectionReportStateService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public changeAmount(amount: string) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      if (!this.canEditState(state)) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        pondReportNewOrEditStateActions.changeCarpSelectionReport({
          selectionUniqueKey: this.getSelectionUniqueKey(state),
          key: "amount",
          value: amount,
        })
      );
    });
  }

  @catchApplicationError()
  public changeCarpSize(sizeId: null | number) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      if (!this.canEditState(state)) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        pondReportNewOrEditStateActions.changeCarpSelectionReport({
          selectionUniqueKey: this.getSelectionUniqueKey(state),
          key: "carpSizeTypeId",
          value: sizeId,
        })
      );
    });
  }

  @catchApplicationError()
  public changeCarpQuality(qualityId: null | number) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      if (!this.canEditState(state)) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        pondReportNewOrEditStateActions.changeCarpSelectionReport({
          selectionUniqueKey: this.getSelectionUniqueKey(state),
          key: "carpQualityTypeId",
          value: qualityId,
        })
      );
    });
  }

  @catchApplicationError()
  public changeMalformation(malformationId: null | number) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      if (!this.canEditState(state)) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        pondReportNewOrEditStateActions.changeCarpSelectionReport({
          selectionUniqueKey: this.getSelectionUniqueKey(state),
          key: "malformationTypeId",
          value: malformationId,
        })
      );
    });
  }

  @catchApplicationError()
  public changeCarpRatio(ratioId: null | number) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      if (!this.canEditState(state)) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        pondReportNewOrEditStateActions.changeCarpSelectionReport({
          selectionUniqueKey: this.getSelectionUniqueKey(state),
          key: "carpRatioTypeId",
          value: ratioId,
        })
      );
    });
  }

  @catchApplicationError()
  public changeDate(date: Date) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      if (!this.canEditState(state)) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        pondReportNewOrEditStateActions.changeCarpSelectionReport({
          selectionUniqueKey: this.getSelectionUniqueKey(state),
          key: "date",
          value: date,
        })
      );
    });
  }

  @catchApplicationError()
  public changeDateToToday() {
    const date = new Date();
    this.changeDate(date);
  }

  @catchApplicationError()
  public changeDateToYesterday() {
    const date = moment().add(-1, "days");
    this.changeDate(date.toDate());
  }

  @catchApplicationError()
  public changeDateToYesterdayMinusOne() {
    const date = moment().add(-2, "days");
    this.changeDate(date.toDate());
  }

  @catchApplicationError()
  public changeCarpScore(carpScore: string) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      if (!this.canEditState(state)) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        pondReportNewOrEditStateActions.changeCarpSelectionReport({
          selectionUniqueKey: this.getSelectionUniqueKey(state),
          key: "carpScore",
          value: carpScore,
        })
      );
    });
  }

  @catchApplicationError()
  public changeNote(note: string) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      if (!this.canEditState(state)) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        pondReportNewOrEditStateActions.changeCarpSelectionReport({
          selectionUniqueKey: this.getSelectionUniqueKey(state),
          key: "note",
          value: note,
        })
      );
    });
  }

  @catchApplicationError()
  public changeIsCompleted(isCompleted: boolean) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      if (!this.canEditState(state)) {
        throw new ApplicationError("不正な動作です。");
      }
      this.dispatch(
        pondReportNewOrEditStateActions.changePondReport({
          key: "isCompleted",
          value: isCompleted,
        })
      );
    });
  }

  @catchApplicationError()
  public addCarpMovingReport(successCallback: () => void) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      const state = getState();
      const pairPondReport = getCurrentPondReport(
        state.api.pondReport.pondReports,
        state.mobile.pondReportState.moveModalState.modalState.pondId,
        state.mobile.navigation.selectedDate
      ) as PondReport;
      if (!pairPondReport) {
        throw new ApplicationError("移動先の野池に記録がありません。");
      }
      if (isAdultPondReports([pairPondReport], state.api.pondType.pondTypes)) {
        throw new ApplicationError("移動先の野池が稚魚池ではありません。");
      }

      const { newOrEditStateType, listState } = state.mobile.pondReportState;
      const selectionReport =
        newOrEditStateType.pondReport &&
        newOrEditStateType.pondReport.carpSelectionReports.find(
          (csr) => getUniqueKey(csr) === listState.selectedUniqueKey
        );
      if (!newOrEditStateType.pondReport || !selectionReport) {
        throw new ApplicationError("移動元の記録がありません。");
      }
      if (selectionReport.date === null) {
        throw new ApplicationError(
          "該当の選別に日付が入っていないので、移動の記録の日付を入れることができませんでした。"
        );
      }
      const subNumber =
        getFryMaxSubNumberOfSelectionNumber(newOrEditStateType.pondReport, selectionReport.selectionNumber) + 1;
      const newMovingReport: NewCarpMovingReport = {
        ...createNewCarpMovingReport(selectionReport.date, selectionReport.selectionNumber, subNumber),
        pairPondReportId: pairPondReport.id,
        amount: selectionReport.amount,
        note: "",
      };
      this.dispatch(pondReportNewOrEditStateActions.addCarpMovingReport({ carpMovingReport: newMovingReport }));
      successCallback();
    });
  }

  private canEditState(state: ApplicationState) {
    const { selectedReportKey, selectedUniqueKey } = state.mobile.pondReportState.listState;
    return selectedReportKey === "CarpSelection" && selectedUniqueKey !== null;
  }

  private getSelectionUniqueKey = (state: ApplicationState): UniqueKey => {
    const { selectedUniqueKey } = state.mobile.pondReportState.listState;
    if (selectedUniqueKey === null) {
      throw new Error("PondReport SelectedUniqueKey is null.");
    }
    return selectedUniqueKey;
  };
}
