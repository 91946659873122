import { Dispatch } from "redux";
import { ScaleType } from "../../../domain/scaleType";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ScaleTypeRepository } from "../../../infrastracture/scaleType/repository";
import { ApplicationState } from "../../../store/modules";
import { apiScaleTypeActions } from "../../../store/modules/api/scaleType/ducks";

interface IScaleTypeApiService extends IApplicationService {
  init: () => void;
  fetchUnFetched: () => void;
}

export class ScaleTypeApiService implements IScaleTypeApiService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError((dispatch) => dispatch(apiScaleTypeActions.fetchFail()))
  public async init() {
    this.dispatch(apiScaleTypeActions.fetchStart());
    const scaleTypes: ScaleType[] = await new ScaleTypeRepository().getScaleTypes();
    this.dispatch(apiScaleTypeActions.fetchComplete({ scaleTypes }));
  }

  @catchApplicationError((dispatch) => dispatch(apiScaleTypeActions.fetchFail()))
  public async fetchUnFetched() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      if (getState().api.scaleType.isFetched) {
        return;
      }
      this.dispatch(apiScaleTypeActions.fetchStart());
      const scaleTypes: ScaleType[] = await new ScaleTypeRepository().getScaleTypes();
      this.dispatch(apiScaleTypeActions.fetchComplete({ scaleTypes }));
    });
  }
}
