import { Dispatch } from "redux";
import { CarpVarietyType } from "../../../domain/carpVarietyType";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { CarpVarietyTypeRepository } from "../../../infrastracture/carpVarietyType/repository";
import { ApplicationState } from "../../../store/modules";
import { apiCarpVarietyTypeActions } from "../../../store/modules/api/carpVarietyType/ducks";

interface ICarpVarietyTypeApiService extends IApplicationService {
  init: () => void;
  fetchUnFetched: () => void;
}

export class CarpVarietyTypeApiService implements ICarpVarietyTypeApiService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError((dispatch) => dispatch(apiCarpVarietyTypeActions.fetchFail()))
  public async init() {
    this.dispatch(apiCarpVarietyTypeActions.fetchStart());
    const carpVarietyTypes: CarpVarietyType[] = await new CarpVarietyTypeRepository().getCarpVarietyTypes();
    this.dispatch(apiCarpVarietyTypeActions.fetchComplete({ carpVarietyTypes }));
  }

  @catchApplicationError((dispatch) => dispatch(apiCarpVarietyTypeActions.fetchFail()))
  public async fetchUnFetched() {
    await this.dispatch(async (__: Dispatch, getState: () => ApplicationState) => {
      if (getState().api.carpVarietyType.isFetched) {
        return;
      }
      this.dispatch(apiCarpVarietyTypeActions.fetchStart());
      const carpVarietyTypes: CarpVarietyType[] = await new CarpVarietyTypeRepository().getCarpVarietyTypes();
      this.dispatch(apiCarpVarietyTypeActions.fetchComplete({ carpVarietyTypes }));
    });
  }
}
