import { Dispatch } from "redux";
import { catchApplicationError, IApplicationService } from "../../../handler/errorHandlers";
import { ApplicationError } from "../../../handler/errors/applicationError";
import { ApplicationState } from "../../../store/modules";
import { masterPondPondAreaStateActions as PondAreaStateActions } from "../../../store/modules/master/pond/pondAreaState/ducks";

interface IPondAreaStateService extends IApplicationService {
  selectPondArea: (pondAreaId: number | null) => void;
}

export class PondAreaStateService implements IPondAreaStateService {
  public constructor(private dispatch: Dispatch<any>) {}

  public getDispatch(): Dispatch {
    return this.dispatch;
  }

  @catchApplicationError()
  public selectPondArea(pondAreaId: number | null) {
    this.dispatch((__: Dispatch, getState: () => ApplicationState) => {
      if (pondAreaId === null) {
        this.dispatch(PondAreaStateActions.selectPondArea({ pondArea: null }));
        return;
      }

      const pondAreas = getState().api.pondArea.pondAreas;
      const pondArea = pondAreas.find((area) => area.id === pondAreaId);
      if (!pondArea) {
        throw new ApplicationError("エリアが見つかりませんでした。");
      }
      this.dispatch(PondAreaStateActions.selectPondArea({ pondArea }));
    });
  }
}
